import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';

import * as moment from "moment";
import * as download from "downloadjs";
import * as JSZip from "jszip";
import * as JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";

import { DXCODE_OPTIONS } from "../../config/constants";
import { Holter } from "../../models/holter.model";
import { Physician } from "../../models/physician.model";
import { Medtrace } from "../../models/medtrace.model";

import { HolterService } from "../../providers/holter.service";
import { AuthService } from "../../providers/auth.service";
import { ClinicService } from "../../providers/clinic.service";
import { PhysicianService } from "../../providers/physician.service";
import { RequisitionService } from 'src/app/providers/requisition.service';
import { PatientService } from 'src/app/providers/patient.service';
import { FaxService } from 'src/app/providers/fax.service';
import { MedtraceService } from 'src/app/providers/medtrace.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalClosedComponent } from '../modal-closed/modal-closed.component';
import { ModalStaffNotesComponent } from '../modal-staff-notes/modal-staff-notes.component';
import { ModalCreateFaxComponent } from '../modal-create-fax/modal-create-fax.component';
import { ModalFlagComponent } from '../modal-flag/modal-flag.component';
import { ModalMedtraceDisplayComponent } from '../modal-medtrace-display/modal-medtrace-display.component';

import { formatNotes, formatNotesMultiple } from "../../utils/format";
import { Fax } from 'src/app/models/fax.model';

@Component({
  selector: 'app-fax-repair',
  templateUrl: './fax-repair.component.html',
  styleUrls: ['./fax-repair.component.scss']
})
export class FaxRepairComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;

  public dtOptions: any = {};

  public multiSelected: boolean = false;

  public formGroup: FormGroup;
  public checkBoxFormGroup: FormGroup;
  public currentHolter: Holter;
  public currentDoctor: Physician;
  public currentFax: Fax;
  public currentMedtrace: Medtrace;
  private loaded: boolean = false;

  public codeFilter: string | null = "All";
  public holterStatusFilter: string | null = "";

  public errorStrings: any = {};

  public formChanged: boolean = false;
  public checkBoxFormChanged: boolean = false;
  public billingCode: any = null;

  public holterErrorIdx: number | string = null;
  public holterErrors: any[] = [];

  public filterDatabaseIdx: number = null;
  public searchDatabases: any[] = [];
  public reqOptions: string[] = [];
  public readingDoctorOptions: any[] = [];
  public downloadProgress = null;

  public pdfSrc: any = null;
  public loadingPdf: boolean = false;
  public pdfSizeLeft: any = 50;
  public pdfSizeRight: any = 50;
  public showPdfPreview: string = "requisition";

  public attachedReqPlaceHolder: string = "Type to search";

  public closeResult = null;

  public fileTypeFilters: any = {
    backup: null,
    pdf: null,
    req: null,
    preliminary: null,
    final: null,
  };

  public emailFilter: string | null = null;

  private readonly DEBOUNCE_SEC: number = 1;

  public verboseDxcode: boolean = false;
  public DXCODE_OPTIONS = DXCODE_OPTIONS;

  public setEmailFilter() {
    if (this.emailFilter) {
      this.emailFilter = null;

    } else {
      const token = this.authService.getToken(true);
      this.emailFilter = token.email;
    }

    this.holterService.updateEmailFilter(this.emailFilter);
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  // important variable that changes if the status column position changes
  private readonly STATUS_COLUMN_INDEX: number = 0;

  public ngAfterViewInit(): void {

    // set up the callbacks
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {

      // on search term callback
      $("#search-bar").on("keyup change", function () {
        setTimeout(() => {
          const search_string = this["value"].replace(',', '')
          if (dtInstance.search() !== search_string) {
            dtInstance.search(search_string).draw();
          }
        }, 1000);
      });

    });

    // focus on the search bar right away after load
    $("#search-bar").focus();
  }

  private resetFilter() {
    this.holterService.clearFilters();
    this.holterService.updateStateFilter(["PENDING", "EDITING", "RESERVED", "READING", "FAXING"]);
    this.holterService.updateReservedByFilter(null);
    this.holterService.updateShowDuplicates(null, null);
    this.holterService.updateClosedFilter(false);
    this.holterService.setCustomFilter("to_repair_faxing");
  }

  public ngOnInit() {}

  constructor(
    private physicianService: PhysicianService,
    private clinicService: ClinicService,
    private holterService: HolterService,
    private authService: AuthService,
    private requisitionService: RequisitionService,
    private patientService: PatientService,
    private faxService: FaxService,
    private medtraceService: MedtraceService,
    private router: Router,
    private modalService: NgbModal,
  ) {

    this.resetFilter();

    // roll callback
    const rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
      // $("td", row).unbind("click");
      $(".table-download-icon", row).bind("click", (event) => this.downloadFileHandler(event, data));
      $(".fa-plus", row).bind("click", (event) => this.expandRowsHandler(event, data, true));
      $(".fa-minus", row).bind("click", (event) => this.expandRowsHandler(event, data, false));
      $("td", row).not(".download-file-column").not(".expand-row-column").bind("click", () => this.rowClickHandler(data));
      return row;
    };

    const drawCallback: any = (settings) => {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
          const row = dtInstance.row(rowIdx);

          if (this.currentHolter && row.data()["holter:id"] == this.currentHolter.id) {
            (row as any).select();
          }

          const isClosed = !!row.data()['holter:closed_by'];
          if (isClosed) {
            $(row.node()).addClass('closed-entry');
          }
          else {
            //KEEP FOR NOW INCASE WE USE THE FLAG REPAIR AGAIN, WAS STOPPED USING BECAUSE THE NEED TO REPAIR REQUIREMENT KEPT CHANGING ESP. WHEN EDITING AND REPAIR PAGE WERE DIFFERENT
            // const expandedFlags = HolterService.expandFlags(row.data()["holter:flags"]);
            // const needRepair = expandedFlags["holter:flag_need_repair"];
            // if (needRepair) {
            //   $(row.node()).addClass('need-repair-entry');
            // }

            const billing_code = row.data()["clinic_assignment:billing_code"];
            const state = row.data()["holter:state"];
            const reading_doctor = row.data()["reading_doctor:name"];
            const expandedFlags = HolterService.expandFlags(row.data()["holter:flags"]);
            const no_req = expandedFlags["holter:flag_no_req"];
            const no_data = expandedFlags["holter:flag_no_data"];
            if (((!billing_code || ((state == "READING" || state == "FAXING") && !reading_doctor) || no_req) && state != "PENDING") || no_data)  {
              $(row.node()).addClass('need-repair-entry');
            }
            else {
              const faxes = row.data()["holter:faxes"];
              let final_faxed = false;
              let faxStatus = "";
              if (faxes && faxes.length > 0) {
                for (let fax of faxes){
                  if (fax["type"]){
                    if (fax["type"].includes("final")){
                      final_faxed = true;
                      faxStatus = fax["status"]
                    }
                  }
                }
              }
              if (faxStatus.toUpperCase() === "FAXED" && final_faxed) {
                $(row.node()).addClass('faxed-entry');
              }
            }
          }
        });
      });
    };
    // setting size of pdfviewer
    this.getPdfViewerSpacing();

    // datatable options
    this.dtOptions = {
        autoWidth: false,
        responsive: true,
        lengthChange: false,
        select: true,
        pageLength: 25,
        dom: "Blfrtip",
        buttons: [],
        rowCallback: rowCallback,
        serverSide: true,
        processing: true,
        scrollY: window.innerHeight - 800,
        ajax: this.holterService.getAjaxFuncion(),
        drawCallback: drawCallback,
        order: [[ 3, "asc" ]],
        columnDefs: [{
          targets: 0,
          className: 'expand-row-column'
        },{
          targets: 1,
          className: 'download-file-column'
        }],
        columns: [
          {
            data: 'holter:id',
            orderable: false,
            render: ( data, type, row, meta ) => {
              const duplicateCount = row["holter:duplicate_count"]
              let expandHtml = ``;
              if (duplicateCount > 1)
                expandHtml = ` &nbsp;<a><i class="fa fa-plus expand-rows-icon"></i></a>`;
              if (duplicateCount < -1)
                expandHtml = ` &nbsp;<a><i class="fa fa-minus expand-rows-icon"></i></a>`;
              return expandHtml;
            },
          },
          {
            data: "holter:state",
            render: function ( data, type, row, meta ) {
              return row["holter:closed_by"] ? "SUSPENDED" : data;
            }
          },
          {
            data: "holter:uploaded_files",
            orderable: false,
            render: function ( data, type, row, meta ) {
              const uploadedfiles = JSON.parse(data);
              const hasPdf = uploadedfiles.includes('out_01.pdf');
              const hasPat = uploadedfiles.includes('pat.001');
              const hasBackup = !!row["holter:backed_up_at"];
              const expandedFlags = HolterService.expandFlags(row["holter:flags"]);
              const hasFinal = expandedFlags["holter:flag_verified"] && row["holter:state"] === "FAXING";
              const hasReq = !!row["holter:requisition_id"];
              const hasPreliminary = expandedFlags["holter:flag_verified"] && row["holter:state"] !== "FAXING";

              let icons = '';
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Tracings"><i class="fa download-tracings fa-file-pdf-o ` + (hasPdf ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Requisition"><i class="fa download-req fa-file-o ` + (hasReq ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Preliminary"><i class="fa download-preliminary fa-file-text-o ` + (hasPreliminary ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Final Report"><i class="fa download-final fa-file-pdf-o ` + (hasFinal ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Data"><i class="fa download-backup fa-database ` + (hasBackup ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              return icons;
            },
          },
          { data: "holter:scan_date" },
          {
            data: "patient:last_name",
            orderable: true,
            render: function ( data, type, row, meta ) {
              const last = row["patient:last_name"];
              const first = row["patient:first_name"];
              const card = row["patient:health_card"];
              const code = row["patient:health_card_code"];
              if (!last && !first) {
                return card.substring(0,10) + "<Br>" + "No Name";
              } else if (!last) {
                return card.substring(0,10)  + "<Br>" + first;
              } else if (!first) {
                return card.substring(0,10)  + "<Br>" + last;
              } else {
                return card.substring(0,10)  + "<Br>" + last + ", " + first;
              }
            },
          },
          { data: "referring_doctor:name" },
          { data: "clinic:city" },
          { data: "clinic_assignment:billing_code" },
          {
            data: 'holter:faxes',
            render: function ( data, type, row, meta ) {
              const faxes = data
              let finalcount = 0;
              if (faxes.length > 0) {
                for (let fax of faxes){
                  // if (fax["type"]){
                  //   if (fax["type"].includes("final")){
                  //     finalcount = finalcount+1;
                  //   }
                  // }
                  if (fax["status"] == "Faxed"){
                      finalcount = finalcount+1;
                  }
                }
              }
              return finalcount;
            }
          },
          {
            data: "holter:flags",
            orderable: false,
            visible: false,
          },
          {
            data: "holter:requisition_id",
            orderable: false,
            visible: false,
          },
          { data: "holter:upload_computer_slot", visible: false },
          { data: "holter:hookup_date", visible: false},
          { data: "reading_doctor:name", visible: false },
          { data: "patient:health_card", visible: false },
          { data: "holter:duration", visible: false },
          { data: "holter:reservations", visible: false },
          { data: "patient:gender", visible: false },
          { data: "patient:birthdate", visible: false },
          { data: "holter:updated_at", visible: false },
          { data: 'patient:health_card_code', visible: false },
          { data: 'patient:first_name', visible: false },
          { data: 'holter:id', visible: false },
          { data: 'holter:state', visible: false },
          { data: 'holter:device_id', visible: false },
          { data: 'holter:verification_number', visible: false },
          { data: 'holter:backed_up_at', visible: false },
          { data: 'holter:closed_by', visible: false },
          { data: 'holter:req_resolved_by', visible: false },
          { data: 'clinic_assignment:fax_number', visible: false },
          { data: 'clinic_assignment:clinic',visible: false },
          { data: 'clinic:fax',visible: false },
          { data: 'clinic:id',visible: false },
          { data: 'referring_doctor:id',visible: false },
          { data: 'holter:erequisition_id', visible: false },
        ],
        language: {
          infoFiltered: ""
        }
    };

    this.formGroup = new FormGroup({
      FirstName: new FormControl("", []),
      LastName: new FormControl("", []),
      Gender: new FormControl("", []),
      HealthCard: new FormControl("", []),
      HealthCardVersion: new FormControl("", []),
      Birthdate: new FormControl("", []),
      DxCode: new FormControl("", []),
      HookupDate: new FormControl("", []),
      Duration: new FormControl("", []),
      ScanDate: new FormControl("", []),
      Tech: new FormControl("", []),
      ReferringDoctor: new FormControl("", []),
      City: new FormControl("", []),
      Device: new FormControl("", []),
      Clinic: new FormControl("", []),
      ReadingDoctor: new FormControl("", []),
      Delivery: new FormControl("", []),
      StaffNotes: new FormControl("", []),
      FaxNotes: new FormControl("", []),
      ReqNotes: new FormControl("", []),
      AdditionalStaffNotes: new FormControl("", []),
      AdditionalFaxNotes: new FormControl("", []),
      Requisition: new FormControl("", []),
      FlagVerified: new FormControl("", []),
      // FlagPacemakerInquiry: new FormControl("", []),
      // FlagNoData: new FormControl("", []),
      // FlagNoReq: new FormControl("", []),
      FlagNoBackup: new FormControl("", []),
      FlagNoTracings: new FormControl("", []),
      FlagInvalidReferring: new FormControl("", []),
      FlagInvalidHealthcard: new FormControl("", []),
      FlagInvalidDob: new FormControl("", []),
      Fax: new FormControl("", []),
    });

    this.checkBoxFormGroup = new FormGroup({
      SkipToReading: new FormControl("", []),
      // Reserved: new FormControl("", []),
      FlagNoData: new FormControl("", []),
      FlagPacemakerInquiry: new FormControl("", []),
      FlagNoReq: new FormControl("", []),
      FlagNoTracings: new FormControl("", []),
    });

    this.formGroup.valueChanges.subscribe(x => {
      this.formChanged = true;
    });

    this.checkBoxFormGroup.valueChanges.subscribe(x => {
      this.checkBoxFormChanged = true;
    });

    this.holterService.getDatabaseOptions().then(options => {
      this.searchDatabases = options;
    });


    // this.holterService.getHolterErrors("repair").then(errors => {
    //   const options = [];
    //   for (const [key, value] of Object.entries(errors)) {
    //     options.push({
    //       name: key,
    //       count: value,
    //     });
    //   }
    //   this.holterErrors = options;
    //   options.forEach((option, index) => {
    //     if (option.name == "To be Repaired") {
    //       this.holterErrorIdx = index;
    //       this.applySmartFilter();
    //     }
    //   });
    // });

    this.physicianService.allReading().then(doctors => {
      doctors.forEach(doctor => {
        const text = [
          doctor.last_name,
          doctor.first_initials,
        ].filter(x => !!x).join(" | ");

        this.readingDoctorOptions.push({
          value: doctor.name,
          text: text,
        });
      });
    });

    this.formGroup.get("Gender")?.valueChanges.subscribe(event => this.verifyTracingsObservable(event, this));
    this.formGroup.get("Duration")?.valueChanges.subscribe(event => this.verifyTracingsObservable(event, this));
    this.formGroup.get("ReadingDoctor")?.valueChanges.subscribe(event => this.verifyTracingsObservable(event, this));
    this.formGroup.get("ReferringDoctor")?.valueChanges.subscribe(event => this.getBillingCodeObservable(event, this));
    this.formGroup.get("Clinic")?.valueChanges.subscribe(event => this.getBillingCodeObservable(event, this));
  }

  private refreshSmartFilters() {
    this.holterService.getHolterErrors("repair").then(errors => {
      const options = [];
      for (const [key, value] of Object.entries(errors)) {
        options.push({
          name: key,
          count: value,
        });
      }
      this.holterErrors = options;
    });
  }

  private getBillingCodeObservable(event, that) {
    if (!that.currentHolter || !that.loaded) {
      return;
    }
    const doctor = that.formGroup.get("ReferringDoctor")?.value?.name || that.formGroup.get("ReferringDoctor")?.value;
    const clinic = that.formGroup.get("Clinic")?.value;
    if (doctor && clinic) {
      // SEARCHING BILLING CODE DOESNT WORK WHEN CLINICS/DOCTORS HAVE SAME NAMES IN DIRECTORY
      // this.physicianService.searchBillingCode(doctor, clinic).then(billingCode => {
      //   this.billingCode = billingCode || null;
      // });
      this.billingCode = that.currentDoctor?.billing_code || null;
    } else {
      this.billingCode = null;
    }
  }

  public verifyTracings() {
    $("body, button").css("cursor", "wait");
    this.verifyTracingsObservable(null, this).then(() => {
      $("body, button").css("cursor", "auto");
    });
  }

  private verifyHealthcardObservable(event, that) {
    if (!that.currentHolter || !that.loaded) {
      return;
    }
    const healthcard = that.currentHolter.patient_health_card.substring(0,10);
    this.patientService.getPatientByHealthCard(healthcard).then(found_patient => {
      if ((found_patient.last_name != that.currentHolter.patient_last_name) || (found_patient.first_name != that.currentHolter.patient_first_name)){
        this.errorStrings.last_name = found_patient.last_name;
        this.errorStrings.first_name = found_patient.first_name;
      }
    });
  }

  private verifyTracingsObservable(event, that) {
    if (!that.currentHolter || !that.loaded || !that.currentHolter.uploaded_files.includes("out_01.pdf")) {
      return;
    }

    return that.holterService.getMedfluxVars(that.currentHolter.id)
    .then(medfluxVars => {
        this.errorStrings = {};

        const tracingGender = medfluxVars.pdf["GENDER"] || null;
        if (tracingGender !== that.formGroup.get("Gender")?.value) {
          this.errorStrings.gender = "Conflict: " + (tracingGender || "empty");
        }

        const tracingDuration = medfluxVars.pdf["DAYS RECORDED"] || null;
        if (tracingDuration != that.formGroup.get("Duration")?.value) {
          this.errorStrings.duration = "Conflict: " + (tracingDuration || "empty");
        }

        const tracingReadingDr = medfluxVars.pdf["READING DR"] || null;
        if (tracingReadingDr != that.formGroup.get("ReadingDoctor")?.value) {
          this.errorStrings.readingDr = "Conflict: " + (tracingReadingDr || "empty");
        }

        if (that.formGroup.get("ReadingDoctor")?.value === that.currentHolter.reading_doctor_name && that.currentHolter.reading_doctor_roles !== "Reading") {
          this.errorStrings.readingDr = "Not a registered reading doctor";
        }
      });
  }

  private updateMultiSelected(that: any) {
    that.datatableElement.dtInstance.then((dtInstance: any) => {
      const selectedData = dtInstance.rows(".selected").data();
      that.multiSelected = selectedData.length > 1;
    });
  }

  public rowClickHandler(info: any, force: boolean = false) {
    setTimeout(() => this.updateMultiSelected(this), 200);
    this.loadingPdf = true;
    this.physicianService.getDoctor(info["referring_doctor:id"], info["clinic:id"]).then(doctor => {
      this.currentDoctor = doctor;
      this.holterService.getHolter(info["holter:id"]).then(holter => {
        this.populateHolter(holter);
        // Get latest Medtrace Data for selected holter serial number
        this.medtraceService.getLatestMedtraceBySerialNumber(holter.device_serial_number).then(medtrace => this.currentMedtrace = medtrace);
        this.datatableElement.dtInstance.then((dtInstance: any) => {
          const selectedData = dtInstance.rows(".selected").data();
          if (selectedData.length == 1) {
            if (this.showPdfPreview == "tracings"){
              this.showTracings();
            }
            if (this.showPdfPreview == "requisition"){
              this.showRequisition();
            }
            if (this.showPdfPreview == "fax"){
              const faxes = selectedData[0]["holter:faxes"];
              if (faxes.length > 0){
                let fax_id = null;
                let fax_time = null;
                for (let fax of faxes){
                  let fax_time_compare = moment(fax["success_at"]);
                  if (fax["success_at"]){
                    if (fax_time == null) {
                      fax_time = fax_time_compare;
                      fax_id = fax["id"];
                    }
                    else if (fax_time < fax_time_compare) {
                      fax_time = fax_time_compare;
                      fax_id = fax["id"];
                    }
                  }
                }
                this.showFaxes(fax_id);
                this.faxService.getFax(fax_id).then(fax =>{
                  this.currentFax = fax;
                  this.formGroup.patchValue({
                    Fax: this.currentFax?.id || "",
                  });
                });
              }
            }
          }
        });
      }).catch((error: any) => {
        this.loadingPdf = false;
      });
    });
  }

  public faxSelectionChanged() {
    const fax_id = this.formGroup.get("Fax")?.value;
    this.showFaxes(fax_id);

    return this.faxService.getFax(fax_id).then(fax =>
      this.currentFax = fax
    );
  }

  public showTracings() {
    return this.holterService.getTracings(this.currentHolter.id, "annotated").then(url => {
      this.pdfSrc = url;
      setTimeout(() => {
        this.loadingPdf = false;
      }, 200);
    });
  }

  public showRequisition() {
    this.holterService.getRequisition(this.currentHolter.id).then(url => {
      this.pdfSrc = url;
      setTimeout(() => {
        this.loadingPdf = false;
      }, 200);
    });
  }

  public showFaxes(fax_id) {
    if (fax_id) {
      this.faxService.getS3Preview(fax_id).then((url) => {
        this.pdfSrc = url;
        setTimeout(() => {
          this.loadingPdf = false;
        }, 200);
      });
    }
    else {
      this.pdfSrc = null;
    }
  }

  private populateHolter(holter: Holter)
  {
    this.errorStrings = {};
    this.loaded = false;
    this.currentHolter = holter;
    this.formGroup.reset();
    this.checkBoxFormGroup.reset();
    holter.erequisition_id ? this.attachedReqPlaceHolder = "E-Requisition attached": this.attachedReqPlaceHolder = "Type to search";
    const requisitionStr = this.searchRequisitionFormatter({
      last_name: holter.requisition_last_name,
      first_name: holter.requisition_first_name,
      date: holter.requisition_date,
      status: holter.requisition_status,
      closed: holter.requisition_closed_by,
    });
    this.checkBoxFormGroup.patchValue({
      // Reserved: this.hasReserved(holter.reservations, token.email),
      FlagNoData: holter.flag_monitor_no_data,
      FlagNoReq: holter.req_resolved_by ? !holter.flag_no_req : holter.flag_no_req,
      FlagPacemakerInquiry: holter.flag_pacemaker_inquiry,
      FlagNoTracings: holter.flag_no_tracings,
    })
    this.formGroup.patchValue({
      FirstName: holter.patient_first_name,
      LastName: holter.patient_last_name,
      Gender: holter.patient_gender,
      HealthCard: holter.patient_health_card.substring(0,10),
      HealthCardVersion: holter.patient_health_card_code,
      Birthdate: holter.patient_birthdate,
      DxCode: holter.dx_code_code,
      HookupDate: holter.hookup_date,
      Duration: holter.duration,
      ScanDate: holter.scan_date,
      Tech: holter.staff_first_name + " " + holter.staff_last_name,
      ReferringDoctor: holter.referring_doctor_name,
      City: holter.clinic_city,
      Device: holter.device_serial_number,
      Clinic: holter.clinic_name,
      ReadingDoctor: holter.reading_doctor_name,
      Delivery: holter.delivery,
      StaffNotes: formatNotesMultiple([holter.notes, holter.requisition_staff_notes]),
      FaxNotes: formatNotesMultiple([holter.fax_notes, holter.requisition_fax_notes]),
      ReqNotes: holter.requisition_comments,
      Requisition: requisitionStr,
      FlagVerified: holter.flag_verified,
      // FlagPacemakerInquiry: holter.flag_pacemaker_inquiry,
      // FlagNoData: holter.flag_no_data,
      // FlagNoReq: holter.flag_no_req,
      FlagNoBackup: holter.flag_no_backup,
      // FlagNoTracings: holter.flag_no_tracings,
      FlagInvalidReferring: holter.flag_invalid_referring,
      FlagInvalidHealthcard: holter.flag_invalid_healthcard,
      FlagInvalidDob: holter.flag_invalid_dob,
    })
    this.formChanged = false;
    this.checkBoxFormChanged = false;
    this.loaded = true;
    this.getBillingCodeObservable(null, this);
    this.verifyHealthcardObservable(null, this);
  }

  public updateHolter(): void {
    if (this.currentHolter == null) {
      return;
    }
    let requisition = this.formGroup.get("Requisition")?.value;
    if (!requisition) {
      requisition = null
    } else if (typeof requisition !== "object") {
      requisition = {
        id: this.currentHolter.requisition_id,
      }
    }
    if (requisition && requisition != this.currentHolter.requisition_id){
      this.requisitionService.mergeERequisition(this.currentHolter.id, requisition.id).then(url => {
        if (url) {
          this.loadingPdf = true;
          this.pdfSrc = url;
          setTimeout(() => {
            this.loadingPdf = false;
          }, 200);
        }
        this.requisitionService.reserve(requisition.id).then(() => {
          this.holterService.addNotes(this.currentHolter.id, "Requisition Reserved", null)
        });
      });
    }
    let readingDr = this.formGroup.get("ReadingDoctor")?.value;
    if (this.readingDoctorOptions.filter(option => option.value == readingDr).length <= 0) {
      readingDr = null;
    }
    // Checkbox for requisition resolved
    let flag_req = this.checkBoxFormGroup.get("FlagNoReq")?.value;
    if (!this.currentHolter.requisition_id && !flag_req){
      this.holterService.reqResolved(this.currentHolter.id).then(() => {
        this.holterService.addNotes(this.currentHolter.id, "Requisition Resolved", null)
      });
    }
    // Checkbox for pacemaker
    let pacemakerInquiry = this.checkBoxFormGroup.get("FlagPacemakerInquiry")?.value;
    if (this.currentHolter.flag_pacemaker_inquiry != pacemakerInquiry){
      let notes;
      !this.currentHolter.flag_pacemaker_inquiry ? notes = "Pacemaker Inquiry" : notes = "Pacemaker Resolved";
      this.holterService.addNotes(this.currentHolter.id, notes, null)
    }
    // Checkbox for monitor no data
    let monitorNoData = this.checkBoxFormGroup.get("FlagNoData")?.value;
    if (monitorNoData && !this.currentHolter.flag_monitor_no_data){
      this.holterService.addNotes(this.currentHolter.id, "Monitor No Data", null)
    }
    // Checkbox for skip to reading
    let skipInterpretation = this.checkBoxFormGroup.get("SkipToReading")?.value;
    if (this.currentHolter.state == "EDITING" && skipInterpretation){
      this.holterService.skipToReading(this.currentHolter.id).then(() => {
        this.holterService.addNotes(this.currentHolter.id, "Skip Interpretation", null)
      });
    }
    let update_data = {
      patient: {
        first_name: this.formGroup.get("FirstName")?.value,
        last_name: this.formGroup.get("LastName")?.value,
        healthcard: this.formGroup.get("HealthCard")?.value,
        healthcard_version: this.formGroup.get("HealthCardVersion")?.value,
        gender: this.formGroup.get("Gender")?.value,
        birthdate: this.formGroup.get("Birthdate")?.value,
      },
      dxcode: {
        code: this.formGroup.get("DxCode")?.value,
      },
      duration: this.formGroup.get("Duration")?.value,
      referring_doctor: {
        name: this.formGroup.get("ReferringDoctor")?.value?.name || this.formGroup.get("ReferringDoctor")?.value,
      },
      reading_doctor: {
        name: readingDr,
      },
      delivery: this.formGroup.get("Delivery")?.value,
      notes: this.formGroup.get("StaffNotes")?.value,
      fax_notes: this.formGroup.get("FaxNotes")?.value,
      requisition: requisition,
      // CHECKBOX GROUP
      flag_pacemaker_inquiry: this.checkBoxFormGroup.get("FlagPacemakerInquiry")?.value,
      flag_monitor_no_data: this.checkBoxFormGroup.get("FlagNoData")?.value,
    }
    if (this.formGroup.get("Clinic")?.value != "" && this.formGroup.get("City")?.value != "") {
      update_data["clinic"] = {
        name: this.formGroup.get("Clinic")?.value,
        city: this.formGroup.get("City")?.value,
        clinic_id: this.currentDoctor ? this.currentDoctor.clinic?.id : null,
      }
    }
    else {
      update_data["clinic"] = {
        name: null,
        clinic_id: null,
      }
    }
    this.holterService.updateHolter(this.currentHolter.id, update_data).then(() => {
      if (this.formChanged){
        this.holterService.annotate(this.currentHolter.id);
      }
      this.holterService.getHolter(this.currentHolter.id).then(holter => {
        this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.draw(false);
        });
        this.refreshSmartFilters();
        if (holter.flag_need_repair) {
          this.formChanged = false;
          this.checkBoxFormChanged = false;
          this.populateHolter(holter)
          return;
        }
        this.currentHolter = null;
        this.formGroup.reset();
        this.checkBoxFormGroup.reset();
        this.errorStrings = {};
        this.loaded = false;
      });
    });
  }

  public formatDatabaseDisplay(database: any) {
    if (database.computer) {
      return database.computer + ' ' + database.path;
    }
    return database.path;
  }

  // NOT BEING USED PER PORT 336
  public applySmartFilter() {
    const toFilter = this.holterErrorIdx == null ? null : this.holterErrors[this.holterErrorIdx];
    this.resetFilter();
    this.holterService.updateStateFilter(["PENDING", "EDITING", "RESERVED", "READING", "FAXING"]);

    const codeFilter = this.codeFilter.toLowerCase() === "all" ? null : this.codeFilter;
    this.holterService.updateBillingCodeFilter(codeFilter);

    if (toFilter == null) {
      // no-op
    } else if (toFilter.name == "Duplicate Data") {
      this.holterService.applyGroupBy("holter_duplicates");
    } else if (toFilter.name == "No Suspended Duplicates") {
      this.holterService.setCustomFilter("no_suspended_dupes");
    } else if (toFilter.name == "No Requisition") {
      this.holterService.updateFlag("flag_no_req", true);
    } else if (toFilter.name == "No Data") {
      this.holterService.updateFlag("flag_no_data", true);
    } else if (toFilter.name == "Pacemaker Inquiry") {
      this.holterService.updateFlag("flag_pacemaker_inquiry", true);
    } else if (toFilter.name == "Invalid Referring Doctor") {
      this.holterService.updateFlag("flag_invalid_referring", true);
    } else if (toFilter.name == "Invalid Healthcard") {
      this.holterService.updateFlag("flag_invalid_healthcard", true);
    } else if (toFilter.name == "Invalid Date of Birth") {
      this.holterService.updateFlag("flag_invalid_dob", true);
    } else if (toFilter.name == "To be Repaired") {
      if (this.holterStatusFilter == "") {
        this.holterService.updateClosedFilter(false);
        this.holterService.updateStateFilter(["PENDING", "EDITING", "RESERVED", "READING", "FAXING"]);
      }
      this.holterService.setCustomFilter("to_repair_faxing");
    }

    if (this.holterStatusFilter == "SUSPENDED") {
      this.holterService.updateClosedFilter(true);
    }
    if (this.holterStatusFilter != "" && this.holterStatusFilter != "SUSPENDED") {
      this.holterService.updateStateFilter([this.holterStatusFilter]);
      this.holterService.updateClosedFilter(false);
    }

    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  public databaseFilterChanged() {
    const toFilter = this.filterDatabaseIdx == null ? null : this.searchDatabases[this.filterDatabaseIdx];
    if (toFilter == null) {
      this.holterService.updateComputerFilter(null, null);
    } else {
      this.holterService.updateComputerFilter(toFilter.computer, toFilter.path);
    }
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  private hasReserved(employees, toCheckEmail) {
    for(const employee of employees) {
      if (employee.email == toCheckEmail) {
        return true;
      }
    }
    return false;
  }

  public expandRowsHandler(event: any, info: any, expand: boolean) {
    const clickedId = info["holter:id"];

    if (expand)
    {
      this.holterService.setCustomFilter("show_duplicates");
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
          const row = dtInstance.rows().data()[rowIdx];
          if (row["holter:id"] != clickedId) {
            return;
          }
          this.holterService.updateShowDuplicates(row["holter:verification_number"], row["holter:device_id"]);
          dtInstance.page(0).draw();
        });
      });
    }
    else
    {
      this.applySmartFilter();
    }
  }

  public downloadFileHandler(event: any, info: any) {
    setTimeout(() => {
      $("body, .table-download-icon, tr").css("cursor", "wait");
      return this.holterService.getHolter(parseInt(info["holter:id"]))
      .then((holter: Holter | null) => {

        // no holter found with matching id
        if (!holter) {
          return;
        }

        // determine which download was clicked
        const classNames = event.target.className;
        const fileNamePrefix = holter.patient_last_name + "_" + holter.patient_first_name + "_" + holter.hookup_date + "_";
        if (classNames.includes('download-tracings')) {
          return this.holterService.getTracings(holter.id, "flattened").then(url => {
            this.downloadPDF(url, holter, 'tracing', 'pdf');
          });
        } else if (classNames.includes('download-backup')) {
          return this.holterService.backupUrls(holter.id).then(urls => {
            const zip_name = `${holter.patient_last_name}, ${holter.patient_first_name}_backup_${holter.hookup_date}_${holter.referring_doctor_name}.zip`;
            var count = 0;
            var zip = new JSZip();
            urls.forEach(url => {
              return JSZipUtils.getBinaryContent(url.url, (err, data) => {
                zip.file(url.filename, data, {binary:true});
                count++;
                this.downloadProgress = "Downloaded " + count + "/" + urls.length + " files";
                if (count == urls.length) {
                  this.downloadProgress = "Zipping files. This may take a couple of minutes. Do not navigate away from page.";
                  zip.generateAsync({type:'blob'}).then(content => {
                    saveAs(content, zip_name);
                    this.downloadProgress = null;
                  });
                }
              });
            });
          });
        } else if (classNames.includes('download-preliminary')) {
          return this.holterService.getMonitorReport(holter.id)
          .then((base64Data: string) => {
            download(HolterService.base64ToArrayBuffer(base64Data), fileNamePrefix + "prelim", "application/pdf");
          })
        } else if (classNames.includes('download-final')) {
          return this.holterService.getMonitorReport(holter.id)
          .then((base64Data: string) => {
            download(HolterService.base64ToArrayBuffer(base64Data), fileNamePrefix + "final", "application/pdf");
          })
        } else if (classNames.includes('download-req')) {
          return this.holterService.getRequisition(holter.id).then((url) => {
            this.downloadPDF(url, holter, 'req', 'pdf');
          });
        }
        return;
      })
      .then(() => {
        $("body, .table-download-icon, tr").css("cursor", "auto");
      })
      .catch(() => {
        $("body, .table-download-icon, tr").css("cursor", "auto");
      });
    }, 500);
  }

  public faxInquiry() {

  }

  searchRequisition = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => (term.length < 2) ? Promise.resolve([])
        : this.requisitionService.searchRequisition(
          term, false, {
            first_name: "",
            last_name: "No requisition found",
        }))
    );

  searchRequisitionFormatter = (result: any): string => {
    if (typeof result !== 'object') {
      return result;
    }
    const status = (result.closed) ? " Suspended" : (result.status ? (" " + result.status) : "");
    return ((result.last_name || result.first_name) ? (result.last_name + ", " + result.first_name) : "") +
      (result.date ? (" - " + moment(result.date).format("YYYY-MM-DD")) : "") + status;
  };

  searchRequisitionInputFormatter = (result: any): string => {
    return this.searchRequisitionFormatter(result);
  };

  selectedReq(item){
    this.requisitionService.getRequisitionPresignedUrl(item.item.id).then(url => {
      this.pdfSrc = url;
      setTimeout(() => {
        this.loadingPdf = false;
      }, 200);
    });
  }

  searchReferringDoctor = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => (!this.authService.isCardioStudyCompanyClass() || term.length < 2) ? Promise.resolve([])
        : this.physicianService.searchDoctor(term, true, false))
    )

  searchReferringDoctorFormatter = (result: any): string => {
    return [
      result.last_name,
      result.first_initials,
      result.clinic ? result.clinic.city : null,
      result.billing_code,
    ].filter(x => !!x).join(" | ")
  };

  searchReferringDoctorInputFormatter = (result: any): string => {
    if (!result.name) {
      return result;
    }

    this.formGroup.patchValue({
      ReferringDoctor: result.name,
      City: result.clinic ? result.clinic.city : null,
      Clinic: result.clinic ? result.clinic.name : null,
    });
    this.errorStrings.city = result.clinic ? null : "None found";
    this.currentDoctor = result;
    return result.name;
  };

  public toggleFileTypeFilter(fileType) {
    if (fileType == "pdf") {
      if (this.currentHolter) this.showTracings();
      this.showPdfPreview = "tracings"
    }
    if (fileType == "req") {
      if (this.currentHolter) this.showRequisition();
      this.showPdfPreview = "requisition"
    }
    if (fileType == "fax") {
      if (this.currentHolter) {
        this.datatableElement.dtInstance.then((dtInstance: any) => {
          const selectedData = dtInstance.rows(".selected").data();
          // Check if there is no multi select
          if (selectedData.length == 1) {
            if (this.showPdfPreview == "fax"){
              const faxes = selectedData[0]["holter:faxes"];
              // Check if there is multiple faxes
              if (faxes.length > 0){
                let fax_id = null;
                let fax_time = null;
                // Get the latest successful fax
                for (let fax of faxes){
                  let fax_time_compare = moment(fax["success_at"]);
                  if (fax["success_at"]){
                    if (fax_time == null) {
                      fax_time = fax_time_compare;
                      fax_id = fax["id"];
                    }
                    else if (fax_time < fax_time_compare) {
                      fax_time = fax_time_compare;
                      fax_id = fax["id"];
                    }
                  }
                }
                this.showFaxes(fax_id);
                this.faxService.getFax(fax_id).then(fax =>{
                  this.currentFax = fax;
                  this.formGroup.patchValue({
                    Fax: this.currentFax?.id || "",
                  });
                });
              }
            }
          }
        });
      }
      this.showPdfPreview = "fax"
    }
  }

  public closeHolter() {
    this.holterService.closeHolter(this.currentHolter.id).then(() => {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.draw(false);
      });
      this.holterService.getHolter(this.currentHolter.id).then(holter => this.populateHolter(holter));
      this.refreshSmartFilters();
    });
  }

  public addStaffNotes() {
    this.requisitionService.getRequisition(this.currentHolter.erequisition_id).then(ereq => {

      const modalRef = this.modalService.open(ModalStaffNotesComponent, {size:'lg'});
      modalRef.componentInstance.holter = this.currentHolter;
      modalRef.componentInstance.doctor = this.currentDoctor;
      modalRef.componentInstance.ereq = ereq;
      modalRef.componentInstance.disable = false;
      modalRef.result.then(result => {

        if (result.createReq){
          let formResult = {
            lastName: result.lastName ? result.lastName : null,
            firstName: result.firstName ? result.firstName : null,
            healthCard: result.healthCard ? result.healthCard : null,
            healthCardVersion: result.healthCardVersion ? result.healthCardVersion : null,
            birthdate: result.birthdate ? result.birthdate : null,
            referringDoctor: result.referringDoctor ? result.referringDoctor : null,
            adult: result.adult ? result.adult : null,
            peadiatric: !result.adult ? !result.adult : null,
            testDate: result.testDate ? result.testDate : null,
            abnormal: result.abnormal ? result.abnormal : null,
            afib: result.afib ? result.afib : null,
            palpitations: result.palpitations ? result.palpitations : null,
            postStroke: result.postStroke ? result.postStroke : null,
            syncope: result.syncope ? result.syncope : null,
            atrialArrhythmia: result.atrialArrhythmia ? result.atrialArrhythmia : null,
            presyncope: result.presyncope ? result.presyncope : null,
            medication: result.medication ? result.medication : null,
            chestPain: result.chestPain ? result.chestPain : null,
            ventricular: result.ventricular ? result.ventricular : null,
            fatigue: result.fatigue ? result.fatigue : null,
            pacemaker: result.pacemaker ? result.pacemaker : null,
            VVI: result.VVI ? result.VVI : null,
            DDD: result.DDD ? result.DDD : null,
            atrialFibrillation: result.atrialFibrillation ? result.atrialFibrillation : null,
            other: result.other ? result.other : null,
            otherIndicationsText: result.otherIndicationsText ? result.otherIndicationsText : null,
            clinic: result.clinic ? result.clinic : null,
            city: result.city ? result.city : null,
            duration: result.duration ? result.duration : null,
          };
          this.requisitionService.createRequisitionReport(this.currentHolter.id, formResult).then(erequisition => {
            // Checkbox for requisition resolved
            let flag_req = this.checkBoxFormGroup.get("FlagNoReq")?.value;
            if (!this.currentHolter.requisition_id && flag_req){
              this.holterService.reqResolved(this.currentHolter.id).then(() => {
                this.holterService.addNotes(this.currentHolter.id, "Requisition Resolved by creating E-Req", null)
              });
            }
            const uploadData = {
              patient: {
                first_name: formResult.firstName,
                last_name: formResult.lastName,
                healthcard: formResult.healthCard,
                healthcard_version: formResult.healthCardVersion,
                birthdate: formResult.birthdate,
              },
              hookup_date: formResult.testDate,
              referring_doctor: {
                name: formResult.referringDoctor ? (formResult.referringDoctor.name || formResult.referringDoctor) : null,
              },
              clinic: {
                name: formResult.clinic,
                city: formResult.city,
              },
              ereq: erequisition,
              duration: formResult.duration,
            }
            this.holterService.updateHolter(this.currentHolter.id, uploadData).then(() => {
              this.showRequisition();
              this.holterService.addNotes(this.currentHolter.id, "Requisition Created", null)
              this.holterService.getHolter(this.currentHolter.id).then(holter => this.populateHolter(holter));
            });
          });

        }
        else if (result.staffNotes){
          this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
            this.holterService.addNotes(this.currentHolter.id, result.staffNotes, null)
            dtInstance.draw(false);
            this.holterService.getHolter(this.currentHolter.id).then(holter => this.populateHolter(holter));
          });
        }
      });
    });
  }

  // NOT BEING USED PER PORT 336
  public addFaxNotes() {
    const modalRef = this.modalService.open(ModalStaffNotesComponent, {size:'md'});
    modalRef.result.then(result => {
      this.holterService.addNotes(this.currentHolter.id, null, result).then(() => {
        this.holterService.getHolter(this.currentHolter.id).then(holter => this.populateHolter(holter));
      });
    });
  }

  public isClosed(): boolean {
    if (!this.currentHolter) {
      return true;
    }
    return !!this.currentHolter.closed_by;
  }


  public openCreateFaxModal() {
    if (!!this.currentHolter.closed_by) {
      return;
    }
    const modalRef = this.modalService.open(ModalCreateFaxComponent, {size:'md'});
    modalRef.componentInstance.holter = this.currentHolter;
    modalRef.componentInstance.doctor = this.currentDoctor;
    modalRef.result.then(result => {
      let toBatch = {
        final: false,
        notes: true,
        notes_req: result.faxNotesReq ? result.faxNotesReq : false,
        notes_data: result.faxNotesData ? result.faxNotesData : false,
        notes_pacemaker: result.faxNotesPacemaker ? result.faxNotesPacemaker : false,
        req: false,
        tracings:false,
      }

      if (result.createFax){
        this.holterService.scheduleFax(this.currentHolter.id, {
          doctor: result.referring_doctor,
          fax_number: result.fax,
          fax_notes: result.faxNotes,
          to_fax: toBatch,
          // holter_additional_fax_numbers: result.faxNumbers ? JSON.stringify(result.faxNumbers) : null,
        }).then(() => {
          this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
            let faxedList = "";
            result.faxNotesReq ? ((faxedList == "") ? faxedList = faxedList + "Patient Req" : null): null;
            result.faxNotesData ? ((faxedList == "") ? faxedList = faxedList + "Patient Data" : faxedList = faxedList + ", Patient Data" ): null;
            result.faxNotesPacemaker ? ((faxedList == "") ? faxedList = faxedList + "Pacemaker" : faxedList = faxedList + ", Pacemaker" ): null;
            this.holterService.addNotes(this.currentHolter.id, null, result.faxNotes);
            this.holterService.addNotes(this.currentHolter.id, "Fax sent for " + faxedList, null);
            dtInstance.draw(false);
            this.holterService.getHolter(this.currentHolter.id).then(holter => {
              this.populateHolter(holter);
            });
          });
        });
      }
      if (result.faxNotes){
        this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
          this.holterService.addNotes(this.currentHolter.id, null, result.faxNotes);
          dtInstance.draw(false);
          this.holterService.getHolter(this.currentHolter.id).then(holter => this.populateHolter(holter));
        });
      }
      if (result.faxNumbers || result.faxWithTracings) {
        this.holterService.updateHolter(this.currentHolter.id, {
          fax_numbers: result.faxNumbers ? result.faxNumbers : null,
          flag_fax_with_tracing: result.faxWithTracings ? result.faxWithTracings : null,
        }).then(() => {
          this.holterService.getHolter(this.currentHolter.id).then(holter => {
            this.currentHolter = holter;
          });
        });
      }

    });
  }

  public openFlagModal() {
    const token = this.authService.getToken(true);
    let data = {}
    if (this.multiSelected){
      data = {
        multi: this.multiSelected,
        fax_repair: true,
      }
    }
    else {
      data = {
        reserve: this.hasReserved(this.currentHolter.reservations, token.email)? true : false,
        canSkip: this.currentHolter.state == ("EDITING") ? true : false,
        canEdit: this.currentHolter.state == ("READING") ? true : false,
        pacemakerInquiry: this.currentHolter.flag_pacemaker_inquiry ? true: false,
        req: this.currentHolter.req_resolved_by ? true : false,
        multi: this.multiSelected,
        fax_repair: true,
      }
    }
    const modalRef = this.modalService.open(ModalFlagComponent, {size:'md'});
    modalRef.componentInstance.holter = data;
    modalRef.result.then(result => {
      if (result && !this.multiSelected) {
        result.skipToReading ? this.skipToReading() : null;
        result.backToEditing ? this.backToEditing() : null;
        result.pacemakerInquiry ? this.flagPacemakeInquiry(null) : null;
        result.pacemakerResolved ? this.flagPacemakeInquiry(null) : null;
        result.reqResolved ? this.markAsReqResolved() : null;
      }
      else if (result && this.multiSelected){
        result.skipToReading ? this.skipToReading() : null;
        result.backToEditing ? this.backToEditing() : null;
        result.pacemakerInquiry ? this.flagPacemakeInquiry(true) : null;
        result.pacemakerResolved ? this.flagPacemakeInquiry(false) : null;
        result.reqResolved ? this.markAsReqResolved() : null;
      }
    });
  }

  public openCaseClosedModal() {
    if (!!this.currentHolter.closed_by) {
      return;
    }

    this.closeResult = "";
    const modalRef = this.modalService.open(ModalClosedComponent, {size:'md'});
    modalRef.componentInstance.modalResult = this.closeResult;
    modalRef.result.then(result => {
      if (result) {
        return this.holterService.addNotes(this.currentHolter.id, result, null).then(() => this.closeHolter());
      } else {
        return this.closeHolter();
      }
    });
  }

  public flagPacemakeInquiry(inquireNeeded : boolean) {
    if (this.multiSelected){
      this.datatableElement?.dtInstance.then((dtInstance: any) => {
        const updatePromises = [];
        dtInstance.rows(".selected").every((idx) => {
          const entry = dtInstance.row(idx).data();
          const expandedFlags = HolterService.expandFlags(entry["holter:flags"]);
          if (inquireNeeded && expandedFlags["holter:flag_pacemaker_inquiry"]) {
            return;
          } else if (!inquireNeeded && !expandedFlags["holter:flag_pacemaker_inquiry"]){
            return;
          }
          let notes;
          inquireNeeded ? notes = "Pacemaker Inquiry" : notes = "Pacemaker Resolved";
          updatePromises.push( this.holterService.updateHolter(entry["holter:id"], {
            flag_pacemaker_inquiry: inquireNeeded,
          }));
          updatePromises.push(this.holterService.addNotes(entry["holter:id"], notes, null));
        });
        Promise.all(updatePromises).then(() => dtInstance.draw(false));
        this.holterService.getHolter(this.currentHolter.id).then(holter => this.populateHolter(holter));
        this.refreshSmartFilters();
      });
    } else {
      this.holterService.updateHolter(this.currentHolter.id, {
        flag_pacemaker_inquiry: !this.currentHolter.flag_pacemaker_inquiry,
      }).then(() => {
        let notes;
        !this.currentHolter.flag_pacemaker_inquiry ? notes = "Pacemaker Inquiry" : notes = "Pacemaker Resolved";
        this.holterService.addNotes(this.currentHolter.id, notes, null).then(() => {
          this.holterService.getHolter(this.currentHolter.id).then(holter => this.populateHolter(holter));
          this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw(false);
          });
        });

      });
    }
  }

  public markAsReqResolved() {
    this.datatableElement?.dtInstance.then((dtInstance: any) => {
      const updatePromises = [];
      dtInstance.rows(".selected").every((idx) => {
        const entry = dtInstance.row(idx).data();
        if (entry["holter:req_resolved_by"] || entry["holter:requisition_id"]) {
          return;
        }
        updatePromises.push(this.holterService.reqResolved(entry["holter:id"]));
        updatePromises.push(this.holterService.addNotes(entry["holter:id"], "Requisition Resolved", null));
      });
      Promise.all(updatePromises).then(() => dtInstance.draw(false));
      this.holterService.getHolter(this.currentHolter.id).then(holter => this.populateHolter(holter));
      this.refreshSmartFilters();
    });
  }

  public skipToReading() {
    this.datatableElement?.dtInstance.then((dtInstance: any) => {
      const updatePromises = [];
      dtInstance.rows(".selected").every((idx) => {
        const entry = dtInstance.row(idx).data();
        if (entry["holter:state"] != "EDITING" ) {
          return;
        }
        updatePromises.push(this.holterService.skipToReading(entry["holter:id"]));
        updatePromises.push(this.holterService.addNotes(entry["holter:id"], "Skip Interpretation", null));
      });
      Promise.all(updatePromises).then(() => dtInstance.draw(false));
      this.holterService.getHolter(this.currentHolter.id).then(holter => this.populateHolter(holter));
      this.refreshSmartFilters();
    });
  }

  public backToEditing() {
    this.datatableElement?.dtInstance.then((dtInstance: any) => {
      const updatePromises = [];
      dtInstance.rows(".selected").every((idx) => {
        const entry = dtInstance.row(idx).data();
        if (entry["holter:state"] != "READING" ) {
          return;
        }
        updatePromises.push(this.holterService.backToEditing(entry["holter:id"]));
        updatePromises.push(this.holterService.addNotes(entry["holter:id"], "Change Interpretation", null));
      });
      Promise.all(updatePromises).then(() => dtInstance.draw(false));
      this.holterService.getHolter(this.currentHolter.id).then(holter => this.populateHolter(holter));
      this.refreshSmartFilters();
    });
  }

  private downloadPDF(url: any, holter: Holter, fileType: string, extension: string) {
    this.holterService.downloadFile(url)
      .subscribe(blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = holter.patient_last_name + "_" + holter.patient_first_name + "_" + holter.hookup_date + "_" + fileType + "." + extension;
        a.click();
        URL.revokeObjectURL(objectUrl);
      });
  }

  private getPdfViewerSpacing(){
    const pdfsize = JSON.parse(localStorage.getItem('readingSpace'));
    pdfsize ? this.pdfSizeLeft = pdfsize : this.pdfSizeLeft = 50;
    this.pdfSizeRight = 100 - this.pdfSizeLeft;
  }

  public onDragEnd(e: { gutterNum: any; sizes: Array<any> }) {
    localStorage.setItem('readingSpace', e.sizes[0]);
  }

  public faxType(fileType: string) {
    let types = ""
    if (fileType.includes("final")){
      types = types + "FINAL ";
    }
    if (fileType.includes("tracings")){
      types = types + "TRACINGS ";
    }
    if (fileType.includes("preliminary")){
      types = types + "PRELIMINARY ";
    }
    if (fileType.includes("notes_req")){
      types = types + "REQ FORM ";
    }
    else if(fileType.includes("req")){
      types = types + "REQ ";
    }
    if (fileType.includes("notes_data")){
      types = types + "PATIENT DATA ";
    }
    if (fileType.includes("notes_pacemaker")){
      types = types + "PACEMAKER ";
    }
    return types;
  }

  public showMedtraceDisplay($event){
    $event.preventDefault();
    const modalRef = this.modalService.open(ModalMedtraceDisplayComponent, { size:'lg'});
    modalRef.componentInstance.medtrace = this.currentMedtrace;
    modalRef.componentInstance.type = "display_monitor_traffic";
    // return false;
  }
}
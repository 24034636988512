<div class="md-width">

    <div class="row sub-header">
      <div class="col-12">
        <div class="text-center">
          <br>
          <ul class="list-inline">
            <li class="list-inline-item table-filter"
              (click)="navigateTo('/holter/detail/debug/' + holterId())"
              [class.table-filter-selected]="urlContains('debug')">
                <div class="col-xs-3"><i class="fa fa-bug"></i></div>
                <div class="col-xs-9 text-center"><p>Debug</p></div>
            </li>
            <li class="list-inline-item table-filter"
              (click)="navigateTo('/holter/detail/medflux/' + holterId())"
              [class.table-filter-selected]="urlContains('medflux')">
                <div class="col-xs-3"><i class="fa fa-edit"></i></div>
                <div class="col-xs-9 text-center"><p>Medflux</p></div>
            </li>
            <li class="list-inline-item table-filter"
              (click)="navigateTo('/holter/detail/report/' + holterId())"
              [class.table-filter-selected]="urlContains('report')">
                <div class="col-xs-3"><i class="fa fa-file-text-o"></i></div>
                <div class="col-xs-9 text-center"><p>Report</p></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <router-outlet></router-outlet>
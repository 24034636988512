export class Holter {

  public id: number | null = null;
  public billing_date: number | null = null;
  public billed_at: number | null = null;
  public hookup_date: number | null = null;
  public scan_date: number | null = null;
  public duration: number | null = null;
  public delivery: string | null = null;
  public computer: string | null = null;
  public state: string | null = null;
  public updated_at: number | null = null;
  public submitted_by: string | null = null;
  public rejected_by: string | null = null;
  public closed_by: string | null = null;
  public req_resolved_by: string | null = null;
  public report_prepared_at: string | null = null;
  public duplicate_count: number | null = null;
  public uploaded_files: string | null = null;
  public synced_at: number | null = null;
  public sync_requested_at: number | null = null;
  public addressed_at: number | null = null;
  public addressed_by: number | null = null;
  public called_at: number | null = null;

  public flag_verified: boolean | null = null;
  public flag_pacemaker_inquiry: boolean | null = null;
  public flag_no_data: boolean | null = null;
  public flag_monitor_no_data: boolean | null = null;
  public flag_no_req: boolean | null = null;
  public flag_no_backup: boolean | null = null;
  public flag_no_tracings: boolean | null = null;
  public flag_invalid_referring: boolean | null = null;
  public flag_invalid_healthcard: boolean | null = null;
  public flag_invalid_dob: boolean | null = null;
  public flag_need_repair: boolean | null = null;
  public flag_abnormal: boolean | null = null;
  public flag_fax_with_tracing: boolean | null = null;

  public upload_email: string | null = null;
  public upload_computer_path: string | null = null;
  public upload_computer: string | null = null;
  public upload_computer_slot: string | null = null;
  public s3_key: string | null = null;
  public report_clinic: string | null = null;
  public notes: string | null = null;
  public fax_notes: string | null = null;
  public fax_numbers: string | null = null;
  public medflux_data: string | null = null;
  public general_comments: string | null = null;
  public specific_comments: string | null = null;
  public summary: string | null = null;
  public additional_comments: string | null = null;
  public report_revision_lines: string | null = null;

  public device_id: number | null = null;
  public device_serial_number: string | null = null;
  public device_measurement: string | null = null;

  public dx_code_id: number | null = null;
  public dx_code_code: string | null = null;
  public dx_code_description: string | null = null;

  public patient_id: number | null = null;
  public patient_last_name: string | null = null;
  public patient_first_name: string | null = null;
  public patient_birthdate: string | null = null;
  public patient_health_card: string | null = null;
  public patient_health_card_code: string | null = null;
  public patient_gender: string | null = null;
  public patient_height: number | null = null;
  public patient_weight: number | null = null;

  public referring_doctor_id: number | null = null;
  public referring_doctor_last_name: string | null = null;
  public referring_doctor_first_initials: string | null = null;
  public referring_doctor_name: string | null = null;
  public referring_doctor_title: string | null = null;
  public referring_doctor_billing_number: string | null = null;
  public referring_doctor_billing_code: string | null = null;
  public referring_doctor_roles: string | null = null;
  public referring_doctor_fax: string | null = null;
  public referring_doctor_phone: string | null = null;

  public analyst_email: string | null = null;
  public analyst_firstname: string | null = null;
  public analyst_lastname: string | null = null;

  public editor_email: string | null = null;
  public editor_firstname: string | null = null;
  public editor_lastname: string | null = null;

  public clinic_id: number | null = null;
  public clinic_name: string | null = null;
  public clinic_street: string | null = null;
  public clinic_city: string | null = null;
  public clinic_province: string | null = null;
  public clinic_postal_code: string | null = null;
  public clinic_fax: string | null = null;
  public clinic_phone_number: string | null = null;
  public clinic_additional_fax_numbers: string | null = null;
  public clinic_flag_no_fax: boolean | null = null;

  public reading_doctor_id: number | null = null;
  public reading_doctor_last_name: string | null = null;
  public reading_doctor_first_initials: string | null = null;
  public reading_doctor_name: string | null = null;
  public reading_doctor_title: string | null = null;
  public reading_doctor_billing_number: string | null = null;
  public reading_doctor_roles: string | null = null;

  public staff_id: number | null = null;
  public staff_last_name: string | null = null;
  public staff_first_name: string | null = null;

  public requisition_id: any = null;
  public requisition_file_name: string | null = null;
  public requisition_first_name: string | null = null;
  public requisition_last_name: string | null = null;
  public requisition_closed_by: string | null = null;
  public requisition_date: any = null;
  public requisition_status: any = null;
  public requisition_fax_notes: any = null;
  public requisition_staff_notes: any = null;
  public requisition_comments: any = null;
  public erequisition_id: any = null;

  public faxes: any[] | null = null;
  public skip_faxing_at: string | null = null;
  public skip_follow_up_at: string | null = null;
  public reservations: any[] | null = null;

  public needFlattenTracing: any = null;
  public needFlattenBatch: any = null;
  public needReannotateAt: any = null;

  public pat_data: any = null;
}
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Event, ActivatedRoute, NavigationEnd, Router, ParamMap } from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { FeatureToggleService } from "../../providers/feature-toggle.service";

@Component({
  selector: 'app-home',
  templateUrl: './holter.component.html',
  styleUrls: ['./holter.component.scss']
})
export class HolterComponent implements OnInit {

  public showSearchTab$: Observable<boolean>;
  public showPendingTab$: Observable<boolean>;
  public showEditingTab$: Observable<boolean>;
  public showVisitTab$: Observable<boolean>;
  public showReservedTab$: Observable<boolean>;
  public showReadingTab$: Observable<boolean>;
  public showRequisitionTab$: Observable<boolean>;
  public showRepairTab$: Observable<boolean>;
  public showBillingTab$: Observable<boolean>;
  public showFaxTab$: Observable<boolean>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private featureToggleService: FeatureToggleService
  ) {
  }

  public ngOnInit() {
    this.showSearchTab$ = this.route.paramMap.pipe(switchMap(() => this.featureToggleService.showHolterSubtab("/holter/search")));
    this.showPendingTab$ = this.route.paramMap.pipe(switchMap(() => this.featureToggleService.showHolterSubtab("/holter/pending")));
    this.showEditingTab$ = this.route.paramMap.pipe(switchMap(() => this.featureToggleService.showHolterSubtab("/holter/editing")));
    this.showVisitTab$ = this.route.paramMap.pipe(switchMap(() => this.featureToggleService.showHolterSubtab("/holter/visit")));
    this.showReservedTab$ = this.route.paramMap.pipe(switchMap(() => this.featureToggleService.showHolterSubtab("/holter/reserved")));
    this.showReadingTab$ = this.route.paramMap.pipe(switchMap(() => this.featureToggleService.showHolterSubtab("/holter/reading")));
    this.showRequisitionTab$ = this.route.paramMap.pipe(switchMap(() => this.featureToggleService.showHolterSubtab("/holter/requisition")));
    this.showRepairTab$ = this.route.paramMap.pipe(switchMap(() => this.featureToggleService.showHolterSubtab("/holter/repair")));
    this.showBillingTab$ = this.route.paramMap.pipe(switchMap(() => this.featureToggleService.showHolterSubtab("/holter/billing")));
    this.showFaxTab$ = this.route.paramMap.pipe(switchMap(() => this.featureToggleService.showHolterSubtab("/holter/fax")));
  }

  public navigateTo(url: string): void {
    this.router.navigateByUrl(url);
  }

  public urlContains(str: string): boolean {
    return this.router.url.indexOf(str) > -1;
  }

}
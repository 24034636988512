import { Component } from '@angular/core';
import { version } from '../../package.json';
import { Event, ActivatedRoute, NavigationEnd, Router, ParamMap } from "@angular/router";

import { AuthService } from "./providers/auth.service";
import { CookieService } from 'ngx-cookie-service';

import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'frontend-portal';
  private logoutTimer: any = null;
  public page: string = "/physician";
  public versionString: string = "";

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {

    // track which page was navigated to
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.page = (<NavigationEnd>event).url;
      }
    });

    console.log('environment', environment);

    // poll the token for session expirations
    setInterval(() => this.logoutExpiredSessions(), 10000);

    this.versionString = version;
  }

  // automatically logout when the cookie expires
  private logoutExpiredSessions(): void {

    const token: any = this.authService.getToken(true);
    if (!token) {
      return;
    }

    const now: any = new Date();
    const expiration: any = new Date(token.exp * 1000);

    // if (this.logoutTimer === null) {
    //   console.log("Session is only valid for " + Math.round((expiration - now) / 1000 / 60) + " more minutes.");
    // }
    // console.log("Setting timer for " + ((expiration - now) / 1000) + " seconds");

    clearTimeout(this.logoutTimer);
    this.logoutTimer = setTimeout(() => this.logout(), expiration - now);
  }

  public logout(): void {
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

  public getName(): string {
    const decodedToken: any = this.authService.getToken(true);
    return decodedToken ? decodedToken.first_name + " " + decodedToken.last_name : "";
  }

  // determine if the sub menu should be hidden
  public shouldHide(menu: string): boolean {
    const decodedToken: any = this.authService.getToken(true);
    if (!decodedToken) {
      return true;
    }

    // limit cardio study only pages
    const cardiostudyOnlyPages: string[] = ['analytics', 'physician', 'employee', 'patient', 'fax', 'holter'];
    if (!decodedToken.admin && cardiostudyOnlyPages.indexOf(menu) > -1) {
      return true;
    }

    // see which page the user has access to
    let resources: string[] = [];
    for (const role of decodedToken.roles) {
      for (const resource of role.permissions.portal) {
        resources.push(resource.toLowerCase());
      }
    }

    // compare with the requested resource
    if (resources.indexOf("*") > -1) {
      return false;
    }

    let hasPermissionToRootMenu = false;
    for (const resource of resources) {
      if (resource.startsWith(menu.toLowerCase())) {
        hasPermissionToRootMenu = true;
      }
    }
    return !hasPermissionToRootMenu;
  }

  public hideSideMenu(): boolean {
    const decodedToken: any = this.authService.getToken(true);
    return !decodedToken
  }

  public openWindow(url: string){
    window.open(url, "_blank" );
  }
}

import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { ClinicService } from "../../providers/clinic.service";
import { HolterService } from "../../providers/holter.service";
import { FaxService } from "../../providers/fax.service";

import { formatLocaleDateSingle, formatNotesMultiple  } from "../../utils/format";

@Component({
  selector: 'app-modal-addressed',
  templateUrl: './modal-addressed.component.html',
  styleUrls: ['./modal-addressed.component.scss']
})
export class ModalAddressedComponent implements OnInit {
  @Input() public holter;
  public formGroup: FormGroup;
  public showPdfPreview: any = "";
  public pdfSrc: any = null;
  public loadingPdf: boolean = false;
  public titleString: any = "";
  @ViewChild("attemptLogDiv") attemptLogDiv: ElementRef;

  constructor(
    public activeModal: NgbActiveModal,
    private clinicService: ClinicService,
    private holterService: HolterService,
    private faxService: FaxService,
  ) {
    this.formGroup = new FormGroup({
      PhoneNumber: new FormControl("", [Validators.required, Validators.pattern(/^\d{10}$/)]),
      FaxNumber: new FormControl("", [ ]),
      Addressed: new FormControl("", [ ]),
      StaffNotes: new FormControl("", [ ]),
      TextStaffNotes: new FormControl("", [ ]),
      ReferringDoctor: new FormControl("", [ ]),
      Clinic: new FormControl("", [ ]),
    });
  }

  ngOnInit(): void {
    this.populateModal(this.holter);
  }

  private populateModal(holter) {
    this.formGroup.reset();
    this.formGroup.patchValue({
      PhoneNumber: holter?.clinic_phone_number,
      FaxNumber: holter?.clinic_fax,
      StaffNotes: formatNotesMultiple([holter.notes, holter.requisition_staff_notes]),
      ReferringDoctor: holter?.referring_doctor_name,
      Clinic: holter?.clinic_name,
    });
    this.titleString = holter.patient_last_name + ", " + holter.patient_first_name + " (" + holter.patient_health_card + ")";
    this.toggleFileTypeFilter('req');
  }

  public update() {
    const updatePromises = [];
    updatePromises.push(this.holterService.addressedFax(this.holter.id, {addressed: this.formGroup.get("Addressed")?.value}));
    let data = {
      phone_number: this.formGroup.get("PhoneNumber")?.value,
    }
    const staffNotesControl = this.formGroup.get('TextStaffNotes');
    if (staffNotesControl.dirty) {
      data["staff_notes"] = this.formGroup.get("TextStaffNotes")?.value;
    }
    let output = this.logisticsNotesFormat(data);
    updatePromises.push(this.holterService.addNotes(this.holter.id, output, null));
    const phoneControl = this.formGroup.get('PhoneNumber');
    if (phoneControl.dirty) {
      let data = {
        id: this.holter.clinic_id,
        name: this.holter.clinic_name,
        phone: this.formGroup.get("PhoneNumber")?.value,
      }
      updatePromises.push(this.clinicService.updateClinic(this.holter.clinic_id, data));
      updatePromises.push(this.clinicService.addNotes(this.holter.clinic_id, "Clinic phone number updated from " + this.holter.clinic_phone_number + "to " + this.formGroup.get("PhoneNumber")?.value));
      updatePromises.push(this.holterService.addNotes(this.holter.id, "Clinic phone number updated from " + this.holter.clinic_phone_number + "to " + this.formGroup.get("PhoneNumber")?.value, null));
    }
    Promise.all(updatePromises).then(() => {
      this.holterService.getHolter(this.holter.id).then(holter => {
        this.holter = holter;
        this.formGroup.patchValue({
          StaffNotes: formatNotesMultiple([holter.notes, holter.requisition_staff_notes]),
          TextStaffNotes: "",
          Addressed: false,
        });
        this.formGroup.get("TextStaffNotes").markAsPristine();
      });
    });
  }

  private logisticsNotesFormat(info: any) {
    let output = "";
    if (info["staff_notes"]) {
      output = "\n[Logistics]\nNote: " + info["staff_notes"];
    }
    else {
      output = "\n[Logistics]\nNote: Called";
    }
    if (this.holter.addressed_at == null && this.formGroup.get("Addressed")?.value == true){
      output = output + " and addressed"
    }
    return output;
  }

  public toggleFileTypeFilter(fileType) {
    if (fileType == "pdf") {
      if (this.holter) this.showTracings();
      this.showPdfPreview = "tracings"
    }
    if (fileType == "req") {
      if (this.holter) this.showRequisition();
      this.showPdfPreview = "requisition"
    }
    if (fileType == "report") {
      if (this.holter) this.showReport();
      this.showPdfPreview = "report"
    }
  }

  private showTracings() {
    return this.holterService.getTracings(this.holter.id, "annotated").then(url => {
      this.pdfSrc = url;
      this.showPdfPreview = "tracings";
      setTimeout(() => {
        this.loadingPdf = false;
      }, 200);
    });
  }

  private showRequisition() {
    this.holterService.getRequisition(this.holter.id).then(url => {
      this.pdfSrc = url;
      this.showPdfPreview = "requisition";
      setTimeout(() => {
        this.loadingPdf = false;
      }, 200);
    });
  }

  private showReport() {
    this.holterService.getMonitorReport(this.holter.id)
      .then((base64Data: string) => {
        this.pdfSrc = HolterService.base64ToArrayBuffer(base64Data);
        setTimeout(() => {
          this.loadingPdf = false;
        }, 200);
      });
  }

  public faxTypeArray(fileType: string[]) {
    let types = ""
    if (fileType.indexOf("final") !== -1){
      types = types + "FINAL ";
    }
    if (fileType.indexOf("tracings") !== -1){
      types = types + "TRACINGS ";
    }
    if (fileType.indexOf("preliminary") !== -1){
      types = types + "PRELIMINARY ";
    }
    if (fileType.indexOf("notes_req") !== -1){
      types = types + "REQ FORM ";
    }
    else if(fileType.indexOf("req") !== -1){
      types = types + "REQ ";
    }
    if (fileType.indexOf("notes_data") !== -1){
      types = types + "PATIENT DATA ";
    }
    if (fileType.indexOf("notes_pacemaker") !== -1){
      types = types + "PACEMAKER ";
    }
    return types;
  }

  public fax() {
    let fax_req = false;
    let fax_tracings = false;
    this.showPdfPreview == "req" ? fax_req = true : null;
    this.showPdfPreview == "tracings" ? fax_tracings = true : null;
    let toBatch = {
      final: true,
      notes: false,
      notes_req: false,
      notes_data: false,
      notes_pacemaker: false,
      req: fax_req,
      tracings: fax_tracings,
    }
    let toBatchString;
    toBatchString = Object.keys(toBatch).filter(key => toBatch[key] === true);
    this.holterService.scheduleFax(this.holter.id, {
      doctor: this.formGroup.get("ReferringDoctor")?.value,
      fax_number: this.formGroup.get("FaxNumber")?.value,
      fax_notes: null,
      holter_additional_fax_numbers: null,
      to_fax: toBatch,
    }).then(fax => {
      if (fax) {
        this.faxService.actionRingCentral(fax.id, "force", 0);
        let data = {
          staff_notes: toBatchString + " faxed out",
          phone_number: this.formGroup.get("PhoneNumber")?.value,
        }
        let output = this.logisticsNotesFormat(data);
        this.holterService.addNotes(this.holter.id, output, null).then(() => {
          this.holterService.getHolter(this.holter.id).then(holter => {
            this.holter = holter;
            this.formGroup.patchValue({
              StaffNotes: formatNotesMultiple([holter.notes, holter.requisition_staff_notes]),
            });
          });
        });
      }
    });
  }
}

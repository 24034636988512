import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment";

@Pipe({name: 'reqFormat'})
export class ReqFormatPipe implements PipeTransform {
  transform(req: any): string {
  // write your custom logic here
  return ((req.last_name || req.first_name) ? (req.last_name + ", " + req.first_name) : "") +
          (req.date ? (" - " + moment(req.date).format("YYYY-MM-DD")) : "") + (req.status ? (" " + req.status) : "")
  }
}
<div>
  <div class="row sub-main">

    <div class="page-title">
      <div class="title_left">
        <h3>Holter - Pending</h3>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="x_panel">
      <div class="x_content">
        <div class="row split-container">
          <as-split direction="horizontal" (dragEnd)="onDragEnd($event)">

            <as-split-area [size]="pdfSizeLeft">

        <br>

        <form class="form-horizontal form-label-left" [formGroup]="formGroup">
          <div class="row">

            <div class="col-5 px-0 ml-3 mr-n3">
              <div class="form-group form-display row">
                <div class="col form-group">
                  <label>Last Name</label>
                  <input type="text" class="form-control" placeholder="" formControlName="LastName" [attr.disabled]="true">
                </div>
                <div class="col form-group">
                  <label>First Name</label>
                  <input type="text" class="form-control" placeholder="" formControlName="FirstName" [attr.disabled]="true">
                </div>
                <div class="col form-group">
                  <label>Health Card</label>
                  <input type="text" class="form-control" placeholder="" formControlName="HealthCard" [attr.disabled]="true">
                </div>
              </div>
              <div class="form-group form-display row">
                <div class="col form-group">
                  <label>Hookup Date</label>
                  <input type="text" class="form-control" placeholder="YYYY/MM/DD" formControlName="HookupDate" [attr.disabled]="true">
                </div>
                <div class="col form-group">
                  <label>Scan Date</label>
                  <input type="text" class="form-control" placeholder="YYYY/MM/DD" formControlName="ScanDate" [attr.disabled]="true">
                </div>
                <div class="col form-group">
                  <label>Tech</label>
                  <input type="text" class="form-control" placeholder="" formControlName="Tech" [attr.disabled]="true">
                </div>
              </div>
              <div class="form-group form-display row">
                <div class="col form-group">
                  <label>Reading Doctor <i [hidden]="!errorStrings.readingDr" class="conflict-message-inline">{{errorStrings.readingDr}}</i></label>
                  <input type="text" class="form-control" placeholder="" [class.has-conflict]="!!errorStrings.readingDr" formControlName="ReadingDoctor" list="readinglist" [attr.disabled]="(currentHolter?.state != 'PENDING') ? '' : null">
                    <datalist id="readinglist">
                      <option *ngFor="let dr of readingDoctorOptions" [value]="dr.value">{{dr.text}}</option>
                    </datalist>
                </div>
                <div class="col-6 form-group">
                  <label>Referring Doctor</label>
                  <input type="text" class="form-control" placeholder="" formControlName="ReferringDoctor" [ngbTypeahead]="searchReferringDoctor" [resultFormatter]="searchReferringDoctorFormatter" [inputFormatter]="searchReferringDoctorInputFormatter" [attr.disabled]="(currentHolter?.state != 'PENDING') ? '' : null">
                </div>
              </div>
              <div class="form-group form-display row">
                <div class="col form-group">
                  <label>City <i [hidden]="!errorStrings.city" class="conflict-message-inline">{{errorStrings.city}}</i></label>
                  <input type="text" class="form-control" placeholder="" [class.has-conflict]="!!errorStrings.city" formControlName="City" [attr.disabled]="(currentHolter?.state != 'PENDING') ? '' : null">
                </div>
                <div class="col form-group">
                  <label>Clinic Name</label>
                  <input type="text" class="form-control" placeholder="" formControlName="Clinic" [attr.disabled]="(currentHolter?.state != 'PENDING') ? '' : null">
                </div>
              </div>
              <div class="col form-group">
                <form [formGroup]="checkBoxFormGroup">
                  <div class="form-group form-display row ml-4 mr-n4">
                    <div class="col form-group">
                      <input class="form-check-input" type="checkbox" name="check0" id="check0" formControlName="Reserved" [attr.disabled]="''">
                      <label class="form-check-label" for="check0">Reserved</label>
                    </div>
                    <div class="col form-group">
                      <input class="form-check-input" type="checkbox" name="check1" id="check1" formControlName="SkipToReading" [attr.disabled]="''">
                      <label class="form-check-label" for="check1">Skip Interpretation</label>
                    </div>
                    <div class="col form-group">
                      <input class="form-check-input" type="checkbox" name="check2" id="check2" formControlName="FlagNoData" [attr.disabled]="multiSelected ? '' : null">
                      <label class="form-check-label" for="check2">No Monitor Data</label>
                    </div>
                  </div>
                  <div class="form-group form-display row ml-4 mr-n4">
                    <div class="col form-group">
                      <input class="form-check-input" type="checkbox" name="check3" id="check3" formControlName="FlagPacemakerInquiry" [attr.disabled]="multiSelected ? '' : null">
                      <label class="form-check-label" for="check3">Pacemaker Inq.</label>
                    </div>
                    <div class="col form-group">
                      <input class="form-check-input" type="checkbox" name="check4" id="check4" formControlName="FlagNoReq" [attr.disabled]="multiSelected || currentHolter?.requisition_id ? '' : null">
                      <label class="form-check-label" for="check4">No Requisition</label>
                      <label class="req-resolved-indicator">{{(!!this.currentHolter && this.currentHolter.req_resolved_by) ? '&#40;' + 'Req Resolved' + '&#41;': '&nbsp;'}}</label>
                    </div>
                    <div class="col form-group">
                      <input class="form-check-input" type="checkbox" onclick="return false;" name="check5" id="check5" formControlName="FlagNoTracings" [attr.disabled]="true">
                      <label class="form-check-label" for="check5">No Report Tracings</label>
                    </div>

                  </div>
                </form>
              </div>
            </div>
            <div class="col-5 px-0 ml-3 mr-n3">
              <div class="form-group form-display row">
                <div class="col-2 form-group">
                  <label>VC</label>
                  <input type="text" class="form-control" placeholder="" formControlName="HealthCardVersion" [attr.disabled]="(currentHolter?.state != 'PENDING') ? '' : null">
                </div>
                <div class="col-2 form-group">
                  <label>Dur. <i [hidden]="!errorStrings.duration" class="conflict-message-inline">{{errorStrings.duration}}</i></label>
                  <input type="text" class="form-control" placeholder="" [class.has-conflict]="!!errorStrings.duration" formControlName="Duration" [attr.disabled]="(currentHolter?.state != 'PENDING') ? '' : null">
                </div>
                <div class="col-4 form-group">
                  <label>Birthdate</label>
                  <input type="text" class="form-control" placeholder="YYYY/MM/DD" formControlName="Birthdate" [attr.disabled]="true">
                </div>
                <div class="col-4 form-group">
                  <label>Gender <i [hidden]="!errorStrings.gender" class="conflict-message-inline">{{errorStrings.gender}}</i></label>
                  <select class="form-control" data-live-search="true" [class.has-conflict]="!!errorStrings.gender" formControlName="Gender" [attr.disabled]="(currentHolter?.state != 'PENDING') ? '' : null">
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                  </select>
                </div>
              </div>
              <div class="form-group form-display row">
                <div class="col form-group">
                  <label>DX Code</label>
                  <select class="form-control" data-live-search="true" (mouseleave)="verboseDxcode = false" (mouseenter)="verboseDxcode = true" formControlName="DxCode" [attr.disabled]="(currentHolter?.state != 'PENDING') ? '' : null">
                    <option *ngFor="let codeInfo of DXCODE_OPTIONS" [value]="codeInfo.code">{{verboseDxcode ? codeInfo.text : codeInfo.code}}</option>
                  </select>
                </div>
                <div class="col form-group">
                  <label>Delivery</label>
                  <input type="text" class="form-control" placeholder="" formControlName="Delivery" [attr.disabled]="(currentHolter?.state != 'PENDING') ? '' : null">
                </div>
                <div class="col form-group">
                  <label>Device SN</label>
                  <input type="text" class="form-control" placeholder="" formControlName="Device" [attr.disabled]="true">
                </div>
              </div>
              <div class="form-group form-display row">
                <div class="col form-group">
                  <label>Attached Req.</label>
                  <input type="text" class="form-control" placeholder="{{attachedReqPlaceHolder}}" formControlName="Requisition" [ngbTypeahead]="searchRequisition" [resultFormatter]="searchRequisitionFormatter" (selectItem)="selectedReq($event)" [inputFormatter]="searchRequisitionInputFormatter" [attr.disabled]="(currentHolter?.state != 'PENDING') ? '' : null">
                </div>
              </div>
              <div class="form-group form-display row">
                <div class="col form-group">
                  <label>Requisitions (For Updating)</label>
                  <div class="req-container">
                    <ul>
                      <li class="overflow-auto" *ngFor="let req of reqAuto" (click)="toggleSelection(req, 1)" [class.item-selected]="req.id === reqID" (click)="selectedSearchReq(req)" [class.disabled]="currentHolter?.state != 'PENDING'">{{req | reqFormat}}</li>
                    </ul>
                  </div>

                  <!--
                  <select class="form-control" formControlName="RequisitionSearch" [(ngModel)]="currentReq" (ngModelChange)="selectedSearchReq(currentReq)" list="reqs_list" [attr.disabled]="(currentHolter?.state != 'PENDING') ? '' : null">
                    <option></option>
                    <option *ngFor="let req of reqAuto" [ngValue]="req">{{req | reqFormat}}</option>
                  </select>

                    <li id="reqs_list" *ngFor="let req of reqAuto" (click)="selectedReq(req)">{{req | reqFormat}}</li>
                  <input type="text" class="form-control" placeholder="Type to search" formControlName="RequisitionSearch" [(ngModel)]="currentReq" (ngModelChange)="selectedReq(currentReq)" list="reqs_list" [attr.disabled]="(currentHolter?.state != 'PENDING') ? '' : null">
                  <datalist id="reqs_list" >
                    <option *ngFor="let req of reqAuto">{{req | reqFormat}}</option>
                  </datalist>
                  -->
                </div>
              </div>
              <div class="form-group form-display row">
                <div class="col form-group">
                  <p class="billing-code-indicator">{{billingCode ? 'Billing Code: ' + billingCode : '&nbsp;'}}</p>
                </div>
              </div>
            </div>
            <div class="col-2 px-0">
              <div class="form-group form-display row">
                <div class="col text-center">
                  <i data-toggle="tooltip" data-placement="left" title="See Requisition" class="fa download-req fa-file-o filter-icons" [class.filter-icons-off]="showPdfPreview != 'requisition'" (click)="toggleFileTypeFilter('req')"></i>
                  <p>Requisition</p>
                </div>
              </div>
              <div class="form-group form-display row">
                <div class="col text-center">
                  <i data-toggle="tooltip" data-placement="left" title="See Tracings" class="fa download-tracings fa-file-pdf-o filter-icons" [class.filter-icons-off]="showPdfPreview != 'tracings'" (click)="toggleFileTypeFilter('pdf')"></i>
                  <p>Tracings</p>
                </div>
              </div>
              <div class="text-center">
                <br>
                <button class="btn btn-primary btn-narrow" (click)="updateHolter()" type="button" [disabled]="!currentHolter || !currentHolter.patient_id || !formGroup.valid || (!formChanged && !checkBoxFormChanged && !reqChanged) || !checkBoxFormGroup.valid">Update</button>
              </div>
            </div>
          </div>
        </form>

        <hr>

        <div class="row">
          <div class="col-12">

            <div class="row">
              <div class="col-1"></div>
              <!--
              <div class="col-3 text-center">
                <i data-toggle="tooltip" data-placement="left" title="Filter Tracings" class="fa download-tracings fa-file-pdf-o filter-icons" [class.filter-icons-off]="!fileTypeFilters.pdf" (click)="toggleFileTypeFilter('pdf')"></i>
                <i data-toggle="tooltip" data-placement="left" title="Filter Requisition" class="fa download-req fa-file-o filter-icons" [class.filter-icons-off]="!fileTypeFilters.req" (click)="toggleFileTypeFilter('req')"></i>
                <i data-toggle="tooltip" data-placement="left" title="Filter Preliminary" class="fa download-preliminary fa-file-text-o filter-icons" [class.filter-icons-off]="!fileTypeFilters.preliminary" (click)="toggleFileTypeFilter('preliminary')"></i>
                <i data-toggle="tooltip" data-placement="left" title="Filter Final Report" class="fa download-final fa-file-pdf-o filter-icons" [class.filter-icons-off]="!fileTypeFilters.final" (click)="toggleFileTypeFilter('final')"></i>
                <i data-toggle="tooltip" data-placement="left" title="Filter Data" class="fa download-backup fa-database filter-icons" [class.filter-icons-off]="!fileTypeFilters.backup" (click)="toggleFileTypeFilter('backup')"></i>
              </div>
              <div class="col-3">
                <label>Smart Filters</label>
                <select class="form-control" data-live-search="true" [(ngModel)]="holterErrorIdx" (change)="applySmartFilter()">
                  <option [value]=""></option>
                  <option *ngFor="let holterError of holterErrors let i = index" [value]="i">{{holterError.name + ' (' + holterError.count + ')'}}</option>
                </select>
              </div>
              -->

              <div class="col-10">
                <label>Search</label>
                <input type="text" class="form-control search-bar" placeholder="Start typing to search" id="search-bar">

              </div>
              <div class="col-1">
                <div class="col-md-auto">
                  <div class="custom-control custom-switch">
                    <input class="custom-control-input" type="checkbox" id="switch1" [(ngModel)]="showEntries" (click)="showUserEntries()" [hidden]="isSuperUser == false">
                    <label class="custom-control-label" [hidden]="isSuperUser == false" for="switch1">Work List</label>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <table datatable [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover" id="not-pending-table">
                <thead>
                  <tr>
                    <th width="10px"></th>
                    <th>Status</th>
                    <th>Files</th>
                    <th>Sync</th>
                    <th>Hookup Date</th>
                    <th>Scan Date</th>
                    <th>Name</th>
                    <th>Referring Dr</th>
                    <th>Path</th>
                    <th>Slot</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div class="row">
              <div class="col-3 text refresh-text-on" *ngIf="autoRefreshing">
                Autorefresh: On
              </div>
              <div class="col-3 text refresh-text-off" *ngIf="!autoRefreshing">
                Autorefresh: Off
              </div>
              <div class="col-6 text-center progress-text">
                <div *ngIf="downloadProgress">
                  {{downloadProgress}}
                </div>
              </div>
            </div>
          </div>
        </div>
          </as-split-area>
          <as-split-area [size]="pdfSizeRight">
            <form class="form-horizontal form-label-left" [formGroup]="formGroup">
              <div class="form-group form-display row ml-3">
                <div class="col form-group text-center">
                  <button class="btn btn-primary btn-review" type="button" (click)="addStaffNotes()" [disabled]="!currentHolter || currentHolter.closed_by || multiSelected">Staff Notes</button>
                </div>
                <div class="col form-group text-center">
                  <button class="btn btn-primary btn-review" type="button" (click)="openCreateFaxModal()" [disabled]="!currentHolter || currentHolter.closed_by || multiSelected">Fax Options</button>
                </div>
              </div>
              <div class="form-group form-display row ml-3">

                <div class="col form-group">
                  <label>Staff Notes</label>
                  <div class="row">
                    <div class="col">
                      <textarea rows="3" class="form-control" placeholder="" formControlName="StaffNotes" readonly></textarea>
                    </div>
                  </div>
                </div>
                <div class="col form-group">
                  <label>Requisition Notes</label>
                  <div class="row">
                    <div class="col">
                      <textarea rows="3" class="form-control" placeholder="" formControlName="ReqNotes" readonly></textarea>
                    </div>
                  </div>
                </div>

              </div>
              <div class="preview-panel">
                <div *ngIf="currentHolter && pdfSrc && !loadingPdf">
                  <pdf-viewer [src]="pdfSrc" [autoresize]="true" [render-text]="false" [show-all]="showPdfPreview != 'tracings'" [original-size]="false" [fit-to-page]="true" style="display: block;"></pdf-viewer>
                </div>
              </div>
            </form>

          </as-split-area>

        </as-split>


      </div>
      </div>
    </div>
  </div>

  <div>
    <div class="row sub-footer">
      <div class="col-12">
        <button class="btn btn-primary btn-review" type="button" (click)="syncHolter()" [disabled]="!currentHolter">Sync</button>
        <button class="btn btn-primary btn-review" type="button" (click)="submitHolter(true)" [disabled]="!currentHolter || !currentHolter.patient_id || currentHolter.state != 'PENDING'"><i class="fa fa-check"></i> Submit</button>
        <!--
        <button class="btn btn-primary btn-review" type="button" (click)="flagPacemakeInquiry()" [disabled]="!currentHolter || multiSelected || currentHolter.state != 'PENDING'">{{(currentHolter && !!currentHolter.flag_pacemaker_inquiry) ? 'Pacemaker Resolved' : 'Pacemaker Inquiry'}}</button>
        -->
        <button class="btn btn-primary btn-review" type="button" (click)="hideEntry()" [disabled]="!currentHolter || currentHolter.state != 'PENDING'">Mark as Test</button>
      </div>
    </div>
  </div>

</div>
<div>
    <div class="row sub-main">

      <div class="page-title">
        <div class="title_left">
          <h3>Logistics - Monitor Traffic</h3>
        </div>
      </div>
      <div class="clearfix"></div>

      <div class="x_panel">
        <div class="x_content">

          <div class="row split-container">

            <as-split direction="horizontal">
              <as-split-area [size]="50">
                <div class="col">
                  <div class="row">
                    <div class="col-4 form-group">
                      <input type="radio" (change)="applyBranchFilter()" [(ngModel)]="radio" value="serial"> Serial
                      <input type="radio" (change)="applyBranchFilter()" [(ngModel)]="radio" value="clinic"> Clinics
                    </div>
                    <div class="col-8">

                    </div>
                  </div>
                  <form class="form-horizontal form-label-left" [formGroup]="formGroup">
                    <div class="row">
                      <div class="col">
                        <div class="row">
                            <label>Search</label>
                            <input type="text" formControlName="Search" [(ngModel)]="search" class="form-control search-bar" placeholder="Start typing to search" id="search_bar">
                        </div>
                        <div class="row">
                          <div class="col form-group">
                            <label>Preset Dates</label>
                            <select class="form-control" data-live-search="true" formControlName="DateFilter" (change)="changeDates()">
                              <option value="DAY">Day</option>
                              <option value="WEEK">Week</option>
                              <option value="MONTH">Month</option>
                              <option value="YEAR">Year</option>
                              <option value="ALL_TIME">All Time</option>
                            </select>
                          </div>
                          <div class="col form-group">
                            <label>Usage</label>
                            <select class="form-control" data-live-search="true" formControlName="UsageFilter" (change)="applyFilter()">
                              <option value="">All</option>
                              <option value="LT5">Less than 5</option>
                              <option value="MT5LT30">More than 5, Less than 30</option>
                              <option value="MT30">More than 30</option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col form-group">
                              <label>Date From</label>
                              <input type="text" class="form-control" placeholder="YYYY/MM/DD" formControlName="DateFrom">
                          </div>
                          <div class="col form-group">
                              <label>Date To</label>
                              <input type="text" class="form-control" placeholder="YYYY/MM/DD" formControlName="DateTo">
                          </div>
                          <div class="col text-center">
                              <button class="btn btn-primary btn-narrow" type="button" (click)="applyFilter()">Apply</button>
                          </div>
                        </div>
                      </div>
                      <div class="col form-group">
                        <label>Status</label><span> ({{monitorsCount}}) ({{entriesCount}} entries)</span>
                        <div class="form-group form-display row ml-4 mr-n4">
                            <div class="col-2 form-group">
                                <input class="form-check-input" type="checkbox" name="check0" id="check0" formControlName="Active" [(ngModel)]="isActive" (change)="checkboxChange()" >
                                <label class="form-check-label" for="check0">Active</label>
                            </div>
                            <div class="col-9 form-group center" [ngStyle]="activeStyle">
                              <span>{{activeCount}}</span>
                            </div>
                            <div class="col"></div>
                        </div>
                        <div class="form-group form-display row ml-4 mr-n4">
                            <div class="col-2 form-group">
                                <input class="form-check-input" type="checkbox" name="check4" id="check4" formControlName="Demo" [(ngModel)]="isDemo" (change)="checkboxChange()">
                                <label class="form-check-label" for="check4">Demo</label>
                            </div>
                            <div class="col-9 form-group center" [ngStyle]="demoStyle">
                              <span>{{demoCount}}</span>
                            </div>
                            <div class="col"></div>
                        </div>
                        <div class="form-group form-display row ml-4 mr-n4">
                            <div class="col-2 form-group">
                                <input class="form-check-input" type="checkbox" name="check2" id="check2" formControlName="Expired" [(ngModel)]="isExpired" (change)="checkboxChange()">
                                <label class="form-check-label" for="check2">Expired</label>
                            </div>
                            <div class="col-9 form-group center" [ngStyle]="expiredStyle">
                              <span>{{expiredCount}}</span>
                            </div>
                            <div class="col"></div>
                        </div>
                        <div class="form-group form-display row ml-4 mr-n4">
                            <div class="col-2 form-group">
                                <input class="form-check-input" type="checkbox" name="check5" id="check5" formControlName="Repair" [(ngModel)]="isRepair" (change)="checkboxChange()">
                                <label class="form-check-label" for="check5">Repair</label>
                            </div>
                            <div class="col-9 form-group center" [ngStyle]="repairStyle">
                              <span>{{repairCount}}</span>
                            </div>
                            <div class="col"></div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <table datatable *ngIf="dtRendered" [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover" id="medtrace-table">
                    <thead>
                        <tr>
                          <th *ngFor="let hd of tableHeaders">
                            {{hd}}
                          </th>
                        </tr>
                  </thead>
                  </table>
                </div>
              </as-split-area>
              <as-split-area [size]="50">
                  <div class="col info-panel" >
                    <div class="row">
                      <div class="col text-right">
                        <label>ID / Percentage / Current Year / Past Year</label>
                      </div>
                    </div>
                    <div class="chart-box">
                      <div class="content">
                          <div class="content-selection">
                              <div class="row">
                                  <div class="col-md-auto form-group">
                                      <div class="row">
                                        <button class="btn btn-primary btn-review btn-add-notes" type="button" (click)="addContent('billing')">+</button>
                                      </div>
                                      <div class="row">
                                        <button class="btn btn-primary btn-review btn-add-notes" type="button" (click)="removeContent('billing')">-</button>
                                      </div>
                                  </div>
                                  <div class="col">
                                      <input type="text" class="form-control" placeholder="Add Billing Code" [(ngModel)]="billingCodeText" [ngbTypeahead]="searchBillingCode" [resultFormatter]="searchBillingCodeFormatter" [inputFormatter]="searchBillingCodeInputFormatter">
                                      <div class="selection-container">
                                          <ul>
                                              <li class="overflow-auto" *ngFor="let billingCode of billingCodeList" [class.item-selected]="(billingCode === billingCodeSelection)" (click)="selection('billing', billingCode)">{{billingCode}}</li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="content-chart">
                            <div class="row">
                              <div class="col">
                                <div class="row" *ngIf="this.billingCodeList.length != 0">
                                  <div class="col-3"></div>
                                  <div class="col-7 text-center">
                                    <label>2022</label>
                                  </div>
                                  <div class="col-2 text-center">
                                    <label>2021</label>
                                  </div>
                                </div>
                                <div *ngFor="let billingCode of billingCodeList">
                                  <ng-template #dynamicBillingCodeYTD></ng-template>
                                </div>
                              </div>
                              <div class="col">
                                <div class="row" *ngIf="this.billingCodeList.length != 0">
                                  <div class="col-3"></div>
                                  <div class="col-7 text-center">
                                    <label>2022</label>
                                  </div>
                                  <div class="col-2 text-center">
                                    <label>2021</label>
                                  </div>
                                </div>
                                <div *ngFor="let billingCode of billingCodeList">
                                  <ng-template #dynamicBillingCodeMTD></ng-template>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="content">
                        <div class="content-selection">
                            <div class="row">
                                <div class="col-md-auto form-group">
                                    <div class="row">
                                      <button class="btn btn-primary btn-review btn-add-notes" type="button" (click)="addContent('clinic')">+</button>
                                    </div>
                                    <div class="row">
                                      <button class="btn btn-primary btn-review btn-add-notes" type="button" (click)="removeContent('clinic')">-</button>
                                    </div>
                                  </div>
                                  <div class="col">
                                      <input type="text" class="form-control" placeholder="Add Clinic" [(ngModel)]="clinicText" [ngbTypeahead]="searchClinic" [resultFormatter]="searchClinicFormatter" [inputFormatter]="searchClinicInputFormatter">
                                      <div class="selection-container">
                                          <ul>
                                              <li class="overflow-auto" *ngFor="let clinic of clinicList" [class.item-selected]="(clinic === clinicSelection)" (click)="selection('clinic', clinic)">{{clinic.name}}</li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                        </div>
                        <div class="content-chart">
                          <div class="row">
                            <div class="col">
                              <div class="row" *ngIf="this.clinicList.length != 0">
                                <div class="col-3"></div>
                                <div class="col-7 text-center">
                                  <label>2022</label>
                                </div>
                                <div class="col-2 text-center">
                                  <label>2021</label>
                                </div>
                              </div>
                              <div *ngFor="let clinic of clinicList">
                                  <ng-template #dynamicClinicYTD></ng-template>
                              </div>
                            </div>
                            <div class="col">
                              <div class="row" *ngIf="this.clinicList.length != 0">
                                <div class="col-3"></div>
                                <div class="col-7 text-center">
                                  <label>2022</label>
                                </div>
                                <div class="col-2 text-center">
                                  <label>2021</label>
                                </div>
                              </div>
                              <div *ngFor="let clinic of clinicList">
                                <ng-template #dynamicClinicMTD></ng-template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="content">
                        <div class="content-selection">
                            <div class="row">
                                <div class="col-md-auto form-group">
                                    <div class="row">
                                      <button class="btn btn-primary btn-review btn-add-notes" type="button" (click)="addContent('doctor')">+</button>
                                    </div>
                                    <div class="row">
                                      <button class="btn btn-primary btn-review btn-add-notes" type="button" (click)="removeContent('doctor')">-</button>
                                    </div>
                                  </div>
                                  <div class="col">
                                      <input type="text" class="form-control" placeholder="Add Doctor" [(ngModel)]="doctorText" [ngbTypeahead]="searchReferringDoctor" [resultFormatter]="searchReferringDoctorFormatter" [inputFormatter]="searchReferringDoctorInputFormatter">
                                      <div class="selection-container">
                                          <ul>
                                              <li class="overflow-auto" *ngFor="let doctor of doctorList" [class.item-selected]="(doctor === doctorSelection)" (click)="selection('doctor', doctor)">{{doctor.name}}</li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                        </div>
                        <div class="content-chart">
                          <div class="row">
                            <div class="col">
                              <div class="row" *ngIf="this.doctorList.length != 0">
                                <div class="col-3"></div>
                                <div class="col-7 text-center">
                                  <label>2022</label>
                                </div>
                                <div class="col-2 text-center">
                                  <label>2021</label>
                                </div>
                              </div>
                              <div *ngFor="let doctor of doctorList">
                                  <ng-template #dynamicDoctorYTD></ng-template>
                              </div>
                            </div>
                            <div class="col">
                              <div class="row" *ngIf="this.doctorList.length != 0">
                                <div class="col-3"></div>
                                <div class="col-7 text-center">
                                  <label>2022</label>
                                </div>
                                <div class="col-2 text-center">
                                  <label>2021</label>
                                </div>
                              </div>
                              <div *ngFor="let doctor of doctorList">
                                <ng-template #dynamicDoctorMTD></ng-template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="content">
                          <div class="content-selection">
                              <div class="row">
                                  <div class="col-md-auto form-group">
                                      <div class="row">
                                        <button class="btn btn-primary btn-review btn-add-notes" type="button" (click)="addContent('staff')" >+</button>
                                      </div>
                                      <div class="row">
                                        <button class="btn btn-primary btn-review btn-add-notes" type="button" (click)="removeContent('staff')">-</button>
                                      </div>
                                    </div>
                                    <div class="col">
                                        <input type="text" class="form-control" placeholder="Add Staff" [(ngModel)]="staffText" [ngbTypeahead]="searchEmployee" [resultFormatter]="searchEmployeeFormatter" [inputFormatter]="searchEmployeeInputFormatter">
                                        <div class="selection-container">
                                            <ul>
                                                <li class="overflow-auto" *ngFor="let staff of staffList" [class.item-selected]="(staff === staffSelection)" (click)="selection('staff', staff)">{{staff.name}}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                          </div>
                          <div class="content-chart">
                            <div class="row">
                              <div class="col">
                                <div class="row" *ngIf="this.staffList.length != 0">
                                  <div class="col-3"></div>
                                  <div class="col-7 text-center">
                                    <label>2022</label>
                                  </div>
                                  <div class="col-2 text-center">
                                    <label>2021</label>
                                  </div>
                                </div>
                                <div *ngFor="let staff of staffList">
                                    <ng-template #dynamicStaffYTD></ng-template>
                                </div>
                              </div>
                              <div class="col">
                                <div class="row" *ngIf="this.staffList.length != 0">
                                  <div class="col-3"></div>
                                  <div class="col-7 text-center">
                                    <label>2022</label>
                                  </div>
                                  <div class="col-2 text-center">
                                    <label>2021</label>
                                  </div>
                                </div>
                                <div *ngFor="let staff of staffList">
                                  <ng-template #dynamicStaffMTD></ng-template>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
              </as-split-area>
            </as-split>
          </div>
        </div>
      </div>
    </div>
  <div>
    <div class="row sub-footer">
      <div class="col-12">
        <button class="btn btn-primary btn-review" type="button" (click)="exportQuery()">Query</button>
        <button class="btn btn-primary btn-review" type="button" (click)="downloadReport()">Report</button>
      </div>
    </div>
  </div>
</div>
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { version } from '../../../package.json';

import { environment } from "../../environments/environment";
import { AuthService } from "../providers/auth.service";
import { Patient } from "../models/patient.model";
import { DataTablesResponse } from "../models/database-response.model";

import { formatLocaleTime, formatServerTime } from "../utils/format";

@Injectable()
export class PatientService {

  private readonly JOB_URL: string = environment.api + "/job";
  private readonly CLIENT_NAME: string = "cardio_study";

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  // this is used to override the ajax call in datatables for server-side processing
  public ajaxCall(dataTablesParams: any): Promise<any> {

    // populate the info in multiform part
    dataTablesParams["table"] = "patient";
    const formData: FormData = new FormData();
    formData.append('info', JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "query_data",
      job_id: null,
      task_status: "create",
      data: dataTablesParams
    }));

    // request the data from url
    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise();
  }

  public getPatient(id: number): Promise<Patient | null> {

    // populate the info in multiform part
    const query: any = {
      draw: 1,
      columns: [
        { data: "patient:updated_at" },
        { data: "patient:last_name" },
        { data: "patient:first_name" },
        { data: "patient:health_card" },
        { data: "patient:health_card_code" },
        { data: "patient:gender" },
        { data: "patient:birthdate" },
        { data: "patient:height" },
        { data: "patient:weight" },
        { data: "patient:id", search: { value: id.toString(), regex: false}  },
      ],
      order: [],
      table: "patient",
      start: 0,
      length: 1,
      search: {},
    };

    const formData: FormData = new FormData();
    formData.append('info', JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "query_data",
      job_id: null,
      task_status: "create",
      data: query,
    }));

    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => {
        const result: DataTablesResponse = response.Payload.data.result;
        if (result.data.length <= 0) {
          return null;
        }

        const raw: any = result.data[0];
        let patient: Patient = new Patient();
        patient.updated_at = raw["patient:updated_at"];
        patient.last_name = raw["patient:last_name"];
        patient.first_name = raw["patient:first_name"];
        patient.health_card = raw["patient:health_card"];
        patient.health_card_code = raw["patient:health_card_code"];
        patient.gender = raw["patient:gender"];
        patient.birthdate = raw["patient:birthdate"];
        patient.height = raw["patient:height"] ? parseFloat(raw["patient:height"]) : null;
        patient.weight = raw["patient:weight"] ? parseFloat(raw["patient:weight"]) : null;
        patient.id = raw["patient:id"];
        return patient;
      })
      .catch((error: any) => {
        console.log("Failed to get patient: " + error.message);
        return null;
      });
  }

  public getPatientByHealthCard(cardNumber: string): Promise<any> {

    // remove everything but numbers from the cardnumber
    // cardNumber = cardNumber.replace(/[^\d]/g, '').substr(0, 10);

    // populate the info in multiform part
    const query: any = {
      draw: 1,
      columns: [
        { data: "patient:updated_at" },
        { data: "patient:last_name" },
        { data: "patient:first_name" },
        { data: "patient:health_card", search: { value: cardNumber, regex: true} },
        { data: "patient:health_card_code" },
        { data: "patient:gender" },
        { data: "patient:birthdate" },
        { data: "patient:height" },
        { data: "patient:weight" },
        { data: "patient:id" },
      ],
      order: [],
      table: "patient",
      start: 0,
      length: 1,
      search: {},
    };

    const formData: FormData = new FormData();
    formData.append('info', JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "query_data",
      job_id: null,
      task_status: "create",
      data: query,
    }));

    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => {
        const result: DataTablesResponse = response.Payload.data.result;
        if (result.data.length <= 0) {
          return null;
        }

        result.data = result.data.map(formatLocaleTime);
        const raw: any = result.data[0];
        let patient: Patient = new Patient();
        patient.updated_at = raw["patient:updated_at"];
        patient.last_name = raw["patient:last_name"];
        patient.first_name = raw["patient:first_name"];
        patient.health_card = raw["patient:health_card"];
        patient.health_card_code = raw["patient:health_card_code"];
        patient.gender = raw["patient:gender"];
        patient.birthdate = raw["patient:birthdate"];
        patient.height = raw["patient:height"] ? parseFloat(raw["patient:height"]) : null;
        patient.weight = raw["patient:weight"] ? parseFloat(raw["patient:weight"]) : null;
        patient.id = raw["patient:id"];
        return patient;
      })
      .catch((error: any) => {
        console.log("Failed to get patient: " + error.message);
        return null;
      });

  }

  public searchPatient(term: string): Promise<Patient[] | null> {

    // populate the info in multiform part
    const query: any = {
      draw: 1,
      columns: [
        { data: "patient:last_name" },
        { data: "patient:first_name" },
        { data: "patient:health_card" },
        { data: "patient:id"},
      ],
      order: [],
      table: "patient",
      start: 0,
      length: 50,
      search: {
        regex: false,
        value: term,
      },
    };

    const formData: FormData = new FormData();
    formData.append('info', JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "query_data",
      job_id: null,
      task_status: "create",
      data: query,
    }));

    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => {
        const result: DataTablesResponse = response.Payload.data.result;
        if (result.data.length <= 0) {
          return null;
        }

        let patients: Patient[] = [];
        for (const raw of result.data) {
          let patient: Patient = new Patient();
          patient.last_name = raw["patient:last_name"];
          patient.first_name = raw["patient:first_name"];
          patient.health_card = raw["patient:health_card"];
          patient.id = raw["patient:id"];
          patients.push(patient);
        }
        return patients;
      })
      .catch((error: any) => {
        console.log("Failed to search patients: " + error.message);
        return null;
      });
  }

  public createPatient(patient: Patient, update: boolean = false): Promise<Patient | any> {

    // populate the info in multiform part
    const query: any = {
      entries: [{
        id: patient.id,
        last_name: patient.last_name,
        first_name: patient.first_name,
        health_card: patient.health_card,
        health_card_code: patient.health_card_code,
        gender: patient.gender,
        birthdate: formatServerTime(patient.birthdate),
        height: patient.height,
        weight: patient.weight,
      }],
      table: "patient",
    };

    const formData: FormData = new FormData();
    formData.append('info', JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: update ? "update_data" : "post_data",
      job_id: null,
      task_status: "create",
      data: query,
    }));

    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => response.Payload.data.result)
      .catch((error: any) => {
        console.log("Failed to create patient: " + error.message);
        return null;
      });
  }

  public getHolters(patientId: string | number): Promise<any> {
    const url = environment.api + '/patient/' + patientId + '/holter';
    return this.http.get(url,this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  private httpOptions(): any {
    return { headers: new HttpHeaders({
      "token": this.authService.getToken(),
      "version": version,
    }) };
  }

}
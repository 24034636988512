<div class="modal-body">
    <form>
      <div class="row">
        <div class="col">

          <form class="form-horizontal form-label-left" [formGroup]="formGroup">
            <div class="row">
              <div class="col form-group text-center">
                  <div class="title-selection-orange">Fax Options</div>
                  <br>
                  <label>{{patientInfo}}</label>
              </div>
            </div>
            <div class="row">
              <div class="col form-group">
                <label>Fax Notes</label>
                <input type="text" class="form-control" placeholder="Add notes here" formControlName="FaxNotes" id="faxNotes" [(ngModel)]="createFaxNotes" name="faxNotes">
                <textarea rows="3" class="form-control" placeholder="" formControlName="FaxLog" readonly></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col form-group">
                <div class="row ml-4">
                  <input class="form-check-input" type="checkbox" name="check4" id="check4" formControlName="FaxWithTracings" [(ngModel)]="faxWithTracings">
                  <label class="form-check-label" for="check4">Fax with tracings</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col form-group">
                <label>Send reports to:</label>
              </div>
            </div>
            <div class="row">
              <div class="col form-group required">
                <input type="text" class="form-control" placeholder="Search Doctor Name" formControlName="ReferringDoctor" [ngbTypeahead]="searchDoctor" [resultFormatter]="searchDoctorFormatter" [inputFormatter]="searchDoctorInputFormatter" [attr.disabled]="!createFax ? '' : null">
                <p *ngIf="invalidReferring" class="error-message">Invalid Referring Doctor</p>
              </div>
            </div>
            <div class="row">
              <div class="col form-group required">
                <input type="text" class="form-control" placeholder="Enter Fax Number" [(ngModel)]="faxNumber" formControlName="Fax" [attr.disabled]="!createFax ? '' : null">
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-md-auto form-group">
                <div class="row">
                  <button class="btn btn-primary btn-review btn-add-notes" type="button" (click)="addFaxNumber()" [disabled]="!textAdditionalFax || typeOf(textAdditionalFax)">+</button>
                </div>
                <div class="row">
                  <button class="btn btn-primary btn-review btn-add-notes" type="button" (click)="removeFaxNumber()" [disabled]="!faxNumberString">-</button>
                </div>
              </div>
              <div class="col form-group">
                  <label>Also send to:</label>
                  <input type="text" class="form-control" placeholder="Add Doctor or Fax Number" formControlName="FaxNumbers" [(ngModel)]="textAdditionalFax" [ngbTypeahead]="searchDoctor" [resultFormatter]="searchDoctorFormatter" [inputFormatter]="searchFaxDoctorInputFormatter">
                  <div class="fax-container">
                      <ul>
                          <li class="overflow-auto" *ngFor="let faxNumber of faxNumberAuto" [class.item-selected]="(faxNumber === faxNumberString)" [ngClass]="{'clinic-fax': clinicFaxNumbers?.indexOf(faxNumber) > -1, 'disabled': clinicFaxNumbers?.indexOf(faxNumber) > -1}" (click)="selectedFaxNumber(faxNumber)">{{faxNumber}}</li>
                      </ul>
                  </div>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-8 form-group">
                <div class="row ml-4">
                  <input class="form-check-input" type="checkbox" name="check3" id="check3" formControlName="CreateFax" [(ngModel)]="createFax" (change)="changeCreateFaxString()">
                  <label class="form-check-label" for="check3">{{createFaxString}}</label>
                </div>
                <div class="row ml-4" *ngIf="createFax">
                  <div class="col form-group">
                    <label>{{doctor.name}}</label>
                  </div>
                </div>
                <div class="row ml-4" *ngIf="createFax">
                  <div class="col form-group">
                    <label>{{faxNumber}}</label>
                  </div>
                </div>
              </div>
              <div class="col-4 form-group">
                <div class="row ml-3" *ngIf="createFax">
                  <input class="form-check-input" type="checkbox" name="check0" id="check0" formControlName="FaxNotesReq">
                  <label class="form-check-label" for="check0">Patient Req</label>
                </div>
                <div class="row ml-3" *ngIf="createFax">
                  <input class="form-check-input" type="checkbox" name="check1" id="check1" formControlName="FaxNotesData" >
                  <label class="form-check-label" for="check1">Patient Data</label>
                </div>
                <div class="row ml-3" *ngIf="createFax">
                  <input class="form-check-input" type="checkbox" name="check2" id="check2" formControlName="FaxNotesPacemaker">
                  <label class="form-check-label" for="check2">Pacemaker</label>
                </div>
              </div>
            </div>

          </form>
          <div class="col text-center">
            <button type="submit" class="btn btn-primary btn-review" (click)="passBack()" [disabled]="(!formGroup.valid || (createFaxNotes == '' && faxNumberSame && !createFax) || (faxNumber == '' && createFax)) && !formGroup.dirty">Submit</button>
          </div>


        </div>
      </div>
    </form>
  </div>
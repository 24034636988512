<div class="modal-body">
    <div class="col">
        <div class="row">
            <div class="col">

                <form class="form-horizontal form-label-left" [formGroup]="formGroup">
                    <div class="row">
                        <div class="col form-group text-center">
                            <div [ngClass]="titleClass">{{titleString}}</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <label>Status</label>
                            <input type="text" class="form-control" placeholder="" formControlName="Status">
                        </div>
                        <div class="col form-group">
                            <label>Serial</label>
                            <input type="text" class="form-control" placeholder="" formControlName="Serial">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <label>Entry Date</label>
                            <input type="text" class="form-control" placeholder="" formControlName="EntryDate">
                        </div>
                        <div class="col form-group">
                            <label>Zone</label>
                            <input type="text" class="form-control" placeholder="" formControlName="Zone">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <label>Doctor</label>
                            <input type="text" class="form-control" placeholder="" formControlName="Doctor" [ngbTypeahead]="searchDoctor" [resultFormatter]="searchReferringDoctorFormatter" [inputFormatter]="searchReferringDoctorInputFormatter">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <label>Clinic</label>
                            <input type="text" class="form-control" placeholder="" formControlName="Clinic" [attr.disabled]="true">
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col text-center form-group" *ngIf="!create && !add">
                <button type="submit" [ngClass]="buttonClass" (click)="passBack('modify')" [disabled]="!formGroup.valid">Modify</button>
            </div>
            <div class="col text-center form-group" *ngIf="create && !add">
                <button type="submit" class="btn btn-primary btn-narrow btn-primary-blue" (click)="passBack('create')" [disabled]="!formGroup.valid || this.disableCreate">Create</button>
            </div>
        </div>
    </div>
</div>
<div>
  <div class="row sub-main">

    <div class="page-title">
      <div class="title_left">
        <h3>Holter - Reserved</h3>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="x_panel">
      <div class="x_content">

        <div class="row split-container">

          <as-split direction="horizontal">

            <as-split-area [size]="40">
              <div class="col">
                <div [hidden]="showPdfPreview">

                  <div class="row">
                    <div class="col-8">
                      <label>Search</label>
                      <input type="text" class="form-control search-bar" placeholder="Start typing to search" id="search-bar">
                    </div>

                    <div class="col-4 toggle-align">
                      <div class="custom-control custom-switch">
                        <input class="custom-control-input" type="checkbox" id="switch1" [(ngModel)]="rejectedSort" (click)="applyRejectedSort()" [disabled]="statusFilter !== 'All'">
                        <label class="custom-control-label" for="switch1">Show Rejected First</label>
                      </div>
                      <div class="custom-control custom-switch">
                        <input class="custom-control-input" type="checkbox" id="switch2" [(ngModel)]="reservedFilter" (click)="applyReservedFilter()" [disabled]="statusFilter !== 'All'">
                        <label class="custom-control-label" for="switch2">Show Reserved Only</label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <label>Status Filters</label>
                      <select class="form-control" data-live-search="true" [(ngModel)]="statusFilter" (change)="statusFilterChanged()">
                        <option>All</option>
                        <option>Editor Rejected</option>
                        <option>Sent</option>
                        <option>To Process</option>
                        <option>Doctor Rejected</option>
                      </select>
                    </div>
                    <div class="col-4 form-group">
                      <label>Scan Date From</label>
                      <input type="text" class="form-control" id="scan-date-from" placeholder="YYYY/MM/DD" [(ngModel)]="startDateFilter" (change)="filterChanged()">
                    </div>
                    <div class="col-4 form-group">
                      <label>Scan Date To</label>
                      <input type="text" class="form-control" id="scan-date-to" placeholder="YYYY/MM/DD" [(ngModel)]="endDateFilter" (change)="filterChanged()">
                    </div>
                  </div>
                  <br>

                  <table datatable [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover" id="not-pending-table">
                    <thead>
                      <tr>
                        <th>Scan Date</th>
                        <th>Last Name</th>
                        <th>First Name</th>
                        <th>Files</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                  </table>
                  <div class="row" *ngIf="downloadProgress">
                    <div class="col text-center progress-text">
                      {{downloadProgress}}
                    </div>
                  </div>

                  <button class="btn btn-primary btn-narrow" type="button" (click)="unreserve()" [disabled]="!currentHolter">Unreserve</button>
                  <button class="btn btn-primary btn-narrow" type="button" (click)="openRejectionModal()" [disabled]="!currentHolter || !!currentHolter.rejected_by">Reject</button>
                  <button class="btn btn-primary btn-narrow" type="button" (click)="showTracings(true)" [disabled]="!currentHolter || multiSelected">Show Docs</button>
                </div>
                <div *ngIf="showPdfPreview && currentHolter">
                  <div class="row">
                    <div class="col">
                      <button class="btn btn-primary btn-narrow" type="button" (click)="showTracings()" [disabled]="showPdfPreview === 'tracings'">Tracings</button>
                      <button class="btn btn-primary btn-narrow" type="button" (click)="showRequisition()" [disabled]="showPdfPreview === 'requisition' || !currentHolter.requisition_file_name">Requisition</button>
                      <button class="btn btn-primary btn-narrow" type="button" (click)="showReportPreview()" [disabled]="showPdfPreview === 'report'">Report </button>
                      <button class="btn btn-primary btn-narrow" type="button" (click)="showReadingBatch()" [disabled]="showPdfPreview === 'batch'">Reading Batch </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <select class="form-control" data-live-search="true" [(ngModel)]="currentPreviewHolter" (change)="showTracings()">
                        <option *ngFor="let holter of allHolters" [value]="holter.id" [ngClass]="(holter.id == currentHolter.id) && 'current-entry'">{{formatTime(holter.hookup_date)}} - {{holter.patient_last_name}}, {{holter.patient_first_name}}{{(holter.id == currentHolter.id) ? ' (Current Entry)' : ''}}</option>
                      </select>
                      <br>
                    </div>
                  </div>
                  <div class="preview-panel">
                    <pdf-viewer id="pdf-viewer" [autoresize]="true" [src]="pdfSrc" [render-text]="false" [original-size]="false" [fit-to-page]="true" style="display: block;"></pdf-viewer>
                  </div>
                  <div class="row">
                    <div class="col">
                      <button class="btn btn-primary btn-narrow" type="button" (click)="closeTracings()">Back</button>
                    </div>
                    <div class="col-md-auto form-group" *ngIf="showPdfPreview === 'batch'">
                      <label>Batch</label>
                      <input type="text" class="form-control reorder-page" placeholder="Page # of page 2" [(ngModel)]="reorderPage" name="reorder-page" id="reorder-page"/>
                    </div>
                    <div class="col" *ngIf="showPdfPreview === 'batch'">
                      <button class="btn btn-primary btn-narrow" type="button" (click)="reorderReadingBatch()">Reorder</button>
                      <button class="btn btn-primary btn-narrow" type="button" (click)="rebatch()">Rebatch</button>
                    </div>
                  </div>
                </div>
              </div>
            </as-split-area>

            <as-split-area [size]="60">
              <div class="col info-panel">
                <form *ngIf="!showReport" class="form-horizontal form-label-left" [formGroup]="formGroupMedflux">
                  <div class="row">
                    <div class="col"><p class="also-reserved">{{alsoReservedText()}}</p></div>
                    <div class="col text-right">
                      <button class="btn btn-primary btn-review" type="button" (click)="save()" [disabled]="!currentHolter || (!wasAutofilled && !currentHolter.flag_verified) || (!formGroupMedflux.valid && !showReport)" title="{{saveTooltip()}}"><i class="fa fa-floppy-o"></i> Save All</button>
                      <button class="btn btn-primary btn-review" type="button" (click)="toggleReport()" [disabled]="!currentHolter || (!showReport && !currentHolter.flag_verified)">{{showReport ? "Edit Medflux" : "Edit Report"}}</button>
                      <div class="form-answer bottom-info-message">A save will reset any saved report changes to reflect the updated test details.</div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <h4>Patient Information</h4>
                      <div class="form-group form-display row">
                        <div class="col form-group required">
                          <label>Last Name <i [hidden]="!errorStrings.last_name" class="conflict-message-inline">{{errorStrings.last_name}}</i></label>
                          <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('LastName')" [class.has-conflict]="hasConflict('LastName') || errorStrings.last_name" formControlName="LastName" [attr.disabled]="multiSelected ? '' : null">
                          <div class="conflict-message" [hidden]="!pdfPatConflictValue('LastName')">{{pdfPatConflictValue('LastName')}}</div>
                          <div class="conflict-message" [hidden]="!autofillConflictValue('LastName')">{{autofillConflictValue('LastName')}}</div>
                        </div>
                        <div class="col form-group required">
                          <label>First Name <i [hidden]="!errorStrings.first_name" class="conflict-message-inline">{{errorStrings.first_name}}</i></label>
                          <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('FirstName')" [class.has-conflict]="hasConflict('FirstName') || errorStrings.first_name" formControlName="FirstName" [attr.disabled]="multiSelected ? '' : null">
                          <div class="conflict-message" [hidden]="!pdfPatConflictValue('FirstName')">{{pdfPatConflictValue('FirstName')}}</div>
                          <div class="conflict-message" [hidden]="!autofillConflictValue('FirstName')">{{autofillConflictValue('FirstName')}}</div>
                        </div>
                      </div>
                      <div class="form-group form-display row">
                        <div class="col-4 form-group required">
                          <label>Health Card</label>
                          <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('HealthCard')" [class.has-conflict]="hasConflict('HealthCard') || errorStrings.first_name || errorStrings.last_name" formControlName="HealthCard" [attr.disabled]="multiSelected ? '' : null">
                          <div class="conflict-message" [hidden]="!pdfPatConflictValue('HealthCard')">{{pdfPatConflictValue('HealthCard')}}</div>
                          <div class="conflict-message" [hidden]="!autofillConflictValue('HealthCard')">{{autofillConflictValue('HealthCard')}}</div>
                        </div>
                        <div class="col-2 form-group">
                          <label>Version</label>
                          <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('HealthCardVersion')" [class.has-conflict]="hasConflict('HealthCardVersion')" formControlName="HealthCardVersion" [attr.disabled]="multiSelected ? '' : null">
                          <div class="conflict-message" [hidden]="!pdfPatConflictValue('HealthCardVersion')">{{pdfPatConflictValue('HealthCardVersion')}}</div>
                          <div class="conflict-message" [hidden]="!autofillConflictValue('HealthCardVersion')">{{autofillConflictValue('HealthCardVersion')}}</div>
                        </div>
                        <div class="col-6 form-group required">
                          <label>Date of Birth (YYYY/MM/DD)</label>
                          <input type="text" class="form-control" placeholder="YYYY/MM/DD" [class.not-autofilled]="!autofilled('Birthdate')" [class.has-conflict]="hasConflict('Birthdate')" formControlName="Birthdate" [attr.disabled]="multiSelected ? '' : null">
                          <div class="conflict-message" [hidden]="!pdfPatConflictValue('Birthdate')">{{pdfPatConflictValue('Birthdate')}}</div>
                          <div class="conflict-message" [hidden]="!autofillConflictValue('Birthdate')">{{autofillConflictValue('Birthdate')}}</div>
                        </div>
                      </div>
                      <div class="form-group form-display row">
                        <div class="col form-group required">
                          <label>Referring Physician</label>
                          <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('ReferringDoctorName')" [class.has-conflict]="hasConflict('ReferringDoctorName')" formControlName="ReferringDoctorName" [attr.disabled]="multiSelected ? '' : null" [ngbTypeahead]="searchReferringDoctor" [resultFormatter]="searchReferringDoctorFormatter" [inputFormatter]="searchReferringDoctorInputFormatter">
                          <div class="conflict-message" [hidden]="!pdfPatConflictValue('ReferringDoctorName')">{{pdfPatConflictValue('ReferringDoctorName')}}</div>
                          <div class="conflict-message" [hidden]="!autofillConflictValue('ReferringDoctorName')">{{autofillConflictValue('ReferringDoctorName')}}</div>
                        </div>
                        <div class="col form-group required">
                          <label>Gender</label>
                          <select class="form-control" data-live-search="true" [class.not-autofilled]="!autofilled('Gender')" [class.has-conflict]="hasConflict('Gender')" formControlName="Gender" [attr.disabled]="multiSelected ? '' : null">
                            <div class="conflict-message" [hidden]="!pdfPatConflictValue('Gender')">{{pdfPatConflictValue('Gender')}}</div>
                            <div class="conflict-message" [hidden]="!autofillConflictValue('Gender')">{{autofillConflictValue('Gender')}}</div>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                          </select>
                        </div>
                      </div>
                      <div class="form-group form-display row">
                        <div class="col form-group">
                          <button class="btn btn-primary btn-review btn-no-left-margin" type="button" (click)="savePatientAndReport()" [disabled]="!currentHolter || (!wasAutofilled && !currentHolter.flag_verified) || (!formGroupMedflux.valid && !showReport)" title="{{saveTooltip()}}"><i class="fa fa-floppy-o"></i> Save Patient & Report Info Only</button>
                        </div>
                      </div>
                      <div class="col form-group">
                        <label>Staff Notes</label>
                        <div class="row">
                          <div class="col">
                            <textarea rows="3" class="form-control" placeholder="" formControlName="StaffNotes" readonly></textarea>
                          </div>
                          <div class="col-md-auto form-group ml-n3">
                            <button class="btn btn-primary btn-review btn-add-notes" type="button" (click)="addStaffNotes()" [disabled]="!currentHolter">+</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <h4>Report Information</h4>
                      <div class="form-group form-display row">
                        <div class="col-9 form-group required">
                          <label>Clinic</label>
                          <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('Clinic')" [class.has-conflict]="hasConflict('Clinic')" formControlName="Clinic" [attr.disabled]="multiSelected ? '' : null" [ngbTypeahead]="searchClinic" [resultFormatter]="searchClinicFormatter" [inputFormatter]="searchClinicInputFormatter">
                          <div class="conflict-message" [hidden]="!pdfPatConflictValue('Clinic')">{{pdfPatConflictValue('Clinic')}}</div>
                          <div class="conflict-message" [hidden]="!autofillConflictValue('Clinic')">{{autofillConflictValue('Clinic')}}</div>
                        </div>
                        <div class="col-3 form-group">
                          <label>Duration</label>
                          <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('Duration')" [class.has-conflict]="hasConflict('Duration')" formControlName="Duration" [attr.disabled]="multiSelected ? '' : null">
                          <div class="conflict-message" [hidden]="!pdfPatConflictValue('Duration')">{{pdfPatConflictValue('Duration')}}</div>
                          <div class="conflict-message" [hidden]="!autofillConflictValue('Duration')">{{autofillConflictValue('Duration')}}</div>
                        </div>
                      </div>
                      <div class="form-group form-display row">
                        <div class="col-6 form-group">
                          <label>Reading Physician</label>
                          <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('ReadingDoctorName')" [class.has-conflict]="hasConflict('ReadingDoctorName')" formControlName="ReadingDoctorName" list="readinglist" [attr.disabled]="multiSelected ? '' : null">
                          <datalist id="readinglist">
                            <option *ngFor="let dr of readingDoctorOptions" [value]="dr.value">{{dr.text}}</option>
                          </datalist>
                          <div class="conflict-message" [hidden]="!pdfPatConflictValue('ReadingDoctorName')">{{pdfPatConflictValue('ReadingDoctorName')}}</div>
                          <div class="conflict-message" [hidden]="!autofillConflictValue('ReadingDoctorName')">{{autofillConflictValue('ReadingDoctorName')}}</div>
                        </div>
                        <div class="col-3 form-group">
                          <label>Editor</label>
                          <input type="text" class="form-control" placeholder="" formControlName="Editor" [attr.disabled]="true">
                        </div>
                        <div class="col-3 form-group">
                          <label>Analyst</label>
                          <input type="text" class="form-control" placeholder="" formControlName="Analyst" [attr.disabled]="true">
                        </div>
                      </div>
                      <div class="form-group form-display row">
                        <div class="col form-group">
                          <label>Indications</label>
                          <textarea rows="1" class="form-control" placeholder="" [contextMenu]="reqMenu" [contextMenuSubject]="'Indications'" [class.not-autofilled]="!autofilled('Indications')" [class.has-conflict]="hasConflict('Indications')" formControlName="Indications"></textarea><br>
                          <div class="conflict-message" [hidden]="!pdfPatConflictValue('Indications')">{{pdfPatConflictValue('Indications')}}</div>
                          <div class="conflict-message" [hidden]="!autofillConflictValue('Indications')">{{autofillConflictValue('Indications')}}</div>
                        </div>
                      </div>
                      <div class="form-group form-display row">
                        <div class="col form-group">
                          <label>12 Lead ECG</label>
                          <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('LeadECG')" [class.has-conflict]="hasConflict('LeadECG')" formControlName="LeadECG" [attr.disabled]="multiSelected ? '' : null">
                          <div class="conflict-message" [hidden]="!pdfPatConflictValue('LeadECG')">{{pdfPatConflictValue('LeadECG')}}</div>
                          <div class="conflict-message" [hidden]="!autofillConflictValue('LeadECG')">{{autofillConflictValue('LeadECG')}}</div>
                        </div>
                      </div>
                      <div class="form-group form-display row">
                        <div class="col form-group">
                          <label>Clinic Header</label>
                          <select class="form-control" formControlName="ReportClinic" [attr.disabled]="multiSelected ? '' : null">
                            <option value="Cardio Study BC">BC | 7404 KING GEORGE BLVD. #200 SURREY, BRITISH COLUMBIA, V3W 1N6 | TEL #18007963053 FAX #18007963042</option>
                            <option value="Cardio Study Toronto">Toronto | 343 WILSON AVENUE #201, TORONTO, ONTARIO, M3H 1TA | TEL #18007963053 FAX #18007963042</option>
                            <option value="Cardio Study Woodbridge">Woodbridge | 8333 WESTON ROAD #401, WOODBRIDGE, ONTARIO, L4L 8E2 | TEL #9052640022 FAX #9052640085</option>
                            <option value="Cardio Study Mississauga">Mississauga | 1420 BURNHAMTHORPE ROAD EAST #330, MISSISSAUGA, ONTARIO, L4X 2Z9 | TEL #18007963053 FAX #18007963042</option>
                            <option value="Cardio Study Newmarket">Newmarket | 18120 YONGE STREET, NEWMARKET, ONTARIO, L9N 0J3 | TEL #18007963053 FAX #18007963042</option>
                            <option value="Cardio Study London">London | 240 WHARNCLIFFE ROAD N., SUITE 202, LONDON, ONTARIO N6H 4P2 | TEL #18007963053 FAX #18007963042</option>
                            <option value="Cardio Study Hamilton">Hamilton | 236 PARKDALE AVE N., SUITE 201, HAMILTON, ONTARIO L8H 5X5 | TEL #18007061134 FAX #18005927801</option>
                            <option value="LDS">LDS | 343 WILSON AVENUE #201, TORONTO, ONTARIO, M3H 1TA | TEL #18007963053 FAX #18007963042</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col">
                      <h4>Test Details</h4>
                      <div class="form-group form-display row">
                        <div class="col-4 form-group required">
                          <label>Date of Test (YYYY/MM/DD)</label>
                          <input type="text" class="form-control" placeholder="YYYY/MM/DD" [class.not-autofilled]="!autofilled('TestDate')" [class.has-conflict]="hasConflict('TestDate')" formControlName="TestDate" [attr.disabled]="multiSelected ? '' : null">
                          <div class="conflict-message" [hidden]="!pdfPatConflictValue('TestDate')">{{pdfPatConflictValue('TestDate')}}</div>
                          <div class="conflict-message" [hidden]="!autofillConflictValue('TestDate')">{{autofillConflictValue('TestDate')}}</div>
                        </div>
                      </div>
                      <div class=row>
                        <div class=col>
                          <div class="form-group form-display row">
                            <div class="col form-group">
                              Rhythm<hr>
                            </div>
                          </div>
                          <div class="form-group form-display row">
                            <div class="col form-group">
                              <label>Time Analyzed</label>
                              <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('TimeAnalyzed')" [class.has-conflict]="hasConflict('TimeAnalyzed')" formControlName="TimeAnalyzed" [attr.disabled]="multiSelected ? '' : null">
                              <div class="conflict-message" [hidden]="!pdfPatConflictValue('TimeAnalyzed')">{{pdfPatConflictValue('TimeAnalyzed')}}</div>
                              <div class="conflict-message" [hidden]="!autofillConflictValue('TimeAnalyzed')">{{autofillConflictValue('TimeAnalyzed')}}</div>
                            </div>
                            <div class="col form-group">
                              <label>Min HR</label>
                              <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('MinHR')" [class.has-conflict]="hasConflict('MinHR')" formControlName="MinHR" [attr.disabled]="multiSelected ? '' : null">
                              <div class="conflict-message" [hidden]="!pdfPatConflictValue('MinHR')">{{pdfPatConflictValue('MinHR')}}</div>
                              <div class="conflict-message" [hidden]="!autofillConflictValue('MinHR')">{{autofillConflictValue('MinHR')}}</div>
                            </div>
                            <div class="col form-group">
                              <label>Max HR</label>
                              <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('MaxHR')" [class.has-conflict]="hasConflict('MaxHR')" formControlName="MaxHR" [attr.disabled]="multiSelected ? '' : null">
                              <div class="conflict-message" [hidden]="!pdfPatConflictValue('MaxHR')">{{pdfPatConflictValue('MaxHR')}}</div>
                              <div class="conflict-message" [hidden]="!autofillConflictValue('MaxHR')">{{autofillConflictValue('MaxHR')}}</div>
                            </div>
                            <div class="col form-group">
                              <label>Mean HR</label>
                              <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('MeanHR')" [class.has-conflict]="hasConflict('MeanHR')" formControlName="MeanHR" [attr.disabled]="multiSelected ? '' : null">
                              <div class="conflict-message" [hidden]="!pdfPatConflictValue('MeanHR')">{{pdfPatConflictValue('MeanHR')}}</div>
                              <div class="conflict-message" [hidden]="!autofillConflictValue('MeanHR')">{{autofillConflictValue('MeanHR')}}</div>
                            </div>
                            <div class="col form-group">
                              <label>Total Beats</label>
                              <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('TotalBeats')" [class.has-conflict]="hasConflict('TotalBeats')" formControlName="TotalBeats" [attr.disabled]="multiSelected ? '' : null">
                              <div class="conflict-message" [hidden]="!pdfPatConflictValue('TotalBeats')">{{pdfPatConflictValue('TotalBeats')}}</div>
                              <div class="conflict-message" [hidden]="!autofillConflictValue('TotalBeats')">{{autofillConflictValue('TotalBeats')}}</div>
                            </div>
                          </div>
                          <div class="form-group form-display row">
                            <div class="col form-group">
                              Arrhythmia<hr>
                            </div>
                          </div>
                          <div class="form-group form-display row">
                            <div class="col form-group">
                              <label>Rhythm 1</label>
                              <select class="form-control" [class.not-autofilled]="!autofilled('Rhythm1')" [class.has-conflict]="hasConflict('Rhythm1')" formControlName="Rhythm1" [attr.disabled]="multiSelected ? '' : null">
                                <option>Normal sinus rhythm</option>
                                <option>Atrial fibrillation</option>
                                <option>Atrial flutter</option>
                                <option>Sinus rhythm</option>
                                <option>V-Pacing</option>
                                <option>Paced Rhythm</option>
                                <option>3rd degree AV block</option>
                              </select>
                              <div class="conflict-message" [hidden]="!pdfPatConflictValue('Rhythm1')">{{pdfPatConflictValue('Rhythm1')}}</div>
                              <div class="conflict-message" [hidden]="!autofillConflictValue('Rhythm1')">{{autofillConflictValue('Rhythm1')}}</div>
                            </div>
                          </div>
                          <div class="form-group form-display row">
                            <div class="col form-group">
                              <label>Rhythm 2</label>
                              <select class="form-control" [class.not-autofilled]="!autofilled('Rhythm2')" [class.has-conflict]="hasConflict('Rhythm2')" formControlName="Rhythm2" [attr.disabled]="multiSelected ? '' : null">
                                <option>Atrial fibrillation</option>
                                <option>Atrial flutter</option>
                                <option>Paroxysmal atrial fibrillation</option>
                                <option>V-Pacing</option>
                                <option>Paced Rhythm</option>
                                <option>Wenckebach Mobitz 1 (proximal 2nd degree AV block type 1)</option>
                                <option>2nd degree AV block type 2</option>
                                <option>3rd degree AV block</option>
                              </select>
                              <div class="conflict-message" [hidden]="!pdfPatConflictValue('Rhythm2')">{{pdfPatConflictValue('Rhythm2')}}</div>
                              <div class="conflict-message" [hidden]="!autofillConflictValue('Rhythm2')">{{autofillConflictValue('Rhythm2')}}</div>
                            </div>
                          </div>
                          <div class="form-group form-display row">
                            <div class="col form-group">
                              <label>Rhythm 3</label>
                              <select class="form-control" [class.not-autofilled]="!autofilled('Rhythm3')" [class.has-conflict]="hasConflict('Rhythm3')" formControlName="Rhythm3" [attr.disabled]="multiSelected ? '' : null">
                                <option>Atrial fibrillation</option>
                                <option>Atrial flutter</option>
                                <option>Paroxysmal atrial fibrillation</option>
                                <option>V-Pacing</option>
                                <option>Paced Rhythm</option>
                                <option>Wenckebach Mobitz 1 (proximal 2nd degree AV block type 1)</option>
                                <option>3rd degree AV block</option>
                              </select>
                              <div class="conflict-message" [hidden]="!pdfPatConflictValue('Rhythm3')">{{pdfPatConflictValue('Rhythm3')}}</div>
                              <div class="conflict-message" [hidden]="!autofillConflictValue('Rhythm3')">{{autofillConflictValue('Rhythm3')}}</div>
                            </div>
                          </div>
                        </div>
                        <div class=col>
                          <div class="form-group form-display row">
                            <div class="col form-group">
                              Pauses<hr>
                            </div>
                          </div>
                          <div class="form-group form-display row">
                            <div class="col form-group">
                              <label>Longest R-R</label>
                              <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('PauseLongestRR')" [class.has-conflict]="hasConflict('PauseLongestRR')" formControlName="PauseLongestRR" [attr.disabled]="multiSelected ? '' : null">
                              <div class="conflict-message" [hidden]="!pdfPatConflictValue('PauseLongestRR')">{{pdfPatConflictValue('PauseLongestRR')}}</div>
                              <div class="conflict-message" [hidden]="!autofillConflictValue('PauseLongestRR')">{{autofillConflictValue('PauseLongestRR')}}</div>
                            </div>
                            <div class="col form-group">
                              <label>Time</label>
                              <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('PauseTime')" [class.has-conflict]="hasConflict('PauseTime')" formControlName="PauseTime" [attr.disabled]="multiSelected ? '' : null">
                              <div class="conflict-message" [hidden]="!pdfPatConflictValue('PauseTime')">{{pdfPatConflictValue('PauseTime')}}</div>
                              <div class="conflict-message" [hidden]="!autofillConflictValue('PauseTime')">{{autofillConflictValue('PauseTime')}}</div>
                            </div>
                            <div class="col form-group">
                              <label>Pauses > 2.5</label>
                              <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('PauseCount')" [class.has-conflict]="hasConflict('PauseCount')" formControlName="PauseCount" [attr.disabled]="multiSelected ? '' : null">
                              <div class="conflict-message" [hidden]="!pdfPatConflictValue('PauseCount')">{{pdfPatConflictValue('PauseCount')}}</div>
                              <div class="conflict-message" [hidden]="!autofillConflictValue('PauseCount')">{{autofillConflictValue('PauseCount')}}</div>
                            </div>
                          </div>
                          <div class="form-group form-display row">
                            <div class="col form-group">
                              APB<hr>
                            </div>
                          </div>
                          <div class="form-group form-display row">
                            <div class="col form-group">
                              <label>Total</label>
                              <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('APBTotal')" [class.has-conflict]="hasConflict('APBTotal')" formControlName="APBTotal" [attr.disabled]="multiSelected ? '' : null">
                              <div class="conflict-message" [hidden]="!pdfPatConflictValue('APBTotal')">{{pdfPatConflictValue('APBTotal')}}</div>
                              <div class="conflict-message" [hidden]="!autofillConflictValue('APBTotal')">{{autofillConflictValue('APBTotal')}}</div>
                            </div>
                            <div class="col form-group">
                              <label>Pair</label>
                              <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('APBPair')" [class.has-conflict]="hasConflict('APBPair')" formControlName="APBPair" [attr.disabled]="multiSelected ? '' : null">
                              <div class="conflict-message" [hidden]="!pdfPatConflictValue('APBPair')">{{pdfPatConflictValue('APBPair')}}</div>
                              <div class="conflict-message" [hidden]="!autofillConflictValue('APBPair')">{{autofillConflictValue('APBPair')}}</div>
                            </div>
                            <div class="col form-group">
                              <label>SVT</label>
                              <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('APBSVT')" [class.has-conflict]="hasConflict('APBSVT')" formControlName="APBSVT" [attr.disabled]="multiSelected ? '' : null">
                              <div class="conflict-message" [hidden]="!pdfPatConflictValue('APBSVT')">{{pdfPatConflictValue('APBSVT')}}</div>
                              <div class="conflict-message" [hidden]="!autofillConflictValue('APBSVT')">{{autofillConflictValue('APBSVT')}}</div>
                            </div>
                          </div>
                          <div class="form-group form-display row">
                            <div class="col form-group">
                              VPB<hr>
                            </div>
                          </div>
                          <div class="form-group form-display row">
                            <div class="col form-group">
                              <label>Total</label>
                              <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('VPBTotal')" [class.has-conflict]="hasConflict('VPBTotal')" formControlName="VPBTotal" [attr.disabled]="multiSelected ? '' : null">
                              <div class="conflict-message" [hidden]="!pdfPatConflictValue('VPBTotal')">{{pdfPatConflictValue('VPBTotal')}}</div>
                              <div class="conflict-message" [hidden]="!autofillConflictValue('VPBTotal')">{{autofillConflictValue('VPBTotal')}}</div>
                            </div>
                            <div class="col form-group">
                              <label>Pair</label>
                              <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('VPBPair')" [class.has-conflict]="hasConflict('VPBPair')" formControlName="VPBPair" [attr.disabled]="multiSelected ? '' : null">
                              <div class="conflict-message" [hidden]="!pdfPatConflictValue('VPBPair')">{{pdfPatConflictValue('VPBPair')}}</div>
                              <div class="conflict-message" [hidden]="!autofillConflictValue('VPBPair')">{{autofillConflictValue('VPBPair')}}</div>
                            </div>
                            <div class="col form-group">
                              <label>VT</label>
                              <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('VPBVT')" [class.has-conflict]="hasConflict('VPBVT')" formControlName="VPBVT" [attr.disabled]="multiSelected ? '' : null">
                              <div class="conflict-message" [hidden]="!pdfPatConflictValue('VPBVT')">{{pdfPatConflictValue('VPBVT')}}</div>
                              <div class="conflict-message" [hidden]="!autofillConflictValue('VPBVT')">{{autofillConflictValue('VPBVT')}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group form-display row">
                        <div class="col form-group">
                          Diary Events<hr>
                        </div>
                      </div>
                      <div class="form-group form-display row">
                        <div class="col-1 form-group">
                          <label>No. Events</label>
                          <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('EventNumber1')" [class.has-conflict]="hasConflict('EventNumber1')" formControlName="EventNumber1" [attr.disabled]="multiSelected ? '' : null">
                          <div class="conflict-message" [hidden]="!pdfPatConflictValue('EventNumber1')">{{pdfPatConflictValue('EventNumber1')}}</div>
                          <div class="conflict-message" [hidden]="!autofillConflictValue('EventNumber1')">{{autofillConflictValue('EventNumber1')}}</div>
                        </div>
                        <div class="col-5 form-group">
                          <label>Corresponded Arrhythmia</label>
                          <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('Arrhythmia1')" [class.has-conflict]="hasConflict('Arrhythmia1')" formControlName="Arrhythmia1" [attr.disabled]="multiSelected ? '' : null">
                          <div class="conflict-message" [hidden]="!pdfPatConflictValue('Arrhythmia1')">{{pdfPatConflictValue('Arrhythmia1')}}</div>
                          <div class="conflict-message" [hidden]="!autofillConflictValue('Arrhythmia1')">{{autofillConflictValue('Arrhythmia1')}}</div>
                        </div>
                        <div class="col-1 form-group">
                          <label>No. Events</label>
                          <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('EventNumber2')" [class.has-conflict]="hasConflict('EventNumber2')" formControlName="EventNumber2" [attr.disabled]="multiSelected ? '' : null">
                          <div class="conflict-message" [hidden]="!pdfPatConflictValue('EventNumber2')">{{pdfPatConflictValue('EventNumber2')}}</div>
                          <div class="conflict-message" [hidden]="!autofillConflictValue('EventNumber2')">{{autofillConflictValue('EventNumber2')}}</div>
                        </div>
                        <div class="col-5 form-group">
                          <label>Corresponded Arrhythmia</label>
                          <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('Arrhythmia2')" [class.has-conflict]="hasConflict('Arrhythmia2')" formControlName="Arrhythmia2" [attr.disabled]="multiSelected ? '' : null">
                          <div class="conflict-message" [hidden]="!pdfPatConflictValue('Arrhythmia2')">{{pdfPatConflictValue('Arrhythmia2')}}</div>
                          <div class="conflict-message" [hidden]="!autofillConflictValue('Arrhythmia2')">{{autofillConflictValue('Arrhythmia2')}}</div>
                        </div>
                      </div>
                      <div class="form-group form-display row">
                        <div class="col-1 form-group">
                          <label>No. Events</label>
                          <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('EventNumber3')" [class.has-conflict]="hasConflict('EventNumber3')" formControlName="EventNumber3" [attr.disabled]="multiSelected ? '' : null">
                          <div class="conflict-message" [hidden]="!pdfPatConflictValue('EventNumber3')">{{pdfPatConflictValue('EventNumber3')}}</div>
                          <div class="conflict-message" [hidden]="!autofillConflictValue('EventNumber3')">{{autofillConflictValue('EventNumber3')}}</div>
                        </div>
                        <div class="col-5 form-group">
                          <label>Corresponded Arrhythmia</label>
                          <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('Arrhythmia3')" [class.has-conflict]="hasConflict('Arrhythmia3')" formControlName="Arrhythmia3" [attr.disabled]="multiSelected ? '' : null">
                          <div class="conflict-message" [hidden]="!pdfPatConflictValue('Arrhythmia3')">{{pdfPatConflictValue('Arrhythmia3')}}</div>
                          <div class="conflict-message" [hidden]="!autofillConflictValue('Arrhythmia3')">{{autofillConflictValue('Arrhythmia3')}}</div>
                        </div>
                        <div class="col-1 form-group">
                          <label>No. Events</label>
                          <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('EventNumber4')" [class.has-conflict]="hasConflict('EventNumber4')" formControlName="EventNumber4" [attr.disabled]="multiSelected ? '' : null">
                          <div class="conflict-message" [hidden]="!pdfPatConflictValue('EventNumber4')">{{pdfPatConflictValue('EventNumber4')}}</div>
                          <div class="conflict-message" [hidden]="!autofillConflictValue('EventNumber4')">{{autofillConflictValue('EventNumber4')}}</div>
                        </div>
                        <div class="col-5 form-group">
                          <label>Corresponded Arrhythmia</label>
                          <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('Arrhythmia4')" [class.has-conflict]="hasConflict('Arrhythmia4')" formControlName="Arrhythmia4" [attr.disabled]="multiSelected ? '' : null">
                          <div class="conflict-message" [hidden]="!pdfPatConflictValue('Arrhythmia4')">{{pdfPatConflictValue('Arrhythmia4')}}</div>
                          <div class="conflict-message" [hidden]="!autofillConflictValue('Arrhythmia4')">{{autofillConflictValue('Arrhythmia4')}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <form *ngIf="showReport" class="form-horizontal form-label-left" [formGroup]="formGroupReport">
                  <div class="row">
                    <div class="col text-right">
                      <button class="btn btn-primary btn-review" type="button" (click)="save()" [disabled]="!currentHolter || (!wasAutofilled && !currentHolter.flag_verified) || (!formGroupMedflux.valid && !showReport)" title="{{saveTooltip()}}"><i class="fa fa-floppy-o"></i> Save</button>
                      <button class="btn btn-primary btn-review" type="button" (click)="toggleReport()" [disabled]="!currentHolter || (!showReport && !currentHolter.flag_verified)">{{showReport ? "Edit Medflux" : "Edit Report"}}</button>
                    </div>
                  </div>
                  <div class="row">

                    <div class="col-12 md-width">

                      <h4>Holter Monitor Report</h4>
                      <form class="form-horizontal form-label-left">
                        <div class="form-group form-display row">
                          <label class="control-label col-2" for="first-name">Patient's Name </label>
                          <div class="col form-answer">{{(currentHolter.patient_last_name && currentHolter.patient_last_name) ? (currentHolter.patient_last_name + ', ' + currentHolter.patient_first_name) : '-'}}</div>
                          <label class="control-label col-2" for="last-name">Date of Test </label>
                          <div class="col form-answer">{{currentHolter.scan_date || '-'}}</div>
                        </div>
                        <div class="form-group form-display row">
                          <label class="control-label col-2" for="first-name">Date of Birth </label>
                          <div class="col form-answer">{{currentHolter.patient_birthdate || '-'}}</div>
                          <label class="control-label col-2" for="last-name">Referring Physician </label>
                          <div class="col form-answer">{{currentHolter.referring_doctor_name || '-'}}</div>
                        </div>
                        <div class="form-group form-display row">
                          <label class="control-label col-2" for="first-name">HIN </label>
                          <div class="col form-answer">{{buildHIN(currentHolter) || '-'}}</div>
                          <label class="control-label col-2" for="last-name"></label>
                          <div class="col form-answer"></div>
                        </div>
                        <br>
                        <div class="form-group form-display row">
                          <label class="control-label col-2" for="first-name">Indications </label>
                          <div class="col form-answer">{{getMedfluxData(currentHolter, 'indications') || '-'}}</div>
                        </div>
                        <div class="form-group form-display row">
                          <label class="control-label col-2" for="first-name">12 Lead ECG </label>
                          <div class="col form-answer">{{getMedfluxData(currentHolter, 'leadecg') || '-'}}</div>
                        </div>
                      </form>
                      <br>

                      <div class="form-group">
                        <h4>General Comments</h4>
                        <textarea #generalComment1Div class="form-control" rows="1" formControlName="GeneralComment1" [contextMenu]="suggestedMenu" [contextMenuSubject]="'GeneralComment1'"></textarea><br>
                        <textarea #generalComment2Div class="form-control" rows="1" formControlName="GeneralComment2" [contextMenu]="suggestedMenu" [contextMenuSubject]="'GeneralComment2'"></textarea><br>
                        <textarea #generalComment3Div class="form-control" rows="1" formControlName="GeneralComment3" [contextMenu]="suggestedMenu" [contextMenuSubject]="'GeneralComment3'"></textarea><br>
                        <textarea #generalComment4Div class="form-control" rows="1" formControlName="GeneralComment4" [contextMenu]="suggestedMenu" [contextMenuSubject]="'GeneralComment4'"></textarea><br>
                        <textarea #generalComment5Div class="form-control" rows="1" formControlName="GeneralComment5" [contextMenu]="suggestedMenu" [contextMenuSubject]="'GeneralComment5'"></textarea><br>
                        <br>

                        <h4>Diary Comments</h4>
                        <p><i>Patient diary was reviewed by analyst</i></p>
                        <textarea #specificComment1Div class="form-control" rows="1" formControlName="SpecificComment1" [contextMenu]="suggestedMenu" [contextMenuSubject]="'SpecificComment1'"></textarea><br>
                        <textarea #specificComment2Div class="form-control" rows="1" formControlName="SpecificComment2" [contextMenu]="suggestedMenu" [contextMenuSubject]="'SpecificComment2'"></textarea><br>
                        <textarea #specificComment3Div class="form-control" rows="1" formControlName="SpecificComment3" [contextMenu]="suggestedMenu" [contextMenuSubject]="'SpecificComment3'"></textarea><br>
                        <textarea #specificComment4Div class="form-control" rows="1" formControlName="SpecificComment4" [contextMenu]="suggestedMenu" [contextMenuSubject]="'SpecificComment4'"></textarea><br>
                        <textarea #specificComment5Div class="form-control" rows="1" formControlName="SpecificComment5" [contextMenu]="suggestedMenu" [contextMenuSubject]="'SpecificComment5'"></textarea><br>
                        <br>

                        <h4>Summary</h4>
                        <textarea #summary1Div class="form-control" rows="1" formControlName="Summary1" [contextMenu]="suggestedMenu" [contextMenuSubject]="'Summary1'"></textarea><br>
                        <textarea #summary2Div class="form-control" rows="1" formControlName="Summary2" [contextMenu]="suggestedMenu" [contextMenuSubject]="'Summary2'"></textarea><br>
                        <textarea #summary3Div class="form-control" rows="1" formControlName="Summary3" [contextMenu]="suggestedMenu" [contextMenuSubject]="'Summary3'"></textarea><br>
                        <textarea #summary4Div class="form-control" rows="1" formControlName="Summary4" [contextMenu]="suggestedMenu" [contextMenuSubject]="'Summary4'"></textarea><br>
                        <textarea #summary5Div class="form-control" rows="1" formControlName="Summary5" [contextMenu]="suggestedMenu" [contextMenuSubject]="'Summary5'"></textarea><br>
                        <textarea #additionalComment1Div class="form-control" rows="1" formControlName="AdditionalComment1" placeholder="Additional notes from physician if necessary"></textarea><br>
                        <br>

                        <!--
                        <h4>Additional Comments</h4>
                        <textarea #additionalComment1Div class="form-control" rows="1" formControlName="AdditionalComment1"></textarea><br>
                        <textarea #additionalComment2Div class="form-control" rows="1" formControlName="AdditionalComment2"></textarea><br>
                        <textarea #additionalComment3Div class="form-control" rows="1" formControlName="AdditionalComment3"></textarea><br>
                        <textarea #additionalComment4Div class="form-control" rows="1" formControlName="AdditionalComment4"></textarea><br>
                        <textarea #additionalComment5Div class="form-control" rows="1" formControlName="AdditionalComment5"></textarea><br>
                        -->

                      </div>
                      <br>

                      <form class="form-horizontal form-label-left">
                        <div class="form-group form-display row">
                          <label class="control-label col-1" for="first-name">Analyst: </label>
                          <div class="col form-answer">{{getMedfluxData(currentHolter, 'analyst') || '-'}}</div>
                        </div>
                        <div class="form-group form-display row">
                          <label class="control-label col-1" for="first-name">Editor: </label>
                          <div class="col form-answer">{{getMedfluxData(currentHolter, 'editor') || '-'}}</div>
                        </div>
                        <!-- <div class="form-group form-display row">
                          <div class="col text-right">{{currentHolter.reading_doctor_name || ''}}</div>
                        </div> -->
                      </form>



                    </div>
                  </div>
                </form>

              </div>
            </as-split-area>

          </as-split>



        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="row sub-footer">
      <div class="col-12">
        <button class="btn btn-primary btn-review" type="button" (click)="autofillForm()" [disabled]="!currentHolter || showReport"><i class="fa fa-flash"></i> Autofill</button>
        <button class="btn btn-primary btn-review" type="button" (click)="submitForm()" [disabled]="!currentHolter || !showReport || (!formGroupReport.valid && showReport)" title="{{submitTooltip()}}"><i class="fa fa-check"></i> Submit</button>
      </div>
    </div>
  </div>

  <!-- this is for contextMenu -->
  <context-menu>
    <ng-template *ngFor="let phrase of PRECANNED_PHRASES" contextMenuItem (execute)="contextMenuClick($event, phrase)">{{phrase}}</ng-template>
  </context-menu>

  <!-- this is for REQ contextMenu -->
  <context-menu #reqMenu>
    <ng-template *ngFor="let reqInfo of REQ_OPTIONS" contextMenuItem (execute)="reqContextMenuClick($event, reqInfo)">{{reqInfo.text}}</ng-template>
  </context-menu>

  <!-- this is for DXCODE contextMenu
  <context-menu #dxcodeMenu>
    <ng-template *ngFor="let codeInfo of DXCODE_OPTIONS" contextMenuItem (execute)="dxcodeContextMenuClick($event, codeInfo)">{{codeInfo.text.substring(4)}}</ng-template>
  </context-menu>
  -->

</div>

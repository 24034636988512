import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { version } from '../../../package.json';

import { environment } from "../../environments/environment";
import { JOB_STATUS, JOBS } from "../config/constants";
import { AuthService } from "../providers/auth.service";
import { Fax } from "../models/fax.model";
import { DataTablesResponse } from "../models/database-response.model";

import { formatLocaleTime } from "../utils/format";

@Injectable()
export class FaxService {

  private readonly JOB_URL: string = environment.api + "/job";
  private readonly CLIENT_NAME: string = "cardio_study";

  private triggerFilter: string | null = null;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  public updateTriggerFilter(trigger: string | null) {
    this.triggerFilter = trigger;
  }

  // this is used to override the ajax call in datatables for server-side processing
  public getAjaxFuncion(): any {

    // pass this class to the callback through "that"
    const that: FaxService = this;

    // server side rendering
    return (dataTablesParams: any, callback: any): void => {

      // populate the info in multiform part
      dataTablesParams["table"] = "fax";

      if (this.triggerFilter !== null) {
        dataTablesParams.columns.push({
          data: 'fax:trigger',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            value: this.triggerFilter,
          },
        });
      }

      const formData: FormData = new FormData();
      formData.append('info', JSON.stringify({
        client: that.CLIENT_NAME,
        job_name: "query_data",
        job_id: null,
        task_status: JOB_STATUS.CREATE,
        data: dataTablesParams
      }));

      // request the data from url
      that.http.post(that.JOB_URL, formData, this.httpOptions()).toPromise()
        .then((response: any) => {

          // callback to the datatable to rerender with the updated information
          const result: DataTablesResponse = response.Payload.data.result;

          callback({
            recordsTotal: result.recordsTotal,
            recordsFiltered: result.recordsFiltered,
            data: result.data.map(formatLocaleTime),
          });

        })
        .catch((error: any) => {
          console.log("Error datatable handler for fax: " + error.message);
        });
    };
  }

  public getFax(id: number): Promise<Fax | null> {
    if (!id) {
      return Promise.resolve(null);
    }

    // populate the info in multiform part
    const query: any = {
      draw: 1,
      columns: [
        { data: "fax:id", search: {value: id.toString(), regex: false} },
        { data: "fax:job_id" },
        { data: "fax:file_name" },
        { data: "fax:original_path" },
        { data: "fax:pdf_path" },
        { data: "fax:doctor" },
        { data: "fax:fax_number" },
        { data: "fax:status" },
        { data: "fax:received_at" },
        { data: "fax:cancelled_at" },
        { data: "fax:attempt_at" },
        { data: "fax:attempts" },
        { data: "fax:attempt_log" },
        { data: "fax:success_at" },
        { data: "fax:to_fax" },
        { data: "fax:fax_notes" },
        { data: "fax:queue_id" },
        { data: "fax:holter_id" },
        { data: "fax:holter_additional_fax_numbers" },
        { data: "fax:clinic_additional_fax_numbers" },
      ],
      order: [],
      table: "fax",
      start: 0,
      length: 1,
      search: {},
    };

    const formData: FormData = new FormData();
    formData.append('info', JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "query_data",
      job_id: null,
      task_status: JOB_STATUS.CREATE,
      data: query,
    }));

    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => {
        const result: DataTablesResponse = response.Payload.data.result;
        if (result.data.length <= 0) {
          return null;
        }

        result.data = result.data.map(formatLocaleTime);
        const raw: any = result.data[0];
        let fax: Fax = new Fax();
        fax.id = raw["fax:id"];
        fax.job_id = raw["fax:job_id"];
        fax.file_name = raw["fax:file_name"];
        fax.original_path = raw["fax:original_path"];
        fax.pdf_path = raw["fax:pdf_path"];
        fax.doctor = raw["fax:doctor"];
        fax.fax_number = raw["fax:fax_number"];
        fax.status = raw["fax:status"];
        fax.received_at = raw["fax:received_at"];
        fax.cancelled_at = raw["fax:cancelled_at"];
        fax.attempt_at = raw["fax:attempt_at"];
        fax.attempts = raw["fax:attempts"];
        fax.attempt_log = JSON.parse(raw["fax:attempt_log"]);
        fax.success_at = raw["fax:success_at"];
        fax.to_fax = raw["fax:to_fax"];
        fax.fax_notes = raw["fax:fax_notes"];
        fax.queue_id = raw["fax:queue_id"];
        fax.holter_id = raw["fax:holter_id"];
        fax.holter_additional_fax_numbers = raw["fax:holter_additional_fax_numbers"];
        fax.clinic_additional_fax_numbers = raw["fax:clinic_additional_fax_numbers"];
        return fax;
      })
      .catch((error: any) => {
        console.log("Failed to get fax: " + error.message);
        return null;
      });
  }

  public getPreview(fax: Fax): Promise<string> {

    // construct the request
    const formData: FormData = new FormData();
    formData.append('info', JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "download_file",
      job_id: null,
      task_status: JOB_STATUS.CREATE,
      data: { "file_path": fax.pdf_path },
    }));

    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => response.Payload.data)
      .catch((error: any) => {
        console.log("Failed to get fax preview: " + error.message);
        return null;
      });
  }

  public updateFax(faxId: number, data: any): Promise<any> {
    const url = environment.api + '/fax/' + faxId;
    return this.http.post(url, data, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public verifyAndSchedule(faxId: number, data: any): Promise<any> {
    const url = environment.api + '/fax/' + faxId + '/verify';
    return this.http.post(url, data, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public action(faxId: number, action: string): Promise<any> {
    const url = environment.api + '/fax/' + faxId + '/action';
    return this.http.post(url, {action: action}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public actionRingCentral(faxId: number, action: string, index: number): Promise<any> {
    const url = environment.api + '/fax/' + faxId + '/ringcentral/action';
    return this.http.post(url, {action: action, index: index}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getS3Preview(faxId: number): Promise<any> {
    const url = environment.api + '/fax/' + faxId + "/preview/presigned-url";
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  private httpOptions(): any {
    return { headers: new HttpHeaders({
      "token": this.authService.getToken(),
      "version": version,
    }) };
  }

}
import { AfterViewInit, Component, OnInit, ViewChildren, ViewChild, QueryList, OnDestroy } from '@angular/core';
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { saveAs } from "file-saver";
import { ContextMenuComponent } from "ngx-contextmenu";

import { DataTableDirective } from 'angular-datatables';

import { HolterService } from "../../providers/holter.service";
import { AuthService } from "../../providers/auth.service";
import { AbpmService } from "../../providers/abpm.service";
import { VisitService } from "../../providers/visit.service";

import { Holter } from "../../models/holter.model";

import { formatNotes, formatNotesMultiple, formatLocaleDateSingle } from "../../utils/format";
import * as moment from "moment";

@Component({
  selector: 'app-debug-spark',
  templateUrl: './debug-spark.component.html',
  styleUrls: ['./debug-spark.component.scss']
})
export class DebugSparkComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(DataTableDirective)
  // dtElements: QueryList<DataTableDirective>;
  private datatableElement: DataTableDirective;
  @ViewChild(ContextMenuComponent)
  public suggestedMenu: ContextMenuComponent;

  public dtTrigger1: any = new Subject();
  public dtTrigger2: any = new Subject();
  public dtOptions: any = {};
  public dtOptionsPending: any = {};
  public dtOptionsRaw: any = {};

  public dtInstancePending: any = null;
  public dtInstanceNotPending: any = null;

  public abpmStatusFilter: string | null = "";
  public holterStatusFilter: string | null = "";
  public currentHolter: Holter;
  public formGroup: FormGroup;
  public staffNotes: string[] = [];

  holter_list: Holter[] = [];

  public ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger1.unsubscribe();
    this.dtTrigger2.unsubscribe();
  }

  public ngAfterViewInit(): void {

    const that = this;
    document.addEventListener("click",  function(e){
      if (!e.target) {
        return;
      }
      const className = (e.target as Element).className;
      if(className == "as-split-gutter-icon"){
        window.dispatchEvent(new Event('resize'));
        that.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.draw();
        });
      }
    });
    // this handles the filter tabs on the top of the table
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) =>  {
      if (dtInstance === undefined) {
        return;
      }
      $("#search-bar").on("keyup change", function () {
        setTimeout(() => {
          const search_string = this["value"].replace(',', '')
          if (dtInstance.search() !== search_string) {
            dtInstance.search(search_string).draw();
          }
        }, 1000);
      });
      $("#search-bar").focus();
    });
  }

  public ngOnInit() {}

  constructor(
    private abpmService: AbpmService,
    private holterService: HolterService,
    private visitService: VisitService,
    private authService: AuthService,
    private router: Router
  ) {

    // roll callback
    const rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
      // $("td", row).unbind("click");
      $("td", row).bind("click", () => {
        this.rowClickHandler(data);
      });
      return row;
    };

    this.statusFilterChanged();
    // datatable options
    this.dtOptions = {
        autoWidth: false,
        responsive: true,
        lengthChange: false,
        select: true,
        pageLength: 30,
        dom: "Blfrtip",
        buttons: [],
        rowCallback: rowCallback,
        serverSide: true,
        processing: true,
        ajax: this.holterService.getAjaxFuncion(),
        order: [[ 1, "desc" ]],
        columns: [
          {
            data: "holter:state",
            render: function ( data, type, row, meta ) {
              return row["holter:closed_by"] ? "SUSPENDED" : data;
            }
          },
          { data: "holter:scan_date" },
          {
            data: "patient:last_name",
            orderable: true,
            render: function ( data, type, row, meta ) {
              const last = row["patient:last_name"];
              const first = row["patient:first_name"];
              if (!last && !first) {
                return "";
              } else if (!last) {
                return first;
              } else if (!first) {
                return last;
              } else {
                return last + ", " + first;
              }
            },
          },
          { data: "patient:first_name", visible: false },
          { data: 'holter:id', visible: false },
          { data: 'holter:closed_by', visible: false },
        ],
        language: {
          infoFiltered: ""
        }
    };
    this.formGroup = new FormGroup({
      SyncedAt: new FormControl("", []),
      RequestedAt: new FormControl("", []),
      Computer: new FormControl("", []),
      ComputerSlot: new FormControl("", []),
      UpdatedAt: new FormControl("", []),
      Key: new FormControl("", []),
      NeedFlattenTracing: new FormControl("", []),
      NeedFlattenBatch: new FormControl("", []),
      NeedAnnotateAt: new FormControl("", []),
      StaffNotes: new FormControl("", []),
    });
  }

  public statusFilterChanged(): void {
    this.holterService.clearFilters();
    this.holterService.updatePdfFilter(false);
    this.holterService.updateClosedFilter(false);
    if (this.holterStatusFilter == "SUSPENDED") {
      this.holterService.updateClosedFilter(true);
    }
    if (this.holterStatusFilter && this.holterStatusFilter != "SUSPENDED") {
      this.holterService.updateStateFilter([this.holterStatusFilter]);
      this.holterService.updateClosedFilter(false);
    } else {
      this.holterService.updateStateFilter(["EDITING", "RESERVED", "READING", "FAXING"]);
    }
    this.reloadTable();
  }

  private reloadTable() {
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw(false);
    });
  }

  public rowClickHandler(info: any) {
    // this.router.navigateByUrl('/abpm/detail/interpretations/' + info["abpm:id"]);
    this.holterService.getHolter(info["holter:id"]).then(holter => {
      this.populateHolter(holter);
    });
  }

  private populateHolter(holter: Holter)
  {
    this.currentHolter = holter;
    this.formGroup.reset();
    this.formGroup.patchValue({
      SyncedAt: holter.synced_at,
      RequestedAt: holter.sync_requested_at,
      UploadedFiles: holter.uploaded_files,
      Computer: holter.upload_computer,
      ComputerSlot: holter.upload_computer_slot,
      UpdatedAt: holter.updated_at,
      Key: holter.s3_key,
      NeedFlattenTracing: holter.needFlattenTracing,
      NeedFlattenBatch: holter.needFlattenBatch,
      NeedAnnotateAt: holter.needReannotateAt,
      StaffNotes: formatNotesMultiple([holter.notes, holter.requisition_staff_notes]),
    })
  }

  public queryHolter() {
    // this.abpmService.exportQuery(this.abpmStatusFilter, true).subscribe(data => {
    //   saveAs(data, "query.csv");
    // });
  }

  public runScan(){
    this.holterService.clearFilters();
    $("body, button").css("cursor", "wait");
    this.holterService.pdfComparison().then(data => {
      let temp = []
      if (data.length > 500)
        temp = data.slice(0,499);
      else
        temp = data;
      this.holterService.updatePdfComparisonFilter(temp);
      $("body, button").css("cursor", "auto");
      this.reloadTable();
    });
  }

  public syncHolter() {
    this.datatableElement?.dtInstance.then((dtInstance: any) => {
      const updatePromises = [];
      dtInstance.rows(".selected").every((idx) => {
        const entry = dtInstance.row(idx).data();
        updatePromises.push(this.holterService.syncRequest(entry["holter:id"]));
      });
      Promise.all(updatePromises).then(() => dtInstance.draw(false));
    });
  }

  public updateFileHolter() {
    this.datatableElement?.dtInstance.then((dtInstance: any) => {
      const updatePromises = [];
      dtInstance.rows(".selected").every((idx) => {
        const entry = dtInstance.row(idx).data();
        updatePromises.push(this.holterService.updateFileComparison(entry["holter:id"]));
      });
      Promise.all(updatePromises).then(() => dtInstance.draw(false));
    });
  }

  public bucketCheckHolter() {
    this.datatableElement?.dtInstance.then((dtInstance: any) => {
      const updatePromises = [];
      dtInstance.rows(".selected").every((idx) => {
        const entry = dtInstance.row(idx).data();
        updatePromises.push(this.holterService.pdfBucketCheck(entry["holter:id"]));
      });
      Promise.all(updatePromises).then(() => dtInstance.draw(false));
    });
  }

  public formatDate(time: any){
    return formatLocaleDateSingle(time)
  }

  public queryLastBucketCheckHolters() {
    this.holterService.exportQuery("Last Bucket Check", true).subscribe(data => {
      saveAs(data, "query.csv");
    });
  }

}

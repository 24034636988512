<div class="md-width">

  <div class="row sub-header">
    <div class="col-12">
      <div class="text-center">
        <br>
        <ul class="list-inline">
          <!-- <li *ngIf="showSearchTab$ | async as showSearchTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/holter/search')"
            [class.table-filter-selected]="urlContains('search')"
            [hidden]="!showSearchTab">
              <div class="col"><p class="text-center">Search</p></div>
          </li> -->
          <li *ngIf="showVisitTab$ | async as showVisitTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/holter/visit')"
            [class.table-filter-selected]="urlContains('visit')"
            [hidden]="!showVisitTab">
              <div class="col"><p class="text-center">Visit</p></div>
          </li>
          <li *ngIf="showPendingTab$ | async as showPendingTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/holter/pending')"
            [class.table-filter-selected]="urlContains('pending')"
            [hidden]="!showPendingTab">
              <div class="col"><p class="text-center">Pending</p></div>
          </li>
          <li *ngIf="showEditingTab$ | async as showEditingTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/holter/editing')"
            [class.table-filter-selected]="urlContains('editing')"
            [hidden]="!showEditingTab">
              <div class="col"><p class="text-center">Editing</p></div>
          </li>
          <li *ngIf="showReservedTab$ | async as showReservedTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/holter/reserved')"
            [class.table-filter-selected]="urlContains('reserved')"
            [hidden]="!showReservedTab">
              <div class="col"><p class="text-center">Reserved</p></div>
          </li>
          <li *ngIf="showReadingTab$ | async as showReadingTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/holter/reading')"
            [class.table-filter-selected]="urlContains('reading')"
            [hidden]="!showReadingTab">
              <div class="col"><p class="text-center">Reading</p></div>
          </li>
          <li *ngIf="showRequisitionTab$ | async as showRequisitionTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/holter/requisition')"
            [class.table-filter-selected]="urlContains('requisition')"
            [hidden]="!showRequisitionTab">
              <div class="col"><p class="text-center">Requisition</p></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
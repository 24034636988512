<div class="">
  <div class="page-title">
    <div class="title_left">
      <h3>Reports</h3>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div class="x_panel">
        <div class="x_content">
          <br />
          <h4>Coming Soon</h4>
        </div>
      </div>
    </div>
  </div>
</div>
export const JOB_STATUS: any = {
  CREATE: "create",
  UPDATE: "update",
  CANCEL: "cancel",
  SCHEDULE: "schedule",
}

export const JOBS: any = {
  OCR_FAX: "ocr_fax",
}

export const DXCODE_OPTIONS = [
  {code: 403, text:"403: Hypertensive heart disease"},
  {code: 426, text:"426: Bradycardia, Sick sinus syndrome"},
  {code: 427, text:"427: A-fib, Tachyarrhythmias, Cardiac arrest"},
  {code: 435, text:"435: TIA, Fainting, Dizziness, Syncopy, Pre-syncopy"},
  {code: 436, text:"436: CVA, Stroke"},
  {code: 412, text:"412: Myocardia infarct, Heart attack"},
  {code: 785, text:"785: Chest pains, tachycardia"},
  {code: 786, text:"786: Shortness of breath, dyspnea"},
];

export const REQ_OPTIONS = [
  {text:"Abnormal ECG"},
  {text:"Palpitations"},
  {text:"Syncope / Fainting Spells"},
  {text:"Presyncope / Light-headedness"},
  {text:"Chest Pain / Shortness of Breath"},
  {text:"Fatigue / Weakness"},
  {text:"R/O Atrial Fibrillation / Flutter"},
  {text:"Atrial Fibrillation Rate Control"},
  {text:"Post Stroke / TIA"},
  {text:"Atrial Arrhythmia"},
  {text:"Ventricular Arrhythmia"},
  {text:"PACEMAKER"},
  {text:"VVI"},
  {text:"DDD"},
  {text:"R/O Arrhythmia"},
];

export const PAT_OPTIONS = ['VersionNo', 'Status', 'StatusDate', 'StatusTime', 'PatientName', 'PatientNameLast', 'PatientNameFirst', 'PatientNameMI', 'ScanNo', 'IdNo', 'DOB', 'DOBunknown',
'Sex', 'Age', 'AgeUnit', 'Physician', 'PhysicianInterp', 'HookupTech', 'Analyst', 'DateRecorded', 'RecorderNo', 'RecorderType', 'TapeTimeBeg', 'TapeTimeTotal', 'TapeTimeReq', 'PrimaryChannel',
'AlternateChannel', 'TapeSkew', 'TapeClockErrorTime', 'Invert', 'HideChannel', 'Gain', 'StripSaveMax', 'StripSaveMaxInter', 'StripSaveMaxInterSame', 'StripSaveMaxSame', 'StripSaveMinTimeSame',
'StripSaveMaxSt', 'AutoLabelStrips', 'AutoStripIrregularRR', 'AutoStrip1perHR', 'AutoStripArrest', 'AutoStripBigeminy', 'AutoStripBradycardia', 'AutoStripCalibration', 'AutoStripCaptureFail',
'AutoStripDiary', 'AutoStripEvent', 'AutoStripFastSVT', 'AutoStripFastVT', 'AutoStripInhibit', 'AutoStripLongSVT', 'AutoStripLongVT', 'AutoStripMaxHR', 'AutoStripMaxRR', 'AutoStripMinHR',
'AutoStripMinRR', 'AutoStripNewVPB', 'AutoStripPause', 'AutoStripQuadrigeminy', 'AutoStripRonT', 'AutoStripSTBaseline', 'AutoStripSTDepression', 'AutoStripSTDeviation', 'AutoStripSTElevation',
'AutoStripSTEnd', 'AutoStripSTMaxHR', 'AutoStripSTOnset', 'AutoStripSVPB', 'AutoStripSVPBPair', 'AutoStripSVT', 'AutoStripSenseFail', 'AutoStripTachycardia', 'AutoStripTrigeminy',
'AutoStripVPB', 'AutoStripVPBPair', 'AutoStripVTAC', 'AutoStripEarlyVPB', 'HRateVTAC', 'HRateSVT', 'HRateTachy', 'HRateBrady', 'LengthPause', 'LengthArrest', 'ProcNumChans',
'ProcAutoChanSelect', 'ProcAutoStSelect', 'ProcSignalQuality', 'ExtraDeadTime', 'ModeNarrowQRS', 'ModeArtifactFilter', 'PaceType', 'PaceHrateMin', 'PaceHrateMax', 'RepIntervSize',
'RepReuseECG', 'PrematuritySVPB', 'PrematurityVPB', 'PauseAfib', 'FullDisclosure', 'Calibration', 'ProcStEvents', 'StElevationTime', 'StDepressionTime', 'StMaxElevationCh',
'StMaxDepressionCh', 'StMaxElevationTime', 'StMaxDepressionTime', 'JSt', 'LeadContInterval', 'Notes', 'Custom_01', 'Custom_02', 'Custom_03', 'Custom_04', 'Custom_05', 'Custom_06',
'Custom_07', 'Custom_08', 'Custom_09', 'Custom_10', 'Custom_11', 'Custom_12', 'Custom_13', 'Custom_14', 'Custom_15', 'Custom_16', 'Custom_17', 'Custom_18', 'Custom_19', 'Custom_20',
'ErrorLimitCalIso', 'ErrorLimitCalJ', 'ErrorLimitCalP', 'ErrorLimitCalQ', 'ErrorLimitCalS', 'ErrorLimitCalStSeg', 'ErrorLimitCalT', 'ExportExe', 'ExportLabel', 'ImportExe', 'ImportLabel',
'IsEdit', 'IsFinished', 'IsPrint', 'IsLock', 'LeadDispNonCaliper', 'LeadDispNonNormal', 'LeadRefRatio', 'LeadRefThresh', 'LeadInclMedNotCalc', 'MinPeakCalJ', 'MinPeakCalP', 'MinPeakCalQ',
'MinPeakCalS', 'MinPeakCalT', 'PaceIntPrMax', 'PaceIntVpaceRMax', 'PaceSvpbAllowed', 'SingleEventMax', 'IgnoreFlashFile', 'Reprocess12lead', 'patienttype', 'countirregularhrasaf', 'disablesvpb',
'ConfigurationDescription', 'ConfigFilePath', 'Release', 'height', 'weight', 'bmi', 'heightUnit', 'weightUnit', 'QTcCalculation', 'ApneaUpToDate', 'Front_Page_Height', 'Front_Page_Width',
'stripsort', 'PatientUUID', 'channel1label', 'channel2label', 'channel3label', 'labeleventsasartifact', 'MaximumAFInterruption', 'MinimumAFLength', 'Front_Page_Label', 'Front_Page_Type',
'ExpandSavedStrips', 'RemoteUser', 'RemotePreservedCookie', 'PatientOrigin', 'configurationlabel', 'verificationno', 'UseEncryption', 'DebugLogging', 'Annotate', 'Custom', 'Description',
'GainCalibration', 'MaskFull', 'MaskStrip', 'PrintAnnotate', 'PrintManager', 'StMeasurement', 'Summary', 'qtccalculation', 'Background', 'Vent', 'Svent', 'Artifact', 'Normal', 'Pause',
'Paced', 'Drag', 'Box', 'Border', 'RRText', 'EcgGrid', 'ManyLabel', 'ManySelect', 'Reviewbar', 'Beat', 'OxiPulse', 'Expand', 'Chan', 'Zoom', 'RelMode', 'Seconds', 'Other', 'Data', 'Type',
'Hours', 'StBaseLine', 'StEvent', 'Grid', 'Text', 'Hr', 'RR', 'St1', 'St2', 'Oxi', 'OxiEvent', 'OxiHr', 'Sort', 'Interval', 'Graph', 'Select', 'Cal1', 'Cal2', 'Cal3', 'Markers', 'Rate',
'Hash', 'ColorText', 'ColorAxis', 'ColorPlot', 'Scale', 'ColorCal', 'ColorExclude', 'ColorMax', 'ColorMin', 'ColorRef', 'ColorRefThresh', 'FilterLine', 'Measure', 'ColorMeshTop', 'ColorMeshBot',
'ColorBack', 'StLow', 'StHi', 'ChanMask', 'Leads', 'View1', 'View2', 'View3', 'Vselect', 'Rotate', 'RotateB', 'ColorQt', 'ColorPr', 'ColorQrs', 'ColorHr', 'ColorSt', 'ColorQtd', 'DrawShaded',
'DrawContours', 'DrawZones', 'HiddenLines', 'MeshX', 'MeshY', 'Z5P0', 'Z4P5', 'Z4P0', 'Z3P5', 'Z3P0', 'Z2P5', 'Z2P0', 'Z1P5', 'Z1P0', 'ZP5', 'Z0', 'Range01', 'Range02', 'Range03', 'Range04',
'Range05', 'Range06', 'Range07', 'Check', 'Sdnn', 'Rms', 'Sdsd', 'Nn50', 'Pnn50', 'MeanRr', 'ProcTime', 'WindowSize', 'StabilityCnt', 'WindowThresh', 'OnsetTermThresh', 'AbsDeprThresh',
'DevDeprThresh', 'AbsElevThresh', 'DevElevThresh', 'MinDur', 'OnsetTermDur', 'ConsecCnt', 'Run', 'Window', 'Size', 'AvgOfLog', 'AvgSeconds', 'Spacing', 'MinValPct', 'DiffIndLim', 'AvgFreq',
'RangeBeg', 'RangeEnd', 'LogSpac24Hr', 'AveCnt24Hr', 'PeakCnt24Hr', 'Extrapolate', 'V', 'Event', 'EventMin', 'EventLen', 'ArtSkip', 'ArtMinSep', 'Mod', 'Company', 'LastName', 'FirstName', 'MI',
'Address1', 'Address2', 'Address3', 'City', 'State', 'Zip', 'Telephone', 'Fax', 'Email', 'Line1', 'Line2', 'Line3', 'Line4', 'Line5', 'Line6', 'Line7', 'Line8', 'Line9', 'Line10', 'SelectedReports',
'SampleRate', 'SampleStorageFormat', 'PaceWidthDetection', 'starttime', 'basehr', 'basebp', 'basespo2', 'baseo2', 'basedysdesc', 'basedysdescText', 'basefatdesc', 'basefatdescText', 'stoptime',
'posthr', 'postbp', 'postspo2', 'posto2', 'postdysdesc', 'postdysdescText', 'postfatdesc', 'postfatdescText', 'testnum', 'testtot', 'totaldistance', 'totaldistanceunits', 'walkpace', 'walkpaceText',
'numstops', 'Label0', 'Start0', 'Stop0', 'Label1', 'Start1', 'Stop1', 'Label2', 'Start2', 'Stop2', 'Label3', 'Start3', 'Stop3', 'Label4', 'Start4', 'Stop4', 'Label5', 'Start5', 'Stop5',
'ScreenDisplayInterval', 'SPO2_Detected_SPHR', 'SPO2_Desat_calculated', 'SamplePeakDetection', 'start_time', 'start_date', 'Serial_number', 'Recorder_version', 'custcode', 'VerificationNo',
'patient_id', 'DiaryText', 'date', 'time', 'id', 'type']

export const PDF_OPTIONS = ['GENDER', 'LAST NAME', 'FIRST NAME', 'HEALTH CARD', 'DATE OF BIRTH', 'REFERRING DR', 'READING DR', 'DATE OF TEST', 'DAYS RECORDED', 'ANALYZED HOURS',
'ANALYZED MINUTES', 'TOTAL BEATS', 'LONGESTRR', 'MINIMUM HR', 'MAXIMUM HR', 'AVERAGE HR', 'PACEMAKER SUMMARY TOTAL', 'PACEMAKER SUMMARY A PACED', 'PACEMAKER SUMMARY V PACED',
'PACEMAKER SUMMARY AV PACED', 'VENTRICULAR SUMMARY TOTAL', 'VENTRICULAR SUMMARY PAIRS', 'VENTRICULAR SUMMARY RUNS', 'VENTRICULAR SUMMARY LONGESTVTAC', 'VENTRICULAR SUMMARY LONGESTVTAC TIME',
'VENTRICULAR SUMMARY LONGESTVTAC DAYS', 'VENTRICULAR SUMMARY HR2', 'VENTRICULAR TRIPLETS', 'SUPRAVENTRICULAR SUMMARY TOTAL', 'SUPRAVENTRICULAR SUMMARY PAIRS', 'SUPRAVENTRICULAR SUMMARY RUNS',
'SUPRAVENTRICULAR SUMMARY LONGESTSVT', 'SUPRAVENTRICULAR SUMMARY LONGESTSVT TIME', 'SUPRAVENTRICULAR SUMMARY LONGESTSVT DAYS', 'SUPRAVENTRICULAR SUMMARY HR2', 'ATRIAL TRIPLETS', 'PAUSES',
'COMMENTS', 'SYMPTOMS']

// ['GENDER', 'LAST NAME', 'FIRST NAME', 'HEALTH CARD', 'DATE OF BIRTH', 'REFERRING DR', 'READING DR', 'DATE OF TEST', 'DAYS RECORDED', 'ANALYZED HOURS',
// 'ANALYZED MINUTES', 'TOTAL BEATS', 'LONGESTRR', 'MINIMUM HR', 'MAXIMUM HR', 'AVERAGE HR', 'PACEMAKER SUMMARY TOTAL', 'PACEMAKER SUMMARY A PACED', 'PACEMAKER SUMMARY V PACED',
// 'PACEMAKER SUMMARY AV PACED', 'VENTRICULAR SUMMARY TOTAL', 'VENTRICULAR SUMMARY PAIRS', 'VENTRICULAR SUMMARY RUNS', 'VENTRICULAR SUMMARY LONGESTVTAC', 'VENTRICULAR SUMMARY LONGESTVTAC TIME',
// 'VENTRICULAR SUMMARY LONGESTVTAC DAYS', 'VENTRICULAR SUMMARY HR2', 'VENTRICULAR TRIPLETS', 'SUPRAVENTRICULAR SUMMARY TOTAL', 'SUPRAVENTRICULAR SUMMARY PAIRS', 'SUPRAVENTRICULAR SUMMARY RUNS',
// 'SUPRAVENTRICULAR SUMMARY LONGESTSVT', 'SUPRAVENTRICULAR SUMMARY LONGESTSVT TIME', 'SUPRAVENTRICULAR SUMMARY LONGESTSVT DAYS', 'SUPRAVENTRICULAR SUMMARY HR2', 'ATRIAL TRIPLETS', 'PAUSES',
// 'COMMENTS', 'SYMPTOMS', 'EDITOR', 'ANALYST', 'ECG INTERPRETATION', 'INDICATIONS', 'RHYTHM 1', 'RHYTHM 2', 'RHYTHM 3', 'ARRHYTHMIA 1', 'ARRHYTHMIA 2', 'ARRHYTHMIA 3', 'ARRHYTHMIA 4',
// 'EVENT 1', 'EVENT 2', 'EVENT 3', 'EVENT 4']
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";

import { DataTableDirective } from 'angular-datatables';
import { saveAs } from "file-saver";

import { VisitService } from "../../providers/visit.service";
import { AbpmService } from "../../providers/abpm.service";
import { AuthService } from "../../providers/auth.service";

@Component({
  selector: 'app-billing-abpm',
  templateUrl: './billing-abpm.component.html',
  styleUrls: ['./billing-abpm.component.scss']
})
export class BillingAbpmComponent implements OnInit {
  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;
  public dtOptions: any = {};
  public abpmStatusFilter: string | null = "All";
  public startDateFilter: string | null = null;
  public endDateFilter: string | null = null;

  constructor(
    private visitService: VisitService,
    private router: Router,
    private abpmService: AbpmService,
    private authService: AuthService,
  ) {

    // roll callback
    const rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
      $("td", row).bind("click", () => this.rowClickHandler(data));
      return row;
    };

    this.abpmService.clearFilters();

    // datatable options
    this.dtOptions = {
        autoWidth: false,
        responsive: true,
        lengthChange: false,
        select: true,
        pageLength: 25,
        dom: "Blfrtip",
        buttons: [],
        rowCallback: rowCallback,
        serverSide: true,
        processing: true,
        ajax: this.abpmService.getAjaxFuncion(),
        // ajax: this.visitService.getBillingAjaxFuncion(),
        order: [[ 1, "desc" ]],
        columns: [
          { data: "abpm:status" },
          { data: "visit:hookup_at" },
          { data: "patient:health_card" },
          { data: "patient:last_name" },
          { data: "patient:first_name" },
          { data: "owner:name" },
          { data: "signoff_doctor:name" },
          { data: 'abpm:signed_off_at'},
          { data: 'abpm:id', visible: false },
          { data: 'visit:id', visible: false },
        ],
        language: {
          infoFiltered: ""
        }
    };

  }

  public rowClickHandler(info: any) {
    this.router.navigateByUrl('/billing/abpm/' + info["visit:id"]);
  }

  public statusFilterChanged(): void {
    this.abpmService.clearFilters();
    this.abpmService.updateStatusFilter(this.abpmStatusFilter);
    this.reloadTable();
  }

  private reloadTable() {
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw(false);
    });
  }

  public queryAbpm() {
    this.abpmService.exportQuery("Billing ABPM", false, this.startDateFilter, this.endDateFilter).subscribe(data => {
      saveAs(data, "billingABPM.csv");
    });
  }

  ngOnInit(): void {
  }

}

<div class="modal-body">
    <div class="col">
        <div class="row">
            <div class="col">

                <form class="form-horizontal form-label-left" [formGroup]="formGroup">
                    <div class="row">
                        <div class="col form-group text-center">
                            <div class="title-selection-orange">Spark Slot Display</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group text-center">
                            <div class="title-selection-orange">Computer Path: {{path}}</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group text-center">
                            <div class="title-selection-orange">Empty Slots Count: {{emptySlotsCount}}</div>
                        </div>
                    </div>
                </form>
                <table datatable [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover" id="branch-table">
                    <thead>
                        <tr>
                            <th *ngFor="let hd of tableHeaders">
                              {{hd}}
                            </th>
                          </tr>
                    </thead>
                </table>
            </div>
        </div>

    </div>
</div>
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { version } from '../../../package.json';

import { environment } from "../../environments/environment";
import { AuthService } from "../providers/auth.service";
import { Holter } from "../models/holter.model";
import { DataTablesResponse } from "../models/database-response.model";

import { formatLocaleTime } from "../utils/format";

import * as moment from "moment";
import * as MomentTZ from "moment-timezone";
import { Observable } from "rxjs";

@Injectable()
export class HolterService {

  private readonly JOB_URL: string = environment.api + "/job";
  private readonly CLIENT_NAME: string = "cardio_study";

  public readonly AUTOFILL_TO_FORM_MAPPING: any = {
    ReferringDoctorName: "REFERRING DR",
    FirstName: "FIRST NAME",
    LastName: "LAST NAME",
    HealthCard: "HEALTH CARD",
    HealthCardVersion: "HEALTH CARD VERSION",
    Birthdate: "DATE OF BIRTH",
    Duration: "DAYS RECORDED",
    ReadingDoctorName: "READING DR",
    TestDate: "DATE OF TEST",
    TimeAnalyzed: "ANALYZED TIME",
    MinHR: "MINIMUM HR",
    MaxHR: "MAXIMUM HR",
    MeanHR: "AVERAGE HR",
    TotalBeats: "TOTAL BEATS",
    Comments: "COMMENTS",
    PauseLongestRR: "LONGESTRR",
    PauseTime: "LONGESTRR TIME",
    PauseCount: "PAUSES",
    APBTotal: "SUPRAVENTRICULAR SUMMARY TOTAL",
    APBPair: "SUPRAVENTRICULAR SUMMARY PAIRS",
    APBSVT: "VENTRICULAR AIVR",
    VPBTotal: "VENTRICULAR SUMMARY TOTAL",
    VPBPair: "VENTRICULAR SUMMARY PAIRS",
    VPBVT: "VENTRICULAR SUMMARY RUNS",
    Symptoms: "SYMPTOMS",
    Rhythm1: "RHYTHM 1",
    Rhythm2: "RHYTHM 2",
    Rhythm3: "RHYTHM 3",
    Arrhythmia1: "ARRHYTHMIA 1",
    Arrhythmia2: "ARRHYTHMIA 2",
    Arrhythmia3: "ARRHYTHMIA 3",
    Arrhythmia4: "ARRHYTHMIA 4",
    EventNumber1: "EVENT 1",
    EventNumber2: "EVENT 2",
    EventNumber3: "EVENT 3",
    EventNumber4: "EVENT 4",
  };

  private currentFlags : any = null;

  // TODO: dictionary to an array so the order is guarenteed
  static readonly FLAG_COLUMNS: any = {
    'holter:flag_verified': {
      flagKey: 'flag_verified',
      trueValue: 1,
    },
    'holter:flag_pacemaker_inquiry': {
      flagKey: 'flag_pacemaker_inquiry',
      trueValue: 1,
    },
    'holter:flag_no_data': {
      flagKey: 'flag_no_data',
      trueValue: 1,
    },
    'holter:flag_no_req': {
      flagKey: 'flag_no_req',
      trueValue: 1,
    },
    'holter:flag_no_backup': {
      flagKey: 'flag_no_backup',
      trueValue: 1,
    },
    'holter:flag_no_tracings': {
      flagKey: 'flag_no_tracings',
      trueValue: 1,
    },
    'holter:flag_invalid_referring': {
      flagKey: 'flag_invalid_referring',
      trueValue: 1,
    },
    'holter:flag_invalid_healthcard': {
      flagKey: 'flag_invalid_healthcard',
      trueValue: 1,
    },
    'holter:flag_invalid_dob': {
      flagKey: 'flag_invalid_dob',
      trueValue: 1,
    },
    'holter:flag_need_repair': {
      flagKey: 'flag_need_repair',
      trueValue: 1,
    },
  };

  private prioritySort: boolean | null = null;
  private rejectedSort: boolean | null = null;

  private groupByInstruction: string | null = null;

  private reservedByFilter: string | null = null;
  private emailFilter: string | null = null;
  private computerFilter: string | null = null;
  private pathFilter: string | null = null;
  private stateFilter: string[] | null = null;
  private verificationNumberFilter: string | null = null;
  private deviceIdFilter: string | null = null;
  private billingCodeFilter: string | null = null;
  private hasBillingCodeFilter: boolean | null = null;
  private hasBillingDateFilter: boolean | null = null;
  private billingStatusFilter: any = null;
  private startScanDateFilter: any = null;
  private endScanDateFilter: any = null;
  private startBillingDateFilter: any = null;
  private endBillingDateFilter: any = null;
  private submittedByFilter: any = null;
  private pdfFilter: boolean | null = null;
  private patFilter: boolean | null = null;
  private backupFilter: boolean | null = null;
  private finalReportFilter: boolean | null = null;
  private requisitionFilter: boolean | null = null;
  private preliminaryFilter: boolean | null = null;
  private readingDoctorFilter: number | null = null;
  private rejectedFilter: boolean | null = null;
  private closedFilter: boolean | null = null;
  private abnormalFilter: boolean | null = null;
  private reservedFilter: boolean | null = null;
  private faxedFilter: boolean | null = null;
  private cancelledFaxFilter: boolean | null = null;
  private skipFaxingFilter: boolean | null = null;
  private skipFollowUpFilter: boolean | null = null;
  private addressedFilter: boolean | null = null;
  private calledFilter: boolean | null = null;
  private faxedStatusFilter: string | null = null;
  private invalidReadingDoctorFilter: boolean | null = null;
  private customFilter: string | null = null;
  private pdfComparisonFilter: string[] | null = null;
  private faxedTypeFilter: string | null = null;
  private doctorResubmittedFilter: boolean | null = null;
  private researchCustomSearchFilter: string | null = null;
  private researchAnchorFilter: string | null = null;
  private researchSourceFilter: string | null = null;
  private researchFilter: boolean | null = null;
  private batchCallFilter: boolean | null = null;

  private customFocusFilter: string | null = null;
  private clinicPhoneNumberFilter: string | null = null;
  private focusHolterIDFilter: string | null = null;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  public clearFocusFilters() {
    this.customFocusFilter = null;
    this.clinicPhoneNumberFilter = null;
    this.focusHolterIDFilter = null;
  }

  public clearFilters() {
    this.currentFlags = {
      flag_verified: null,
      flag_pacemaker_inquiry: null,
      flag_no_data: null,
      flag_no_req: null,
      flag_no_backup: null,
      flag_no_tracings: null,
      flag_invalid_referring: null,
      flag_invalid_healthcard: null,
      flag_invalid_dob: null,
      flag_need_repair: null,
    };

    this.prioritySort = null;
    this.rejectedSort = null;

    this.groupByInstruction = null;

    this.reservedByFilter = null;
    this.emailFilter = null;
    this.computerFilter = null;
    this.pathFilter = null;
    this.stateFilter = null;
    this.verificationNumberFilter = null;
    this.deviceIdFilter = null;
    this.billingCodeFilter = null;
    this.hasBillingCodeFilter = null;
    this.hasBillingDateFilter = null;
    this.billingStatusFilter = null;
    this.startScanDateFilter = null;
    this.endScanDateFilter = null;
    this.startBillingDateFilter = null;
    this.endBillingDateFilter = null;
    this.submittedByFilter = null;
    this.pdfFilter = null;
    this.patFilter = null;
    this.backupFilter = null;
    this.finalReportFilter = null;
    this.requisitionFilter = null;
    this.preliminaryFilter = null;
    this.readingDoctorFilter = null;
    this.rejectedFilter = null;
    this.closedFilter = null;
    this.abnormalFilter = null;
    this.reservedFilter = null;
    this.faxedFilter = null;
    this.cancelledFaxFilter = null;
    this.skipFaxingFilter = null;
    this.skipFollowUpFilter = null;
    this.addressedFilter = null;
    this.calledFilter = null;
    this.faxedStatusFilter = null;
    this.invalidReadingDoctorFilter = null;
    this.customFilter = null;
    this.pdfComparisonFilter = null;
    this.faxedTypeFilter = null;
    this.doctorResubmittedFilter = null;
    this.researchCustomSearchFilter = null;
    this.researchAnchorFilter = null;
    this.researchSourceFilter = null;
    this.researchFilter = null;
    this.batchCallFilter = null;
  }

  public applyPrioritySort(apply: boolean) {
    this.prioritySort = apply;
  }

  public applyRejectedSort(apply: boolean) {
    this.rejectedSort = apply;
  }

  public applyGroupBy(instruction: string) {
    this.groupByInstruction = instruction;
  }

  public updateComputerFilter(computer: string, path: string) {
    this.computerFilter = computer;
    this.pathFilter = path;
  }

  public updateFlags(flags: any) {
    this.currentFlags = flags;
  }

  public updateFlag(flag: any, value: any) {
    this.currentFlags[flag] = value;
  }

  public updateEmailFilter(email: string | null) {
    this.emailFilter = email;
  }

  public updateReservedByFilter(email: string | null) {
    this.reservedByFilter = email;
  }

  public updateStateFilter(state: string[] | null) {
    this.stateFilter = state;
  }

  public updateBillingCodeFilter(billingCode: string | null) {
    this.billingCodeFilter = billingCode;
  }

  public updateHasBillingCodeFilter(value: boolean | null) {
    this.hasBillingCodeFilter = value;
  }

  public updateHasBillingDateFilter(value: boolean | null) {
    this.hasBillingDateFilter = value;
  }

  public updateBillingStatusFilter(billingStatus: any | null) {
    this.billingStatusFilter = billingStatus;
  }

  public updateStartScanDateFilter(date: string | null) {
    this.startScanDateFilter = date;
  }

  public updateEndScanDateFilter(date: string | null) {
    this.endScanDateFilter = date;
  }

  public updateStartBillingDateFilter(date: string | null) {
    this.startBillingDateFilter = date;
  }

  public updateEndBillingDateFilter(date: string | null) {
    this.endBillingDateFilter = date;
  }

  public updateSubmittedByFilter(email: string | null) {
    this.submittedByFilter = email;
  }

  public updatePdfFilter(value: boolean | null) {
    this.pdfFilter = value;
  }

  public updatePatFilter(value: boolean | null) {
    this.patFilter = value;
  }

  public updateBackupFilter(value: boolean | null) {
    this.backupFilter = value;
  }

  public updateFinalReportFilter(value: boolean | null) {
    this.finalReportFilter = value;
  }

  public updateRequisitionFilter(value: boolean | null) {
    this.requisitionFilter = value;
  }

  public updatePreliminaryFilter(value: boolean | null) {
    this.preliminaryFilter = value;
  }

  public updateReadingDoctorFilter(id: number | null) {
    this.readingDoctorFilter = id;
  }

  public updateRejectedFilter(value: boolean | null) {
    this.rejectedFilter = value;
  }

  public updateClosedFilter(value: boolean | null) {
    this.closedFilter = value;
  }

  public updateCancelledFaxFilter(value: boolean | null) {
    this.cancelledFaxFilter = value;
  }

  public updateSkipFaxingFilter(value: boolean | null) {
    this.skipFaxingFilter = value;
  }

  public updateSkipFollowUpFilter(value: boolean | null) {
    this.skipFollowUpFilter = value;
  }

  public updateAddressedFilter(value: boolean | null) {
    this.addressedFilter = value;
  }

  public updateCalledFilter(value: boolean | null) {
    this.calledFilter = value;
  }

  public updateAbnormalFilter(value: boolean | null) {
    this.abnormalFilter = value;
  }

  public updateBatchCallFilter(value: boolean | null) {
    this.batchCallFilter = value;
  }

  public updateReservedFilter(value: boolean | null) {
    this.reservedFilter = value;
    this.groupByInstruction = (value !== null) ? "holter_unique" : null;
  }

  public updateFaxedFilter(value: boolean | null) {
    this.faxedFilter = value;
    this.groupByInstruction = (value !== null) ? "holter_unique" : null;
  }

  public updateFaxedTypeFilter(value: string| null) {
    this.faxedTypeFilter = value;
    this.groupByInstruction = (value !== null) ? "holter_unique" : null;
  }

  public updateDoctorResubmittedFilter(value: boolean | null) {
    this.doctorResubmittedFilter = value;
  }

  public updateFaxStatusFilter(value: string | null) {
    this.faxedStatusFilter = value;
  }

  public updateResearchSearchFilter(anchor: string, search: string, source: string) {
    this.researchSourceFilter = source
    this.researchAnchorFilter = anchor;
    this.researchCustomSearchFilter = search;
  }

  public updateResearchFilter(value: boolean | null) {
    this.researchFilter = value;
  }

  public updateInvalidReadingDoctorFilter(value: boolean | null) {
    this.invalidReadingDoctorFilter = value;
  }

  public updateClinicPhoneNumberFilter(value: string | null) {
    this.clinicPhoneNumberFilter = value;
  }

  public updateFocusHolterIDFilter(value: string | null) {
    this.focusHolterIDFilter = value;
  }

  public setCustomFilter(filterName: string | null) {
    this.customFilter = this.customFilter == filterName ? null : filterName;
    if (this.customFilter == "doctor_resubmit" || this.customFilter == "custom_faxed"){
      this.groupByInstruction = (filterName !== null) ? "holter_unique" : null;
    }
  }

  public setCustomFocusFilter(filterName: string | null) {
    this.customFocusFilter = this.customFocusFilter == filterName ? null : filterName;
  }

  public updateShowDuplicates(verificationNumber: string, deviceId: string) {
    this.verificationNumberFilter = verificationNumber;
    this.deviceIdFilter = deviceId;
  }

  public updatePdfComparisonFilter(comparison: string[] | null) {
    this.pdfComparisonFilter = comparison;
  }

  public static expandFlags(data: string): any {
    const expanded = {};
    let index = 0;
    for (let flagColumn in HolterService.FLAG_COLUMNS) {
      expanded[flagColumn] = data[index] === '1' ? true : false;
      index = index + 1;
    }
    return expanded;
  }

  // this function is used to expand the flag query when requesting it
  private expandFlagsQuery(dataTablesParams: any): any {

    const newColumns = [];
    for (let oldColumn of dataTablesParams.columns) {
      if (oldColumn.data !== 'holter:flags') {
        newColumns.push(oldColumn);
      } else {
        for (let flagColumn in HolterService.FLAG_COLUMNS) {

          // flag search filters
          let search = {
            regex: false,
            method: null,
            value: '',
          };
          const flagKey = HolterService.FLAG_COLUMNS[flagColumn].flagKey;
          if (this.currentFlags[flagKey] === true) {
            search = {
              regex: true,
              method: null,
              value: '1',
            };
          } else if (this.currentFlags[flagKey] === false) {
            search = {
              regex: true,
              method: "null_zero",
              value: '0',
            };
          }

          newColumns.push({
            data: flagColumn,
            name: '',
            searchable: true,
            orderable: true,
            search: search,
          });
        }
      }
    }

    dataTablesParams.columns = newColumns;
    return dataTablesParams;
  }


  // this function is used to condense the return from the flag query to display in the table
  private collapseFlagsQuery(result: any): any {

    for (let row of result) {
      let flags = '';
      for(let flagColumn of Object.keys(HolterService.FLAG_COLUMNS)) {
        flags += row[flagColumn] ? '1' : '0';
        delete row[flagColumn];
      }
      row['holter:flags'] = flags;
    }

    return result;
  }

  // this is used to override the ajax call in datatables for server-side processing
  public getAjaxFuncion(): any {

    // pass this class to the callback through "that"
    const that: HolterService = this;

    // server side rendering
    return (dataTablesParams: any, callback: any): void => {

      // populate the info in multiform part
      dataTablesParams["table"] = "holter";
      dataTablesParams["show_duplicate"] = !!this.verificationNumberFilter && !!this.deviceIdFilter;
      dataTablesParams = this.expandFlagsQuery(dataTablesParams);
      if (!!this.researchCustomSearchFilter) {
        dataTablesParams["research_search"] = this.researchCustomSearchFilter;
      }
      if (!!this.researchSourceFilter) {
        dataTablesParams["research_source"] = this.researchSourceFilter;
      }
      if (!!this.researchAnchorFilter) {
        dataTablesParams["research_anchor"] = this.researchAnchorFilter;
      }

      if (!!this.customFilter) {
        dataTablesParams["custom"] = this.customFilter;
        dataTablesParams["batch_call"] = this.batchCallFilter;
      }
      if (!!this.groupByInstruction) {
        dataTablesParams.group_by = this.groupByInstruction;
      }

      dataTablesParams.columns.push({
        data: 'holter:is_test',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: true,
          value: 0,
        },
      });

      if (this.prioritySort !== null) {
        dataTablesParams.order.push({
          column: -1,
          custom: "holter_priority",
          dir: "custom",
        });
      }

      if (this.rejectedSort !== null) {
        dataTablesParams.order.push({
          column: -1,
          custom: "holter_rejected",
          dir: "custom",
        });
      }

      // inject the email filter if enabled
      if (this.emailFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:upload_email',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            value: this.emailFilter,
          },
        });
      }

      if (this.computerFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:upload_computer',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            value: this.computerFilter,
          },
        });
      }

      if (this.pathFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:upload_computer_path',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            value: this.pathFilter,
          },
        });
      }

      if (this.stateFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:state',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: 'in',
            value: this.stateFilter,
          },
        });
      }

      if (this.pdfComparisonFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:s3_key',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: 'in',
            value: this.pdfComparisonFilter,
          },
        });
      }

      if (this.reservedByFilter !== null) {
        dataTablesParams.columns.push({
          data: 'employee:email',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            value: this.reservedByFilter,
          },
        });
      }

      if (this.verificationNumberFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:verification_number',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            value: this.verificationNumberFilter,
          },
        });
      }

      if (this.deviceIdFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:device_id',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            value: this.deviceIdFilter,
          },
        });
      }

      if (this.hasBillingCodeFilter) {
        dataTablesParams.columns.push({
          data: 'clinic_assignment:billing_code',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: "not_null",
            value: true,
          },
        });
      }

      if (this.hasBillingDateFilter) {
        dataTablesParams.columns.push({
          data: 'holter:billing_date',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: "not_null",
            value: true,
          },
        });
      }

      if (this.billingCodeFilter !== null) {
        dataTablesParams.columns.push({
          data: 'clinic_assignment:billing_code',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            value: this.billingCodeFilter,
          },
        });
      }

      if (this.billingStatusFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:billed_at',
          name: '',
          searchable: true,
          orderable: true,
          search: this.billingStatusFilter,
        });
      }

      if (this.startScanDateFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:scan_date',
          name: '',
          searchable: true,
          orderable: true,
          search: this.startScanDateFilter,
        });
      }

      if (this.endScanDateFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:scan_date',
          name: '',
          searchable: true,
          orderable: true,
          search: this.endScanDateFilter,
        });
      }

      if (this.startBillingDateFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:billing_date',
          name: '',
          searchable: true,
          orderable: true,
          search: this.startBillingDateFilter,
        });
      }

      if (this.endBillingDateFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:billing_date',
          name: '',
          searchable: true,
          orderable: true,
          search: this.endBillingDateFilter,
        });
      }

      if (this.readingDoctorFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:reading_doctor_id',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            value: this.readingDoctorFilter,
          },
        });
      }

      if (this.submittedByFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:submitted_by',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            value: this.submittedByFilter,
          },
        });
      }

      if (this.pdfFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:uploaded_files',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: this.pdfFilter ? "like" : "not_like",
            value: "out_01.pdf",
          },
        });
      }

      if (this.patFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:uploaded_files',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: this.patFilter ? "like" : "not_like",
            value: "pat.001",
          },
        });
      }

      if (this.backupFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:backed_up_at',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: this.backupFilter ? "not_null" : "null",
            value: true,
          },
        });
      }

      if (this.finalReportFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:flag_verified',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            value: this.finalReportFilter ? '1' : '0',
          },
        });
        // missing the finalReportFilter === false case
        if (this.finalReportFilter) {
          dataTablesParams.columns.push({
            data: 'holter:state',
            name: '',
            searchable: true,
            orderable: true,
            search: {
              regex: true,
              value: "FAXING",
            },
          });
        }
      }

      if (this.reservedFilter === false) {
        dataTablesParams.columns.push({
          data: 'holter_reservation:holter_id',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: "null",
            value: true,
          },
        });
      }

      if (this.reservedFilter === true) {
        dataTablesParams.columns.push({
          data: 'holter_reservation:holter_id',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: "not_null",
            value: true,
          },
        });
      }

      if (this.requisitionFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:requisition_id',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: this.requisitionFilter ? "not_null" : "null",
            value: true,
          },
        });
      }

      if (this.preliminaryFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:flag_verified',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            value: this.preliminaryFilter ? '1' : '0',
          },
        });
        // missing the preliminaryFilter === false case
        if (this.preliminaryFilter) {
          dataTablesParams.columns.push({
            data: 'holter:state',
            name: '',
            searchable: true,
            orderable: true,
            search: {
              regex: true,
              method: "not_like",
              value: "FAXING",
            },
          });
        }
      }

      if (this.rejectedFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:rejected_by',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: this.rejectedFilter ? "not_null" : "null",
            value: true,
          },
        });
      }

      if (this.closedFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:closed_by',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: this.closedFilter ? "not_null" : "null",
            value: true,
          },
        });
      }

      if (this.abnormalFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:flag_abnormal',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            value: this.abnormalFilter ? 1 : 0,
          },
        });
      }
      if (this.cancelledFaxFilter !== null) {
        dataTablesParams.columns.push({
          data: 'fax:status',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: this.cancelledFaxFilter ? "eq" : "null_neq",
            value: "Cancelled",
          },
        });
      }

      if (this.skipFaxingFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:skip_faxing_at',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: this.skipFaxingFilter ? "not_null" : "null",
            value: true,
          },
        });
      }

      if (this.skipFollowUpFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:skip_follow_up_at',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: this.skipFollowUpFilter ? "not_null" : "null",
            value: true,
          },
        });
      }

      if (this.addressedFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:addressed_at',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: this.addressedFilter ? "not_null" : "null",
            value: true,
          },
        });
      }

      if (this.calledFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:called_at',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: this.calledFilter ? "not_null" : "null",
            value: true,
          },
        });
      }

      if (this.faxedFilter) {
        dataTablesParams.columns.push({
          data: 'fax:status',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: "eq",
            value: "Faxed",
          },
        });
      }

      if (this.faxedTypeFilter !== null) {
        dataTablesParams.columns.push({
          data: 'fax:type',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: "like",
            value: this.faxedTypeFilter,
          },
        });
      }

      if (this.doctorResubmittedFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:doctor_resubmitted_report_at',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: this.doctorResubmittedFilter ? "not_null" : "null",
            value: true,
          },
        });
      }

      if (this.faxedFilter === false) {
        dataTablesParams.columns.push({
          data: 'fax:status',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: "null_neq",
            value: "Faxed",
          },
        });
      }

      if (this.faxedStatusFilter) {
        dataTablesParams.columns.push({
          data: 'fax:status',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            value: this.faxedStatusFilter,
          },
        });
      }

      if (this.invalidReadingDoctorFilter) {
        dataTablesParams.columns.push({
          data: 'reading_doctor:roles',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: "neq",
            value: "Reading",
          },
        });
      }

      if (this.researchFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:pat_data',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: this.researchFilter ? "not_null" : "null",
            value: true,
          },
        });
      }

      if (this.researchFilter !== null) {
        dataTablesParams.columns.push({
          data: 'holter:pdf_data',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: this.researchFilter ? "not_null" : "null",
            value: true,
          },
        });
      }

      const formData: FormData = new FormData();
      formData.append("info", JSON.stringify({
        client: that.CLIENT_NAME,
        job_name: "query_data",
        job_id: null,
        task_status: "create",
        data: dataTablesParams
      }));

      // request the data from url
      that.http.post(that.JOB_URL, formData, this.httpOptions()).toPromise()
        .then((response: any) => {

          // callback to the datatable to rerender with the updated information
          const result: DataTablesResponse = response.Payload.data.result;
          callback({
            recordsTotal: result.recordsTotal,
            recordsFiltered: result.recordsFiltered,
            data: this.collapseFlagsQuery(result.data.map(formatLocaleTime)),
          });

        })
        .catch((error: any) => {
          console.log("Error datatable handler for holter: " + error.message);
        });
    };
  }

  public getHolter(id: number): Promise<Holter | null> {

    // populate the info in multiform part
    const query: any = {
      draw: 1,
      columns: [

        { data: "holter:id", search: { value: id.toString(), regex: false} },
        { data: "holter:billing_date" },
        { data: "holter:billed_at" },
        { data: "holter:hookup_date" },
        { data: "holter:scan_date" },
        { data: "holter:duration" },
        { data: "holter:delivery" },
        { data: "holter:computer" },
        { data: "holter:state" },
        { data: "holter:updated_at" },
        { data: "holter:submitted_by" },
        { data: "holter:rejected_by" },
        { data: "holter:closed_by" },
        { data: "holter:req_resolved_by" },
        { data: "holter:synced_at" },
        { data: "holter:sync_requested_at" },
        { data: "holter:skip_faxing_at" },
        { data: "holter:skip_follow_up_at" },
        { data: "holter:report_prepared_at" },
        { data: "holter:addressed_at" },
        { data: "holter:addressed_by" },
        { data: "holter:called_at" },
        { data: "holter:flag_verified" },
        { data: "holter:flag_pacemaker_inquiry" },
        { data: "holter:flag_no_data" },
        { data: "holter:flag_monitor_no_data" },
        { data: "holter:flag_no_req" },
        { data: "holter:flag_no_backup" },
        { data: "holter:flag_no_tracings" },
        { data: "holter:flag_invalid_referring" },
        { data: "holter:flag_invalid_healthcard" },
        { data: "holter:flag_invalid_dob" },
        { data: "holter:flag_need_repair" },
        { data: "holter:flag_abnormal" },
        { data: "holter:flag_fax_with_tracing" },
        { data: "holter:uploaded_files" },
        { data: "holter:upload_email" },
        { data: "holter:upload_computer_path" },
        { data: "holter:upload_computer" },
        { data: "holter:upload_computer_slot" },
        { data: "holter:s3_key" },
        { data: "holter:report_clinic" },
        { data: "holter:notes" },
        { data: "holter:fax_notes" },
        { data: "holter:fax_numbers" },
        { data: "holter:medflux_data" },
        { data: "holter:general_comments" },
        { data: "holter:specific_comments" },
        { data: "holter:additional_comments" },
        { data: "holter:summary" },
        { data: "holter:report_revision_lines" },
        { data: "device:id" },
        { data: "device:serial_number" },
        { data: "device:measurement" },
        { data: "dx_code:id" },
        { data: "dx_code:code" },
        { data: "dx_code:description" },
        { data: "patient:id" },
        { data: "patient:last_name" },
        { data: "patient:first_name" },
        { data: "patient:birthdate" },
        { data: "patient:health_card" },
        { data: "patient:health_card_code" },
        { data: "patient:gender" },
        { data: "patient:height" },
        { data: "patient:weight" },
        { data: "referring_doctor:id" },
        { data: "referring_doctor:last_name" },
        { data: "referring_doctor:first_initials" },
        { data: "referring_doctor:name" },
        { data: "referring_doctor:roles" },
        { data: "clinic_assignment:fax_number" },
        { data: "clinic_assignment:phone_number" },
        { data: "clinic_assignment:billing_code" },
        // { data: "referring_doctor:title" },
        { data: "referring_doctor:billing_number" },
        { data: "analyst:email" },
        { data: "analyst:firstname" },
        { data: "analyst:lastname" },
        { data: "editor:email" },
        { data: "editor:firstname" },
        { data: "editor:lastname" },
        { data: "clinic:id" },
        { data: "clinic:name" },
        { data: "clinic:street" },
        { data: "clinic:city" },
        { data: "clinic:province" },
        { data: "clinic:postal_code" },
        { data: "clinic:fax" },
        { data: "clinic:phone_number" },
        { data: "clinic:additional_fax_numbers" },
        { data: "clinic:flag_no_fax" },
        { data: "reading_doctor:id" },
        { data: "reading_doctor:last_name" },
        { data: "reading_doctor:first_initials" },
        { data: "reading_doctor:name" },
        { data: "reading_doctor:roles" },
        // { data: "reading_doctor:title" },
        { data: "reading_doctor:billing_number" },
        { data: "staff:id" },
        { data: "staff:last_name" },
        { data: "staff:first_name" },
        { data: "requisition:id" },
        { data: "requisition:file_name" },
        { data: "requisition:first_name"},
        { data: "requisition:last_name"},
        { data: "requisition:date"},
        { data: "requisition:status"},
        { data: "requisition:fax_notes"},
        { data: "requisition:staff_notes"},
        { data: "requisition:comments"},
        { data: "requisition:closed_by"},
        { data: "holter:erequisition_id" },
        { data: "holter:faxes" },
        { data: "holter:reservations" },
        { data: "holter:need_flatten_tracing" },
        { data: "holter:need_flatten_batch" },
        { data: "holter:need_reannotate_at" },
        { data: "holter:pat_data" },
      ],
      order: [],
      table: "holter",
      start: 0,
      length: 1,
      search: {},
    };

    const formData: FormData = new FormData();
    formData.append("info", JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "query_data",
      job_id: null,
      task_status: "create",
      data: query,
    }));

    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => {
        const result: DataTablesResponse = response.Payload.data.result;
        if (result.data.length <= 0) {
          return null;
        }

        result.data = result.data.map(formatLocaleTime);
        const raw: any = result.data[0];
        let holter: Holter = new Holter();
        holter.id = raw["holter:id"];
        holter.billing_date = raw["holter:billing_date"];
        holter.billed_at = raw["holter:billed_at"];
        holter.hookup_date = raw["holter:hookup_date"];
        holter.scan_date = raw["holter:scan_date"];
        holter.duration = raw["holter:duration"];
        holter.delivery = raw["holter:delivery"];
        holter.computer = raw["holter:computer"];
        holter.state = raw["holter:state"];
        holter.updated_at = raw["holter:updated_at"];
        holter.submitted_by = raw["holter:submitted_by"];
        holter.rejected_by = raw["holter:rejected_by"];
        holter.closed_by = raw["holter:closed_by"];
        holter.req_resolved_by = raw["holter:req_resolved_by"];
        holter.synced_at = raw["holter:synced_at"];
        holter.sync_requested_at = raw["holter:sync_requested_at"];
        holter.skip_faxing_at = raw["holter:skip_faxing_at"];
        holter.skip_follow_up_at = raw["holter:skip_follow_up_at"];
        holter.report_prepared_at = raw["holter:report_prepared_at"];
        holter.addressed_at = raw["holter:addressed_at"];
        holter.addressed_by = raw["holter:addressed_by"];
        holter.called_at = raw["holter:called_at"];
        holter.flag_verified = raw["holter:flag_verified"];
        holter.flag_pacemaker_inquiry = raw["holter:flag_pacemaker_inquiry"];
        holter.flag_no_data = raw["holter:flag_no_data"];
        holter.flag_monitor_no_data = raw["holter:flag_monitor_no_data"];
        holter.flag_no_req = raw["holter:flag_no_req"];
        holter.flag_no_backup = raw["holter:flag_no_backup"];
        holter.flag_no_tracings = raw["holter:flag_no_tracings"];
        holter.flag_invalid_referring = raw["holter:flag_invalid_referring"];
        holter.flag_invalid_healthcard = raw["holter:flag_invalid_healthcard"];
        holter.flag_invalid_dob = raw["holter:flag_invalid_dob"];
        holter.flag_need_repair = raw["holter:flag_need_repair"];
        holter.flag_abnormal = raw["holter:flag_abnormal"];
        holter.flag_fax_with_tracing = raw["holter:flag_fax_with_tracing"];
        holter.uploaded_files = raw["holter:uploaded_files"];
        holter.upload_email = raw["holter:upload_email"];
        holter.upload_computer_path = raw["holter:upload_computer_path"];
        holter.upload_computer = raw["holter:upload_computer"];
        holter.upload_computer_slot = raw["holter:upload_computer_slot"];
        holter.s3_key = raw["holter:s3_key"];
        holter.report_clinic = raw["holter:report_clinic"];
        holter.notes = raw["holter:notes"];
        holter.fax_notes = raw["holter:fax_notes"];
        holter.fax_numbers = raw["holter:fax_numbers"];
        holter.medflux_data = raw["holter:medflux_data"];
        holter.general_comments = raw["holter:general_comments"];
        holter.specific_comments = raw["holter:specific_comments"];
        holter.additional_comments = raw["holter:additional_comments"];
        holter.summary = raw["holter:summary"];
        holter.report_revision_lines = raw["holter:report_revision_lines"];
        holter.device_id = raw["device:id"];
        holter.device_serial_number = raw["device:serial_number"];
        holter.device_measurement = raw["device:measurement"];
        holter.dx_code_id = raw["dx_code:id"];
        holter.dx_code_code = raw["dx_code:code"];
        holter.dx_code_description = raw["dx_code:description"];
        holter.patient_id = raw["patient:id"];
        holter.patient_last_name = raw["patient:last_name"];
        holter.patient_first_name = raw["patient:first_name"];
        holter.patient_birthdate = raw["patient:birthdate"];
        holter.patient_health_card = raw["patient:health_card"];
        holter.patient_health_card_code = raw["patient:health_card_code"];
        holter.patient_gender = raw["patient:gender"];
        holter.patient_height = raw["patient:height"];
        holter.patient_weight = raw["patient:weight"];
        holter.referring_doctor_id = raw["referring_doctor:id"];
        holter.referring_doctor_last_name = raw["referring_doctor:last_name"];
        holter.referring_doctor_first_initials = raw["referring_doctor:first_initials"];
        holter.referring_doctor_name = raw["referring_doctor:name"];
        holter.referring_doctor_roles = raw["referring_doctor:roles"];
        holter.referring_doctor_fax = raw["clinic_assignment:fax_number"];
        holter.referring_doctor_phone = raw["clinic_assignment:phone_number"];
        holter.referring_doctor_billing_code = raw["clinic_assignment:billing_code"];
        // holter.referring_doctor_title = raw["referring_doctor:title"];
        holter.referring_doctor_billing_number = raw["referring_doctor:billing_number"];
        holter.analyst_email = raw["analyst:email"];
        holter.analyst_firstname = raw["analyst:firstname"];
        holter.analyst_lastname = raw["analyst:lastname"];
        holter.editor_email = raw["editor:email"];
        holter.editor_firstname = raw["editor:firstname"];
        holter.editor_lastname = raw["editor:lastname"];
        holter.clinic_id = raw["clinic:id"];
        holter.clinic_name = raw["clinic:name"];
        holter.clinic_street = raw["clinic:street"];
        holter.clinic_city = raw["clinic:city"];
        holter.clinic_province = raw["clinic:province"];
        holter.clinic_postal_code = raw["clinic:postal_code"];
        holter.clinic_fax = raw["clinic:fax"];
        holter.clinic_phone_number = raw["clinic:phone_number"];
        holter.clinic_additional_fax_numbers = raw["clinic:additional_fax_numbers"];
        holter.clinic_flag_no_fax = raw["clinic:flag_no_fax"];
        holter.reading_doctor_id = raw["reading_doctor:id"];
        holter.reading_doctor_last_name = raw["reading_doctor:last_name"];
        holter.reading_doctor_first_initials = raw["reading_doctor:first_initials"];
        holter.reading_doctor_name = raw["reading_doctor:name"];
        holter.reading_doctor_roles = raw["reading_doctor:roles"];
        // holter.reading_doctor_title = raw["reading_doctor:title"];
        holter.reading_doctor_billing_number = raw["reading_doctor:billing_number"];
        holter.staff_id = raw["staff:id"];
        holter.staff_last_name = raw["staff:last_name"];
        holter.staff_first_name = raw["staff:first_name"];
        holter.requisition_id = raw["requisition:id"];
        holter.requisition_file_name = raw["requisition:file_name"];
        holter.requisition_first_name = raw["requisition:first_name"];
        holter.requisition_last_name = raw["requisition:last_name"];
        holter.requisition_date = raw["requisition:date"];
        holter.requisition_status = raw["requisition:status"];
        holter.requisition_closed_by = raw["requisition:closed_by"];
        holter.requisition_fax_notes = raw["requisition:fax_notes"];
        holter.requisition_staff_notes = raw["requisition:staff_notes"];
        holter.requisition_comments = raw["requisition:comments"];
        holter.erequisition_id = raw["holter:erequisition_id"];
        holter.faxes = raw["holter:faxes"];
        holter.reservations = raw["holter:reservations"];
        holter.needFlattenTracing = raw["holter:need_flatten_tracing"];
        holter.needFlattenBatch = raw["holter:need_flatten_batch"];
        holter.needReannotateAt = raw["holter:need_reannotate_at"];
        holter.pat_data = raw["holter:pat_data"];
        return holter;
      })
      .catch((error: any) => {
        console.log("Failed to get holter: " + error.message);
        return null;
      });
  }

    // this is used to override the ajax call in datatables for server-side processing
    public getFocusAjax(): any {

      // pass this class to the callback through "that"
      const that: HolterService = this;

      // server side rendering
      return (dataTablesParams: any, callback: any): void => {

        // populate the info in multiform part
        dataTablesParams["table"] = "holter";

        if (!!this.customFocusFilter) {
          dataTablesParams["custom"] = this.customFocusFilter;
          dataTablesParams["phone_number"] = this.clinicPhoneNumberFilter;
        }

        if (this.focusHolterIDFilter !== null) {
          dataTablesParams.columns.push({
            data: 'holter:id',
            name: '',
            searchable: true,
            orderable: true,
            search: {
              regex: true,
              value: this.focusHolterIDFilter,
            },
          });
        }

        // if (this.clinicPhoneNumberFilter !== null) {
        //   dataTablesParams.columns.push({
        //     data: 'clinic:phone_number',
        //     name: '',
        //     searchable: true,
        //     orderable: true,
        //     search: {
        //       regex: true,
        //       value: this.clinicPhoneNumberFilter,
        //     },
        //   });
        // }

        const formData: FormData = new FormData();
        formData.append("info", JSON.stringify({
          client: that.CLIENT_NAME,
          job_name: "query_data",
          job_id: null,
          task_status: "create",
          data: dataTablesParams
        }));

        // request the data from url
        that.http.post(that.JOB_URL, formData, this.httpOptions()).toPromise()
          .then((response: any) => {

            // callback to the datatable to rerender with the updated information
            const result: DataTablesResponse = response.Payload.data.result;
            callback({
              recordsTotal: result.recordsTotal,
              recordsFiltered: result.recordsFiltered,
              data: result.data.map(formatLocaleTime),
            });

          })
          .catch((error: any) => {
            console.log("Error datatable handler for Clinic: " + error.message);
          });
      };
    }

  public getPresignedUrl(holterId: number, fileName: string): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/presigned-url/" + fileName;
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getTracings(holterId: number, format: string): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/tracings?format=" + format;
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getMedfluxVars(holterId: number): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/medflux-vars";
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public updateHolter(holterId: number, data: any): Promise<any> {
    const url = environment.api + '/holter/' + holterId;
    return this.http.post(url, data, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getCommentPredictions(holterId: number, fromEditor: boolean): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/comments/predict" + (fromEditor ? "?editor=1" : "");
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getDatabaseOptions(): Promise<any> {
    const url = environment.api + '/holter/computer';
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getRequisitions(): Promise<any> {
    const url = environment.api + '/requisition';
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getMonitorReport(holterId: number): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/report";
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getPat(holterId: number): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/pat";
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getReadingBatchReport(holterId: number, format: string): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/reading-batch?format=" + format;
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getReadingCount(doctorId: number): Promise<any> {
    const url = environment.api + '/holter/' + doctorId +'/reading-count';
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getReadingTableCount(doctorId: number): Promise<any> {
    const url = environment.api + '/holter/' + doctorId +'/reading-table-count';
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public reorderReadingBatchReport(holterId: number, reorderDetails: any): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/reading-batch/reorder";
    return this.http.post(url, reorderDetails, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public rebatchReport(holterId: number): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/reading-batch/rebatch";
    return this.http.post(url, {}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public faxMonitorReport(holterId: number): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/fax";
    return this.http.post(url, {}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getFaxBatchDocPreview(holterId: number, files: string[], faxNotes: string): Promise<any> {
    const filesArg = encodeURIComponent(JSON.stringify(files))
    const url = environment.api + '/holter/' + holterId + "/fax-doc?files=" + filesArg + "&faxnotes=" + encodeURIComponent(faxNotes);
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public createNoDataHolter(data: any): Promise<any> {
    const url = environment.api + '/holter/no-data';
    return this.http.post(url, data, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public unrejectHolter(holterId: number): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/unreject";
    return this.http.post(url, {}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public rejectHolter(holterId: number): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/reject";
    return this.http.post(url, {}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public closeHolter(holterId: number): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/close";
    return this.http.post(url, {}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public prepareHolter(holterId: number): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/prepare";
    return this.http.post(url, {}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getRequisition(holterId: number): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/requisition/presigned-url";
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getZipPresignedUrl(holterId: number): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/zip/presigned-url";
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getHolterErrors(tab: string): Promise<any> {
    const url = environment.api + '/holter/errors?tab=' + tab;
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public unreserve(holterId: number): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/unreserve";
    return this.http.post(url, {}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public markEditor(holterId: number): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/mark-editor";
    return this.http.post(url, {}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public reserve(holterId: number): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/reserve";
    return this.http.post(url, {}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public skipToReading(holterId: number): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/skip-to-reading";
    return this.http.post(url, {}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public backToEditing(holterId: number): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/back-to-editing";
    return this.http.post(url, {}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public reqResolved(holterId: number): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/req-resolved";
    return this.http.post(url, {}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public removeReqResolved(holterId: number): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/remove-req-resolved";
    return this.http.post(url, {}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public skipFaxing(holterId: number): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/skip-faxing";
    return this.http.post(url, {}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public skipFollowUp(holterId: number): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/skip-follow-up";
    return this.http.post(url, {}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public addressedFax(holterId: number, data: any): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/addressed-fax";
    return this.http.post(url, data, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public annotate(holterId: number): Promise<any> {
    const url = environment.api + '/holter/' + holterId + "/annotate";
    return this.http.post(url, {}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public backupUrls(holterId: number): Promise<any[]> {
    const url = environment.api + '/holter/' + holterId + "/backup-presigned-urls";
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public scheduleFax(holterId: number, data: any): Promise<any> {
    const url = environment.api + '/holter/' + holterId + '/fax/schedule';
    return this.http.post(url, data, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public updateMedfluxVars(holterId: number, data: any): Promise<any> {
    const url = environment.api + '/holter/' + holterId + '/medflux-data/update';
    return this.http.post(url, data, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public syncRequest(holterId: number): Promise<any> {
    const url = environment.api + '/holter/' + holterId + '/sync-request';
    return this.http.post(url, {}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public pdfComparison(): Promise<any[]> {
    const url = environment.api + '/holter/pdf-comparison';
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public researchSearch(holterId: string, search: string, source: string): Promise<any> {
    let url = environment.api + '/holter/research-search';
    if (holterId || search || source)
      url += "?";
    if (holterId)
      url += "holter_id=" + encodeURIComponent(holterId) + "&";
    if (search)
      url += "search=" + encodeURIComponent(search) + "&";
    if (source)
      url += "source=" + encodeURIComponent(source) + "&";
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public updateFileComparison(holterId: number): Promise<any> {
    const url = environment.api + '/holter/' + holterId + '/file-comparison';
    return this.http.post(url, {}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public pdfBucketCheck(holterId: number): Promise<any> {
    const url = environment.api + '/holter/' + holterId + '/bucket-check';
    return this.http.post(url, {}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public bulkDownloadFax(holterIds: number[]): Observable<any> {
    const idArgs = encodeURIComponent(JSON.stringify(holterIds));
    const url = environment.api + '/holter/fax/bulk-download?ids=' + idArgs;
    const options = this.httpOptions();
    options.responseType = "blob";
    options.observe = "response";
    return this.http.get(url, options);
  }

  public addNotes(holterId: number, staffNotes: string, faxNotes: string): Promise<any> {
    const data = {
      notes: staffNotes,
      fax_notes: faxNotes,
    };
    const url = environment.api + '/holter/' + holterId + "/notes";
    return this.http.post(url, data, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public searchRequisitions(firstName: string, lastName: string, hookupDate: any): Promise<any> {
    let url = environment.api + '/requisition';
    if (lastName || firstName || hookupDate)
      url += "?";
    if (lastName)
      url += "last=" + encodeURIComponent(lastName) + "&";
    if (firstName)
      url += "first=" + encodeURIComponent(firstName) + "&";
    if (hookupDate)
      url += "date=" + encodeURIComponent(hookupDate) + "&";

    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public searchHolterForInbox(first: string, last: string, hookupDate: string, defaultValue: any): Promise<any> {
    const url = environment.api + '/holter/inbox?first=' + (first ? encodeURIComponent(first) : '') +
     '&last=' + (last ? encodeURIComponent(last) : '') + '&hookupdate=' + (hookupDate ? encodeURIComponent(hookupDate) : '');
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => (response.length == 0) ? [defaultValue] : response);
  }

  public searchEmployees(term: string): Promise<any> {
    const url = environment.api + '/holter/employee?search=' + encodeURIComponent(term);
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public static base64ToArrayBuffer(base64: string): Uint8Array {
    var binary_string =  window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array( len );
    for (var i = 0; i < len; i++)        {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return <Uint8Array>bytes.buffer;
  }

  private httpOptions(): any {
    return { headers: new HttpHeaders({
      "token": this.authService.getToken(),
      "version": version,
    }) };
  }

  public downloadFile(url): any {
		let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(url, { headers: headers, responseType: 'blob' });
  }

  public exportQuery(custom, debug): Observable<any> {
    const queryParams = [];

    if (debug) {
      queryParams.push('debug=' + debug)
    }
    if (custom) {
      queryParams.push('custom=' + custom)
    }
    const url = environment.api + '/holter/query?' + queryParams.join("&");
    const options = this.httpOptions();
    options.responseType = "blob";
    return this.http.get(url, options);
  }

  public exportReport(custom, debug): Observable<any> {
    const queryParams = [];

    if (debug) {
      queryParams.push('debug=' + debug)
    }
    if (custom) {
      queryParams.push('custom=' + custom)
    }
    const url = environment.api + '/holter/report?' + queryParams.join("&");
    const options = this.httpOptions();
    options.responseType = "blob";
    return this.http.get(url, options);
  }

  // public createHolterLog(holterId: number, data: any): Promise<any> {
  //   const url = environment.api + '/holter/' + holterId + '/holter-log';
  //   return this.http.post(url, data, this.httpOptions()).toPromise()
  //     .then((response: any) => response);
  // }

  public compareHolterLog(holterId: number): Promise<any> {
    const url = environment.api + '/holter/' + holterId + '/holter-log';
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }
}
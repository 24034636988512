import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

import { AnalyticsService } from "../../providers/analytics.service";

@Component({
  selector: 'app-home',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {

  public readonly PAGE_TECH: string = "tech";
  public readonly PAGE_READING: string = "reading";
  public readonly PAGE_LOCATION: string = "location";
  public page: string = this.PAGE_TECH;

  public techGraph: any = {};
  public doctorGraph: any = {};
  public locationGraph: any = {};

  public techCumulativeGraph: any = {};
  public doctorCumulativeGraph: any = {};
  public locationCumulativeGraph: any = {};

  private analyticsData: any = {};

  constructor(
    private analyticsService: AnalyticsService
  ) {

    this.techGraph = {
      data: [
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'red'} },
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'blue'} },
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'green'} },
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'orange'} },
      ],
      layout: {
        autosize: true,
        title: 'Number of Patients (Monthly)',
        yaxis: { title: "Count"},
      }
    };

    this.techCumulativeGraph = {
      data: [
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'red'} },
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'blue'} },
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'green'} },
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'orange'} },
      ],
      layout: {
        autosize: true,
        title: 'Number of Patients (Cumulative)',
        yaxis: { title: "Count"},
      }
    };

    this.doctorGraph = {
      data: [
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'red'} },
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'blue'} },
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'green'} },
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'orange'} },
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'black'} },
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'purple'} },
      ],
      layout: {
        autosize: true,
        title: 'Number of Patients (Monthly)',
        yaxis: { title: "Count"},
      }
    };

    this.doctorCumulativeGraph = {
      data: [
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'red'} },
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'blue'} },
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'green'} },
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'orange'} },
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'black'} },
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'purple'} },
      ],
      layout: {
        autosize: true,
        title: 'Number of Patients (Cumulative)',
        yaxis: { title: "Count"},
      }
    };

    this.locationGraph = {
      data: [
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'red'} },
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'blue'} },
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'green'} },
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'orange'} },
      ],
      layout: {
        autosize: true,
        title: 'Number of Patients (Monthly)',
        yaxis: { title: "Count"},
      }
    };

    this.locationCumulativeGraph = {
      data: [
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'red'} },
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'blue'} },
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'green'} },
        { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'orange'} },
      ],
      layout: {
        autosize: true,
        title: 'Number of Patients (Cumulative)',
        yaxis: { title: "Count"},
      }
    };

  }

  public ngOnInit() {
    this.analyticsService.getAnalytics().then((data: any) => {

      // populate the tech statistics
      for (const idx in this.techGraph.data) {
        this.techGraph.data[idx].x = data.tech[idx].time;
        this.techGraph.data[idx].y = data.tech[idx].value;
        this.techGraph.data[idx].name = data.tech[idx].name;
      }

      for (const idx in this.techCumulativeGraph.data) {
        this.techCumulativeGraph.data[idx].x = data.tech[idx].time;
        this.techCumulativeGraph.data[idx].y = data.tech[idx].cumulative;
        this.techCumulativeGraph.data[idx].name = data.tech[idx].name;
      }

      // populate the doctor statistics
      for (const idx in this.doctorGraph.data) {
        this.doctorGraph.data[idx].x = data.doctor[idx].time;
        this.doctorGraph.data[idx].y = data.doctor[idx].value;
        this.doctorGraph.data[idx].name = data.doctor[idx].name;
      }

      for (const idx in this.doctorCumulativeGraph.data) {
        this.doctorCumulativeGraph.data[idx].x = data.doctor[idx].time;
        this.doctorCumulativeGraph.data[idx].y = data.doctor[idx].cumulative;
        this.doctorCumulativeGraph.data[idx].name = data.doctor[idx].name;
      }

      // populate the location statistics
      for (const idx in this.locationGraph.data) {
        this.locationGraph.data[idx].x = data.location[idx].time;
        this.locationGraph.data[idx].y = data.location[idx].value;
        this.locationGraph.data[idx].name = data.location[idx].name;
      }

      for (const idx in this.locationCumulativeGraph.data) {
        this.locationCumulativeGraph.data[idx].x = data.location[idx].time;
        this.locationCumulativeGraph.data[idx].y = data.location[idx].cumulative;
        this.locationCumulativeGraph.data[idx].name = data.location[idx].name;
      }

    });
  }

  public switchPage(page: string) {
    this.page = page;
    window.dispatchEvent(new Event('resize'));
  }

}
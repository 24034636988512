import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { version } from '../../../package.json';

import { environment } from "../../environments/environment";
import { AuthService } from "../providers/auth.service";
import { Physician } from "../models/physician.model";
import { DataTablesResponse } from "../models/database-response.model";

import { formatLocaleTime } from "../utils/format";

import { Observable } from "rxjs";

@Injectable()
export class PhysicianService {

  private readonly JOB_URL: string = environment.api + "/job";
  private readonly CLIENT_NAME: string = "cardio_study";

  private srcFilter: string | null = null;
  private hiddenDoctorFilter: boolean | null = null;
  private hiddenClinicFilter: boolean | null = null;
  private clinicDoctorFilter: string | null = null;
  private customFilter: string | null = null;
  private customDirectoryFilter: string[] | null = null;
  private billingNumberFilter: boolean | null = null;
  private billingCodeFilter: boolean | null = null;
  private billingNumberDuplicateFilter: string | null = null;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  public clearFilters() {
    this.srcFilter = null;
    this.hiddenDoctorFilter = null;
    this.hiddenClinicFilter = null;
    this.clinicDoctorFilter = null;
    this.customFilter = null;
    this.customDirectoryFilter = null;
    this.billingNumberFilter = null;
    this.billingCodeFilter = null;
    this.billingNumberDuplicateFilter = null;
  }

  public updateSrcFilter(src: any) {
    this.srcFilter = src;
  }

  public updateHiddenDoctorFilter(value: boolean | null) {
    this.hiddenDoctorFilter = value;
  }

  public updateHiddenClinicFilter(value: boolean | null) {
    this.hiddenClinicFilter = value;
  }

  public updateClinicDoctorsFilter(value: string | null) {
    this.clinicDoctorFilter = value;
  }

  public setCustomFilter(filterName: string | null) {
    this.customFilter = this.customFilter == filterName ? null : filterName;
  }

  public setCustomDirectoryFilter(filterNames: string[] | null) {
    this.customDirectoryFilter =filterNames;
  }

  public updateBillingNumberFilter(value: boolean | null) {
    this.billingNumberFilter = value;
  }

  public updateBillingCodeFilter(value: boolean | null) {
    this.billingCodeFilter = value;
  }

  public updateShowDuplicates(billingNumber: string) {
    this.billingNumberDuplicateFilter = billingNumber;
  }

  // this is used to override the ajax call in datatables for server-side processing
  public getAjaxFuncion(): any {

    // pass this class to the callback through "that"
    const that: PhysicianService = this;

    // server side rendering
    return (dataTablesParams: any, callback: any): void => {

      if (this.srcFilter) {
        dataTablesParams.columns.push({
          data: 'doctor:import_src',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            value: this.srcFilter,
          },
        });
      }

      // populate the info in multiform part
      dataTablesParams["table"] = "doctor";

      dataTablesParams["show_duplicate"] = !!this.billingNumberDuplicateFilter;

      if (!!this.customFilter) {
        dataTablesParams["custom_doctor"] = this.customFilter;
        dataTablesParams["duplicate_billing_number"] = this.billingNumberDuplicateFilter;
      }

      if (!!this.customDirectoryFilter) {
        dataTablesParams["custom_doctor_directory"] = this.customDirectoryFilter;
      }

      if (this.hiddenDoctorFilter !== null) {
        dataTablesParams.columns.push({
          data: 'doctor:is_hidden_at',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: this.hiddenDoctorFilter ? "not_null" : "null",
            value: true,
          },
        });
      }

      if (this.billingNumberFilter !== null) {
        dataTablesParams.columns.push({
          data: 'doctor:billing_number',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: this.billingNumberFilter ? "not_null" : "null",
            value: true,
          },
        });
      }

      // if (this.billingNumberDuplicateFilter !== null) {
      //   dataTablesParams.columns.push({
      //     data: 'doctor:billing_number',
      //     name: '',
      //     searchable: true,
      //     orderable: true,
      //     search: {
      //       regex: true,
      //       value: this.billingNumberDuplicateFilter,
      //     },
      //   });
      // }

      if (this.billingCodeFilter !== null) {
        dataTablesParams.columns.push({
          data: 'clinic_assignment:billing_code',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: this.billingCodeFilter ? "not_null" : "null",
            value: true,
          },
        });
      }

      if (this.hiddenClinicFilter !== null) {
        dataTablesParams.columns.push({
          data: 'clinic_assignment:is_hidden_at',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: this.hiddenClinicFilter ? "not_null" : "null",
            value: true,
          },
        });
      }

      if (this.clinicDoctorFilter !== null) {
        dataTablesParams.columns.push({
          data: 'clinic_assignment:clinic_id',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            value: this.clinicDoctorFilter,
          },
        });
      }

      const formData: FormData = new FormData();
      formData.append("info", JSON.stringify({
        client: that.CLIENT_NAME,
        job_name: "query_data",
        job_id: null,
        task_status: "create",
        data: dataTablesParams
      }));

      // request the data from url
      that.http.post(that.JOB_URL, formData, this.httpOptions()).toPromise()
        .then((response: any) => {

          // callback to the datatable to rerender with the updated information
          const result: DataTablesResponse = response.Payload.data.result;
          callback({
            recordsTotal: result.recordsTotal,
            recordsFiltered: result.recordsFiltered,
            data: result.data.map(formatLocaleTime),
          });

        })
        .catch((error: any) => {
          console.log("Error datatable handler for holter: " + error.message);
        });
    };
  }

  public searchPhysician(term: string): Promise<Physician[] | null> {
    // populate the info in multiform part
    const query: any = {
      draw: 1,
      columns: [
        { data: "doctor:last_name" },
        { data: "doctor:first_initials" },
        { data: "doctor:name" },
        { data: "doctor:id"},
        { data: "doctor:is_hidden_at"},
      ],
      order: [],
      table: "doctor",
      start: 0,
      length: 15,
      search: {
        regex: false,
        value: term,
      },
    };

    const formData: FormData = new FormData();
    formData.append('info', JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "query_data",
      job_id: null,
      task_status: "create",
      data: query,
    }));

    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => {
        const result: DataTablesResponse = response.Payload.data.result;
        if (result.data.length <= 0) {
          return null;
        }

        let physicians: Physician[] = [];
        for (const raw of result.data) {
          let physician: Physician = new Physician();
          physician.last_name = raw["doctor:last_name"];
          physician.first_initials = raw["doctor:first_initials"];
          physician.name = raw["doctor:name"];
          physician.id = raw["doctor:id"];
          physician.is_hidden_at = raw["doctor:is_hidden_at"];
          physicians.push(physician);
        }
        return physicians;
      })
      .catch((error: any) => {
        console.log("Failed to search physicians: " + error.message);
        return null;
      });
  }

  public createPhysician(physician: Physician, update: boolean = false): Promise<Physician | any> {

    // populate the info in multiform part
    const query: any = {
      entries: [{
        id: physician.id,
        last_name: physician.last_name,
        first_initials: physician.first_initials,
        name: physician.name,
      }],
      table: "doctor",
    };

    const formData: FormData = new FormData();
    formData.append('info', JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: update ? "update_data" : "post_data",
      job_id: null,
      task_status: "create",
      data: query,
    }));

    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => response.Payload.data.result)
      .catch((error: any) => {
        console.log("Failed to create physician: " + error.message);
        return null;
      });
  }

  public updateDoctor(doctorId: number, data: any): Promise<any> {
    const url = environment.api + '/doctor/' + doctorId;
    return this.http.post(url, data, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public hideDoctor(doctorId: number): Promise<any> {
    const url = environment.api + '/doctor/' + doctorId + '/hide';
    return this.http.post(url, {}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public unhideDoctor(doctorId: number): Promise<any> {
    const url = environment.api + '/doctor/' + doctorId + '/unhide';
    return this.http.post(url, {}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  //used by old directory
  public hideDoctorClinic(doctorId: number, data: any): Promise<any> {
    const url = environment.api + '/doctor/' + doctorId + '/hide_clinic';
    return this.http.post(url, data, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  //used by old directory
  public unhideDoctorClinic(doctorId: number, data: any): Promise<any> {
    const url = environment.api + '/doctor/' + doctorId + '/unhide_clinic';
    return this.http.post(url, data, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public insertDoctor(data: any): Promise<any> {
    const url = environment.api + '/doctor';
    return this.http.post(url, data, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public verify(name: string): Promise<any> {
    const url = environment.api + '/doctor/verify?name=' + encodeURIComponent(name);
    return this.http.get(url,this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public verifyReading(name: string): Promise<any> {
    const url = environment.api + '/doctor/verify?reading=1&name=' + encodeURIComponent(name);
    return this.http.get(url,this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public allReading(): Promise<any> {
    const url = environment.api + '/doctor/reading';
    return this.http.get(url,this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  // NOT USED SINCE IT COMPARES NAMES, DIRECTORY HAS DUPLICATE CLINIC AND DOCTOR NAMES
  public searchBillingCode(doctor: string, clinic: string): Promise<any> {
    const url = environment.api + '/doctor/billing-code?doctor=' + encodeURIComponent(doctor) + "&clinic=" + encodeURIComponent(clinic);
    return this.http.get(url,this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getDoctor(doctorId: number, clinicId: number): Promise<any> {
    const url = environment.api + '/doctor/' + doctorId + '?clinic=' + clinicId;
    return this.http.get(url,this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getDoctorByBillingNumber(billingNumber: number): Promise<any> {
    const url = environment.api + '/doctor/billing-number/' + billingNumber;
    return this.http.get(url,this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public addNotes(doctorId: number, staffNotes: string): Promise<any> {
    const data = {
      notes: staffNotes,
    };
    const url = environment.api + '/doctor/' + doctorId + "/notes";
    return this.http.post(url, data, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public searchDoctor(term: string, fromDirectory: boolean, readingOnly: boolean): Promise<any> {
    const url = environment.api + '/doctor?src=' + (fromDirectory ? 'directory_xlsx' : '') + '&reading=' + (readingOnly ? '1' : '') + '&search=' + encodeURIComponent(term);
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => {
        return (response.length > 0) ? response : [{
          last_name: "None found",
          first_initials: null,
          billing_code: null,
        }];
      });
  }

  public searchDoctorNoClinic(term: string, fromDirectory: boolean, readingOnly: boolean): Promise<any> {
    const url = environment.api + '/doctor/search?src=' + (fromDirectory ? 'directory_xlsx' : '') + '&reading=' + (readingOnly ? '1' : '') + '&search=' + encodeURIComponent(term);
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => {
        return (response.length > 0) ? response : [{
          last_name: "None found",
          first_initials: null,
          billing_code: null,
        }];
      });
  }
  private httpOptions(): any {
    return { headers: new HttpHeaders({
      "token": this.authService.getToken(),
      "version": version,
    }) };
  }

  public exportQuery(custom, debug): Observable<any> {
    const queryParams = [];

    if (debug) {
      queryParams.push('debug=' + debug)
    }
    if (custom) {
      queryParams.push('custom=' + custom)
    }
    const url = environment.api + '/doctor/query?' + queryParams.join("&");
    const options = this.httpOptions();
    options.responseType = "blob";
    return this.http.get(url, options);
  }

  public getReconcileCount(): Promise<any> {
    const url = environment.api + '/doctor/reconcile';
    return this.http.get(url,this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

}
<div class="">
  <div class="page-title">
    <div class="title_left">
      <h3>Patients</h3>
    </div>
  </div>
  <div class="clearfix"></div>
  <div>
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div class="x_panel">
        <div class="x_content">
          <br />

          <form class="form-horizontal form-label-left input_mask">


            <div class="col-md-4 col-sm-4 col-xs-12 form-group has-feedback">
              <div class="col-md-12 col-sm-12 col-xs-12 form-group has-feedback">
                <label for="inputSuccess2">Last Name</label>
                <input type="text" class="form-control" [(ngModel)]="patient.last_name" name="last_name">
              </div>
              <div class="col-md-12 col-sm-12 col-xs-12 form-group has-feedback">
                <label for="inputSuccess2">Birthdate</label>
                <input type="text" class="form-control" [(ngModel)]="patient.birthdate" name="birthdate">
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 form-group has-feedback">
                <label for="inputSuccess2">City</label>
                <input type="text" class="form-control">
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 form-group has-feedback">
                <label for="inputSuccess2">Province</label>
                <input type="text" class="form-control">
              </div>
              <div class="col-md-12 col-sm-12 col-xs-12 form-group has-feedback">
                <label for="inputSuccess2">Notes</label>
                <input type="text" class="form-control">
              </div>
            </div>

            <div class="col-md-4 col-sm-4 col-xs-12 form-group has-feedback">
              <div class="col-md-6 col-sm-6 col-xs-12 form-group has-feedback">
                <label for="inputSuccess2">First Name</label>
                <input type="text" class="form-control" [(ngModel)]="patient.first_name" name="first_name">
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 form-group has-feedback">
                <label for="inputSuccess2">Health Card</label>
                <input type="text" class="form-control" [(ngModel)]="patient.health_card" name="health_card">
              </div>
              <div class="col-md-12 col-sm-12 col-xs-12 form-group has-feedback">
                <label for="inputSuccess2">Street Address</label>
                <input type="text" class="form-control">
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 form-group has-feedback">
                <label for="inputSuccess2">Postal Code</label>
                <input type="text" class="form-control">
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 form-group has-feedback">
                <label for="inputSuccess2">Gender</label>
                <input type="text" class="form-control" [(ngModel)]="patient.gender" name="gender">
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 form-group has-feedback">
                <label for="inputSuccess2">Home Phone</label>
                <input type="text" class="form-control">
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 form-group has-feedback">
                <label for="inputSuccess2">Mobile</label>
                <input type="text" class="form-control">
              </div>
            </div>

            <div class="col-md-4 col-sm-4 col-xs-12 form-group has-feedback" style="height: 100%;">
              <label for="inputSuccess2">Edit History</label>
              <textarea class="form-control" id="inputSuccess2" style="resize: vertical;"></textarea>
            </div>

            <div class="col-md-12 col-sm-12 col-xs-12 form-group has-feedback">
              <div class="text-right">
                <button class="btn btn-primary btn-review" type="button" disabled><i class="fa fa-plus"></i> &nbsp; Create</button>
                <button class="btn btn-primary btn-review" type="button" disabled><i class="fa fa-pencil"></i> &nbsp; Edit</button>
              </div>
            </div>

          </form>

        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="col-12">
      <div class="x_panel">
        <div class="x_content">
          <br />
          <div class="text-center">
            <div class="col-12">
              <label><h4>Type to start searching or select a record from below to begin.</h4></label>
            </div>
            <div class="form-group col-md-6 offset-md-3">
              <input type="text" class="form-control search-bar" placeholder="Search" id="search-bar">
            </div>
          </div>
          <table datatable [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover">
            <thead>
              <tr>
                <th>Modified On</th>
                <th>Last Name</th>
                <th>First Name</th>
                <th>Health Card</th>
                <th>Card Code</th>
                <th>Birthdate</th>
                <th>Id</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>

</div>
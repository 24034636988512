<div class="md-width">

    <div class="row sub-header">
      <div class="col-12">
        <div class="text-center">
          <br>
          <ul class="list-inline">
            <!-- <li *ngIf="showMainTab$ | async as showMainTab"
              class="list-inline-item table-filter"
              (click)="navigateTo('/inbox/main')"
              [class.table-filter-selected]="urlContains('main')"
              [hidden]="!showMainTab">
                <div class="col"><p class="text-center">Inbox</p></div>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>

  <router-outlet></router-outlet>
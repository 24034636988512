import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import * as download from "downloadjs";
import * as moment from "moment";
declare var SqPaymentForm: any;

import { environment } from "../../../environments/environment";
import { Visit } from "../../models/visit.model";

import { AbpmService } from "../../providers/abpm.service";
import { VisitService } from "../../providers/visit.service";
import { BillingService } from "../../providers/billing.service";


// Now fill in the Pay with a Credit Card form with test card information and click "Pay with Card":

// Card Number: 4532759734545858
// CVV: any three non-consecutive numbers
// Expiration Date: any month and year in the future
// Postal Code: 94103

@Component({
  selector: 'app-home',
  templateUrl: './billing-detail.component.html',
  styleUrls: ['./billing-detail.component.scss']
})
export class BillingDetailComponent implements OnInit, AfterViewInit {

  public dtOptions: any = {};

  public email: string = "";
  public visit: Visit | null = null;
  public visit$: Observable<Visit>;
  public title: string = "";

  public cardNumber: string = "";
  public cardExpiration: string = "";
  public cardCvv: string = "";
  public cardPostal: string = "";

  public emailSuccess: boolean | null = null;
  public paymentForm: any = null;

  public priceFormGroup: FormGroup;

  constructor(
    private abpmService: AbpmService,
    public billingService: BillingService,
    private visitService: VisitService,
    private route: ActivatedRoute,
    private router: Router
    ) {

      // the following allows reloading of the same route
      this.router.routeReuseStrategy.shouldReuseRoute = function(){
        return false;
      };
      this.router.events.subscribe((evt) => {
        if (evt instanceof NavigationEnd) {
          this.router.navigated = false;
          window.scrollTo(0, 0);
        }
      });

      // create the form
      this.priceFormGroup = new FormGroup({
        Price: new FormControl("", [ Validators.required, Validators.pattern(/^\d+[.]?\d{0,2}$/), ]),
      });

      // check if square payments is allowed
      if (!SqPaymentForm.isSupportedBrowser()) {
        const message: string = "Browser does not support online payments!";
        document.getElementById("card-error").innerHTML = message;
        console.log(message);
      }

      const self = this;

      // define the form
      this.paymentForm = new SqPaymentForm({
        applicationId: environment.squareAppId,
        inputClass: 'sq-input',
        autoBuild: false,

        applePay: false,
        masterpass: false,

        cardNumber: {
          elementId: 'cc-number',
          placeholder: '• • • •  • • • •  • • • •  • • • •'
        },
        cvv: {
          elementId: 'cc-expiration',
          placeholder: 'CVV'
        },
        expirationDate: {
          elementId: 'cc-cvv',
          placeholder: 'MM/YY'
        },
        postalCode: {
          elementId: 'cc-postal',
          placeholder: 'A1A1A1'
        },

        callbacks: {

          cardNonceResponseReceived: (errors: any, nonce: any, cardData: any) => {

            // check for the errors
            if (errors && errors.length > 0) {

              let errorMessage: string = "";
              errors.forEach((error: any) => {
                errorMessage += "<div class=\"col form-answer error-message\">" + error.message + "</div>";
              });
              document.getElementById("card-error").innerHTML = errorMessage;
              return;

            }

            // check that the nonce is a string
            if (!nonce) {
              document.getElementById("card-error").innerHTML = "<div class=\"col form-answer error-message\">Unsupported Card</div>";
              return;
            }

            // send to backend
            return self.billingService.makePayment(nonce, cardData, this.visit.billing_id)
              .then(() => this.navigateTo("/billing/" + this.visit.id));
          },

          paymentFormLoaded: () => {
            console.log("Ready to accept payments");
          },

        }
      })
    }

  public ngAfterViewInit() {

  }

  public ngOnInit() {

    // get the visit object from the server
    this.visit$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.visitService.getVisit(parseInt(params.get('id'), 10)))
    );

    // generate the invoice
    this.visit$.subscribe((visit: Visit) => {

      if ((visit.patient_first_name.length + visit.patient_last_name.length) < 30) {
        this.title = "Billing - " + visit.patient_first_name + " " + visit.patient_last_name;
      } else {
        this.title = "Billing - Invoice";
      }

      this.populateInvoice(visit);

      // build and recalculate the payment form
      if (SqPaymentForm.isSupportedBrowser()) {

        this.paymentForm.build();
        this.paymentForm.recalculateSize();
      }

    });

  }

  private populateInvoice(visit: Visit): void{
      this.visit = visit;

      this.priceFormGroup.patchValue({
        Price: this.visit.billing_balance
      });
  }

  public emailInvoice(): Promise<void> {

    // compute the invoice name
    const subject: string = this.visit.owner_name + " - Electronic Receipt";

    return this.billingService.emailInvoice(subject, this.email, this.visit.billing_id, this.visit.owner_id).then((success: boolean) => {
      if (!success) {
        console.log("Server error while emailing the invoice.");
        this.emailSuccess = false;
        return;
      }
      this.emailSuccess = true;
    });

  }

  public formatMoney(amount): string {

    let amountStr: string = amount.toString();
    const decimalIndex: number = amountStr.indexOf(".");
    if (decimalIndex < 0) {
      return "$" + amountStr + ".00";
    }
    if (amountStr.length - decimalIndex === 2) {
      return "$" + amountStr + "0";
    }
    return "$" + amountStr;

  }

  public billingItems(): any[] {
    try {
      let billingItems: any[] = JSON.parse(this.visit.billing_items);
      if (!billingItems) {
        return [];
      }
      billingItems.forEach((billingItem, index) => {
        billingItem.id = index + 1;
      });
      return billingItems;
    } catch (error) {
      console.log("Error parsing billing items " + (error || error.message));
    }
    return [];
  }

  public formatDate(date: any): string {
    return moment(date).format("ddd, DD MMM YYYY");
  }

  public navigateTo(url: string): void {
    this.router.navigateByUrl(url);
  }

  // this function will collect all the information required to generate the invoice
  public downloadInvoice(): any {

    // compute the invoice name
    const invoiceName: string = this.visit.patient_first_name.trim() + "_" + this.visit.patient_last_name.trim() + "_receipt_" + new Date().toISOString() + ".pdf";

    return this.billingService.downloadInvoice(this.visit.billing_id, this.visit.owner_id).then((pdfBase64: string) => {
      if (!pdfBase64) {
        console.log("Server error while downloading the report.");
        return;
      }
      return download("data:application/pdf;base64," + pdfBase64, invoiceName, "application/pdf");
    });
  }

  public makePayment(): any {
    this.paymentForm.requestCardNonce();
  }

  public updatePrice(): any {
    const price: number = parseFloat(this.priceFormGroup.get("Price")?.value);
    console.log("New Price " + price);
    return this.billingService.updatePrice(this.visit.billing_id, price)
      .then(() => this.navigateTo("/billing/" + this.visit.id));
  }

  public trackErrorMessages(index, _) {
    return index;
  }

}

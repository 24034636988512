<div>

    <div class="row sub-main">

        <div class="page-title">
          <div class="title_left">
            <h3>Billing - Holter</h3>
          </div>
        </div>
        <div class="clearfix"></div>

        <div class="x_panel">
          <div class="x_content">

            <br>

            <form class="form-horizontal form-label-left" [formGroup]="formGroup">
              <div class="row">

                <div class="col-6">
                  <div class="form-group form-display row">
                    <div class="col form-group">
                      <label>First Name</label>
                      <input type="text" class="form-control" placeholder="" formControlName="FirstName" [attr.disabled]="true">
                    </div>
                    <div class="col form-group">
                      <label>Last Name</label>
                      <input type="text" class="form-control" placeholder="" formControlName="LastName" [attr.disabled]="true">
                    </div>
                    <div class="col form-group">
                      <label>Gender</label>
                      <select class="form-control" data-live-search="true" formControlName="Gender" [attr.disabled]="true">
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group form-display row">
                    <div class="col form-group">
                      <label>Health Card</label>
                      <input type="text" class="form-control" placeholder="" formControlName="HealthCard" [attr.disabled]="true">
                    </div>
                    <div class="col form-group">
                      <label>Version Code</label>
                      <input type="text" class="form-control" placeholder="" formControlName="HealthCardVersion" [attr.disabled]="true">
                    </div>
                    <div class="col form-group">
                      <label>Birthdate</label>
                      <input type="text" class="form-control" placeholder="YYYY/MM/DD" formControlName="Birthdate" [attr.disabled]="true">
                    </div>
                    <div class="col form-group">
                      <label>DX Code</label>
                      <select class="form-control" data-live-search="true" (mouseleave)="verboseDxcode = false" (mouseenter)="verboseDxcode = true" formControlName="DxCode" [attr.disabled]="true">
                        <option *ngFor="let codeInfo of DXCODE_OPTIONS" [value]="codeInfo.code">{{verboseDxcode ? codeInfo.text : codeInfo.code}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group form-display row">
                    <div class="col form-group">
                      <label>Hookup Date</label>
                      <input type="text" class="form-control" placeholder="YYYY/MM/DD" formControlName="HookupDate" [attr.disabled]="true">
                    </div>
                    <div class="col form-group">
                      <label>Duration</label>
                      <input type="text" class="form-control" placeholder="" formControlName="Duration" [attr.disabled]="true">
                    </div>
                    <div class="col form-group">
                      <label>Scan Date</label>
                      <input type="text" class="form-control" placeholder="YYYY/MM/DD" formControlName="ScanDate" [attr.disabled]="true">
                    </div>
                    <div class="col form-group">
                      <label>Tech</label>
                      <input type="text" class="form-control" placeholder="" formControlName="Tech" [attr.disabled]="true">
                    </div>
                  </div>
                  <div class="form-group form-display row">
                    <div class="col-6 form-group">
                      <label>Referring Doctor</label>
                      <input type="text" class="form-control" placeholder="" formControlName="ReferringDoctor" [attr.disabled]="true">
                    </div>
                    <div class="col-3 form-group">
                      <label>City</label>
                      <input type="text" class="form-control" placeholder="" formControlName="City" [attr.disabled]="true">
                    </div>
                    <div class="col-3 form-group">
                      <label>Device SN</label>
                      <input type="text" class="form-control" placeholder="" formControlName="Device" [attr.disabled]="true" [attr.disabled]="true">
                    </div>
                  </div>
                  <div class="form-group form-display row">
                    <div class="col form-group">
                      <label>Clinic Name</label>
                      <input type="text" class="form-control" placeholder="" formControlName="Clinic" [attr.disabled]="true">
                    </div>
                  </div>
                  <div class="form-group form-display row">
                    <div class="col form-group">
                      <label>Reading Doctor</label>
                      <input type="text" class="form-control" placeholder="" formControlName="ReadingDoctor" [attr.disabled]="true">
                    </div>
                    <div class="col form-group">
                      <label>Delivery</label>
                      <input type="text" class="form-control" placeholder="" formControlName="Delivery" [attr.disabled]="true">
                    </div>
                  </div>
                </div>

                <div class="col-3">
                  <div class="form-group form-display row">
                    <div class="col form-group">
                      <label>Staff Notes</label>
                      <textarea rows="4" class="form-control" placeholder="" formControlName="StaffNotes" [attr.disabled]="true"></textarea>
                    </div>
                  </div>
                  <div class="form-group form-display row">
                    <div class="col form-group">
                      <label>Fax Notes</label>
                      <textarea rows="4" class="form-control" placeholder="" formControlName="FaxNotes" [attr.disabled]="true"></textarea>
                    </div>
                  </div>
                  <div class="form-group form-display row">
                    <div class="col form-group">
                      <label>Requisitions</label>
                      <select class="form-control" data-live-search="true" formControlName="Requisition" [attr.disabled]="true">
                        <option *ngFor="let reqOption of reqOptions" [value]="reqOption">{{reqOption}}</option>
                        <option [value]=""></option>
                      </select>
                    </div>
                  </div>
                  <div [hidden]="!currentHolter || !billingCode" class="form-group form-display row">
                    <div class="col form-group">
                      <p class="billing-code-indicator">Billing Code: {{billingCode}}</p>
                    </div>
                  </div>
                </div>

                <div class="col-3">
                  <p>Status Flags</p>
                  <div class="form-group form-display row">
                    <div class="col form-group">
                      <input class="form-check-input" type="checkbox" name="check0" id="check0" formControlName="FlagNoData" [attr.disabled]="true">
                      <label class="form-check-label" for="check0">No Data</label>
                    </div>
                  </div>
                  <div class="form-group form-display row">
                    <div class="col form-group">
                      <input class="form-check-input" type="checkbox" name="check1" id="check1" formControlName="FlagNoReq" [attr.disabled]="true">
                      <label class="form-check-label" for="check1">No Requisition</label>
                    </div>
                  </div>
                  <div class="form-group form-display row">
                    <div class="col form-group">
                      <input class="form-check-input" type="checkbox" name="check2" id="check2" formControlName="FlagNoBackup" [attr.disabled]="true">
                      <label class="form-check-label" for="check2">No Backup</label>
                    </div>
                  </div>
                  <div class="form-group form-display row">
                    <div class="col form-group">
                      <input class="form-check-input" type="checkbox" name="check3" id="check3" formControlName="FlagNoTracings" [attr.disabled]="true">
                      <label class="form-check-label" for="check3">No Report Tracings</label>
                    </div>
                  </div>
                  <div class="form-group form-display row">
                    <div class="col form-group">
                      <input class="form-check-input" type="checkbox" name="check4" id="check4" formControlName="FlagInvalidReferring" [attr.disabled]="true">
                      <label class="form-check-label" for="check4">Invalid Referring Dr</label>
                    </div>
                  </div>
                  <div class="form-group form-display row">
                    <div class="col form-group">
                      <input class="form-check-input" type="checkbox" name="check5" id="check5" formControlName="FlagInvalidHealthcard" [attr.disabled]="true">
                      <label class="form-check-label" for="check5">Invalid Healthcard</label>
                    </div>
                  </div>
                  <div class="form-group form-display row">
                    <div class="col form-group">
                      <input class="form-check-input" type="checkbox" name="check6" id="check6" formControlName="FlagInvalidDob" [attr.disabled]="true">
                      <label class="form-check-label" for="check6">Invalid Date of Birth</label>
                    </div>
                  </div>
                  <div class="form-group form-display row">
                    <div class="col form-group">
                      <input class="form-check-input" type="checkbox" name="check7" id="check7" formControlName="FlagVerified" [attr.disabled]="true">
                      <label class="form-check-label" for="check7">Verified</label>
                    </div>
                  </div>
                  <div class="form-group form-display row">
                    <div class="col form-group">
                      <input class="form-check-input" type="checkbox" name="check8" id="check8" formControlName="FlagPacemakerInquiry" [attr.disabled]="true">
                      <label class="form-check-label" for="check8">Pacemaker Inq.</label>
                    </div>
                  </div>
                </div>

              </div>
            </form>

            <hr>

            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-1 col-xl-1 text-right">
                    <div class="custom-control custom-switch">
                      <input class="custom-control-input" type="checkbox" id="switch1" [(ngModel)]="billingShowAll" (click)="filterChanged('SHOW_ALL')">
                      <label class="custom-control-label text-center" for="switch1">Show All<br>(inc. unbillable)</label>
                    </div>
                  </div>
                  <div class="col-1 col-xl-1">
                    <label>Billing Code</label>
                    <select class="form-control" data-live-search="true" [(ngModel)]="codeFilter" (change)="filterChanged()">
                      <option>All</option>
                      <option>ON</option>
                      <option>BC</option>
                      <option>WB</option>
                      <option>NBC</option>
                    </select>
                  </div>
                  <div class="col-1 col-xl-1">
                    <label>Bill Status</label>
                    <select class="form-control" data-live-search="true" [(ngModel)]="statusFilter" (change)="filterChanged()">
                      <option>All</option>
                      <option>Billed</option>
                      <option>Not Billed</option>
                      <option>Archived</option>
                    </select>
                  </div>
                  <div class="col-1 col-xl-1">
                    <label>Status</label>
                    <select class="form-control" data-live-search="true" [(ngModel)]="holterStatusFilter" (change)="filterChanged()">
                      <option value="">All</option>
                      <option value="PENDING">Pending</option>
                      <option value="EDITING">Editing</option>
                      <option value="RESERVED">Reserved</option>
                      <option value="READING">Reading</option>
                      <option value="FAXING">Faxing</option>
                    </select>
                  </div>
                  <div class="col-1 col-xl-1">
                    <label>Start Date</label>
                    <input type="text" class="form-control" placeholder="YYYY/MM/DD" [(ngModel)]="startDateFilter" (change)="filterChanged()">
                  </div>
                  <div class="col-1 col-xl-1">
                    <label>End Date</label>
                    <input type="text" class="form-control" placeholder="YYYY/MM/DD" [(ngModel)]="endDateFilter" (change)="filterChanged()">
                  </div>
                  <div class="col-2 col-xl-2">
                    <label>Search</label>
                    <input type="text" class="form-control search-bar" placeholder="Start typing to search" id="search-bar">
                  </div>
                  <div class="col-4 col-xl-4">
                    <button class="btn btn-primary btn-review" (click)="exportQuery()" type="button">Export Query</button>
                    <button class="btn btn-primary btn-review" (click)="exportBilling()" type="button" [disabled]="codeFilter == 'All'">Export Billing</button>
                  </div>
                </div>

                <div>
                  <table datatable [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover" id="not-pending-table">
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Files</th>
                        <th>Hookup Date</th>
                        <th>Billing Date</th>
                        <th>Scan Date</th>
                        <th>Billed</th>
                        <th>Reading</th>
                        <th>Referring</th>
                        <th>Name</th>
                        <th>Sex</th>
                        <th>Health Card</th>
                        <th>DOB</th>
                        <th>Billing Number</th>
                        <th>Code</th>
                        <th>Location</th>
                        <th>Clinic</th>
                        <th>DX Code</th>
                        <th>Duration</th>
                      </tr>
                    </thead>
                  </table>
                </div>

                <div class="row" *ngIf="downloadProgress">
                  <div class="col text-center progress-text">
                    {{downloadProgress}}
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
    </div>

    <div>
      <div class="row sub-footer">
        <div class="col-12">
          <button class="btn btn-primary btn-review" type="button" (click)="markBilled()" [disabled]="!currentHolter"><i class="fa fa-fax"></i> Mark as Billed</button>
          <button class="btn btn-primary btn-review" type="button" (click)="markUnbilled()" [disabled]="!currentHolter"><i class="fa fa-repeat"></i> Mark as Unbilled</button>
        </div>
      </div>
    </div>
  </div>

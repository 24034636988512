<div>
    <div class="row sub-main">

      <div class="page-title">
        <div class="title_left">
          <h3>Fax - Directory</h3>
        </div>
      </div>
      <div class="clearfix"></div>

      <div class="x_panel">
        <div class="x_content">

          <div class="row split-container">

            <as-split direction="horizontal">
              <as-split-area [size]="50">
                <div class="col">
                  <div class="row">
                    <div class="col-9">
                      <div class="row">
                        <div class="col">
                            <label>Search By: </label>
                            <input type="radio" (change)="changeTable($event)" [(ngModel)]="radio" value="doctor"> Doctors
                            <input type="radio" (change)="changeTable($event)" [(ngModel)]="radio" value="clinic"> Clinics
                        </div>
                      </div>
                          <form class="form-horizontal form-label-left" [formGroup]="formGroup">
                            <div class="row">
                                <div class="col-3">

                                    <div class="col">
                                      <input class="form-check-input" formControlName="RepairFilter" type="checkbox" name="check1" id="check1" [attr.disabled]="expanded ? true : null" [(ngModel)]="isRepair" (change)="checkboxChange('repair')">
                                      <label class="form-check-label" for="check1">Repair</label>
                                    </div>
                                    <div class="col">
                                      <input class="form-check-input" formControlName="InactiveFilter" type="checkbox" name="check2" id="check2" [attr.disabled]="expanded ? true : null" [(ngModel)]="isNotActive" (change)="checkboxChange('not_active')">
                                      <label class="form-check-label" for="check2">Inactive</label>
                                    </div>
                                    <div class="col">
                                      <input class="form-check-input" formControlName="ReconcileFilter" type="checkbox" name="check3" id="check3" [attr.disabled]="expanded ? true : null" [(ngModel)]="isReconcile" (change)="checkboxChange('reconcile')">
                                      <label class="form-check-label" for="check3">Reconcile</label><span> ({{reconcileCount}})</span>
                                    </div>
                                </div>
                                <div class="col-9">
                                    <label>Search</label>
                                    <input type="text" formControlName="Search" class="form-control search-bar" placeholder="Start typing to search" id="search_bar">
                                </div>

                            </div>
                          </form>
                        </div>
                    <div class="col-3">
                        <button class="btn btn-primary btn-review" type="button" (click)="modify('left')" [attr.disabled]="disable('left') ? '' : null" [hidden]="true">MODIFY</button>
                    </div>
                  </div>

                  <table datatable *ngIf="dtRendered" [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover blue-table" id="left-table">
                    <thead>
                      <tr>
                        <th *ngFor="let hd of tableHeadersLeft">
                          {{hd}}
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <div class="row">
                    <div class="col">
                        <div class="col text-right">
                            <button class="btn btn-primary btn-review btn-primary-blue" type="button" (click)="add('left')">Create</button>
                        </div>
                    </div>
                    </div>
                </div>
              </as-split-area>
              <as-split-area [size]="50">
                <div class="col info-panel" >
                    <div class="col">
                        <div class="form-group form-display row">
                            <div class="col-9">
                              <div class="title-selection">{{titleSelection}}</div>
                            </div>
                            <div class="col-3 text-right">
                              <div class="custom-control custom-switch">
                                <input class="custom-control-input" type="checkbox" id="switch1" [(ngModel)]="showAll" (change)="showAllEntries()">
                                <label class="custom-control-label" for="switch1">Show all</label>
                              </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                              <div class="row-selection">{{rowSelection}}</div>
                            </div>
                        </div>
                    </div>
                    <table *ngIf="dtRendered" datatable [dtOptions]="dtOptions2" class="table table-striped row-bordered datatable-buttons hover" id="right-table">
                        <thead>
                        <tr>
                          <th *ngFor="let hd of tableHeadersRight">
                            {{hd}}
                          </th>
                        </tr>
                        </thead>
                    </table>
                    <div class="col">
                        <div class="form-group form-display row">
                            <div class="col text-right">
                                <button class="btn btn-primary btn-review" type="button" (click)="add('right')" [attr.disabled]="disable('left') ? '' : null">ADD</button>
                            </div>
                        </div>
                    </div>
                </div>
              </as-split-area>
            </as-split>
          </div>
        </div>
      </div>
    </div>

    <!--
    <div>
      <div class="row sub-footer">
        <div class="col-12">

        </div>
      </div>
    </div>
    -->


</div>

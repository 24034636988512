<div class="md-width">

  <div class="row sub-header">
    <div class="col-12">
      <div class="text-center">
        <br>
        <ul class="list-inline">
          <li *ngIf="showLegacyTab$ | async as showLegacyTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/fax/legacy')"
            [class.table-filter-selected]="urlContains('legacy')"
            [hidden]="!showLegacyTab">
              <div class="col"><p class="text-center">Legacy</p></div>
          </li>
          <li *ngIf="showRepairTab$ | async as showRepairTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/fax/repair')"
            [class.table-filter-selected]="urlContains('repair')"
            [hidden]="!showRepairTab">
              <div class="col"><p class="text-center">Repair</p></div>
          </li>
          <li *ngIf="showHolterTab$ | async as showHolterTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/fax/holter')"
            [class.table-filter-selected]="urlContains('holter')"
            [hidden]="!showHolterTab">
              <div class="col"><p class="text-center">Holter</p></div>
          </li>
          <!-- <li *ngIf="showDirectoryTab$ | async as showDirectoryTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/fax/directory')"
            [class.table-filter-selected]="urlContains('directory')"
            [hidden]="!showDirectoryTab">
              <div class="col"><p class="text-center">Directory</p></div>
          </li> -->
          <li *ngIf="showClinicDoctorDirectoryTab$ | async as showClinicDoctorDirectoryTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/fax/clinic-doctor-directory')"
            [class.table-filter-selected]="urlContains('clinic-doctor-directory')"
            [hidden]="!showClinicDoctorDirectoryTab">
              <div class="col"><p class="text-center">New Directory</p></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';

import { AuthService } from "../../providers/auth.service";
import { PhysicianService } from "../../providers/physician.service";

import { formatLocaleDateSingle, formatNotesMultiple  } from "../../utils/format";
@Component({
  selector: 'app-modal-nodata',
  templateUrl: './modal-nodata.component.html',
  styleUrls: ['./modal-nodata.component.scss']
})
export class ModalNodataComponent implements OnInit {
  @Input() public requisition;
  public formGroup: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private physicianService: PhysicianService,
    private authService: AuthService,
  ) {
    this.formGroup = new FormGroup({
      LastName: new FormControl("", [ ]),
      FirstName: new FormControl("", [ ]),
      HookupDate: new FormControl("", [ Validators.required, Validators.pattern(/^\d{4}\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/) ]),
      HealthCard: new FormControl("", [ Validators.required, Validators.pattern(/^\d{10}$/) ]),
      BirthDate: new FormControl("", [ Validators.pattern(/^\d{4}\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/) ]),
      ReferringDoctor: new FormControl("", [ ]),
      StaffNotes: new FormControl("", [ ]),
    });
  }

  ngOnInit(): void {
    this.populateModal(this.requisition);
  }

  private populateModal(requisition) {
    this.formGroup.reset();
    this.formGroup.patchValue({
      LastName: requisition.last_name,
      FirstName: requisition.first_name,
      HookupDate: formatLocaleDateSingle(requisition.date),
    });
  }

  public passBack() {
    this.activeModal.close({
      last_name: this.formGroup.get("LastName").value ? this.formGroup.get("LastName").value : "BLANK",
      first_name: this.formGroup.get("FirstName").value ? this.formGroup.get("FirstName").value : "BLANK",
      hookup_date: this.formGroup.get("HookupDate")?.value,
      healthcard: this.formGroup.get("HealthCard")?.value,
      birthdate: this.formGroup.get("BirthDate")?.value,
      referring_doctor: this.formGroup.get("ReferringDoctor")?.value ? this.formGroup.get("ReferringDoctor")?.value?.name || this.formGroup.get("ReferringDoctor")?.value : null,
      staff_notes: this.formGroup.get("StaffNotes")?.value,
    });
  }

  searchDoctor = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => (!this.authService.isCardioStudyCompanyClass() || term.length < 2) ? Promise.resolve([])
        : this.physicianService.searchDoctor(term, true, false))
    )

  searchDoctorFormatter = (result: any): string => {
    return [
      result.last_name,
      result.first_initials,
      result.clinic ? result.clinic.city : null,
      result.billing_code,
    ].filter(x => !!x).join(" | ")
  };

  searchDoctorInputFormatter = (result: any): string => {
    if (!result.name) {
      return result;
    }

    this.formGroup.patchValue({
      ReferringDoctor: result.name,
    });
    return result.name;
  };

}

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';

import * as moment from "moment";
import * as download from "downloadjs";
import * as JSZip from "jszip";
import * as JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";

import { DXCODE_OPTIONS } from "../../config/constants";
import { Holter } from "../../models/holter.model";
import { Physician } from "../../models/physician.model";

import { HolterService } from "../../providers/holter.service";
import { AuthService } from "../../providers/auth.service";
import { ClinicService } from "../../providers/clinic.service";
import { PhysicianService } from "../../providers/physician.service";
import { RequisitionService } from 'src/app/providers/requisition.service';

import { formatNotes, formatNotesMultiple } from "../../utils/format";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalStaffNotesComponent } from '../modal-staff-notes/modal-staff-notes.component';
import { ModalCreateFaxComponent } from '../modal-create-fax/modal-create-fax.component';

@Component({
  selector: 'app-home',
  templateUrl: './holter-pending.component.html',
  styleUrls: ['./holter-pending.component.scss']
})
export class HolterPendingComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;

  public dtOptions: any = {};

  public multiSelected: boolean = false;

  public readonly PAGE_SEARCH: string = "search";
  public readonly PAGE_CREATE: string = "create";
  public readonly PAGE_EDIT: string = "edit";
  public readonly PAGE_PENDING: string = "pending";
  public page: string = this.PAGE_SEARCH;

  public formGroup: FormGroup;
  public checkBoxFormGroup: FormGroup;
  public currentHolter: Holter;
  public currentDoctor: Physician;
  private loaded: boolean = false;

  public reqAuto : any[] = [];
  public currentReq: any = null;

  public errorStrings: any = {};

  public formChanged: boolean = false;
  public checkBoxFormChanged: boolean = false;
  public reqChanged: boolean = false;
  public reqID: number = null;
  public attachedReqPlaceHolder: string = "Type to search";

  public holterErrorIdx: number = null;
  public holterErrors: any[] = [];

  public filterDatabaseIdx: number = null;
  public searchDatabases: any[] = [];
  public reqOptions: string[] = [];

  public changeSubs: any = [];
  public billingCode: any = null;

  public readingDoctorOptions: any[] = [];
  public downloadProgress = null;

  public autoRefreshing: boolean = true;

  public showEntries: boolean = true;
  public isSuperUser: boolean = false;

  public pdfSrc: any = null;
  public loadingPdf: boolean = false;
  public pdfSizeLeft: any = 50;
  public pdfSizeRight: any = 50;
  public showPdfPreview: string = "requisition";

  public fileTypeFilters: any = {
    backup: null,
    pdf: null,
    req: null,
    preliminary: null,
    final: null,
  };

  public emailFilter: string | null = null;

  private readonly DEBOUNCE_SEC: number = 1;

  public verboseDxcode: boolean = false;
  public DXCODE_OPTIONS = DXCODE_OPTIONS;

  public setEmailFilter() {
    if (this.emailFilter) {
      this.emailFilter = null;

    } else {
      const token = this.authService.getToken(true);
      this.emailFilter = token.email;
    }

    this.holterService.updateEmailFilter(this.emailFilter);
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  // important variable that changes if the status column position changes
  private readonly STATUS_COLUMN_INDEX: number = 0;

  public ngAfterViewInit(): void {

    // set up the callbacks
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {

      // on search term callback
      $("#search-bar").on("keyup change", function () {
        setTimeout(() => {
          const search_string = this["value"].replace(',', '')
          if (dtInstance.search() !== search_string) {
            dtInstance.search(search_string).draw();
          }
        }, 1000);
      });
    });

    // focus on the search bar right away after load
    $("#search-bar").focus();
  }

  public isSuperUserOnly(roles: any[]) {
    let counts = 0;
    for (const role of roles) {
      if (role.name === "Super User") {
        counts += 1;
      }
    }
    this.isSuperUser = true;
    return counts == 1;
  }

  private isHolterTechnicianOnly(roles: any[]) {
    let counts = 0;
    for (const role of roles) {
      if (role.name === "Holter Technician") {
        counts += 1;
      }
    }
    return counts == 1;
  }

  private resetFilter() {
    this.holterService.clearFilters();
    this.holterService.updateStateFilter(["PENDING"]);
    this.holterService.updateClosedFilter(false);
    this.holterService.updateFlag("flag_no_data", false);
    this.holterService.updateReservedByFilter(null);
    this.holterService.updateShowDuplicates(null, null);
    const userToken = this.authService.getToken(true);
    if (this.isHolterTechnicianOnly(userToken.roles) || ((this.isSuperUserOnly(userToken.roles) && (this.showEntries == true)))) {
      this.holterService.updateComputerFilter(userToken.first_name, null);
    }
  }

  public ngOnInit() {}

  constructor(
    private physicianService: PhysicianService,
    private clinicService: ClinicService,
    private holterService: HolterService,
    private authService: AuthService,
    private requisitionService: RequisitionService,
    private router: Router,
    private modalService: NgbModal,
  ) {

    var work_list_filter = localStorage.getItem(document.location.href+'work_list');
    if (work_list_filter){
      this.showEntries = (/true/i).test(work_list_filter);
    }
    this.resetFilter();

    // roll callback
    const rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
      // $("td", row).unbind("click");
      $(".table-download-icon", row).bind("click", (event) => this.downloadFileHandler(event, data));
      $(".fa-plus", row).bind("click", (event) => this.expandRowsHandler(event, data, true));
      $(".fa-minus", row).bind("click", (event) => this.expandRowsHandler(event, data, false));
      $("td", row).not(".download-file-column").not(".expand-row-column").bind("click", () => this.rowClickHandler(data));
      return row;
    };

    // setting size of pdfviewer
    this.getPdfViewerSpacing();

    // datatable options
    this.dtOptions = {
        autoWidth: false,
        responsive: true,
        lengthChange: false,
        select: true,
        pageLength: 50,
        dom: "Blfrtip",
        buttons: [],
        rowCallback: rowCallback,
        serverSide: true,
        processing: true,
        scrollY: window.innerHeight - 850,
        ajax: this.holterService.getAjaxFuncion(),
        order: [[ 9, "asc" ]],
        columnDefs: [{
          targets: 0,
          className: 'expand-row-column'
        },{
          targets: 1,
          className: 'download-file-column'
        }],
        columns: [
          {
            data: 'holter:id',
            orderable: false,
            render: ( data, type, row, meta ) => {
              const duplicateCount = row["holter:duplicate_count"]
              let expandHtml = ``;
              if (duplicateCount > 1)
                expandHtml = ` &nbsp;<a><i class="fa fa-plus expand-rows-icon"></i></a>`;
              if (duplicateCount < -1)
                expandHtml = ` &nbsp;<a><i class="fa fa-minus expand-rows-icon"></i></a>`;
              return expandHtml;
            },
          },
          {
            data: "holter:state",
            render: function ( data, type, row, meta ) {
              return row["holter:closed_by"] ? "SUSPENDED" : data;
            }
          },
          {
            data: "holter:uploaded_files",
            orderable: false,
            render: function ( data, type, row, meta ) {
              const uploadedfiles = JSON.parse(data);
              const hasPdf = uploadedfiles.includes('out_01.pdf');
              const hasPat = uploadedfiles.includes('pat.001');
              const hasBackup = !!row["holter:backed_up_at"];
              const expandedFlags = HolterService.expandFlags(row["holter:flags"]);
              const hasFinal = expandedFlags["holter:flag_verified"] && row["holter:state"] === "FAXING";
              const hasReq = !!row["holter:requisition_id"];
              const hasPreliminary = expandedFlags["holter:flag_verified"] && row["holter:state"] !== "FAXING";

              let icons = '';
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Tracings"><i class="fa download-tracings fa-file-pdf-o ` + (hasPdf ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Requisition"><i class="fa download-req fa-file-o ` + (hasReq ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Preliminary"><i class="fa download-preliminary fa-file-text-o ` + (hasPreliminary ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Final Report"><i class="fa download-final fa-file-pdf-o ` + (hasFinal ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Data"><i class="fa download-backup fa-database ` + (hasBackup ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              return icons;
            },
          },
          {
            data: "holter:synced_at",
            orderable: false,
            render: ( data, type, row, meta ) => {
              const req = row["holter:sync_requested_at"];
              const sync = row["holter:synced_at"];
              const uploadedfiles = JSON.parse(row["holter:uploaded_files"]);
              const hasPdf = uploadedfiles.includes('out_01.pdf');
              let expandHtml = ``;
              if (!req && !sync && !hasPdf){
                expandHtml = ` &nbsp;<a><i class="fa fa-times-circle-o fa-fw"></i></a>`;
                return expandHtml + " Not Synced";
              }
              else if ((req > sync) || (req && sync == null)){
                expandHtml = ` &nbsp;<a><i class="fa fa-circle-o-notch fa-spin fa-fw"></i></a>`;
                return expandHtml + " " + req;
              }
              else if ((req < sync) || (sync && req == null) || hasPdf){
                expandHtml = ` &nbsp;<a><i class="fa fa-check-circle fa-fw"></i></a>`;
                return expandHtml + " " + (sync ? sync : "Synced");
              }
              return expandHtml;
            },
          },
          { data: "holter:hookup_date" },
          { data: "holter:scan_date" },
          {
            data: "patient:last_name",
            orderable: true,
            render: function ( data, type, row, meta ) {
              const last = row["patient:last_name"];
              const first = row["patient:first_name"];
              const card = row["patient:health_card"];
              if (card){
                if (!last && !first) {
                  return card.substring(0,10) + "<Br>" + "No Name";
                } else if (!last) {
                  return card.substring(0,10)  + "<Br>" + first;
                } else if (!first) {
                  return card.substring(0,10)  + "<Br>" + last;
                } else {
                  return card.substring(0,10)  + "<Br>" + last + ", " + first;
                }
              }
              else {
                if (!last && !first) {
                  return "";
                } else if (!last) {
                  return first;
                } else if (!first) {
                  return last;
                } else {
                  return last + ", " + first;
                }
              }

            },
          },
          { data: "referring_doctor:name" },
          { data: "holter:upload_computer_path",
            orderable: true,
            render: function ( data, type, row, meta ) {
              const path = row["holter:upload_computer_path"];
              const computer_name = row["holter:upload_computer"];
              if (path && computer_name) {
                return computer_name + "<Br>" + path;
              }
              else {
                return path;
              }
            }
          },
          { data: "holter:upload_computer_slot" },
          { data: "holter:duration", visible: false  },
          { data: "clinic:city", visible: false  },
          { data: "holter:updated_at", visible: false  },
          {
            data: "holter:flags",
            orderable: false,
            visible: false,
          },
          {
            data: "holter:requisition_id",
            orderable: false,
            visible: false,
          },
          { data: 'patient:health_card', visible: false },
          { data: 'patient:health_card_code', visible: false },
          { data: 'patient:first_name', visible: false },
          { data: 'holter:id', visible: false },
          { data: 'holter:state', visible: false },
          { data: 'holter:device_id', visible: false },
          { data: 'holter:verification_number', visible: false },
          { data: 'holter:backed_up_at', visible: false },
          { data: 'holter:sync_requested_at', visible: false },
          { data: 'holter:closed_at', visible: false },
          { data: 'holter:closed_by', visible: false },
          { data: 'referring_doctor:id', visible: false },
          { data: 'clinic:id', visible: false },
          { data: 'holter:erequisition_id', visible: false },
          { data: 'holter:req_resolved_by', visible: false },
          { data: 'holter:upload_computer', visible: false },
        ],
        language: {
          infoFiltered: ""
        }
    };

    this.formGroup = new FormGroup({
      FirstName: new FormControl("", []),
      LastName: new FormControl("", []),
      Gender: new FormControl("", []),
      HealthCard: new FormControl("", []),
      HealthCardVersion: new FormControl("", []),
      Birthdate: new FormControl("", []),
      DxCode: new FormControl("", []),
      HookupDate: new FormControl("", []),
      Duration: new FormControl("", []),
      ScanDate: new FormControl("", []),
      Tech: new FormControl("", []),
      ReferringDoctor: new FormControl("", []),
      City: new FormControl("", []),
      Device: new FormControl("", []),
      Clinic: new FormControl("", []),
      ReadingDoctor: new FormControl("", []),
      Delivery: new FormControl("", []),
      StaffNotes: new FormControl("", []),
      FaxNotes: new FormControl("", []),
      ReqNotes: new FormControl("", []),
      AdditionalStaffNotes: new FormControl("", []),
      AdditionalFaxNotes: new FormControl("", []),
      Requisition: new FormControl("", []),
      RequisitionSearch: new FormControl("", []),
      FlagVerified: new FormControl("", []),
      // FlagPacemakerInquiry: new FormControl("", []),
      // FlagNoData: new FormControl("", []),
      // FlagNoReq: new FormControl("", []),
      FlagNoBackup: new FormControl("", []),
      FlagNoTracings: new FormControl("", []),
      FlagInvalidReferring: new FormControl("", []),
      FlagInvalidHealthcard: new FormControl("", []),
      FlagInvalidDob: new FormControl("", []),
    });

    this.checkBoxFormGroup = new FormGroup({
      // SkipToReading: new FormControl("", []),
      // Reserved: new FormControl("", []),
      FlagNoData: new FormControl("", []),
      FlagPacemakerInquiry: new FormControl("", []),
      FlagNoReq: new FormControl("", []),
      FlagNoTracings: new FormControl("", []),
    });

    this.formGroup.valueChanges.subscribe(x => {
      this.formChanged = true;
    });

    this.checkBoxFormGroup.valueChanges.subscribe(x => {
      this.checkBoxFormChanged = true;
    });

    this.holterService.getDatabaseOptions().then(options => {
      this.searchDatabases = options;
    });

    this.holterService.getHolterErrors("pending").then(errors => {
      const options = [];
      for (const [key, value] of Object.entries(errors)) {
        options.push({
          name: key,
          count: value,
        });
      }
      this.holterErrors = options;
    });

    this.physicianService.allReading().then(doctors => {
      doctors.forEach(doctor => {
        const text = [
          doctor.last_name,
          doctor.first_initials,
        ].filter(x => !!x).join(" | ");

        this.readingDoctorOptions.push({
          value: doctor.name,
          text: text,
        });
      });
    });

    this.formGroup.get("ReferringDoctor")?.valueChanges.subscribe(event => this.getBillingCodeObservable(event, this));
    this.formGroup.get("Clinic")?.valueChanges.subscribe(event => this.getBillingCodeObservable(event, this));
    this.setupTracingsVerificationSubscribtions();

    // refresh the page every 1 minute
    setInterval(() => {
      this.reloadData(this)
    }, 30000);
  }

  ngOnDestroy() {
    // TODO: NEED TO UNSUBSCRIBE ALL FORM SUBSCRIBES
  //   this.subscription = checkboxControl.valueChanges.subscribe(checkbox => {
  //     checkboxControl.setValue(
  //         checkboxControl.value.map((value, i) => value ? this.checkboxes[i].value : false),
  //         { emitEvent: false }
  //     );
  // });


    // this.formGroup.unsubscribe();
  }

  public verifyTracings() {
    $("body, button").css("cursor", "wait");
    this.verifyTracingsObservable(null, this).then(() => {
      $("body, button").css("cursor", "auto");
    });
  }

  private setupTracingsVerificationSubscribtions() {
    this.changeSubs = [
      this.formGroup.get("Gender")?.valueChanges.subscribe(event => this.verifyTracingsObservable(event, this)),
      this.formGroup.get("Duration")?.valueChanges.subscribe(event => this.verifyTracingsObservable(event, this)),
      this.formGroup.get("ReadingDoctor")?.valueChanges.subscribe(event => this.verifyTracingsObservable(event, this)),
    ];
  }

  private verifyTracingsObservable(event, that) {
    if (!that.currentHolter || !that.loaded || !that.currentHolter.uploaded_files.includes("out_01.pdf")) {
      return;
    }

    return that.holterService.getMedfluxVars(that.currentHolter.id)
    .then(medfluxVars => {
        this.errorStrings = {};

        const tracingGender = medfluxVars.pdf["GENDER"] || null;
        if (tracingGender !== that.formGroup.get("Gender")?.value) {
          this.errorStrings.gender = "Conflict: " + (tracingGender || "empty");
        }

        const tracingDuration = medfluxVars.pdf["DAYS RECORDED"] || null;
        if (tracingDuration != that.formGroup.get("Duration")?.value) {
          this.errorStrings.duration = "Conflict: " + (tracingDuration || "empty");
        }

        const tracingReadingDr = medfluxVars.pdf["READING DR"] || null;
        if (tracingReadingDr != that.formGroup.get("ReadingDoctor")?.value) {
          this.errorStrings.readingDr = "Conflict: " + (tracingReadingDr || "empty");
        }

        if (that.formGroup.get("ReadingDoctor")?.value === that.currentHolter.reading_doctor_name && that.currentHolter.reading_doctor_roles !== "Reading") {
          this.errorStrings.readingDr = "Not a registered reading doctor";
        }
      });
  }

  private getBillingCodeObservable(event, that) {
    if (!that.currentHolter || !that.loaded) {
      return;
    }
    const referringDoctorValue = this.formGroup.get("ReferringDoctor")?.value;
    const doctor = referringDoctorValue ? (referringDoctorValue.name || referringDoctorValue) : null;
    const clinic = that.formGroup.get("Clinic")?.value;
    if (doctor && clinic) {
      // SEARCHING BILLING CODE DOESNT WORK WHEN CLINICS/DOCTORS HAVE SAME NAMES IN DIRECTORY
      // this.physicianService.searchBillingCode(doctor, clinic).then(billingCode => {
      //   this.billingCode = billingCode || null;
      // });
      this.billingCode = this.currentDoctor?.billing_code || null;
    } else {
      this.billingCode = null;
    }
  }

  private updateMultiSelected(that: any) {
    that.datatableElement.dtInstance.then((dtInstance: any) => {
      const selectedData = dtInstance.rows(".selected").data();
      that.multiSelected = selectedData.length > 1;
      that.autoRefreshing = selectedData.length <= 0;
    });
  }

  public rowClickHandler(info: any, force: boolean = false) {
    setTimeout(() => this.updateMultiSelected(this), 200);
    this.loadingPdf = true;
    this.physicianService.getDoctor(info["referring_doctor:id"], info["clinic:id"]).then(doctor =>
    {
      this.currentDoctor = doctor;
      this.holterService.getHolter(info["holter:id"]).then(holter => {
        this.populateHolter(holter);
        this.getReqs();
        this.datatableElement.dtInstance.then((dtInstance: any) => {
          const selectedData = dtInstance.rows(".selected").data();
          if (selectedData.length == 1) {
            if (this.showPdfPreview == "tracings"){
              this.showTracings();
            }
            if (this.showPdfPreview == "requisition"){
              this.showRequisition();
            }
          }
        });
      }).catch((error: any) => {
        this.loadingPdf = false;
      });
    });

  }

  public getReqs(){
    this.reqAuto = [];
    if (this.formGroup.get("RequisitionSearch")?.value == null){
      this.requisitionService.searchRequisition(
        this.formGroup.get("LastName")?.value, true, {
          first_name: "",
          last_name: "No requisition found",
      }).then(result => {
        this.reqAuto = result;
      });
    }
  }

  public formatReq(req) {
    return ((req.last_name || req.first_name) ? (req.last_name + ", " + req.first_name) : "") +
          (req.date ? (" - " + moment(req.date).format("YYYY-MM-DD")) : "") + (req.status ? (" " + req.status) : "");
  }

  public toggleSelection(item, list) {
    item.selected = !item.selected;
  }

  public showTracings() {
    return this.holterService.getTracings(this.currentHolter.id, "annotated").then(url => {
      this.pdfSrc = url;
      // this.showPdfPreview = "tracings";
      setTimeout(() => {
        this.loadingPdf = false;
      }, 200);
    });
  }

  public showRequisition() {
    this.holterService.getRequisition(this.currentHolter.id).then(url => {
      this.pdfSrc = url;
      setTimeout(() => {
        this.loadingPdf = false;
      }, 200);
    });
  }

  private populateHolter(holter: Holter) {
    this.errorStrings = {};
    this.loaded = false;
    this.currentHolter = holter;
    this.reqID = null;
    this.formGroup.reset();
    this.checkBoxFormGroup.reset();
    holter.erequisition_id ? this.attachedReqPlaceHolder = "E-Requisition attached": this.attachedReqPlaceHolder = "Type to search";
    const requisitionStr = this.searchRequisitionFormatter({
      last_name: holter.requisition_last_name,
      first_name: holter.requisition_first_name,
      date: holter.requisition_date,
      status: holter.requisition_status,
    });
    this.checkBoxFormGroup.patchValue({
      // Reserved: this.hasReserved(holter.reservations, token.email),
      FlagNoData: holter.flag_monitor_no_data,
      FlagNoReq: holter.req_resolved_by ? !holter.flag_no_req : holter.flag_no_req,
      FlagPacemakerInquiry: holter.flag_pacemaker_inquiry,
      FlagNoTracings: holter.flag_no_tracings,
    })
    this.formGroup.patchValue({
      FirstName: holter.patient_first_name,
      LastName: holter.patient_last_name,
      Gender: holter.patient_gender,
      HealthCard: holter.patient_health_card.substring(0,10),
      HealthCardVersion: holter.patient_health_card_code,
      Birthdate: holter.patient_birthdate,
      DxCode: holter.dx_code_code,
      HookupDate: holter.hookup_date,
      Duration: holter.duration,
      ScanDate: holter.scan_date,
      Tech: holter.staff_first_name ? (holter.staff_first_name + " " + holter.staff_last_name) : null,
      ReferringDoctor: holter.referring_doctor_name,
      City: holter.clinic_city,
      Device: holter.device_serial_number,
      Clinic: holter.clinic_name,
      ReadingDoctor: holter.reading_doctor_name,
      Delivery: holter.delivery,
      StaffNotes: formatNotesMultiple([holter.notes, holter.requisition_staff_notes]),
      FaxNotes: formatNotesMultiple([holter.fax_notes, holter.requisition_fax_notes]),
      ReqNotes: holter.requisition_comments,
      Requisition: requisitionStr,
      FlagVerified: holter.flag_verified,
      // FlagPacemakerInquiry: holter.flag_pacemaker_inquiry,
      // FlagNoData: holter.flag_no_data,
      // FlagNoReq: holter.flag_no_req,
      FlagNoBackup: holter.flag_no_backup,
      // FlagNoTracings: holter.flag_no_tracings,
      FlagInvalidReferring: holter.flag_invalid_referring,
      FlagInvalidHealthcard: holter.flag_invalid_healthcard,
      FlagInvalidDob: holter.flag_invalid_dob,
    });
    this.formChanged = false;
    this.checkBoxFormChanged = false;
    this.reqChanged = false;
    this.loaded = true;
    this.getBillingCodeObservable(null, this);
  }

  public updateHolter(): void {
    return this.submitHolter(null);
  }

  public submitHolter(approve: boolean): void {
    // if approve is null, it means it's an update

    if (this.currentHolter == null) {
      return;
    }
    $("body, button").css("cursor", "wait");
    let requisition = this.formGroup.get("Requisition")?.value;
    if (!requisition && !this.reqID) {
      requisition = null
    }
    else if (this.reqID){
      requisition = {
        id: this.reqID,
      }
    }
    else if (typeof requisition !== "object") {
      requisition = {
        id: this.currentHolter.requisition_id,
      }
    }
    if (requisition && requisition != this.currentHolter.requisition_id){
      this.requisitionService.reserve(requisition.id).then(() => {
        this.holterService.addNotes(this.currentHolter.id, "Requisition Reserved", null)
      });
    }
    let readingDr = this.formGroup.get("ReadingDoctor")?.value;
    if (this.readingDoctorOptions.filter(option => option.value == readingDr).length <= 0) {
      readingDr = null;
    }
    const referringDoctorValue = this.formGroup.get("ReferringDoctor")?.value;
    const token = this.authService.getToken(true);
    // Checkbox for requisition resolved
    let flag_req = this.checkBoxFormGroup.get("FlagNoReq")?.value;
    if (!this.currentHolter.requisition_id && !flag_req){
      this.holterService.reqResolved(this.currentHolter.id).then(() => {
        this.holterService.addNotes(this.currentHolter.id, "Requisition Resolved", null)
      });
    }
    // Checkbox for pacemaker
    let pacemakerInquiry = this.checkBoxFormGroup.get("FlagPacemakerInquiry")?.value;
    if (this.currentHolter.flag_pacemaker_inquiry != pacemakerInquiry){
      let notes;
      !this.currentHolter.flag_pacemaker_inquiry ? notes = "Pacemaker Inquiry" : notes = "Pacemaker Resolved";
      this.holterService.addNotes(this.currentHolter.id, notes, null)
    }
    // Checkbox for monitor no data
    let monitorNoData = this.checkBoxFormGroup.get("FlagNoData")?.value;
    if (monitorNoData && !this.currentHolter.flag_monitor_no_data){
      this.holterService.addNotes(this.currentHolter.id, "Monitor No Data", null)
    }

    const uploadData = {
      patient: {
        first_name: this.formGroup.get("FirstName")?.value,
        last_name: this.formGroup.get("LastName")?.value,
        healthcard: this.formGroup.get("HealthCard")?.value,
        healthcard_version: this.formGroup.get("HealthCardVersion")?.value,
        gender: this.formGroup.get("Gender")?.value,
      },
      dxcode: {
        code: this.formGroup.get("DxCode")?.value,
      },
      duration: this.formGroup.get("Duration")?.value,
      referring_doctor: {
        name: referringDoctorValue ? (referringDoctorValue.name || referringDoctorValue) : null,
      },
      clinic: {
        name: this.formGroup.get("Clinic")?.value,
        city: this.formGroup.get("City")?.value,
        clinic_id: this.currentDoctor ? this.currentDoctor.clinic?.id : null,
      },
      reading_doctor: {
        name: readingDr,
      },
      staff: {
        name: token.first_name + " " + token.last_name,
      },
      delivery: this.formGroup.get("Delivery")?.value,
      notes: this.formGroup.get("StaffNotes")?.value,
      fax_notes: this.formGroup.get("FaxNotes")?.value,
      requisition: requisition,
      // flag_verified: this.formGroup.get("FlagVerified")?.value,
      // flag_no_data: this.formGroup.get("FlagNoData")?.value,
      // flag_no_req: this.formGroup.get("FlagNoReq")?.value,
      // flag_no_backup: this.formGroup.get("FlagNoBackup")?.value,
      // flag_no_tracings: this.formGroup.get("FlagNoTracings")?.value,
      // flag_invalid_referring: this.formGroup.get("FlagInvalidReferring")?.value,
      // flag_invalid_healthcard: this.formGroup.get("FlagInvalidHealthcard")?.value,
      // flag_invalid_dob: this.formGroup.get("FlagInvalidDob")?.value,

      // CHECKBOX GROUP
      flag_pacemaker_inquiry: this.checkBoxFormGroup.get("FlagPacemakerInquiry")?.value,
      flag_monitor_no_data: this.checkBoxFormGroup.get("FlagNoData")?.value,
    };
    // can only approve if patient is assigned
    if (approve !== null && this.currentHolter.patient_id) {
      uploadData["state"] = "EDITING";
    }

    for(var subscription of this.changeSubs) {
      subscription.unsubscribe();
    }
    this.holterService.updateHolter(this.currentHolter.id, uploadData).then(() => {
      this.holterService.getHolter(this.currentHolter.id).then(holter => {
        $("body, button").css("cursor", "auto");
        this.formChanged = false;
        this.checkBoxFormChanged = false;
        this.reqChanged = false;
        this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.draw(false);
          this.populateHolter(holter);
          this.getReqs();
        });
        if (approve !== null) {
          this.formGroup.reset();
          this.checkBoxFormGroup.reset();
          this.currentHolter = null;
          this.errorStrings = {};
          this.loaded = false;
          this.reqID = null;
          this.reqAuto = null;
        }
        this.setupTracingsVerificationSubscribtions();
      });
    });
  }

  public formatDatabaseDisplay(database: any) {
    if (database.computer) {
      return database.computer + ' ' + database.path;
    }
    return database.path;
  }

  public applySmartFilter() {
    const toFilter = this.holterErrorIdx == null ? null : this.holterErrors[this.holterErrorIdx];
    this.resetFilter();
    if (toFilter == null) {
      // no-op
    } else if (toFilter.name == "Duplicate Data") {
      this.holterService.applyGroupBy("holter_duplicates");
    } else if (toFilter.name == "No Requisition") {
      this.holterService.updateFlag("flag_no_req", true);
    } else if (toFilter.name == "No Data") {
      this.holterService.updateFlag("flag_no_data", true);
    } else if (toFilter.name == "Pacemaker Inquiry") {
      this.holterService.updateFlag("flag_pacemaker_inquiry", true);
    } else if (toFilter.name == "Invalid Referring Doctor") {
      this.holterService.updateFlag("flag_invalid_referring", true);
    } else if (toFilter.name == "Invalid Healthcard") {
      this.holterService.updateFlag("flag_invalid_healthcard", true);
    } else if (toFilter.name == "Invalid Date of Birth") {
      this.holterService.updateFlag("flag_invalid_dob", true);
    } else if (toFilter.name == "Doctor Reject") {
      this.holterService.updateRejectedFilter(true);
      this.holterService.updateStateFilter(["READING"]);
    } else if (toFilter.name == "Editor Reject") {
      this.holterService.updateRejectedFilter(true);
      this.holterService.updateStateFilter(["EDITING", "RESERVED"]);
    } else if (toFilter.name == "Unlinked Reading Doctor") {
      this.holterService.updateInvalidReadingDoctorFilter(true);
    }
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  public databaseFilterChanged() {
    const toFilter = this.filterDatabaseIdx == null ? null : this.searchDatabases[this.filterDatabaseIdx];
    if (toFilter == null) {
      this.holterService.updateComputerFilter(null, null);
    } else {
      this.holterService.updateComputerFilter(toFilter.computer, toFilter.path);
    }
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  public expandRowsHandler(event: any, info: any, expand: boolean) {
    const clickedId = info["holter:id"];

    if (expand)
    {
      this.holterService.setCustomFilter("show_duplicates");
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
          const row = dtInstance.rows().data()[rowIdx];
          if (row["holter:id"] != clickedId) {
            return;
          }
          this.holterService.updateShowDuplicates(row["holter:verification_number"], row["holter:device_id"]);
          dtInstance.page(0).draw();
        });
      });
    }
    else
    {
      this.holterService.setCustomFilter(null);
      this.holterService.updateShowDuplicates(null, null);
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.page(0).draw();
      });
    }
  }

  public downloadFileHandler(event: any, info: any) {
    setTimeout(() => {
      $("body, .table-download-icon, tr").css("cursor", "wait");
      return this.holterService.getHolter(parseInt(info["holter:id"]))
      .then((holter: Holter | null) => {

        // no holter found with matching id
        if (!holter) {
          return;
        }

        // determine which download was clicked
        const classNames = event.target.className;
        const fileNamePrefix = holter.patient_last_name + "_" + holter.patient_first_name + "_" + holter.hookup_date + "_";
        if (classNames.includes('download-tracings')) {
          return this.holterService.getTracings(holter.id, "flattened").then(url => {
            this.downloadPDF(url, holter, 'tracing', 'pdf');
          });
        } else if (classNames.includes('download-backup')) {
          return this.holterService.backupUrls(holter.id).then(urls => {
            const zip_name = `${holter.patient_last_name}, ${holter.patient_first_name}_backup_${holter.hookup_date}_${holter.referring_doctor_name}.zip`;
            var count = 0;
            var zip = new JSZip();
            urls.forEach(url => {
              return JSZipUtils.getBinaryContent(url.url, (err, data) => {
                zip.file(url.filename, data, {binary:true});
                count++;
                this.downloadProgress = "Downloaded " + count + "/" + urls.length + " files";
                if (count == urls.length) {
                  this.downloadProgress = "Zipping files. This may take a couple of minutes. Do not navigate away from page.";
                  zip.generateAsync({type:'blob'}).then(content => {
                    saveAs(content, zip_name);
                    this.downloadProgress = null;
                  });
                }
              });
            });
          });
        } else if (classNames.includes('download-preliminary')) {
          return this.holterService.getMonitorReport(holter.id)
          .then((base64Data: string) => {
            download(HolterService.base64ToArrayBuffer(base64Data), fileNamePrefix + "prelim", "application/pdf");
          })
        } else if (classNames.includes('download-final')) {
          return this.holterService.getMonitorReport(holter.id)
          .then((base64Data: string) => {
            download(HolterService.base64ToArrayBuffer(base64Data), fileNamePrefix + "final", "application/pdf");
          })
        } else if (classNames.includes('download-req')) {
          return this.holterService.getRequisition(holter.id).then((url) => {
            this.downloadPDF(url, holter, 'req', 'pdf');
          });
        }
        return;
      })
      .then(() => {
        $("body, .table-download-icon, tr").css("cursor", "auto");
      })
      .catch(() => {
        $("body, .table-download-icon, tr").css("cursor", "auto");
      });
    }, 500);
  }

  searchRequisition = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => (term.length < 2) ? Promise.resolve([])
        : this.requisitionService.searchRequisition(
          term, false, {
            first_name: "",
            last_name: "No requisition found",
        }))
    );

  searchRequisitionFormatter = (result: any): string => {
    if (typeof result !== 'object') {
      return result;
    }
    return ((result.last_name || result.first_name) ? (result.last_name + ", " + result.first_name) : "") +
      (result.date ? (" - " + moment(result.date).format("YYYY-MM-DD")) : "") + (result.status ? (" " + result.status) : "");
  };

  selectedReq(item){
    this.requisitionService.getRequisitionPresignedUrl(item.item.id).then(url => {
      this.pdfSrc = url;
      setTimeout(() => {
        this.loadingPdf = false;
      }, 200);
    });
  }

  selectedSearchReq(item){
    if (this.currentHolter?.state != 'PENDING'){
      return;
    }
    this.reqID = item.id;
    this.reqChanged = true;
    this.requisitionService.getRequisitionPresignedUrl(item.id).then(url => {
      this.pdfSrc = url;
      setTimeout(() => {
        this.loadingPdf = false;
      }, 200);
    });
  }

  searchRequisitionInputFormatter = (result: any): string => {
    return this.searchRequisitionFormatter(result);
  };

  searchReferringDoctor = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => (!this.authService.isCardioStudyCompanyClass() || term.length < 2) ? Promise.resolve([])
        : this.physicianService.searchDoctor(term, true, false))
    )

  searchReferringDoctorFormatter = (result: any): string => {
    return [
      result.last_name,
      result.first_initials,
      result.clinic ? result.clinic.city : null,
      result.billing_code,
    ].filter(x => !!x).join(" | ")
  };

  searchReferringDoctorInputFormatter = (result: any): string => {
    if (!result.name) {
      return result;
    }

    this.formGroup.patchValue({
      ReferringDoctor: result.name,
      City: result.clinic ? result.clinic.city : null,
      Clinic: result.clinic ? result.clinic.name : null,
    });
    this.errorStrings.city = result.clinic ? null : "None found";
    this.currentDoctor = result;
    return result.name;
  };

  public toggleFileTypeFilter(fileType) {
    if (fileType == "pdf") {
      if (this.currentHolter) this.showTracings();
      this.showPdfPreview = "tracings"
    }
    if (fileType == "req") {
      if (this.currentHolter) this.showRequisition();
      this.showPdfPreview = "requisition"
    }

    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw(false);
    });
  }

  public flagPacemakeInquiry() {
    this.holterService.updateHolter(this.currentHolter.id, {
      flag_pacemaker_inquiry: !this.currentHolter.flag_pacemaker_inquiry,
    }).then(() => {
      this.holterService.getHolter(this.currentHolter.id).then(holter => this.populateHolter(holter));
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.draw();
      });
    });
  }

  public disableControl(): boolean {
    if (!this.currentHolter) {
      return true;
    }
    return !!this.currentHolter.closed_by || ["PENDING", "FAXING"].indexOf(this.currentHolter.state) >= 0;
  }

  public addStaffNotes() {
    const modalRef = this.modalService.open(ModalStaffNotesComponent, {size:'lg'});
    modalRef.componentInstance.disable = true;
    modalRef.componentInstance.holter = this.currentHolter;
    modalRef.result.then(result => {
      this.holterService.addNotes(this.currentHolter.id, result.staffNotes, null).then(() => {
        this.holterService.getHolter(this.currentHolter.id).then(holter => this.populateHolter(holter));
      });
    });
  }

  public openCreateFaxModal() {
    if (!!this.currentHolter.closed_by) {
      return;
    }
    const modalRef = this.modalService.open(ModalCreateFaxComponent, {size:'md'});
    modalRef.componentInstance.holter = this.currentHolter;
    modalRef.componentInstance.doctor = this.currentDoctor;
    modalRef.result.then(result => {
      let toBatch = {
        final: false,
        notes: true,
        notes_req: result.faxNotesReq ? result.faxNotesReq : false,
        notes_data: result.faxNotesData ? result.faxNotesData : false,
        notes_pacemaker: result.faxNotesPacemaker ? result.faxNotesPacemaker : false,
        req: false,
        tracings:false,
      }

      if (result.createFax){
        this.holterService.scheduleFax(this.currentHolter.id, {
          doctor: result.referring_doctor,
          fax_number: result.fax,
          fax_notes: result.faxNotes,
          to_fax: toBatch,
          // holter_additional_fax_numbers: result.faxNumbers ? JSON.stringify(result.faxNumbers) : null,
        }).then(() => {
          this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
            let faxedList = "";
            result.faxNotesReq ? ((faxedList == "") ? faxedList = faxedList + "Patient Req" : null): null;
            result.faxNotesData ? ((faxedList == "") ? faxedList = faxedList + "Patient Data" : faxedList = faxedList + ", Patient Data" ): null;
            result.faxNotesPacemaker ? ((faxedList == "") ? faxedList = faxedList + "Pacemaker" : faxedList = faxedList + ", Pacemaker" ): null;
            this.holterService.addNotes(this.currentHolter.id, null, result.faxNotes);
            this.holterService.addNotes(this.currentHolter.id, "Fax sent for " + faxedList, null);
            dtInstance.draw(false);
            this.holterService.getHolter(this.currentHolter.id).then(holter => {
              this.populateHolter(holter);
            });
          });
        });
      }
      if (result.faxNotes){
        this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
          this.holterService.addNotes(this.currentHolter.id, null, result.faxNotes);
          dtInstance.draw(false);
          this.holterService.getHolter(this.currentHolter.id).then(holter => this.populateHolter(holter));
        });
      }
      if (result.faxNumbers || result.faxWithTracings) {
        this.holterService.updateHolter(this.currentHolter.id, {
          fax_numbers: result.faxNumbers ? result.faxNumbers : null,
          flag_fax_with_tracing: result.faxWithTracings ? result.faxWithTracings : null,
        }).then(() => {
          this.holterService.getHolter(this.currentHolter.id).then(holter => {
            this.currentHolter = holter;
          });
        });
      }
    });
  }

  public hideEntry() {
    this.holterService.updateHolter(this.currentHolter.id, {
      is_test: true,
    }).then(() => {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        const remaining_count = dtInstance.data().count() - dtInstance.rows(".selected").count();
        if (remaining_count == 1){
          this.holterService.setCustomFilter(null);
          this.holterService.updateShowDuplicates(null, null);
        }
        dtInstance.draw();
      });
    })
  }

  public reloadData(that: any) {
    return that.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      const selectedData = dtInstance.rows(".selected").data();
      if (selectedData.length <= 0) {
        dtInstance.draw(false);
      }
    });
  }

  public syncHolter() {
    this.datatableElement?.dtInstance.then((dtInstance: any) => {
      const updatePromises = [];
      dtInstance.rows(".selected").every((idx) => {
        const entry = dtInstance.row(idx).data();
        updatePromises.push(this.holterService.syncRequest(entry["holter:id"]));
      });
      Promise.all(updatePromises).then(() => dtInstance.draw(false));
    });
  }

  public showUserEntries() {
    this.showEntries = !this.showEntries;
    localStorage.setItem(document.location.href+'work_list', String(this.showEntries));
    this.resetFilter();
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  private downloadPDF(url: any, holter: Holter, fileType: string, extension: string) {
    this.holterService.downloadFile(url)
      .subscribe(blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = holter.patient_last_name + "_" + holter.patient_first_name + "_" + holter.hookup_date + "_" + fileType + "." + extension;
        a.click();
        URL.revokeObjectURL(objectUrl);
      });
  }

  private getPdfViewerSpacing(){
    const pdfsize = JSON.parse(localStorage.getItem('readingSpace'));
    pdfsize ? this.pdfSizeLeft = pdfsize : this.pdfSizeLeft = 50;
    this.pdfSizeRight = 100 - this.pdfSizeLeft;
  }

  public onDragEnd(e: { gutterNum: any; sizes: Array<any> }) {
    localStorage.setItem('readingSpace', e.sizes[0]);
  }

  public faxType(fileType: string) {
    let types = ""
    if (fileType.includes("final")){
      types = types + "FINAL ";
    }
    if (fileType.includes("tracings")){
      types = types + "TRACINGS ";
    }
    if (fileType.includes("preliminary")){
      types = types + "PRELIMINARY ";
    }
    if (fileType.includes("notes_req")){
      types = types + "REQ FORM ";
    }
    else if(fileType.includes("req")){
      types = types + "REQ ";
    }
    if (fileType.includes("notes_data")){
      types = types + "PATIENT DATA ";
    }
    if (fileType.includes("notes_pacemaker")){
      types = types + "PACEMAKER ";
    }
    return types;
  }
}


import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';

import { FormControl, FormGroup, Validators } from "@angular/forms";

import * as moment from "moment";

import { Holter } from "../../models/holter.model";
import { HolterService } from "../../providers/holter.service";

@Component({
  selector: 'app-home',
  templateUrl: './holter-detail-debug.component.html',
  styleUrls: ['./holter-detail-debug.component.scss']
})
export class HolterDetailDebugComponent implements OnInit {

  public error: string = "";
  public holter: Holter = new Holter();

  public title: string = "";

  public holter$: Observable<Holter>;

  public createFormGroup: FormGroup;
  public measurements: any[] = [];

  constructor(
    private holterService: HolterService,
    private route: ActivatedRoute,
    private router: Router
  ) {

    // the following allows reloading of the same route
    this.router.routeReuseStrategy.shouldReuseRoute = function(){
        return false;
    };
    this.router.events.subscribe((evt) => {
        if (evt instanceof NavigationEnd) {
            this.router.navigated = false;
            window.scrollTo(0, 0);
        }
    });
  }

  public ngOnInit() {

    // get the Holter object from the server
    this.holter$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.holterService.getHolter(parseInt(params.get('id'), 10)))
    );

    // initialize form
    this.holter$.subscribe((holter: Holter) => {
        if (holter && holter.patient_first_name && holter.patient_last_name) {
          if ((holter.patient_first_name.length + holter.patient_last_name.length) < 20) {
            this.title = "Medflux - " + holter.patient_first_name + " " + holter.patient_last_name;
          } else {
            this.title = "Medflux - Debug";
          }
        } else {
          this.title = "Medflux - Debug";
        }

        this.holterService.getMedfluxVars(holter.id).then(medfluxVars => {
          const groups: any = {};
          for (const groupName of ["pat", "pdf", "verbal", "merged"]) {
            for (var varName in medfluxVars[groupName]) {
              const value = medfluxVars[groupName][varName];
              if (!(varName in groups)) {
                groups[varName] = {
                  name: varName,
                };
              }
              groups[varName][groupName] = value;
            };
          }

          this.measurements = Object.keys(groups).map(k => groups[k]);
          this.measurements.sort((a, b) => a.name.localeCompare(b.name));
        })

    });

    this.createFormGroup = new FormGroup({
      HealthCard: new FormControl("", [ Validators.required, ]),
      ReferringDoctorName: new FormControl("", [ Validators.required, ]),
      FirstName: new FormControl("", [ Validators.required, ]),
      LastName: new FormControl("", [ Validators.required, ]),
      Location: new FormControl("", [ Validators.required, ]),
      Birthdate: new FormControl("", [ Validators.required, Validators.pattern(/^\d{4}\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/) ]),
      Height: new FormControl("", [ Validators.required, Validators.pattern(/^\d+$/) ]),
      Weight: new FormControl("", [ Validators.required, Validators.pattern(/^\d+$/) ]),
      Gender: new FormControl("", [ Validators.required, ]),
      Indication: new FormControl("", [ ]),
      Medications: new FormControl("", [ ]),
      ReadBy: new FormControl("", [ Validators.required, ]),
    });
  }
}

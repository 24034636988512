import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { version } from '../../../package.json';

import { environment } from "../../environments/environment";
import { AuthService } from "../providers/auth.service";
import { SparkDevice } from "../models/spark-device.model";
import { DataTablesResponse } from "../models/database-response.model";

import { formatLocaleTime } from "../utils/format";

import { Observable } from "rxjs";

@Injectable()
export class SparkService {

  private readonly JOB_URL: string = environment.api + "/job";
  private readonly CLIENT_NAME: string = "cardio_study";

  private customDeviceFilter: string | null = null;
  private customUploadFilter: string | null = null;
  private customDisplayFilter: string | null = null;
  private focusDeviceIDFilter: number | null = null;
  private focusFilesFilter: string | null = null;
  private displayDeviceFilter: string | null = null;
  private displayDevicePathFilter: string | null = null;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  public clearFilters() {
    this.customDeviceFilter = null;
    this.customUploadFilter = null;
    this.customDisplayFilter = null;
  }

  public clearFocusFilters() {
    this.customUploadFilter = null;
    this.focusDeviceIDFilter = null;
    this.focusFilesFilter = null;
  }

  public clearDisplayFilters() {
    this.customDisplayFilter = null;
  }

  public setCustomDeviceFilter(filterName: string | null) {
    this.customDeviceFilter = this.customDeviceFilter == filterName ? null : filterName;
  }

  public setCustomUploadFilter(filterName: string | null) {
    this.customUploadFilter = this.customUploadFilter == filterName ? null : filterName;
  }

  public setCustomDisplayFilter(filterName: string | null) {
    this.customDisplayFilter = this.customDisplayFilter == filterName ? null : filterName;
  }

  public updateDisplayDevicePathFilter(deviceID: string | null, path: string | null) {
    this.displayDeviceFilter = deviceID;
    this.displayDevicePathFilter = path;
  }

  public updateUploadDeviceIDFilter(id: number | null) {
    this.focusDeviceIDFilter = id;
  }

  public updateUploadFileFilter(file: string | null) {
    this.focusFilesFilter = file;
  }
  // this is used to override the ajax call in datatables for server-side processing
  public getDeviceAjaxFunction(): any {

    // pass this class to the callback through "that"
    const that: SparkService = this;

    // server side rendering
    return (dataTablesParams: any, callback: any): void => {

      // populate the info in multiform part
      dataTablesParams["table"] = "spark_device";

      if (!!this.customDeviceFilter) {
        dataTablesParams["custom_spark"] = this.customDeviceFilter;
      }

      const formData: FormData = new FormData();
      formData.append("info", JSON.stringify({
        client: that.CLIENT_NAME,
        job_name: "query_data",
        job_id: null,
        task_status: "create",
        data: dataTablesParams
      }));

      // request the data from url
      that.http.post(that.JOB_URL, formData, this.httpOptions()).toPromise()
        .then((response: any) => {

          // callback to the datatable to rerender with the updated information
          const result: DataTablesResponse = response.Payload.data.result;
          callback({
            recordsTotal: result.recordsTotal,
            recordsFiltered: result.recordsFiltered,
            data: result.data.map(formatLocaleTime),
          });

        })
        .catch((error: any) => {
          console.log("Error datatable handler for Spark Device: " + error.message);
        });
    };
  }

  public getDisplayAjaxFuncion(): any {

    // pass this class to the callback through "that"
    const that: SparkService = this;

    // server side rendering
    return (dataTablesParams: any, callback: any): void => {

      // populate the info in multiform part
      dataTablesParams["table"] = "spark_upload";

      if (!!this.customDisplayFilter) {
        dataTablesParams["custom_spark"] = this.customDisplayFilter;
        dataTablesParams["display_path"] = this.displayDevicePathFilter;
        dataTablesParams["display_device"] = this.displayDeviceFilter;
      }

      const formData: FormData = new FormData();
      formData.append("info", JSON.stringify({
        client: that.CLIENT_NAME,
        job_name: "query_data",
        job_id: null,
        task_status: "create",
        data: dataTablesParams
      }));

      // request the data from url
      that.http.post(that.JOB_URL, formData, this.httpOptions()).toPromise()
        .then((response: any) => {

          // callback to the datatable to rerender with the updated information
          const result: DataTablesResponse = response.Payload.data.result;
          callback({
            recordsTotal: result.recordsTotal,
            recordsFiltered: result.recordsFiltered,
            data: result.data.map(formatLocaleTime),
          });

        })
        .catch((error: any) => {
          console.log("Error datatable handler for Spark Device: " + error.message);
        });
    };
  }

    // this is used to override the ajax call in datatables for server-side processing
    public getDeviceUploadAjaxFunction(): any {

        // pass this class to the callback through "that"
        const that: SparkService = this;

        // server side rendering
        return (dataTablesParams: any, callback: any): void => {

          // populate the info in multiform part
          dataTablesParams["table"] = "spark_upload";

          if (!!this.customUploadFilter) {
            dataTablesParams["custom_spark"] = this.customUploadFilter;
            // dataTablesParams["upload_device_id"] = this.focusDeviceIDFilter;
          }

          if (this.focusDeviceIDFilter !== null) {
            dataTablesParams.columns.push({
              data: 'spark_device:id',
              name: '',
              searchable: true,
              orderable: true,
              search: {
                regex: true,
                value: this.focusDeviceIDFilter,
              },
            });
          }

          // if (this.focusFilesFilter !== null) {
          //   dataTablesParams.columns.push({
          //     data: 'spark_upload:file',
          //     name: '',
          //     searchable: true,
          //     orderable: true,
          //     search: {
          //       regex: true,
          //       // method: "like",
          //       value: this.focusFilesFilter,
          //     },
          //   });
          // }

          const formData: FormData = new FormData();
          formData.append("info", JSON.stringify({
            client: that.CLIENT_NAME,
            job_name: "query_data",
            job_id: null,
            task_status: "create",
            data: dataTablesParams
          }));

          // request the data from url
          that.http.post(that.JOB_URL, formData, this.httpOptions()).toPromise()
            .then((response: any) => {

              // callback to the datatable to rerender with the updated information
              const result: DataTablesResponse = response.Payload.data.result;
              callback({
                recordsTotal: result.recordsTotal,
                recordsFiltered: result.recordsFiltered,
                data: result.data.map(formatLocaleTime),
              });

            })
            .catch((error: any) => {
              console.log("Error datatable handler for Spark Device: " + error.message);
            });
        };
      }

  private httpOptions(): any {
    return { headers: new HttpHeaders({
      "token": this.authService.getToken(),
      "version": version,
    }) };
  }

  public getDatabaseInfo(id: number, path: string): Promise<any> {
    const url = environment.api + '/spark/database-info?device=' + id + '&path=' + path;
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

}
<div>
    <div class="row sub-main">

      <div class="page-title">
        <div class="title_left">
          <h3>Fax - Directory</h3>
        </div>
      </div>
      <div class="clearfix"></div>

      <div class="x_panel">
        <div class="x_content">

          <br>

          <form class="form-horizontal form-label-left" [formGroup]="formGroup">
            <div class="row">

              <div class="col">
                <h4>Doctor Information</h4>
                <div class="form-group form-display row">
                  <div class="col form-group required">
                    <label>Last Name</label>
                    <input type="text" class="form-control" placeholder="" formControlName="LastName" [attr.disabled]="!currentDoctor ? '' : null"  [ngbTypeahead]="searchDoctor" [resultFormatter]="searchDoctorFormatter" [inputFormatter]="searchDoctorInputFormatter">
                  </div>
                  <div class="col form-group required">
                    <label>First Name</label>
                    <input type="text" class="form-control" placeholder="" formControlName="FirstName" [attr.disabled]="!currentDoctor ? '' : null">
                  </div>
                </div>
                <div class="form-group form-display row">
                  <div class="col-4 form-group">
                    <label>Billing No.</label>
                    <input type="text" class="form-control" placeholder="" formControlName="BillingNunber" [attr.disabled]="!currentDoctor ? '' : null">
                  </div>
                  <div class="col-2 form-group">
                    <label>Code</label>
                    <input type="text" class="form-control" placeholder="" formControlName="BillingCode" [attr.disabled]="!currentDoctor ? '' : null">
                  </div>
                  <div class="col-6 form-group">
                    <label>Fax No.</label>
                    <input type="text" class="form-control" placeholder="" formControlName="FaxNumber" [attr.disabled]="!currentDoctor ? '' : null">
                  </div>
                </div>
                <div class="form-group form-display row">
                  <div class="col form-group">
                    <label>Title</label>
                    <input type="text" class="form-control" placeholder="" formControlName="Title" [attr.disabled]="!currentDoctor ? '' : null">
                  </div>
                  <div class="col form-group">
                    <label>Roles</label>
                    <select class="form-control" data-live-search="true" formControlName="Roles" [attr.disabled]="!currentDoctor ? '' : null">
                      <option></option>
                      <option>Reading</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col">
                <h4>Clinic Information</h4>
                <div class="form-group form-display row">
                  <div class="col form-group required">
                    <label>Clinic Name</label>
                    <input type="text" class="form-control" placeholder="" formControlName="ClinicName" [attr.disabled]="!currentDoctor ? '' : null" [ngbTypeahead]="searchClinic" [resultFormatter]="searchClinicFormatter" [inputFormatter]="searchClinicInputFormatter">
                  </div>
                </div>
                <div class="form-group form-display row">
                  <div class="col form-group">
                    <label>Address</label>
                    <input type="text" class="form-control" placeholder="" formControlName="Address" [attr.disabled]="!currentDoctor ? '' : null">
                  </div>
                </div>
                <div class="form-group form-display row">
                  <div class="col form-group">
                    <label>City</label>
                    <input type="text" class="form-control" placeholder="" formControlName="City" [attr.disabled]="!currentDoctor ? '' : null">
                  </div>
                  <div class="col form-group">
                    <label>Province</label>
                    <input type="text" class="form-control" placeholder="" formControlName="Province" [attr.disabled]="!currentDoctor ? '' : null">
                  </div>
                  <div class="col form-group">
                    <label>Postal Code</label>
                    <input type="text" class="form-control" placeholder="" formControlName="PostalCode" [attr.disabled]="!currentDoctor ? '' : null">
                  </div>
                </div>
              </div>

            </div>
          </form>

          <div class="row">
            <div class="col-12">

              <div class="row">
                <div class="col-md-auto ml-4">
                  <div class="custom-control custom-switch">
                    <input class="custom-control-input" type="checkbox" id="switch2" [(ngModel)]="hiddenDoctors" (click)="showHiddenDoctorEntries()">
                    <label class="custom-control-label" for="switch2">Hidden Doctors</label>
                  </div>
                </div>
                <div class="col-md-auto">
                  <div class="custom-control custom-switch">
                    <input class="custom-control-input" type="checkbox" id="switch3" [(ngModel)]="hiddenClinics" (click)="showHiddenClinicEntries()">
                    <label class="custom-control-label" for="switch3">Hidden Clinics</label>
                  </div>
                </div>
                <div class="offset-1 col-6">
                  <input type="text" class="form-control search-bar" placeholder="Start typing to search doctor..." id="search-bar">
                </div>
                <div class="col-2 text-right">
                  <button class="btn btn-primary btn-review" (click)="upsertInfo()" [disabled]="!currentDoctor || !formGroup.valid || !formChanged" type="button">{{isAddingDoctor() ? 'Add' : 'Update'}}</button>
                </div>
              </div>

              <div>
                <table datatable [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover">
                  <thead>
                    <tr>
                      <th>Last Name</th>
                      <th>First Name</th>
                      <th>Billing Number</th>
                      <th>Code</th>
                      <th>Title</th>
                      <th>Roles</th>
                      <th>Fax Number</th>
                      <th>Clinic Name</th>
                      <th>City</th>
                      <th>Province</th>
                      <th>Id</th>
                    </tr>
                  </thead>
                </table>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>

    <div>
      <div class="row sub-footer">
        <div class="col-12">
          <button class="btn btn-primary btn-review" type="button" (click)="hideDoctor(true)" [hidden]="hiddenDoctors || hiddenClinics" [disabled]="!currentDoctor"> Hide Doctor</button>
          <button class="btn btn-primary btn-review" type="button" (click)="hideDoctor(false)" [hidden]="!hiddenDoctors || hiddenClinics" [disabled]="!currentDoctor"> Unhide Doctor</button>
          <button class="btn btn-primary btn-review" type="button" (click)="hideClinic(true)" [hidden]="hiddenClinics || hiddenDoctors" [disabled]="!currentDoctor"> Hide Clinic</button>
          <button class="btn btn-primary btn-review" type="button" (click)="hideClinic(false)" [hidden]="!hiddenClinics || hiddenDoctors" [disabled]="!currentDoctor"> Unhide Clinic</button>
          <!--
          <button class="btn btn-primary btn-review" type="button" (click)="disableDoctor()" [disabled]="true || !currentDoctor"> Disable</button>
          <button class="btn btn-primary btn-review" type="button" (click)="enableDoctor()" [disabled]="true || !currentDoctor"> Enable</button>
          -->
          <button class="btn btn-primary btn-review" type="button" (click)="newDoctor()" [disabled]="isAddingDoctor()"> New Doctor</button>
        </div>
      </div>
    </div>

  </div>
<div>
    <div class="row sub-main">

      <div class="page-title">
        <div class="title_left">
          <h3>Fax - Repair</h3>
        </div>
      </div>
      <div class="clearfix"></div>

      <div class="x_panel">
        <div class="x_content">
          <div class="row split-container">
            <as-split direction="horizontal" (dragEnd)="onDragEnd($event)">

              <as-split-area [size]="pdfSizeLeft">
          <br>

          <form class="form-horizontal form-label-left" [formGroup]="formGroup">
            <div class="row">
              <div class="col-5 px-0 ml-3 mr-n3">
                <div class="form-group form-display row">
                  <div class="col form-group">
                    <label>Last Name<i [hidden]="!errorStrings.last_name" class="conflict-message-inline">{{errorStrings.last_name}}</i></label>
                    <input type="text" class="form-control" placeholder="" formControlName="LastName" [attr.disabled]="isClosed() ? '' : null">
                  </div>
                  <div class="col form-group">
                    <label>First Name<i [hidden]="!errorStrings.first_name" class="conflict-message-inline">{{errorStrings.first_name}}</i></label>
                    <input type="text" class="form-control" placeholder="" formControlName="FirstName" [attr.disabled]="isClosed() ? '' : null">
                  </div>
                  <div class="col form-group">
                    <label>Health Card</label>
                    <input type="text" class="form-control" placeholder="" formControlName="HealthCard" [attr.disabled]="isClosed() ? '' : null">
                  </div>
                </div>
                <div class="form-group form-display row">
                  <div class="col form-group">
                    <label>Hookup Date</label>
                    <input type="text" class="form-control" placeholder="YYYY/MM/DD" formControlName="HookupDate" [attr.disabled]="isClosed() ? '' : null">
                  </div>
                  <div class="col form-group">
                    <label>Scan Date</label>
                    <input type="text" class="form-control" placeholder="YYYY/MM/DD" formControlName="ScanDate" [attr.disabled]="true">
                  </div>
                  <div class="col form-group">
                    <label>Tech</label>
                    <input type="text" class="form-control" placeholder="" formControlName="Tech" [attr.disabled]="true">
                  </div>
                </div>
                <div class="form-group form-display row">
                  <div class="col form-group">
                    <label>Reading Doctor <i [hidden]="!errorStrings.readingDr" class="conflict-message-inline">{{errorStrings.readingDr}}</i></label>
                    <input type="text" class="form-control" placeholder="" [class.has-conflict]="!!errorStrings.readingDr" formControlName="ReadingDoctor" list="readinglist" [attr.disabled]="true">
                    <datalist id="readinglist">
                      <option *ngFor="let dr of readingDoctorOptions" [value]="dr.value">{{dr.text}}</option>
                    </datalist>
                  </div>
                  <div class="col-6 form-group">
                    <label>Referring Doctor</label>
                    <input type="text" class="form-control" placeholder="" formControlName="ReferringDoctor" [attr.disabled]="multiSelected || isClosed() ? '' : null" [ngbTypeahead]="searchReferringDoctor" [resultFormatter]="searchReferringDoctorFormatter" [inputFormatter]="searchReferringDoctorInputFormatter">
                  </div>



                </div>

                <div class="col form-group">
                  <form [formGroup]="checkBoxFormGroup">
                    <div class="form-group form-display row ml-4 mr-n4">
                      <div class="col form-group">
                        <input class="form-check-input" type="checkbox" name="check0" id="check0" formControlName="Reserved" [attr.disabled]="''">
                        <label class="form-check-label" for="check0">Reserved</label>
                      </div>
                      <div class="col form-group">
                        <input class="form-check-input" type="checkbox" name="check1" id="check1" formControlName="SkipToReading" [attr.disabled]="multiSelected ? '' : null">
                        <label class="form-check-label" for="check1">Skip Interpretation</label>
                      </div>
                      <div class="col form-group">
                        <input class="form-check-input" type="checkbox" name="check2" id="check2" formControlName="FlagNoData" [attr.disabled]="multiSelected ? '' : null">
                        <label class="form-check-label" for="check2">No Monitor Data</label>
                      </div>
                    </div>
                    <div class="form-group form-display row ml-4 mr-n4">
                      <div class="col form-group">
                        <input class="form-check-input" type="checkbox" name="check3" id="check3" formControlName="FlagPacemakerInquiry" [attr.disabled]="multiSelected ? '' : null">
                        <label class="form-check-label" for="check3">Pacemaker Inq.</label>
                      </div>
                      <div class="col form-group">
                        <input class="form-check-input" type="checkbox" name="check4" id="check4" formControlName="FlagNoReq" [attr.disabled]="multiSelected || currentHolter?.requisition_id ? '' : null">
                        <label class="form-check-label" for="check4">No Requisition</label>
                        <label class="req-resolved-indicator">{{(!!this.currentHolter && this.currentHolter.req_resolved_by) ? '&#40;' + 'Req Resolved' + '&#41;': '&nbsp;'}}</label>
                      </div>
                      <div class="col form-group">
                        <input class="form-check-input" type="checkbox" onclick="return false;" name="check5" id="check5" formControlName="FlagNoTracings" [attr.disabled]="true">
                        <label class="form-check-label" for="check5">No Report Tracings</label>
                      </div>

                    </div>
                  </form>
                </div>
              </div>

              <div class="col-5 px-0 ml-3 mr-n3">
                <div class="form-group form-display row">
                  <div class="col-2 form-group">
                    <label>VC</label>
                    <input type="text" class="form-control" placeholder="" formControlName="HealthCardVersion" [attr.disabled]="isClosed() ? '' : null">
                  </div>
                  <div class="col-2 form-group">
                    <label>Dur.<i [hidden]="!errorStrings.duration" class="conflict-message-inline">{{errorStrings.duration}}</i></label>
                    <input type="text" class="form-control" placeholder="" [class.has-conflict]="!!errorStrings.duration" formControlName="Duration" [attr.disabled]="isClosed() ? '' : null">
                  </div>
                  <div class="col-4 form-group">
                    <label>Birthdate</label>
                    <input type="text" class="form-control" placeholder="YYYY/MM/DD" formControlName="Birthdate" [attr.disabled]="isClosed() ? '' : null">
                  </div>
                  <div class="col-4 form-group">
                    <label>Gender <i [hidden]="!errorStrings.gender" class="conflict-message-inline">{{errorStrings.gender}}</i></label>
                    <select class="form-control" data-live-search="true" [class.has-conflict]="!!errorStrings.gender" formControlName="Gender" [attr.disabled]="isClosed() ? '' : null">
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </select>
                  </div>
                </div>
                <div class="form-group form-display row">
                  <div class="col form-group">
                    <label>DX Code</label>
                    <select class="form-control" data-live-search="true" (mouseleave)="verboseDxcode = false" (mouseenter)="verboseDxcode = true" formControlName="DxCode" [attr.disabled]="isClosed() ? '' : null">
                      <option *ngFor="let codeInfo of DXCODE_OPTIONS" [value]="codeInfo.code">{{verboseDxcode ? codeInfo.text : codeInfo.code}}</option>
                    </select>
                  </div>
                  <div class="col form-group">
                    <label>Delivery</label>
                    <input type="text" class="form-control" placeholder="" formControlName="Delivery" [attr.disabled]="true">
                  </div>
                  <div class="col form-group">
                    <label>Device SN</label>
                    <input type="text" class="form-control" [ngClass]="{'medtrace-field' : this.currentHolter?.referring_doctor_id != this.currentMedtrace?.doctor_id}" placeholder="" formControlName="Device" [readonly]="true" (contextmenu)="showMedtraceDisplay($event)">
                  </div>
                </div>
                <div class="form-group form-display row">
                  <div class="col form-group">
                    <label>City <i [hidden]="!errorStrings.city" class="conflict-message-inline">{{errorStrings.city}}</i></label>
                    <input type="text" class="form-control" placeholder="" [class.has-conflict]="!!errorStrings.city" formControlName="City" [attr.disabled]="multiSelected || isClosed() ? '' : null">
                  </div>
                  <div class="col form-group">
                    <label>Clinic Name</label>
                    <input type="text" class="form-control" placeholder="" formControlName="Clinic" [attr.disabled]="multiSelected || isClosed() ? '' : null">
                  </div>
                </div>
                <div class="form-group form-display row">
                  <div class="col form-group">
                    <label>Requisitions</label>
                    <input type="text" class="form-control" placeholder="{{attachedReqPlaceHolder}}" formControlName="Requisition" [attr.disabled]="isClosed() ? '' : null" [ngbTypeahead]="searchRequisition" [resultFormatter]="searchRequisitionFormatter" (selectItem)="selectedReq($event)" [inputFormatter]="searchRequisitionInputFormatter">
                  </div>
                </div>
                <div class="form-group form-display row">
                  <div class="col form-group">
                    <p class="billing-code-indicator">{{billingCode ? 'Billing Code: ' + billingCode : '&nbsp;'}}</p>
                  </div>
                </div>
              </div>

              <div class="col-2 px-0">
                <div class="form-group form-display my-n3 row">
                  <div class="col text-center">
                    <i data-toggle="tooltip" data-placement="left" title="See Requisition" class="fa download-req fa-file-o filter-icons" [class.filter-icons-off]="showPdfPreview != 'requisition'" (click)="toggleFileTypeFilter('req')"></i>
                    <p>Requisition</p>
                  </div>
                </div>
                <div class="form-group form-display my-n3 row">
                  <div class="col text-center">
                    <i data-toggle="tooltip" data-placement="left" title="See Tracings" class="fa download-tracings fa-file-pdf-o filter-icons" [class.filter-icons-off]="showPdfPreview != 'tracings'" (click)="toggleFileTypeFilter('pdf')"></i>
                    <p>Tracings</p>
                  </div>
                </div>
                <div class="form-group form-display my-n3 row">
                  <div class="col text-center">
                    <i data-toggle="tooltip" data-placement="left" title="See Faxes" class="fa download-faxes fa-fax filter-icons" [class.filter-icons-off]="showPdfPreview != 'fax'" (click)="toggleFileTypeFilter('fax')"></i>
                    <p>Faxes</p>
                  </div>
                </div>
                <div class="text-center ">
                  <button class="btn btn-primary btn-narrow" (click)="updateHolter()" type="button" [disabled]="!currentHolter || !currentHolter.patient_id || !formGroup.valid || (!formChanged && !checkBoxFormChanged) || !checkBoxFormGroup.valid">Update</button>
                </div>
              </div>
            </div>
          </form>

          <hr>

          <div class="row">
            <div class="col-12">

              <div class="row">
                <div class="col-2 ">
                  <label>B.C.</label>
                  <select class="form-control" data-live-search="true" [(ngModel)]="codeFilter" (change)="applySmartFilter()">
                    <option>All</option>
                    <option>ON</option>
                    <option>BC</option>
                    <option>WB</option>
                    <option>NBC</option>
                  </select>
                </div>
                <div class="col-2 ">
                  <label>Status</label>
                  <select class="form-control" data-live-search="true" [(ngModel)]="holterStatusFilter" (change)="applySmartFilter()">
                    <option value="">All</option>
                    <option value="PENDING">Pending</option>
                    <option value="EDITING">Editing</option>
                    <option value="RESERVED">Reserved</option>
                    <option value="READING">Reading</option>
                    <option value="FAXING">Faxing</option>
                    <option value="SUSPENDED">Suspended</option>
                  </select>
                </div>
                <!--
                <div class="col-3">
                  <label>Smart Filters</label>
                  <select class="form-control" data-live-search="true" [(ngModel)]="holterErrorIdx" (change)="applySmartFilter()">
                    <option [value]=""></option>
                    <option *ngFor="let holterError of holterErrors let i = index" [value]="i">{{holterError.name + ' (' + holterError.count + ')'}}</option>
                  </select>
                </div>
                -->

                <div class="col-8">
                  <label>Search</label>
                  <input type="text" class="form-control search-bar" placeholder="Start typing to search" id="search-bar">
                </div>
              </div>

              <div>
                <table datatable [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover" id="not-pending-table">
                  <thead>
                    <tr>
                      <th width="10px"></th>
                      <th>Status</th>
                      <th>Files</th>
                      <th>Scan Date</th>
                      <th>Name</th>
                      <th>Referring Dr</th>
                      <th>City</th>
                      <th>Billing Code</th>
                      <th>Faxes</th>
                    </tr>
                  </thead>
                </table>
              </div>

              <div class="row" *ngIf="downloadProgress">
                <div class="col text-center progress-text">
                  {{downloadProgress}}
                </div>
              </div>

            </div>
          </div>
        </as-split-area>
        <as-split-area [size]="pdfSizeRight">
          <form class="form-horizontal form-label-left" [formGroup]="formGroup">
            <div class="form-group form-display row ml-3">
              <div class="col form-group text-center">
                <button class="btn btn-primary btn-review" type="button" (click)="addStaffNotes()" [disabled]="!currentHolter || isClosed() || multiSelected">Staff Notes</button>
              </div>
              <div class="col form-group text-center">
                <button class="btn btn-primary btn-review" type="button" (click)="openCreateFaxModal()" [disabled]="!currentHolter || isClosed() || multiSelected">Fax Options</button>
              </div>
            </div>
            <div class="form-group form-display row ml-3">

              <div class="col form-group">
                <label>Staff Notes</label>
                <div class="row">
                  <div class="col">
                    <textarea rows="3" class="form-control" placeholder="" formControlName="StaffNotes" readonly></textarea>
                  </div>
                </div>
              </div>
              <div class="col form-group">
                <label>Requisition Notes</label>
                <div class="row">
                  <div class="col">
                    <textarea rows="3" class="form-control" placeholder="" formControlName="ReqNotes" readonly></textarea>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group form-display row ml-3" *ngIf="currentHolter?.faxes.length > 0 && showPdfPreview == 'fax'">
              <div class="col form-group">
                <select class="form-control" data-live-search="true" formControlName="Fax" (change)="faxSelectionChanged()">
                  <option *ngFor="let fax of currentHolter.faxes" [value]="fax.id" [ngClass]="(fax && currentFax && (fax.id == currentFax.id)) && 'current-entry'">{{fax?.status}} {{faxType(fax?.type)}} - {{fax?.fax_number}} {{fax?.doctor}}</option>
                </select>
              </div>
            </div>
            <div class="preview-panel">
              <div *ngIf="currentHolter && pdfSrc && !loadingPdf">
                <pdf-viewer [src]="pdfSrc" [autoresize]="true" [render-text]="false" [show-all]="showPdfPreview != 'tracings'" [original-size]="false" [fit-to-page]="true" style="display: block;"></pdf-viewer>
              </div>
            </div>
          </form>

        </as-split-area>

      </as-split>
    </div>
        </div>
      </div>
    </div>

    <div>
      <div class="row sub-footer">
        <div class="col-12">
          <!--
            <button class="btn btn-primary btn-review" (click)="updateHolter()" type="button" [disabled]="multiSelected || isClosed() || !currentHolter || !formGroup.valid || !formChanged"><i class="fa fa-floppy-o"></i> Update</button>
            <button class="btn btn-primary btn-review" type="button" (click)="markAsReqResolved()" [disabled]="!currentHolter || isClosed() || currentHolter.requisition_file_name || this.currentHolter.req_resolved_by">Req Resolved</button>
            <button class="btn btn-primary btn-review" type="button" (click)="faxInquiry()" [disabled]="true || !currentHolter || multiSelected"><i class="fa fa-fax"></i> Fax Inquiry</button>
          -->

          <button class="btn btn-primary btn-review" type="button" (click)="openCaseClosedModal()" [disabled]="!currentHolter || isClosed() || multiSelected">Case Closed</button>
          <button class="btn btn-primary btn-review" type="button" (click)="openFlagModal()" [disabled]="!currentHolter || currentHolter.closed_by">Flag As</button>
        </div>
      </div>
    </div>

  </div>
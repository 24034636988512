import { Directive, HostListener, ElementRef, Injectable } from '@angular/core';

// inserts str2 in str1 at index
function stringInsert(str1: string, str2: string, index: number) {
  if (index > str1.length) {
    return str1;
  }
  return str1.slice(0, index) + str2 + str1.slice(index);
}

@Injectable()
abstract class InputDirective {

  constructor(protected el: ElementRef) { }

  @HostListener('keypress', ['$event'])
  public onKeyPress(event) {
    return this.validateFields(event);
  }

  @HostListener('paste', ['$event'])
  public blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  protected abstract validateFields(event: any);
}

@Directive({
  selector: '[healthCardCodeInput]'
})
export class HealthCardCodeInputDirective extends InputDirective {

  constructor(el: ElementRef) { super(el); }
  protected validateFields(event: any) {
    setTimeout(() => {
      this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^A-Za-z]/g, '').toUpperCase().substr(0, 2);
      event.preventDefault();
    }, 100)
  }
}

@Directive({
  selector: '[healthCardInput]'
})
export class HealthCardInputDirective extends InputDirective {

  constructor(el: ElementRef) { super(el); }
  protected validateFields(event: any) {
    setTimeout(() => {
      this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^\d]/g, '').substr(0, 10);
      this.el.nativeElement.value = stringInsert(this.el.nativeElement.value, "-", 4);
      this.el.nativeElement.value = stringInsert(this.el.nativeElement.value, "-", 8);
      event.preventDefault();
    }, 100)
  }
}

@Directive({
  selector: '[dateInput]'
})
export class DateInputDirective extends InputDirective {

  constructor(el: ElementRef) { super(el); }
  protected validateFields(event: any) {
    setTimeout(() => {
      this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^\d]/g, '').substr(0, 8);
      this.el.nativeElement.value = stringInsert(this.el.nativeElement.value, "/", 4);
      this.el.nativeElement.value = stringInsert(this.el.nativeElement.value, "/", 7);
      event.preventDefault();
    }, 100)
  }
}

@Directive({
  selector: '[numberInput]'
})
export class NumberInputDirective extends InputDirective {

  constructor(el: ElementRef) { super(el); }
  protected validateFields(event: any) {
    setTimeout(() => {
      this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^\d]/g, '');
      event.preventDefault();
    }, 100)
  }
}

@Directive({
  selector: '[phoneInput]'
})
export class PhoneInputDirective extends InputDirective {

  constructor(el: ElementRef) { super(el); }
  protected validateFields(event: any) {
    setTimeout(() => {
      this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^\d]/g, '').substr(0, 11);
      // this.el.nativeElement.value = stringInsert(this.el.nativeElement.value, "+", 0);
      // this.el.nativeElement.value = stringInsert(this.el.nativeElement.value, "-", 2);
      // this.el.nativeElement.value = stringInsert(this.el.nativeElement.value, "-", 6);
      // this.el.nativeElement.value = stringInsert(this.el.nativeElement.value, "-", 10);
      event.preventDefault();
    }, 100)
  }
}

@Directive({
  selector: '[upperInput]'
})
export class UpperCaseDirective extends InputDirective {

  constructor(el: ElementRef) { super(el); }
  protected validateFields(event: any) {
    setTimeout(() => {
      this.el.nativeElement.value = this.el.nativeElement.value.toUpperCase();
      event.preventDefault();
    }, 100)
  }
}

<div>
    <div class="row sub-main">

      <div class="page-title">
        <div class="title_left">
          <h3>Logistics - Abnormal</h3>
        </div>
      </div>
      <div class="clearfix"></div>

      <div class="x_panel">
        <div class="x_content">

          <div class="row split-container">

            <as-split direction="horizontal">
              <as-split-area [size]="50">
                <div class="col">
                  <div class="row">
                    <div class="col-4">
                      <select class="form-control" data-live-search="true" [(ngModel)]="holterStatusFilter" (change)="statusFilterChanged('')">
                        <option value="Pending">Pending</option>
                        <option value="New">New</option>
                        <option value="Addressed">Addressed</option>
                      </select>
                    </div>
                    <div class="col-8">
                      <input type="text" class="form-control search-bar" placeholder="Start typing to search" id="search_bar">
                    </div>
                  </div>
                  <table datatable [dtOptions]="dtOptions" *ngIf="dtRendered" class="table table-striped row-bordered datatable-buttons hover" id="left-table">
                    <thead>
                      <tr>
                        <th>Scan Date Range</th>
                        <th>Clinic Name</th>
                        <th>Patient Name [healthcard]</th>
                        <th>Phone Number</th>
                        <th>Last Updated</th>
                      </tr>
                    </thead>
                  </table>
                  <div class="row ml-3">
                    <div class="col">
                        <input class="form-check-input" type="checkbox" name="check2" id="check2" [(ngModel)]="batchCalls" [attr.disabled]="this.holterStatusFilter == 'Addressed' ? '' : null" (change)="statusFilterChanged('batch')">
                        <label class="form-check-label" for="check2">Batch Calls</label>
                    </div>
                  </div>
                </div>
              </as-split-area>
              <as-split-area [size]="50">
                <div class="col info-panel" *ngIf="currentHolter">
                  <form class="form-horizontal form-label-left" [formGroup]="formGroup">
                    <div class="row">
                      <div class="col form-group">
                        <div class="row">
                          <div class="title-selection">{{titleSelection}}</div>
                        </div>
                        <div class="row">
                          <div class="row-selection">{{clinicSelection}}</div>
                        </div>
                        <div class="row">
                          <table datatable [dtOptions]="dtOptions2" class="table table-striped row-bordered datatable-buttons hover" id="right-table">
                            <thead>
                              <tr>
                                <th>HOOKUP DATE</th>
                                <th>NAME</th>
                                <th>HEALTHCARD</th>
                                <th>REFERRING DOCTOR</th>
                              </tr>
                            </thead>
                          </table>
                        </div>

                      </div>
                      <div class="col form-group">
                        <div class="row ml-3">
                          <div class="col">
                              <input class="form-check-input" type="checkbox" name="check1" id="check1" formControlName="Addressed">
                              <label class="form-check-label" for="check1">Addressed</label>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col form-group">
                            <input type="text" class="form-control" placeholder="{{staffNotePlaceHolder}}" formControlName="TextStaffNotes" [ngClass]="{'field-changed' : formGroup.get('TextStaffNotes').dirty}" [attr.disabled]="!focusHolter ? '' : null" [(ngModel)]="textStaffNotes">
                            <textarea #attemptLogDiv rows="14" class="form-control no-top-bot-margin" formControlName="StaffNotes" [attr.disabled]="true"></textarea>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col form-group">
                            <label>Fax Notes (for ref.)</label>
                            <textarea #attemptLogDiv rows="2" class="form-control no-top-bot-margin" formControlName="FaxNotes" [attr.disabled]="true"></textarea>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-auto form-group">
                            <button class="btn btn-primary btn-narrow" type="button" (click)="update()">Update</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="focusHolter">
                      <div class="col form-group vertical-narrow">
                        <select class="form-control" data-live-search="true" formControlName="Fax" (change)="faxSelectionChanged()" [disabled]="multiSelected">
                          <option *ngFor="let fax of focusHolter.faxes" [value]="fax.id" [ngClass]="(fax && currentFax && (fax.id == currentFax.id)) && 'current-entry'">{{fax?.status}} {{faxType(fax?.type)}} - {{fax?.fax_number}} {{fax?.doctor}}</option>
                        </select>
                        <br>
                      </div>
                    </div>
                  </form>
                  <div class="preview-panel">
                    <div *ngIf="(focusHolter || pdfSrc) && !multiSelected">
                      <pdf-viewer [src]="pdfSrc" [render-text]="false" [original-size]="false" [fit-to-page]="true" style="display: block;"></pdf-viewer>
                    </div>
                  </div>

                </div>
              </as-split-area>
            </as-split>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="row sub-footer">
        <div class="col-12">

        <!-- <button class="btn btn-primary btn-review" type="button" (click)="reverseAddressed()">Reverse</button> -->
        <!-- <button class="btn btn-primary btn-review" type="button" (click)="queryAbnormalHolter()">Query</button> -->
        </div>
      </div>
    </div>

  </div>

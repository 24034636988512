import { AfterViewInit, Component, OnInit, ViewChild, QueryList, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { ContextMenuComponent } from "ngx-contextmenu";

import { formatNotesLast } from "../../utils/format";

import { Clinic } from "../../models/clinic.model";
import { Physician } from "../../models/physician.model";

import { ClinicService } from "../../providers/clinic.service";
import { PhysicianService } from "../../providers/physician.service";

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalClinicDoctorComponent } from '../modal-clinic-doctor/modal-clinic-doctor.component';

@Component({
  selector: 'app-fax-clinic-doctor-directory',
  templateUrl: './fax-clinic-doctor-directory.component.html',
  styleUrls: ['./fax-clinic-doctor-directory.component.scss']
})
export class FaxClinicDoctorDirectoryComponent implements OnInit, AfterViewInit {

  @ViewChildren(DataTableDirective)
  dtElements: QueryList<any>;

  public dtOptions: any = {};
  public dtOptions2: any = {};
  public dtRendered = true;

  @ViewChild(ContextMenuComponent)

  public pdfSrc: any = null;
  public formGroup: FormGroup;
  public checkBoxFormGroup: FormGroup;

  public isNotActive = false;
  public isRepair = false;
  public isReconcile = false;
  public showAll = true;

  public radio = "doctor";
  public tableHeadersRight = ["Updated At", "Clinic Name", "Fax Number", "Phone Number", "Address", "Billing Code"];
  public tableHeadersLeft = ["", "Clinics", "Updated At", "Doctor's Name", "Roles", "Notes"];

  public titleSelection = "Doctor"
  public rowSelection = "Clinic"

  public clinic: Clinic;
  public doctor: Physician;

  public multiSelected: boolean = false;
  public expanded: boolean = false;
  public focusCount = 0;
  public reconcileCount: any = "0"

  // row callback
  public clinicRowCallback: any = (row: Node, data: any[] | Object, index: number) => {
    $(".fa-plus", row).bind("click", (event) => this.expandRowsHandler('clinic', event, data, true));
    $(".fa-minus", row).bind("click", (event) => this.expandRowsHandler('clinic', event, data, false));
    $("td", row).bind("click", () => this.clinicRowClickHandler(data));
    $("td", row).bind("contextmenu", (e) => {
      e.preventDefault();
      this.clinicRowClickHandler(data);
      setTimeout(() => {
        this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
          dtElement.dtInstance.then((dtInstance: any) => {
            if (dtInstance.table().node().id == 'right-table') {
              this.focusCount = dtInstance.table().page.info().recordsDisplay;
              this.radio == 'clinic' ? this.modify('left') : this.modify('right');
            }
          });
        });
      }, 500);
      return false;
    });
    return row;
  };

  public doctorRowCallback: any = (row: Node, data: any[] | Object, index: number) => {
    $(".fa-plus", row).bind("click", (event) => this.expandRowsHandler('doctor', event, data, true));
    $(".fa-minus", row).bind("click", (event) => this.expandRowsHandler('doctor', event, data, false));
    $("td", row).bind("click", () => this.doctorRowClickHandler(data));
    $("td", row).bind("contextmenu", (e) => {
      e.preventDefault();
      this.doctorRowClickHandler(data);
      setTimeout(() => {
        this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
          dtElement.dtInstance.then((dtInstance: any) => {
            if (dtInstance.table().node().id == 'right-table') {
              this.focusCount = dtInstance.table().page.info().recordsDisplay;
              this.radio == 'clinic' ? this.modify('right') : this.modify('left')
            }
          });
        });
      }, 500);
      return false;
    });
    return row;
  };

  public clinicDrawCallBack: any = (settings) => {
    this.dtElements?.forEach((dtElement: DataTableDirective, index: number) => {
      if ((index == 0 && this.radio == 'clinic') || (index == 1 && this.radio == 'doctor')) {
        dtElement.dtInstance.then((dtInstance: any) => {
          dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
            const row = dtInstance.row(rowIdx);
            if (this.clinic && row.data()["clinic:id"] == this.clinic.id) {
              (row as any).select();
            }
            const clinic_assignment_hidden = row.data()["clinic_assignment:is_hidden_at"];
            const clinic_assignment_billing_code = row.data()["clinic_assignment:billing_code"];
            const clinic_no_fax = row.data()["clinic:flag_no_fax"];
            const suspended = row.data()["clinic:is_hidden_at"];
            const repair = row.data()["clinic:fax"];
            if (suspended || clinic_assignment_hidden){
              $(row.node()).addClass('closed-entry');
            }
            else if (clinic_no_fax && (clinic_assignment_billing_code && this.radio == 'doctor')) {
              $(row.node()).addClass('faxed-entry');
            }
            else if (clinic_no_fax && this.radio == 'clinic') {
              $(row.node()).addClass('faxed-entry');
            }
            else if (repair == '' || !repair || (!clinic_assignment_billing_code && this.radio == 'doctor')){
              $(row.node()).addClass('need-repair-entry');
            }
          });
          if (this.isReconcile && index == 0){
            var api = dtInstance;
            var rows = api.rows( {page:'current'} ).nodes();
            var last=null;
            var storedIndexArray = [];
            api.column(3, {page:'current'} ).data().each( function ( group, i ) {
              var new_name = group.trim().toLowerCase()
              if ( last != new_name) {
                storedIndexArray.push(i);
                $(rows).eq( i ).before(
                  '<tr style="background-color:#dedede" class="group"><th colspan="9">' + "<span class='group-count'></span></td>" + " " + group  + "</tr>"
                );
                last = new_name;
              }
            });
            storedIndexArray.push(
              api.column(0, { page: "current" }).data().length
            );
            for (let i = 0; i < storedIndexArray.length - 1; i++) {
              let element = $(".group-count")[i];
              var count = storedIndexArray[i + 1] - storedIndexArray[i];
              if (count > 1) {
                $(element).text("(" + count + ")");
              }
            }
          }
        });
      }
    });
  };

  public doctorDrawCallBack: any = (settings) => {
    this.dtElements?.forEach((dtElement: DataTableDirective, index: number) => {
      if ((index == 0 && this.radio == 'doctor') || (index == 1 && this.radio == 'clinic')) {
        dtElement.dtInstance.then((dtInstance: any) => {
          dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
            const row = dtInstance.row(rowIdx);
            if (this.doctor && row.data()["doctor:id"] == this.doctor.id) {
              (row as any).select();
            }
            const clinic_assignment_hidden = row.data()["clinic_assignment:is_hidden_at"];
            const clinic_assignment_billing_code = row.data()["clinic_assignment:billing_code"];
            const doctor_hidden = row.data()["doctor:is_hidden_at"];
            const repair = row.data()["doctor:billing_number"];
            if (doctor_hidden || clinic_assignment_hidden){
              $(row.node()).addClass('closed-entry');
            }
            else if (repair == '' || !repair || (!clinic_assignment_billing_code && this.radio == 'clinic')){
              $(row.node()).addClass('need-repair-entry');
            }
          });
          if (this.isReconcile && index == 0){
            var api = dtInstance;
            var rows = api.rows( {page:'current'} ).nodes();
            var last=null;
            var storedIndexArray = [];
            api.column(3, {page:'current'} ).data().each( function ( group, i ) {
              if ( last != group) {
                storedIndexArray.push(i);
                $(rows).eq( i ).before(
                  '<tr style="background-color:#dedede" class="group"><th colspan="9">' + "<span class='group-count'></span></td>" + " " + group  + "</tr>"
                );
                last = group;
              }
            });
            storedIndexArray.push(
              api.column(0, { page: "current" }).data().length
            );
            for (let i = 0; i < storedIndexArray.length - 1; i++) {
              let element = $(".group-count")[i];
              var count = storedIndexArray[i + 1] - storedIndexArray[i];
              if (count > 1) {
                $(element).text("(" + count + ")");
              }
            }
          }
        });
      }
    });
  };

  public focusDoctorColumns = [
    { data: "doctor:updated_at" },
    { data: 'doctor:last_name',
      render: ( data, type, row, meta ) => {
        const last_name = row["doctor:last_name"] ? row["doctor:last_name"] : "";
        const first_name = row["doctor:first_name"] ? row["doctor:first_name"] : "";
        const billing_code = row["doctor:billing_number"] ? row["doctor:billing_number"] : "";
        let name = last_name + ' ' + first_name + "<Br>" + billing_code;
        return name;
      },
    },
    { data: "clinic_assignment:billing_code" },
    { data: 'doctor:roles' },
    { data: 'doctor:notes',
      render: ( data, type, row, meta ) => {
        const notes = row["doctor:notes"] ?  formatNotesLast(row["doctor:notes"]) : "";
        return notes;
      },
    },
    { data: 'doctor:id', visible: false  },
    { data: 'doctor:billing_number', visible: false  },
    { data: 'doctor:first_name', visible: false  },
    { data: "clinic_assignment:clinic_id", visible: false},
    { data: 'clinic_assignment:is_hidden_at', visible: false },
  ]

  public branchDoctorColumns = [
    {
      data: 'doctor:id',
      orderable: false,
      render: ( data, type, row, meta ) => {
        const duplicateCount = row["clinic:duplicate_count"];
        let expandHtml = ``;
        if (duplicateCount > 1)
        expandHtml = ` &nbsp;<a><i class="fa fa-plus expand-rows-icon"></i></a>`;
        if (duplicateCount < -1)
          expandHtml = ` &nbsp;<a><i class="fa fa-minus expand-rows-icon"></i></a>`;
        return expandHtml;
      },
    },
    { data: "doctor:focus_count",
      render: ( data, type, row, meta ) => {
        const active_count = row["doctor:focus_count"] ? parseInt(row["doctor:focus_count"]) : 0;
        return active_count;
      },
    },
    { data: "doctor:updated_at" },
    { data: 'doctor:last_name',
      render: ( data, type, row, meta ) => {
        const last_name = row["doctor:last_name"] ? row["doctor:last_name"] : "";
        const first_name = row["doctor:first_name"] ? row["doctor:first_name"] : "";
        const billing_code = row["doctor:billing_number"] ? row["doctor:billing_number"] : "";
        let name = last_name + ' ' + first_name + "<Br>" + billing_code;
        return name;
      },
    },
    { data: 'doctor:roles' },
    { data: 'doctor:notes',
      render: ( data, type, row, meta ) => {
        const notes = row["doctor:notes"] ?  formatNotesLast(row["doctor:notes"]) : "";
        return notes;
      },
    },
    { data: 'doctor:billing_number', visible: false },
    { data: 'doctor:first_name', visible: false  },
    { data: "doctor:is_hidden_at", visible: false },
  ]

  public branchDoctorReconcileColumns = [
    {
      data: 'doctor:id',
      orderable: false,
      render: ( data, type, row, meta ) => {
        if (!this.isReconcile){
          const duplicateCount = row["clinic:duplicate_count"];
          let expandHtml = ``;
          if (duplicateCount > 1)
          expandHtml = ` &nbsp;<a><i class="fa fa-plus expand-rows-icon"></i></a>`;
          if (duplicateCount < -1)
            expandHtml = ` &nbsp;<a><i class="fa fa-minus expand-rows-icon"></i></a>`;
          return expandHtml;
        }
        return null;
      },
    },
    { data: "doctor:focus_count",
      render: ( data, type, row, meta ) => {
        const active_count = row["doctor:focus_count"] ? parseInt(row["doctor:focus_count"]) : 0;
        return active_count;
      },
    },
    { data: "doctor:updated_at" },
    { data: 'doctor:billing_number',
      render: ( data, type, row, meta ) => {
        const last_name = row["doctor:last_name"] ? row["doctor:last_name"] : "";
        const first_name = row["doctor:first_name"] ? row["doctor:first_name"] : "";
        const billing_code = row["doctor:billing_number"] ? row["doctor:billing_number"] : "";
        let name = last_name + ' ' + first_name + "<Br>" + billing_code;
        return name;
      },
    },
    { data: 'doctor:roles' },
    { data: 'doctor:notes',
      render: ( data, type, row, meta ) => {
        const notes = row["doctor:notes"] ?  formatNotesLast(row["doctor:notes"]) : "";
        return notes;
      },
    },
    { data: 'doctor:last_name', visible: false },
    { data: 'doctor:first_name', visible: false  },
    { data: "doctor:is_hidden_at", visible: false },
  ]

  public branchClinicColumns = [
    {
      data: 'clinic:id',
      orderable: false,
      render: ( data, type, row, meta ) => {
        if (!this.isReconcile){
          const duplicateCount = row["clinic:duplicate_count"];
          let expandHtml = ``;
          if (duplicateCount > 1)
          expandHtml = ` &nbsp;<a><i class="fa fa-plus expand-rows-icon"></i></a>`;
          if (duplicateCount < -1)
            expandHtml = ` &nbsp;<a><i class="fa fa-minus expand-rows-icon"></i></a>`;
          return expandHtml;
        }
        return null;
      },
    },
    { data: "clinic:focus_count",
      render: ( data, type, row, meta ) => {
        const active_count = row["clinic:focus_count"] ? parseInt(row["clinic:focus_count"]) : 0;
        return active_count;
      },
    },
    { data: "clinic:updated_at" },
    { data: "clinic:name" },
    { data: "clinic:fax" },
    { data: "clinic:phone_number" },
    { data: "clinic:street",
      render: ( data, type, row, meta ) => {
        const street = row["clinic:street"] ? row["clinic:street"] : "";
        const city = row["clinic:city"] ? row["clinic:city"] : "";
        const province = row["clinic:province"] ? row["clinic:province"] : "";
        const postal = row["clinic:postal_code"] ? row["clinic:postal_code"] : "";
        let address = street + "<Br>" + city + ' ' + province + ' ' + postal;
        return address;
      },
    },
    { data: "clinic:city", visible: false },
    { data: "clinic:province", visible: false },
    { data: "clinic:postal_code", visible: false },
    { data: "clinic:is_hidden_at", visible: false },
    { data: "clinic:flag_no_fax", visible: false },
  ]

  public focusClinicColumns = [
    { data: "clinic:updated_at" },
    { data: "clinic:name" },
    { data: "clinic:fax" },
    { data: "clinic:phone_number" },
    { data: "clinic:street",
      render: ( data, type, row, meta ) => {
        const street = row["clinic:street"] ? row["clinic:street"] : "";
        const city = row["clinic:city"] ? row["clinic:city"] : "";
        const province = row["clinic:province"] ? row["clinic:province"] : "";
        const postal = row["clinic:postal_code"] ? row["clinic:postal_code"] : "";
        let address = street + "<Br>" + city + ' ' + province + ' ' + postal;
        return address;
      },
    },
    { data: "clinic_assignment:billing_code" },
    { data: "clinic:id", visible: false },
    { data: "clinic:city", visible: false },
    { data: "clinic:province", visible: false },
    { data: "clinic:postal_code", visible: false },
    { data: "clinic:is_hidden_at", visible: false },
    { data: "clinic_assignment:doctor_id", visible: false},
    { data: 'clinic_assignment:is_hidden_at', visible: false },
    { data: "clinic:flag_no_fax", visible: false },
  ]

  public getDoctorColumns() {
    if (this.radio == 'clinic') {
      return this.focusDoctorColumns;
    }
    else {
      if (this.isReconcile){
        return this.branchDoctorReconcileColumns;
      }
      else {
        return this.branchDoctorColumns;
      }
    }
  }

  public getClinicColumns() {
    if (this.radio == 'doctor') {
      return this.focusClinicColumns;
    }
    else {
      return this.branchClinicColumns;
    }
  }

  public clinicBuilder = {
    autoWidth: false,
    responsive: true,
    lengthChange: false,
    select: true,
    pageLength: 50,
    dom: "Blfrtip",
    buttons: [],
    rowCallback: this.clinicRowCallback,
    serverSide: true,
    processing: true,
    scrollY: window.innerHeight - 500,
    ajax: this.clinicService.getAjaxFuncion(),
    drawCallback: this.clinicDrawCallBack,
    order: [[ 1, "desc" ]],
    columnDefs: [{
      targets: 0,
      className: 'expand-row-column'
    },{
      targets: 1,
      className: 'download-file-column'
    }],
    columns: this.getClinicColumns(),
    language: {
      infoFiltered: ""
    }
  };

  public doctorBuilder = {
    autoWidth: false,
    responsive: true,
    lengthChange: false,
    select: true,
    pageLength: 50,
    dom: "Blfrtip",
    buttons: [],
    rowCallback: this.doctorRowCallback,
    serverSide: true,
    processing: true,
    scrollY: window.innerHeight - 500,
    ajax: this.physicianService.getAjaxFuncion() ,
    drawCallback: this.doctorDrawCallBack,
    order: [[ 3, "asc" ]],
    columnDefs: [{
      targets: 0,
      className: 'expand-row-column'
    },{
      targets: 1,
      className: 'download-file-column'
    }],
    columns: this.getDoctorColumns(),
    language: {
      infoFiltered: ""
    }
  };

  public ngAfterViewInit(): void {
    const that = this;
    document.addEventListener("click",  function(e){
      if (!e.target) {
        return;
      }
      const className = (e.target as Element).className;
      if(className == "as-split-gutter-icon"){
        window.dispatchEvent(new Event('resize'));
        that.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
          dtElement.dtInstance.then((dtInstance: any) => {
            dtInstance.draw();
          });
        });
      }
    });

    // set up the callbacks
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        if (dtInstance.table().node().id == 'left-table') {
          // on search term callback
          $("#search_bar").on("keyup change", function () {
            setTimeout(() => {
              const search_string = this["value"].replace(',', '')
              if (dtInstance.search() !== search_string) {
                dtInstance.search(search_string).draw();
              }
            }, 1000);
          });
        }
      });
    });

    // focus on the search bar right away after load
    $("#search_bar").focus();
  }

  public ngOnInit() {}

  constructor(
    private physicianService: PhysicianService,
    private clinicService: ClinicService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
  ) {

    this.physicianService.clearFilters();
    // this.physicianService.updateSrcFilter("directory_xlsx");
    // this.physicianService.updateHiddenDoctorFilter(false);
    this.physicianService.setCustomFilter("branch_fax_directory");
    this.clinicService.updateDoctorClinicsFilter('');

    // datatable option
    this.dtOptions = this.doctorBuilder;
    this.dtOptions2 = this.clinicBuilder;

    this.formGroup = new FormGroup({
      ActiveFilter: new FormControl("", []),
      RepairFilter: new FormControl("", []),
      InactiveFilter: new FormControl("", []),
      ReconcileFilter: new FormControl("", []),
      Search: new FormControl("", []),
    });

    this.physicianService.getReconcileCount().then(count => {
      this.reconcileCount = count;
    });
  }

  // NOT NEEDED YET
  private updateMultiSelected(that: any) {
    that.dtElements?.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        // Checks for multi selection on the right table only
        if (dtInstance.table().node().id == 'right-table') {
          const selectedData = dtInstance.rows(".selected").data();
          that.multiSelected = selectedData.length > 1;
        }
      });
    });
  }

  public expandRowsHandler(type: string, event: any, info: any, expand: boolean) {
    let clickedId;
    if (expand) {
      this.expanded = true;
      if (type == 'doctor') {
        clickedId = info["doctor:id"];
        this.dtElements?.forEach((dtElement: DataTableDirective, index: number) => {
          if ((index == 0 && this.radio == 'doctor') || (index == 1 && this.radio == 'clinic')) {
            dtElement.dtInstance.then((dtInstance: any) => {
              dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
                const row = dtInstance.rows().data()[rowIdx];
                if (row["doctor:id"] != clickedId) {
                  return;
                }
                this.physicianService.clearFilters();
                this.physicianService.setCustomFilter("dupe_doctors");
                this.physicianService.updateSrcFilter("directory_xlsx");
                this.clinicService.updateDoctorClinicsFilter('');
                this.physicianService.updateShowDuplicates(row["doctor:billing_number"]);
                dtInstance.table().page(0).draw(false);
              });
            });
          }
        });
      }
      else {
        clickedId = info["clinic:id"];
        this.dtElements?.forEach((dtElement: DataTableDirective, index: number) => {
          if ((index == 0 && this.radio == 'clinic') || (index == 1 && this.radio == 'doctor')) {
            dtElement.dtInstance.then((dtInstance: any) => {
              dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
                const row = dtInstance.rows().data()[rowIdx];
                if (row["clinic:id"] != clickedId) {
                  return;
                }
                this.clinicService.clearFilters();
                this.clinicService.setCustomFilter("dupe_clinics");
                this.physicianService.updateClinicDoctorsFilter('');
                this.clinicService.updateShowDuplicates(row["clinic:fax"]);
                dtInstance.table().page(0).draw(false);
              });
            });
          }
        });
      }
    }
    else
    {
      this.expanded = false;
      if (this.isNotActive) {
        this.checkboxChange('not_active');
      }
      else if (this.isRepair) {
        this.checkboxChange('repair');
      }
      else if (this.isReconcile) {
        this.checkboxChange('reconcile');
      }
      else {
        this.checkboxChange('');
      }
    }
  }

  public clinicRowClickHandler(info: any, force: boolean = false) {
    setTimeout(() => this.updateMultiSelected(this), 200);
    if (this.radio == "doctor"){
      this.physicianService.getDoctor(info["clinic_assignment:doctor_id"], info["clinic:id"]).then(clinic => {
        this.clinic = clinic.clinic;
        this.clinic["billing_code"] = clinic.billing_code;
        this.clinic["clinic_assignment_hidden_at"] = clinic.clinic_assignment_hidden_at;
        this.clinic["clinic_assignment_hidden_by"] = clinic.clinic_assignment_hidden_by;
        this.clinic["clinic_assignment_additional_fax_numbers"] = clinic.clinic_assignment_additional_fax_numbers;
        this.populateRow();
      });
    }
    if (this.radio == "clinic"){
      // reset text when selecting a new clinic
      this.rowSelection = "Doctor";
      this.clinicService.getClinic(info["clinic:id"]).then(clinic => {
        this.clinic = clinic;
        this.populateTitle();
        this.showAllEntries();
      });
    }
  }

  public doctorRowClickHandler(info: any, force: boolean = false) {
    setTimeout(() => this.updateMultiSelected(this), 200);
    this.physicianService.getDoctor(info["doctor:id"], info["clinic_assignment:clinic_id"]).then(doctor => {
      this.doctor = doctor;
      if (this.radio == "doctor"){
        // reset text when selecting a new doctor
        this.rowSelection = "Clinic";
        this.populateTitle();
        this.showAllEntries();
      }
      else {
        this.populateRow();
      }
    });
  }

  public populateTitle() {
    if (this.radio == "doctor"){
      this.titleSelection = this.doctor.name;
    }
    if (this.radio == "clinic"){
      this.titleSelection = this.clinic.name;
    }
  }

  public populateRow(){
    if (this.radio == "doctor"){
      this.rowSelection = this.clinic.name;
    }
    if (this.radio == "clinic"){
      this.rowSelection = this.doctor.name;
    }
  }

  public searchClinicForDoctors(clinic){
    this.physicianService.clearFilters();
    this.physicianService.updateSrcFilter("directory_xlsx");
    this.physicianService.updateClinicDoctorsFilter(clinic.id);
    this.reloadTable('right');
  }

  public searchDoctorForClinics(doctor){
    this.clinicService.clearFilters();
    this.clinicService.updateDoctorClinicsFilter(doctor.id);
    this.reloadTable('right');
  }

  public disable(side):boolean {
    if (side == 'left'){
      if (this.radio == "doctor" && !this.doctor) {
        return true;
      }
      else if (this.radio == "clinic" && !this.clinic) {
        return true;
      }
      else {
        return false;
      }
    }
    if (side == 'right'){
      if (this.radio == "doctor" && !this.clinic) {
        return true;
      }
      else if (this.radio == "clinic" && !this.doctor) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  public modify(side) {
    const modalRef = this.modalService.open(ModalClinicDoctorComponent, { size:'md'});
    if (this.radio == "doctor"){
      if (side == 'left'){
        modalRef.componentInstance.modifyType = 'doctors';
        modalRef.componentInstance.doctor = this.doctor;
        modalRef.componentInstance.focusCount = this.focusCount;
        modalRef.result.then(result => {
          if (result) {
            const data = {
              last_name: result.doctorLastName,
              first_name: result.doctorFirstName,
              name: result.doctorLastName + ", " + result.doctorFirstName,
              billing_number: result.doctorBillingNumber,
              roles: result.doctorRoles != "" ? result.doctorRoles : null,
              import_src: "directory_xlsx",
              notes: result.doctorNote != "" ? result.doctorNote : null,
            };
            if (result.archive){
              this.physicianService.addNotes(this.doctor.id, "Archiving " + result.doctorLastName + ", " + result.doctorFirstName).then(() => {
                this.physicianService.hideDoctor(this.doctor.id).then(doctor => {
                  this.clinicService.updateDoctorClinicsFilter('');
                  this.reloadTable("right");
                });
              });
            }
            else {
              this.physicianService.addNotes(this.doctor.id, "Modifying");
            }
            this.physicianService.updateDoctor(this.doctor.id, data).then(doctor => {
              this.doctor = doctor;
              this.populateTitle();
              this.reloadTable("left");
            });
          }
        });
      }
      if (side == 'right'){
        modalRef.componentInstance.modifyType = 'clinics';
        modalRef.componentInstance.clinic = this.clinic;
        modalRef.result.then(result => {
          if (result) {
            let doctor = {
              doctor_id: this.doctor.id,
              billing_code: result.clinicBillingCode ? result.clinicBillingCode : null,
              additional_fax_numbers: result.clinicAdditionalFax,
            }
            let data = {
              name: result.clinicName,
              fax: result.clinicFax != "" ? result.clinicFax : null,
              flag_no_fax: result.clinicNoFax,
              phone: result.clinicPhone,
              street: result.clinicStreet,
              city: result.clinicCity,
              province: result.clinicProvince,
              zone: result.clinicZone,
              postal_code: result.clinicPostal,
              clinic_id: this.clinic.id,
              notes: result.clinicNote != "" ? result.clinicNote : null,
              doctors: [doctor],
            };
            if (result.archive){
              const output = result.clinicName + " and " + this.doctor.last_name + ", " + this.doctor.first_name;
              if (this.clinic["clinic_assignment_hidden_at"]){
                data['is_hidden_at'] = 'restore';
                data['is_hidden_by'] = 'restore';
                const clinic_data = {
                  clinics: [data],
                  is_hidden_at: 'restore',
                  is_hidden_by: 'restore',
                };
                this.clinicService.addNotes(this.doctor.id, "Restoring " + output).then(() => {
                  this.physicianService.unhideDoctorClinic(this.doctor.id, clinic_data).then(clinic => {
                    this.reloadTable("left");
                  });
                });
              }
              else {
                const clinic_data = {
                  clinics: [data],
                };
                this.clinicService.addNotes(this.doctor.id, "Archiving " + output).then(() => {
                  this.physicianService.hideDoctorClinic(this.doctor.id, clinic_data);
                });
              }
            }
            this.clinicService.updateClinic(this.clinic.id, data).then(clinic => {
              this.clinic = clinic;
              this.populateRow();
              this.reloadTable("right");
            });
          }
        });
      }
    }
    if (this.radio == "clinic"){
      if (side == 'left'){
        modalRef.componentInstance.modifyType = 'clinics';
        modalRef.componentInstance.clinic = this.clinic;
        modalRef.componentInstance.focusCount = this.focusCount;
        modalRef.result.then(result => {
          if (result) {
            const data = {
              name: result.clinicName,
              fax: result.clinicFax != "" ? result.clinicFax : null,
              // additional_fax_numbers: result.clinicAdditionalFax,
              flag_no_fax: result.clinicNoFax,
              phone: result.clinicPhone,
              street: result.clinicStreet,
              city: result.clinicCity,
              province: result.clinicProvince,
              zone: result.clinicZone,
              postal_code: result.clinicPostal,
              notes: result.clinicNote != "" ? result.clinicNote : null,
            };
            if (result.archive){
              this.clinicService.addNotes(this.clinic.id, "Archiving " + result.clinicName).then(() => {
                this.clinicService.hideClinic(this.clinic.id, data).then(clinic => {
                  this.physicianService.updateClinicDoctorsFilter('');
                  this.reloadTable("right");
                });
              });
            }
            else {
              this.clinicService.addNotes(this.clinic.id, "Modified");;
            }
            this.clinicService.updateClinic(this.clinic.id, data).then(clinic => {
              this.clinic = clinic;
              this.populateTitle();
              this.reloadTable("left")
            });
          }
        });
      }
      if (side == 'right'){
        modalRef.componentInstance.modifyType = 'doctors';
        modalRef.componentInstance.doctor = this.doctor;
        modalRef.result.then(result => {
          if (result) {
            let clinic = {
              name: this.clinic.name,
              street: this.clinic.street,
              city: this.clinic.city,
              province: this.clinic.province,
              zone: this.clinic.zone,
              postal_code: this.clinic.postal_code,
              clinic_id: this.clinic.id,
            }
            const data = {
              last_name: result.doctorLastName,
              first_name: result.doctorFirstName,
              name: result.doctorLastName + ", " + result.doctorFirstName,
              billing_code: result.doctorBillingCode ? result.doctorBillingCode : null,
              billing_number: result.doctorBillingNumber,
              roles: result.doctorRoles != "" ? result.doctorRoles : null,
              import_src: "directory_xlsx",
              notes: result.doctorNote != "" ? result.doctorNote : null,
              additional_fax_numbers: result.clinicAdditionalFax,
              clinics:[clinic]
            };
            if (result.archive){
              const output = this.clinic.name + " and " + result.doctorLastName + ", " + result.doctorFirstName;
              if (this.doctor["clinic_assignment_hidden_at"]){
                clinic['is_hidden_at'] = 'restore';
                clinic['is_hidden_by'] = 'restore';
                const clinic_data = {
                  clinics: [clinic],
                };
                this.physicianService.addNotes(this.doctor.id, "Restoring " + output).then(() => {
                  this.physicianService.unhideDoctorClinic(this.doctor.id, clinic_data).then(doctor => {
                    this.reloadTable("left")
                  });
                });
              }
              else {
                const clinic_data = {
                  clinics: [clinic],
                };
                this.physicianService.addNotes(this.doctor.id, "Archiving " + output).then(() => {
                  this.physicianService.hideDoctorClinic(this.doctor.id, clinic_data)
                });
              }
            }
            else {
              this.physicianService.addNotes(this.doctor.id, "Modified")
            }
            this.physicianService.updateDoctor(this.doctor.id, data).then(doctor => {
              this.doctor = doctor;
              this.populateRow();
              this.reloadTable("right")
            });
          }
        });
      }
    }
  }

  public add(side) {
    const modalRef = this.modalService.open(ModalClinicDoctorComponent, { size:'md'});
    if (this.radio == "doctor"){
      if (side == 'left'){
        modalRef.componentInstance.modifyType = 'doctors';
        modalRef.componentInstance.create = true;
        modalRef.result.then(result => {
          if (result) {
            const data = {
              last_name: result.doctorLastName,
              first_name: result.doctorFirstName,
              name: result.doctorLastName + ", " + result.doctorFirstName,
              // billing_code: result.doctorBillingCode,
              billing_number: result.doctorBillingNumber ? result.doctorBillingNumber : null,
              roles: result.doctorRoles ? result.doctorRoles : null,
              import_src: "directory_xlsx",
              notes: result.doctorNote != "" ? result.doctorNote : null,
            };
            this.physicianService.insertDoctor(data).then(doctor => {
              this.doctor = doctor;
              this.reloadTable("left");
              this.clinicService.updateDoctorClinicsFilter('');
              this.populateTitle();
              this.reloadTable("right");
            });
          }
        });
      }
      if (side == 'right'){
        modalRef.componentInstance.modifyType = 'clinics';
        modalRef.componentInstance.doctor = this.doctor;
        modalRef.componentInstance.add = true;
        modalRef.result.then(result => {
          if (result) {
            const data = {
              clinic_id: result.clinicID,
              name: result.clinicName,
              fax: result.clinicFax != "" ? result.clinicFax : null,
              flag_no_fax: result.clinicNoFax,
              phone: result.clinicPhone,
              street: result.clinicStreet,
              city: result.clinicCity,
              province: result.clinicProvince,
              zone: result.clinicZone,
              postal_code: result.clinicPostal,
              notes: result.clinicNote != "" ? result.clinicNote : null,
              is_hidden_at: 'restore',
              is_hidden_by: 'restore',
              doctors: [{
                billing_code: result.clinicBillingCode ? result.clinicBillingCode : null,
                doctor_id: this.doctor.id,
                additional_fax_numbers: result.clinicAdditionalFax,
                is_hidden_at: 'restore',
                is_hidden_by: 'restore',
              }],

            };
            const output = result.clinicName + " and " + this.doctor.last_name + ", " + this.doctor.first_name;
            this.physicianService.addNotes(this.doctor.id, "Adding " + output).then(doctor => {
              this.doctor = doctor;
            });
            this.clinicService.insertClinic(data).then(clinic => {
              this.clinicService.addNotes(clinic.id, "Adding " + output).then(clinic => {
                this.clinic = clinic;
                this.reloadTable("left");
                this.reloadTable("right")
              });
            });
          }
        });
      }
    }
    if (this.radio == "clinic"){
      if (side == 'left'){
        modalRef.componentInstance.modifyType = 'clinics';
        modalRef.componentInstance.create = true;
        modalRef.result.then(result => {
          if (result) {
            const data = {
              name: result.clinicName,
              fax: result.clinicFax != "" ? result.clinicFax : null,
              // additional_fax_numbers: result.clinicAdditionalFax,
              flag_no_fax: result.clinicNoFax,
              phone: result.clinicPhone,
              street: result.clinicStreet,
              city: result.clinicCity,
              province: result.clinicProvince,
              zone: result.clinicZone,
              postal_code: result.clinicPostal,
              notes: result.clinicNote != "" ? result.clinicNote : null,
            };
            this.clinicService.insertClinic(data).then(clinic => {
              this.clinic = clinic;
              this.reloadTable("left");
              // Updating the right side to display the right title and list
              this.physicianService.updateClinicDoctorsFilter('');
              this.populateTitle();
              this.reloadTable("right");
            });
          }
        });
      }
      if (side == 'right'){
        modalRef.componentInstance.modifyType = 'doctors';
        modalRef.componentInstance.clinic = this.clinic;
        modalRef.componentInstance.add = true;
        modalRef.result.then(result => {
          if (result) {
            const data = {
              last_name: result.doctorLastName,
              first_name: result.doctorFirstName,
              name: result.doctorLastName + ", " + result.doctorFirstName,
              billing_code: result.doctorBillingCode ? result.doctorBillingCode : null,
              billing_number: result.doctorBillingNumber,
              roles: result.doctorRoles ? result.doctorRoles : null,
              is_hidden_at: 'restore',
              is_hidden_by: 'restore',
              notes: result.doctorNote != "" ? result.doctorNote : null,
              import_src: "directory_xlsx",
              additional_fax_numbers: result.clinicAdditionalFax,
              clinics: [{
                clinic_id: this.clinic.id,
                name: this.clinic.name,
                street: this.clinic.street,
                city: this.clinic.city,
                province: this.clinic.province,
                zone: this.clinic.zone,
                postal_code: this.clinic.postal_code,
                is_hidden_at: 'restore',
                is_hidden_by: 'restore',
              }],
            };
            const output = this.clinic.name + " and " + result.doctorLastName + ", " + result.doctorFirstName;
            this.clinicService.addNotes(this.clinic.id, "Adding " + output).then(clinic => {
              this.clinic = clinic;
            });
            this.physicianService.insertDoctor(data).then(doctor => {
              this.physicianService.addNotes(doctor.id, "Adding " + output).then(() => {
                this.doctor = doctor;
                this.reloadTable("left");
                this.reloadTable("right");
              });
            });
          }
        });
      }
    }
  }

  public showAllEntries(){
    if (this.radio == "doctor"){
      this.clinicService.clearFilters();
      this.clinicService.updateDoctorClinicsFilter(this.doctor.id.toString());
      if (!this.showAll) {
        this.clinicService.updateHiddenDoctorFilter(false);
        this.clinicService.updateBillingCodeFilter(true);
        this.clinicService.updateRepairFilter(true);
      }
    }
    else {
      this.physicianService.clearFilters();
      this.physicianService.updateClinicDoctorsFilter(this.clinic.id.toString());
      if (!this.showAll) {
        this.physicianService.updateBillingCodeFilter(true);
        this.physicianService.updateHiddenClinicFilter(false);
        this.physicianService.updateBillingNumberFilter(true);
      }
    }
    this.reloadTable('right');
  }

  public checkboxChange(box){
    this.clinic = null;
    this.doctor = null;
    if (box == 'repair'){
      this.isNotActive = false;
      this.isReconcile = false;
    }
    else if (box == 'not_active'){
      this.isRepair = false;
      this.isReconcile = false;
    }
    else if (box == 'reconcile'){
      this.isNotActive = false;
      this.isRepair = false;
    }
    else {
      this.isNotActive = false;
      this.isRepair = false;
      this.isReconcile = false;
    }

    if (this.radio == "doctor"){
      this.physicianService.clearFilters();
      this.physicianService.updateSrcFilter("directory_xlsx");
      this.isNotActive ? this.physicianService.setCustomFilter("inactive") : null;
      this.isRepair ? this.physicianService.setCustomFilter("repair") : null;
      this.isReconcile ? this.physicianService.setCustomFilter("reconcile") : null;
      !this.isNotActive && !this.isRepair && !this.isReconcile ? this.physicianService.setCustomFilter("branch_fax_directory") : null;
      this.clinicService.updateDoctorClinicsFilter('');
    }
    if (this.radio == "clinic"){
      this.clinicService.clearFilters();
      this.isNotActive ? this.clinicService.setCustomFilter("inactive") : null;
      this.isRepair ? this.clinicService.setCustomFilter("repair") : null;
      this.isReconcile ? this.clinicService.setCustomFilter("reconcile") : null;
      !this.isNotActive && !this.isRepair && !this.isReconcile ? this.clinicService.setCustomFilter("branch_fax_directory") : null;
      this.physicianService.updateClinicDoctorsFilter('');
    }
    this.refreshTable();
  }

  refreshTable() {
    if (this.radio == "clinic")
    {
      // Set up table changes
      this.titleSelection = "Clinic";
      this.rowSelection = "Doctor";
      this.tableHeadersLeft = ["", "Doctors", "Updated At", "Clinic Name", "Fax Number", "Phone Number", "Address"];
      this.tableHeadersRight = ["Updated At", "Doctor's Name", "Billing Code", "Roles", "Notes"];
      this.dtRendered = false
      // Build the proper columns
      this.clinicBuilder.columns = this.getClinicColumns()
      this.doctorBuilder.columns = this.getDoctorColumns()
      this.clinicBuilder.order = [[ 3, "asc" ]];
      this.doctorBuilder.order = [[ 1, "desc" ]];
      // Recreating the dt options
      this.dtOptions = this.clinicBuilder;
      this.dtOptions2 = this.doctorBuilder;
      // Removing and creating new table
      this.cdr.detectChanges();
      this.dtRendered = true
      this.cdr.detectChanges();
    }
    else if (this.radio == "doctor")
    {
      this.titleSelection = "Doctor";
      this.rowSelection = "Clinic";
      this.tableHeadersRight = ["Updated At", "Clinic Name", "Fax Number", "Phone Number", "Address", "Billing Code"];
      this.tableHeadersLeft = ["", "Clinics", "Updated At", "Doctor's Name", "Roles", "Notes"];
      this.clinicBuilder.order = [[ 1, "desc" ]];
      this.doctorBuilder.order = [[ 3, "asc" ]];
      this.dtRendered = false
      this.clinicBuilder.columns = this.getClinicColumns()
      this.doctorBuilder.columns = this.getDoctorColumns()
      this.dtOptions = this.doctorBuilder;
      this.dtOptions2 = this.clinicBuilder;
      this.cdr.detectChanges();
      this.dtRendered = true
      this.cdr.detectChanges();
    }

    this.formGroup.patchValue({
      Search: '',
    });

    // set up the search callbacks
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        if (dtInstance.table().node().id == 'left-table') {
          // on search term callback
          $("#search_bar").on("keyup change", function () {
            if (dtInstance.search() !== this["value"]) {
              dtInstance.search(this["value"]).draw();
            }
          });
        }
      });
    });
  }

  private changeReconcileCount(){
    if (this.radio == "doctor"){
      this.physicianService.getReconcileCount().then(count => {
        this.reconcileCount = count;
      });
    }
    else {
      this.clinicService.getReconcileCount().then(count => {
        this.reconcileCount = count;
      });
    }
  }

  changeTable($event) {
    this.isRepair = false;
    this.isNotActive = false;
    this.isReconcile = false;
    this.changeReconcileCount();
    this.checkboxChange('');
    this.refreshTable();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    // this.dtTrigger.unsubscribe();
  }

  private reloadTable(table) {
    this.dtElements?.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        if (dtInstance.table().node().id == 'left-table' && table == 'left') {
          dtInstance.table().draw(false);
        }
        if (dtInstance.table().node().id == 'right-table' && table == 'right') {
          dtInstance.table().draw(false);
        }
      });
    });
  }

}
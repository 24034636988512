import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn,  } from "@angular/forms";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Medtrace } from "src/app/models/medtrace.model";
import { MedtraceService } from "src/app/providers/medtrace.service";

export function SerialPatternValidator(
    control: AbstractControl
): ValidationErrors {
    const valid =
        /([0-9]){2,}/.test(control.value);

    return valid ? null: {serialpattern: true};
}

export function CustomSerialPatternValidator(serial: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
        return null
    };
}

@Injectable({ providedIn:'root'})
export class ExistingSerialValidator {
    constructor(private medtraceService: MedtraceService) {}
    validate(
        ctrl: AbstractControl
    ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        return this.medtraceService.getLatestMedtraceBySerialNumber(ctrl.value).then(medtrace => {
            return medtrace ? null : { existserial : true};
        });


        // let new_medtrace: Medtrace;
        // this.medtraceService.getLatestMedtraceBySerialNumber(ctrl.value).then(medtrace => {
        //     new_medtrace = medtrace;
        // });
        // return new_medtrace ? null : { existserial : true};
    };
}
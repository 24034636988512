<div>
    <div class="row sub-main">

      <div class="page-title">
        <div class="title_left">
          <h3>Fax - Holter</h3>
        </div>
      </div>
      <div class="clearfix"></div>

      <div class="x_panel">
        <div class="x_content">

          <div class="row split-container">

            <as-split direction="horizontal">
              <as-split-area [size]="40">
                <div class="col">
                  <div class="row">
                    <div class="col-6">
                      <select class="form-control" data-live-search="true" [(ngModel)]="holterStatusFilter" (change)="statusFilterChanged()">
                        <option value="">All</option>
                        <option value="PENDING">Pending</option>
                        <option value="EDITING">Editing</option>
                        <option value="RESERVED">Reserved</option>
                        <option value="READING">Reading</option>
                        <option value="Faxing">Faxing</option>
                        <option value="Faxed">Faxed</option>
                        <option value="SUSPENDED">Suspended</option>
                        <option value="No Fax">No Fax</option>
                        <option value="Need Repair">Need Repair</option>
                        <option value="CANCELLED">Cancelled</option>
                      </select>
                    </div>
                    <div class="col-6">
                      <input type="text" class="form-control no-top-bot-margin" placeholder="Start typing to search" id="search-bar">
                    </div>
                  </div>
                  <table datatable [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover" id="not-pending-table">
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Files</th>
                        <th>Name</th>
                        <th>Scan Date</th>
                        <!--<th>Reading</th>-->
                        <th>Doctor Signed</th>
                        <th>Referring</th>
                        <th>Fax Number</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </as-split-area>
              <as-split-area [size]="60">
                <div class="col info-panel" *ngIf="currentHolter">

                  <form class="form-horizontal form-label-left" [formGroup]="formGroup">
                    <div class="row">
                      <div class="col-9 form-group vertical-narrow">
                        <select class="form-control" data-live-search="true" formControlName="Fax" (change)="faxSelectionChanged()">
                          <option *ngFor="let fax of currentHolter.faxes" [value]="fax.id" [ngClass]="(fax && currentFax && (fax.id == currentFax.id)) && 'current-entry'">{{fax?.status}} {{faxType(fax?.type)}} - {{fax?.fax_number}} {{fax?.doctor}}</option>
                          <option value="" [ngClass]="!currentFax && 'current-entry'">New Fax</option>
                        </select>
                        <br>
                      </div>
                      <div class="col-3 form-group text-center">
                        <button class="btn btn-primary btn-review " (click)="verifyFax()" type="button" [disabled]="multiSelected || !currentHolter || (!formGroup.valid && faxNumberString.length == 0) || uneditableFax(currentFax) || currentHolter.skip_faxing_at">Create Fax</button>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-9">
                        <div class="row">
                          <div class="col form-group">
                            <label>Files to Fax</label>
                            <p class="text-center">
                              <i data-toggle="tooltip" data-placement="left" title="Tracings" class="fa fa-file-pdf-o filter-icons" [class.filter-icons-off]="!(toBatch?.tracings) || currentHolter.skip_faxing_at" (click)="toggleToBatch('tracings')"></i>
                              <i data-toggle="tooltip" data-placement="left" title="Requisition" class="fa fa-file-o filter-icons" [class.filter-icons-off]="!(toBatch?.req) || currentHolter.skip_faxing_at" (click)="toggleToBatch('req')"></i>
                              <i data-toggle="tooltip" data-placement="left" title="Preliminary" class="fa fa-file-text-o filter-icons" [class.filter-icons-off]="!(toBatch?.preliminary) || currentHolter.skip_faxing_at" (click)="toggleToBatch('preliminary')"></i>
                              <i data-toggle="tooltip" data-placement="left" title="Final Report" class="fa fa-file-pdf-o filter-icons" [class.filter-icons-off]="!(toBatch?.final) || currentHolter.skip_faxing_at" (click)="toggleToBatch('final')"></i>
                              <i data-toggle="tooltip" data-placement="left" title="Fax Notes" class="fa fa-fax filter-icons" [class.filter-icons-off]="!(toBatch?.notes) || currentHolter.skip_faxing_at" (click)="toggleToBatch('notes')"></i>
                            </p>
                          </div>
                          <div class="col form-group">
                            <label>Referring Doctor</label>
                            <input type="text" class="form-control" placeholder="" formControlName="Doctor" [ngbTypeahead]="searchDoctor" [resultFormatter]="searchDoctorFormatter" [inputFormatter]="searchDoctorInputFormatter" [attr.disabled]="uneditableFax(currentFax) ? '' : null || currentHolter.skip_faxing_at">
                            <p *ngIf="invalidReferring" class="error-message">Invalid Referring Doctor</p>
                          </div>
                          <div class="col form-group">
                            <label>Fax Number</label>
                            <input type="text" class="form-control" placeholder="{{clinicNoFax}}" formControlName="FaxNumber" [attr.disabled]="uneditableFax(currentFax) ? '' : null || currentHolter.skip_faxing_at">
                          </div>
                          <div class="col form-group">
                              <label>Scheduled For</label>
                              <input type="text" class="form-control" placeholder="" formControlName="ScheduledFor" [attr.disabled]="true">
                          </div>
                        </div>
                        <div class="row">
                          <div class="col form-group">
                            <div class="row">
                              <div class="col-7 col-narrow">
                                <div class="row">
                                  <div class="col checbox-input">
                                    <input class="form-check-input" type="checkbox" name="check0" id="check0" formControlName="FaxNotesReq" [attr.disabled]="!(toBatch?.notes) ? '' : null">
                                    <label class="form-check-label" for="check0">Patient Req Form</label>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col checbox-input">
                                    <input class="form-check-input" type="checkbox" name="check1" id="check1" formControlName="FaxNotesData" [attr.disabled]="!(toBatch?.notes) ? '' : null">
                                    <label class="form-check-label" for="check1">Patient Data</label>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col checbox-input">
                                    <input class="form-check-input" type="checkbox" name="check2" id="check2" formControlName="FaxNotesPacemaker" [attr.disabled]="!(toBatch?.notes) ? '' : null">
                                    <label class="form-check-label" for="check2">Pacemaker</label>
                                  </div>
                                </div>
                              </div>
                              <div class="col-5 col-narrow form-group">
                                <button class="btn btn-primary btn-narrow" (click)="previewFaxingBatch()" type="button" [disabled]="uneditableFax(currentFax) || currentHolter.skip_faxing_at">Preview</button>
                              </div>
                            </div>
                          </div>
                          <div class="col form-group">
                            <label>Fax Notes (for ref.)</label>
                            <textarea #attemptLogDiv rows="1" class="form-control no-top-bot-margin" formControlName="FaxNotes" [contextMenu]="suggestedMenu" [contextMenuSubject]="'FaxNotes'" [attr.disabled]="true"></textarea>
                          </div>
                          <div class="col form-group">
                            <label>Staff Notes (for ref.)</label>
                            <textarea #attemptLogDiv rows="1" class="form-control no-top-bot-margin" formControlName="StaffNotes" [contextMenu]="suggestedMenu" [contextMenuSubject]="'StaffNotes'" [attr.disabled]="true"></textarea>
                          </div>
                          <div class="col form-group">
                            <label>Attempt Log</label>
                            <textarea #attemptLogDiv rows="1" class="form-control no-top-bot-margin" formControlName="AttemptLog" [attr.disabled]="true"></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="row">
                          <div class="col-md-auto form-group">
                            <div class="row">
                              <button class="btn btn-primary btn-review btn-add-notes" type="button" (click)="addFaxNumber()" [disabled]="currentFax || !textClinicAdditionalFax || typeOf(textClinicAdditionalFax)">+</button>
                            </div>
                            <!-- <div class="row">
                              <button class="btn btn-primary btn-review btn-add-notes" type="button" (click)="removeFaxNumber()" [disabled]="true">-</button>
                            </div> -->
                          </div>
                          <div class="col form-group">
                              <label>Also send to:</label>
                              <input type="text" class="form-control" placeholder="Add Doctor or Fax Number" formControlName="AdditionalFaxNumbers" [attr.disabled]="currentFax ? '' : null" [(ngModel)]="textClinicAdditionalFax" [ngbTypeahead]="searchDoctor" [resultFormatter]="searchDoctorFormatter"[inputFormatter]="searchFaxDoctorInputFormatter">
                              <div class="fax-container">
                                  <ul>
                                      <li class="overflow-auto" *ngFor="let faxNumber of faxNumberAuto" [class.item-selected]="(faxNumberString.indexOf(faxNumber)!=-1)" [ngClass]="{'clinic-fax': clinicFaxNumbers?.indexOf(faxNumber) > -1, 'disabled': currentFax}" (click)="selectedFaxNumber(faxNumber)">{{faxNumber}}</li>
                                  </ul>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </form>

                  <div class="preview-panel">
                    <div *ngIf="currentFax || pdfSrc">
                      <pdf-viewer [src]="pdfSrc" [render-text]="false" [original-size]="false" [fit-to-page]="true" style="display: block;"></pdf-viewer>
                    </div>
                  </div>

                </div>
              </as-split-area>
            </as-split>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="row sub-footer">
        <div class="col-12">
          <button class="btn btn-primary btn-review" type="button" (click)="addStaffNotes()" [disabled]="!currentHolter">Add Staff Note</button>
          <button class="btn btn-primary btn-review" type="button" (click)="downloadFaxDocs()" [disabled]="!currentFax || !canFax()">Download</button>
          <button class="btn btn-primary btn-review" type="button" (click)="faxImmediately()" [disabled]="!currentHolter || currentHolter.skip_faxing_at"><i class="fa fa-fax"></i> Fax Immediately</button>
          <button class="btn btn-primary btn-review" type="button" (click)="retryFax()" [disabled]="!currentFax || !canFax() || currentFax.status !== 'Cancelled'"><i class="fa fa-repeat"></i> Retry</button>
          <button class="btn btn-primary btn-review" type="button" (click)="skipFaxing()" [disabled]="!currentHolter || currentHolter.skip_faxing_at"><i class="fa fa-window-close"></i> Skip Faxing</button>
          <button class="btn btn-primary btn-review" type="button" (click)="cancelFax()" [disabled]="!currentFax || !currentFax.attempt_at || currentFax.status === 'Cancelled'"><i class="fa fa-times"></i> Cancel</button>
        </div>
      </div>
    </div>

    <!-- this is for contextMenu -->
    <context-menu>
      <ng-template *ngFor="let phrase of faxNotes" contextMenuItem (execute)="contextMenuClick($event, phrase)">{{phrase}}</ng-template>
    </context-menu>

  </div>
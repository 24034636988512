<div class="md-width">

  <div class="row sub-header">
    <div class="col-12">
      <div class="text-center">
        <br>
        <ul class="list-inline">
          <li *ngIf="showHolterTab$ | async as showHolterTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/debug/holter')"
            [class.table-filter-selected]="urlContains('holter')"
            [hidden]="!showHolterTab">
              <div class="col"><p class="text-center">Holter</p></div>
          </li>
          <li *ngIf="showAbpmTab$ | async as showAbpmTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/debug/abpm')"
            [class.table-filter-selected]="urlContains('abpm')"
            [hidden]="!showAbpmTab">
              <div class="col"><p class="text-center">Abpm</p></div>
          </li>
          <!-- <li *ngIf="showSparkTab$ | async as showSparkTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/debug/spark')"
            [class.table-filter-selected]="urlContains('spark')"
            [hidden]="!showSparkTab">
              <div class="col"><p class="text-center">Spark</p></div>
          </li> -->
          <li *ngIf="showSparkMonitorTab$ | async as showSparkMonitorTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/debug/spark-monitor')"
            [class.table-filter-selected]="urlContains('spark-monitor')"
            [hidden]="!showSparkMonitorTab">
              <div class="col"><p class="text-center">Spark Monitor</p></div>
          </li>
          <li *ngIf="showReqTab$ | async as showReqTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/debug/req')"
            [class.table-filter-selected]="urlContains('req')"
            [hidden]="!showReqTab">
              <div class="col"><p class="text-center">Requisition</p></div>
          </li>
          <li *ngIf="showDirectoryTab$ | async as showDirectoryTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/debug/directory')"
            [class.table-filter-selected]="urlContains('directory')"
            [hidden]="!showDirectoryTab">
              <div class="col"><p class="text-center">Directory</p></div>
          </li>
          <li *ngIf="showResearchTab$ | async as showResearchTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/debug/research')"
            [class.table-filter-selected]="urlContains('research')"
            [hidden]="!showResearchTab">
              <div class="col"><p class="text-center">Research</p></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
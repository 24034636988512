<div>
    <div class="row sub-main">

      <div class="page-title">
        <div class="title_left">
          <h3>Debug - Spark</h3>
        </div>
      </div>
      <div class="clearfix"></div>

      <div class="x_panel">
        <div class="x_content">

          <div class="row split-container">

            <as-split direction="horizontal">
              <as-split-area [size]="40">
                <div class="col">
                  <div class="row">
                    <div class="col-6">
                      <select class="form-control" data-live-search="true" [(ngModel)]="holterStatusFilter" (change)="statusFilterChanged()">
                        <option value="">All</option>
                        <option value="PENDING">Pending</option>
                        <option value="EDITING">Editing</option>
                        <option value="RESERVED">Reserved</option>
                        <option value="READING">Reading</option>
                        <option value="Faxing">Faxing</option>
                        <option value="Faxed">Faxed</option>
                        <option value="SUSPENDED">Suspended</option>
                        <option value="No Fax">No Fax</option>
                        <option value="Need Repair">Need Repair</option>
                        <option value="SKIPFAXING">Skip Faxing</option>
                        <option value="CANCELLED">Cancelled</option>
                      </select>
                    </div>
                    <div class="col-6">
                      <input type="text" class="form-control no-top-bot-margin" placeholder="Start typing to search" id="search-bar">
                    </div>
                  </div>
                  <table datatable [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover" id="not-pending-table">
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Scan Date</th>
                        <th>Name</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </as-split-area>
              <as-split-area [size]="60">


                  <form class="form-horizontal form-label-left" [formGroup]="formGroup">
                    <div class="row">
                        <div class="col form-group">
                            <label>Synced At</label>
                            <input type="text" class="form-control" placeholder="" formControlName="SyncedAt" [attr.disabled]="true">
                        </div>
                        <div class="col form-group">
                            <label>Requested At</label>
                            <input type="text" class="form-control" placeholder="" formControlName="RequestedAt" [attr.disabled]="true">
                        </div>
                        <div class="col form-group">
                            <label>Uploaded Files</label>
                            <input type="text" class="form-control" placeholder="" formControlName="UploadedFiles" [attr.disabled]="true">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <label>Computer</label>
                            <input type="text" class="form-control" placeholder="" formControlName="Computer" [attr.disabled]="true">
                        </div>
                        <div class="col form-group">
                            <label>Computer Slot</label>
                            <input type="text" class="form-control" placeholder="" formControlName="ComputerSlot" [attr.disabled]="true">
                        </div>
                        <div class="col form-group">
                            <label>Updated At</label>
                            <input type="text" class="form-control" placeholder="" formControlName="UpdatedAt" [attr.disabled]="true">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <label>Key</label>
                            <input type="text" class="form-control" placeholder="" formControlName="Key" [attr.disabled]="true">
                        </div>
                    </div>
                     <div class="row">
                        <div class="col form-group">
                            <label>Need Flatten Tracing</label>
                            <input type="text" class="form-control" placeholder="" formControlName="FlattenTracing" [attr.disabled]="true">
                        </div>
                        <div class="col form-group">
                            <label>Need Flatten Batch</label>
                            <input type="text" class="form-control" placeholder="" formControlName="FlattenBatch" [attr.disabled]="true">
                        </div>
                        <div class="col form-group">
                            <label>Need Reannotate At</label>
                            <input type="text" class="form-control" placeholder="" formControlName="FlattenBatch" [attr.disabled]="true">
                        </div>
                    </div>
                    <div class="row">
                      <div class="col form-group">
                        <label>Staff Notes (for ref.)</label>
                        <textarea #attemptLogDiv rows="1" class="form-control no-top-bot-margin" formControlName="StaffNotes" [contextMenu]="suggestedMenu" [contextMenuSubject]="'StaffNotes'" [attr.disabled]="true"></textarea>
                      </div>
                    </div>
                  </form>


              </as-split-area>
            </as-split>
        </div>
      </div>
    </div>

    <div>
      <div class="row sub-footer">
         <div class="col-12">
          <button class="btn btn-primary btn-review" type="button" (click)="runScan()">Scan</button>
          <button class="btn btn-primary btn-review" type="button" (click)="syncHolter()" [disabled]="!currentHolter || currentHolter.closed_by">Sync</button>
          <button class="btn btn-primary btn-review" type="button" (click)="bucketCheckHolter()" [disabled]="!currentHolter || currentHolter.closed_by">Check S3 Bucket</button>
          <button class="btn btn-primary btn-review" type="button" (click)="updateFileHolter()" [disabled]="!currentHolter || currentHolter.closed_by || this.currentHolter.state == 'FAXING'">Update Files</button>
          <button class="btn btn-primary btn-review" type="button" (click)="queryLastBucketCheckHolters()">Query</button>
         </div>
      </div>
    </div>
</div>

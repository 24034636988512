import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { version } from '../../../package.json';

import { environment } from "../../environments/environment";
import { AuthService } from "../providers/auth.service";
import { Requisition } from "../models/requisition.model";
import { DataTablesResponse } from "../models/database-response.model";

import { formatLocaleTime, formatServerTime } from "../utils/format";
import { NONE_TYPE } from "@angular/compiler";
import { Observable } from "rxjs";

@Injectable()
export class RequisitionService {

  private readonly JOB_URL: string = environment.api + "/job";
  private readonly CLIENT_NAME: string = "cardio_study";

  private statusFilter: any = null;
  private typeFilter: any = null;
  private programFilter: any = null;
  private customFilter: string | null = null;
  private noBookingFilter: string | null = null;
  private reqNameLastFilter: string | null = null;
  private reqNameFirstFilter: string | null = null;
  private timeFilter: string | null = null;
  private comparisonFilter: string | null = null;
  private closedFilter: boolean | null = null;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  public clearFilters() {
    this.customFilter = null;
    this.noBookingFilter = null;
    this.programFilter = null;
    this.typeFilter = null;
    this.statusFilter = null;
    this.reqNameLastFilter = null;
    this.reqNameFirstFilter = null;
    this.timeFilter = null;
    this.comparisonFilter = null;
    this.closedFilter = null;
  }

  public updateStatusFilter(status: string) {
    if (status == "All") {
      this.statusFilter = {
        regex: true,
        method: "not_like",
        value: "Archived",
      };
    } else if (status == 'Reconcile') {
      this.statusFilter = null;
      this.customFilter = "dupe_reqs"
    } else if (status == 'New Data') {
      this.statusFilter = null;
      this.customFilter = "dupe_new_reqs"
    } else if (!status) {
      this.statusFilter = null;
    } else {
      this.statusFilter = {
        regex: true,
        value: status,
      };
    }
  }

  public updateProgramFilter(status: string) {
    if (status == "All") {
      this.programFilter = null;
    } else if (status == "Empty") {
      this.programFilter = {
        regex: true,
        method: "null",
        value: true,
      };
    } else {
      this.programFilter = {
        regex: true,
        value: status,
      };
    }
  }

  public updateClosedFilter(value: boolean | null) {
    this.closedFilter = value;
  }

  public updateDocumentTypeFilter(documentType: string) {
    if (documentType === "All") {
      this.typeFilter = {
        regex: true,
        method: "not_like",
        value: "Archived",
      };
    } else if (documentType === "New") {
      this.typeFilter = {
        regex: true,
        method: "in",
        value: ["New", "Merged", "Splitted"],
      };
    } else if (!documentType) {
      this.typeFilter = null;
    } else {
      this.typeFilter = {
        regex: true,
        value: documentType,
      };
    }
  }

  public setCustomFilter(filterName: string | null) {
    this.customFilter = this.customFilter == filterName ? null : filterName;
  }

  public setBookingFilter(filterName: string | null) {
    this.noBookingFilter = this.noBookingFilter == filterName ? null : filterName;
  }

  public setTimeFilter(filterTime: string, filterComparison: string) {
    this.timeFilter = filterTime;
    this.comparisonFilter = filterComparison;
  }

  public updateShowDuplicates(reqLastName: string, reqFirstName: string) {
    this.reqNameLastFilter = reqLastName;
    this.reqNameFirstFilter = reqFirstName;
  }

  public updateProgramTypeFilter(programType: string) {
    if (programType === "All") {
      this.programFilter = null;
    } else if (!programType) {
      this.programFilter = null;
    } else if (programType == "None") {
      this.programFilter = {
        regex: true,
        method: "null",
        value: true,
      };
    } else {
      this.programFilter = {
        regex: true,
        value: programType,
      };
    }
  }

  // this is used to override the ajax call in datatables for server-side processing
  public getAjaxFuncion(): any {

    // pass this class to the callback through "that"
    const that: RequisitionService = this;

    // server side rendering
    return (dataTablesParams: any, callback: any): void => {

      // populate the info in multiform part
      dataTablesParams["table"] = "requisition";
      dataTablesParams["dupe_new_reqs_patient"] = !!this.reqNameLastFilter && !!this.reqNameFirstFilter;
      dataTablesParams["show_duplicate"] = !!this.reqNameLastFilter && !!this.reqNameFirstFilter;
      dataTablesParams["time"] = this.timeFilter;
      dataTablesParams["time_comparison"] = this.comparisonFilter;
      dataTablesParams["booking"] = this.noBookingFilter;

      if (!!this.customFilter) {
        dataTablesParams["custom_req"] = this.customFilter;
      }

      if (this.statusFilter !== null) {
        dataTablesParams.columns.push({
          data: 'requisition:status',
          name: '',
          searchable: true,
          orderable: true,
          search: this.statusFilter,
        });
      }

      if (this.programFilter !== null) {
        dataTablesParams.columns.push({
          data: 'requisition:program',
          name: '',
          searchable: true,
          orderable: true,
          search: this.programFilter,
        });
      }

      if (this.typeFilter !== null) {
        dataTablesParams.columns.push({
          data: 'requisition:document_type',
          name: '',
          searchable: true,
          orderable: true,
          search: this.typeFilter,
        });
      }

      if (this.reqNameLastFilter !== null) {
        dataTablesParams.columns.push({
          data: 'requisition:last_name',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            value: this.reqNameLastFilter,
          },
        });
      }

      if (this.reqNameFirstFilter !== null) {
        dataTablesParams.columns.push({
          data: 'requisition:first_name',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            value: this.reqNameFirstFilter,
          },
        });
      }

      if (this.closedFilter !== null) {
        dataTablesParams.columns.push({
          data: 'requisition:closed_by',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: this.closedFilter ? "not_null" : "null",
            value: true,
          },
        });
      }

      if (this.programFilter !== null) {
        dataTablesParams.columns.push({
          data: 'requisition:program',
          name: '',
          searchable: true,
          orderable: true,
          search: this.programFilter,
        });
      }

      const formData: FormData = new FormData();
      formData.append("info", JSON.stringify({
        client: that.CLIENT_NAME,
        job_name: "query_data",
        job_id: null,
        task_status: "create",
        data: dataTablesParams
      }));

      // request the data from url
      that.http.post(that.JOB_URL, formData, this.httpOptions()).toPromise()
        .then((response: any) => {

          // callback to the datatable to rerender with the updated information
          const result: DataTablesResponse = response.Payload.data.result;
          callback({
            recordsTotal: result.recordsTotal,
            recordsFiltered: result.recordsFiltered,
            data: result.data.map(formatLocaleTime),
          });

        })
        .catch((error: any) => {
          console.log("Error datatable handler for requisition: " + error.message);
        });
    };
  }

  public getRequisition(id: number): Promise<any> {
    const url = environment.api + '/requisition/' + id;
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public searchRequisition(term: string, newOnly: any, defaultValue: any): Promise<any> {
    const url = environment.api + '/requisition/search?search=' + encodeURIComponent(term) + '&new=' + (newOnly ? encodeURIComponent(newOnly) : '');
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => {
        return (response.length <= 0) ? [defaultValue] : response;
      });
  }

  public getRequisitionPresignedUrl(id: number): Promise<any> {
    const url = environment.api + '/requisition/' + id + '/presigned-url';
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public mergeERequisition(holterId: number, reqID: number): Promise<any> {
    const url = environment.api + '/requisition/' + holterId + "/e-merge";
    return this.http.post(url, {"reqID": reqID}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public renameReq(id: number, data: any): Promise<any> {
    const url = environment.api + '/requisition/' + id + '/rename';
    return this.http.post(url, data, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public markDocumentType(id: number, documentType: string): Promise<any> {
    const url = environment.api + '/requisition/' + id + '/mark';
    return this.http.post(url, {"document_type": documentType, "status": "New"}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public updateStatus(id: number, status: string): Promise<any> {
    const url = environment.api + '/requisition/' + id + '/mark';
    return this.http.post(url, {"status": status}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public updateComments(id: number, comments: string): Promise<any> {
    const url = environment.api + '/requisition/' + id + '/mark';
    return this.http.post(url, {"comments": comments}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public splitDocument(id: number, pagesStr: string): Promise<any> {
    const url = environment.api + '/requisition/' + id + '/split';
    return this.http.post(url, {"pages": pagesStr}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public mergeDocument(ids: number[]): Promise<any> {
    const url = environment.api + '/requisition/merge';
    return this.http.post(url, {"req_ids": ids}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public rotateDocument(id: number, pagesStr: string): Promise<any> {
    const url = environment.api + '/requisition/' + id + '/rotate';
    return this.http.post(url, {"pages": pagesStr}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public reserve(id: any): Promise<any> {
    const url = environment.api + '/requisition/' + id + '/reserve';
    return this.http.post(url, {}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public unreserve(id: any): Promise<any> {
    const url = environment.api + '/requisition/' + id + '/unreserve';
    return this.http.post(url, {}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  private httpOptions(): any {
    return { headers: new HttpHeaders({
      "token": this.authService.getToken(),
      "version": version,
    }) };
  }

  public exportQuery(custom, debug): Observable<any> {
    const queryParams = [];

    if (debug) {
      queryParams.push('debug=' + debug)
    }
    if (custom) {
      queryParams.push('custom=' + custom)
    }
    const url = environment.api + '/requisition/query?' + queryParams.join("&");
    const options = this.httpOptions();
    options.responseType = "blob";
    return this.http.get(url, options);
  }

  public getRequisitionReport(holterId: number): Promise<any> {
    const url = environment.api + '/requisition/' + holterId + "/requisition-report";
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public createRequisitionReport(holterId: number, data: any): Promise<any> {
    const url = environment.api + '/requisition/' + holterId + "/requisition-report";
    return this.http.post(url, data, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

}
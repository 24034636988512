<div class="container-fluid">
    <div class="row">
        <div class="col-1 center">
            <span>{{name}}</span>
        </div>
        <div class="col-1 center">
            <i *ngIf="imagePercentage < 0" class="fa fa-arrow-down" style="color: red"></i>
            <i *ngIf="imagePercentage > 0" class="fa fa-arrow-up" style="color: green"></i>
            <i *ngIf="imagePercentage == 0" class="fa fa-arrow-right" style="color: orange"></i>
        </div>
        <div class="col-1 center">
            <span>{{percentage}}</span>
        </div>
        <div class="col-7 center" [ngStyle]=getStyle()>
            <span>{{currentCount}}</span>
        </div>
        <div class="col-2 center">
            <span>{{pastCount}}</span>
        </div>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { FeatureToggleService } from "../../providers/feature-toggle.service";

@Component({
  selector: 'app-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss']
})
export class DebugComponent implements OnInit {

  public showHolterTab$: Observable<boolean>;
  public showAbpmTab$: Observable<boolean>;
  public showSparkTab$: Observable<boolean>;
  public showSparkMonitorTab$: Observable<boolean>;
  public showReqTab$: Observable<boolean>;
  public showDirectoryTab$: Observable<boolean>;
  public showResearchTab$: Observable<boolean>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private featureToggleService: FeatureToggleService
  ) { }

  ngOnInit(): void {
    this.showHolterTab$ = this.route.paramMap.pipe(switchMap(() => this.featureToggleService.showHolterSubtab("/debug/holter")));
    this.showAbpmTab$ = this.route.paramMap.pipe(switchMap(() => this.featureToggleService.showHolterSubtab("/debug/abpm")));
    this.showSparkTab$ = this.route.paramMap.pipe(switchMap(() => this.featureToggleService.showHolterSubtab("/debug/spark")));
    this.showSparkMonitorTab$ = this.route.paramMap.pipe(switchMap(() => this.featureToggleService.showHolterSubtab("/debug/spark-monitor")));
    this.showReqTab$ = this.route.paramMap.pipe(switchMap(() => this.featureToggleService.showHolterSubtab("/debug/req")));
    this.showDirectoryTab$ = this.route.paramMap.pipe(switchMap(() => this.featureToggleService.showHolterSubtab("/debug/directory")));
    this.showResearchTab$ = this.route.paramMap.pipe(switchMap(() => this.featureToggleService.showHolterSubtab("/debug/research")));
  }

  public navigateTo(url: string): void {
    this.router.navigateByUrl(url);
  }

  public urlContains(str: string): boolean {
    return this.router.url.indexOf(str) > -1;
  }

}
<div class="modal-body">
  <form>
    <div class="row">
      <div class="col form-group text-center">
        <div class="title-selection-orange">{{titleString}}</div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group" [formGroup]="formGroup">
            <div class="row">
              <div class="col">
                <div class="row ml-3">
                  <div class="col form-group">
                    <input class="form-check-input" type="checkbox" name="check1" id="check1" formControlName="Addressed">
                    <label class="form-check-label" for="check1">Addressed</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col form-group">
                    <input type="text" class="form-control" placeholder="Add Staff Notes" formControlName="TextStaffNotes" [ngClass]="{'field-changed' : formGroup.get('TextStaffNotes').dirty}" [attr.disabled]="!holter ? '' : null">
                    <textarea #attemptLogDiv rows="6" class="form-control no-top-bot-margin" formControlName="StaffNotes" [attr.disabled]="true"></textarea>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="row">
                  <div class="col form-group">
                    <label>Referring Doctor</label>
                    <input type="text" class="form-control" placeholder="" formControlName="ReferringDoctor" [attr.disabled]="true">
                  </div>
                  <div class="col form-group">
                    <label>Clinic</label>
                    <input type="text" class="form-control" placeholder="" formControlName="Clinic" [attr.disabled]="true">
                  </div>
                </div>
                <div class="row">
                  <div class="col form-group">
                    <label>Phone Number</label>
                    <input type="text" class="form-control" placeholder="" formControlName="PhoneNumber" [ngClass]="{'field-changed' : formGroup.get('PhoneNumber').dirty}">
                  </div>
                  <div class="col form-group">
                    <label>Fax Number</label>
                    <input type="text" class="form-control" placeholder="" formControlName="FaxNumber" [attr.disabled]="true">
                  </div>
                </div>
                <div class="row">
                  <div class="col-2 text-center">
                    <i data-toggle="tooltip" data-placement="left" title="See Tracings" class="fa fa-file-pdf-o filter-icons" [class.filter-icons-off]="showPdfPreview != 'tracings'" (click)="toggleFileTypeFilter('pdf')"></i>
                    <p>Tracings</p>
                  </div>
                  <div class="col-2 text-center">
                    <i data-toggle="tooltip" data-placement="left" title="See Requisition" class="fa fa-file-o filter-icons" [class.filter-icons-off]="showPdfPreview != 'requisition'" (click)="toggleFileTypeFilter('req')"></i>
                    <p>Requisition</p>
                  </div>
                  <div class="col-2 text-center">
                    <i data-toggle="tooltip" data-placement="left" title="See Report" class="fa fa-file-text-o filter-icons" [class.filter-icons-off]="showPdfPreview != 'report'" (click)="toggleFileTypeFilter('report')"></i>
                    <p>Report</p>
                  </div>
                  <div class="col form-group">

                  </div>
                  <div class="col-md-auto form-group">
                    <button class="btn btn-primary btn-narrow" type="button" (click)="update()" >Update</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row preview-panel">
              <div class="col text-center">
                <div *ngIf="holter || pdfSrc && !loadingPdf">
                  <pdf-viewer [src]="pdfSrc" [autoresize]="true" [render-text]="false" [original-size]="false" [fit-to-page]="true" style="display: block;"></pdf-viewer>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col text-right">
                <button type="submit" class="btn btn-primary btn-review" (click)="fax()">Fax</button>
              </div>
            </div>
        </div>
      </div>
    </div>
  </form>
</div>
import { AfterViewInit, Component, OnInit, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { DataTableDirective } from 'angular-datatables';

import { Abpm } from "../../models/abpm.model";
import { Visit } from "../../models/visit.model";
import { Patient } from "../../models/patient.model";
import { AbpmAnalysis } from "../../models/abpm-analysis.model";

import { AuthService } from "../../providers/auth.service";
import { AbpmService } from "../../providers/abpm.service";
import { VisitService } from "../../providers/visit.service";

@Component({
  selector: 'app-home',
  templateUrl: './abpm.component.html',
  styleUrls: ['./abpm.component.scss']
})
export class AbpmComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;

  public dtTrigger: any = new Subject();
  public dtOptions: any = {};
  public dtOptionsPending: any = {};
  public dtOptionsRaw: any = {};

  public dtInstancePending: any = null;
  public dtInstanceNotPending: any = null;

  public hiddenAbpms: boolean = false;
  public isAdmin: boolean = false;
  public abpmFilter: string | null = "";
  public abpmStatusFilter: string | null = "All";

  // important variable that changes if the status column position changes
  private readonly STATUS_COLUMN_INDEX: number = 0;
  // public readonly STATUS_ALL: string = "All";
  // public readonly STATUS_PENDING: string = "Pending";
  // public readonly STATUS_REVIEW: string = "Review";
  // public readonly STATUS_COMPLETED: string = "Completed";
  // public status: string = this.STATUS_REVIEW;

  public ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  public ngAfterViewInit(): void {

    // this handles the filter tabs on the top of the table
    this.dtElements.forEach((dtElement: DataTableDirective) => {

      if (dtElement.dtInstance === undefined) {
        return;
      }

      // dtElement.dtInstance.then((dtInstance: any) => {

      //   // click handlers for pending table, refresh the table
      //   if (dtInstance.table().node().id == "pending-table") {

      //     const statusColumn = dtInstance.column(this.STATUS_COLUMN_INDEX);
      //     this.dtInstancePending = dtInstance;

      //     $(".table-filter").on("click", () => {
      //       statusColumn.search("").draw();
      //     });

      //   // click handlers for non-pending table, apply a filter
      //   } else {

      //     const statusColumn = dtInstance.column(this.STATUS_COLUMN_INDEX);
      //     this.dtInstanceNotPending = dtInstance;

      //     $(".table-filter").on("click", () => {
      //       const filter: string = (this.status == this.STATUS_ALL) ? "" : this.status;
      //       if (statusColumn.search() !== filter) {
      //         statusColumn.search(filter).draw();
      //       }
      //     });

      //     const filter: string = (this.status == this.STATUS_ALL) ? "" : this.status;
      //     if (statusColumn.search() !== filter) {
      //       statusColumn.search(filter).draw();
      //     }
      //   }

      // });

      dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        $("#search-bar").on("keyup change", function () {
          if (dtInstance.search() !== (<HTMLInputElement>this)["value"]) {
            dtInstance.search((<HTMLInputElement>this)["value"]).draw();
          }
        });
      });
      $("#search-bar").focus();

    });
  }

  public isSuperUserOnly(roles: any[]) {
    let counts = 0;
    for (const role of roles) {
      if (role.name === "Super User") {
        counts += 1;
      }
    }
    return counts == 1;
  }

  private isAdminOnly(roles: any[]) {
    let counts = 0;
    for (const role of roles) {
      if (role.name === "Administrator") {
        counts += 1;
      }
    }
    return counts == 1;
  }

  public ngOnInit() {}

  constructor(
    private abpmService: AbpmService,
    private visitService: VisitService,
    private authService: AuthService,
    private router: Router
  ) {

    // roll callback
    const rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
      // $("td", row).unbind("click");
      $(".fa-plus", row).bind("click", (event) => this.expandRowsHandler(event, data, true));
      $(".fa-minus", row).bind("click", (event) => this.expandRowsHandler(event, data, false));
      $("td", row).not(".expand-row-column").bind("click", () => this.rowClickHandler(data));
      return row;
    };


    // if reading doctor, filter so that they can see just what's assigned to them. no need to strict permission check on server, because it's okay for them to see
    const isReadingDoctor = this.authService.getToken(true).roles.some(role => role.name === "Reading Doctor");
    const tokenInfo = this.authService.getToken(true);
    this.abpmService.updateReadingDoctorFilter((tokenInfo.doctor_id && isReadingDoctor) ? tokenInfo.doctor_id : null);
    this.abpmService.updateHiddenAbpmFilter(false);
    if (this.isAdminOnly(tokenInfo.roles) || (this.isSuperUserOnly(tokenInfo.roles))) {
      this.isAdmin = true;
    }

    // datatable options
    this.dtOptions = {
        autoWidth: false,
        responsive: true,
        lengthChange: false,
        select: true,
        pageLength: 25,
        dom: "Blfrtip",
        buttons: [],
        rowCallback: rowCallback,
        serverSide: true,
        processing: true,
        ajax: this.abpmService.getAjaxFuncion(),
        order: [[ 1, "desc" ]],
        columnDefs: [{
          targets: 0,
          className: 'expand-row-column'
        }],
        columns: [
          {
            data: 'abpm:id',
            orderable: false,
            render: ( data, type, row, meta ) => {
              const duplicateCount = row["abpm:duplicate_count"];
              let expandHtml = ``;
              if (duplicateCount > 1)
                expandHtml = ` &nbsp;<a><i class="fa fa-plus expand-rows-icon"></i></a>`;
              if (duplicateCount < -1)
                expandHtml = ` &nbsp;<a><i class="fa fa-minus expand-rows-icon"></i></a>`;

              return expandHtml;
            },
          },
          { data: "abpm:status" },
          { data: "visit:hookup_at" },
          { data: "patient:last_name" },
          { data: "patient:first_name" },
          { data: "patient:birthdate" },
          { data: "owner:name" },
          { data: "visit:read_by" },
          { data: "reading_doctor:name" },
          { data: "abpm:serial_number", visible: false },
          { data: "abpm:visit_id", visible: false },
        ],
        language: {
          infoFiltered: ""
        }
    };
    // this.dtOptionsPending = {
    //     autoWidth: false,
    //     responsive: true,
    //     lengthChange: false,
    //     select: true,
    //     pageLength: 25,
    //     dom: "Blfrtip",
    //     buttons: [],
    //     rowCallback: rowCallback,
    //     serverSide: true,
    //     processing: true,
    //     ajax: this.abpmService.getPendingAjaxFuncion(),
    //     order: [[ 1, "desc" ]],
    //     columns: [
    //       { data: "abpm:status" },
    //       { data: "abpm:uploaded_at" },
    //       { data: "abpm:serial_number" },
    //       { data: "owner:name" },
    //       { data: 'abpm:id', visible: false },
    //     ]
    // };

  }

  public rowClickHandler(info: any) {
    this.router.navigateByUrl('/abpm/detail/interpretations/' + info["abpm:id"]);
  }

  // public switchFilter(status: string) {
  //   if (this.status != status) {
  //     this.status = status;
  //   }
  // }

  public expandRowsHandler(event: any, info: any, expand: boolean) {
    const clickedId = info["abpm:id"];
    if (expand)
    {
      this.abpmService.setCustomFilter("show_abpm_duplicates");
      this.dtElements.forEach((dtElement: DataTableDirective) => {
        dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
            const row = dtInstance.rows().data()[rowIdx];
            if (row["abpm:id"] != clickedId) {
              return;
            }
            this.abpmService.updateShowDuplicates(row["abpm:visit_id"], row["abpm:serial_number"]);
            dtInstance.page(0).draw();
          });
        });
      });
    }
    else
    {
      this.resetFilter();
    }
  }

  public resetFilter() {
    this.abpmService.clearFilters();
    this.abpmService.updateHiddenAbpmFilter(this.hiddenAbpms ? true : false);
    if (this.abpmFilter == "SERIAL"){
      this.abpmService.setCustomFilter("hardcoded_serial");
    }
    this.abpmService.updateStatusFilter(this.abpmStatusFilter);
    this.dtElements.forEach((dtElement: DataTableDirective) => {
      dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.draw(false);
      });
    });
  }

  public showHiddenAbpmEntries() {
    this.hiddenAbpms = !this.hiddenAbpms;
    this.resetFilter();
  }
}

<div class="modal-body">
  <form>
    <div class="row">
      <div class="col text-center">
        <div class="form-group">
          <div class="input-group">
            <label for="modalReason">Reason</label>
            <input class="form-control" id="modalReason" name="modalReason" type="text" [(ngModel)]="modalResult">
          </div>
          <button type="submit" class="btn btn-primary btn-review" (click)="passBack()">Close</button>
        </div>
      </div>
    </div>
  </form>
</div>
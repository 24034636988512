import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { version } from '../../../package.json';

import { environment } from "../../environments/environment";
import { JOB_STATUS, JOBS } from "../config/constants";
import { AuthService } from "../providers/auth.service";
import { DataTablesResponse } from "../models/database-response.model";

import { formatLocaleTime } from "../utils/format";

@Injectable()
export class BillingService {

  private readonly JOB_URL: string = environment.api + "/job";
  private readonly CLIENT_NAME: string = "cardio_study";

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  // this is used to override the ajax call in datatables for server-side processing
  public getAjaxFuncion(): any {

    // pass this class to the callback through "that"
    const that: BillingService = this;

    // server side rendering
    return (dataTablesParams: any, callback: any): void => {

      // populate the info in multiform part
      dataTablesParams["table"] = "fax";
      const formData: FormData = new FormData();
      formData.append('info', JSON.stringify({
        client: that.CLIENT_NAME,
        job_name: "query_data",
        job_id: null,
        task_status: JOB_STATUS.CREATE,
        data: dataTablesParams
      }));

      // request the data from url
      that.http.post(that.JOB_URL, formData, this.httpOptions()).toPromise()
        .then((response: any) => {

          // callback to the datatable to rerender with the updated information
          const result: DataTablesResponse = response.Payload.data.result;

          callback({
            recordsTotal: result.recordsTotal,
            recordsFiltered: result.recordsFiltered,
            data: result.data.map(formatLocaleTime),
          });

        })
        .catch((error: any) => {
          console.log("Error datatable handler for fax: " + error.message);
        });
    };
  }

  public downloadInvoice(billing_id: number, owner_id: number): Promise<any> {

    // get the timezone
    var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // populate the info in multiform part
    const formData: FormData = new FormData();
    formData.append("info", JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "download_invoice",
      job_id: null,
      task_status: "create",
      data: {
        billing_id: billing_id,
        owner_id: owner_id,
        timezone: timezone,
      },
    }));

    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => {
        const pdfBase64: string = response.Payload.data;
        return pdfBase64;
      })
      .catch((error: any) => {
        console.log("Failed to generate PDF invoice: " + error.message);
        return null;
      });
  }

  public emailInvoice(subject: string, email: string, billing_id: number, owner_id: number): Promise<any> {

    // get the timezone
    var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // populate the info in multiform part
    const formData: FormData = new FormData();
    formData.append("info", JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "email_invoice",
      job_id: null,
      task_status: "create",
      data: {
        subject: subject,
        email: email,
        billing_id: billing_id,
        owner_id: owner_id,
        timezone: timezone,
      },
    }));

    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => response.Payload.data)
      .catch((error: any) => {
        console.log("Failed to generate PDF invoice: " + error.message);
        return null;
      });

  }

  public makePayment(nonce: string, cardData: any, billing_id: number): Promise<any> {

    // populate the info in multiform part
    const formData: FormData = new FormData();
    formData.append("info", JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "make_payment",
      job_id: null,
      task_status: "create",
      data: {
        nonce: nonce,
        cardData: cardData,
        billing_id: billing_id,
      },
    }));

    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => response.Payload.data)
      .catch((error: any) => {
        console.log("Failed to make a payment: " + error.message);
        return null;
      });

  }

  public updatePrice(billing_id: number, price: number): Promise<any> {

    // populate the info in multiform part
    const formData: FormData = new FormData();
    formData.append("info", JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "update_price",
      job_id: null,
      task_status: "create",
      data: {
        price: price,
        billing_id: billing_id,
      },
    }));

    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => response.Payload.data)
      .catch((error: any) => {
        console.log("Failed to make update price: " + error.message);
        return null;
      });

  }

  public exportQuery(holterState, showAll, code, start, end, status, debug): Observable<any> {
    const queryParams = [];
    queryParams.push('all=' + (showAll ? '1' : ''));
    if (holterState) {
      queryParams.push('state=' + holterState)
    }
    if (code) {
      queryParams.push('code=' + code)
    }
    if (debug) {
      queryParams.push('debug=' + debug)
    }
    if (status) {
      queryParams.push('status=' + status)
    }
    if (start) {
      queryParams.push('start=' + start)
    }
    if (end) {
      queryParams.push('end=' + end)
    }
    const url = environment.api + '/billing/query?' + queryParams.join("&");
    const options = this.httpOptions();
    options.responseType = "blob";
    return this.http.get(url, options);
  }

  public exportBilling(holterState, showAll, code, start, end, status): Observable<any> {
    const queryParams = [];
    queryParams.push('all=' + (showAll ? '1' : ''));
    if (holterState) {
      queryParams.push('state=' + holterState)
    }
    if (code) {
      queryParams.push('code=' + code)
    }
    if (status) {
      queryParams.push('status=' + status)
    }
    if (start) {
      queryParams.push('start=' + start)
    }
    if (end) {
      queryParams.push('end=' + end)
    }
    const url = environment.api + '/billing/report?' + queryParams.join("&");
    const options = this.httpOptions();
    options.responseType = "blob";
    options.observe = "response";
    return this.http.get(url, options);
  }

  private httpOptions(): any {
    return { headers: new HttpHeaders({
      "token": this.authService.getToken(),
      "version": version,
    }) };
  }

}
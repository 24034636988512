import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ElementRef, ViewChild} from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { HostListener } from '@angular/core';

import { Holter } from "../../models/holter.model";
import { HolterService } from "../../providers/holter.service";

@Component({
  selector: 'app-home',
  templateUrl: './holter-detail-report.component.html',
  styleUrls: ['./holter-detail-report.component.scss']
})
export class HolterDetailReportComponent implements OnInit {

  public error: string = "";
  public holter: Holter = new Holter();

  public title: string = "";

  public holter$: Observable<Holter>;
  public currentHolter: Holter;

  public generalComment1: string = "";
  public generalComment2: string = "";
  public generalComment3: string = "";
  public generalComment4: string = "";
  public generalComment5: string = "";

  public alertGeneralComment5: string = "";

  public specificComment1: string = "";
  public specificComment2: string = "";
  public specificComment3: string = "";
  public specificComment4: string = "";
  public specificComment5: string = "";

  public summary1: string = "";
  public summary2: string = "";
  public summary3: string = "";
  public summary4: string = "";
  public summary5: string = "";

  @ViewChild("generalComment1Div") generalComment1Div: ElementRef;
  @ViewChild("generalComment2Div") generalComment2Div: ElementRef;
  @ViewChild("generalComment3Div") generalComment3Div: ElementRef;
  @ViewChild("generalComment4Div") generalComment4Div: ElementRef;
  @ViewChild("generalComment5Div") generalComment5Div: ElementRef;
  @ViewChild("specificComment1Div") specificComment1Div: ElementRef;
  @ViewChild("specificComment2Div") specificComment2Div: ElementRef;
  @ViewChild("specificComment3Div") specificComment3Div: ElementRef;
  @ViewChild("specificComment4Div") specificComment4Div: ElementRef;
  @ViewChild("specificComment5Div") specificComment5Div: ElementRef;
  @ViewChild("summary1Div") summary1Div: ElementRef;
  @ViewChild("summary2Div") summary2Div: ElementRef;
  @ViewChild("summary3Div") summary3Div: ElementRef;
  @ViewChild("summary4Div") summary4Div: ElementRef;
  @ViewChild("summary5Div") summary5Div: ElementRef;

  constructor(
    private holterService: HolterService,
    private route: ActivatedRoute,
    private router: Router
  ) {

    // the following allows reloading of the same route
    this.router.routeReuseStrategy.shouldReuseRoute = function(){
        return false;
    };
    this.router.events.subscribe((evt) => {
        if (evt instanceof NavigationEnd) {
            this.router.navigated = false;
            window.scrollTo(0, 0);
        }
    });
  }

  public ngOnInit() {

    // get the Holter object from the server
    this.holter$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.holterService.getHolter(parseInt(params.get('id'), 10)))
    );

    // initialize form
    this.holter$.subscribe((holter: Holter) => {
      this.currentHolter = holter;
      if (holter) {
        if ((holter.patient_first_name.length + holter.patient_last_name.length) < 20) {
          this.title = "Medflux - " + holter.patient_first_name + " " + holter.patient_last_name;
        } else {
          this.title = "Medflux - Report";
        }
      } else {
        this.title = "Medflux - Report";
      }
      this.populateFormWithCurrentData();
    });
  }

  public buildHIN(holter: Holter): string | null {
    if (!holter || !holter.patient_health_card)
      return null;

    if (holter.patient_health_card_code) {
      return holter.patient_health_card + "-" + holter.patient_health_card_code;
    } else {
      return holter.patient_health_card;
    }
  }

  public getMedfluxData(holter: Holter, field: string): string | null {
    if (!holter || !holter.medflux_data)
      return null;

    const parsed = JSON.parse(holter.medflux_data);
    return parsed[field];
  }

  public clearForm(): void {
    this.generalComment1 = "";
    this.generalComment2 = "";
    this.generalComment3 = "";
    this.generalComment4 = "";
    this.generalComment5 = "";
    this.alertGeneralComment5 = "";
    this.specificComment1 = "";
    this.specificComment2 = "";
    this.specificComment3 = "";
    this.specificComment4 = "";
    this.specificComment5 = "";
    this.summary1 = "";
    this.summary2 = "";
    this.summary3 = "";
    this.summary4 = "";
    this.summary5 = "";
  }

  public convertStringToArray(toConvert: string): string[] {
    if (!toConvert || toConvert === "") {
      return [];
    }
    try {
      return JSON.parse(toConvert);
    } catch(e) {
      return [toConvert]
    }
  }

  public autofillForm(fromEditor: boolean = false): void {
    this.holterService.getCommentPredictions(this.currentHolter.id, fromEditor).then(predictions => {
      this.populateForm(predictions.general_comments, predictions.specific_comments, predictions.summary);
      if (predictions.alerts && predictions.alerts.general_comment_5) {
        this.alertGeneralComment5 = predictions.alerts.general_comment_5;
      }
    });
  }

  public undoForm(): void {
    this.populateFormWithCurrentData();
  }

  public submitForm(): void {
    this.holterService.updateHolter(this.currentHolter.id, {
      "general_comments": JSON.stringify([this.generalComment1, this.generalComment2, this.generalComment3, this.generalComment4, this.generalComment5]),
      "specific_comments": JSON.stringify([this.specificComment1, this.specificComment2, this.specificComment3]),
      "summary": JSON.stringify([this.summary1, this.summary2, this.summary3, this.summary4, this.summary5]),
    }).then(() => {
        this.navigateTo('/holter/detail/report/' + this.currentHolter.id);
    });
  }

  private populateFormWithCurrentData(): void {
    this.populateForm(this.currentHolter.general_comments, this.currentHolter.specific_comments, this.currentHolter.summary);
  }

  private populateForm(general: string, specific: string, summary: string): void {

    const generalComments = this.convertStringToArray(general);
    const specificComments = this.convertStringToArray(specific);
    const summaries = this.convertStringToArray(summary);

    this.generalComment1 = generalComments.length >= 1 ? generalComments[0] : null;
    this.generalComment2 = generalComments.length >= 2 ? generalComments[1] : null;
    this.generalComment3 = generalComments.length >= 3 ? generalComments[2] : null;
    this.generalComment4 = generalComments.length >= 4 ? generalComments[3] : null;
    this.generalComment5 = generalComments.length >= 5 ? generalComments[4] : null;
    this.specificComment1 = specificComments.length >= 1 ? specificComments[0] : null;
    this.specificComment2 = specificComments.length >= 2 ? specificComments[1] : null;
    this.specificComment3 = specificComments.length >= 3 ? specificComments[2] : null;
    this.specificComment4 = specificComments.length >= 4 ? specificComments[3] : null;
    this.specificComment5 = specificComments.length >= 5 ? specificComments[4] : null;
    this.summary1 = summaries.length >= 1 ? summaries[0] : null;
    this.summary2 = summaries.length >= 2 ? summaries[1] : null;
    this.summary3 = summaries.length >= 3 ? summaries[2] : null;
    this.summary4 = summaries.length >= 4 ? summaries[3] : null;
    this.summary5 = summaries.length >= 5 ? summaries[4] : null;

    setTimeout(() => this.autoResizeTextAreas(), 500);
  }

  private setToScrollHeight(elementRef: ElementRef) {
    if (elementRef) {
      const e = elementRef.nativeElement;
      const styleHeight = parseInt(e.style.height.substring(0, e.style.height.length - 2));
      e.style.height = (e.scrollHeight > styleHeight || isNaN(styleHeight)) ? ((e.scrollHeight + 3) + 'px') : e.style.height;
    }
  }

  private autoResizeTextAreas() {
    if (!this.setToScrollHeight) return;
    this.setToScrollHeight(this.generalComment1Div);
    this.setToScrollHeight(this.generalComment2Div);
    this.setToScrollHeight(this.generalComment3Div);
    this.setToScrollHeight(this.generalComment4Div);
    this.setToScrollHeight(this.generalComment5Div);
    this.setToScrollHeight(this.specificComment1Div);
    this.setToScrollHeight(this.specificComment2Div);
    this.setToScrollHeight(this.specificComment3Div);
    this.setToScrollHeight(this.specificComment4Div);
    this.setToScrollHeight(this.specificComment5Div);
    this.setToScrollHeight(this.summary1Div);
    this.setToScrollHeight(this.summary2Div);
    this.setToScrollHeight(this.summary3Div);
    this.setToScrollHeight(this.summary4Div);
    this.setToScrollHeight(this.summary5Div);
  }

  private navigateTo(url: string): void {
    this.router.navigateByUrl(url);
  }

  @HostListener('keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    this.autoResizeTextAreas();
    if (event.key === 'Tab') {
      // event.preventDefault();
      // this.increment();
    }
  }

}

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { Visit } from "../../models/visit.model";
import { Patient } from "../../models/patient.model";
import { Physician } from "../../models/physician.model";
import { Clinic } from "../../models/clinic.model";

import { AuthService } from "../../providers/auth.service";
import { VisitService } from "../../providers/visit.service";
import { PatientService } from "../../providers/patient.service";
import { PhysicianService } from "../../providers/physician.service";
import { ClinicService } from "../../providers/clinic.service";

import { DataTablesResponse } from "../../models/database-response.model";

import { formatLocaleTime } from "../../utils/format";

@Component({
  selector: 'app-home',
  templateUrl: './visit.component.html',
  styleUrls: ['./visit.component.scss']
})
export class VisitComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;

  public dtOptions: any = {};

  private visits: Visit[] = [];
  public visit: Visit | null = new Visit();

  public readonly PAGE_SEARCH: string = "search";
  public readonly PAGE_CREATE: string = "create";
  public readonly PAGE_EDIT: string = "edit";
  public page: string = this.PAGE_SEARCH;

  public readonly HEIGHT_METRIC = "cm";
  public readonly HEIGHT_IMPERIAL = "in";
  public readonly WEIGHT_METRIC = "kg";
  public readonly WEIGHT_IMPERIAL = "lb";
  public heightUnit: string = this.HEIGHT_METRIC;
  public weightUnit: string = this.WEIGHT_METRIC;

  public createFormGroup: FormGroup;

  //
  // Patient Typeahead Functions and Variables
  //
  public optionPatients: Patient[] = [];
  private formatPatients: any = obj => obj.first_name + " " + obj.last_name + " (" + obj.health_card + ")";

  public searchPatient: any = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        if (term.length < 3) {
          return Promise.resolve([]);
        }
        return this.patientService.searchPatient(term).then((options: Patient[]) => {
          if (options === null || options === undefined || options.length === 0) {
            return [];
          }
          this.optionPatients = options;
          return options.map(this.formatPatients);
        });
      })
    );
  };

  public selectedPatientId(currentPatient: string) : number | null {
    if (currentPatient === this.visit.patient_first_name + " " + this.visit.patient_last_name + " (" + this.visit.patient_health_card + ")") {
      return this.visit.patient_id;
    }

    for (const patient of this.optionPatients) {
      if (currentPatient === this.formatPatients(patient)) {
        return patient.id;
      }
    }
    return null;
  }

  private patientValidator(): any {
    const that = this;
    return (control: FormControl) => {
      const patientId: number | null = that.selectedPatientId(control.value);
      if (patientId === null || patientId === undefined) {
        return {
          patient: { parsedPatient: patientId }
        };
      }
      return null;
    }
  }

  //
  // Referring Doctor Typeahead Functions and Variables
  //
  public optionReferringDoctors: Physician[] = [];
  private formatDoctors: any = obj => obj.first_initials + " " + obj.last_name;

  public searchReferringDoctor: any = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        if (term.length < 2) {
          return Promise.resolve([]);
        }
        return this.physicianService.searchPhysician(term).then((options: Physician[]) => {
          if (options === null || options === undefined || options.length === 0) {
            return [];
          }
          this.optionReferringDoctors = options;
          return options.map(this.formatDoctors);
        });
      })
    );
  };

  public selectedReferringDoctorId(currentDoctor: string) : number | null {
    if (currentDoctor === this.visit.referring_doctor_first_initials + " " + this.visit.referring_doctor_last_name) {
      return this.visit.referring_doctor_id;
    }

    for (const physician of this.optionReferringDoctors) {
      if (currentDoctor === this.formatDoctors(physician)) {
        return physician.id;
      }
    }
    return null;
  }

  private referringDoctorValidator(): any {
    const that = this;
    return (control: FormControl) => {
      const doctorId: number | null = that.selectedReferringDoctorId(control.value);
      if (doctorId === null || doctorId === undefined) {
        return {
          doctor: { parsedDoctor: doctorId }
        };
      }
      return null;
    }
  }

  //
  // Reading Doctor Typeahead Functions and Variables
  //
  public optionReadingDoctors: Physician[] = [];

  public searchReadingDoctor: any = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        if (term.length < 2) {
          return Promise.resolve([]);
        }
        return this.physicianService.searchPhysician(term).then((options: Physician[]) => {
          if (options === null || options === undefined || options.length === 0) {
            return [];
          }
          this.optionReadingDoctors = options;
          return options.map(this.formatDoctors);
        });
      })
    );
  };

  public selectedReadingDoctorId(currentDoctor: string) : number | null {
    if (currentDoctor === this.visit.reading_doctor_first_initials + " " + this.visit.reading_doctor_last_name) {
      return this.visit.reading_doctor_id;
    }

    for (const physician of this.optionReadingDoctors) {
      if (currentDoctor === this.formatDoctors(physician)) {
        return physician.id;
      }
    }
    return null;
  }

  private readingDoctorValidator(): any {
    const that = this;
    return (control: FormControl) => {
      const doctorId: number | null = that.selectedReadingDoctorId(control.value);
      if (doctorId === null || doctorId === undefined) {
        return {
          doctor: { parsedDoctor: doctorId }
        };
      }
      return null;
    }
  }

  //
  // Clinic Typeahead Functions and Variables
  //
  public optionClinics: Clinic[] = [];
  private formatClinics: any = obj => obj.name + " (" + obj.postal_code + ")";

  public searchClinic: any = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        if (!this.authService.isCardioStudyCompanyClass() || term.length < 2) {
          return Promise.resolve([]);
        }
        return this.clinicService.searchClinic(term).then((options: Clinic[]) => {
          if (options === null || options === undefined || options.length === 0) {
            return [];
          }
          this.optionClinics = options;
          return options.map(this.formatClinics);
        });
      })
    );

  public selectedClinicId(currentClinic: string) : number | null {
    if (currentClinic === this.visit.clinic_name + " (" + this.visit.clinic_postal_code + ")") {
      return this.visit.clinic_id;
    }

    for (const clinic of this.optionClinics) {
      if (currentClinic === this.formatClinics(clinic)) {
        return clinic.id;
      }
    }
    return null;
  }

  private clinicValidator(): any {
    const that = this;
    return (control: FormControl) => {
      const clinicId: number | null = that.selectedClinicId(control.value);
      if (clinicId === null || clinicId === undefined) {
        return {
          clinic: { parsedClinic: clinicId }
        };
      }
      return null;
    }
  }

  //
  // Main Function
  //

  public ngOnInit() {}

  constructor(
    private authService: AuthService,
    private visitService: VisitService,
    private patientService: PatientService,
    private physicianService: PhysicianService,
    private clinicService: ClinicService,
  ) {

    // roll callback
    const rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
      $("td", row).unbind("click");
      $("td", row).bind("click", () => this.rowClickHandler(data));
      return row;
    };

    // datatable options
    this.dtOptions = {
        autoWidth: false,
        responsive: true,
        lengthChange: false,
        select: true,
        pageLength: 25,
        dom: "Blfrtip",
        buttons: [],
        rowCallback: rowCallback,
        serverSide: true,
        processing: true,
        ajax: this.ajaxCall(),
        order: [[ 0, "desc" ]],
        columns: [
          { data: "visit:visited_at" },
          { data: "patient:health_card" },
          { data: "patient:first_name" },
          { data: "patient:last_name" },
          { data: "visit:tech" },
          { data: "referring_doctor:name" },
          { data: "owner:name" },
          { data: 'visit:id', visible: false },
        ]
    };

    this.createFormGroup = new FormGroup({
      PatientFirstName: new FormControl('', [ Validators.required, ]),
      PatientHealthCard: new FormControl('', [ Validators.required, Validators.pattern(/^\d{4}-\d{3}-\d{3}$/) ]),
      PatientHealthCardCode: new FormControl('', [ Validators.pattern(/^[a-zA-Z]{2}$/) ]),
      PatientLastName: new FormControl('', [ Validators.required, ]),
      PatientGender: new FormControl('', [ Validators.required, ]),
      PatientBirthdate: new FormControl('', [ Validators.required, Validators.pattern(/^\d{4}\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/) ]),
      PatientHeight: new FormControl('', [ Validators.required, Validators.pattern(/^\d+$/) ]),
      PatientWeight: new FormControl('', [ Validators.required, Validators.pattern(/^\d+$/) ]),

      ClinicName: new FormControl('', [ Validators.required, ]),
      ClinicCity: new FormControl('', [ ]),
      ClinicProvince: new FormControl('', [ ]),
      ClinicStreet: new FormControl('', [ ]),
      ClinicPostalCode: new FormControl('', [ ]),

      ReferringDoctorFirstInitials: new FormControl('', [ Validators.required, ]),
      ReferringDoctorLastName: new FormControl('', [ Validators.required, ]),
      // InterpretingDoctorFirstName: new FormControl('', [ Validators.required, ]),
      // InterpretingDoctorLastName: new FormControl('', [ Validators.required, ]),

      // Patient: new FormControl('', [ Validators.required, this.patientValidator() ]),
      // Clinic: new FormControl('', [ Validators.required, this.clinicValidator() ]),
      // ReferringDoctor: new FormControl('', [ Validators.required, this.referringDoctorValidator() ]),
      // ReadingDoctor: new FormControl('', [ Validators.required, this.readingDoctorValidator() ]),
      Comments: new FormControl('', [ ]),
      Tech: new FormControl('', [ Validators.required ]),
      VisitedAt: new FormControl('', [ Validators.required, Validators.pattern(/^\d{4}\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/) ]),
      HookupAt: new FormControl('', [ Validators.required, Validators.pattern(/^\d{4}\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/) ]),
      // ScannedOn: new FormControl('', [ Validators.required, Validators.pattern(/^\d{4}\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/) ]),
      Duration: new FormControl('', [ ]),
      Delivery: new FormControl('', [ ]),
      MonitorSerialNumber: new FormControl('', [ ]),
    });

  }

  private ajaxCall(): any {
    const that: VisitComponent = this;

    return (dataTablesParams: any, callback: any): void => {
      that.visitService.ajaxCall(dataTablesParams)
        .then((response: any) => {

          that.selectFirstRow();

          // callback to the datatable to rerender with the updated information
          const result: DataTablesResponse = response.Payload.data.result;
          callback({
            recordsTotal: result.recordsTotal,
            recordsFiltered: result.recordsFiltered,
            data: result.data.map(formatLocaleTime),
          });

        })
        .catch((error: any) => {
          console.log("Error datatable handler for visit: " + error.message);
        });
    }
  }

  public rowClickHandler(info: any, force: boolean = false) {
    this.visitService.getVisit(info["visit:id"])
      .then((found: Visit | null) => {
        if (!found) {
          return;
        } else if (this.visit && found.id == this.visit.id && force === false) {
          this.visit = new Visit();
        } else {
          this.visit = found;
        }
      });
  }

  public ngAfterViewInit(): void {
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      $("#search-bar").on("keyup change", function () {
        if (dtInstance.search() !== (<HTMLInputElement>this)["value"]) {
          dtInstance.search((<HTMLInputElement>this)["value"]).draw();
        }
      });
    });
    $("#search-bar").focus();
  }

  private selectFirstRow(): void {
    this.datatableElement?.dtInstance.then((dtInstance: any) => {
      dtInstance.rows().deselect();
      if (dtInstance.rows().count() > 0) {
        dtInstance.row(0).select();
        this.rowClickHandler(dtInstance.row(0).data(), true);
      }
    });
  }

  private reloadDatatable(): void {
    this.datatableElement?.dtInstance.then(dtInstance => dtInstance.ajax.reload());
  }

  public switchPage(page: string) {
    this.page = page;

    this.heightUnit = this.HEIGHT_METRIC;
    this.weightUnit = this.WEIGHT_METRIC;

    // reset the form
    const formatDate = d => {
      if (isNaN(d.getTime())) {
        return "";
      }
      return d.getFullYear() + "/" + (d.getMonth()+1).toString().padStart(2, '0') + "/" + d.getDate().toString().padStart(2, '0');
    };
    this.createFormGroup.reset();

    // populate the form according to if it's editing or creating
    if (this.page === this.PAGE_EDIT) {
      this.createFormGroup.patchValue({
        PatientFirstName: this.visit.patient_first_name,
        PatientHealthCard: this.visit.patient_health_card,
        PatientHealthCardCode: this.visit.patient_health_card_code,
        PatientLastName: this.visit.patient_last_name,
        PatientGender: this.visit.patient_gender,
        PatientBirthdate: this.visit.patient_birthdate,
        PatientHeight: this.visit.patient_height,
        PatientWeight: this.visit.patient_weight,

        ClinicName: this.visit.clinic_name,
        ClinicCity: this.visit.clinic_city,
        ClinicProvince: this.visit.clinic_province,
        ClinicStreet: this.visit.clinic_street,
        ClinicPostalCode: this.visit.clinic_postal_code,

        ReferringDoctorFirstInitials: this.visit.referring_doctor_first_initials,
        ReferringDoctorLastName: this.visit.referring_doctor_last_name,

        Comments: this.visit.comments,
        Tech: this.visit.tech,
        VisitedAt: formatDate(new Date(this.visit.visited_at)),
        HookupAt: formatDate(new Date(this.visit.hookup_at)),
        // ScannedOn: formatDate(new Date(this.visit.scanned_at)),
        Duration: this.visit.hookup_duration,
        Delivery: this.visit.delivery_id_type,
        MonitorSerialNumber: this.visit.monitor_serial_number,
      });

    } else if (this.page === this.PAGE_CREATE) {
      this.visit = new Visit();
      this.createFormGroup.patchValue({
        VisitedAt: formatDate(new Date()),
        HookupAt: formatDate(new Date()),
        // ScannedOn: formatDate(new Date()),
      });

    } else if (this.page === this.PAGE_SEARCH) {
      this.selectFirstRow();
    }
  }

  public pageName(): string {
    if (this.page === this.PAGE_EDIT && this.visit.patient_first_name.length + this.visit.patient_last_name.length < 25) {
      return this.visit.patient_first_name + " " + this.visit.patient_last_name;
    }
    return this.page.charAt(0).toUpperCase() + this.page.substr(1).toLowerCase();
  }

  public createEditTitle(): string {
    return (this.page === this.PAGE_EDIT) ? "&nbsp&nbspEdit" : "Create";
  }

  public onSubmit(): Promise<any> {

    // convert to metric if units aren't in metric
    let height = parseInt(this.createFormGroup.get("PatientHeight")?.value, 10);
    let weight = parseInt(this.createFormGroup.get("PatientWeight")?.value, 10);
    if (!isNaN(height) && this.heightUnit == this.HEIGHT_IMPERIAL) {
      height = height * 2.54;
    }
    if (!isNaN(weight) && this.weightUnit == this.WEIGHT_IMPERIAL) {
      weight = weight * 0.453592;
    }

    this.visit.visited_at = this.createFormGroup.get("VisitedAt")?.value;
    this.visit.hookup_at = this.createFormGroup.get("HookupAt")?.value;
    // this.visit.scanned_at = this.createFormGroup.get("ScannedOn")?.value;
    this.visit.comments = this.createFormGroup.get("Comments")?.value;
    this.visit.hookup_duration = this.createFormGroup.get("Duration")?.value;
    this.visit.delivery_id_type = this.createFormGroup.get("Delivery")?.value;
    this.visit.monitor_serial_number = this.createFormGroup.get("MonitorSerialNumber")?.value;
    this.visit.tech = this.createFormGroup.get("Tech")?.value;

    let patient: Patient = new Patient();
    patient.id = this.visit.patient_id;
    patient.last_name = this.createFormGroup.get("PatientLastName")?.value;
    patient.first_name = this.createFormGroup.get("PatientFirstName")?.value;
    patient.health_card = this.createFormGroup.get("PatientHealthCard")?.value;
    patient.health_card_code = this.createFormGroup.get("PatientHealthCardCode")?.value;
    patient.gender = this.createFormGroup.get("PatientGender")?.value;
    patient.birthdate = this.createFormGroup.get("PatientBirthdate")?.value;
    patient.height = height;
    patient.weight = weight;
    const patientPromise: any = this.patientService.createPatient(patient, !!patient.id);

    let clinic: Clinic = new Clinic();
    clinic.id = this.visit.clinic_id;
    clinic.name = this.createFormGroup.get("ClinicName")?.value;
    clinic.street = this.createFormGroup.get("ClinicStreet")?.value;
    clinic.city = this.createFormGroup.get("ClinicCity")?.value;
    clinic.province = this.createFormGroup.get("ClinicProvince")?.value;
    clinic.postal_code = this.createFormGroup.get("ClinicPostalCode")?.value;
    clinic.hash = clinic.name + clinic.postal_code + clinic.street;
    const clinicPromise: any = this.clinicService.createClinic(clinic, !!clinic.id);

    let doctor: Physician = new Physician();
    doctor.id = this.visit.referring_doctor_id;
    doctor.last_name = this.createFormGroup.get("ReferringDoctorLastName")?.value;
    doctor.first_initials = this.createFormGroup.get("ReferringDoctorFirstInitials")?.value;
    doctor.name = doctor.last_name + ", " + doctor.first_initials;
    const doctorPromise: any = this.physicianService.createPhysician(doctor, !!doctor.id);

    return Promise.all([patientPromise, clinicPromise, doctorPromise]).then((values: any[]) => {
      if (values[0] && values[0].length >= 0) {
        this.visit.patient_id = values[0][0].id;
      }
      if (values[1] && values[1].length >= 0) {
        this.visit.clinic_id = values[1][0].id;
      }
      if (values[2] && values[2].length >= 0) {
        this.visit.referring_doctor_id = values[2][0].id;
      }
      return this.visitService.createVisit(this.visit, !!this.visit.id);
    }).then(() => {
      this.reloadDatatable();
      this.switchPage(this.PAGE_SEARCH);
    });
  }

  public toggleHeightUnit(): void {
    this.heightUnit = (this.heightUnit == this.HEIGHT_METRIC) ? this.HEIGHT_IMPERIAL : this.HEIGHT_METRIC;
  }

  public toggleWeightUnit(): void {
    this.weightUnit = (this.weightUnit == this.WEIGHT_METRIC) ? this.WEIGHT_IMPERIAL : this.WEIGHT_METRIC;
  }

}

<div class="modal-body">
  <form>
    <div class="row">
      <div class="col">

        <form class="form-horizontal form-label-left" [formGroup]="formGroup">
          <div class="row">
            <div class="col form-group">
              <label>Last Name</label>
              <input type="text" class="form-control" placeholder="" formControlName="LastName">
            </div>
            <div class="col form-group">
              <label>First Name</label>
              <input type="text" class="form-control" placeholder="" formControlName="FirstName">
            </div>
          </div>
          <div class="row">
            <div class="col form-group required">
              <label>Health Card</label>
              <input type="text" class="form-control" placeholder="" formControlName="HealthCard">
            </div>
            <div class="col form-group">
              <label>Date of Birth</label>
              <input type="text" class="form-control" placeholder="YYYY/MM/DD" formControlName="BirthDate">
            </div>
          </div>
          <div class="row">
            <div class="col form-group required">
              <label>Hookup Date</label>
              <input type="text" class="form-control" placeholder="YYYY/MM/DD" formControlName="HookupDate">
            </div>
            <div class="col form-group">
              <label>Referring Physician</label>
              <input type="text" class="form-control" placeholder="" formControlName="ReferringDoctor" [ngbTypeahead]="searchDoctor" [resultFormatter]="searchDoctorFormatter" [inputFormatter]="searchDoctorInputFormatter">
            </div>
          </div>
          <div class="row">
            <div class="col form-group">
              <label for="modalReason">Reason</label>
              <textarea rows="3" id="modalReason" name="modalReason" class="form-control" placeholder="" formControlName="StaffNotes"></textarea>
            </div>
          </div>
        </form>
        <button type="submit" class="btn btn-primary btn-review" (click)="passBack()" [disabled]="!formGroup.valid">Submit</button>

      </div>
    </div>
  </form>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-closed',
  templateUrl: './modal-closed.component.html',
  styleUrls: ['./modal-closed.component.scss']
})
export class ModalClosedComponent implements OnInit {
  @Input() public modalResult;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }
  passBack() {this.activeModal.close(this.modalResult);}

}
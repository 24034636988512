<div class="row sub-main">

  <div class="page-title">
    <div class="title_left">
      <h3>{{title}}</h3>
    </div>
  </div>
  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="x_content">

      <br><br>

      <!-- These divs require visit to be loaded first -->
      <div *ngIf="visit$ | async as visit">

        <div class="row">
          <div class="col-12 md-width">
            <h4>Invoice - {{visit.billing_status !== "Paid" ? "Due": "Paid"}}</h4>
            <form class="form-horizontal form-label-left">
              <div style="margin-left:50px; margin-right:50px;">
                <br>
                <div class="form-group form-display row">
                  <!-- <label class="control-label col-5" for="first-name">Invoice #{{visit.billing_id}}</label> -->
                  <label class="col-5">
                      <b>{{visit.owner_name}}</b><br>
                      {{visit.owner_address}}<br>
                      Phone: {{visit.owner_telephone}}<br>
                      Fax: {{visit.owner_fax}}
                  </label>
                  <div class="col text-right">
                    <div class="row">
                      <div class="col">Date:</div>
                      <div class="col">{{formatDate(visit.billing_invoice_date)}}</div>
                    </div>
                    <div class="row">
                        <div class="col">{{visit.billing_status !== "Paid" ? "Due Date: ": "Paid On: "}}</div>
                        <div class="col">{{formatDate(visit.billing_invoice_due_date)}}</div>
                    </div>
                  </div>
                </div>
                <br>
                <table class="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Item</th>
                      <th class="text-right">Unit Cost</th>
                      <th class="text-right">Qty</th>
                      <th class="text-right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let billingItem of billingItems()">
                      <td width="20%">{{billingItem.id}}</td>
                      <td width="50%">{{billingItem.item}}</td>
                      <td width="15%" class="text-right">{{formatMoney(billingItem.rate)}}</td>
                      <td width="15%" class="text-right">{{billingItem.quantity}}</td>
                      <td width="20%" class="text-right">{{formatMoney(billingItem.amount)}}</td>
                    </tr>
                  </tbody>
                </table>
                <br>
                <div class="row">
                  <table class="table col offset-9">
                    <tbody>
                      <tr>
                        <td><b>Subtotal</b></td>
                        <td class="text-right">{{formatMoney(visit.billing_balance_pre_tax)}}</td>
                      </tr>
                      <tr>
                        <td><b>Total</b></td>
                        <td class="text-right"><b>{{formatMoney(visit.billing_balance)}}</b></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
            <form class="form-horizontal form-label-left" [hidden]="!!visit && visit.billing_status === 'Paid'" [formGroup]="priceFormGroup">
              <div class="form-group form-display row">
                <div class="col-3 form-group required">
                  <input type="text" class="form-control" formControlName="Price" placeholder="75" />
                </div>
              </div>
              <div class="form-group form-display row">
                <div class="col-3">
                  <button class="btn btn-primary btn-review" type="button" (click)="updatePrice()" [disabled]="!priceFormGroup.valid"><i class="fa fa-edit"></i> Edit Price (CAD)</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="row" [hidden]="!!visit && visit.billing_status === 'Paid'">
          <div class="col-12 md-width">
            <h4>Payment</h4>
            <form class="form-horizontal form-label-left">
              <div class="form-group form-display row">
                  <div class="col-6 form-group required">
                    <input type="text" class="form-control" [(ngModel)]="cardNumber" name="number" id="cc-number"/>
                  </div>
              </div>
              <div class="form-group form-display row">
                  <div class="col-2 form-group required">
                    <input type="text" class="form-control" [(ngModel)]="cardExpiration" name="expiration" id="cc-expiration"/>
                  </div>
                  <div class="col-2 form-group required">
                    <input type="text" class="form-control" [(ngModel)]="cardCvv" name="cvv" id="cc-cvv"/>
                  </div>
                  <div class="col-2 form-group required">
                      <input type="text" class="form-control" [(ngModel)]="cardPostal" name="postal" id="cc-postal"/>
                    </div>
              </div>
              <div class="col form-answer error-message" id="card-error"></div>
              <div class="form-group form-display row text-left">
                  <div class="col">
                    <button class="btn btn-primary btn-review" type="button" (click)="makePayment()" [disabled]="!!visit && visit.billing_status === 'Paid'"><i class="fa fa-credit-card"></i> Pay With Card</button>
                  </div>
              </div>
              <br><br>
            </form>
          </div>
      </div>

      <div *ngIf="visit$ | async as visit">

        <div class="row">
          <div class="col-12 md-width">
            <h4>Receipt</h4>
            <div class="form-horizontal form-label-left">
              <div class="form-group form-display row">
                <div class="col form-group required">
                  <input type="text" class="form-control" placeholder="Email Address" [(ngModel)]="email" id="email"/>
                </div>
              </div>
              <div class="form-group form-display row text-left">
                <div class="col">
                  <button class="btn btn-primary btn-review" type="button" (click)="emailInvoice()" [disabled]="email === ''"><i class="fa fa-envelope-o"></i> Send</button>
                  <button class="btn btn-primary btn-review" type="button" (click)="downloadInvoice()"><i class="fa fa-file-pdf-o"></i> Download</button>
                </div>
              </div>
              <div class="form-group form-display row text-left" [hidden]="emailSuccess !== true">
                <div class="col form-answer success-message" style="padding-left:40px;">Email Sent!</div>
              </div>
              <div class="form-group form-display row text-left" [hidden]="emailSuccess !== false">
                  <div class="col form-answer error-message" style="padding-left:40px;">Failed to send email</div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

  </div>
</div>

<div>
  <div class="row sub-footer">
    <div class="col-12">
      <button class="btn btn-primary btn-review" type="button" (click)="navigateTo('/billing/abpm')"><i class="fa fa-times"></i> Cancel</button>
    </div>
  </div>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col">
      <form class="form-horizontal form-label-left" [formGroup]="formGroup">

        <div class="row">
          <div class="col form-group">
            <label>Staff Notes</label>
            <input type="text" class="form-control" (contextmenu)="onRightClick($event, 'StaffNotes', '')" [ngClass]="{'field-changed' : formGroup.get('StaffNotes').dirty}" placeholder="" formControlName="StaffNotes" id="staffNotes" [(ngModel)]="createReqNotes" name="staffNotes">
          </div>
        </div>
        <div class="row">
          <div class="col form-group">
            <label>Staff Log</label>
            <textarea rows="3" class="form-control" placeholder="" formControlName="StaffLog" readonly></textarea>
          </div>
        </div>
        <div class="row" *ngIf="!disable">
          <div class="col form-group">
            <label>Requisition</label>
            <div class="row ml-3">
              <div class="col">
                <input class="form-check-input" type="checkbox" name="check0" id="check0" formControlName="CreateReq" [(ngModel)]="createReq">
                <label class="form-check-label" for="check0">{{labelText}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="createReq">
          <div class="col form-group">
            <label>Last Name</label>
            <input type="text" class="form-control" (contextmenu)="onRightClick($event, 'LastName', holter.patient_last_name)" [ngClass]="{'field-changed' : formGroup.get('LastName').dirty}" placeholder="" formControlName="LastName" >
          </div>
          <div class="col form-group">
            <label>First Name</label>
            <input type="text" class="form-control" (contextmenu)="onRightClick($event, 'FirstName', holter.patient_first_name)" [ngClass]="{'field-changed' : formGroup.get('FirstName').dirty}" placeholder="" formControlName="FirstName" >
          </div>
        </div>
        <div class="row" *ngIf="createReq">
          <div class="col form-group">
            <label>Birthdate</label>
            <input type="text" class="form-control required" (contextmenu)="onRightClick($event, 'Birthdate', holter.patient_birthdate)" [ngClass]="{'field-changed' : formGroup.get('Birthdate').dirty}" placeholder="YYYY/MM/DD" formControlName="Birthdate" >
          </div>

          <div class="col-6 form-group">
            <label>Age</label>
            <div class="row ml-3">
              <div class="col">
                <input class="form-check-input" type="radio" name="Adult" value="0" id="check22" formControlName="Adult" >
                <label class="form-check-label" for="check22">Adult (â‰¥ 18)</label>
              </div>
              <div class="col">
                <input class="form-check-input" type="radio" name="Adult" value="1" id="check23" formControlName="Adult" >
                <label class="form-check-label" for="check23">Peadiatric (â‰¤ 17)</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="createReq">
          <div class="col-4 form-group">
            <label>Health Card</label>
            <input type="text" class="form-control required" (contextmenu)="onRightClick($event, 'HealthCard', holter.patient_health_card.substring(0,10))" [ngClass]="{'field-changed' : formGroup.get('HealthCard').dirty}" placeholder="" formControlName="HealthCard" >
          </div>
          <div class="col-2 form-group">
            <label>VC</label>
            <input type="text" class="form-control" (contextmenu)="onRightClick($event, 'HealthCardVersion', holter.patient_health_card_code)" [ngClass]="{'field-changed' : formGroup.get('HealthCardVersion').dirty}" placeholder="" formControlName="HealthCardVersion" >
          </div>
          <div class="col-6 form-group">
            <label>Tech</label>
            <input type="text" class="form-control" placeholder="" formControlName="Tech" [attr.disabled]="true">
          </div>
        </div>
        <div class="row" *ngIf="createReq">
          <div class="col form-group">
            <label>Hookup Date</label>
            <input type="text" class="form-control" (contextmenu)="onRightClick($event, 'TestDate', holter.hookup_date)" [ngClass]="{'field-changed' : formGroup.get('TestDate').dirty}" placeholder="YYYY/MM/DD" formControlName="TestDate" >
          </div>
          <div class="col form-group">
            <label>Referring Doctor</label>
            <input type="text" class="form-control" [attr.disabled]="true" (contextmenu)="onRightClick($event, 'ReferringDoctor', holter.referring_doctor_name)" [ngClass]="{'field-changed' : formGroup.get('ReferringDoctor').dirty}" placeholder="" formControlName="ReferringDoctor" [ngbTypeahead]="searchReferringDoctor" [resultFormatter]="searchReferringDoctorFormatter" [inputFormatter]="searchReferringDoctorInputFormatter" >
          </div>
        </div>
        <div class="row" *ngIf="createReq">
            <div class="col form-group ">
              <label>Duration Selection</label>
              <div class="row ml-3">
                <div class="col">
                    <input class="form-check-input" type="radio" name="Duration" value="1" [(ngModel)]="testDuration" id="check17" formControlName="Duration">
                    <label class="form-check-label" for="check17">24 Hours</label>
                </div>
                <div class="col">
                    <input class="form-check-input" type="radio" name="Duration" value="2" [(ngModel)]="testDuration" id="check18" formControlName="Duration" >
                    <label class="form-check-label" for="check18">48 Hours</label>
                </div>
                <div class="col">
                  <input class="form-check-input" type="radio" name="Duration" value="3" [(ngModel)]="testDuration" id="check19" formControlName="Duration" >
                  <label class="form-check-label" for="check19">72 Hours</label>
                </div>
                <div class="col">
                  <input class="form-check-input" type="radio" name="Duration" value="14" [(ngModel)]="testDuration" id="check20" formControlName="Duration" >
                  <label class="form-check-label" for="check20">14 Days</label>
                </div>
                <div class="col">
                  <input class="form-check-input" type="radio" name="Duration" value="0" [(ngModel)]="testDuration" id="check21" formControlName="Duration" >
                  <input type="text" class="form-control" placeholder="Days" formControlName="OtherHoursText" [attr.disabled]="testDuration != '0' ? '' : null">
                </div>
              </div>
            </div>
        </div>

        <div class="row" *ngIf="createReq">
          <div class="col form-group ">
            <label>Indication Selection</label>
            <div class="row ml-3">
              <div class="col">
                  <input class="form-check-input" type="checkbox" name="check1" id="check1" formControlName="Abnormal">
                  <label class="form-check-label" for="check1">Abnormal ECG</label>
              </div>
              <div class="col">
                  <input class="form-check-input" type="checkbox" name="check2" id="check2" formControlName="Afib" >
                  <label class="form-check-label" for="check2">A-fib Rate Control</label>
              </div>
            </div>
            <div class="row ml-3">
              <div class="col">
                  <input class="form-check-input" type="checkbox" name="check3" id="check3" formControlName="Palpitations">
                  <label class="form-check-label" for="check3">Palpitations</label>
              </div>
              <div class="col">
                  <input class="form-check-input" type="checkbox" name="check4" id="check4" formControlName="PostStroke" >
                  <label class="form-check-label" for="check4">Post Stroke / TIA</label>
              </div>
            </div>
            <div class="row ml-3">
              <div class="col">
                  <input class="form-check-input" type="checkbox" name="check5" id="check5" formControlName="Syncope">
                  <label class="form-check-label" for="check5">Syncope / Fainting Spells</label>
              </div>
              <div class="col">
                  <input class="form-check-input" type="checkbox" name="check6" id="check6" formControlName="AtrialArrhythmia" >
                  <label class="form-check-label" for="check6">Atrial Arrhythmia</label>
              </div>
            </div>
            <div class="row ml-3">
              <div class="col">
                  <input class="form-check-input" type="checkbox" name="check7" id="check7" formControlName="Presyncope">
                  <label class="form-check-label" for="check7">Presyncope / Light-headedness</label>
              </div>
              <div class="col">
                  <input class="form-check-input" type="checkbox" name="check8" id="check8" formControlName="Medication" >
                  <label class="form-check-label" for="check8">Medication Effect</label>
              </div>
            </div>
            <div class="row ml-3">
              <div class="col">
                  <input class="form-check-input" type="checkbox" name="check9" id="check9" formControlName="ChestPain">
                  <label class="form-check-label" for="check9">Chest Pain / Shortness of Breath</label>
              </div>
              <div class="col">
                  <input class="form-check-input" type="checkbox" name="check10" id="check10" formControlName="Ventricular" >
                  <label class="form-check-label" for="check10">Ventricular Arrhythmia</label>
              </div>
            </div>
            <div class="row ml-3">
              <div class="col">
                  <input class="form-check-input" type="checkbox" name="check11" id="check11" formControlName="Fatigue">
                  <label class="form-check-label" for="check11">Fatigue / Weakness</label>
              </div>
              <div class="col">
                  <input class="form-check-input" type="checkbox" name="check12" id="check12" formControlName="Pacemaker" >
                  <label class="form-check-label" for="check12">PACEMAKER</label>
              </div>
            </div>
            <div class="row ml-3">
              <div class="col">
                  <input class="form-check-input" type="checkbox" name="check13" id="check13" formControlName="VVI">
                  <label class="form-check-label" for="check13">VVI</label>
              </div>
              <div class="col">
                  <input class="form-check-input" type="checkbox" name="check14" id="check14" formControlName="DDD" >
                  <label class="form-check-label" for="check14">DDD</label>
              </div>
            </div>
            <div class="row ml-3">
              <div class="col">
                  <input class="form-check-input" type="checkbox" name="check15" id="check15" formControlName="AtrialFibrillation">
                  <label class="form-check-label" for="check15">R/O Atrial Fibrillation / Flutter</label>
              </div>
              <div class="col">
                <input class="form-check-input" type="checkbox" name="check16" id="check16" formControlName="ROArrhythmia">
                <label class="form-check-label" for="check16">R/O Arrhythmia</label>
              </div>
            </div>
            <div class="row ml-3">
              <div class="col">
                <input class="form-check-input" type="checkbox" name="check17" id="check17" [(ngModel)]="otherIndication" formControlName="Other" >
                <input type="text" class="form-control" placeholder="Indication" formControlName="OtherIndicationsText" [attr.disabled]="!otherIndication ? '' : null">
              </div>
              <div class="col">

              </div>
            </div>
          </div>
        </div>


      </form>
      <div class="col text-center">
        <button type="submit" class="btn btn-primary btn-review" (click)="passBack()">Submit</button>
      </div>
    </div>
  </div>
</div>
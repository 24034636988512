import { PhysicianComponent } from "./components/physician/physician.component";
import { StaffComponent } from "./components/staff/staff.component";
import { BillingComponent } from "./components/billing/billing.component";
import { BillingDetailComponent } from "./components/billing-detail/billing-detail.component";
import { PatientComponent } from "./components/patient/patient.component";
import { FaxComponent } from "./components/fax/fax.component";
import { AbpmComponent } from "./components/abpm/abpm.component";
import { HolterComponent } from "./components/holter/holter.component";
import { HolterEditComponent } from "./components/holter-edit/holter-edit.component";
import { HolterVisitComponent } from "./components/holter-visit/holter-visit.component";
import { HolterPendingComponent } from "./components/holter-pending/holter-pending.component";
import { HolterReservedComponent } from "./components/holter-reserved/holter-reserved.component";
import { HolterReadingComponent } from "./components/holter-reading/holter-reading.component";
import { HolterSearchComponent } from "./components/holter-search/holter-search.component";
import { HolterDetailComponent } from "./components/holter-detail/holter-detail.component";
import { HolterDetailDebugComponent } from "./components/holter-detail-debug/holter-detail-debug.component";
import { HolterDetailMedfluxComponent } from "./components/holter-detail-medflux/holter-detail-medflux.component";
import { HolterDetailReportComponent } from "./components/holter-detail-report/holter-detail-report.component";
import { HelpComponent } from "./components/help/help.component";
import { LoginComponent } from "./components/login/login.component";
import { VisitComponent } from "./components/visit/visit.component";
import { ReportsComponent } from "./components/reports/reports.component";
import { AnalyticsComponent } from "./components/analytics/analytics.component";
import { AbpmStatisticalComponent } from "./components/abpm-statistical/abpm-statistical.component";
import { AbpmDetailComponent} from "./components/abpm-detail/abpm-detail.component";
import { AbpmInterpretationComponent} from "./components/abpm-interpretation/abpm-interpretation.component";
import { AbpmGraphicalComponent} from "./components/abpm-graphical/abpm-graphical.component";
import { AbpmRawComponent} from "./components/abpm-raw/abpm-raw.component";
import { InboxComponent } from "./components/inbox/inbox.component";
import { InboxMainComponent } from "./components/inbox-main/inbox-main.component";
import { BillingHolterComponent } from './components/billing-holter/billing-holter.component';
import { BillingAbpmComponent } from './components/billing-abpm/billing-abpm.component';
import { FaxLegacyComponent } from './components/fax-legacy/fax-legacy.component';
import { FaxRepairComponent } from './components/fax-repair/fax-repair.component';
import { FaxHolterComponent } from './components/fax-holter/fax-holter.component';
import { HolterRequisitionComponent } from './components/holter-requisition/holter-requisition.component';
import { FaxDirectoryComponent } from './components/fax-directory/fax-directory.component';
import { BillingInvoiceComponent } from './components/billing-invoice/billing-invoice.component';
import { DebugComponent } from './components/debug/debug.component';
import { DebugHolterComponent } from './components/debug-holter/debug-holter.component';
import { DebugAbpmComponent } from './components/debug-abpm/debug-abpm.component';
import { DebugSparkComponent } from './components/debug-spark/debug-spark.component';
import { DebugRequisitionComponent } from './components/debug-requisition/debug-requisition.component';
import { DebugDirectoryComponent } from './components/debug-directory/debug-directory.component';
import { DebugSparkMonitorComponent } from './components/debug-spark-monitor/debug-spark-monitor.component';
import { LogisticsComponent } from './components/logistics/logistics.component';
import { LogisticsHomeHolterComponent } from './components/logistics-home-holter/logistics-home-holter.component';
import { LogisticsFollowUpComponent } from './components/logistics-follow-up/logistics-follow-up.component';
import { FaxClinicDoctorDirectoryComponent } from './components/fax-clinic-doctor-directory/fax-clinic-doctor-directory.component';
import { BillingHolterLogComponent } from './components/billing-holter-log/billing-holter-log.component';
import { ResearchSearchDocumentsComponent } from './components/research-search-documents/research-search-documents.component';
import { LogisticsMedtraceComponent } from './components/logistics-medtrace/logistics-medtrace.component';
import { LogisticsMonitorTrafficComponent } from './components/logistics-monitor-traffic/logistics-monitor-traffic.component';

import { AuthGuardService } from "./providers/auth-guard.service";

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    {
        path: "",
        component: AbpmComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: "directory",
        component: PhysicianComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: "staff",
        component: StaffComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: "billing",
        component: BillingComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: "invoice",
                component: BillingInvoiceComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "holter",
                component: BillingHolterComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "holter-log",
                component: BillingHolterLogComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "abpm",
                component: BillingAbpmComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "abpm/:id",
                component: BillingDetailComponent,
                canActivate: [AuthGuardService]
            },
        ]
    },
    {
        path: "debug",
        component: DebugComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: "holter",
                component: DebugHolterComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "abpm",
                component: DebugAbpmComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "spark",
                component: DebugSparkComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "spark-monitor",
                component: DebugSparkMonitorComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "req",
                component: DebugRequisitionComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "directory",
                component: DebugDirectoryComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "research",
                component: ResearchSearchDocumentsComponent,
                canActivate: [AuthGuardService]
            },
        ]
    },
    {
        path: "logistics",
        component: LogisticsComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: "home-holter",
                component: LogisticsHomeHolterComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "monitor-traffic",
                component: LogisticsMonitorTrafficComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "follow-up",
                component: LogisticsFollowUpComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "medtrace",
                component: LogisticsMedtraceComponent,
                canActivate: [AuthGuardService]
            },
        ]
    },
    {
        path: "patient",
        component: PatientComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: "visit",
        component: VisitComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: "fax",
        component: FaxComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: "legacy",
                component: FaxLegacyComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "repair",
                component: FaxRepairComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "holter",
                component: FaxHolterComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "directory",
                component: FaxDirectoryComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "clinic-doctor-directory",
                component: FaxClinicDoctorDirectoryComponent,
                canActivate: [AuthGuardService]
            },
        ]
    },
    {
        path: "abpm",
        component: AbpmComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: "inbox",
        component: InboxComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: "main",
                component: InboxMainComponent,
                canActivate: [AuthGuardService]
            },
        ]
    },
    {
        path: "abpm/detail",
        component: AbpmDetailComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: "statistical/:id",
                component: AbpmStatisticalComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "interpretations/:id",
                component: AbpmInterpretationComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "graphical/:id",
                component: AbpmGraphicalComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "raw/:id",
                component: AbpmRawComponent,
                canActivate: [AuthGuardService]
            },
        ]
    },
    {
        path: "holter",
        component: HolterComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: "pending",
                component: HolterPendingComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "search",
                component: HolterSearchComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "editing",
                component: HolterEditComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "visit",
                component: HolterVisitComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "reserved",
                component: HolterReservedComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "reading",
                component: HolterReadingComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "requisition",
                component: HolterRequisitionComponent,
                canActivate: [AuthGuardService]
            }
        ]
    },
    {
        path: "holter/detail",
        component: HolterDetailComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: "debug/:id",
                component: HolterDetailDebugComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "medflux/:id",
                component: HolterDetailMedfluxComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: "report/:id",
                component: HolterDetailReportComponent,
                canActivate: [AuthGuardService]
            },
        ]
    },
    {
        path: "analytics",
        component: AnalyticsComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: "reports",
        component: ReportsComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: "help",
        component: HelpComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: "login",
        component: LoginComponent,
    },
    {
        path: '**',
        component: LoginComponent,
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule]
})
export class AppRoutingModule { }

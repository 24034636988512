<div>
    <div class="row sub-main">

      <div class="page-title">
        <div class="title_left">
          <h3>Holter - Reading</h3>
        </div>
      </div>
      <div class="clearfix"></div>

      <div class="x_panel">
        <div class="x_content">

          <div class="row split-container">
            <as-split direction="horizontal" (dragEnd)="onDragEnd($event)">

              <as-split-area [size]="pdfSizeLeft">
                <div class="col">
                  <div *ngIf="!showPdfPreview">
                    <div class="row">
                      <div class="col">
                        <label>Search</label>
                        <input type="text" class="form-control search-bar" placeholder="Start typing to search" id="search-bar">
                       </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <select class="form-control" data-live-search="true" [(ngModel)]="statusFilter" (change)="statusFilterChanged()">
                          <option>All</option>
                          <option>Rejected</option>
                          <option>Sent</option>
                          <option>To Read</option>
                          <option>Priority</option>
                        </select>
                      </div>
                      <div class="col"></div>
                      <div class="col-md-auto">
                        <div class="custom-control custom-switch">
                          <input class="custom-control-input" type="checkbox" id="switch2" [(ngModel)]="batchMode" (change)="saveBatchMode()" >
                          <label class="custom-control-label" for="switch2">Batch Mode</label>
                        </div>
                      </div>
                      <div class="col">
                        <div class="custom-control custom-switch">
                          <input class="custom-control-input" type="checkbox" id="switch1" [(ngModel)]="prioritySort" (click)="applyPrioritySort()" [disabled]="statusFilter !== 'All'">
                          <label class="custom-control-label" for="switch1">Show Priority First</label>
                        </div>
                      </div>
                    </div>
                    <br>

                    <table datatable [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover" id="reading-table">
                      <thead>
                        <tr>
                          <th>Scan Date</th>
                          <th>Patient Name</th>
                          <th>Signed At</th>
                          <th>Files</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div *ngIf="showPdfPreview && currentHolter">
                    <button class="btn btn-primary btn-review" type="button" (click)="showTracings()" [disabled]="showPdfPreview === 'tracings'">Tracings</button>
                    <button class="btn btn-primary btn-review" type="button" (click)="showRequisition()" [disabled]="showPdfPreview === 'requisition' || !currentHolter.requisition_file_name">Requisition</button>
                     <button class="btn btn-primary btn-review" type="button" (click)="showReadingBatch()" [disabled]="showPdfPreview === 'batch' || !batchMode">Batched Report</button>
                    <div class="preview-panel">
                      <pdf-viewer id="pdf-viewer" [autoresize]="true" [show-all]="true" (after-load-complete)="afterLoadComplete($event)"[src]="pdfSrcLeft" [render-text]="false" [original-size]="false" [fit-to-page]="true" [(page)]="pageNumber"></pdf-viewer>
                    </div>
                    <div class="row">
                      <div class="col">
                        <button class="btn btn-primary btn-narrow" type="button" (click)="closeTracings()">Back</button>
                      </div>
                      <div class="col text-right">
                        <button class="btn btn-primary btn-narrow" type="button" (click)="flipPage(false)">Previous Page</button>
                        <button class="btn btn-primary btn-narrow" type="button" (click)="flipPage(true)">Next Page</button>
                      </div>
                    </div>
                  </div>
                </div>
              </as-split-area>

              <as-split-area [size]="pdfSizeRight">
                <div class="row" *ngIf="currentHolter && !isSkipToReading">
                  <div class="col text-right">
                    <button class="btn btn-primary btn-review" type="button" (click)="toggleReport()" [disabled]="!currentHolter"><i class="fa" [class.fa-pencil]="!editingReport" [class.fa-file]="editingReport"></i> {{editingReport ? "Final Report" : "Edit"}}</button>
                  </div>
                </div>
                <div class="col info-panel">

                  <div *ngIf="currentHolter && !editingReport && pdfSrc && !loadingPdf">
                    <pdf-viewer [src]="pdfSrc" [autoresize]="true" [render-text]="false" [original-size]="false" [fit-to-page]="true" style="display: block;"></pdf-viewer>
                  </div>

                  <form *ngIf="currentHolter && editingReport" class="form-horizontal form-label-left" [formGroup]="formGroupReport">
                    <div class="row">

                      <div class="col-12 md-width">

                        <h4>Holter Monitor Report</h4>
                        <div class="form-horizontal form-label-left form-group">
                          <div class="form-group form-display row">
                            <label class="control-label col-2" for="first-name">Patient's Name </label>
                            <div class="col form-answer">{{(currentHolter.patient_last_name && currentHolter.patient_last_name) ? (currentHolter.patient_last_name + ', ' + currentHolter.patient_first_name) : '-'}}</div>
                            <label class="control-label col-2" for="last-name">Date of Test </label>
                            <div class="col form-answer">{{currentHolter.scan_date || '-'}}</div>
                          </div>
                          <div class="form-group form-display row">
                            <label class="control-label col-2" for="first-name">Date of Birth </label>
                            <div class="col form-answer">{{currentHolter.patient_birthdate || '-'}}</div>
                            <label class="control-label col-2" for="last-name">Referring Physician </label>
                            <div class="col form-answer">{{currentHolter.referring_doctor_name || '-'}}</div>
                          </div>
                          <div class="form-group form-display row">
                            <label class="control-label col-2" for="first-name">HIN </label>
                            <div class="col form-answer">{{buildHIN(currentHolter) || '-'}}</div>
                            <label class="control-label col-2" for="last-name"></label>
                            <div class="col form-answer"></div>
                          </div>
                          <br>
                          <div class="form-group form-display row">
                            <label class="control-label col-2" for="first-name">Indications </label>
                            <div class="col form-answer">{{getMedfluxData(currentHolter, 'indications') || '-'}}</div>
                          </div>
                          <div class="form-group form-display row">
                            <label class="control-label col-2" for="first-name">12 Lead ECG </label>
                            <div class="col form-group">
                              <input type="text" class="form-control" placeholder="" formControlName="LeadECG">
                            </div>
                          </div>
                        </div>
                        <br>

                        <div class="form-group">
                          <h4>General Comments</h4>
                          <textarea #generalComment1Div class="form-control" rows="1" formControlName="GeneralComment1"></textarea><br>
                          <textarea #generalComment2Div class="form-control" rows="1" formControlName="GeneralComment2"></textarea><br>
                          <textarea #generalComment3Div class="form-control" rows="1" formControlName="GeneralComment3"></textarea><br>
                          <textarea #generalComment4Div class="form-control" rows="1" formControlName="GeneralComment4"></textarea><br>
                          <textarea #generalComment5Div class="form-control" rows="1" formControlName="GeneralComment5"></textarea><br>
                          <br>

                          <h4>Diary Comments</h4>
                          <p><i>Patient diary was reviewed by analyst</i></p>
                          <textarea #specificComment1Div class="form-control" rows="1" formControlName="SpecificComment1"></textarea><br>
                          <textarea #specificComment2Div class="form-control" rows="1" formControlName="SpecificComment2"></textarea><br>
                          <textarea #specificComment3Div class="form-control" rows="1" formControlName="SpecificComment3"></textarea><br>
                          <textarea #specificComment4Div class="form-control" rows="1" formControlName="SpecificComment4"></textarea><br>
                          <textarea #specificComment5Div class="form-control" rows="1" formControlName="SpecificComment5"></textarea><br>
                          <br>

                          <h4>Summary</h4>
                          <textarea #summary1Div class="form-control" rows="1" formControlName="Summary1"></textarea><br>
                          <textarea #summary2Div class="form-control" rows="1" formControlName="Summary2"></textarea><br>
                          <textarea #summary3Div class="form-control" rows="1" formControlName="Summary3"></textarea><br>
                          <textarea #summary4Div class="form-control" rows="1" formControlName="Summary4"></textarea><br>
                          <textarea #summary5Div class="form-control" rows="1" formControlName="Summary5"></textarea><br>
                          <textarea #additionalComment1Div class="form-control" rows="1" formControlName="AdditionalComment1" placeholder="Additional notes from physician if necessary"></textarea><br>
                          <br>

                          <!--
                          <h4>Additional Comments</h4>
                          <textarea #additionalComment1Div class="form-control" rows="1" formControlName="AdditionalComment1"></textarea><br>
                          <textarea #additionalComment2Div class="form-control" rows="1" formControlName="AdditionalComment2"></textarea><br>
                          <textarea #additionalComment3Div class="form-control" rows="1" formControlName="AdditionalComment3"></textarea><br>
                          <textarea #additionalComment4Div class="form-control" rows="1" formControlName="AdditionalComment4"></textarea><br>
                          <textarea #additionalComment5Div class="form-control" rows="1" formControlName="AdditionalComment5"></textarea><br>
                          -->

                        </div>

                        <br>

                        <form class="form-horizontal form-label-left">
                          <div class="form-group form-display row">
                            <label class="control-label col-1" for="first-name">Analyst: </label>
                            <div class="col form-answer">{{getMedfluxData(currentHolter, 'analyst') || '-'}}</div>
                          </div>
                          <div class="form-group form-display row">
                            <label class="control-label col-1" for="first-name">Editor: </label>
                            <div class="col form-answer">{{getMedfluxData(currentHolter, 'editor') || '-'}}</div>
                          </div>
                          <div *ngIf="currentHolter && currentHolter.state === 'FAXING'" class="form-group form-display row">
                            <div class="col text-right">{{currentHolter.reading_doctor_name || ''}}</div>
                          </div>
                        </form>



                      </div>
                    </div>
                  </form>

                </div>
              </as-split-area>

            </as-split>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="row sub-footer">
        <div class="col-2">
          <div class="row" *ngIf="holterIdx">
            <div class="col text-left progress-text">
              {{holterIdx}}
            </div>
          </div>
          <div class="row" *ngIf="holterCount">
            <div class="col text-left progress-text">
              {{holterCount}}
            </div>
          </div>
        </div>
        <div class="col-8">
          <button class="btn btn-primary btn-review" type="button" (click)="openRejectionModal()" [disabled]="!currentHolter"><i class="fa fa-times"></i> Reject</button>
          <button class="btn btn-primary btn-review" type="button" (click)="save(false)" [disabled]="!currentHolter || wasSaved || !editingReport" *ngIf="!isSkipToReading"><i class="fa fa-floppy-o"></i> Save</button>
          <button class="btn btn-primary btn-review" type="button" [hidden]="!currentHolter || isPrepared" (click)="save(true)" placement="top" ngbTooltip="Ctrl+Enter" [disabled]="!currentHolter || statusFilter == 'Rejected'"><i class="fa fa-asterisk"></i> Submit</button>
          <button class="btn btn-primary btn-review" type="button" [hidden]="!currentHolter || !isPrepared || statusFilter == 'Sent'" (click)="send()" placement="top" ngbTooltip="Ctrl+Enter" [disabled]="!currentHolter || statusFilter == 'Rejected'"><i class="fa fa-check"></i> Submit</button>
        </div>
        <div class="col-2">
        </div>
        <div class="col-2">
        </div>
      </div>
    </div>

  </div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { FeatureToggleService } from "../../providers/feature-toggle.service";

@Component({
  selector: 'app-home',
  templateUrl: './fax.component.html',
  styleUrls: ['./fax.component.scss']
})
export class FaxComponent implements OnInit {

  public showLegacyTab$: Observable<boolean>;
  public showRepairTab$: Observable<boolean>;
  public showHolterTab$: Observable<boolean>;
  public showDirectoryTab$: Observable<boolean>;
  public showClinicDoctorDirectoryTab$: Observable<boolean>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private featureToggleService: FeatureToggleService
  ) { }

  ngOnInit(): void {
    this.showLegacyTab$ = this.route.paramMap.pipe(switchMap(() => this.featureToggleService.showHolterSubtab("/fax/legacy")));
    this.showRepairTab$ = this.route.paramMap.pipe(switchMap(() => this.featureToggleService.showHolterSubtab("/fax/repair")));
    this.showHolterTab$ = this.route.paramMap.pipe(switchMap(() => this.featureToggleService.showHolterSubtab("/fax/holter")));
    // this.showDirectoryTab$ = this.route.paramMap.pipe(switchMap(() => this.featureToggleService.showHolterSubtab("/fax/directory")));
    this.showClinicDoctorDirectoryTab$ = this.route.paramMap.pipe(switchMap(() => this.featureToggleService.showHolterSubtab("/fax/clinic-doctor-directory")));
  }

  public navigateTo(url: string): void {
    this.router.navigateByUrl(url);
  }

  public urlContains(str: string): boolean {
    return this.router.url.indexOf(str) > -1;
  }

}
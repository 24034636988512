import * as moment from "moment";

export function formatLocaleDateSingle(toFormat: any): any {
  const datetime: any = moment(toFormat);
  if (datetime.isValid()) {
    return datetime.format("YYYY/MM/DD");
  }
  return toFormat;
}

// formats the time of the data that is returned to local time
export function formatLocaleTime(entry: any): any {
  const dateTimeKeys: string[] = [
    "abpm:uploaded_at",
    "fax:received_at",
    "fax:attempt_at",
    "fax:success_at",
    "employee:updated_at",
    "holter:scan_date",
    "holter:updated_at",
    "holter:signed_off_at",
    "holter:synced_at",
    "holter:sync_requested_at",
    "holter:addressed_at",
    "holter:called_at",
    "holter:beginning_date",
    "holter:last_called",
    "requisition:received_at",
    "requisition:created_at",
    "requisition:updated_at",
    "doctor:updated_at",
    "clinic:updated_at",
    "spark_device:updated_at",
    "spark_device:created_at",
    "spark_upload:updated_at",
    "spark_upload:created_at",
  ];
  for (const key of dateTimeKeys) {
    if (key in entry) {
      const datetime: any = moment(entry[key]);
      if (datetime.isValid()) {
        entry[key] = datetime.format("YYYY/MM/DD HH:mm");
      }
    }
  }
  const dateKeys: string[] = [
    "visit:hookup_at",
    "abpm:signed_off_at",
    "patient:birthdate",
    "visit:visited_at",
    "visit:scanned_at",
    "holter:billing_date",
    "holter:hookup_date",
    "holter:billed_at",
    "requisition:date",
    "medtrace:hookup_date",
    "medtrace:scan_date",
    "medtrace:return_date",
    "medtrace:beginning_date",
  ];
  for (const key of dateKeys) {
    if (key in entry) {
      const datetime: any = moment(entry[key]);
      if (datetime.isValid()) {
        entry[key] = datetime.format("YYYY/MM/DD");
      }
    }
  }
  return entry;
}

export function formatServerTime(localTime: any): any {
  return moment(localTime).utc().format("YYYY-MM-DD HH:mm:ss");
}

export function formatLocalTime(localTime: any, showTime: boolean = false): any {
  return showTime ?
    moment(localTime).format("YYYY-MM-DD HH:mm:ss") :
    moment(localTime).format("YYYY-MM-DD");
}

export function formatFaxNumbersMultiple(rawNumbers: string[]): any {
  try {
    let parsedNumbers = [];
    for(const rawNumber of rawNumbers) {
      if (!rawNumber) {
        continue;
      }
      parsedNumbers = parsedNumbers.concat(JSON.parse(rawNumber))
    }
    let output = "";
    for (const number of parsedNumbers) {
      output = number + "\n" + output;
    }
    return output;
  } catch {
    return null;
  }
}

export function formatNotesMultiple(rawNotes: string[]): any {
  try {
    let parsedNotes = [];
    for(const rawNote of rawNotes) {
      if (!rawNote) {
        continue;
      }
      parsedNotes = parsedNotes.concat(JSON.parse(rawNote))
    }
    let output = "";
    for (const note of parsedNotes) {
      const dateString = note.time ? new Date(note.time * 1000).toLocaleString("en") : "Unknown Time"
      const author = note.author || "";
      output = "[" + dateString + "] " + author + ": " + note.note + "\n" + output;
    }
    return output;
  } catch {
    return null;
  }
}

export function formatNotes(rawNotes: string): any {
  try {
    const parsedNotes = JSON.parse(rawNotes);
    let output = "";
    for (const note of parsedNotes) {
      const dateString = note.time ? new Date(note.time * 1000).toLocaleString("en") : "Unknown Time"
      const author = note.author || "";
      output = "[" + dateString + "] " + author + ": " + note.note + "\n" + output;
    }
    return output;
  } catch {
    return rawNotes;
  }
}

export function formatNotesLast(rawNotes: string): any {
  try {
    const parsedNotes = JSON.parse(rawNotes);
    let output = "";
    const dateString = parsedNotes[parsedNotes.length-1].time ? new Date(parsedNotes[parsedNotes.length-1].time * 1000).toLocaleString("en") : "Unknown Time"
    const author = parsedNotes[parsedNotes.length-1].author || "";
    output = "[" + dateString + "] " + author + ": " + parsedNotes[parsedNotes.length-1].note;
    return output;
  } catch {
    return rawNotes;
  }
}

export function formatNotesOptions(rawNotes: string): any {
  try {
    const parsedNotes = JSON.parse(rawNotes);
    const output = [];
    for (const note of parsedNotes) {
      output.push(note.note);
    }
    return output;
  } catch {
    return rawNotes;
  }
}

export function formatNotesSingle(rawNotes: string): any {
  const options = formatNotesOptions(rawNotes)
  return (!!options && options.length > 0) ? options[0] : null;
}
export class Clinic {
    public id: number | null = null;
    public name: string | null = null;
    public street: string | null = null;
    public city: string | null = null;
    public province: string | null = null;
    public zone: string | null = null;
    public postal_code: string | null = null;
    public fax: string | null = null;
    public hash: string | null = null;
}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import {Observable, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';

import { AuthService } from "../../providers/auth.service";
import { PhysicianService } from "../../providers/physician.service";
import { ClinicService } from "../../providers/clinic.service";


import { formatLocaleDateSingle, formatNotesMultiple, formatNotes  } from "../../utils/format";

@Component({
  selector: 'app-modal-clinic-doctor',
  templateUrl: './modal-clinic-doctor.component.html',
  styleUrls: ['./modal-clinic-doctor.component.scss']
})
export class ModalClinicDoctorComponent implements OnInit {
  @Input() public clinic;
  @Input() public doctor;
  @Input() public modifyType;
  @Input() public create;
  @Input() public add;
  @Input() public focusCount;

  public formGroup: FormGroup;
  public invalidReferring: boolean = false;
  public createClinicNote: boolean = false;
  public createDoctorNote: boolean = false;
  public disableArchive: boolean = false;
  public disableCreate: boolean = false;
  public enableBillingNumber: boolean = false;
  public verify: boolean = false;
  public note: string = '';
  public noFaxNumber: boolean = false;

  public createFax: boolean = false;
  public textDoctorNotes: string = '';
  public textClinicNotes: string = '';
  public textClinicAdditionalFax: string = '';
  public faxNumber: string = '';

  public titleString: string = '';
  public archiveString: string = 'Archive';
  public titleClass: string = 'title-selection-blue';
  public buttonClass: string = 'title-selection-blue';

  public faxNumberAuto : any[] = [];
  public faxNumberString: number = null;

  public doctorSearch: string = '';

  public errorStrings: any = {};
  private subscr:Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    private physicianService: PhysicianService,
    private clinicService: ClinicService,
    private authService: AuthService,
  ) {
    this.formGroup = new FormGroup({
      ClinicName: new FormControl("", [ ]),
      ClinicFax: new FormControl("", [ ]),
      ClinicAdditionalFax: new FormControl("", [ ]),
      ClinicNoFaxNumber: new FormControl("", [ ]),
      ClinicPhone: new FormControl("", [Validators.required, Validators.pattern(/^\d{10}$/)]),
      ClinicStreet: new FormControl("", [ ]),
      ClinicCity: new FormControl("", [ ]),
      ClinicProvince: new FormControl("", [ ]),
      ClinicZone: new FormControl("", [ ]),
      ClinicPostal: new FormControl("", [ ]),
      ClinicBillingCode: new FormControl("", [ ]),
      ClinicID: new FormControl("", [ ]),
      DoctorLastName: new FormControl("", [ ]),
      DoctorFirstName: new FormControl("", [ ]),
      DoctorRoles: new FormControl("", [ ]),
      DoctorBillingCode: new FormControl("", [ ]),
      DoctorBillingNumber: new FormControl("", [ ]),
      CreateClinicNote: new FormControl("", [ ]),
      CreateDoctorNote: new FormControl("", [ ]),
      ClinicNote: new FormControl("", [ ]),
      DoctorNote: new FormControl("", [ ]),
      ClinicNoteLog: new FormControl("", [ ]),
      DoctorNoteLog: new FormControl("", [ ]),

      DoctorSearch: new FormControl("", [ ]),
      ClinicSearch: new FormControl("", [ ]),
    });
  }

  ngOnInit(): void {
    if (this.clinic) {
      this.populateClinic();
      this.noFaxNumber = this.clinic.flag_no_fax;
      this.getFaxes();
    }
    if (this.doctor) {
      this.populateDoctor();
      this.getFaxes();
    }
    if (this.focusCount || this.create) {
      this.titleClass = 'title-selection-blue';
      this.buttonClass = 'btn btn-primary btn-narrow btn-primary-blue'
    }
    else {
      this.titleClass = 'title-selection-orange';
      this.buttonClass = 'btn btn-primary btn-narrow'
    }

    if (this.clinic) {
      if (this.modifyType == 'doctors') {
        this.titleString = 'Add Doctor';
        this.enableBillingNumber = true;
      }
      else {
        this.titleString = 'Modify Clinic';
        if (this.clinic["clinic_assignment_hidden_at"]) {
          this.archiveString = 'Restore';
        }
        else if (this.clinic.is_hidden_at) {
          this.disableArchive = true;
        }

      }
    }
    else if(!this.doctor && this.modifyType == 'clinics') {
      this.titleString = 'Create Clinic';
    }

    if (this.doctor) {
      if (!this.doctor.billing_number) {
        this.enableBillingNumber = true;
      }
      if (this.modifyType == 'clinics') {
        this.titleString = 'Add Clinic';
      }
      else {
        this.titleString = 'Modify Doctor';
        if (this.doctor["clinic_assignment_hidden_at"]) {
          this.archiveString = 'Restore';
        }
        else if (this.doctor.is_hidden_at) {
          this.disableArchive = true;
        }
      }
    }
    else if (!this.clinic && this.modifyType == 'doctors') {
      this.titleString = 'Create Doctor'
    }
    this.subscr = this.formGroup.get("DoctorBillingNumber")?.valueChanges.subscribe(event => this.verifyBillingNumberObservable(event, this));

  }

  ngOnDestroy() {
    this.subscr.unsubscribe();
  }

  private populateClinic() {
    this.formGroup.reset();
    this.formGroup.patchValue({
      ClinicName: this.clinic.name,
      ClinicFax: this.clinic.fax,
      ClinicNoFaxNumber: this.clinic.flag_no_fax,
      ClinicPhone: this.clinic.phone_number,
      ClinicStreet: this.clinic.street,
      ClinicCity: this.clinic.city,
      ClinicProvince: this.clinic.province,
      ClinicZone: this.clinic.zone,
      ClinicPostal: this.clinic.postal_code,
      ClinicBillingCode: this.clinic.billing_code,
      ClinicID: this.clinic.id,
      ClinicNoteLog: formatNotes(this.clinic.notes),
    });
  }

  private populateDoctor() {
    this.formGroup.reset();
    this.formGroup.patchValue({
      DoctorLastName: this.doctor.last_name,
      DoctorFirstName: this.doctor.first_name,
      DoctorRoles: this.doctor.roles,
      DoctorBillingCode: this.doctor.billing_code,
      DoctorBillingNumber: this.doctor.billing_number,
      DoctorNoteLog: formatNotes(this.doctor.notes),
    });
  }

  public verifyArchive() {
    this.verify = true;
  }

  public addFaxNumber() {
    let fax_num = this.formGroup.get("ClinicAdditionalFax")?.value;
    if (typeof fax_num === "object") {
      let fax = fax_num.clinic.fax + " - " + fax_num.name;
      if (this.faxNumberAuto.indexOf(fax) == -1){
        this.faxNumberAuto.push(fax);
      }
    }
    else {
      // if (this.faxNumberAuto.indexOf(fax_num) == -1 && this.formGroup.get("ClinicFax")?.value != fax_num){
      if (this.faxNumberAuto.indexOf(fax_num) == -1){
        this.faxNumberAuto.push(fax_num);
      }
    }
    this.formGroup.patchValue({
      ClinicAdditionalFax: ""
    });
  }

  public removeFaxNumber() {
    const index: number = this.faxNumberAuto.indexOf(this.faxNumberString);
    if (index !== -1) {
      this.faxNumberAuto.splice(index, 1);
    }
  }

  public selectedFaxNumber(item){
    this.faxNumberString = item;
  }

  public getFaxes(){
    this.faxNumberAuto = [];
    if (this.clinic?.clinic_assignment_additional_fax_numbers){
      this.faxNumberAuto = JSON.parse(this.clinic.clinic_assignment_additional_fax_numbers);
    }
    if (this.doctor?.clinic_assignment_additional_fax_numbers){
      this.faxNumberAuto = JSON.parse(this.doctor.clinic_assignment_additional_fax_numbers);
    }
    this.faxNumberAuto.sort((a, b) => a.localeCompare(b));
  }

  public passBack(action) {
    let data = {};
    if (this.modifyType == 'clinics'){
      data = {
        clinicName: this.formGroup.get("ClinicName")?.value,
        clinicFax: this.formGroup.get("ClinicFax")?.value,
        clinicAdditionalFax: this.faxNumberAuto,
        clinicPhone: this.formGroup.get("ClinicPhone")?.value,
        clinicStreet: this.formGroup.get("ClinicStreet")?.value,
        clinicCity: this.formGroup.get("ClinicCity")?.value,
        clinicProvince: this.formGroup.get("ClinicProvince")?.value,
        clinicZone: this.formGroup.get("ClinicZone")?.value,
        clinicPostal: this.formGroup.get("ClinicPostal")?.value,
        clinicBillingCode: this.formGroup.get("ClinicBillingCode")?.value,
        clinicNote: this.textClinicNotes,
        clinicNoFax: this.noFaxNumber,
      }
      if (action == 'add'){
        data['clinicID'] = this.clinic?.id;
      }
    }
    if (this.modifyType == 'doctors'){
      data = {
        doctorLastName: this.formGroup.get("DoctorLastName")?.value,
        doctorFirstName: this.formGroup.get("DoctorFirstName")?.value,
        doctorRoles: this.formGroup.get("DoctorRoles")?.value,
        doctorBillingCode: this.formGroup.get("DoctorBillingCode")?.value,
        doctorBillingNumber: this.formGroup.get("DoctorBillingNumber")?.value,
        doctorNote: this.textDoctorNotes,
        clinicAdditionalFax: this.faxNumberAuto,
      }
      if (action == 'add'){
        data['doctorID'] = this.doctor?.id;
      }
    }
    if (action == 'archive') {
      data["archive"] = true;
    }
    this.activeModal.close(data);
  }

  searchReferringDoctor = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => (!this.authService.isCardioStudyCompanyClass() || term.length < 2) ? Promise.resolve([])
        : this.physicianService.searchDoctorNoClinic(term, true, false))
    )

  searchDoctor = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => (!this.authService.isCardioStudyCompanyClass() || term.length < 2) ? Promise.resolve([])
        : this.physicianService.searchDoctor(term, true, false))
    )

  searchReferringDoctorFormatter = (result: any): string => {
    return [
      result.last_name,
      result.first_initials,
      result.clinic ? result.clinic.city : null,
      result.clinic ? result.clinic.fax : null,
      result.billing_number,
    ].filter(x => !!x).join(" | ")
  };

  searchReferringDoctorInputFormatter = (result: any): string => {
    if (!result.name) {
      return result;
    }
    this.formGroup.patchValue({
      DoctorLastName: result.last_name,
      DoctorFirstName: result.first_name,
      DoctorRoles: result.roles,
      // DoctorBillingCode: result.billing_code,
      DoctorBillingNumber: result.billing_number,
    });
    this.doctor = result;
    return result.name;
  };

  searchFaxDoctorInputFormatter = (result: any): string => {
    if (result.clinic?.fax){
      let fax = result.clinic.fax + " - " + result.name;
      return fax;
    }
  };

  // possibly taking lots of resources?
  typeOf(value) {
    if(typeof value === "object"){
      if (value.clinic?.fax) {
        return false;
      }
      else {
        return true;
      }
    }
  }

  searchClinic = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => (!this.authService.isCardioStudyCompanyClass() || term.length < 2) ? Promise.resolve([])
        : this.clinicService.searchClinics(term))
    )

  searchClinicFormatter = (result: any): string => result.name;

  searchClinicInputFormatter = (result: any): string => {
    if (!result.name) {
      return result;
    }

    this.formGroup.patchValue({
      ClinicName: result.name,
      ClinicFax: result.fax,
      ClinicNoFaxNumber: result.flag_no_fax,
      ClinicPhone: result.phone,
      ClinicStreet: result.street,
      ClinicCity: result.city,
      ClinicProvince: result.province,
      ClinicPostal: result.postal_code,
      ClinicID: result.id,
      ClinicZone: result.zone,
    });
    this.clinic = result;
    this.getFaxes();
    this.noFaxNumber = this.clinic.flag_no_fax;
    return result.name;
  };

  private verifyBillingNumberObservable(event, that) {
    const billing_number = that.formGroup.get("DoctorBillingNumber")?.value;
    this.errorStrings = {};
    this.disableCreate = false;
    if (!billing_number || (billing_number.length < 3)) {
      return;
    }
    this.physicianService.getDoctorByBillingNumber(billing_number).then(found_doctor => {
      if (found_doctor) {
        this.errorStrings.last_name = found_doctor.last_name;
        this.errorStrings.first_name = found_doctor.first_name;
        this.errorStrings.roles = found_doctor.roles;
        this.errorStrings.billing_code = found_doctor.billing_code;
        if (this.add){
          this.disableCreate = false;
        }
        else {
          this.disableCreate = true;
        }
      }
    });
  }
}
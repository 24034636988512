<div class="row sub-main no-footer">

    <div class="page-title">
      <div class="title_left">
        <h3>Holter - Requisitions</h3>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="x_panel">
      <div class="x_content">

        <div class="row split-container">
          <as-split direction="horizontal">

            <as-split-area [size]="50">
              <div class="col">

                <div class="row">
                  <div class="col-4">
                    <select class="form-control" data-live-search="true" [(ngModel)]="statusFilter" (change)="statusFilterChanged()">
                      <option>All</option>
                      <option>Reconcile</option>
                      <option>New</option>
                      <option>New Data</option>
                      <option>Match Data</option>
                      <option>No Data</option>
                      <option>Reported</option>
                      <option>Archived</option>
                      <option>Suspended</option>
                      <option>Within 3 Days</option>
                      <option>Beyond 3 Days</option>
                    </select>
                  </div>
                  <div class="col-4">
                    <select class="form-control" data-live-search="true" [(ngModel)]="programFilter" (change)="statusFilterChanged()">
                      <option>All</option>
                      <option>Clinical</option>
                      <option>Home Holter</option>
                      <option>Follow Up</option>
                      <option>Booking</option>
                      <option>Empty</option>
                    </select>
                  </div>
                  <div class="col-4">
                      <input type="text" class="form-control search-bar" placeholder="Start typing to search" id="search-bar">
                  </div>
                </div>

                <table datatable [dtOptions]="dtOptions" class="statusFilter == 'Reconcile'? datatable-buttons hover : table table-striped row-bordered" id="datatable">
                  <thead>
                    <tr>
                      <th width="10px"></th>
                      <th>Name</th>
                      <th>Last Updated</th>
                      <th>Upload Date</th>
                      <th>Hookup Date</th>
                      <th>Comments</th>
                      <th>Reservation</th>
                      <th>Status</th>
                      <th>Program</th>
                    </tr>
                  </thead>
                </table>

                <div class="row">
                  <div class="col text-right form-group">
                    <button class="btn btn-primary btn-narrow" type="button" (click)="downloadReq()" [disabled]="!currentReq">Download</button>
                    <button class="btn btn-primary btn-narrow" type="button" (click)="reserveReq()" [disabled]="!currentReq || currentReq.closed_by" title="Cannot reserve if duplicate is reserved">Reserve</button>
                    <button class="btn btn-primary btn-narrow" type="button" (click)="unreserveReq()" [disabled]="!currentReq || currentReq.closed_by">Unreserve</button>
                    <button class="btn btn-primary btn-narrow" (click)="reportNoData()" type="button" [disabled]="!currentReq || multiSelected || currentReq.closed_by">Mark No Data</button>
                    <button class="btn btn-primary btn-narrow" (click)="updateStatus(statusFilter === 'Archived' ? 'New' : 'Archived')" type="button" [disabled]="!currentReq || currentReq.closed_by">
                      {{statusFilter === "Archived" ? "Restore" : "Archive"}}
                    </button>
                  </div>
                </div>

              </div>
            </as-split-area>

            <as-split-area [size]="50">
              <div class="col info-panel" *ngIf="currentReq">

                <form class="form-horizontal form-label-left" [formGroup]="formGroup">
                  <div class="row">
                    <div class="col form-group">
                      <label>Last Name</label>
                      <input type="text" class="form-control" placeholder="" formControlName="LastName"[ngbTypeahead]="searchHolter" [resultFormatter]="searchHolterFormatter" [inputFormatter]="searchHolterInputLastFormatter" [attr.disabled]="currentReq.closed_by">
                    </div>
                    <div class="col form-group">
                      <label>First Name</label>
                      <input type="text" class="form-control" placeholder="" formControlName="FirstName"[ngbTypeahead]="searchHolter" [resultFormatter]="searchHolterFormatter" [inputFormatter]="searchHolterInputFirstFormatter" [attr.disabled]="currentReq.closed_by">
                    </div>
                    <div class="col form-group">
                      <label>Hookup Date</label>
                      <input type="text" class="form-control" placeholder="YYYY/MM/DD" formControlName="HookupDate"[ngbTypeahead]="searchHolter" [resultFormatter]="searchHolterFormatter" [inputFormatter]="searchHolterInputHookupDateFormatter" [attr.disabled]="currentReq.closed_by">
                    </div>
                    <div class="col form-group">
                      <button class="btn btn-primary btn-review" (click)="renameReq()" type="button" [disabled]="multiSelected || !currentReq || !validToUpdate() || currentReq.closed_by">Update</button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col form-group">
                      <label>Program</label>
                      <select class="form-control" data-live-search="true" formControlName="Program" [attr.disabled]="currentReq.closed_by">
                        <option>Clinical</option>
                        <option>Home Holter</option>
                        <option>Follow Up</option>
                        <option>Booking</option>
                      </select>
                    </div>
                    <div class="col form-group">
                      <label>Comments</label>
                      <textarea class="form-control" rows="1" formControlName="Comments" [attr.disabled]="currentReq.closed_by"></textarea>
                    </div>
                  </div>
                </form>

                <div class="preview-panel">
                  <pdf-viewer [src]="pdfSrc" [autoresize]="true" [render-text]="false" [original-size]="false" [fit-to-page]="true" style="display: block;"></pdf-viewer>
                </div>
              </div>
            </as-split-area>

          </as-split>
        </div>
      </div>
    </div>
  </div>
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import * as moment from "moment";
import * as download from "downloadjs";
import * as JSZip from "jszip";
import * as JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";

import { Holter } from "../../models/holter.model";

import { HolterService } from "../../providers/holter.service";
import { AuthService } from "../../providers/auth.service";

@Component({
  selector: 'app-home',
  templateUrl: './holter-search.component.html',
  styleUrls: ['./holter-search.component.scss']
})
export class HolterSearchComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;

  public dtOptions: any = {};
  public downloadProgress = null;

  public readonly PAGE_SEARCH: string = "search";
  public readonly PAGE_CREATE: string = "create";
  public readonly PAGE_EDIT: string = "edit";
  public readonly PAGE_PENDING: string = "pending";
  public page: string = this.PAGE_SEARCH;

  public createFormGroup: FormGroup;

  public flags: any = {
    flag_verified: null,
    flag_pacemaker_inquiry: null,
    flag_no_data: null,
    flag_no_req: null,
    flag_no_backup: null,
    flag_no_tracings: null,
    flag_invalid_referring: null,
    flag_invalid_healthcard: null,
    flag_invalid_dob: null,
    flag_need_repair: null,
  };

  public emailFilter: string | null = null;

  private readonly DEBOUNCE_SEC: number = 1;

  public updateFlags(flagName, value) {
    this.flags[flagName] = value;
    this.holterService.updateFlags(this.flags);
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  public setEmailFilter() {
    if (this.emailFilter) {
      this.emailFilter = null;

    } else {
      const token = this.authService.getToken(true);
      this.emailFilter = token.email;
    }

    this.holterService.updateEmailFilter(this.emailFilter);
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  // important variable that changes if the status column position changes
  private readonly STATUS_COLUMN_INDEX: number = 0;

  public ngAfterViewInit(): void {

    // set up the callbacks
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {

      // on search term callback
      $("#search-bar").on("keyup change", function () {
        if (dtInstance.search() !== (<HTMLInputElement>this)["value"]) {
          dtInstance.search((<HTMLInputElement>this)["value"]).draw();
        }
      });
    });

    // focus on the search bar right away after load
    $("#search-bar").focus();
  }

  public ngOnInit() {}

  constructor(
    private holterService: HolterService,
    private authService: AuthService,
    private router: Router,
  ) {

    // notify the holter service of updated flags
    this.holterService.clearFilters();
    this.holterService.updateFlags(this.flags);
    this.holterService.updateStateFilter(null);
    this.holterService.updateReservedByFilter(null);
    this.holterService.updateShowDuplicates(null, null);

    // roll callback
    const rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
      // $("td", row).unbind("click");
      $(".table-download-icon", row).bind("click", (event) => this.downloadFileHandler(event, data));
      $("td", row).not(".download-file-column").bind("click", () => this.rowClickHandler(data));
      return row;
    };

    // datatable options
    this.dtOptions = {
        autoWidth: false,
        responsive: true,
        lengthChange: false,
        select: true,
        pageLength: 25,
        dom: "Blfrtip",
        buttons: [],
        rowCallback: rowCallback,
        serverSide: true,
        processing: true,
        ajax: this.holterService.getAjaxFuncion(),
        order: [[ 1, "desc" ]],
        columnDefs: [{
          targets: 0,
          className: 'download-file-column'
        }],
        columns: [
          {
            data: "holter:uploaded_files",
            orderable: false,
            render: function ( data, type, row, meta ) {
              const uploadedfiles = JSON.parse(data);
              const hasPdf = uploadedfiles.includes('out_01.pdf');
              const hasPat = uploadedfiles.includes('pat.001');
              const hasBackup = !!row["holter:backed_up_at"];
              const expandedFlags = HolterService.expandFlags(row["holter:flags"]);
              const hasFinal = expandedFlags["holter:flag_verified"] && row["holter:state"] === "FAXING";
              const hasReq = !!row["holter:requisition_id"];
              const hasPreliminary = expandedFlags["holter:flag_verified"] && row["holter:state"] !== "FAXING";

              let icons = '';
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Data"><i class="fa download-backup fa-database ` + (hasBackup ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Tracings"><i class="fa download-tracings fa-file-pdf-o ` + (hasPdf ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Requisition"><i class="fa download-req fa-file-o ` + (hasReq ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Preliminary"><i class="fa download-preliminary fa-file-text-o ` + (hasPreliminary ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Final Report"><i class="fa download-final fa-file-pdf-o ` + (hasFinal ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              return icons;
            },
          },
          { data: "holter:scan_date" },
          { data: "patient:health_card" },
          { data: "patient:last_name" },
          { data: "patient:first_name" },
          { data: "patient:birthdate" },
          { data: "clinic:name" },
          { data: "clinic:fax" },
          {
            data: "holter:flags",
            orderable: false,
          },
          {
            data: "holter:requisition_id",
            orderable: false,
            visible: false,
          },
          { data: 'holter:state', visible: false },
          { data: 'holter:id', visible: false },
          { data: 'holter:backed_up_at', visible: false },
        ],
        language: {
          infoFiltered: ""
        }
    };
  }

  public rowClickHandler(info: any, force: boolean = false) {
    this.router.navigateByUrl('/holter/detail/medflux/' + info["holter:id"]);
  }

  public downloadFileHandler(event: any, info: any) {

    $("body, .table-download-icon, tr").css("cursor", "wait");
    return this.holterService.getHolter(parseInt(info["holter:id"]))
    .then((holter: Holter | null) => {

      // no holter found with matching id
      if (!holter) {
        return;
      }

      // determine which download was clicked
      const classNames = event.target.className;
      if (classNames.includes('download-tracings')) {
        return this.holterService.getTracings(holter.id, "flattened").then(url => {
          download(url);
        });
      } else if (classNames.includes('download-backup')) {
        return this.holterService.backupUrls(holter.id).then(urls => {
          const zip_name = `${holter.patient_last_name}, ${holter.patient_first_name}_backup_${holter.hookup_date}_${holter.referring_doctor_name}.zip`;
          var count = 0;
          var zip = new JSZip();
          urls.forEach(url => {
            return JSZipUtils.getBinaryContent(url.url, (err, data) => {
              zip.file(url.filename, data, {binary:true});
              count++;
              this.downloadProgress = "Downloaded " + count + "/" + urls.length + " files";
              if (count == urls.length) {
                this.downloadProgress = "Zipping files. This may take a couple of minutes. Do not navigate away from page.";
                zip.generateAsync({type:'blob'}).then(content => {
                  saveAs(content, zip_name);
                  this.downloadProgress = null;
                });
              }
            });
          });
        });
      } else if (classNames.includes('download-preliminary')) {
        return this.holterService.getMonitorReport(holter.id)
        .then((base64Data: string) => {
          download(HolterService.base64ToArrayBuffer(base64Data), "preliminary_report.pdf", "application/pdf");
        })
      } else if (classNames.includes('download-final')) {
        return this.holterService.getMonitorReport(holter.id)
        .then((base64Data: string) => {
          download(HolterService.base64ToArrayBuffer(base64Data), "final_report.pdf", "application/pdf");
        })
      } else if (classNames.includes('download-req')) {
        return this.holterService.getRequisition(holter.id).then((url) => {
          download(url);
        });
      }
      return;
    })
    .then(() => {
      $("body, .table-download-icon, tr").css("cursor", "auto");
    })
    .catch(() => {
      $("body, .table-download-icon, tr").css("cursor", "auto");
    });
  }

}
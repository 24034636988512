<div class="col-md-3 left_col" [class.force-hide]="hideSideMenu()">
  <div class="left_col scroll-view">
    <div class="navbar nav_title" style="border: 0; text-align: center;">
      <div class="col-12"><img src="assets/logo.png" class="img-sidebar-logo"></div>
      <a routerLink="/" class="site_title">
        <span>Cardio Study</span>
        <p class="subscript">{{versionString}}</p>
      </a>
    </div>

    <div class="bottom-fade active"></div>
    <div class="clearfix"></div>
    <br />

    <div class="static user-profile">
      <img src="assets/user.svg" alt=""> {{getName()}}
    </div>

    <!-- sidebar menu -->
    <div id="sidebar-menu" class="main_menu_side hidden-print main_menu">
      <div class="menu_section">
        <ul class="nav side-menu">
          <li [class.active]="page.startsWith('/abpm') || page === '/'" [hidden]="shouldHide('/abpm')"><a routerLink="/abpm">
            <i class="fa fa-heart"></i> ABPM </a>
          </li>
          <li [class.active]="page.startsWith('/holter')" [hidden]="shouldHide('/holter')"><a routerLink="/holter/pending">
            <i class="fa fa-heartbeat"></i> Holter </a>
          </li>
          <!-- <li [class.active]="page === '/visit' || page === '/'" [hidden]="shouldHide('/visit')"><a routerLink="/visit">
            <i class="fa fa-home"></i> Bookings </a>
          </li> -->
          <li [class.active]="page.startsWith('/analytics')" [hidden]="shouldHide('/analytics')"><a routerLink="/analytics">
            <i class="fa fa-line-chart"></i> Analytics </a>
          </li>
          <!-- <li [class.active]="page === '/directory'" [hidden]="shouldHide('/directory')"><a routerLink="/directory">
            <i class="fa fa-user-md"></i> Directory </a>
          </li> -->
          <!-- <li [class.active]="page === '/patient'" [hidden]="shouldHide('/patient')"><a routerLink="/patient">
            <i class="fa fa-users"></i> Patients </a>
          </li> -->
          <li [class.active]="page.startsWith('/inbox')" [hidden]="shouldHide('/inbox')"><a routerLink="/inbox/main">
            <i class="fa fa-envelope"></i> Inbox </a>
          </li>
          <li [class.active]="page.startsWith('/fax')" [hidden]="shouldHide('/fax')"><a routerLink="/fax/legacy">
            <i class="fa fa-fax"></i> Fax </a>
          </li>
          <li [class.active]="page.startsWith('/staff')" [hidden]="shouldHide('/staff')"><a routerLink="/staff">
            <i class="fa fa-lock"></i> Admin </a>
          </li>
          <li [class.active]="page.startsWith('/billing')" [hidden]="shouldHide('/billing')"><a routerLink="/billing/holter-log">
            <i class="fa fa-credit-card"></i> Billing </a>
          </li>
          <li [class.active]="page.startsWith('/logistics')" [hidden]="shouldHide('/logistics')"><a routerLink="/logistics/medtrace">
            <i class="fa fa-globe"></i> Logistics </a>
          </li>
          <li [class.active]="page.startsWith('/debug')" [hidden]="shouldHide('/debug')"><a routerLink="/debug/holter">
            <i class="fa fa-bug"></i> Debug </a>
          </li>
          <!-- <li [class.active]="page === '/reports'" [hidden]="shouldHide('/reports')"><a routerLink="/reports">
            <i class="fa fa-clipboard"></i> Reports </a>
          </li> -->
          <!-- <li [class.active]="page === '/help'" [hidden]="shouldHide('/help')"><a routerLink="/help">
            <i class="fa fa-question"></i> Help &amp; Support </a>
          </li> -->
        </ul>
      </div>
    </div>
    <!-- /sidebar menu -->

    <!-- sidebar footer -->
    <div class="main_menu_side hidden-print main_menu">
      <div class="menu_section">
        <ul class="nav side-menu">
          <li>
            <a (click)="logout()">
              <i class="fa fa-sign-out pull-right"></i> Log Out
            </a>
          </li>
          <li>
            <a style="display: flex; height: 10px; align-items: center; justify-content: center;" (click)="openWindow('https://cardiostudy.ca/terms-of-use')">Terms of Use</a>
          </li>
          <li>
            <a style="display: flex; height: 10px; align-items: center; justify-content: center;" (click)="openWindow('assets/documents/2021-09-29-PatientPrivacyPolicyAndManual.pdf')">Privacy Policy</a>
          </li>
        </ul>
      </div>
      <p style="text-align:center"><small>Cardio Study Technologies Inc. &#169;</small></p>
    </div>
    <!-- /sidebar footer -->

  </div>
</div>
<!-- side bar -->

<div class="right-fade active" [class.force-hide]="hideSideMenu()"></div>

<!-- top navigation, this is turned off, hidden is always set to true-->
<div class="top_nav" [hidden]="true">
  <div class="nav_menu">
    <nav>
      <div class="nav toggle">
        <!-- <a id="menu_toggle"><i class="fa fa-bars"></i></a> -->
      </div>

      <ul class="nav navbar-nav navbar-right">
        <li class="">
          <a href="javascript:;" class="user-profile dropdown-toggle" data-toggle="dropdown" aria-expanded="false" [class.disabled]="!getName()">
            <img src="assets/user.svg" alt="">{{getName()}}
          </a>
          <ul class="dropdown-menu dropdown-usermenu pull-right">
            <li><a class="dropdown-item" (click)="logout()"><i class="fa fa-sign-out pull-right"></i> Log Out</a></li>
          </ul>
        </li>

      </ul>
    </nav>
  </div>
</div>
<!-- /top navigation -->

<!-- page content -->
<div class="right_col" role="main" [class.force-hide]="hideSideMenu()">
    <router-outlet></router-outlet>
</div>
<!-- /page content -->
import { AfterViewInit, Component, OnInit, ViewChild, Injectable } from '@angular/core';
import { HostListener } from '@angular/core';
import { Router } from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { NgbInputDatepicker, NgbDateStruct, NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { environment } from "../../../environments/environment";
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import * as moment from "moment";
import { formatNotesSingle } from "../../utils/format";

import { RequisitionService } from "../../providers/requisition.service";
import { HolterService } from "../../providers/holter.service";
import { AuthService } from "../../providers/auth.service";
import { PhysicianService } from "../../providers/physician.service";

import { formatLocaleDateSingle } from "../../utils/format";

 function padNumber(value: number | null) {
  if (!isNaN(value) && value !== null) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct | null {
    if (value) {
      const dateParts = value.trim().split('/');

      let dateObj: NgbDateStruct = { day: <any>null, month: <any>null, year: <any>null }
      const dateLabels = Object.keys(dateObj);

      dateParts.forEach((datePart, idx) => {
        dateObj[dateLabels[idx]] = parseInt(datePart, 10) || <any>null;
      });
      return dateObj;
    }
    return null;
  }

  static formatDate(date: NgbDateStruct | NgbDate | null): string {
    return date ?
      `${date.year || ''}/${padNumber(date.month)}/${padNumber(date.day)}` : '';
  }

  format(date: NgbDateStruct | null): string {
    return date ?
      `${date.year || ''}/${padNumber(date.month)}/${padNumber(date.day)}`: '';
  }
}

@Component({
  selector: 'app-inbox-main',
  templateUrl: './inbox-main.component.html',
  styleUrls: ['./inbox-main.component.scss'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
  ]
})
export class InboxMainComponent implements AfterViewInit {
  @ViewChild(DataTableDirective) private datatableElement: DataTableDirective;
  @ViewChild('datePicker') datePicker: NgbInputDatepicker;
  public dtOptions: any = {};

  private readonly UPLOAD_URL: string = environment.api + '/requisition';
  public dropzoneConfig: DropzoneConfigInterface | null = null;

  public multiSelected: boolean = false;
  public currentReq: any = null;

  public pendingReqs: string[] = [];
  public pdfSrc: any = null;
  public pdfPage: any = 1;
  public pdfPageCount: any = null;

  public formGroup: FormGroup;
  public reqFormGroup: FormGroup;

  public typeFilter: any = "New";
  public programFilter: any = "All";

  // public currentDatatablePage: any = "";

  constructor(
    private authService: AuthService,
    private holterService: HolterService,
    private requisitionService: RequisitionService,
    private physicianService: PhysicianService,
    private router: Router,
    private calendar: NgbCalendar,
  ) {

    // configure dropzone
    this.dropzoneConfig = {
      url: this.UPLOAD_URL,
      maxFilesize: 50,
      paramName: "file",
      acceptedFiles: ".pdf",
      headers: { "token": this.authService.getToken() },
      params: {
        info: JSON.stringify({}),
      }
    };

    // roll callback
    const rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
      $(".fa-plus", row).bind("click", (event) => this.expandRowsHandler(event, data, true));
      $(".fa-minus", row).bind("click", (event) => this.expandRowsHandler(event, data, false));
      $("td", row).bind("click", () => this.rowClickHandler(data));
      return row;
    };

    const drawCallback: any = (settings) => {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
          const row = dtInstance.row(rowIdx);
          const isClosed = !!row.data()['requisition:closed_by'];
          if (isClosed) {
            $(row.node()).addClass('closed-entry');
          }
        });
      });
    }

    this.requisitionService.clearFilters();
    this.requisitionService.updateDocumentTypeFilter(this.typeFilter);
    this.requisitionService.updateProgramFilter(this.programFilter);

    // datatable options
    this.dtOptions = {
      autoWidth: false,
      responsive: true,
      lengthChange: false,
      select: true,
      pageLength: 50,
      dom: "Blfrtip",
      buttons: [],
      rowCallback: rowCallback,
      drawCallback: drawCallback,
      serverSide: true,
      processing: true,
      scrollY: window.innerHeight - 480,
      ajax: this.requisitionService.getAjaxFuncion(),
      order: [[ 3, "desc" ]],
      columnDefs: [{
        targets: 0,
        className: 'expand-row-column'
      },{
        targets: 1,
        className: 'download-file-column'
      }],
      columns: [
        {
          data: 'requisition:id',
          orderable: false,
          render: ( data, type, row, meta ) => {
            const duplicateCount = row["requisition:duplicate_count"];
            let expandHtml = ``;
            if (duplicateCount > 1 && row["requisition:status"] == "New")
              expandHtml = ` &nbsp;<a><i class="fa fa-plus expand-rows-icon"></i></a>`;
            if (duplicateCount < -1)
              expandHtml = ` &nbsp;<a><i class="fa fa-minus expand-rows-icon"></i></a>`;

            return expandHtml;
          },
        },
        {
          data: "requisition:last_name" ,
          orderable: true,
          render: function ( data, type, row, meta ) {
            const last = row["requisition:last_name" ];
            const first = row["requisition:first_name"];
            if (!last && !first) {
              return "";
            } else if (!last) {
              return first;
            } else if (!first) {
              return last;
            } else {
              return last + ", " + first;
            }
          },
        },
        { data: "requisition:updated_at" },
        { data: "requisition:received_at",
          orderable: true,
          render: function ( data, type, row, meta ) {
            const created = row["requisition:created_at"];
            const received = row["requisition:received_at"];
            return received == null ? created : received;
          },
        },
        { data: "requisition:received_subject" },
        { data: "requisition:document_type" },
        { data: "requisition:status",
          render: function ( data, type, row, meta ) {
            return row["requisition:closed_by"] ? "SUSPENDED" : data;
          }
        },
        { data: "requisition:program" },
        { data: "requisition:created_at", visible: false},
        { data: "requisition:file_name", visible: false },
        { data: "requisition:first_name", visible: false },
        { data: "requisition:closed_by", visible: false },
        { data: "requisition:duplicate_count", visible: false },
      ],
      language: {
        infoFiltered: ""
      }
    };

    this.reqFormGroup = new FormGroup({
      LastName: new FormControl("", [ Validators.required, ]),
      FirstName: new FormControl("", [ Validators.required, ]),
      HookupDate: new FormControl("", [ Validators.pattern(/^\d{4}\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/) ]),
      Program: new FormControl("", [ Validators.required, ]),
      FaxNotes: new FormControl("", []),
      StaffNotes: new FormControl("", []),
    });
    this.formGroup = new FormGroup({
      Pages: new FormControl("", [ Validators.required, Validators.pattern(/[\s,]*(\d+)[\s,]*/)]),
    });
    this.reloadRequisitions();
  }

  private updateMultiSelected(that: any) {
    that.datatableElement.dtInstance.then((dtInstance: any) => {
      const selectedData = dtInstance.rows(".selected").data();
      that.multiSelected = selectedData.length > 1;
    });
  }

  public expandRowsHandler(event: any, info: any, expand: boolean) {
    const clickedId = info["requisition:id"];
    if (expand)
    {
      this.requisitionService.setCustomFilter("dupe_new_reqs_patient");
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
          const row = dtInstance.rows().data()[rowIdx];
          if (row["requisition:id"] != clickedId) {
            return;
          }
          this.requisitionService.updateShowDuplicates(row["requisition:last_name"], row["requisition:first_name"]);
          dtInstance.page(0).draw();
        });
      });
    }
    else
    {
      this.requisitionService.clearFilters();
      if (this.typeFilter == "All" && this.programFilter == "All"){
        this.requisitionService.setCustomFilter("noReqArchive");
      }else{
        this.requisitionService.updateDocumentTypeFilter(this.typeFilter);
        this.requisitionService.updateProgramFilter(this.programFilter);
        this.requisitionService.updateShowDuplicates(null, null);
      }
      this.reloadTable();
    }
  }

  public rowClickHandler(info: any, force: boolean = false) {
    setTimeout(() => this.updateMultiSelected(this), 200);
    this.requisitionService.getRequisition(info["requisition:id"]).then(
      requisition => {
        // NO NEED TO SWITCH TO ANOTHER FILTER WHEN IT ALWAYS LOADS NEW + ALL FILTER ON LOAD
        // localStorage.setItem(document.location.href+'currentEntry', String(requisition.id));
        this.populateRequisition(requisition, true)
      }
    );
  }

  public statusFilterChanged(): void {
    this.requisitionService.clearFilters();
    this.requisitionService.updateClosedFilter(false);
    if (this.typeFilter == "All" && this.programFilter == "All"){
      this.requisitionService.setCustomFilter("noReqArchive");
    } else {
      if (this.typeFilter == "Suspended"){
        this.requisitionService.updateClosedFilter(true);
      } else {
        this.requisitionService.updateDocumentTypeFilter(this.typeFilter);
      }
      this.requisitionService.updateProgramFilter(this.programFilter);
    }
    this.reloadTable();
  }

  public ngAfterViewInit(): void {
    const that = this;
    document.addEventListener("click",  function(e){
      if (!e.target) {
        return;
      }
      const className = (e.target as Element).className;
      if(className == "as-split-gutter-icon"){
        window.dispatchEvent(new Event('resize'));
        that.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.draw(false);
        });
      }
    });

    this.configureReqHeights();

    setTimeout(() => {
      // set up the callbacks
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {

        // on search term callback
        $("#search-bar").on("keyup change", function () {
          if (dtInstance.search() !== (<HTMLInputElement>this)["value"]) {
            dtInstance.search((<HTMLInputElement>this)["value"]).draw();
          }
        });

        // NO NEED TO SWITCH TO ANOTHER FILTER WHEN IT ALWAYS LOADS NEW + ALL FILTER ON LOAD
        // on page change callback
        // $('#inbox-datatable').on('page.dt', function(){
        //   var info = dtInstance.page.info();
        //   that.currentDatatablePage = info.page;
        //   localStorage.setItem(document.location.href+'currentPage', String(that.currentDatatablePage));
        // });

        // NO NEED TO SWITCH TO ANOTHER FILTER WHEN IT ALWAYS LOADS NEW + ALL FILTER ON LOAD
        //Find previous requisition/entry and select if it exist
        // let found_req = JSON.parse(localStorage.getItem(document.location.href+'currentEntry'));
        // let found_page = JSON.parse(localStorage.getItem(document.location.href+'currentPage'));
        // if (found_req){
        //   // Switch to page first then run loop through datatable
        //   dtInstance.page(found_page).draw(false);
        //   setTimeout(() => {
        //     dtInstance.rows().every( function ( rowIdx, tableLoop, rowLoop ) {
        //       const row = dtInstance.row(rowIdx);
        //       if (row.data()["requisition:id"] == found_req) {
        //         (row as any).select();
        //       }
        //     });
        //   }, 500);
        // }
      });
    }, 1500);
    // focus on the search bar right away after load
    $("#search-bar").focus();

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.configureReqHeights();
  }

  public onUploadError(event: any) {
    console.log("Dropzone Error: " + event);
    this.configureReqHeights();
    this.reloadRequisitions();
    this.reloadTable();
  }

  public onUploadSuccess(event) {
    this.configureReqHeights();
    this.reloadRequisitions();
    this.reloadTable();
  }

  private configureReqHeights() {
    const dropzoneHeight = $("#dropzone-box").height();
    $(".req-table").height(window.innerHeight - dropzoneHeight - 280);
  }

  private reloadRequisitions(): Promise<void> {
    return this.holterService.getRequisitions().then((reqs) => {
      this.pendingReqs = reqs.pending.map(x => x.file_name);
    })
  }

  private populateRequisition(requisition, reloadPreview: boolean) {
    this.currentReq = requisition;
    this.formGroup.reset();
    this.reqFormGroup.reset();
    this.requisitionService.getRequisitionPresignedUrl(requisition.id).then(url => {
      this.pdfSrc = url;
    });
    this.reqFormGroup.patchValue({
      LastName: requisition.last_name,
      FirstName: requisition.first_name,
      HookupDate: {
        "year": moment(formatLocaleDateSingle(requisition.date)).year(),
        "month": moment(formatLocaleDateSingle(requisition.date)).month() + 1,
        "day": moment(formatLocaleDateSingle(requisition.date)).date()
      },
      Program: requisition.program,
      FaxNotes: formatNotesSingle(requisition.fax_notes),
      StaffNotes: formatNotesSingle(requisition.staff_notes),
    });
  }

  private reloadTable() {
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw(false);
    });
  }

  public updateDocumentType(documentType: string) {
    this.datatableElement?.dtInstance.then((dtInstance: any) => {
      const updatePromises = [];
      dtInstance.rows(".selected").every((idx) => {
        const entry = dtInstance.row(idx).data();
        updatePromises.push(this.requisitionService.markDocumentType(entry["requisition:id"], documentType));
      });
      Promise.all(updatePromises).then(() => dtInstance.draw(false));
    });
  }

  public splitPdf() {
    this.requisitionService.splitDocument(this.currentReq.id, this.formGroup.get("Pages")?.value || "")
      .then(() => this.reloadTable());
  }

  public mergePdf() {
    this.datatableElement?.dtInstance.then((dtInstance: any) => {
      const reqIds = [];
      dtInstance.rows(".selected").every((idx) => {
        const entry = dtInstance.row(idx).data();
        reqIds.push(entry["requisition:id"]);
      });
      this.requisitionService.mergeDocument(reqIds).then(() => this.reloadTable());
    });
  }

  public rotatePdf() {
    this.requisitionService.rotateDocument(this.currentReq.id, this.formGroup.get("Pages")?.value || "")
      .then((url) => {
        this.pdfSrc = url;
      });
  }

  public renameReq() {
    this.requisitionService.renameReq(this.currentReq.id, {
      first_name: this.getFormFirstName(),
      last_name: this.getFormLastName(),
      hookup_date: this.getFormHookupDate(),
      program: this.reqFormGroup.get("Program")?.value,
      staff_notes: this.reqFormGroup.get("StaffNotes")?.value,
      fax_notes: this.reqFormGroup.get("FaxNotes")?.value,
    })
    .then(() => {
      this.reloadTable();
      this.requisitionService.getRequisition(this.currentReq.id).then(
        requisition => this.populateRequisition(requisition, true)
      );
    });
  }

  public hasProgramSelected() {
    return !!this.reqFormGroup.get("Program")?.value;
  }

  public pdfLoaded(pdf: any) {
    this.pdfPage = 1;
    this.pdfPageCount = pdf.numPages;
  }

  public getFormFirstName() {
    let value = this.reqFormGroup.get("FirstName")?.value;
    if (typeof value === 'object' && value !== null) {
      value = value.patient.first_name;
    }
    return value;
  }

  public getFormLastName() {
    let value = this.reqFormGroup.get("LastName")?.value;
    if (typeof value === 'object' && value !== null) {
      value = value.patient.last_name;
    }
    return value;
  }

  public getFormHookupDate() {
    let value = this.reqFormGroup.get("HookupDate")?.value;
    let hookup_date = ""
    if (typeof value === 'object' && value !== null) {
      hookup_date = value.hookup_date;
      if (hookup_date == null) {
        return NgbDateCustomParserFormatter.formatDate(value)
      }
      else {
        return hookup_date
      }
    }
    return value;
  }

  searchHolter = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => (term.length < 2) ? Promise.resolve([])
        : this.holterService.searchHolterForInbox(
          this.getFormFirstName(),
          this.getFormLastName(),
          this.getFormHookupDate(),
          {
            hookup_date: null,
            patient: {
              first_name: "No Match Found",
              last_name: null,
            }
          }
        ))
    )

  searchHolterFormatter = (result: any): string => {
    return [
      result.patient.first_name,
      result.patient.last_name,
      result.hookup_date ? moment(result.hookup_date).format("YYYY/MM/DD") : '',
    ].filter(x => !!x).join(" | ")
  };

  searchHolterInputFirstFormatter = (result: any): string => {
    if (typeof result === 'object' && result !== null) {
      this.reqFormGroup.patchValue({FirstName: result.patient.first_name});
      this.reqFormGroup.patchValue({LastName: result.patient.last_name});
      this.reqFormGroup.patchValue({HookupDate: (result.hookup_date ? {
        "year": moment(formatLocaleDateSingle(result.hookup_date)).year(),
        "month": moment(formatLocaleDateSingle(result.hookup_date)).month() + 1,
        "day": moment(formatLocaleDateSingle(result.hookup_date)).date()
      } : '')});
      return result.patient.first_name;
    }
    return result;
  };

  searchHolterInputLastFormatter = (result: any): string => {
    if (typeof result === 'object' && result !== null) {
      this.reqFormGroup.patchValue({FirstName: result.patient.first_name});
      this.reqFormGroup.patchValue({LastName: result.patient.last_name});
      this.reqFormGroup.patchValue({HookupDate: (result.hookup_date ? {
        "year": moment(formatLocaleDateSingle(result.hookup_date)).year(),
        "month": moment(formatLocaleDateSingle(result.hookup_date)).month() + 1,
        "day": moment(formatLocaleDateSingle(result.hookup_date)).date()
      } : '')});
      return result.patient.last_name;
    }
    return result;
  };

  searchHolterInputHookupDateFormatter = (result: any): string => {
    if (typeof result === 'object' && result !== null) {
      this.reqFormGroup.patchValue({FirstName: result.patient.first_name});
      this.reqFormGroup.patchValue({LastName: result.patient.last_name});
      this.reqFormGroup.patchValue({HookupDate: (result.hookup_date ? {
        "year": moment(formatLocaleDateSingle(result.hookup_date)).year(),
        "month": moment(formatLocaleDateSingle(result.hookup_date)).month() + 1,
        "day": moment(formatLocaleDateSingle(result.hookup_date)).date()
      } : '')});
      return result.hookup_date;
    }
    return result;
  };

  public showDatePicker($event, dp){
    $event.preventDefault();
    this.reqFormGroup.patchValue({HookupDate: this.calendar.getToday()});
    dp.toggle();
  }

  public closeFix(event, datePicker) {
    if(event.target.offsetParent == null)
      datePicker.close();
    else if(event.target.offsetParent.nodeName != "NGB-DATEPICKER")
      datePicker.close();
  }

  public todayDate($event){
    this.reqFormGroup.patchValue({HookupDate: this.calendar.getToday()});
  }

}
<div class="md-width">

  <div class="row sub-header">
    <div class="col-12">
      <div class="text-center">
        <br>
        <ul class="list-inline">
          <li *ngIf="showMedtraceTab$ | async as showMedtraceTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/logistics/medtrace')"
            [class.table-filter-selected]="urlContains('medtrace')"
            [hidden]="!showMedtraceTab">
              <div class="col"><p class="text-center">Holter Medtrace</p></div>
          </li>
          <li *ngIf="showMonitorTrafficTab$ | async as showMonitorTrafficTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/logistics/monitor-traffic')"
            [class.table-filter-selected]="urlContains('monitor-traffic')"
            [hidden]="!showMonitorTrafficTab">
              <div class="col"><p class="text-center">Monitor Traffic</p></div>
          </li>
          <li *ngIf="showHomeHolterTab$ | async as showHomeHolterTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/logistics/home-holter')"
            [class.table-filter-selected]="urlContains('home-holter')"
            [hidden]="!showHomeHolterTab">
              <div class="col"><p class="text-center">Home Holter</p></div>
          </li>
          <li *ngIf="showFollowUpTab$ | async as showFollowUpTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/logistics/follow-up')"
            [class.table-filter-selected]="urlContains('follow-up')"
            [hidden]="!showFollowUpTab">
              <div class="col"><p class="text-center">Holter Follow Up</p></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
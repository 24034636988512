import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";

import { environment } from "../../../environments/environment";

import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { DataTableDirective } from 'angular-datatables';

import { Fax } from "../../models/fax.model";
import { FaxService } from "../../providers/fax.service";
import { AuthService } from "../../providers/auth.service";
import { HolterService } from "../../providers/holter.service";

import * as moment from "moment";

@Component({
  selector: 'app-fax-legacy',
  templateUrl: './fax-legacy.component.html',
  styleUrls: ['./fax-legacy.component.scss']
})

export class FaxLegacyComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;

  public dtOptions: any = {};

  // this variable is purely to force a reload on the datatable when a new fax is inserted
  public showTable: boolean = true;

  private readonly FAX_UPLOAD_URL: string = environment.api + '/fax';
  public dropzoneConfig: DropzoneConfigInterface | null = null;

  private faxes: Fax[] = [];
  public fax: Fax | null = new Fax();

  public pdfSrc: any = null;
  public previewPage: number = 1;
  public loadingPdf: boolean = false;

  public errorMessage: string = "";
  public bulkMessage: string = "";

  public ngAfterViewInit(): void {

    // set up the callbacks
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {

      // on search term callback
      $("#search-bar").on("keyup change", function () {

        // : and / don't work well, filter them out
        const searchTerm = (<HTMLInputElement>this)["value"].replace(/:/g, " ").replace(/\//g, " ")

        if (dtInstance.search() !== searchTerm) {
          dtInstance.search(searchTerm).draw();
        }
      });

    });

    // focus on the search bar right away after load
    $("#search-bar").focus();
  }

  public ngOnInit() {}

  constructor(
    private authService: AuthService,
    private faxService: FaxService,
  ) {

    // configure dropzone
    this.dropzoneConfig = {
      url: this.FAX_UPLOAD_URL,
      maxFilesize: 50,
      paramName: "file",
      acceptedFiles: ".docx,.pdf",
      headers: { "token": this.authService.getToken() },
      params: {
        info: JSON.stringify({}),
      }
    };

    this.faxService.updateTriggerFilter("dropped");

    // roll callback
    const rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
      $("td", row).unbind("click");
      $("td", row).bind("click", () => this.rowClickHandler(data));
      return row;
    };

    // datatable options
    this.dtOptions = {
        autoWidth: false,
        responsive: true,
        lengthChange: false,
        select: true,
        pageLength: 30,
        dom: "Blfrtip",
        buttons: [],
        rowCallback: rowCallback,
        serverSide: true,
        processing: true,
        ajax: this.faxService.getAjaxFuncion(),
        order: [[ 3, "desc" ]],
        columns: [
          { data: "fax:file_name" },
          { data: "fax:fax_number" },
          { data: "fax:status" },
          { data: "fax:received_at" },
          { data: "fax:attempt_at" },
          { data: "fax:success_at" },
          { data: "fax:attempts" },
          { data: "owner:name" },
          { data: "fax:id", visible: false },
        ],
        language: {
          infoFiltered: ""
        }
    };
  }

  public rowClickHandler(info: any) {
    this.errorMessage = "";
    this.faxService.getFax(info["fax:id"])
      .then((found: Fax | null) => {
        if (!found) {
          return;
        } else if (this.fax && found.id == this.fax.id) {
          this.fax = new Fax();
        } else {
          this.fax = found;
          this.loadingPdf = true;
        }
      });
  }

  public onUploadError(event: any) {
    console.log("Dropzone Error: " + event);
  }

  // on dropzone success, reload the datatable
  public onUploadSuccess(event: any) {
    this.reloadTable();
  }

  public bulkForceFax(): Promise<any> {

    return this.datatableElement?.dtInstance.then((dtInstance: any) => {
      const updatePromises = [];
      dtInstance.rows(".selected").every((idx) => {
        const entry = dtInstance.row(idx).data();

        if (entry["fax:status"] != "Scheduled") {
          return;
        }
        const thisPromise: Promise<any> = this.faxService.action(entry["fax:id"], "force")
          .catch((error: any) => console.log("Error verifying fax " + error.message))
        updatePromises.push(thisPromise);
      });

      return Promise.all(updatePromises).then((results) => {
        let success: number = 0;
        for (const result of results) {
          success = (result !== null) ? (success + 1) : success;
        }
        this.bulkMessage = success + " of " + results.length + " successful force faxes";
        dtInstance.draw();
      });
    });

  }

  // run a bulk verification on all the selected rows
  public bulkVerify(): Promise<any> {

    return this.datatableElement?.dtInstance.then((dtInstance: any) => {
      const updatePromises = [];
      dtInstance.rows(".selected").every((idx) => {
        const entry = dtInstance.row(idx).data();

        const verifyPromise: Promise<any> = this.faxService.getFax(entry["fax:id"])
          .then((fax: Fax) => this.verifyFax(fax, false))
          .catch((error: any) => console.log("Error verifying fax " + error.message))
        updatePromises.push(verifyPromise);
      });

      return Promise.all(updatePromises).then((results) => {
        let success: number = 0;
        for (const result of results) {
          success = (result === "") ? (success + 1) : success;
        }
        this.bulkMessage = success + " of " + results.length + " successful verifications";
        dtInstance.draw();
      });
    });

  }

  // run a single verification on the selected fax
  public verified(): Promise<string> {
    return this.verifyFax(this.fax);
  }

  private verifyFax(fax: Fax, refresh: boolean = true): Promise<string> {
    if (fax.status.toLowerCase() !== "awaiting verification") {
      this.errorMessage = "Fax not waiting for verification";
      return Promise.resolve(this.errorMessage);
    }

    if (!fax.doctor) {
      this.errorMessage = "Missing doctor";
      return Promise.resolve(this.errorMessage);
    }

    if (!fax.fax_number) {
      this.errorMessage = "Missing fax number";
      return Promise.resolve(this.errorMessage);
    }

    const digits: string[] = fax.fax_number.match(/\d/g);
    if (!digits || digits.length != 10 && digits.length != 11) {
      this.errorMessage = "Invalid fax number";
      return Promise.resolve(this.errorMessage);
    }

    this.errorMessage = "";
    return this.faxService.verifyAndSchedule(fax.id, {
        doctor: fax.doctor,
        fax_number: digits.join(""),
      })
      .then(() => {
        if (refresh) {
          this.reloadTable();
          this.faxService.getFax(fax.id).then(fax => fax = fax);
        }
        return this.errorMessage;
      });
  }

  public fax_immediately(): Promise<any> {
    return this.faxService.action(this.fax.id, "force")
      .then(() => {
        this.reloadTable();
        this.faxService.getFax(this.fax.id).then(fax => this.fax = fax);
      });
  }

  public retry_fax(): Promise<any> {
    return this.faxService.action(this.fax.id, "retry")
      .then(() => {
        this.reloadTable();
        this.faxService.getFax(this.fax.id).then(fax => this.fax = fax);
      });
  }

  public cancel_fax(): Promise<any> {
    this.errorMessage = "";
    return this.faxService.action(this.fax.id, "cancel")
    .then(() => {
      this.reloadTable();
      this.faxService.getFax(this.fax.id).then(fax => this.fax = fax);
    });
  }

  private reloadTable(): void {
    this.showTable = false;
    this.fax = new Fax();
    setTimeout(()=>{this.showTable = true}, 0);
  }

  public attempts(): any[] {
    function formatLogLocaleTime(entry: any) {
      const rawTime = Array.isArray(entry.time) ? entry.time[0] : entry.time;
      const datetime: any = moment(rawTime * 1000);
      entry.formatTime = datetime.isValid() ? datetime.format("YYYY/MM/DD HH:mm") : rawTime;
      return entry;
    }
    return this.fax && this.fax.attempt_log ? this.fax.attempt_log.map(formatLogLocaleTime) : [];
  }

  public loadPdf(): Promise<any> {
    if (this.fax.original_path)
    {
      return this.faxService.getPreview(this.fax)
        .then((base64Data: string) => {
          this.pdfSrc = HolterService.base64ToArrayBuffer(base64Data);
          setTimeout(() => { this.loadingPdf = false; }, 200);
        });
    }

    return this.faxService.getS3Preview(this.fax.id)
      .then((url) => {
        this.pdfSrc = url;
        setTimeout(() => { this.loadingPdf = false; }, 200);
      });
  }
}

<div>
  <div class="row sub-main">

    <div class="page-title">
      <div class="title_left">
        <h3>Logistics - Monitor Traffic</h3>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="x_panel">
      <div class="x_content">

        <div class="row split-container">

          <as-split direction="horizontal">
            <as-split-area [size]="50">
              <div class="col">
                <div class="row">
                  <div class="col-4 form-group">
                    <input type="radio" (change)="applyBranchFilter()" [(ngModel)]="radio" value="serial"> Serial
                    <input type="radio" (change)="applyBranchFilter()" [(ngModel)]="radio" value="clinic"> Clinics
                  </div>
                  <div class="col-8">

                  </div>
                </div>
                <form class="form-horizontal form-label-left" [formGroup]="formGroup">
                  <div class="row">
                    <div class="col">
                      <div class="row">
                          <label>Search</label>
                          <input type="text" formControlName="Search" [(ngModel)]="search" class="form-control search-bar" placeholder="Start typing to search" id="search_bar">
                      </div>
                      <div class="row">
                        <div class="col form-group">
                          <label>Recall Date</label>
                          <input type="text" class="form-control" placeholder="YYYY/MM/DD" formControlName="DateFilter">
                        </div>
                        <div class="col form-group">
                          <label>Usage</label>
                          <select class="form-control" data-live-search="true" formControlName="UsageFilter" (change)="applyFilter()">
                            <option value="">All</option>
                            <option value="LT5">Less than 5</option>
                            <option value="MT5LT30">More than 5, Less than 30</option>
                            <option value="MT30">More than 30</option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col form-group">
                            <label>Date From</label>
                            <input type="text" class="form-control" placeholder="YYYY/MM/DD" formControlName="DateFrom">
                        </div>
                        <div class="col form-group">
                            <label>Date To</label>
                            <input type="text" class="form-control" placeholder="YYYY/MM/DD" formControlName="DateTo">
                        </div>
                        <div class="col text-center">
                            <button class="btn btn-primary btn-narrow" type="button" (click)="applyFilter()">Apply</button>
                        </div>
                      </div>
                    </div>
                    <div class="col form-group">
                      <label>Status</label><span> ({{monitorsCount}}) ({{entriesCount}} entries)</span>
                      <div class="form-group form-display row ml-4 mr-n4">
                          <div class="col-2 form-group">
                              <input class="form-check-input" type="checkbox" name="check0" id="check0" formControlName="Active" [(ngModel)]="isActive" (change)="checkboxChange()" >
                              <label class="form-check-label" for="check0">Active</label>
                          </div>
                          <div class="col-9 form-group center" [ngStyle]="activeStyle">
                            <span>{{activeCount}}</span>
                          </div>
                          <div class="col"></div>
                      </div>
                      <div class="form-group form-display row ml-4 mr-n4">
                          <div class="col-2 form-group">
                              <input class="form-check-input" type="checkbox" name="check4" id="check4" formControlName="Demo" [(ngModel)]="isDemo" (change)="checkboxChange()">
                              <label class="form-check-label" for="check4">Demo</label>
                          </div>
                          <div class="col-9 form-group center" [ngStyle]="demoStyle">
                            <span>{{demoCount}}</span>
                          </div>
                          <div class="col"></div>
                      </div>
                      <div class="form-group form-display row ml-4 mr-n4">
                          <div class="col-2 form-group">
                              <input class="form-check-input" type="checkbox" name="check2" id="check2" formControlName="Expired" [(ngModel)]="isExpired" (change)="checkboxChange()">
                              <label class="form-check-label" for="check2">Expired</label>
                          </div>
                          <div class="col-9 form-group center" [ngStyle]="expiredStyle">
                            <span>{{expiredCount}}</span>
                          </div>
                          <div class="col"></div>
                      </div>
                      <div class="form-group form-display row ml-4 mr-n4">
                          <div class="col-2 form-group">
                              <input class="form-check-input" type="checkbox" name="check5" id="check5" formControlName="Repair" [(ngModel)]="isRepair" (change)="checkboxChange()">
                              <label class="form-check-label" for="check5">Repair</label>
                          </div>
                          <div class="col-9 form-group center" [ngStyle]="repairStyle">
                            <span>{{repairCount}}</span>
                          </div>
                          <div class="col"></div>
                      </div>
                      <div class="form-group form-display row ml-4 mr-n4">
                        <div class="col-2 form-group">
                            <input class="form-check-input" type="checkbox" name="check6" id="check6" formControlName="Pending" [(ngModel)]="isPending" (change)="checkboxChange()">
                            <label class="form-check-label" for="check5">Pending</label>
                        </div>
                        <div class="col-9 form-group center" [ngStyle]="pendingStyle">
                          <span>{{pendingCount}}</span>
                        </div>
                        <div class="col"></div>
                      </div>
                    </div>
                  </div>
                </form>
                <table datatable *ngIf="dtRendered" [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover" id="medtrace-table">
                  <thead>
                      <tr>
                        <th *ngFor="let hd of tableHeaders">
                          {{hd}}
                        </th>/
                      </tr>
                </thead>
                </table>
              </div>
            </as-split-area>
            <as-split-area [size]="50">
              <div class="col" >
                <form class="form-horizontal form-label-left" [formGroup]="editMedtraceFormGroup">
                  <div class="row">
                    <div class="col form-group">
                      <label>Enter Serial
                        <i for="SerialNumber" *ngIf="(SerialNumber.dirty || SerialNumber.touched) && SerialNumber.invalid" class="conflict-message-inline">
                          <i *ngIf="SerialNumber.errors?.required && SerialNumber.errors?.serialpattern">Serial required</i>
                          <i *ngIf="!SerialNumber.errors?.required && SerialNumber.errors?.serialpattern">Serial invalid</i>
                          <i *ngIf="SerialNumber.errors?.existserial">Serial not found</i>
                        </i>
                      </label>
                      <input type="text" id="SerialNumber" class="form-control" placeholder="" formControlName="SerialNumber">
                    </div>
                    <div class="col form-group">
                      <label>Enter Clinic</label>
                      <input type="text" class="form-control" placeholder="Cardio Study" formControlName="ClinicName" [ngbTypeahead]="searchClinic" [resultFormatter]="searchClinicFormatter" [inputFormatter]="searchClinicInputFormatter">
                    </div>
                    <div class="col form-group">
                      <label>Doctor</label>
                      <input type="text" class="form-control" placeholder="" [readonly]="true" formControlName="DoctorName">
                    </div>
                    <div class="col form-group text-center">
                      <button class="btn btn-primary btn-review" type="button" [disabled]="editMedtraceFormGroup.invalid" (click)="newMedtrace()">{{medtraceButtonText}}</button>
                    </div>
                  </div>
                </form>
              </div>
            </as-split-area>
          </as-split>
        </div>
      </div>
    </div>
  </div>
<div>
  <div class="row sub-footer">
    <div class="col-12">
      <button class="btn btn-primary btn-review" type="button" (click)="exportQuery()">Query</button>
      <button class="btn btn-primary btn-review" type="button" (click)="downloadReport()">Report</button>
    </div>
  </div>
</div>
</div>
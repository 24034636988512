import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { FeatureToggleService } from "../../providers/feature-toggle.service";

@Component({
  selector: 'app-logistics',
  templateUrl: './logistics.component.html',
  styleUrls: ['./logistics.component.scss']
})
export class LogisticsComponent implements OnInit {

  public showHomeHolterTab$: Observable<boolean>;
  public showFollowUpTab$: Observable<boolean>;
  public showMedtraceTab$: Observable<boolean>;
  public showMonitorTrafficTab$: Observable<boolean>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private featureToggleService: FeatureToggleService
  ) { }

  ngOnInit(): void {
    this.showHomeHolterTab$ = this.route.paramMap.pipe(switchMap(() => this.featureToggleService.showHolterSubtab("/logistics/home-holter")));
    this.showFollowUpTab$ = this.route.paramMap.pipe(switchMap(() => this.featureToggleService.showHolterSubtab("/logistics/follow-up")));
    this.showMedtraceTab$ = this.route.paramMap.pipe(switchMap(() => this.featureToggleService.showHolterSubtab("/logistics/medtrace")));
    this.showMonitorTrafficTab$ = this.route.paramMap.pipe(switchMap(() => this.featureToggleService.showHolterSubtab("/logistics/monitor-traffic")));
  }

  public navigateTo(url: string): void {
    this.router.navigateByUrl(url);
  }

  public urlContains(str: string): boolean {
    return this.router.url.indexOf(str) > -1;
  }

}
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import * as download from "downloadjs";
import * as JSZip from "jszip";
import * as JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";

import { DXCODE_OPTIONS } from "../../config/constants";
import { Holter } from "../../models/holter.model";
import { Physician } from "../../models/physician.model";

import { HolterService } from "../../providers/holter.service";
import { AuthService } from "../../providers/auth.service";
import { BillingService } from "../../providers/billing.service";
import { PhysicianService } from "../../providers/physician.service";

@Component({
  selector: 'app-billing-holter',
  templateUrl: './billing-holter.component.html',
  styleUrls: ['./billing-holter.component.scss']
})
export class BillingHolterComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective | null = null;

  public dtOptions: any = {};

  public formGroup: FormGroup;
  public currentHolter: Holter | null = null;
  public currentDoctor: Physician;

  public codeFilter: string | null = "All";
  public statusFilter: string | null = "All";
  public holterStatusFilter: string | null = "";
  public startDateFilter: string | null = null;
  public endDateFilter: string | null = null;

  public searchDatabases: any[] = [];
  public reqOptions: string[] = [];
  public billingCode: any = null;

  public emailFilter: string | null = null;
  public billingShowAll: boolean = false;

  private readonly DEBOUNCE_SEC: number = 1;

  public verboseDxcode: boolean = false;
  public DXCODE_OPTIONS = DXCODE_OPTIONS;
  public downloadProgress = null;

  public setEmailFilter() {
    if (this.emailFilter) {
      this.emailFilter = null;

    } else {
      const token = this.authService.getToken(true);
      this.emailFilter = token.email;
    }

    this.holterService.updateEmailFilter(this.emailFilter);
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  // important variable that changes if the status column position changes
  private readonly STATUS_COLUMN_INDEX: number = 0;

  public ngAfterViewInit(): void {

    // set up the callbacks
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {

      // on search term callback
      $("#search-bar").on("keyup change", function () {
        if (dtInstance.search() !== (<HTMLInputElement>this)["value"]) {
          dtInstance.search((<HTMLInputElement>this)["value"]).draw();
        }
      });
    });

    // focus on the search bar right away after load
    $("#search-bar").focus();
  }

  public ngOnInit() {}

  constructor(
    private holterService: HolterService,
    private authService: AuthService,
    private billingService: BillingService,
    private physicianService: PhysicianService,
    private router: Router,
  ) {

    // sets the default filters as well
    this.filterChanged();

    // roll callback
    const rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
      // $("td", row).unbind("click");
      $(".table-download-icon", row).bind("click", (event) => this.downloadFileHandler(event, data));
      $(".fa-plus", row).bind("click", (event) => this.expandRowsHandler(event, data, true));
      $(".fa-minus", row).bind("click", (event) => this.expandRowsHandler(event, data, false));
      $("td", row).not(".download-file-column").not(".expand-row-column").bind("click", () => this.rowClickHandler(data));
      return row;
    };

    // datatable options
    this.dtOptions = {
        autoWidth: false,
        responsive: true,
        lengthChange: false,
        select: true,
        pageLength: 50,
        dom: "Blfrtip",
        buttons: [],
        rowCallback: rowCallback,
        serverSide: true,
        processing: true,
        scrollY: window.innerHeight - 830,
        ajax: this.holterService.getAjaxFuncion(),
        order: [[ 4, "desc" ]],
        columnDefs: [{
          targets: 0,
          className: 'expand-row-column'
        },{
          targets: 1,
          className: 'download-file-column'
        }],
        columns: [
          { data: "holter:state" },
          {
            data: "holter:uploaded_files",
            orderable: false,
            render: function ( data, type, row, meta ) {
              const uploadedfiles = JSON.parse(data);
              const hasPdf = uploadedfiles.includes('out_01.pdf');
              const hasPat = uploadedfiles.includes('pat.001');
              const hasBackup = !!row["holter:backed_up_at"];
              const expandedFlags = HolterService.expandFlags(row["holter:flags"]);
              const hasFinal = expandedFlags["holter:flag_verified"] && row["holter:state"] === "FAXING";
              const hasReq = !!row["holter:requisition_id"];
              const hasPreliminary = expandedFlags["holter:flag_verified"] && row["holter:state"] !== "FAXING";

              let icons = '';
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Data"><i class="fa download-backup fa-database ` + (hasBackup ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Tracings"><i class="fa download-tracings fa-file-pdf-o ` + (hasPdf ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Requisition"><i class="fa download-req fa-file-o ` + (hasReq ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Preliminary"><i class="fa download-preliminary fa-file-text-o ` + (hasPreliminary ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Final Report"><i class="fa download-final fa-file-pdf-o ` + (hasFinal ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              return icons;
            },
          },
          { data: "holter:hookup_date" },
          { data: "holter:billing_date" },
          { data: "holter:scan_date" },
          { data: "holter:billed_at" },
          { data: "reading_doctor:name" },
          { data: "referring_doctor:name" },
          {
            data: 'holter:id',
            orderable: false,
            render: ( data, type, row, meta ) => {
              const last = row["patient:last_name"] || "";
              const first = row["patient:first_name"] || "";
              return [last, first].filter(x => x != "").join(", ");
            },
          },
          { data: "patient:gender" },
          {
            data: "patient:health_card",
            orderable: true,
            render: function ( data, type, row, meta ) {
              const card = row["patient:health_card"];
              const code = row["patient:health_card_code"];
              if ((!card && !code) || !card) {
                return "";
              } else if (!code) {
                return card;
              } else {
                return card + "-" + code;
              }
            },
          },
          { data: "patient:birthdate" },
          { data: "referring_doctor:billing_number" },
          { data: "clinic_assignment:billing_code" },
          { data: "clinic:city" },
          { data: "clinic:name" },
          { data: "dx_code:code" },
          { data: "holter:duration" },
          {
            data: "holter:flags",
            orderable: false,
            visible: false,
          },
          { data: 'holter:id', visible: false },
          { data: 'patient:health_card_code', visible: false },
          { data: "patient:last_name", visible: false },
          { data: "patient:first_name", visible: false },
          { data: 'holter:backed_up_at', visible: false },
          { data: 'referring_doctor:id', visible: false },
          { data: 'clinic:id', visible: false },
        ],
        language: {
          infoFiltered: ""
        }
    };

    this.formGroup = new FormGroup({
      FirstName: new FormControl("", []),
      LastName: new FormControl("", []),
      Gender: new FormControl("", []),
      HealthCard: new FormControl("", []),
      HealthCardVersion: new FormControl("", []),
      Birthdate: new FormControl("", []),
      DxCode: new FormControl("", []),
      HookupDate: new FormControl("", []),
      Duration: new FormControl("", []),
      ScanDate: new FormControl("", []),
      Tech: new FormControl("", []),
      ReferringDoctor: new FormControl("", []),
      City: new FormControl("", []),
      Device: new FormControl("", []),
      Clinic: new FormControl("", []),
      ReadingDoctor: new FormControl("", []),
      Delivery: new FormControl("", []),
      StaffNotes: new FormControl("", []),
      FaxNotes: new FormControl("", []),
      Requisition: new FormControl("", []),
      FlagVerified: new FormControl("", []),
      FlagPacemakerInquiry: new FormControl("", []),
      FlagNoData: new FormControl("", []),
      FlagNoReq: new FormControl("", []),
      FlagNoBackup: new FormControl("", []),
      FlagNoTracings: new FormControl("", []),
      FlagInvalidReferring: new FormControl("", []),
      FlagInvalidHealthcard: new FormControl("", []),
      FlagInvalidDob: new FormControl("", []),
    });

    this.holterService.getDatabaseOptions().then(options => {
      this.searchDatabases = options;
    });

    this.formGroup.get("ReferringDoctor")?.valueChanges.subscribe(event => this.getBillingCodeObservable(event, this));
    this.formGroup.get("Clinic")?.valueChanges.subscribe(event => this.getBillingCodeObservable(event, this));
  }

  private getBillingCodeObservable(event, that) {
    if (!that.currentHolter) {
      return;
    }
    const doctor = that.formGroup.get("ReferringDoctor")?.value?.name || that.formGroup.get("ReferringDoctor")?.value;
    const clinic = that.formGroup.get("Clinic")?.value;
    if (doctor && clinic) {
      // SEARCHING BILLING CODE DOESNT WORK WHEN CLINICS/DOCTORS HAVE SAME NAMES IN DIRECTORY
      // this.physicianService.searchBillingCode(doctor, clinic).then(billingCode => {
      //   this.billingCode = billingCode || null;
      // });
      this.billingCode = this.currentDoctor.billing_code || null;
    } else {
      this.billingCode = null;
    }

  }

  public rowClickHandler(info: any, force: boolean = false) {
    this.holterService.getHolter(info["holter:id"]).then(holter => this.populateHolter(holter));
    this.physicianService.getDoctor(info["referring_doctor:id"], info["clinic:id"]).then(doctor => this.currentDoctor = doctor);
  }

  private populateHolter(holter: Holter)
  {
    this.currentHolter = holter;
    this.reqOptions = holter.requisition_file_name ? [holter.requisition_file_name] : [];
    this.formGroup.patchValue({
      FirstName: holter.patient_first_name,
      LastName: holter.patient_last_name,
      Gender: holter.patient_gender,
      HealthCard: holter.patient_health_card,
      HealthCardVersion: holter.patient_health_card_code,
      Birthdate: holter.patient_birthdate,
      DxCode: holter.dx_code_code,
      HookupDate: holter.hookup_date,
      Duration: holter.duration,
      ScanDate: holter.scan_date,
      Tech: holter.staff_first_name + " " + holter.staff_last_name,
      ReferringDoctor: holter.referring_doctor_name,
      City: holter.clinic_city,
      Device: holter.device_serial_number,
      Clinic: holter.clinic_name,
      ReadingDoctor: holter.reading_doctor_name,
      Delivery: holter.delivery,
      StaffNotes: holter.notes,
      FaxNotes: holter.fax_notes,
      Requisition: holter.requisition_file_name,
      FlagVerified: holter.flag_verified,
      FlagPacemakerInquiry: holter.flag_pacemaker_inquiry,
      FlagNoData: holter.flag_no_data,
      FlagNoReq: holter.flag_no_req,
      FlagNoBackup: holter.flag_no_backup,
      FlagNoTracings: holter.flag_no_tracings,
      FlagInvalidReferring: holter.flag_invalid_referring,
      FlagInvalidHealthcard: holter.flag_invalid_healthcard,
      FlagInvalidDob: holter.flag_invalid_dob,
    })

    if (holter.patient_first_name && holter.patient_last_name && holter.hookup_date && !holter.requisition_file_name) {
      this.holterService.searchRequisitions(holter.patient_first_name, holter.patient_last_name, holter.hookup_date).then(reqs => {
        for (const req of reqs.completed) {
          if (this.reqOptions.indexOf(req.file_name) == -1) {
            this.reqOptions.push(req.file_name);
          }
        }
        for (const req of reqs.pending) {
          if (this.reqOptions.indexOf(req.file_name) == -1) {
            this.reqOptions.push(req.file_name);
          }
        }
      });
    }
    this.getBillingCodeObservable(null, this);
  }

  public formatDatabaseDisplay(database: any) {
    if (database.computer) {
      return database.computer + ' ' + database.path;
    }
    return database.path;
  }

  private isValidDate(date: any) {
    return !isNaN(Date.parse(date));
  }

  public filterChanged(trigger: any = null) {

    this.holterService.clearFilters();

    const newShowAllValue = trigger === "SHOW_ALL" ? !this.billingShowAll : this.billingShowAll;
    if (!newShowAllValue) {
      this.holterService.updateHasBillingDateFilter(true);
    }

    const codeFilter = this.codeFilter.toLowerCase() === "all" ? null : this.codeFilter;
    this.holterService.updateBillingCodeFilter(codeFilter);

    const sixMonthsAgo = new Date(new Date().setMonth(new Date().getMonth() - 6));
    let statusFilter = null;
    if (this.statusFilter.toLowerCase() == "billed") {
      statusFilter = {
        regex: true,
        method: "gte",
        value: sixMonthsAgo.toISOString(),
      };
    } else if (this.statusFilter.toLowerCase() == "not billed") {
      statusFilter = {
        regex: true,
        method: "null",
        value: this.statusFilter,
      };
    } else if (this.statusFilter.toLowerCase() == "archived") {
      statusFilter = {
        regex: true,
        method: "lt",
        value: sixMonthsAgo.toISOString(),
      };
    }
    this.holterService.updateBillingStatusFilter(statusFilter);

    let startDateFilter = null;
    if (this.isValidDate(this.startDateFilter)) {
      startDateFilter = {
        regex: true,
        method: "gte",
        value: new Date(this.startDateFilter),
      };
    }
    this.holterService.updateStartScanDateFilter(startDateFilter);

    let endDateFilter = null;
    if (this.isValidDate(this.endDateFilter)) {
      endDateFilter = {
        regex: true,
        method: "lte",
        value: new Date(this.endDateFilter),
      };
    }
    this.holterService.updateEndScanDateFilter(endDateFilter);

    if (this.holterStatusFilter) {
      this.holterService.updateStateFilter([this.holterStatusFilter]);
    } else {
      this.holterService.updateStateFilter(null);
    }

    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  public expandRowsHandler(event: any, info: any, expand: boolean) {
    const clickedId = info["holter:id"];

    if (expand)
    {
      this.holterService.setCustomFilter("show_duplicates");
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
          const row = dtInstance.rows().data()[rowIdx];
          if (row["holter:id"] != clickedId) {
            return;
          }
          this.holterService.updateShowDuplicates(row["holter:verification_number"], row["holter:device_id"]);
          dtInstance.page(0).draw();
        });
      });
    }
    else
    {
      this.holterService.setCustomFilter(null);
      this.holterService.updateShowDuplicates(null, null);
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.page(0).draw();
      });
    }
  }

  public exportQuery() {
    this.billingService.exportQuery(this.holterStatusFilter, this.billingShowAll, this.codeFilter, this.startDateFilter, this.endDateFilter, this.statusFilter, false).subscribe(data => {
      saveAs(data, "query.csv");
    });
  }

  public exportBilling() {
    this.billingService.exportBilling(this.holterStatusFilter, this.billingShowAll, this.codeFilter, this.startDateFilter, this.endDateFilter, this.statusFilter).subscribe(response => {
      var contentDisposition = response.headers.get('content-disposition');
      var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
      filename = filename.substring(1, filename.length - 1) + ".zip"
      saveAs(response.body, filename);
    });
  }

  public markBilled() {
    this.datatableElement?.dtInstance.then((dtInstance: any) => {
      const updatePromises = [];
      dtInstance.rows(".selected").every((idx) => {
        const entry = dtInstance.row(idx).data();
        const curPromise = this.holterService.updateHolter(entry["holter:id"], {
          billed_at: new Date().toISOString(),
        });
        updatePromises.push(curPromise);
      });
      Promise.all(updatePromises).then(() => dtInstance.draw());
    });
  }

  public markUnbilled() {
    this.datatableElement?.dtInstance.then((dtInstance: any) => {
      const updatePromises = [];
      dtInstance.rows(".selected").every((idx) => {
        const entry = dtInstance.row(idx).data();
        const curPromise = this.holterService.updateHolter(entry["holter:id"], {
          billed_at: null,
        });
        updatePromises.push(curPromise);
      });
      Promise.all(updatePromises).then(() => dtInstance.draw());
    });
  }

  public downloadFileHandler(event: any, info: any) {

    $("body, .table-download-icon, tr").css("cursor", "wait");
    return this.holterService.getHolter(parseInt(info["holter:id"]))
    .then((holter: Holter | null) => {

      // no holter found with matching id
      if (!holter) {
        return;
      }

      // determine which download was clicked
      const classNames = event.target.className;
      if (classNames.includes('download-tracings')) {
        return this.holterService.getTracings(holter.id, "flattened").then(url => {
          download(url);
        });
      } else if (classNames.includes('download-backup')) {
        return this.holterService.backupUrls(holter.id).then(urls => {
          const zip_name = `${holter.patient_last_name}, ${holter.patient_first_name}_backup_${holter.hookup_date}_${holter.referring_doctor_name}.zip`;
          var count = 0;
          var zip = new JSZip();
          urls.forEach(url => {
            return JSZipUtils.getBinaryContent(url.url, (err, data) => {
              zip.file(url.filename, data, {binary:true});
              count++;
              this.downloadProgress = "Downloaded " + count + "/" + urls.length + " files";
              if (count == urls.length) {
                this.downloadProgress = "Zipping files. This may take a couple of minutes. Do not navigate away from page.";
                zip.generateAsync({type:'blob'}).then(content => {
                  saveAs(content, zip_name);
                  this.downloadProgress = null;
                });
              }
            });
          });
        });
      } else if (classNames.includes('download-preliminary')) {
        return this.holterService.getMonitorReport(holter.id)
        .then((base64Data: string) => {
          download(HolterService.base64ToArrayBuffer(base64Data), "preliminary_report.pdf", "application/pdf");
        })
      } else if (classNames.includes('download-final')) {
        return this.holterService.getMonitorReport(holter.id)
        .then((base64Data: string) => {
          download(HolterService.base64ToArrayBuffer(base64Data), "final_report.pdf", "application/pdf");
        })
      } else if (classNames.includes('download-req')) {
        return this.holterService.getRequisition(holter.id).then((url) => {
          download(url);
        });
      }
      return;
    })
    .then(() => {
      $("body, .table-download-icon, tr").css("cursor", "auto");
    })
    .catch(() => {
      $("body, .table-download-icon, tr").css("cursor", "auto");
    });
  }
}
export class Visit {
  public id: number | null = null;
  public visited_at: string | null = null;
  public hookup_at: string | null = null;
  public scanned_at: string | null = null;
  public comments: string | null = null;
  public medications: string = "";
  public indication: string = "";
  public read_by: string | null = null;
  public hookup_duration: number | null = null;
  public delivery_id_type: string | null = null;
  public monitor_serial_number: string | null = null;
  public tech: string | null = null;
  public dx_code: string | null = null;
  public updated_at: string | null = null;

  public patient_id: number | null = null;
  public patient_health_card: string | null = null;
  public patient_health_card_code: string | null = null;
  public patient_first_name: string | null = null;
  public patient_last_name: string | null = null;
  public patient_gender: string | null = null;
  public patient_birthdate: number | null = null;
  public patient_height: number | null = null;
  public patient_weight: number | null = null;

  public clinic_id: number | null = null;
  public clinic_name: string | null = null;
  public clinic_street: string | null = null;
  public clinic_city: string | null = null;
  public clinic_province: string | null = null;
  public clinic_postal_code: string | null = null;
  public clinic_fax: string | null = null;

  public referring_doctor_id: number | null = null;
  public referring_doctor_last_name: string | null = null;
  public referring_doctor_first_initials: string | null = null;
  public referring_doctor_name: string | null = null;

  public reading_doctor_id: number | null = null;
  public reading_doctor_last_name: string | null = null;
  public reading_doctor_first_initials: string | null = null;
  public reading_doctor_name: string | null = null;

  public owner_id: number | null = null;
  public owner_name: string | null = null;
  public owner_address: string | null = null;
  public owner_telephone: string | null = null;
  public owner_fax: string | null = null;
  public owner_abpm_price: string | null = null;

  public billing_id: number | null = null;
  public billing_items: string | null = null;
  public billing_status: string | null = null;
  public billing_balance: number | null = null;
  public billing_balance_pre_tax: number | null = null;
  public billing_invoice_link: string | null = null;
  public billing_credit_card: string | null = null;
  public billing_invoice_date: string | null = null;
  public billing_invoice_due_date: string | null = null;
  public billing_paid_on: string | null = null;
  public billing_notes: string = "";
}
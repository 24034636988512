<div>
  <div class="row sub-main">

    <div class="page-title">
      <div class="title_left">
        <h3>{{title}}</h3>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="x_panel">
      <div class="x_content">

        <br><br>

        <div class="row" [hidden]="false">
          <div class="col-12 md-width">

            <!-- <h4>Raw Data</h4> -->
            <table datatable [dtOptions]="dtOptionsRaw" [dtTrigger]="dtTrigger" class="table table-striped row-bordered datatable-buttons hover" id="raw-table">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Day / Night</th>
                  <th>Time</th>
                  <th>Systolic</th>
                  <th>Diastolic</th>
                  <th>MAP</th>
                  <th>PR</th>
                  <th>PP</th>
                  <th>Status</th>
                  <th>Excluded</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let measurement of getRawTable()">
                  <td>{{measurement.type}}</td>
                  <td>{{measurement.day ? "Day" : "Night"}}</td>
                  <td>{{measurement.time}}</td>
                  <td>{{measurement.systolic}}</td>
                  <td>{{measurement.diastolic}}</td>
                  <td>{{measurement.map}}</td>
                  <td>{{measurement.pr}}</td>
                  <td>{{measurement.pp}}</td>
                  <td>{{measurement.status}}</td>
                  <td>{{measurement.excluded}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div>
    <div class="row sub-footer">
      <div class="col-12">
        <!-- <button class="btn btn-primary btn-review" type="button" (click)="createFax()" [hidden]="hideFax()"><i class="fa fa-fax"></i> Fax</button> -->
        <!-- <button class="btn btn-primary btn-review" type="button" (click)="downloadReport()"><i class="fa fa-file-pdf-o"></i> Download</button> -->
        <button class="btn btn-primary btn-review" type="button" (click)="navigateTo('/abpm')"><i class="fa fa-times"></i> Cancel</button>
      </div>
    </div>
  </div>
</div>
export class Physician {
  public id: number | null = null;
  public updated_at: number | null = null;
  public last_name: string | null = null;
  public first_name: string | null = null;
  public first_initials: string | null = null;
  public name: string | null = null;
  public billing_number: number | null = null;
  public billing_code: string | null = null; // this is not always guarenteed to be returned because it requires a join in the backend
  public fax_number: string | null = null; // this is not always guarenteed to be returned because it requires a join in the backend
  public phone_number: string | null = null; // this is not always guarenteed to be returned because it requires a join in the backend
  public title: string | null = null;
  public roles: string | null = null;
  public clinic: any | null = null;
  public is_hidden_at: number | null = null;
}

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Router } from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';

import * as moment from "moment";
import * as download from "downloadjs";
import * as JSZip from "jszip";
import * as JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
import { ContextMenuComponent } from "ngx-contextmenu";

import { formatNotes, formatNotesOptions, formatNotesSingle, formatNotesMultiple, formatFaxNumbersMultiple } from "../../utils/format";

import { Fax } from "../../models/fax.model";
import { Holter } from "../../models/holter.model";

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalStaffNotesComponent } from '../modal-staff-notes/modal-staff-notes.component';

import { HolterService } from "../../providers/holter.service";
import { FaxService } from "../../providers/fax.service";
import { AuthService } from "../../providers/auth.service";
import { ClinicService } from "../../providers/clinic.service";
import { PhysicianService } from "../../providers/physician.service";

@Component({
  selector: 'app-fax-holter',
  templateUrl: './fax-holter.component.html',
  styleUrls: ['./fax-holter.component.scss']
})
export class FaxHolterComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;
  public dtOptions: any = {};

  @ViewChild(ContextMenuComponent)
  public suggestedMenu: ContextMenuComponent;

  public pdfSrc: any = null;
  public formGroup: FormGroup;

  public multiSelected: boolean = false;
  public currentHolter: Holter;
  public currentFax: Fax;
  public currentDoctor: any;
  public attemptLog: string;
  public faxNotes: string[] = [];
  public staffNotes: string[] = [];
  public holterStatusFilter: string | null = "Faxing";
  public invalidReferring: boolean = false;
  public repairOrder = false;
  public downloadProgress = null;

  public toBatch: any = null;
  public clinicNoFax: string = "";

  public faxNumber: string = '';
  public faxNumberAuto : any[] = [];
  public faxNumberString: any[] = [];
  public clinicFaxNumbers : any[] = [];
  public textClinicAdditionalFax: string = '';

  @ViewChild("attemptLogDiv") attemptLogDiv: ElementRef;

  public ngAfterViewInit(): void {

    const that = this;
    document.addEventListener("click",  function(e){
      if (!e.target) {
        return;
      }
      const className = (e.target as Element).className;
      if(className == "as-split-gutter-icon"){
        window.dispatchEvent(new Event('resize'));
        that.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.draw();
        });
      }
    });

    // set up the callbacks
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {

      // on search term callback
      $("#search-bar").on("keyup change", function () {
        setTimeout(() => {
          const search_string = this["value"].replace(',', '')
          if (dtInstance.search() !== search_string) {
            dtInstance.search(search_string).draw();
          }
        }, 1000);
      });
    });

    // focus on the search bar right away after load
    $("#search-bar").focus();
  }

  public ngOnInit() {}

  constructor(
    private physicianService: PhysicianService,
    private holterService: HolterService,
    private clinicService: ClinicService,
    private faxService: FaxService,
    private authService: AuthService,
    private router: Router,
    private modalService: NgbModal,
  ) {

    // roll callback
    const rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
      $("td", row).bind("click", () => this.rowClickHandler(data));
      $(".table-download-icon", row).bind("click", (event) => this.downloadFileHandler(event, data));
      return row;
    };

    const drawCallback: any = (settings) => {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
          const row = dtInstance.row(rowIdx);

          const faxes = row.data()["holter:faxes"];
          let final_faxed = false;
          let faxStatus = "";
          let faxReceived;
          if (faxes && faxes.length > 0) {
            for (let fax of faxes){
              if (fax["type"]){
                if (fax["type"].includes("final")){
                  final_faxed = true;
                  let received = new Date(fax["received_at"])
                  if ((!faxReceived) || (faxReceived < received)){
                    faxStatus = fax["status"]
                    faxReceived = new Date(received)
                  }
                }
              }
            }
          }

          if (row.data()["holter:doctor_resubmitted_report_at"]) {
            $(row.node()).addClass('resubmitted-entry');
          }
          else if (faxStatus.toUpperCase() === "FAILED") {
            $(row.node()).addClass('failed-entry');
          }
          else if (faxStatus.toUpperCase() === "FAXED" && final_faxed) {
            $(row.node()).addClass('faxed-entry');
          }
        });
      });
    };

    this.statusFilterChanged();

    // datatable options
    this.dtOptions = {
        autoWidth: false,
        responsive: true,
        lengthChange: false,
        select: true,
        pageLength: 50,
        dom: "Blfrtip",
        buttons: [],
        rowCallback: rowCallback,
        serverSide: true,
        processing: true,
        scrollY: window.innerHeight - 380,
        ajax: this.holterService.getAjaxFuncion(),
        drawCallback: drawCallback,
        order: [[ 4, "desc" ]],
        columnDefs: [{
          targets: 0,
          className: 'expand-row-column'
        },{
          targets: 1,
          className: 'download-file-column'
        }],
        columns: [
          {
            data: "holter:id",
            orderable: false,
            render: function ( data, type, row, meta ) {
              const state = row["holter:state"];
              if (row["holter:closed_by"]) {
                return "SUSPENDED";
              } else if (state != "FAXING"){
                return state;
              }
              const faxes = row["holter:faxes"];
              let faxStatus = "";
              let faxReceived;
              let final_faxed = false;
              let faxSuccessCount = 0;
              if (faxes && faxes.length > 0) {
                for (let fax of faxes){
                  if (fax["status"] == "Faxed"){
                    faxSuccessCount++;
                  }
                  if (fax["type"]){
                    if (fax["type"].includes("final")){
                      final_faxed = true;
                      let received = new Date(fax["received_at"])
                      if ((!faxReceived) || (faxReceived < received)){
                        faxStatus = fax["status"]
                        faxReceived = new Date(received)
                      }
                    }
                  }
                }
              }
              let successRatio = ""
              if (faxes) {
                successRatio = faxSuccessCount + "/" + faxes.length;
              }
              if (faxStatus.toUpperCase() === "FAXED" && final_faxed){
                return "FAXED " + successRatio;
              } else if (row["holter:skip_faxing_at"]) {
                if (faxes.length == 0) {
                  return "FAXED";
                }
                else {
                  return "FAXED " + successRatio;
                }
              } else if (faxStatus != ""){
                return faxStatus.toUpperCase() + " " + successRatio;
              } else {
                return "FAXING " + successRatio;
              }
              // return faxes && faxes.length > 0 ? faxes[0]["status"].toUpperCase() : "FAXING";
            },
          },
          {
            data: "holter:uploaded_files",
            orderable: false,
            render: function ( data, type, row, meta ) {
              const uploadedfiles = JSON.parse(data);
              const hasPdf = uploadedfiles.includes('out_01.pdf');
              const hasPat = uploadedfiles.includes('pat.001');
              const hasBackup = !!row["holter:backed_up_at"];
              const expandedFlags = HolterService.expandFlags(row["holter:flags"]);
              const hasFinal = expandedFlags["holter:flag_verified"] && row["holter:state"] === "FAXING";
              const hasReq = !!row["holter:requisition_id"];
              const hasPreliminary = expandedFlags["holter:flag_verified"] && row["holter:state"] !== "FAXING";

              let icons = '';
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Tracings"><i class="fa download-tracings fa-file-pdf-o ` + (hasPdf ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Requisition"><i class="fa download-req fa-file-o ` + (hasReq ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Preliminary"><i class="fa download-preliminary fa-file-text-o ` + (hasPreliminary ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Final Report"><i class="fa download-final fa-file-pdf-o ` + (hasFinal ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Data"><i class="fa download-backup fa-database ` + (hasBackup ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              return icons;
            },
          },
          {
            data: "patient:last_name",
            orderable: false,
            render: ( data, type, row, meta ) => {
              const last = row["patient:last_name"] || "";
              const first = row["patient:first_name"] || "";
              return [last, first].filter(x => x != "").join(", ");
            },
          },
          { data: "holter:scan_date" },
          { data: "holter:signed_off_at" },
          { data: "referring_doctor:name" },
          {
            data: "holter:faxes",
            orderable: false,
            render: function ( data, type, row, meta ) {
              const faxes = row["holter:faxes"];
              let ref_clinic_fax = row["clinic:fax"];
              if (faxes && faxes.length > 0){
                var fax_numbers = []
                var unique = ""
                for (let fax of faxes){
                  fax_numbers.push(fax["fax_number"])
                }
                unique = fax_numbers.filter((v, i, a) => a.indexOf(v) === i).join(", ");
                return unique;
              }
              else if (ref_clinic_fax){
                return (ref_clinic_fax);
              }
              else {
                return "";
              }
            },
          },
          { data: "holter:id", visible: false },
          { data: "patient:first_name", visible: false },
          { data: "holter:flags", orderable: false, visible: false },
          { data: "holter:faxes", visible: false },
          { data: "holter:state", visible: false },
          { data: "holter:requisition_id", visible: false },
          { data: "holter:backed_up_at", visible: false },
          { data: "clinic_assignment:fax_number", visible: false },
          { data: "clinic:fax", visible: false },
          { data: "referring_doctor:id", visible:false },
          { data: "clinic:id", visible:false },
          { data: "reading_doctor:name", visible:false },
          { data: "holter:skip_faxing_at", visible:false },
          { data: "holter:closed_by", visible:false },
          { data: "holter:doctor_resubmitted_report_at", visible:false },
        ],
        language: {
          infoFiltered: ""
        }
    };

    this.formGroup = new FormGroup({
      FaxNumber: new FormControl("", [ Validators.required, Validators.pattern(/^\d{10}$/), ]),
      AdditionalFaxNumbers: new FormControl("", []),
      Doctor: new FormControl("", [ Validators.required, ]),
      AttemptLog: new FormControl("", []),
      FaxNotes: new FormControl("", []),
      StaffNotes: new FormControl("", []),
      ScheduledFor: new FormControl("", []),
      FaxNotesReq: new FormControl("", []),
      FaxNotesData: new FormControl("", []),
      FaxNotesPacemaker: new FormControl("", []),
      Fax: new FormControl("", []),
    });
  }

  private updateMultiSelected(that: any) {
    that.datatableElement.dtInstance.then((dtInstance: any) => {
      const selectedData = dtInstance.rows(".selected").data();
      that.multiSelected = selectedData.length > 1;
    });
  }

  public rowClickHandler(info: any, force: boolean = false) {
    setTimeout(() => this.updateMultiSelected(this), 200);
    this.physicianService.getDoctor(info["referring_doctor:id"], info["clinic:id"]).then(doctor =>
    {
      this.currentDoctor = doctor;
      this.holterService.getHolter(info["holter:id"]).then(holter => {
        this.populateFax(holter, true);
      });
    });
  }

  public faxSelectionChanged() {
    const newFaxId = this.formGroup.get("Fax")?.value;
    return this.faxService.getFax(newFaxId).then(newFax =>
      this.populateFax(this.currentHolter, true, newFax)
    );
  }

  private populateFax(holter: Holter, reloadPreview: boolean, faxOverride: Fax = undefined)
  {
    this.invalidReferring = false;
    this.currentHolter = holter;
    this.currentFax = faxOverride !== undefined ? faxOverride : (holter.faxes ? holter.faxes[0] : null);
    let attemptLog = "";
    try {
      attemptLog = JSON.parse(this.currentFax.attempt_log).map(entry => this.stringifyAttemptLog(entry)).join("\n");
    } catch {}
    try {
      this.toBatch = JSON.parse(this.currentFax.to_fax);
    } catch {}

    // SECTION FOR DETERMINING IF THE HOLTER NEEDS FAXES OR NOT
    if (!this.currentHolter.clinic_flag_no_fax || this.currentFax){
      this.clinicNoFax = "";
      this.formGroup.patchValue({
        FaxNumber: this.currentFax?.fax_number || this.currentHolter?.clinic_fax
        // AdditionalFaxNumbers: this.currentFax ? formatFaxNumbersMultiple([this.currentFax?.clinic_additional_fax_numbers, this.currentFax?.holter_additional_fax_numbers]) : formatFaxNumbersMultiple([this.currentHolter?.clinic_additional_fax_numbers, this.currentHolter?.fax_numbers]),
      });
    }
    else {
      this.clinicNoFax = "NO FAX REQUIRED";
      this.formGroup.patchValue({
        FaxNumber: null,
        AdditionalFaxNumbers: null,
      });
    }
    this.getFaxes();
    const scheduleFor = this.currentFax?.attempt_at ? moment(this.currentFax.attempt_at).format("YYYY/MM/DD HH:mm") : "";
    this.formGroup.patchValue({
      AttemptLog: attemptLog,
      Doctor: this.currentFax?.doctor || this.currentHolter?.referring_doctor_name,
      FaxNotes: formatNotesMultiple([this.currentFax?.fax_notes, holter.requisition_fax_notes]) || formatNotesMultiple([holter.fax_notes, holter.requisition_fax_notes]),
      StaffNotes: formatNotesMultiple([holter.notes, holter.requisition_staff_notes]),
      ScheduledFor: scheduleFor,
      FaxNotesReq: this.toBatch?.notes_req ? this.toBatch?.notes_req : this.currentHolter.flag_no_req,
      FaxNotesData: this.toBatch?.notes_data ? this.toBatch?.notes_data : this.currentHolter.flag_no_data,
      FaxNotesPacemaker: this.toBatch?.notes_pacemaker ? this.toBatch?.notes_pacemaker : this.currentHolter.flag_pacemaker_inquiry,
      Fax: this.currentFax?.id || "",
    });
    this.faxNotes = formatNotesOptions(holter.fax_notes) || [];
    if (holter.requisition_fax_notes) {
      this.faxNotes.push(formatNotesSingle(holter.requisition_fax_notes));
    }
    setTimeout(() => this.setToScrollHeight(this.attemptLogDiv), 300);
    if (reloadPreview && this.currentFax) {
      this.faxService.getS3Preview(this.currentFax.id).then((url) => {
        this.pdfSrc = url;
      });
    } else if (reloadPreview) {
      // this is the default when there is no fax created yet, final report selected
      this.toBatch = {
        final: true,
        req: false,
        tracings: holter.flag_fax_with_tracing ? holter.flag_fax_with_tracing : false,
      }
      this.holterService.getMonitorReport(this.currentHolter.id)
      .then((base64Data: string) => {
        this.pdfSrc = HolterService.base64ToArrayBuffer(base64Data);
      });
    }
  }

  public stringifyAttemptLog(entry): string {
    const datetime: any = moment(entry.time[0] * 1000);
    const formattedTime = datetime.isValid() ? datetime.format("YYYY/MM/DD HH:mm") : entry.time[0];
    return formattedTime + " - " + entry.status;
  }

  private setToScrollHeight(elementRef: ElementRef) {
    if (elementRef) {
      const e = elementRef.nativeElement;
      const styleHeight = parseInt(e.style.height.substring(0, e.style.height.length - 2));
      e.style.height = (e.scrollHeight > styleHeight || isNaN(styleHeight)) ? ((e.scrollHeight + 3) + 'px') : e.style.height;
    }
  }

  private reloadTable() {
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      this.repairOrder ? dtInstance.order([[ 3, 'asc']]) : dtInstance.order([[ 4, 'desc']]);
      dtInstance.page(0).draw(false);
    });
  }

  public statusFilterChanged(): void {
    this.holterService.clearFilters();
    this.repairOrder=false;
    // need repair is a custom condition
    if (this.holterStatusFilter === "Need Repair") {
      this.holterService.updateStateFilter(["PENDING", "EDITING", "RESERVED", "READING", "FAXING"]);
      this.holterService.setCustomFilter("to_repair_faxing");
      this.holterService.updateClosedFilter(false);
      this.repairOrder=true;
      this.reloadTable();
      return;
    }

    if (this.holterStatusFilter === "No Fax") {
      this.holterService.updateStateFilter(["FAXING"]);
      this.holterService.setCustomFilter("no_faxes");
      this.reloadTable();
      return;
    }

    if (this.holterStatusFilter == "Faxed") {
      this.holterService.updateStateFilter(["FAXING"]);
      // this.holterService.updateFaxedTypeFilter('final');
      // this.holterService.updateFaxedFilter(true);
      // this.holterService.updateSkipFaxingFilter(false);
      this.holterService.setCustomFilter("custom_faxed");
      this.reloadTable();
      return;
    }

    if (this.holterStatusFilter == "Faxing") {
      this.holterService.updateStateFilter(["FAXING"]);
      this.holterService.setCustomFilter("doctor_resubmit");
      this.holterService.updateCancelledFaxFilter(false);
      // this.holterService.updateFaxedFilter(false);
      this.holterService.updateSkipFaxingFilter(false);

      this.reloadTable();
      return;
    }

    // REMOVED PER PORT 362
    // if (this.holterStatusFilter == "SKIPFAXING") {
    //   this.holterService.updateSkipFaxingFilter(true);
    //   this.reloadTable();
    //   return;
    // }

    if (this.holterStatusFilter == "CANCELLED") {
      this.holterService.updateCancelledFaxFilter(true);
      this.reloadTable();
      return;
    }

    this.holterService.updateClosedFilter(this.holterStatusFilter == "SUSPENDED");

    if (this.holterStatusFilter && this.holterStatusFilter !== "SUSPENDED") {
      this.holterService.updateStateFilter([this.holterStatusFilter]);
    } else {
      this.holterService.updateStateFilter(null);
    }

    this.reloadTable();
  }

  public faxImmediately(): Promise<any> {
    return this.datatableElement?.dtInstance.then((dtInstance: any) => {
      const updatePromises = [];
      let row_index = 0;
      dtInstance.rows(".selected").every((idx) => {
        const entry = dtInstance.row(idx).data();
        const faxes = entry["holter:faxes"];
        let found_fax = false;
        // if fax already exists and status of that fax is scheduled, change the scheduled time to immediate
        for (let fax of faxes){
          if (fax["status"] == "Scheduled"){
            found_fax = true;
            updatePromises.push(this.faxService.actionRingCentral(fax["id"], "force", row_index));
          }
        }
        // if fax doesn't exist or report was resubmitted by doctor, verify the fax and then schedule it immediately
        if (!found_fax || entry["holter:doctor_resubmitted_report_at"] || faxes.length < 0) {
          updatePromises.push(this.holterService.getHolter(entry["holter:id"]).then(holter => {
            if (holter?.referring_doctor_name && holter?.referring_doctor_fax && !holter?.clinic_flag_no_fax) {
              return this.holterService.scheduleFax(holter.id, {
                doctor: holter?.referring_doctor_name,
                fax_number: holter?.referring_doctor_fax,
                to_fax: {"final": true, "req": false, "tracings": false},
                clinic_additional_fax_numbers: holter?.clinic_additional_fax_numbers ? holter?.clinic_additional_fax_numbers : null,
                holter_additional_fax_numbers: holter?.fax_numbers ? holter?.fax_numbers : null,
              });
            }
          }).then(fax => {
            if (fax) {
              this.faxService.actionRingCentral(fax.id, "force", row_index);
              this.holterService.addNotes(fax.holter_id, "FINAL faxed out", null);
            }
          }));
        }
        row_index = row_index + 1;
      });
      return Promise.all(updatePromises).then(() => {
        this.reloadTable();
        this.holterService.getHolter(this.currentHolter.id).then(holter => this.populateFax(holter, false));
      });
    });
  }

  public retryFax(): Promise<any> {
    return this.faxService.action(this.currentFax.id, "retry")
      .then(() => {
        this.reloadTable();
        this.holterService.getHolter(this.currentHolter.id).then(holter => this.populateFax(holter, false));
      });
  }

  public cancelFax(): Promise<any> {
    // this.errorMessage = "";
    return this.faxService.action(this.currentFax.id, "cancel")
      .then(() => {
        this.reloadTable();
        this.holterService.getHolter(this.currentHolter.id).then(holter => this.populateFax(holter, false));
      });
  }

  public canFax(): boolean {
    return this.currentFax.status !== "Awaiting Verification" && this.currentFax.status !== "Scanning Document";
  }

  public verifyFax() {
    this.toBatch.notes_req = this.formGroup.get("FaxNotesReq")?.value;
    this.toBatch.notes_data = this.formGroup.get("FaxNotesData")?.value;
    this.toBatch.notes_pacemaker = this.formGroup.get("FaxNotesPacemaker")?.value;

    let toBatchString;
    toBatchString = Object.keys(this.toBatch).filter(key => this.toBatch[key] === true);

    // need to verify the referring doctor
    const doctorName = this.formGroup.get("Doctor")?.value?.name || this.formGroup.get("Doctor")?.value;
    this.physicianService.verify(doctorName)
    .then(verified => {
      this.invalidReferring = !verified;
      if (!verified) {
        return;
      }
      this.holterService.scheduleFax(this.currentHolter.id, {
        doctor: doctorName,
        fax_number: this.formGroup.get("FaxNumber")?.value,
        fax_notes: this.formGroup.get("FaxNotes")?.value,
        // clinic_additional_fax_numbers: this.currentHolter?.clinic_additional_fax_numbers ? this.currentHolter?.clinic_additional_fax_numbers : null,
        holter_additional_fax_numbers: this.faxNumberString ? JSON.stringify(this.faxNumberString) : null,
        to_fax: this.toBatch,
      }).then(() => {
        this.reloadTable();
        this.holterService.addNotes(this.currentHolter.id, this.faxTypeArray(toBatchString) + "faxed out", null);
        this.holterService.getHolter(this.currentHolter.id).then(holter => this.populateFax(holter, false));
      });
    });
  }

  public downloadFileHandler(event: any, info: any) {
    setTimeout(() => {
      $("body, .table-download-icon, tr").css("cursor", "wait");
      return this.holterService.getHolter(parseInt(info["holter:id"]))
      .then((holter: Holter | null) => {

        // no holter found with matching id
        if (!holter) {
          return;
        }

        // determine which download was clicked
        const classNames = event.target.className;
        const fileNamePrefix = holter.patient_last_name + "_" + holter.patient_first_name + "_" + holter.hookup_date + "_";
        if (classNames.includes('download-tracings')) {
          return this.holterService.getTracings(holter.id, "flattened").then(url => {
            this.downloadPDF(url, holter, 'tracing', 'pdf');
          });
        } else if (classNames.includes('download-backup')) {
          return this.holterService.backupUrls(holter.id).then(urls => {
            const zip_name = `${holter.patient_last_name}, ${holter.patient_first_name}_backup_${holter.hookup_date}_${holter.referring_doctor_name}.zip`;
            var count = 0;
            var zip = new JSZip();
            urls.forEach(url => {
              return JSZipUtils.getBinaryContent(url.url, (err, data) => {
                zip.file(url.filename, data, {binary:true});
                count++;
                this.downloadProgress = "Downloaded " + count + "/" + urls.length + " files";
                if (count == urls.length) {
                  this.downloadProgress = "Zipping files. This may take a couple of minutes. Do not navigate away from page.";
                  zip.generateAsync({type:'blob'}).then(content => {
                    saveAs(content, zip_name);
                    this.downloadProgress = null;
                  });
                }
              });
            });
          });
        } else if (classNames.includes('download-preliminary')) {
          return this.holterService.getMonitorReport(holter.id)
          .then((base64Data: string) => {
            download(HolterService.base64ToArrayBuffer(base64Data), fileNamePrefix + "prelim", "application/pdf");
          })
        } else if (classNames.includes('download-final')) {
          return this.holterService.getMonitorReport(holter.id)
          .then((base64Data: string) => {
            download(HolterService.base64ToArrayBuffer(base64Data), fileNamePrefix + "final", "application/pdf");
          })
        } else if (classNames.includes('download-req')) {
          return this.holterService.getRequisition(holter.id).then((url) => {
            this.downloadPDF(url, holter, 'req', 'pdf');
          });
        }
        return;
      })
      .then(() => {
        $("body, .table-download-icon, tr").css("cursor", "auto");
      })
      .catch(() => {
        $("body, .table-download-icon, tr").css("cursor", "auto");
      });
    }, 500);
  }

  searchDoctor = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => (!this.authService.isCardioStudyCompanyClass() || term.length < 2) ? Promise.resolve([])
        : this.physicianService.searchDoctor(term, true, false))
    )

  searchDoctorFormatter = (result: any): string => {
    return [
      result.last_name,
      result.first_initials,
      result.clinic ? result.clinic.city : null,
      result.clinic ? result.clinic.fax : null,
      result.billing_code,
    ].filter(x => !!x).join(" | ")
  };

  searchDoctorInputFormatter = (result: any): string => {
    if (!result.name) {
      return result;
    }

    this.formGroup.patchValue({
      Doctor: result.name,
      FaxNumber: result.fax_number,
    });
    return result.name;
  };

  searchFaxDoctorInputFormatter = (result: any): string => {
    if (result.clinic?.fax){
      let fax = result.clinic.fax + " - " + result.name;
      return fax;
    }
  };

  public toggleToBatch(fileType: string) {
    // not allowed to toggle fax buttons if it's already verified
    if (this.currentFax && this.currentFax.status != "Awaiting Verification") {
      return;
    }
    if (!this.toBatch) {
      this.toBatch = {};
    }
    if (this.toBatch[fileType] == null) {
      this.toBatch[fileType] = false;
    }
    this.toBatch[fileType] = !this.toBatch[fileType];

    if (fileType == "notes" && !this.toBatch[fileType]) {
      this.formGroup.patchValue({
        FaxNotesReq: this.toBatch[fileType],
        FaxNotesData: this.toBatch[fileType],
        FaxNotesPacemaker: this.toBatch[fileType],
      });
    }
  }

  public previewFaxingBatch() {
    const toBatchArg = [];
    for (const [key, value] of Object.entries(this.toBatch)) {
      if (value) {
        toBatchArg.push(key);
      }
    }

    if (this.formGroup.get("FaxNotesReq").value) {
      toBatchArg.push("notes_req");
    }
    if (this.formGroup.get("FaxNotesData").value) {
      toBatchArg.push("notes_data");
    }
    if (this.formGroup.get("FaxNotesPacemaker").value) {
      toBatchArg.push("notes_pacemaker");
    }

    const faxNotes = this.formGroup.get("FaxNotes")?.value

    $("body, button").css("cursor", "wait");
    this.holterService.getFaxBatchDocPreview(this.currentHolter.id, toBatchArg, faxNotes).then(url => {
      this.pdfSrc = url;
      $("body, button").css("cursor", "auto");
    });
  }

  public downloadFaxDocs() {
    $("body, button").css("cursor", "wait");
    this.datatableElement?.dtInstance.then((dtInstance: any) => {
      const holterIds = [];
      dtInstance.rows(".selected").every((idx) => {
        const entry = dtInstance.row(idx).data();
        holterIds.push(entry["holter:id"]);
      });
      this.holterService.bulkDownloadFax(holterIds).subscribe(response => {
        var contentDisposition = response.headers.get('content-disposition');
        saveAs(response.body, "fax_docs.zip");
        $("body, button").css("cursor", "auto");
      });
    });
  }

  public contextMenuClick(event, phrase) {
    this.formGroup.get(event.item).patchValue(phrase);
  }

  public uneditableFax(fax) {
    if (fax && fax != "Awaiting Verification") {
      return true;
    }
    return false;
  }

  public skipFaxing(){
    this.holterService.skipFaxing(this.currentHolter.id).then(() => {
      this.reloadTable();
      this.holterService.addNotes(this.currentHolter.id, "Skip Faxing", null).then(() => {
        this.holterService.getHolter(this.currentHolter.id).then(holter => this.populateFax(holter, false));
      });
    });
  }

  private downloadPDF(url: any, holter: Holter, fileType: string, extension: string) {
    this.holterService.downloadFile(url)
      .subscribe(blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = holter.patient_last_name + "_" + holter.patient_first_name + "_" + holter.hookup_date + "_" + fileType + "." + extension;
        a.click();
        URL.revokeObjectURL(objectUrl);
      });
  }

  public faxType(fileType: string) {
    let types = ""
    if (fileType.includes("final")){
      types = types + "FINAL ";
    }
    if (fileType.includes("tracings")){
      types = types + "TRACINGS ";
    }
    if (fileType.includes("preliminary")){
      types = types + "PRELIMINARY ";
    }
    if (fileType.includes("notes_req")){
      types = types + "REQ FORM ";
    }
    else if(fileType.includes("req")){
      types = types + "REQ ";
    }
    if (fileType.includes("notes_data")){
      types = types + "PATIENT DATA ";
    }
    if (fileType.includes("notes_pacemaker")){
      types = types + "PACEMAKER ";
    }
    return types;
  }

  public faxTypeArray(fileType: string[]) {
    let types = ""
    if (fileType.indexOf("final") !== -1){
      types = types + "FINAL ";
    }
    if (fileType.indexOf("tracings") !== -1){
      types = types + "TRACINGS ";
    }
    if (fileType.indexOf("preliminary") !== -1){
      types = types + "PRELIMINARY ";
    }
    if (fileType.indexOf("notes_req") !== -1){
      types = types + "REQ FORM ";
    }
    else if(fileType.indexOf("req") !== -1){
      types = types + "REQ ";
    }
    if (fileType.indexOf("notes_data") !== -1){
      types = types + "PATIENT DATA ";
    }
    if (fileType.indexOf("notes_pacemaker") !== -1){
      types = types + "PACEMAKER ";
    }
    return types;
  }

  public selectedFaxNumber(item){
    if (this.currentFax){
      return
    }
    if (this.faxNumberString.indexOf(item) > -1){
      this.faxNumberString.splice(this.faxNumberString.indexOf(item), 1)
    }
    else {
      this.faxNumberString.push(item);
    }
  }

  public getFaxes(){
    this.faxNumberAuto = []
    this.faxNumberString = []
    this.clinicFaxNumbers = []

    let holter_fax;
    let clinic_fax;
    if (this.currentFax){
      holter_fax = JSON.parse(this.currentFax?.holter_additional_fax_numbers);
      clinic_fax = JSON.parse(this.currentFax?.clinic_additional_fax_numbers);
    }
    else if (this.currentHolter){
      holter_fax = JSON.parse(this.currentHolter?.fax_numbers);
      clinic_fax = JSON.parse(this.currentDoctor?.clinic_assignment_additional_fax_numbers);
    }
    let holter_array = holter_fax ? holter_fax : [];
    let clinic_array = clinic_fax ? clinic_fax : [];
    this.clinicFaxNumbers = [...this.clinicFaxNumbers, ...clinic_array];
    this.faxNumberAuto = [...this.faxNumberAuto, ...holter_array];
    this.faxNumberAuto = [...this.faxNumberAuto, ...clinic_array];
    this.faxNumberAuto.sort((a, b) => a.localeCompare(b));
    if (!this.currentHolter.clinic_flag_no_fax || this.currentFax) {
      this.faxNumberString = [...this.faxNumberString, ...this.faxNumberAuto];
    }
  }

  public addFaxNumber() {
    let fax_num = this.formGroup.get("AdditionalFaxNumbers")?.value;
    if (typeof fax_num === "object") {
      let fax = fax_num.clinic.fax + " - " + fax_num.name;
      if (this.faxNumberAuto.indexOf(fax) == -1){
        this.faxNumberAuto.push(fax);
        this.faxNumberString.push(fax);
      }
    }
    else {
      if (this.faxNumberAuto.indexOf(fax_num) == -1 && this.formGroup.get("FaxNumber")?.value != fax_num){
        this.faxNumberAuto.push(fax_num);
        this.faxNumberString.push(fax_num);
      }
    }
    this.formGroup.patchValue({
      AdditionalFaxNumbers: ""
    });
  }

  // NOT USED
  public removeFaxNumber() {
    const index: number = this.faxNumberAuto.indexOf(this.faxNumberString);
    if (index !== -1) {
      this.faxNumberAuto.splice(index, 1);
    }
    // this.faxNumberString = '';
  }

  typeOf(value) {
    if(typeof value === "object"){
      if (value.clinic?.fax) {
        return false;
      }
      else {
        return true;
      }
    }
  }

  public addStaffNotes() {
    const modalRef = this.modalService.open(ModalStaffNotesComponent, {size:'lg'});
    modalRef.componentInstance.disable = true;
    modalRef.componentInstance.holter = this.currentHolter;
    modalRef.result.then(result => {
      this.holterService.addNotes(this.currentHolter.id, result.staffNotes, null).then(() => {
        this.holterService.getHolter(this.currentHolter.id).then(holter => this.populateFax(holter, false));
      });
    });
  }

}
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import {Observable, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';

import { AuthService } from "../../providers/auth.service";
import { PhysicianService } from "../../providers/physician.service";
import { ClinicService } from "../../providers/clinic.service";
import { MedtraceService } from "../../providers/medtrace.service";

import { formatLocaleDateSingle, formatNotesMultiple, formatNotes  } from "../../utils/format";

@Component({
  selector: 'app-modal-medtrace',
  templateUrl: './modal-medtrace.component.html',
  styleUrls: ['./modal-medtrace.component.scss']
})
export class ModalMedtraceComponent implements OnInit {

  @Input() public medtrace;
  // @Input() public doctor;
  @Input() public modifyType;
  @Input() public create;
  @Input() public add;
  // @Input() public focusCount;

  public formGroup: FormGroup;
  public invalidReferring: boolean = false;
  public createClinicNote: boolean = false;
  public createDoctorNote: boolean = false;
  public disableArchive: boolean = false;
  public disableCreate: boolean = false;
  public enableBillingNumber: boolean = false;
  public verify: boolean = false;
  public note: string = '';
  public noFaxNumber: boolean = false;

  public createFax: boolean = false;
  public textDoctorNotes: string = '';
  public textClinicNotes: string = '';
  public textClinicAdditionalFax: string = '';
  public faxNumber: string = '';

  public titleString: string = '';
  public archiveString: string = 'Archive';
  public titleClass: string = 'title-selection-blue';
  public buttonClass: string = 'title-selection-blue';

  public faxNumberAuto : any[] = [];
  public faxNumberString: number = null;

  public doctorSearch: string = '';

  public errorStrings: any = {};
  private subscr:Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    private physicianService: PhysicianService,
    private clinicService: ClinicService,
    private medtraceService: MedtraceService,
    private authService: AuthService,
  ) {
    this.formGroup = new FormGroup({
      Status: new FormControl("", [ ]),
      Serial: new FormControl("", [ ]),
      EntryDate: new FormControl("", [ ]),
      Doctor: new FormControl("", [ ]),
      Clinic: new FormControl("", [ ]),
      Zone: new FormControl("", [ ]),
    });
  }

  ngOnInit(): void {
    if (this.create) {
      this.titleClass = 'title-selection-blue';
      this.buttonClass = 'btn btn-primary btn-narrow btn-primary-blue'
      this.titleString = 'Add Medtrace';
    }
    else {
      this.titleClass = 'title-selection-orange';
      this.buttonClass = 'btn btn-primary btn-narrow'
      this.titleString = 'Change Medtrace';
      this.populateMedtrace();
    }
  }

  ngOnDestroy() {
    this.subscr.unsubscribe();
  }

  private populateMedtrace() {
    this.formGroup.reset();
    this.formGroup.patchValue({
      Status: this.medtrace.status,
      Serial: this.medtrace.serial_number,
      EntryDate: this.medtrace.hookup_date,
      // Doctor: this.medtrace.status,
      // Clinic: this.medtrace.status,
      Zone: this.medtrace.zone,
    });
  }

  public passBack(action) {
    let data = {
      status: this.formGroup.get("Status")?.value,
      serial: this.formGroup.get("Serial")?.value,
      hookup_date: this.formGroup.get("EntryDate")?.value,
      zone: this.formGroup.get("Zone")?.value,
    }
    if (action == 'add'){
      // data['doctorID'] = this.doctor?.id;
    }

    if (action == 'archive') {
      data["archive"] = true;
    }
    this.activeModal.close(data);
  }

  searchDoctor = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => (!this.authService.isCardioStudyCompanyClass() || term.length < 2) ? Promise.resolve([])
        : this.physicianService.searchDoctor(term, true, false))
    )

  searchReferringDoctorFormatter = (result: any): string => {
    return [
      result.last_name,
      result.first_initials,
      result.clinic ? result.clinic.city : null,
      result.clinic ? result.clinic.fax : null,
      result.billing_number,
    ].filter(x => !!x).join(" | ")
  };

  searchReferringDoctorInputFormatter = (result: any): string => {
    if (!result.name) {
      return result;
    }
    this.formGroup.patchValue({
      Clinic: result.clinic ? result.clinic.name : null,
    });
    // this.doctor = result;
    return result.name;
  };

}
<div class="row sub-main no-footer">

    <div class="page-title" *ngIf="holter$ | async as holter">
      <div class="title_left">
        <h3>{{title}}</h3>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="x_panel">
      <div class="x_content">

        <div class="row" [hidden]="false">
          <div class="col-12 md-width">

            <table class="table">
                <thead>
                    <tr>
                        <th width='20%'>Measurement</th>
                        <th width='16%'>PAT (Raw)</th>
                        <th width='16%'>PAT (Log UI)</th>
                        <th width='16%'>Medflux Variables</th>
                        <th width='16%'>PDF (Raw)</th>
                        <th width='16%'>Verbal (Raw)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let measurement of measurements">
                        <td>{{measurement.name}}</td>
                        <td>{{measurement.pat}}</td>
                        <td></td>
                        <td>{{measurement.merged}}</td>
                        <td>{{measurement.pdf}}</td>
                        <td>{{measurement.verbal}}</td>
                    </tr>
                </tbody>
            </table>


          </div>
        </div>

      </div>
    </div>
  </div>
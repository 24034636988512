<div>
  <div class="row sub-main">

    <div class="page-title">
      <div class="title_left">
        <h3>Doctor Directory</h3>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="x_panel">
      <div class="x_content">

        <br>

        <form class="form-horizontal form-label-left" [formGroup]="formGroup">
          <div class="row">

            <div class="col">
              <h4>Doctor Information</h4>
              <div class="form-group form-display row">
                <div class="col form-group required">
                  <label>Last Name</label>
                  <input type="text" class="form-control" placeholder="" formControlName="LastName" [attr.disabled]="!currentDoctor ? '' : null">
                </div>
                <div class="col form-group required">
                  <label>First Name</label>
                  <input type="text" class="form-control" placeholder="" formControlName="FirstName" [attr.disabled]="!currentDoctor ? '' : null">
                </div>
              </div>
              <div class="form-group form-display row">
                <div class="col-4 form-group">
                  <label>Billing No.</label>
                  <input type="text" class="form-control" placeholder="" formControlName="BillingNunber" [attr.disabled]="!currentDoctor ? '' : null">
                </div>
                <div class="col-2 form-group">
                  <label>Code</label>
                  <input type="text" class="form-control" placeholder="" formControlName="BillingCode" [attr.disabled]="!currentDoctor ? '' : null">
                </div>
              </div>
              <div class="form-group form-display row">
                <div class="col form-group">
                  <label>Title</label>
                  <input type="text" class="form-control" placeholder="" formControlName="Title" [attr.disabled]="!currentDoctor ? '' : null">
                </div>
                <div class="col form-group">
                  <label>Roles</label>
                  <input type="text" class="form-control" placeholder="" formControlName="Roles" [attr.disabled]="!currentDoctor ? '' : null">
                </div>
              </div>
            </div>

            <div class="col">
              <h4>Clinic Information</h4>
              <div class="form-group form-display row">
                <div class="col form-group required">
                  <label>Clinic Name</label>
                  <input type="text" class="form-control" placeholder="" formControlName="ClinicName" [attr.disabled]="!currentDoctor ? '' : null">
                </div>
              </div>
              <div class="form-group form-display row">
                <div class="col form-group">
                  <label>Address</label>
                  <input type="text" class="form-control" placeholder="" formControlName="Address" [attr.disabled]="!currentDoctor ? '' : null">
                </div>
              </div>
              <div class="form-group form-display row">
                <div class="col form-group">
                  <label>City</label>
                  <input type="text" class="form-control" placeholder="" formControlName="City" [attr.disabled]="!currentDoctor ? '' : null">
                </div>
                <div class="col form-group">
                  <label>Province</label>
                  <input type="text" class="form-control" placeholder="" formControlName="Province" [attr.disabled]="!currentDoctor ? '' : null">
                </div>
                <div class="col form-group">
                  <label>Postal Code</label>
                  <input type="text" class="form-control" placeholder="" formControlName="PostalCode" [attr.disabled]="!currentDoctor ? '' : null">
                </div>
              </div>
            </div>

          </div>
        </form>

        <div class="row">
          <div class="col-12">

            <div class="row">
              <div class="offset-2 col-8">
                <input type="text" class="form-control search-bar" placeholder="Start typing to search doctor..." id="search-bar">
              </div>
              <div class="col-2 text-right">
                <button class="btn btn-primary btn-review" (click)="updateDoctor()" [disabled]="!currentDoctor || !formGroup.valid" type="button">Submit</button>
              </div>
            </div>

            <div>
              <table datatable [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover">
                <thead>
                  <tr>
                    <th>Last Name</th>
                    <th>First Name</th>
                    <th>Billing Number</th>
                    <th>Code</th>
                    <th>Title</th>
                    <th>Roles</th>
                    <th>Clinic Name</th>
                    <th>City</th>
                    <th>Province</th>
                    <th>Id</th>
                  </tr>
                </thead>
              </table>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>

  <div>
    <div class="row sub-footer">
      <div class="col-12">
        <button class="btn btn-primary btn-review" type="button" (click)="disableDoctor()" [disabled]="!currentDoctor"> Disable</button>
        <button class="btn btn-primary btn-review" type="button" (click)="enableDoctor()" [disabled]="!currentDoctor"> Enable</button>
        <button class="btn btn-primary btn-review" type="button" (click)="newDoctor()"> New Doctor</button>
      </div>
    </div>
  </div>

</div>
<div class="row sub-main no-footer">

    <div class="page-title">
      <div class="title_left">
        <h3>Billing - ABPM</h3>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="x_panel">
      <div class="x_content">

        <div class="row" [hidden]="false">
          <div class="col-12">
            <div class="row">
              <div class="col-3">
                <label>Status</label>
                <select class="form-control" data-live-search="true" [(ngModel)]="abpmStatusFilter" (change)="statusFilterChanged()">
                    <option value="All">All</option>
                    <option value="Completed">Completed</option>
                    <option value="Review">Review</option>
                </select>
              </div>
              <div class="col-1 col-xl-1">
                <label>Start Date</label>
                <input type="text" class="form-control" placeholder="YYYY/MM/DD" [(ngModel)]="startDateFilter">
              </div>
              <div class="col-1 col-xl-1">
                <label>End Date</label>
                <input type="text" class="form-control" placeholder="YYYY/MM/DD" [(ngModel)]="endDateFilter">
              </div>
              <div class="col-4">
                <input type="text" class="form-control search-bar" placeholder="Start typing to search" id="search-bar">
              </div>
              <div class="col-3">
                <button class="btn btn-primary btn-review" type="button" (click)="queryAbpm()">Query</button>
              </div>
            </div>

            <div>
              <table datatable [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover" id="search-table">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Hookup Date</th>
                    <th>Health Card</th>
                    <th>Last Name</th>
                    <th>First Name</th>
                    <th>Owner</th>
                    <th>Reading Doctor</th>
                    <th>Signed Off At</th>
                  </tr>
                </thead>
              </table>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { HostListener } from '@angular/core';
import { Router } from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { environment } from "../../../environments/environment";
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import * as moment from "moment";
import * as download from "downloadjs";

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalNodataComponent } from '../modal-nodata/modal-nodata.component';

import { RequisitionService } from "../../providers/requisition.service";
import { HolterService } from "../../providers/holter.service";
import { AuthService } from "../../providers/auth.service";
import { PhysicianService } from "../../providers/physician.service";

import { formatLocaleDateSingle } from "../../utils/format";

@Component({
  selector: 'app-holter-requisition',
  templateUrl: './holter-requisition.component.html',
  styleUrls: ['./holter-requisition.component.scss']
})
export class HolterRequisitionComponent implements AfterViewInit {
  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;
  public dtOptions: any = {};

  private readonly UPLOAD_URL: string = environment.api + '/requisition';
  public dropzoneConfig: DropzoneConfigInterface | null = null;

  public multiSelected: boolean = false;
  public currentReq: any = null;

  public pendingReqs: string[] = [];
  public pdfSrc: any = null;
  public formGroup: FormGroup;
  public pdfFormGroup: FormGroup;

  public statusFilter: any = "All";
  public programFilter: any = "All";

  public currentDatatablePage: any = "";

  constructor(
    private authService: AuthService,
    private holterService: HolterService,
    private requisitionService: RequisitionService,
    private physicianService: PhysicianService,
    private router: Router,
    private modalService: NgbModal,
  ) {

    // configure dropzone
    this.dropzoneConfig = {
      url: this.UPLOAD_URL,
      maxFilesize: 50,
      paramName: "file",
      acceptedFiles: ".pdf",
      headers: { "token": this.authService.getToken() },
      params: {
        info: JSON.stringify({}),
      }
    };

    // roll callback
    const rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
      $(".fa-plus", row).bind("click", (event) => this.expandRowsHandler(event, data, true));
      $(".fa-minus", row).bind("click", (event) => this.expandRowsHandler(event, data, false));
      $("td", row).bind("click", () => this.rowClickHandler(data));
      return row;
    };

    const drawCallback: any = (settings) => {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
          const row = dtInstance.row(rowIdx);
          const isReserved = !!row.data()['requisition:reserved_by'];
          if (isReserved) {
            $(row.node()).addClass('highlighting-reserved-entry');
          }

          const isClosed = !!row.data()['requisition:closed_by'];
          if (isClosed) {
            $(row.node()).addClass('highlighting-closed-entry');
          }
        });

        if (this.statusFilter == "Reconcile")
        {
          var api = dtInstance;
          var rows = api.rows( {page:'current'} ).nodes();
          var last=null;
          var storedIndexArray = [];
          api.column(1, {page:'current'} ).data().each( function ( group, i ) {
            if ( last !== group ) {
              storedIndexArray.push(i);
              $(rows).eq( i ).before(
                  '<tr style="background-color:#dedede" class="group"><th colspan="9">'+group+ " " + "<span class='group-count'></span></td></tr>"
              );
              last = group;
            }
          });
          storedIndexArray.push(
            api.column(0, { page: "current" }).data().length
          );
          for (let i = 0; i < storedIndexArray.length - 1; i++) {
              let element = $(".group-count")[i];
              var count = storedIndexArray[i + 1] - storedIndexArray[i];
              if (count > 1) {
                $(element).text(count);
              }
          }
        }
      });
    }

    var found_status_filter = localStorage.getItem(document.location.href+'Status');
    var found_program_filter = localStorage.getItem(document.location.href+'Program');
    if (found_program_filter && found_status_filter){
      this.statusFilter = found_status_filter;
      this.programFilter = found_program_filter;
    }

    this.statusFilterChanged();

    // datatable options
    this.dtOptions = {
      autoWidth: false,
      responsive: true,
      lengthChange: false,
      select: true,
      pageLength: 50,
      dom: "Blfrtip",
      buttons: [],
      rowCallback: rowCallback,
      drawCallback: drawCallback,
      serverSide: true,
      processing: true,
      scrollY: window.innerHeight - 440,
      ajax: this.requisitionService.getAjaxFuncion(),
      order: [[ 2, "desc" ]],
      columnDefs: [{
        targets: 0,
        className: 'expand-row-column'
      },{
        targets: 1,
        className: 'download-file-column'
      }],
      columns: [
        {
          data: 'requisition:id',
          orderable: false,
          render: ( data, type, row, meta ) => {
            const duplicateCount = row["requisition:duplicate_count"];
            let expandHtml = ``;
            if (this.statusFilter == "All" || this.statusFilter == "New" || this.statusFilter == "New Data"){
              if (duplicateCount > 1 && row["requisition:status"] == "New")
              expandHtml = ` &nbsp;<a><i class="fa fa-plus expand-rows-icon"></i></a>`;
              if (duplicateCount < -1)
                expandHtml = ` &nbsp;<a><i class="fa fa-minus expand-rows-icon"></i></a>`;
            }

            return expandHtml;
          },
        },
        {
          data: "requisition:last_name" ,
          orderable: true,
          render: function ( data, type, row, meta ) {
            const last = row["requisition:last_name" ];
            const first = row["requisition:first_name"];
            if (!last && !first) {
              return "";
            } else if (!last) {
              return first;
            } else if (!first) {
              return last;
            } else {
              return last + ", " + first;
            }
          },
        },
        { data: "requisition:updated_at" },
        { data: "requisition:created_at" },
        { data: "requisition:date" },
        { data: "requisition:comments" },
        {
          data: "requisition:reserved_by",
          orderable: true,
          render: function ( data, type, row, meta ) {
            if (row["requisition:reserved_by"]){
              var reserved_name = row["requisition:reserved_by"].replace('@cardiostudy.ca','');
              return reserved_name[0].toUpperCase() + reserved_name.substr(1).toLowerCase();
            } else {
              return "";
            }
          },
        },
        { data: "requisition:status",
          render: function ( data, type, row, meta ) {
            return row["requisition:closed_by"] ? "SUSPENDED" : data;
          }
        },
        { data: "requisition:program" },
        { data: "requisition:file_name", visible: false },
        { data: "requisition:id", visible: false },
        { data: "requisition:first_name", visible: false },
        { data: "requisition:closed_by", visible: false },

      ],
      language: {
        infoFiltered: ""
      }
    };

    this.formGroup = new FormGroup({
      LastName: new FormControl("", [ Validators.required, ]),
      FirstName: new FormControl("", [ Validators.required, ]),
      HookupDate: new FormControl("", [ Validators.pattern(/^\d{4}\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/) ]),
      Comments: new FormControl("", [ Validators.required, ]),
      Program: new FormControl("", [ Validators.required, ]),
    });
    this.pdfFormGroup = new FormGroup({
      Pages: new FormControl("", [ Validators.required, Validators.pattern(/[\s,]*(\d+)[\s,]*/)]),
    });
    this.reloadRequisitions();
  }

  private updateMultiSelected(that: any) {
    that.datatableElement.dtInstance.then((dtInstance: any) => {
      const selectedData = dtInstance.rows(".selected").data();
      that.multiSelected = selectedData.length > 1;
    });
  }

  public rowClickHandler(info: any, force: boolean = false) {
    setTimeout(() => this.updateMultiSelected(this), 200);
    this.requisitionService.getRequisition(info["requisition:id"]).then(
      requisition => {
        localStorage.setItem(document.location.href+'currentEntry', String(requisition.id));
        this.populateRequisition(requisition, true)
      }
    );
  }

  public expandRowsHandler(event: any, info: any, expand: boolean) {
    const clickedId = info["requisition:id"];
    if (expand)
    {
      this.requisitionService.setCustomFilter("dupe_new_reqs_patient");
      this.requisitionService.updateStatusFilter("All");
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
          const row = dtInstance.rows().data()[rowIdx];
          if (row["requisition:id"] != clickedId) {
            return;
          }
          this.requisitionService.updateShowDuplicates(row["requisition:last_name"], row["requisition:first_name"]);
          dtInstance.page(0).draw();
        });
      });
    }
    else
    {
      if (this.statusFilter == "New Data"){
        this.requisitionService.setCustomFilter("dupe_new_reqs");
        this.requisitionService.updateShowDuplicates(null, null);
        this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.draw(false);
        });
      }
      else{
        this.statusFilterChanged();
      }
    }
  }

  public statusFilterChanged(): void {
    this.requisitionService.clearFilters();
    this.requisitionService.updateClosedFilter(false);
    if (this.programFilter != "Booking" && this.statusFilter != "Archived"){
      this.requisitionService.setBookingFilter("noBooking");
    }
    if (this.statusFilter == "Within 3 Days"){
      this.requisitionService.setCustomFilter("time");
      this.requisitionService.setTimeFilter("3", ">");
    } else if (this.statusFilter == "Beyond 3 Days"){
      this.requisitionService.setCustomFilter("time");
      this.requisitionService.setTimeFilter("3", "<");
    } else if (this.statusFilter == "Suspended"){
      this.requisitionService.updateClosedFilter(true);
    } else if (this.statusFilter == "Archived"){
      this.requisitionService.setCustomFilter("req_archive");
    } else {
      this.requisitionService.updateStatusFilter(this.statusFilter);
    }
    this.requisitionService.updateProgramFilter(this.programFilter);
    this.requisitionService.updateDocumentTypeFilter("Requisition");
    localStorage.setItem(document.location.href+'Status', String(this.statusFilter));
    localStorage.setItem(document.location.href+'Program', String(this.programFilter));
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      this.statusFilter == 'New' || this.statusFilter == 'New Data' ? dtInstance.order([[ 3, 'asc']]) : dtInstance.order([[ 2, 'desc']]);
      dtInstance.page(0).draw(false);
    });
  }

  public ngAfterViewInit(): void {
    const that = this;
    document.addEventListener("click",  function(e){
      if (!e.target) {
        return;
      }
      const className = (e.target as Element).className;
      if(className == "as-split-gutter-icon"){
        window.dispatchEvent(new Event('resize'));
        that.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.draw(false);
        });
      }
    });

    this.configureReqHeights();

    // let found_status_filter = JSON.parse(localStorage.getItem(document.location.href+'currentStatusFilter'));
    // this.programFilter = JSON.parse(localStorage.getItem(document.location.href+'currentProgramFilter'));
    // console.log(found_status_filter)

    setTimeout(() => {
      // let found_status_filter = JSON.parse(localStorage.getItem(document.location.href+'currentStatusFilter'));
      // this.programFilter = JSON.parse(localStorage.getItem(document.location.href+'currentProgramFilter'));
      // console.log(found_status_filter)
      // this.statusFilterChanged();
      // set up the callbacks
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {

        // on search term callback
        $("#search-bar").on("keyup change", function () {
          if (dtInstance.search() !== (<HTMLInputElement>this)["value"]) {
            dtInstance.search((<HTMLInputElement>this)["value"]).draw();
          }
        });

        // on page change callback
        $('#datatable').on('page.dt', function(){
          var info = dtInstance.page.info();
          that.currentDatatablePage = info.page;
          localStorage.setItem(document.location.href+'currentPage', String(that.currentDatatablePage));
        });

        //Find previous requisition and select if it exist
        let found_req = JSON.parse(localStorage.getItem(document.location.href+'currentEntry'));
        let found_page = JSON.parse(localStorage.getItem(document.location.href+'currentPage'));
        if (found_page == null) {
          found_page = 0;
        }
        if (found_req) {
          // Switch to page first then run loop through datatable
          dtInstance.page(found_page).draw(false);
          setTimeout(() => {
            dtInstance.rows().every( function ( rowIdx, tableLoop, rowLoop ) {
              const row = dtInstance.row(rowIdx);
              if (row.data()["requisition:id"] == found_req) {
                (row as any).select();
              }
            });
          }, 500);
        }
      });
    }, 2000);
    // focus on the search bar right away after load
    $("#search-bar").focus();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.configureReqHeights();
  }

  public onUploadError(event: any) {
    console.log("Dropzone Error: " + event);
    this.configureReqHeights();
    this.reloadRequisitions();
  }

  public onUploadSuccess(event) {
    this.configureReqHeights();
    this.reloadRequisitions();
  }

  private configureReqHeights() {
    const dropzoneHeight = $("#dropzone-box").height();
    $(".req-table").height(window.innerHeight - dropzoneHeight - 280);
  }

  private reloadRequisitions(): Promise<void> {
    return this.holterService.getRequisitions().then((reqs) => {
      this.pendingReqs = reqs.pending.map(x => x.file_name);
    })
  }

  private populateRequisition(requisition, reloadPreview: boolean) {
    this.currentReq = requisition;
    this.pdfFormGroup.reset();
    this.formGroup.reset();
    this.formGroup.patchValue({
      LastName: requisition.last_name,
      FirstName: requisition.first_name,
      HookupDate: formatLocaleDateSingle(requisition.date),
      Comments: requisition.comments,
      Program: requisition.program,
    });
    this.requisitionService.getRequisitionPresignedUrl(requisition.id).then(url => {
      this.pdfSrc = url;
    });
  }

  private reloadTable() {
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw(false);
    });
  }

  public renameReq() {
    this.requisitionService.renameReq(this.currentReq.id, {
      first_name: this.getFormFirstName(),
      last_name: this.getFormLastName(),
      hookup_date: this.getFormHookupDate(),
      program: this.formGroup.get("Program")?.value,
    })
    .then(() => {
      this.reloadTable();
    });
    this.requisitionService.updateComments(this.currentReq.id, this.formGroup.get("Comments")?.value);
  }

  public reportNoData() {
    const modalRef = this.modalService.open(ModalNodataComponent, {size: "lg"});
    modalRef.componentInstance.requisition = this.currentReq;
    modalRef.result.then(noDataForm => {
      noDataForm["req_id"] = this.currentReq.id;
      this.holterService.createNoDataHolter(noDataForm).then(() => {
        this.reloadTable();
      });
    });
  }

  public validToUpdate(): boolean {
    return this.formGroup.get("FirstName")?.value || this.formGroup.get("LastName")?.value || this.formGroup.get("HookupDate")?.value;
  }

  public updateStatus(status: string) {
    this.datatableElement?.dtInstance.then((dtInstance: any) => {
      const updatePromises = [];
      const remaining_count = dtInstance.data().count() - dtInstance.rows(".selected").count();
      dtInstance.rows(".selected").every((idx) => {
        const entry = dtInstance.row(idx).data();
        updatePromises.push(this.requisitionService.updateStatus(entry["requisition:id"], status));
      });
      Promise.all(updatePromises).then(() => {
        if (remaining_count == 1){
          if (this.statusFilter == "New Data"){
            this.requisitionService.setCustomFilter("dupe_new_reqs");
            this.requisitionService.updateShowDuplicates(null, null);
            dtInstance.draw(false);
          }
          else{
            this.statusFilterChanged();
          }
        }
        else{
          dtInstance.draw(false);
        }
      });
    });
  }

  public reserveReq() {
    this.datatableElement?.dtInstance.then((dtInstance: any) => {
      const updatePromises = [];
      dtInstance.rows(".selected").every((idx) => {
        const entry = dtInstance.row(idx).data();
        if (!!entry["requisition:reserved_by"]) {
          return;
        }
        const curPromise = this.requisitionService.reserve(entry["requisition:id"]);
        updatePromises.push(curPromise);
      });
      Promise.all(updatePromises).then(() => dtInstance.draw(false));
    });
  }

  public unreserveReq() {
    const token = this.authService.getToken(true);

    this.datatableElement?.dtInstance.then((dtInstance: any) => {
      const updatePromises = [];
      dtInstance.rows(".selected").every((idx) => {
        const entry = dtInstance.row(idx).data();
        if (!entry["requisition:reserved_by"] || entry["requisition:reserved_by"] != token.email) {
          return;
        }
        const curPromise = this.requisitionService.unreserve(entry["requisition:id"]);
        updatePromises.push(curPromise);
      });
      Promise.all(updatePromises).then(() => dtInstance.draw(false));
    });
  }

  public downloadReq() {
    $("body, button").css("cursor", "wait");
    this.datatableElement?.dtInstance.then((dtInstance: any) => {
      dtInstance.rows(".selected").every((idx) => {
          const entry = dtInstance.row(idx).data();
          this.requisitionService.getRequisitionPresignedUrl(entry["requisition:id"]).then((url) => {
            this.downloadPDF(url, entry, "Requisition", "pdf")
          });
          $("body, button").css("cursor", "auto");
      });
    });
  }

  private downloadPDF(url: any, req: any, fileType: string, extension: string) {
    this.holterService.downloadFile(url)
      .subscribe(blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        var hookup_date = req["requisition:date"]? req["requisition:date"] + "_": "";
        a.download = req["requisition:last_name"] + "_" + req["requisition:first_name"] + "_" + hookup_date + fileType + "." + extension;
        a.click();
        URL.revokeObjectURL(objectUrl);
      });
  }


  public getFormFirstName() {
    let value = this.formGroup.get("FirstName")?.value;
    if (typeof value === 'object' && value !== null) {
      value = value.patient.first_name;
    }
    return value;
  }

  public getFormLastName() {
    let value = this.formGroup.get("LastName")?.value;
    if (typeof value === 'object' && value !== null) {
      value = value.patient.last_name;
    }
    return value;
  }

  public getFormHookupDate() {
    let value = this.formGroup.get("HookupDate")?.value;
    if (typeof value === 'object' && value !== null) {
      value = value.hookup_date;
    }
    return value;
  }

  searchHolter = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => (term.length < 2) ? Promise.resolve([])
        : this.holterService.searchHolterForInbox(
          this.getFormFirstName(),
          this.getFormLastName(),
          this.getFormHookupDate(),
          {
            hookup_date: null,
            patient: {
              first_name: "No Match Found",
              last_name: null,
            }
          }
        ))
    )

  searchHolterFormatter = (result: any): string => {
    return [
      result.patient.first_name,
      result.patient.last_name,
      result.hookup_date ? moment(result.hookup_date).format("YYYY/MM/DD") : '',
    ].filter(x => !!x).join(" | ")
  };

  searchHolterInputFirstFormatter = (result: any): string => {
    if (typeof result === 'object' && result !== null) {
      this.formGroup.patchValue({FirstName: result.patient.first_name});
      this.formGroup.patchValue({LastName: result.patient.last_name});
      this.formGroup.patchValue({HookupDate: (result.hookup_date ? moment(result.hookup_date).format("YYYY/MM/DD") : '')});
      return result.patient.first_name;
    }
    return result;
  };

  searchHolterInputLastFormatter = (result: any): string => {
    if (typeof result === 'object' && result !== null) {
      this.formGroup.patchValue({FirstName: result.patient.first_name});
      this.formGroup.patchValue({LastName: result.patient.last_name});
      this.formGroup.patchValue({HookupDate: (result.hookup_date ? moment(result.hookup_date).format("YYYY/MM/DD") : '')});
      return result.patient.last_name;
    }
    return result;
  };

  searchHolterInputHookupDateFormatter = (result: any): string => {
    if (typeof result === 'object' && result !== null) {
      this.formGroup.patchValue({FirstName: result.patient.first_name});
      this.formGroup.patchValue({LastName: result.patient.last_name});
      this.formGroup.patchValue({HookupDate: (result.hookup_date ? moment(result.hookup_date).format("YYYY/MM/DD") : '')});
      return result.hookup_date;
    }
    return result;
  };

}
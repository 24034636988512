import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';

import { FormControl, FormGroup, Validators } from "@angular/forms";

import * as moment from "moment";

import { Holter } from "../../models/holter.model";
import { HolterService } from "../../providers/holter.service";

@Component({
  selector: 'app-home',
  templateUrl: './holter-detail-medflux.component.html',
  styleUrls: ['./holter-detail-medflux.component.scss']
})
export class HolterDetailMedfluxComponent implements OnInit {

  public error: string = "";
  public holter: Holter = new Holter();

  public title: string = "";

  public holter$: Observable<Holter>;
  public currentHolter: Holter;

  public formGroup: FormGroup;
  public measurements: any[] = [];

  public wasAutofilled: boolean = false;
  public autofillConflicts: any = {};

  constructor(
    private holterService: HolterService,
    private route: ActivatedRoute,
    private router: Router
  ) {

    // the following allows reloading of the same route
    this.router.routeReuseStrategy.shouldReuseRoute = function(){
        return false;
    };
    this.router.events.subscribe((evt) => {
        if (evt instanceof NavigationEnd) {
            this.router.navigated = false;
            window.scrollTo(0, 0);
        }
    });
  }

  public ngOnInit() {

    // get the Holter object from the server
    this.holter$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.holterService.getHolter(parseInt(params.get('id'), 10)))
    );

    // initialize form
    this.holter$.subscribe((holter: Holter) => {
        this.currentHolter = holter;

        if (holter && holter.patient_first_name && holter.patient_last_name) {
          if ((holter.patient_first_name.length + holter.patient_last_name.length) < 20) {
            this.title = "Medflux - " + holter.patient_first_name + " " + holter.patient_last_name;
          } else {
            this.title = "Medflux - Details";
          }
        } else {
          this.title = "Medflux - Details";
        }

        this.populateFormWithCurrentData();
    });

    this.formGroup = new FormGroup({
      ReferringDoctorName: new FormControl("", [ Validators.required, ]),
      FirstName: new FormControl("", [ Validators.required, ]),
      LastName: new FormControl("", [ Validators.required, ]),
      HealthCard: new FormControl("", [ Validators.required, Validators.pattern(/^\d+$/) ]),
      HealthCardVersion: new FormControl("", [ Validators.required ]),
      Birthdate: new FormControl("", [ Validators.required, Validators.pattern(/^\d{4}\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/) ]),
      Gender: new FormControl("", [ Validators.required, ]),
      Clinic: new FormControl("", [ Validators.required, ]),
      Duration: new FormControl("", []),
      ReadingDoctorName: new FormControl("", []),
      TestDate: new FormControl("", [ Validators.required, Validators.pattern(/^\d{4}\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/) ]),
      Editor: new FormControl("", []),
      Analyst: new FormControl("", []),
      Indications: new FormControl("", []),
      LeadECG: new FormControl("", []),
      TimeAnalyzed: new FormControl("", []),
      MinHR: new FormControl("", []),
      MaxHR: new FormControl("", []),
      MeanHR: new FormControl("", []),
      TotalBeats: new FormControl("", []),
      Rhythm1: new FormControl("", []),
      Rhythm2: new FormControl("", []),
      Rhythm3: new FormControl("", []),
      Arrhythmia1: new FormControl("", []),
      Arrhythmia2: new FormControl("", []),
      Arrhythmia3: new FormControl("", []),
      Arrhythmia4: new FormControl("", []),
      EventNumber1: new FormControl("", []),
      EventNumber2: new FormControl("", []),
      EventNumber3: new FormControl("", []),
      EventNumber4: new FormControl("", []),
      PauseLongestRR: new FormControl("", []),
      PauseTime: new FormControl("", []),
      PauseCount: new FormControl("", []),
      APBTotal: new FormControl("", []),
      APBPair: new FormControl("", []),
      APBSVT: new FormControl("", []),
      VPBTotal: new FormControl("", []),
      VPBPair: new FormControl("", []),
      VPBVT: new FormControl("", []),
      Symptoms: new FormControl("", []),
      Comments: new FormControl("", []),
    });
  }

  public autofilled(controlName: string): boolean {

    const exceptionNames = [
      "LastName",
      "FirstName",
      "HealthCard",
      "HealthCardVersion",
      "Birthdate",
      "ReferringDoctorName",
      "Gender",
      "Clinic",
      "TestDate",
    ];

    return (exceptionNames.indexOf(controlName) > -1) || !this.wasAutofilled || !!this.formGroup.get(controlName).value;
  }

  public conflictValue(controlName: string): string | null {
    if (!this.wasAutofilled) {
      return null;
    }
    const backendName = this.holterService.AUTOFILL_TO_FORM_MAPPING[controlName];
    if (backendName && backendName in this.autofillConflicts) {
      return this.autofillConflicts[backendName];
    }
    return null;
  }

  public clearForm(): void {
    this.formGroup.reset();
    this.wasAutofilled = false;
  }

  public autofillForm(): void {
    this.holterService.getMedfluxVars(this.currentHolter.id).then(medfluxVars => {
      this.populateFormWithAutoFill(medfluxVars);
      this.wasAutofilled = true;
    });
  }

  public undoForm(): void {
    this.populateFormWithCurrentData();
    this.wasAutofilled = false;
  }

  public submitForm(): void {
    this.updateHolter().then(() => {
      this.navigateTo('/holter/detail/report/' + this.currentHolter.id);
    });
  }

  private navigateTo(url: string): void {
    this.router.navigateByUrl(url);
  }

  private populateFormWithCurrentData(): void {
    this.formGroup.reset();
    if (!this.currentHolter) {
      return;
    }

    const medfluxDataJson = JSON.parse(this.currentHolter.medflux_data);
    this.formGroup.patchValue({
      ReferringDoctorName: this.currentHolter.referring_doctor_name,
      FirstName: this.currentHolter.patient_first_name,
      LastName: this.currentHolter.patient_last_name,
      HealthCard: this.currentHolter.patient_health_card,
      HealthCardVersion: this.currentHolter.patient_health_card_code,
      Birthdate: this.currentHolter.patient_birthdate,
      Gender: this.currentHolter.patient_gender,
      Clinic: this.currentHolter.clinic_name,
      Duration: this.currentHolter.duration,
      ReadingDoctorName: this.currentHolter.reading_doctor_name,
      TestDate: this.currentHolter.scan_date,
      Editor: medfluxDataJson ? medfluxDataJson.editor : null,
      Analyst: medfluxDataJson ? medfluxDataJson.analyst : null,
      Indications: medfluxDataJson ? medfluxDataJson.indications : null,
      LeadECG: medfluxDataJson ? medfluxDataJson.leadecg : null,
      TimeAnalyzed: medfluxDataJson ? medfluxDataJson.timeanalyzed : null,
      MinHR: medfluxDataJson ? medfluxDataJson.minhr : null,
      MaxHR: medfluxDataJson ? medfluxDataJson.maxhr : null,
      MeanHR: medfluxDataJson ? medfluxDataJson.meanhr : null,
      TotalBeats: medfluxDataJson ? medfluxDataJson.totalbeats : null,
      Rhythm1: medfluxDataJson ? medfluxDataJson.rhythm1 : null,
      Rhythm2: medfluxDataJson ? medfluxDataJson.rhythm2 : null,
      Rhythm3: medfluxDataJson ? medfluxDataJson.rhythm3 : null,
      Arrhythmia1: medfluxDataJson ? medfluxDataJson.arrhythmia1 : null,
      Arrhythmia2: medfluxDataJson ? medfluxDataJson.arrhythmia2 : null,
      Arrhythmia3: medfluxDataJson ? medfluxDataJson.arrhythmia3 : null,
      Arrhythmia4: medfluxDataJson ? medfluxDataJson.arrhythmia4 : null,
      EventNumber1: medfluxDataJson ? medfluxDataJson.eventnumber1 : null,
      EventNumber2: medfluxDataJson ? medfluxDataJson.eventnumber2 : null,
      EventNumber3: medfluxDataJson ? medfluxDataJson.eventnumber3 : null,
      EventNumber4: medfluxDataJson ? medfluxDataJson.eventnumber4 : null,
      PauseLongestRR: medfluxDataJson ? medfluxDataJson.pauselongestrr : null,
      PauseTime: medfluxDataJson ? medfluxDataJson.pausetime : null,
      PauseCount: medfluxDataJson ? medfluxDataJson.pausecount : null,
      APBTotal: medfluxDataJson ? medfluxDataJson.apbtotal : null,
      APBPair: medfluxDataJson ? medfluxDataJson.apbpair : null,
      APBSVT: medfluxDataJson ? medfluxDataJson.apbsvt : null,
      VPBTotal: medfluxDataJson ? medfluxDataJson.vpbtotal : null,
      VPBPair: medfluxDataJson ? medfluxDataJson.vpbpair : null,
      VPBVT: medfluxDataJson ? medfluxDataJson.vpbvt : null,
      Symptoms: medfluxDataJson ? medfluxDataJson.symptoms : null,
      Comments: medfluxDataJson ? medfluxDataJson.comments : null,
    });
  }

  private populateFormWithAutoFill(medfluxVars: any): void {

    const patchValueDict: any = {};
    Object.keys(this.formGroup.controls).forEach(key => {
      const autofillKey = this.holterService.AUTOFILL_TO_FORM_MAPPING[key];
      if (autofillKey !== undefined) {
        patchValueDict[key] = medfluxVars.merged[autofillKey];
      }
    });

    this.formGroup.reset();
    this.formGroup.patchValue(patchValueDict);
    Object.keys(this.formGroup.controls).forEach(key => {
      this.formGroup.get(key).markAsDirty();
      this.formGroup.get(key).markAsTouched();
    });
    this.autofillConflicts = medfluxVars.conflicts;
  }

  private updateHolter(): Promise<void> {
    return this.holterService.updateHolter(this.currentHolter.id, {
      patient: {
        first_name: this.formGroup.get("FirstName")?.value,
        last_name: this.formGroup.get("LastName")?.value,
        healthcard: this.formGroup.get("HealthCard")?.value,
        healthcard_version: this.formGroup.get("HealthCardVersion")?.value,
        birthdate: this.formGroup.get("Birthdate")?.value,
        gender: this.formGroup.get("Gender")?.value,
      },
      test_date: this.formGroup.get("TestDate")?.value,
      referring_doctor: {
        name: this.formGroup.get("ReferringDoctorName")?.value,
      },
      clinic: {
        name: this.formGroup.get("Clinic")?.value,
      },
      reading_doctor: {
        name: this.formGroup.get("ReadingDoctorName")?.value,
      },
      duration: this.formGroup.get("Duration")?.value,
      medflux_data: {
        editor: this.formGroup.get("Editor")?.value,
        analyst: this.formGroup.get("Analyst")?.value,
        indications: this.formGroup.get("Indications")?.value,
        leadecg: this.formGroup.get("LeadECG")?.value,
        timeanalyzed: this.formGroup.get("TimeAnalyzed")?.value,
        minhr: this.formGroup.get("MinHR")?.value,
        maxhr: this.formGroup.get("MaxHR")?.value,
        meanhr: this.formGroup.get("MeanHR")?.value,
        totalbeats: this.formGroup.get("TotalBeats")?.value,
        rhythm1: this.formGroup.get("Rhythm1").value,
        rhythm2: this.formGroup.get("Rhythm2").value,
        rhythm3: this.formGroup.get("Rhythm3").value,
        arrhythmia1: this.formGroup.get("Arrhythmia1").value,
        arrhythmia2: this.formGroup.get("Arrhythmia2").value,
        arrhythmia3: this.formGroup.get("Arrhythmia3").value,
        arrhythmia4: this.formGroup.get("Arrhythmia4").value,
        eventnumber1: this.formGroup.get("EventNumber1").value,
        eventnumber2: this.formGroup.get("EventNumber2").value,
        eventnumber3: this.formGroup.get("EventNumber3").value,
        eventnumber4: this.formGroup.get("EventNumber4").value,
        pauselongestrr: this.formGroup.get("PauseLongestRR")?.value,
        pausetime: this.formGroup.get("PauseTime")?.value,
        pausecount: this.formGroup.get("PauseCount")?.value,
        apbtotal: this.formGroup.get("APBTotal")?.value,
        apbpair: this.formGroup.get("APBPair")?.value,
        apbsvt: this.formGroup.get("APBSVT")?.value,
        vpbtotal: this.formGroup.get("VPBTotal")?.value,
        vpbpair: this.formGroup.get("VPBPair")?.value,
        vpbvt: this.formGroup.get("VPBVT")?.value,
        symptoms: this.formGroup.get("Symptoms")?.value,
        comments: this.formGroup.get("Comments")?.value,
      }
    });
  }
}

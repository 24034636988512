import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-chart-medtrace-holter-monitor',
  templateUrl: './chart-medtrace-holter-monitor.component.html',
  styleUrls: ['./chart-medtrace-holter-monitor.component.scss']
})
export class ChartMedtraceHolterMonitorComponent implements OnInit {
  public name: string = "ON";
  public imagePercentage: number = 10;
  public percentage: string = "%";
  public currentCount: string = "100";
  public pastCount: string = "200";

  public progress = 25;
  public progressColor = 'orange'
  public remainingProgressColor = 'white';
  @Input() chartData;

  constructor() { }

  ngOnInit(): void {
    this.populateChart()
  }

  public populateChart() {
    this.name = this.chartData.name;
    this.imagePercentage = parseInt(this.chartData.percentage);
    this.percentage = this.chartData.percentage + "%";
    this.currentCount = this.chartData.current_count;
    this.pastCount = this.chartData.past_count;
    this.progress = this.chartData.progress;
  }

  public getStyle() {
    return {'outline': 'solid', 'outline-width': 'thin', 'background':'linear-gradient(to right, '+this.progressColor+' '+this.progress+'%, '+this.remainingProgressColor+' '+this.progress+'%'}
    }

}

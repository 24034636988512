import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import * as download from "downloadjs";

import { Physician } from "../../models/physician.model";
import { Abpm } from "../../models/abpm.model";
import { Visit } from "../../models/visit.model";

import { AbpmService } from "../../providers/abpm.service";
import { HolterService } from "../../providers/holter.service";
import { VisitService } from "../../providers/visit.service";
import { PhysicianService } from "../../providers/physician.service";
import { AuthService } from "../../providers/auth.service";

@Component({
  selector: 'app-home',
  templateUrl: './abpm-interpretation.component.html',
  styleUrls: ['./abpm-interpretation.component.scss']
})
export class AbpmInterpretationComponent implements OnInit, AfterViewInit {

  public interpretation1: string = "";
  public interpretation2: string = "";
  public interpretation3: string = "";
  public interpretationNotes: string = "";
  public title: string = "";
  public error: string = "";
  public readingDoctorName: string = "";

  public abpm$: Observable<Abpm>;
  public visit: Visit = null;
  public report: any = null;
  public analysis: any = null;

  public currentPreviewType: string | null = null;
  public pdfSrc: any = null;

  public isAdmin: boolean = false;

  constructor(
    private abpmService: AbpmService,
    private visitService: VisitService,
    private physicianService: PhysicianService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {

    // the following allows reloading of the same route
    this.router.routeReuseStrategy.shouldReuseRoute = function(){
        return false;
    };
    this.router.events.subscribe((evt) => {
        if (evt instanceof NavigationEnd) {
            this.router.navigated = false;
            window.scrollTo(0, 0);
        }
    });
  }

  public ngAfterViewInit() {
    const that = this;
    document.addEventListener("click",  function(e){
      if (!e.target) {
        return;
      }
      const className = (e.target as Element).className;
      if(className == "as-split-gutter-icon"){
        window.dispatchEvent(new Event('resize'));
      }
    });
  }

  public isSuperUserOnly(roles: any[]) {
    let counts = 0;
    for (const role of roles) {
      if (role.name === "Super User") {
        counts += 1;
      }
    }
    return counts == 1;
  }

  private isAdminOnly(roles: any[]) {
    let counts = 0;
    for (const role of roles) {
      if (role.name === "Administrator") {
        counts += 1;
      }
    }
    return counts == 1;
  }

  public ngOnInit() {
    const userToken = this.authService.getToken(true);
    if (this.isAdminOnly(userToken.roles) || (this.isSuperUserOnly(userToken.roles))) {
      this.isAdmin = true;
    }

    // get the ABPM object from the server
    this.abpm$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.abpmService.getAbpm(parseInt(params.get('id'), 10)))
    );

    // generate the report
    this.abpm$.subscribe((abpm: Abpm) => {
      this.abpmService.verifyMeasurements(abpm.id).then(abpm => {
        this.previewReport(abpm, "general");

        return this.visitService.getVisit(abpm.visit_id).then((visit: Visit) => {

          if (visit) {
            if ((visit.patient_first_name.length + visit.patient_last_name.length) < 20) {
              this.title = "ABPM - " + visit.patient_first_name + " " + visit.patient_last_name;
            } else {
              this.title = "ABPM - Preliminary";
            }
          } else {
            this.title = "ABPM - Unassigned";
          }

          this.abpmService.getAnalysis(abpm.id).then(response => {
            abpm.analysis = response.analysis;
            this.analysis = response.analysis;
            this.report = response.report;
            this.visit = visit;
            this.interpretation1 = abpm.interpretation_1;
            this.interpretation2 = abpm.interpretation_2;
            this.interpretation3 = abpm.interpretation_3;
            this.interpretationNotes = abpm.interpretation_notes;
            this.readingDoctorName = visit.reading_doctor_name;
          });
        })
      });
    });
  }

  public previewReport(abpm, reportType) {
    this.currentPreviewType = reportType;
    $("body, button, .preview-panel").css("cursor", "wait");
    this.abpmService.getReportPresignedUrl(abpm.id, reportType).then(url => {
      this.pdfSrc = url;
      $("body, button, .preview-panel").css("cursor", "auto");
    });
  }

  public hide(abpm: Abpm) {
    this.abpmService.hideAbpm(abpm.id).then(abpm => {
      this.navigateTo('/abpm');
    });
  }

  public downloadReport(abpm: Abpm) {
    $("body, button, .preview-panel").css("cursor", "wait");
    this.abpmService.getReportPresignedUrl(abpm.id, this.currentPreviewType).then(url => {
      download(url);
      $("body, button, .preview-panel").css("cursor", "auto");
    });
  }

  public updateInterpretations(abpm: Abpm, interpretation1: string, interpretation2: string, interpretation3: string, notes: string): Promise<any> {
    return this.abpmService.updateInterpretations(interpretation1, interpretation2, interpretation3, notes, abpm)
      .then(() => this.previewReport(abpm, this.currentPreviewType));
  }

  public autoFill(abpm: Abpm): Promise<void> {
    return this.abpmService.predictions(abpm).then((predictions) => {
      this.interpretation1 = predictions.interpretation_1 || "";
      this.interpretation2 = predictions.interpretation_2 || "";
      this.interpretation3 = predictions.interpretation_3 || "";
    })
  }

  public disableInterpretations(abpm: Abpm): boolean {
    if (!abpm) {
      return true;
    }
    // return false;
    return abpm.status.toLowerCase() !== 'review';
  }

  public disableSignature(): boolean {
    return this.readingDoctorName === "";
  }

  public hideSignoff(abpm: Abpm): boolean {
    if (!abpm) {
      return true;
    }
    return abpm.status.toLowerCase() !== 'review';
  }

  public hideSignature(abpm: Abpm): boolean {
    if (!abpm) {
      return true;
    }
    return abpm.signoff_doctor_id === null;
  }

  public signoff(abpm: Abpm): Promise<any> {
    return this.abpmService.signoff(abpm.id, this.readingDoctorName)
      .then(() => this.navigateTo("/abpm/detail/interpretations/" + abpm.id));
  }

  public signature(abpm: Abpm): string {
    if (!abpm) {
      return "";
    }
    return abpm.signoff_doctor_name;
  }

  public navigateTo(url: string): void {
    this.router.navigateByUrl(url);
  }
}

<div class="modal-body">
    <div class="col">
        <div class="row">
            <div class="col" *ngIf="modifyType == 'clinics'">

                <form class="form-horizontal form-label-left" [formGroup]="formGroup">
                    <div class="row">
                        <div class="col form-group text-center">
                            <div [ngClass]="titleClass">{{titleString}}</div>
                        </div>
                    </div>
                    <div class="row"  *ngIf="add">
                        <div class="col form-group">
                            <label>Search</label>
                            <input type="text" class="form-control" formControlName="ClinicSearch" placeholder="Start typing to search" [ngbTypeahead]="searchClinic" [resultFormatter]="searchClinicFormatter" [inputFormatter]="searchClinicInputFormatter">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <label>Clinic Name</label>
                            <input type="text" class="form-control" placeholder="" formControlName="ClinicName">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <div class="row ml-3">
                                <div class="col">
                                    <input class="form-check-input" type="checkbox" name="check5" id="check5" formControlName="ClinicNoFaxNumber" [(ngModel)]="noFaxNumber">
                                    <label class="form-check-label" for="check5">Don't fax to hook up clinic</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <label>Fax Number</label>
                            <input type="text" class="form-control" placeholder="" formControlName="ClinicFax">
                        </div>
                        <div class="col form-group">
                            <label>Phone Number</label>
                            <input type="text" class="form-control" placeholder="" formControlName="ClinicPhone">
                        </div>
                    </div>
                    <div class="row" *ngIf="(clinic && focusCount==null) || add">
                        <div class="col-md-auto form-group">
                          <div class="row">
                            <button class="btn btn-primary btn-review btn-add-notes" type="button" (click)="addFaxNumber()" [disabled]="!textClinicAdditionalFax || typeOf(textClinicAdditionalFax)">+</button>
                          </div>
                          <div class="row">
                            <button class="btn btn-primary btn-review btn-add-notes" type="button" (click)="removeFaxNumber()" [disabled]="!faxNumberString">-</button>
                          </div>
                        </div>
                        <div class="col form-group">
                            <label>Also send to:</label>
                            <input type="text" class="form-control" placeholder="Add Doctor or Fax Number" formControlName="ClinicAdditionalFax" [(ngModel)]="textClinicAdditionalFax" [ngbTypeahead]="searchDoctor" [resultFormatter]="searchReferringDoctorFormatter" [inputFormatter]="searchFaxDoctorInputFormatter">
                            <div class="fax-container">
                                <ul>
                                    <li class="overflow-auto" *ngFor="let faxNumber of faxNumberAuto" [class.item-selected]="(faxNumber === faxNumberString)" (click)="selectedFaxNumber(faxNumber)">{{faxNumber}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <label>Address</label>
                            <input type="text" class="form-control" placeholder="" formControlName="ClinicStreet">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 form-group">
                            <label>City</label>
                            <input type="text" class="form-control" placeholder="" formControlName="ClinicCity">
                        </div>
                        <div class="col-3 form-group">
                            <label>Province</label>
                            <input type="text" class="form-control" placeholder="" formControlName="ClinicProvince">
                        </div>
                        <div class="col-3 form-group">
                            <label>Zone</label>
                            <input type="text" class="form-control" placeholder="" formControlName="ClinicZone">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <label>Postal Code</label>
                            <input type="text" class="form-control" placeholder="" formControlName="ClinicPostal">
                        </div>
                        <div class="col form-group">
                            <label>Clinic ID</label>
                            <input type="text" class="form-control" placeholder="" formControlName="ClinicID" readonly>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group" *ngIf="(clinic && focusCount==null) || add">
                            <label>Billing Code</label>
                            <input type="text" class="form-control" placeholder="" formControlName="ClinicBillingCode">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <div class="row ml-3">
                                <div class="col">
                                    <input class="form-check-input" type="checkbox" name="check3" id="check3" formControlName="CreateClinicNote" [(ngModel)]="createClinicNote">
                                    <label class="form-check-label" for="check3">Create Note</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="createClinicNote">
                        <div class="col form-group">
                            <label>Notes</label>
                            <input type="text" class="form-control" placeholder="" formControlName="ClinicNote" id="notes" [(ngModel)]="textClinicNotes" name="notes">
                        </div>
                    </div>
                    <div class="row" *ngIf="createClinicNote">
                        <div class="col form-group">
                        <label>Notes Log</label>
                        <textarea rows="3" class="form-control" placeholder="" formControlName="ClinicNoteLog" readonly></textarea>
                        </div>
                    </div>
                </form>
            </div>

            <div class="col" *ngIf="modifyType == 'doctors'">

                <form class="form-horizontal form-label-left" [formGroup]="formGroup">
                    <div class="row">
                        <div class="col form-group text-center">
                            <div [ngClass]="titleClass">{{titleString}}</div>
                        </div>
                    </div>
                    <div class="row"  *ngIf="add">
                        <div class="col form-group">
                            <label>Search</label>
                            <input type="text" class="form-control" formControlName="DoctorSearch" [(ngModel)]="doctorSearch" placeholder="Start typing to search" [ngbTypeahead]="searchReferringDoctor" [resultFormatter]="searchReferringDoctorFormatter" [inputFormatter]="searchReferringDoctorInputFormatter">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <label>Last Name <i [hidden]="!errorStrings.last_name || doctorSearch != ''" class="conflict-message-inline">{{errorStrings.last_name}}</i></label>
                            <input type="text" class="form-control" placeholder="" formControlName="DoctorLastName">
                        </div>
                        <div class="col form-group">
                            <label>First Name <i [hidden]="!errorStrings.first_name || doctorSearch != ''" class="conflict-message-inline">{{errorStrings.first_name}}</i></label>
                            <input type="text" class="form-control" placeholder="" formControlName="DoctorFirstName">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 form-group">
                            <label>Roles <i [hidden]="!errorStrings.roles || doctorSearch != ''" class="conflict-message-inline">{{errorStrings.roles}}</i></label>
                            <select class="form-control" formControlName="DoctorRoles">
                                <option value="">None</option>
                                <option value="Reading">Reading</option>
                            </select>
                        </div>
                        <div class="col-6 form-group">
                            <label>Billing Number</label>
                            <input type="text" class="form-control" placeholder="" formControlName="DoctorBillingNumber" [attr.disabled]="!create && !enableBillingNumber ? '' : null">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group" *ngIf="(doctor && focusCount==null) || add">
                            <label>Billing Code <i [hidden]="!errorStrings.billing_code" class="conflict-message-inline">{{errorStrings.billing_code}}</i></label>
                            <input type="text" class="form-control" placeholder="" formControlName="DoctorBillingCode">
                        </div>
                    </div>
                    <div class="row" *ngIf="(doctor && focusCount==null) || add">
                        <div class="col-md-auto form-group">
                          <div class="row">
                            <button class="btn btn-primary btn-review btn-add-notes" type="button" (click)="addFaxNumber()" [disabled]="!textClinicAdditionalFax || typeOf(textClinicAdditionalFax)">+</button>
                          </div>
                          <div class="row">
                            <button class="btn btn-primary btn-review btn-add-notes" type="button" (click)="removeFaxNumber()" [disabled]="!faxNumberString">-</button>
                          </div>
                        </div>
                        <div class="col form-group">
                            <label>Also send to:</label>
                            <input type="text" class="form-control" placeholder="Add Doctor or Fax Number" formControlName="ClinicAdditionalFax" [(ngModel)]="textClinicAdditionalFax" [ngbTypeahead]="searchDoctor" [resultFormatter]="searchReferringDoctorFormatter" [inputFormatter]="searchFaxDoctorInputFormatter">
                            <div class="fax-container">
                                <ul>
                                    <li class="overflow-auto" *ngFor="let faxNumber of faxNumberAuto" [class.item-selected]="(faxNumber === faxNumberString)" (click)="selectedFaxNumber(faxNumber)">{{faxNumber}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <div class="row ml-3">
                                <div class="col">
                                <input class="form-check-input" type="checkbox" name="check3" id="check3" formControlName="CreateDoctorNote" [(ngModel)]="createDoctorNote">
                                <label class="form-check-label" for="check3">Create Note</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="createDoctorNote">
                        <div class="col form-group">
                            <label>Notes</label>
                            <input type="text" class="form-control" placeholder="" formControlName="DoctorNote" id="notes" [(ngModel)]="textDoctorNotes" name="notes">
                        </div>
                    </div>
                    <div class="row" *ngIf="createDoctorNote">
                        <div class="col form-group">
                        <label>Notes Log</label>
                        <textarea rows="3" class="form-control" placeholder="" formControlName="DoctorNoteLog" readonly></textarea>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row" *ngIf="verify">
            <div class="col text-center form-group">
                <p class="conflict-message-inline">{{focusCount}} to be archived. Press the 'Archive' button again.</p>
            </div>
        </div>
        <div class="row">
            <div class="col text-center form-group" *ngIf="!create && !add && !verify && focusCount">
                <button type="submit" class="btn btn-primary btn-narrow btn-primary-blue" (click)="verifyArchive()" [disabled]="this.disableArchive">{{archiveString}}</button>
            </div>
            <div class="col text-center form-group" *ngIf="!create && !add && (verify || !focusCount)">
                <button type="submit" [ngClass]="buttonClass" (click)="passBack('archive')" [disabled]="this.disableArchive">{{archiveString}}</button>
            </div>
            <div class="col text-center form-group" *ngIf="!create && !add">
                <button type="submit" [ngClass]="buttonClass" (click)="passBack('modify')">Modify</button>
            </div>
            <div class="col text-center form-group" *ngIf="create && !add">
                <button type="submit" class="btn btn-primary btn-narrow btn-primary-blue" (click)="passBack('create')" [disabled]="this.disableCreate">Create</button>
            </div>
            <div class="col text-center form-group" *ngIf="add && !create">
                <button type="submit" class="btn btn-primary btn-narrow" (click)="passBack('add')" [disabled]="this.disableCreate">Add</button>
            </div>
        </div>
    </div>
</div>
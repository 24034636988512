import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { Physician } from "../../models/physician.model";
import { PhysicianService } from "../../providers/physician.service";
import { DataTablesResponse } from "../../models/database-response.model";

@Component({
  selector: 'app-home',
  templateUrl: './physician.component.html',
  styleUrls: ['./physician.component.scss']
})
export class PhysicianComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;

  public dtOptions: any = {};
  public formGroup: FormGroup;

  public currentDoctor: Physician | null;

  public ngOnInit() {}

  constructor(
    private physicianService: PhysicianService,
  ) {

    // roll callback
    const rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
      $("td", row).unbind("click");
      $("td", row).bind("click", () => this.rowClickHandler(data));
      return row;
    };

    // datatable options
    this.dtOptions = {
        autoWidth: false,
        responsive: true,
        lengthChange: false,
        select: true,
        pageLength: 50,
        dom: "Blfrtip",
        buttons: [],
        rowCallback: rowCallback,
        serverSide: true,
        processing: true,
        scrollY: window.innerHeight - 720,
        order: [[ 0, "asc" ]],
        ajax: this.physicianService.getAjaxFuncion(),
        columns: [
          { data: 'doctor:last_name' },
          { data: 'doctor:first_initials' },
          { data: 'doctor:billing_number' },
          { data: 'doctor:title' },
          { data: 'doctor:roles' },
          { data: 'clinic:name' },
          { data: 'clinic:city' },
          { data: 'clinic:province' },
          { data: 'doctor:id', visible: false },
        ]
    };


    this.formGroup = new FormGroup({
      LastName: new FormControl("", [ Validators.required, ]),
      FirstName: new FormControl("", [ Validators.required, ]),
      BillingNunber: new FormControl("", []),
      FaxNumber: new FormControl("", []),
      Title: new FormControl("", []),
      Roles: new FormControl("", []),
      ClinicName: new FormControl("", [ Validators.required, ]),
      Address: new FormControl("", []),
      City: new FormControl("", []),
      Province: new FormControl("", []),
      PostalCode: new FormControl("", []),
    });

  }


  public rowClickHandler(info: any, force: boolean = false) {
    // this.physicianService.getPhysician(info["doctor:id"]).then(holter => this.populateDoctor(holter));
  }

  private populateDoctor(doctor: Physician) {
    this.currentDoctor = doctor;
    this.formGroup.patchValue({
      LastName: doctor.last_name,
      FirstName: doctor.first_initials,
      BillingNunber: doctor.billing_number,
      FaxNumber: doctor.fax_number,
      Title: doctor.title,
      Roles: JSON.parse(doctor.roles).join(", "),
      ClinicName: doctor.clinic.name,
      Address: doctor.clinic.street,
      City: doctor.clinic.city,
      Province: doctor.clinic.province,
      PostalCode: doctor.clinic.postal_code,
    });
  }

  public ngAfterViewInit(): void {
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      $("#search-bar").on("keyup change", function () {
        if (dtInstance.search() !== (<HTMLInputElement>this)["value"]) {
          dtInstance.search((<HTMLInputElement>this)["value"]).draw();
        }
      });
    });
    $("#search-bar").focus();
  }

  public updateDoctor() {

  }

  public disableDoctor() {

  }

  public enableDoctor() {

  }

  public newDoctor() {

  }

}

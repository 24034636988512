import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { AuthService } from "../../providers/auth.service";

@Component({
  selector: 'app-home',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public email: string = "";
  public password: string = "";
  public errorMessage: string = "";

  public formGroup: FormGroup;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
    this.formGroup = new FormGroup({
      Email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/),
      ]),
      Password: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(20),
      ])
    });
  }

  public ngOnInit() {}

  public login(): Promise<any> {
    return this.authService.authenticate(this.formGroup.value.Email, this.formGroup.value.Password)
      .then((authenticated: string) => {
        console.log(authenticated)
        if (authenticated == "Success") {
          this.router.navigateByUrl('/abpm');
        }
        else if (authenticated == "Invalid client version"){
          this.errorMessage = "Please refresh browser. If problem presist, contact it@cardiostudy.ca.";
        }
        else if (authenticated == "Maintenance"){
          this.errorMessage = "Server under maintenance. If problem presist, contact it@cardiostudy.ca.";
        }
        else {
          this.errorMessage = "Invalid email or password";
        }
      });
  }
}
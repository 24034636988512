import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { version } from '../../../package.json';

import { environment } from "../../environments/environment";
import { AuthService } from "../providers/auth.service";
import { Abpm } from "../models/abpm.model";
import { DataTablesResponse } from "../models/database-response.model";

import { formatLocaleTime } from "../utils/format";

import * as moment from "moment";
import * as MomentTZ from "moment-timezone";

@Injectable()
export class AbpmService {

  private readonly JOB_URL: string = environment.api + "/job";
  private readonly CLIENT_NAME: string = "cardio_study";

  private readingDoctorFilter: number | null = null;
  private hiddenAbpmFilter: boolean | null = null;
  private statusFilter: any = null;
  private billingFilter: any = null;
  private customFilter: string | null = null;
  private visitNumberFilter: string | null = null;
  private serialNumberFilter: string | null = null;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  public updateReadingDoctorFilter(doctorId: number | null) {
    this.readingDoctorFilter = doctorId;
  }

  public clearFilters() {
    this.statusFilter = null;
    this.billingFilter = null;
    this.customFilter = null;
    this.visitNumberFilter = null;
    this.serialNumberFilter = null;
    this.hiddenAbpmFilter = null;
  }

  public setCustomFilter(filterName: string | null) {
    this.customFilter = this.customFilter == filterName ? null : filterName;
  }

  public updateShowDuplicates(visitID: string, serialNumber: string) {
    this.visitNumberFilter = visitID;
    this.serialNumberFilter = serialNumber;
  }

  public updateHiddenAbpmFilter(value: boolean | null) {
    this.hiddenAbpmFilter = value;
  }

  public updateBillingStatusFilter(value: string | null) {
    if (value == "All") {
      this.billingFilter = {
        regex: true,
        method: "not_like",
        value: "All",
      };
    } else {
      this.billingFilter = {
        regex: true,
        value: value,
      };
    }
  }

  public updateStatusFilter(status: string) {
    if (status == "All") {
      this.statusFilter = {
        regex: true,
        method: "not_like",
        value: "All",
      };
    } else {
      this.statusFilter = {
        regex: true,
        value: status,
      };
    }
  }

  // this is used to override the ajax call in datatables for server-side processing
  public getAjaxFuncion(): any {

    // pass this class to the callback through "that"
    const that: AbpmService = this;

    // server side rendering
    return (dataTablesParams: any, callback: any): void => {

      // populate the info in multiform part
      dataTablesParams["table"] = "abpm";

      dataTablesParams["show_duplicate"] = !!this.visitNumberFilter && !!this.serialNumberFilter;

      if (!!this.customFilter) {
        dataTablesParams["custom_abpm"] = this.customFilter;
      }


      // inject the email filter if enabled
      if (this.readingDoctorFilter !== null) {
        dataTablesParams.columns.push({
          data: 'visit:reading_doctor_id',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            value: this.readingDoctorFilter,
          },
        });
      }

      if (this.visitNumberFilter !== null) {
        dataTablesParams.columns.push({
          data: 'abpm:visit_id',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            value: this.visitNumberFilter,
          },
        });
      }

      if (this.serialNumberFilter !== null) {
        dataTablesParams.columns.push({
          data: 'abpm:serial_number',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            value: this.serialNumberFilter,
          },
        });
      }

      if (this.statusFilter !== null) {
        dataTablesParams.columns.push({
          data: 'abpm:status',
          name: '',
          searchable: true,
          orderable: true,
          search: this.statusFilter,
        });
      }

      if (this.billingFilter !== null) {
        dataTablesParams.columns.push({
          data: 'billing:status',
          name: '',
          searchable: true,
          orderable: true,
          search: this.billingFilter,
        });
      }

      if (this.hiddenAbpmFilter !== null) {
        dataTablesParams.columns.push({
          data: 'abpm:is_hidden_at',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: this.hiddenAbpmFilter ? "not_null" : "null",
            value: true,
          },
        });
      }

      const formData: FormData = new FormData();
      formData.append("info", JSON.stringify({
        client: that.CLIENT_NAME,
        job_name: "query_data",
        job_id: null,
        task_status: "create",
        data: dataTablesParams
      }));

      // request the data from url
      that.http.post(that.JOB_URL, formData, this.httpOptions()).toPromise()
        .then((response: any) => {

          // callback to the datatable to rerender with the updated information
          const result: DataTablesResponse = response.Payload.data.result;
          callback({
            recordsTotal: result.recordsTotal,
            recordsFiltered: result.recordsFiltered,
            data: result.data.map(formatLocaleTime),
          });

        })
        .catch((error: any) => {
          console.log("Error datatable handler for abpm: " + error.message);
        });
    };
  }

  public getPendingAjaxFuncion(): any {

    // pass this class to the callback through "that"
    const that: AbpmService = this;

    // server side rendering
    return (dataTablesParams: any, callback: any): void => {

      // populate the info in multiform part
      dataTablesParams["columns"][0]["search"]["value"] = "Pending";
      dataTablesParams["table"] = "abpm";
      const formData: FormData = new FormData();
      formData.append("info", JSON.stringify({
        client: that.CLIENT_NAME,
        job_name: "query_data",
        job_id: null,
        task_status: "create",
        data: dataTablesParams
      }));

      // request the data from url
      that.http.post(that.JOB_URL, formData, this.httpOptions()).toPromise()
        .then((response: any) => {

          // callback to the datatable to rerender with the updated information
          const result: DataTablesResponse = response.Payload.data.result;
          callback({
            recordsTotal: result.recordsTotal,
            recordsFiltered: result.recordsFiltered,
            data: result.data.map(formatLocaleTime),
          });

        })
        .catch((error: any) => {
          console.log("Error datatable handler for abpm: " + error.message);
        });
    };
  }

  public getAbpm(id: number): Promise<Abpm | null> {

    // populate the info in multiform part
    const query: any = {
      draw: 1,
      columns: [
        { data: "abpm:id", search: { value: id.toString(), regex: false} },
        { data: "abpm:job_id" },
        { data: "abpm:measurements" },
        { data: "abpm:uploaded_at" },
        { data: "abpm:updated_at" },
        { data: "abpm:serial_number" },
        { data: "abpm:status" },
        { data: "abpm:visit_id" },
        { data: "abpm:comments" },
        { data: "abpm:interpretation_1" },
        { data: "abpm:interpretation_2" },
        { data: "abpm:interpretation_3" },
        { data: "abpm:interpretation_notes" },
        { data: "abpm:bug_timezone" },
        { data: "signoff_doctor:id" },
        { data: "signoff_doctor:last_name" },
        { data: "signoff_doctor:first_initials" },
        { data: "signoff_doctor:name" },
      ],
      order: [],
      table: "abpm",
      start: 0,
      length: 1,
      search: {},
    };

    const formData: FormData = new FormData();
    formData.append("info", JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "query_data",
      job_id: null,
      task_status: "create",
      data: query,
    }));

    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => {
        const result: DataTablesResponse = response.Payload.data.result;
        if (result.data.length <= 0) {
          return null;
        }

        const raw: any = result.data[0];
        let abpm: Abpm = new Abpm();
        abpm.id = raw["abpm:id"];
        abpm.job_id = raw["abpm:job_id"];
        abpm.measurements = raw["abpm:measurements"];
        abpm.uploaded_at = raw["abpm:uploaded_at"];
        abpm.updated_at = raw["abpm:updated_at"];
        abpm.serial_number = raw["abpm:serial_number"];
        abpm.status = raw["abpm:status"];
        abpm.visit_id = raw["abpm:visit_id"];
        abpm.comments = raw["abpm:comments"];
        abpm.interpretation_1 = raw["abpm:interpretation_1"];
        abpm.interpretation_2 = raw["abpm:interpretation_2"];
        abpm.interpretation_3 = raw["abpm:interpretation_3"];
        abpm.interpretation_notes = raw["abpm:interpretation_notes"];
        abpm.bug_timezone = raw["abpm:bug_timezone"];
        abpm.signoff_doctor_id = raw["signoff_doctor:id"];
        abpm.signoff_doctor_last_name = raw["signoff_doctor:last_name"];
        abpm.signoff_doctor_first_initials = raw["signoff_doctor:first_initials"];
        abpm.signoff_doctor_name = raw["signoff_doctor:name"];
        return abpm;
      })
      .catch((error: any) => {
        console.log("Failed to get abpm: " + error.message);
        return null;
      });
  }

  public downloadReport(data: any): Promise<any> {

    // populate the info in multiform part
    const formData: FormData = new FormData();
    formData.append("info", JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "abpm_report",
      job_id: null,
      task_status: "create",
      data: {
        data: data,
      },
    }));

    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => {
        const pdfBase64: string = response.Payload.data;
        return pdfBase64;
      })
      .catch((error: any) => {
        console.log("Failed to generate PDF report: " + error.message);
        return null;
      });
  }

  public assignPatient(patientId: number, abpm: Abpm): Promise<any> {
    // populate the info in multiform part
    const formData: FormData = new FormData();
    formData.append("info", JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "abpm",
      job_id: abpm.job_id,
      task_status: "update",
      data: {
        abpm_id: abpm.id,
        patient_id: patientId,
      },
    }));

    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .catch((error: any) => {
        console.log("Failed to assign patient to abpm: " + error.message);
        return null;
      });
  }

  public reassignPatient(visitId: number, abpm: Abpm): Promise<any> {

    const formData: FormData = new FormData();
    formData.append("info", JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "update_data",
      job_id: null,
      task_status: "create",
      data: {
        entries: [{
          id: abpm.id,
          visit_id: visitId,
        }],
        table: "abpm",
      },
    }));

    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .catch((error: any) => {
        console.log("Failed to reassign patient to abpm: " + error.message);
        return null;
      });
  }

  public updateComments(comments: string, abpm: Abpm): Promise<any> {
    const query: any = {
      entries: [{
        id: abpm.id,
        comments: comments,
      }],
      table: "abpm",
    };

    const formData: FormData = new FormData();
    formData.append("info", JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "update_data",
      job_id: null,
      task_status: "create",
      data: query,
    }));
    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => response.Payload.data.result)
      .catch((error: any) => {
        console.log("Failed to update abpm: " + error.message);
        return null;
      });
  }

  public updateInterpretations(interpretation1: string, interpretation2: string, interpretation3: string, notes: string, abpm: Abpm): Promise<any> {
    const query: any = {
      entries: [{
        id: abpm.id,
        interpretation_1: interpretation1,
        interpretation_2: interpretation2,
        interpretation_3: interpretation3,
        interpretation_notes: notes,
      }],
      table: "abpm",
    };

    const formData: FormData = new FormData();
    formData.append("info", JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "update_data",
      job_id: null,
      task_status: "create",
      data: query,
    }));
    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => response.Payload.data.result)
      .catch((error: any) => {
        console.log("Failed to update abpm: " + error.message);
        return null;
      });
  }

  public createFax(report: any): Promise<any> {

    const formData: FormData = new FormData();
    formData.append("info", JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "ocr_fax",
      job_id: null,
      task_status: "create",
      data: {},
    }));

    const pdfBlob = this.dataURItoBlob(report.base64);
    const pdfFile = new File([pdfBlob], report.name, { type: 'application/pdf' });
    formData.append("file", pdfFile);

    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => response.Payload.data)
      .catch((error: any) => {
        console.log("Failed to create fax: " + error.message);
        return null;
      });
  }

  public getAnalysis(abpmId: number): Promise<any> {

    const url: string = environment.api + "/abpm/" + abpmId + "/analyze";

    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => {

        // need to convert time to moment
        const measurementGroups: string[] = ["systolic", "diastolic", "map", "pulseRate", "pulsePressure"];
        const timeGroups: string[] = ["total", "day", "evening"];
        for (const time of timeGroups) {
          for (const measurement of measurementGroups) {
            const currentDict: any = response.analysis[measurement][time];

            if (!!currentDict.max) {
              currentDict.max.time = moment.utc(currentDict.max.time);
            }

            if (!!currentDict.min) {
              currentDict.min.time = moment.utc(currentDict.min.time);
            }

            for (const entry of currentDict.raw) {
              entry.time = moment.utc(entry.time);
            }

            currentDict.graphX = currentDict.graphX.map(entry => moment.utc(entry).toDate());
          }
        }

        return response;
      })
      .catch((err: any) => {
        console.log("Failed to analyze abpm: " + err.message);
        return ;
      });

  }

  public predictions(abpm: Abpm): Promise<any> {
    const url = environment.api + '/abpm/' + abpm.id + "/analyze/predict";
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public verifyMeasurements(abpmId: number): Promise<any> {
    const url = environment.api + '/abpm/' + abpmId + "/verify";
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  // reportType is either "lds" or "general'"
  public getReportPresignedUrl(abpmId: number, reportType: string = "general"): Promise<any> {
    const url = environment.api + '/abpm/' + abpmId + "/report/presigned-url?type=" + reportType;
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public signoff(abpmId: number, doctorName: string): Promise<any> {
    const url = environment.api + '/abpm/' + abpmId + "/signoff";
    return this.http.post(url, {"reading_doctor_name": doctorName}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public updateAbpm(abpmId: number, data: any): Promise<any> {
    const url = environment.api + '/abpm/' + abpmId;
    return this.http.post(url, data, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public hideAbpm(abpmId: number): Promise<any> {
    const url = environment.api + '/abpm/' + abpmId + '/hide';
    return this.http.post(url, {}, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  private dataURItoBlob(dataURI): any {

    const byteString = atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
    return blob;
  }

  private httpOptions(): any {
    return { headers: new HttpHeaders({
      "token": this.authService.getToken(),
      "version": version,
    }) };
  }

  public exportQuery(status, debug, start?, end?): Observable<any> {
    const queryParams = [];
    queryParams.push('all=');
    if (debug) {
      queryParams.push('debug=' + debug)
    }
    if (status) {
      queryParams.push('status=' + status)
    }
    if (start) {
      queryParams.push('start=' + start)
    }
    if (end) {
      queryParams.push('end=' + end)
    }
    const url = environment.api + '/abpm/query?' + queryParams.join("&");
    const options = this.httpOptions();
    options.responseType = "blob";
    return this.http.get(url, options);
  }

}
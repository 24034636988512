import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { version } from '../../../package.json';

import { environment } from "../../environments/environment";
import { AuthService } from "../providers/auth.service";
import { Clinic } from "../models/clinic.model";
import { DataTablesResponse } from "../models/database-response.model";

import { formatLocaleTime } from "../utils/format";

import { Observable } from "rxjs";

@Injectable()
export class ClinicService {

  private readonly JOB_URL: string = environment.api + "/job";
  private readonly CLIENT_NAME: string = "cardio_study";

  private hiddenFilter: boolean | null = null;
  private hiddenClinicFilter: boolean | null = null;
  private doctorClinicsFilter: string | null = null;
  private repairFilter: boolean | null = null;
  private customFilter: string | null = null;
  private customDirectoryFilter: string[] | null = null;
  private billingCodeFilter: boolean | null = null;
  private faxNumberDuplicateFilter: string | null = null;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  public clearFilters() {
    // this.srcFilter = null;
    this.hiddenFilter = null;
    this.hiddenClinicFilter = null;
    this.doctorClinicsFilter = null;
    this.repairFilter = null;
    this.customFilter = null;
    this.customDirectoryFilter = null;
    this.billingCodeFilter = null;
    this.faxNumberDuplicateFilter = null;
  }

  public updateHiddenFilter(value: boolean | null) {
    this.hiddenFilter = value;
  }

  public updateRepairFilter(value: boolean | null) {
    this.repairFilter = value;
  }

  public updateHiddenDoctorFilter(value: boolean | null) {
    this.hiddenClinicFilter = value;
  }

  public updateDoctorClinicsFilter(value: string | null) {
    this.doctorClinicsFilter = value;
  }

  public setCustomFilter(filterName: string | null) {
    this.customFilter = this.customFilter == filterName ? null : filterName;
  }

  public setCustomDirectoryFilter(filterNames: string[] | null) {
    this.customDirectoryFilter =filterNames;
  }

  public updateBillingCodeFilter(value: boolean | null) {
    this.billingCodeFilter = value;
  }

  public updateShowDuplicates(faxNumber: string) {
    this.faxNumberDuplicateFilter = faxNumber;
  }

  // this is used to override the ajax call in datatables for server-side processing
  public getAjaxFuncion(): any {

    // pass this class to the callback through "that"
    const that: ClinicService = this;

    // server side rendering
    return (dataTablesParams: any, callback: any): void => {

      // populate the info in multiform part
      dataTablesParams["table"] = "clinic";

      dataTablesParams["show_duplicate"] = !!this.faxNumberDuplicateFilter;

      if (!!this.customFilter) {
        dataTablesParams["custom_clinic"] = this.customFilter;
        dataTablesParams["duplicate_fax_number"] = this.faxNumberDuplicateFilter;
      }

      if (!!this.customDirectoryFilter) {
        dataTablesParams["custom_clinic_directory"] = this.customDirectoryFilter;
      }

      if (this.hiddenFilter !== null) {
        dataTablesParams.columns.push({
          data: 'clinic:is_hidden_at',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: this.hiddenFilter ? "not_null" : "null",
            value: true,
          },
        });
      }

      if (this.repairFilter !== null) {
        dataTablesParams.columns.push({
          data: 'clinic:fax',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: this.repairFilter ? "not_null" : "null",
            value: true,
          },
        });
      }

      // if (this.repairFilter !== null) {
      //   dataTablesParams.columns.push({
      //     data: 'clinic:fax',
      //     name: '',
      //     searchable: true,
      //     orderable: true,
      //     search: {
      //       regex: true,
      //       method: this.repairFilter ? "null_neq" : "eq",
      //       value: "",
      //     },
      //   });
      // }

      // if (this.faxNumberDuplicateFilter !== null) {
      //   dataTablesParams.columns.push({
      //     data: 'clinic:fax',
      //     name: '',
      //     searchable: true,
      //     orderable: true,
      //     search: {
      //       regex: true,
      //       value: this.faxNumberDuplicateFilter,
      //     },
      //   });
      // }

      if (this.doctorClinicsFilter !== null) {
        dataTablesParams.columns.push({
          data: 'clinic_assignment:doctor_id',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            value: this.doctorClinicsFilter,
          },
        });
      }

      if (this.hiddenClinicFilter !== null) {
        dataTablesParams.columns.push({
          data: 'clinic_assignment:is_hidden_at',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: this.hiddenClinicFilter ? "not_null" : "null",
            value: true,
          },
        });
      }

      if (this.billingCodeFilter !== null) {
        dataTablesParams.columns.push({
          data: 'clinic_assignment:billing_code',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: this.billingCodeFilter ? "not_null" : "null",
            value: true,
          },
        });
      }

      const formData: FormData = new FormData();
      formData.append("info", JSON.stringify({
        client: that.CLIENT_NAME,
        job_name: "query_data",
        job_id: null,
        task_status: "create",
        data: dataTablesParams
      }));

      // request the data from url
      that.http.post(that.JOB_URL, formData, this.httpOptions()).toPromise()
        .then((response: any) => {

          // callback to the datatable to rerender with the updated information
          const result: DataTablesResponse = response.Payload.data.result;
          callback({
            recordsTotal: result.recordsTotal,
            recordsFiltered: result.recordsFiltered,
            data: result.data.map(formatLocaleTime),
          });

        })
        .catch((error: any) => {
          console.log("Error datatable handler for Clinic: " + error.message);
        });
    };
  }

  public searchClinic(term: string): Promise<Clinic[] | null> {
    // populate the info in multiform part
    const query: any = {
      draw: 1,
      columns: [
        { data: "clinic:street", search: { value: term, regex: false} },
        { data: "clinic:id"},
      ],
      order: [],
      table: "clinic",
      start: 0,
      length: 15,
      search: {},
    };

    const formData: FormData = new FormData();
    formData.append('info', JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "query_data",
      job_id: null,
      task_status: "create",
      data: query,
    }));

    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => {
        const result: DataTablesResponse = response.Payload.data.result;
        if (result.data.length <= 0) {
          return null;
        }

        let clinics: Clinic[] = [];
        for (const raw of result.data) {
          let clinic: Clinic = new Clinic();
          clinic.street = raw["clinic:street"];
          clinic.id = raw["clinic:id"];
          clinics.push(clinic);
        }
        return clinics;
      })
      .catch((error: any) => {
        console.log("Failed to search clinics: " + error.message);
        return null;
      });
  }

  public getClinicByHash(hash: string): Promise<any> {

    // populate the info in multiform part
    const query: any = {
      draw: 1,
      columns: [
        { data: "clinic:name" },
        { data: "clinic:street" },
        { data: "clinic:hash", search: { value: hash, regex: true} },
        { data: "clinic:id" },
      ],
      order: [],
      table: "clinic",
      start: 0,
      length: 1,
      search: {},
    };

    const formData: FormData = new FormData();
    formData.append('info', JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "query_data",
      job_id: null,
      task_status: "create",
      data: query,
    }));

    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => {
        const result: DataTablesResponse = response.Payload.data.result;
        if (result.data.length <= 0) {
          return null;
        }

        const raw: any = result.data[0];
        let clinic: Clinic = new Clinic();
        clinic.name = raw["clinic:name"];
        clinic.street = raw["clinic:street"];
        clinic.hash = raw["clinic:hash"];
        clinic.id = raw["clinic:id"];
        return clinic;
      })
      .catch((error: any) => {
        console.log("Failed to get clinic: " + error.message);
        return null;
      });
  }

  public createClinic(clinic: Clinic, update: boolean = false): Promise<Clinic | any> {

    // populate the info in multiform part
    const query: any = {
      entries: [{
        id: clinic.id,
        name: clinic.name,
        street: clinic.street,
        city: clinic.city,
        province: clinic.province,
        postal_code: clinic.postal_code,
        fax: clinic.fax,
        hash: clinic.hash,
      }],
      table: "clinic",
    };

    const formData: FormData = new FormData();
    formData.append('info', JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: update ? "update_data" : "post_data",
      job_id: null,
      task_status: "create",
      data: query,
    }));

    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => response.Payload.data.result)
      .catch((error: any) => {
        console.log("Failed to create clinic: " + error.message);
        return null;
      });
  }

  public searchClinics(term: string): Promise<any> {
    const url = environment.api + '/clinic?search=' + encodeURIComponent(term);
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public searchClinicsWithDoctors(term: string): Promise<any> {
    const url = environment.api + '/clinic/doctor?search=' + encodeURIComponent(term);
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getClinic(id: number): Promise<any> {
    const url = environment.api + '/clinic/' + id;
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public insertClinic(data: any): Promise<any> {
    const url = environment.api + '/clinic';
    return this.http.post(url, data, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public updateClinic(id: number, data: any): Promise<any> {
    const url = environment.api + '/clinic/' + id;
    return this.http.post(url, data, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public hideClinic(id: number, data: any): Promise<any> {
    const url = environment.api + '/clinic/' + id + '/hide';;
    return this.http.post(url, data, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public verify(name: string): Promise<any> {
    const url = environment.api + '/clinic/verify?name=' + encodeURIComponent(name);
    return this.http.get(url,this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public addNotes(clinicId: number, staffNotes: string): Promise<any> {
    const data = {
      notes: staffNotes,
    };
    const url = environment.api + '/clinic/' + clinicId + "/notes";
    return this.http.post(url, data, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  private httpOptions(): any {
    return { headers: new HttpHeaders({
      "token": this.authService.getToken(),
      "version": version,
    }) };
  }

  public getReconcileCount(): Promise<any> {
    const url = environment.api + '/clinic/reconcile';
    return this.http.get(url,this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

}
<div>
    <div class="row sub-main">

      <div class="page-title">
        <div class="title_left">
          <h3>Research</h3>
        </div>
      </div>
      <div class="clearfix"></div>

      <div class="x_panel">
        <div class="x_content">

          <div class="row split-container">

            <as-split direction="horizontal">
              <as-split-area [size]="50">
                <div class="col">
                  <div class="row">
                    <div class="col-3">
                      <label>Source</label>
                      <select class="form-control" data-live-search="true" [(ngModel)]="sourceFilter" (change)="sourceSelectionChanged()">
                        <option value=""></option>
                        <option value="pdf">PDF TRACINGS</option>
                        <option value="pat">PAT.001</option>
                        <option value="report">REPORT</option>
                      </select>
                    </div>
                    <div class="col-3">
                      <label>Anchor Search</label>
                      <input type="text" class="form-control search-bar" [(ngModel)]="anchorFilter" placeholder="Start typing to search" id="anchor-search-bar" [attr.disabled]="anchorSelectFilter || sourceFilter == 'report' ? '' : null">
                    </div>
                    <div class="col-3">
                      <label>Anchor Term</label>
                      <select class="form-control" [(ngModel)]="anchorSelectFilter" [attr.disabled]="anchorFilter || sourceFilter == 'report' ? '' : null">
                        <option disabled value="null">Select anchor</option>
                        <option value=""></option>
                        <option *ngFor="let item of anchorList" [value]="item">
                           {{ item }}
                        </option>
                      </select>
                    </div>
                    <div class="col-3">
                      <label>Search Term</label>
                      <input type="text" class="form-control search-bar" [(ngModel)]="searchFilter" placeholder="Start typing to search" id="phrase-search-bar">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-9">
                      <label>Search Table Below</label>
                      <input type="text" class="form-control search-bar" placeholder="Start typing to search" id="search-bar">
                    </div>
                    <div class="col-3">
                      <button class="btn btn-primary btn-review" type="button" (click)="search()">Search</button>
                  </div>
                  </div>
                  <table datatable [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover" id="not-pending-table">
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Name</th>
                        <th>Scan Date</th>
                        <th>Doctor Signed</th>
                        <th>Referring</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </as-split-area>
              <as-split-area [size]="50">
                <div class="col info-panel" *ngIf="currentHolter">
                  <div class="row">
                    <div class="col-6">
                      <div class="row">
                        <button class="btn btn-primary btn-narrow" type="button" (click)="showTracings()" [disabled]="showPdfPreview === 'tracings'">Tracings</button>
                        <button class="btn btn-primary btn-narrow" type="button" (click)="showRequisition()" [disabled]="showPdfPreview === 'requisition'">Requisition</button>
                        <button class="btn btn-primary btn-narrow" type="button" (click)="showReport()" [disabled]="showPdfPreview === 'report'">Report</button>
                        <button class="btn btn-primary btn-narrow" type="button" (click)="showPat()" [disabled]="showPdfPreview === 'pat'">Pat</button>
                      </div>
                    </div>
                    <!-- TRIED TO ENABLE SEARCH ON DATA -->
                    <div class="col-3" [hidden]="true">
                      <label>Search Key</label>
                      <input type="text" class="form-control search-bar" [(ngModel)]="searchKeyFilter" placeholder="Start typing to search" id="search-bar-key" (ngModelChange)="searchKey($event)">
                    </div>
                    <div class="col-3" [hidden]="true">
                      <label>Search Value</label>
                      <input type="text" class="form-control search-bar" [(ngModel)]="searchValueFilter" placeholder="Start typing to search" id="search-bar-value" (change)="searchValue()">
                    </div>
                  </div>
                  <div class="preview-panel">
                    <div *ngIf="pdfSrc && showPdfPreview != 'pat'">
                      <pdf-viewer [src]="pdfSrc" [render-text]="false" [original-size]="false" [fit-to-page]="true" style="display: block;"></pdf-viewer>
                    </div>
                    <div *ngIf="showPdfPreview == 'pat'">
                      <table class="table table-striped">
                        <thead>
                            <tr>
                              <th>Key</th>
                              <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let pat of patDataJson | keyvalue:orderByKey">
                            <td>{{ pat.key }}</td>
                            <td>{{ pat.value }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </as-split-area>
            </as-split>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="row sub-footer">
        <div class="col-12">

        </div>
      </div>
    </div>

  </div>

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Abpm } from "../../models/abpm.model";
import { AbpmService } from "../../providers/abpm.service";
import { AbpmCalculationsService } from '../../providers/abpm-calculations.service';
import { Visit } from "../../models/visit.model";
import { VisitService } from "../../providers/visit.service";

@Component({
  selector: 'app-home',
  templateUrl: './abpm-graphical.component.html',
  styleUrls: ['./abpm-graphical.component.scss']
})
export class AbpmGraphicalComponent implements OnInit {

  public title: string = "";

  public abpm$: Observable<Abpm>;
  public visit: Visit = null;
  public report: any = null;

  public graph: any = {};

  constructor(
    private abpmService: AbpmService,
    private calculate: AbpmCalculationsService,
    private visitService: VisitService,
    private route: ActivatedRoute,
    private router: Router
  ) {

    this.graph = {
      data: [
        { x: [], y: [], type: 'scatter', mode: 'lines+markers', marker: {color: 'red'}, name: "Systolic", yaxis: "y2" },
        { x: [], y: [], type: 'scatter', mode: 'lines+markers', marker: {color: 'blue'}, name: "Diastolic", yaxis: "y2" },
        { x: [], y: [], type: 'scatter', mode: 'lines+markers', marker: {color: 'purple'}, name: "MAP", yaxis: "y2" },
        { x: [], y: [], type: 'bar', marker: {color: 'orange'}, name: "Heart Rate" },
        { x: [], y: [], type: 'scatter', mode: 'lines+markers', marker: {color: 'black'}, name: "Pulse Pressure", yaxis: "y2" },
      ],
      layout: {
        autosize: true,
        title: 'Measurements',
        height: 680,
        yaxis: { title: "Heart Rate (BPM)", side: "right", range: [40, 300]},
        yaxis2: { title: "Blood Pressure (mmHg)", overlaying: "y", range: [0, 280]},
        shapes: []
      }
    };

  }

  public ngOnInit() {

    // get the ABPM object from the server
    this.abpm$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.abpmService.getAbpm(parseInt(params.get('id'), 10)))
    );

    // generate the report
    this.abpm$.subscribe((abpm: Abpm) => {

      return this.visitService.getVisit(abpm.visit_id).then((visit: Visit) => {

        if (visit) {
          if ((visit.patient_first_name.length + visit.patient_last_name.length) < 20) {
            this.title = "ABPM - " + visit.patient_first_name + " " + visit.patient_last_name;
          } else {
            this.title = "ABPM - Graphical";
          }
        } else {
          this.title = "ABPM - Unassigned";
        }

        this.abpmService.getAnalysis(abpm.id).then(response => {
          abpm.analysis = response.analysis;
          this.report = response.report;
          this.visit = visit;

          this.graph.data[0].x = abpm.analysis.systolic.total.graphX;
          this.graph.data[0].y = abpm.analysis.systolic.total.graphY;
          this.graph.data[1].x = abpm.analysis.diastolic.total.graphX;
          this.graph.data[1].y = abpm.analysis.diastolic.total.graphY;
          this.graph.data[2].x = abpm.analysis.map.total.graphX;
          this.graph.data[2].y = abpm.analysis.map.total.graphY;
          this.graph.data[3].x = abpm.analysis.pulseRate.total.graphX;
          this.graph.data[3].y = abpm.analysis.pulseRate.total.graphY;
          this.graph.data[4].x = abpm.analysis.pulsePressure.total.graphX;
          this.graph.data[4].y = abpm.analysis.pulsePressure.total.graphY;

          this.graph.layout.xaxis.range = [this.report.graphicalBounds.time.start, this.report.graphicalBounds.time.end];
          this.graph.layout.yaxis.range = [this.report.graphicalBounds.rate.min, this.report.graphicalBounds.rate.max];
          this.graph.layout.yaxis2.range = [this.report.graphicalBounds.pressure.min, this.report.graphicalBounds.pressure.max];

          const times = abpm.analysis.pulsePressure.total.graphX;
          const start = new Date(times[0]);
          const end = new Date(times[times.length - 1]);
          this.graph.layout.shapes = this.generateThresholdLines(start, end)
        });
      });
    });
  }

  private generateThresholdLines(start, end) {

    if (!start || !end) {
      return [];
    }

    let day = [];
    let night = [];

    const DAY_START = 6
    const DAY_END = 22
    const isDay = x => x.getHours() >= DAY_START && x.getHours() < DAY_END;

    // create the first one
    if (isDay(start)) {
      day.push({
        start: new Date(start),
        end: null,
      });
    } else {
      night.push({
        start: new Date(start),
        end: null,
      });
    }

    // loop until the end
    let curTime = start;
    while (curTime < end) {

      if (isDay(curTime)) {
        const edge = new Date(curTime.setHours(DAY_END));
        day[day.length - 1].end = edge;
        night.push({
          start: edge,
          end: null,
        });
        curTime = new Date(edge.getTime() + 3600000);

      } else {
        const edge = new Date(new Date(curTime.getTime() + (24 * 3600000)).setHours(DAY_START));
        night[night.length - 1].end = edge;
        day.push({
          start: edge,
          end: null,
        });
        curTime = new Date(edge.getTime() + 3600000);

      }
    }

    // handle the last one
    if (isDay(end)) {
      day[day.length - 1].end = end;
    } else {
      night[night.length - 1].end = end;
    }

    // convert to shapes json
    let shapes = [];
    for (const entry of day) {
      if (!entry.end) {
        continue;
      }
      shapes.push({
        type: 'line',
        yref: 'y2',
        y0: 135,
        y1: 135,
        xref: 'x',
        x0: entry.start,
        x1: entry.end,
        line: {color: 'black', width: 2},
      });
      shapes.push({
        type: 'line',
        yref: 'y2',
        y0: 85,
        y1: 85,
        xref: 'x',
        x0: entry.start,
        x1: entry.end,
        line: {color: 'black', width: 2},
      });
    }
    for (const entry of night) {
      if (!entry.end) {
        continue;
      }
      shapes.push({
        type: 'line',
        yref: 'y2',
        y0: 120,
        y1: 120,
        xref: 'x',
        x0: entry.start,
        x1: entry.end,
        line: {color: 'black', width: 2},
      });
      shapes.push({
        type: 'line',
        yref: 'y2',
        y0: 70,
        y1: 70,
        xref: 'x',
        x0: entry.start,
        x1: entry.end,
        line: {color: 'black', width: 2},
      });
    }

    return shapes;
  }

  public navigateTo(url: string): void {
    this.router.navigateByUrl(url);
  }
}

<div class="modal-body">
    <div class="col">
        <div class="row">
            <div class="col">
                <form class="form-horizontal form-label-left" [formGroup]="formGroup">
                    <div class="row">
                        <div class="col form-group text-center">
                            <div class="title-selection-orange">Medtrace Display</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group text-center">
                            <div class="title-selection-orange">{{displayText}}</div>
                        </div>
                    </div>
                    <div class="row" *ngIf='this.type == "display_monitor_traffic_clinic"'>
                        <div class="col form-group text-center">
                            <div class="title-selection-orange">{{monitorText}}</div>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col form-group text-center" *ngIf='this.type == "display_monitor_traffic_clinic"'>
                            <div class="title-selection-orange">Information</div>
                            <div class="row">
                                <div class="col">
                                    <label>Zone</label>
                                    <input type="text" class="form-control" placeholder="" formControlName="Zone">
                                </div>
                                <div class="col">
                                    <button class="btn btn-primary btn-narrow" type="button" (click)="update()">Update</button>
                                </div>
                            </div>
                        </div>
                        <div class="col form-group text-center">
                            <div class="title-selection-orange">Filters</div>
                            <div class="form-group form-display row ml-4 mr-n4">
                                <div class="col form-group">
                                    <input class="form-check-input" type="checkbox" name="check0" id="check0" formControlName="Active" (change)="checkboxChange()" >
                                    <label class="form-check-label" for="check0">Active</label>
                                </div>
                                <div class="col form-group">
                                    <input class="form-check-input" type="checkbox" name="check2" id="check2" formControlName="Expired" (change)="checkboxChange()">
                                    <label class="form-check-label" for="check2">Expired</label>
                                </div>
                                <div class="col form-group">
                                    <input class="form-check-input" type="checkbox" name="check4" id="check4" formControlName="Demo" (change)="checkboxChange()">
                                    <label class="form-check-label" for="check4">Demo</label>
                                </div>
                                <div class="col form-group">
                                    <input class="form-check-input" type="checkbox" name="check5" id="check5" formControlName="Repair" (change)="checkboxChange()">
                                    <label class="form-check-label" for="check5">Repair</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <table datatable [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover" id="medtrace-table">
                    <thead>
                        <tr>
                            <th *ngFor="let hd of tableHeaders">
                              {{hd}}
                            </th>
                          </tr>
                    </thead>
                </table>
            </div>
        </div>

    </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';

import { AuthService } from "../../providers/auth.service";
import { PhysicianService } from "../../providers/physician.service";

import { formatLocaleDateSingle, formatNotesMultiple  } from "../../utils/format";
@Component({
  selector: 'app-modal-create-fax',
  templateUrl: './modal-create-fax.component.html',
  styleUrls: ['./modal-create-fax.component.scss']
})
export class ModalCreateFaxComponent implements OnInit {
  @Input() public holter;
  @Input() public doctor;
  public formGroup: FormGroup;
  public invalidReferring: boolean = false;
  public createFax: boolean = false;
  public faxWithTracings: boolean = false;
  public createFaxNotes: string = '';
  public faxNumber: string = '';

  public faxNumberAuto : any[] = [];
  public faxNumberPre : any[] = [];
  public clinicFaxNumbers : any[] = [];
  public faxNumberString: string = '';
  public textAdditionalFax: string = '';
  public faxNumberSame: boolean = true;
  public patientInfo: string = '';
  public createFaxString: string = 'Create Fax';

  constructor(
    public activeModal: NgbActiveModal,
    private physicianService: PhysicianService,
    private authService: AuthService,
  ) {
    this.formGroup = new FormGroup({
      ReferringDoctor: new FormControl("", [ ]),
      Fax: new FormControl("", [ ]),
      FaxNotesReq: new FormControl("", [ ]),
      FaxNotesData: new FormControl("", [ ]),
      FaxNotesPacemaker: new FormControl("", [ ]),
      FaxNotes: new FormControl("", [ ]),
      FaxLog: new FormControl("", [ ]),
      CreateFax: new FormControl("", [ ]),
      FaxNumbers: new FormControl("", [ ]),
      FaxWithTracings: new FormControl("", [ ]),
    });
  }

  ngOnInit(): void {
    this.populateModal(this.holter);
    this.getFaxes();
    this.patientInfo = this.holter.patient_last_name + ", " + this.holter.patient_first_name + " - " + this.holter.patient_health_card;
  }

  private populateModal(holter) {
    this.formGroup.reset();
    this.faxNumber = holter.clinic_fax || holter.referring_doctor_fax || this.doctor.fax_number;
    this.faxWithTracings = holter.flag_fax_with_tracing;
    this.formGroup.patchValue({
      ReferringDoctor: holter.referring_doctor_name,
      FaxNotesReq: holter.flag_no_req,
      FaxNotesData: holter.flag_no_data,
      FaxNotesPacemaker: holter.flag_pacemaker_inquiry,
      FaxLog: formatNotesMultiple([holter.fax_notes, holter.requisition_fax_notes]),
    });
  }

  public passBack() {
    // need to verify the referring doctor if faxing
    const doctorName = this.formGroup.get("ReferringDoctor")?.value?.name || this.formGroup.get("ReferringDoctor")?.value;
    this.physicianService.verify(doctorName)
    .then(verified => {
      this.invalidReferring = !verified;
      if (!verified && this.createFax) {
        return;
      }
      this.activeModal.close({
        referring_doctor: this.formGroup.get("ReferringDoctor")?.value ? this.formGroup.get("ReferringDoctor")?.value?.name || this.formGroup.get("ReferringDoctor")?.value : null,
        fax: this.formGroup.get("Fax")?.value,
        faxNumbers: this.faxNumberAuto.filter(item => this.clinicFaxNumbers.indexOf(item) < 0),
        faxNotesReq: this.formGroup.get("FaxNotesReq")?.value,
        faxNotesData: this.formGroup.get("FaxNotesData")?.value,
        faxNotesPacemaker: this.formGroup.get("FaxNotesPacemaker")?.value,
        faxNotes: this.formGroup.get("FaxNotes")?.value ? this.formGroup.get("FaxNotes")?.value : null,
        createFax: this.createFax,
        faxWithTracings: this.faxWithTracings,
      });
    });
  }

  searchDoctor = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => (!this.authService.isCardioStudyCompanyClass() || term.length < 2) ? Promise.resolve([])
        : this.physicianService.searchDoctor(term, true, false))
    )

  searchDoctorFormatter = (result: any): string => {
    return [
      result.last_name,
      result.first_initials,
      result.clinic ? result.clinic.city : null,
      result.clinic ? result.clinic.fax : null,
      result.billing_code,
    ].filter(x => !!x).join(" | ")
  };

  searchDoctorInputFormatter = (result: any): string => {
    if (!result.name) {
      return result;
    }

    this.formGroup.patchValue({
      ReferringDoctor: result.name,
      Fax: result.clinic?.fax,
    });
    this.invalidReferring = false;
    this.doctor = result;
    return result.name;
  };

  searchFaxDoctorInputFormatter = (result: any): string => {
    if (result.clinic?.fax){
      let fax = result.clinic.fax + " - " + result.name;
      return fax;
    }
  };

  // possibly taking lots of resources?
  typeOf(value) {
    if(typeof value === "object"){
      if (value.clinic?.fax) {
        return false;
      }
      else {
        return true;
      }
    }
  }

  public addFaxNumber() {
    let fax_num = this.formGroup.get("FaxNumbers")?.value;
    if (typeof fax_num === "object") {
      let fax = fax_num.clinic.fax + " - " + fax_num.name;
      if (this.faxNumberAuto.indexOf(fax) == -1){
        this.faxNumberAuto.push(fax);
      }
    }
    else {
      if (this.faxNumberAuto.indexOf(fax_num) == -1 && this.faxNumber != fax_num){
        this.faxNumberAuto.push(fax_num);
      }
    }
    this.formGroup.patchValue({
      FaxNumbers: ""
    });
    this.compareFaxNumbers();
  }

  public removeFaxNumber() {
    const index: number = this.faxNumberAuto.indexOf(this.faxNumberString);
    if (index !== -1) {
      this.faxNumberAuto.splice(index, 1);
    }
    this.faxNumberString = '';
    this.compareFaxNumbers();
  }

  public selectedFaxNumber(item){
    if (this.clinicFaxNumbers?.indexOf(item) > -1){
      return
    }
    else {
      this.faxNumberString = item;
    }
  }

  public getFaxes(){
    if (this.doctor){
      if (this.doctor.clinic_assignment_additional_fax_numbers){
        this.clinicFaxNumbers = JSON.parse(this.doctor.clinic_assignment_additional_fax_numbers);
      }
      else {
        this.clinicFaxNumbers = [];
      }
    }

    if (this.holter.fax_numbers){
      this.faxNumberAuto = JSON.parse(this.holter.fax_numbers);
      this.faxNumberPre = JSON.parse(this.holter.fax_numbers);
    }
    this.faxNumberAuto = [...this.faxNumberAuto, ...this.clinicFaxNumbers];
    this.faxNumberAuto.sort((a, b) => a.localeCompare(b));
    this.faxNumberPre = [...this.faxNumberPre, ...this.faxNumberAuto];
  }

  public compareFaxNumbers(){
    let faxNumber1 = this.faxNumberAuto;
    let faxNumber2 = this.faxNumberPre;
    this.faxNumberSame = (faxNumber1.length == faxNumber2.length) && faxNumber1.every(function(element, index) {
      return element === faxNumber2[index];
    });
  }

  public changeCreateFaxString(){
    if (this.createFax) {
      this.createFaxString = "Request hook up clinic for:"
    }
    else {
      this.createFaxString = "Create Fax"
    }
  }

}
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import * as download from "downloadjs";

import { Holter } from "../../models/holter.model";
import { FeatureToggleService } from "../../providers/feature-toggle.service";

@Component({
  selector: 'app-home',
  templateUrl: './holter-detail.component.html',
  styleUrls: ['./holter-detail.component.scss']
})
export class HolterDetailComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  public ngOnInit() {}

  public holterId(): number {
    const tokens = this.router.url.split("/");
    return parseInt(tokens[tokens.length - 1], 10);
  }

  public navigateTo(url: string): void {
    this.router.navigateByUrl(url);
  }

  public urlContains(str: string): boolean {
    return this.router.url.indexOf(str) > -1;
  }

}

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { DataTableDirective } from 'angular-datatables';

import { Patient } from "../../models/patient.model";
import { PatientService } from "../../providers/patient.service";
import { DataTablesResponse } from "../../models/database-response.model";

@Component({
  selector: 'app-home',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss']
})
export class PatientComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;

  public dtOptions: any = {};

  private patients: Patient[] = [];
  public patient: Patient | null = new Patient();

  public ngOnInit() {}

  constructor(
    private patientService: PatientService,
  ) {

    // roll callback
    const rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
      $("td", row).unbind("click");
      $("td", row).bind("click", () => this.rowClickHandler(data));
      return row;
    };

    // datatable options
    this.dtOptions = {
        autoWidth: false,
        responsive: true,
        lengthChange: false,
        select: true,
        pageLength: 25,
        dom: "Blfrtip",
        buttons: [],
        rowCallback: rowCallback,
        serverSide: true,
        processing: true,
        ajax: this.ajaxCall(),
        columns: [
          { data: "patient:updated_at" },
          { data: "patient:last_name" },
          { data: "patient:first_name" },
          { data: "patient:health_card" },
          { data: "patient:health_card_code" },
          { data: "patient:birthdate" },
          { data: "patient:id", visible: false },
        ]
    };
  }

  private ajaxCall(): any {
    const that: PatientComponent = this;

    return (dataTablesParams: any, callback: any): void => {
      that.patientService.ajaxCall(dataTablesParams)
        .then((response: any) => {

          that.selectFirstRow();

          // callback to the datatable to rerender with the updated information
          const result: DataTablesResponse = response.Payload.data.result;
          callback({
            recordsTotal: result.recordsTotal,
            recordsFiltered: result.recordsFiltered,
            data: result.data,
          });

        })
        .catch((error: any) => {
          console.log("Error datatable handler for patient: " + error.message);
        });
    }
  }

  public rowClickHandler(info: any, force: boolean = false) {
    this.patientService.getPatient(info["patient:id"])
      .then((found: Patient | null) => {
        if (!found) {
          return;
        } else if (this.patient && found.id === this.patient.id && force === false) {
          this.patient = new Patient();
        } else {
          this.patient = found;
        }
      });
  }

  public ngAfterViewInit(): void {
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      $("#search-bar").on("keyup change", function () {
        if (dtInstance.search() !== (<HTMLInputElement>this)["value"]) {
          dtInstance.search((<HTMLInputElement>this)["value"]).draw();
        }
      });
    });
    $("#search-bar").focus();
  }

  private selectFirstRow(): void {
    this.datatableElement?.dtInstance.then((dtInstance: any) => {
      if (dtInstance.rows().count() > 0) {
        dtInstance.row(0).select();
        this.rowClickHandler(dtInstance.row(0).data(), true);
      }
    });
  }

}
export class Employee {
  public id: number | null = null;
  public firstname: string | null = null;
  public lastname: string | null = null;
  public email: string | null = null;
  public password: string | null = null;
  public job: string | null = null;
  public city: string | null = null;
  public role_id: number | null = null;
  public role_name: string | null = null;
  public roles: any = {};
  public role_permission: any | null = null;
  public status: string | null = null;
  public owner_id: number | null = null;
  public owner_name: string | null = null;
  public owner_domain: string | null = null;
  public owner_address: string | null = null;
  public owner_telephone: string | null = null;
  public owner_company_class: string | null = null;
  public owner_abpm_price: string | null = null;
  public owner_fax: string | null = null;
}

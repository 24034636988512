<div class="md-width">

  <div class="row sub-header">
    <div class="col-12">
      <div class="text-center">
        <br>
          <ul class="list-inline">
            <li class="list-inline-item table-filter"
                (click)="switchPage(PAGE_SEARCH)"
                [class.table-filter-selected]="page===PAGE_SEARCH">
                <div class="col-xs-3"><i class="fa fa-search"></i></div>
                <div class="col-xs-9 text-center"><p>Search</p></div>
            </li>
            <li class="list-inline-item table-filter"
                (click)="switchPage(PAGE_CREATE)"
                [class.table-filter-selected]="page===PAGE_CREATE || page===PAGE_EDIT">
                <div class="col-xs-3"><i class="fa fa-plus"></i></div>
                <div class="col-xs-9 text-center"><p [innerHTML]="createEditTitle()"></p></div>
            </li>
          </ul>
      </div>
    </div>
  </div>
</div>

<div class="row sub-main">

  <div class="page-title">
    <div class="title_left">
      <h3>Bookings - {{pageName()}}</h3>
    </div>
  </div>
  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="x_content">

      <div class="row" [hidden]="page===PAGE_SEARCH">
        <div class="col-12">

          <br>
          <!-- <h4>Information</h4> -->
          <form class="form-horizontal form-label-left input_mask" [formGroup]="createFormGroup">

            <h4>Visit</h4>
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col form-group required">
                    <label for="inputSuccess2">Tech</label>
                    <input type="text" class="form-control" formControlName="Tech" placeholder="Technician Name" />
                  </div>
                  <div class="col form-group required">
                    <label for="inputSuccess2">Visited On</label>
                    <input type="text" class="form-control" formControlName="VisitedAt" placeholder="YYYY/MM/DD" dateInput>
                  </div>
                  <div class="col form-group required">
                    <label for="inputSuccess2">Hookup On</label>
                    <input type="text" class="form-control" formControlName="HookupAt" placeholder="YYYY/MM/DD" dateInput>
                  </div>
                  <!-- <div class="col form-group required">
                    <label for="inputSuccess2">Scanned On</label>
                    <input type="text" class="form-control" formControlName="ScannedOn" placeholder="YYYY/MM/DD" dateInput>
                  </div> -->
                </div>
                <div class="row">
                  <div class="col form-group">
                    <label for="inputSuccess2">Comments</label>
                    <input type="text" class="form-control" formControlName="Comments">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="row">
                  <div class="col form-group">
                    <label for="inputSuccess2">Period (Days)</label>
                    <input type="text" class="form-control" formControlName="Duration" numberInput>
                  </div>
                  <div class="col form-group">
                    <label for="inputSuccess2">Delivery</label>
                    <input type="text" class="form-control" formControlName="Delivery">
                  </div>
                  <div class="col form-group required">
                    <label for="inputSuccess2">Billing Status</label>
                    <select class="form-control" formControlName="BillStatus" data-live-search="true">
                      <option>Not Billed</option>
                      <option>Billed</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 form-group">
                    <label for="inputSuccess2">Device Id</label>
                    <input type="text" class="form-control" formControlName="MonitorSerialNumber" placeholder="Attach device for number">
                  </div>
                </div>
              </div>
            </div>
            <br>

            <h4>Patient</h4>
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col form-group required">
                    <label for="inputSuccess2">First Name</label>
                    <input id="typeahead-patient" type="text" class="form-control" formControlName="PatientFirstName" placeholder="First Name" />
                    <!-- <input id="typeahead-patient" type="text" class="form-control" formControlName="Patient" [ngbTypeahead]="searchPatient" placeholder="Type health card or name to search" /> -->
                  </div>
                  <div class="col form-group required">
                    <label for="inputSuccess2">Last Name</label>
                    <input id="typeahead-patient" type="text" class="form-control" formControlName="PatientLastName" placeholder="Last Name" upperInput />
                  </div>
                </div>
                <div class="row">
                  <div class="col form-group required">
                    <label for="inputSuccess2">Health Card</label>
                    <input type="text" class="form-control" formControlName="PatientHealthCard" placeholder="1234-123-123" healthCardInput>
                  </div>
                  <div class="col form-group">
                    <label for="inputSuccess2">Health Code</label>
                    <input type="text" class="form-control" formControlName="PatientHealthCardCode" placeholder="HC" healthCardCodeInput>
                  </div>
                  <div class="col form-group required">
                    <label for="inputSuccess2">Birthdate</label>
                    <input type="text" class="form-control" formControlName="PatientBirthdate" placeholder="YYYY/MM/DD" dateInput>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="row">
                  <div class="col form-group required">
                    <label for="inputSuccess2">Gender</label>
                    <select class="form-control" formControlName="PatientGender" data-live-search="true">
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </select>
                  </div>
                  <div class="col form-group required">
                    <label for="inputSuccess2" (click)="toggleHeightUnit()" class="toggle-unit no-select">Height ({{heightUnit}})</label>
                    <input type="text" class="form-control" formControlName="PatientHeight" placeholder="0" numberInput>
                  </div>
                  <div class="col form-group required">
                    <label for="inputSuccess2" (click)="toggleWeightUnit()" class="toggle-unit no-select">Weight ({{weightUnit}})</label>
                    <input type="text" class="form-control" formControlName="PatientWeight" placeholder="0" numberInput>
                  </div>
                </div>
              </div>
            </div>
            <br>

            <h4>Clinic</h4>
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col form-group required">
                    <label for="inputSuccess2">Name</label>
                    <input id="typeahead-clinic" type="text" class="form-control" formControlName="ClinicName" placeholder="Clinic Name" />
                    <!-- <input id="typeahead-clinic" type="text" class="form-control" formControlName="Clinic" [ngbTypeahead]="searchClinic" placeholder="Type name to search" /> -->
                  </div>
                  <div class="col form-group">
                    <label for="inputSuccess2">Street</label>
                    <input id="typeahead-clinic" type="text" class="form-control" formControlName="ClinicStreet" placeholder="Clinic Street" />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="row">
                  <div class="col form-group">
                    <label for="inputSuccess2">City</label>
                    <input type="text" class="form-control" formControlName="ClinicCity" placeholder="Clinic City">
                  </div>
                  <div class="col form-group">
                    <label for="inputSuccess2">Province</label>
                    <select class="form-control" formControlName="ClinicProvince" data-live-search="true">
                      <option>ON</option>
                      <option>QC</option>
                      <option>NS</option>
                      <option>NB</option>
                      <option>MB</option>
                      <option>BC</option>
                      <option>PE</option>
                      <option>SK</option>
                      <option>AB</option>
                      <option>NL</option>
                      <option>NT</option>
                      <option>YT</option>
                      <option>NU</option>
                    </select>
                  </div>
                  <div class="col-6 form-group">
                    <label for="inputSuccess2">Postal Code</label>
                    <input type="text" class="form-control" formControlName="ClinicPostalCode" placeholder="A1A 1A1" upperInput>
                  </div>
                </div>
              </div>
            </div>
            <br>

            <h4>Referring Doctor</h4>
            <div class="row">
              <div class="col-8">
                <div class="row">
                  <div class="col-4 form-group required">
                    <label for="inputSuccess2">First Initials</label>
                    <input id="typeahead-referring-doctor" type="text" class="form-control" formControlName="ReferringDoctorFirstInitials" placeholder="First Initials" />
                    <!-- <input id="typeahead-referring-doctor" type="text" class="form-control" formControlName="ReferringDoctor" [ngbTypeahead]="searchReferringDoctor" placeholder="Type last name to search" /> -->
                  </div>
                  <div class="col form-group required">
                    <label for="inputSuccess2">Last Name</label>
                    <input id="typeahead-referring-doctor" type="text" class="form-control" formControlName="ReferringDoctorLastName" placeholder="Last Name" />
                  </div>
                </div>
              </div>
            </div>
            <br>

          </form>
        </div>
      </div>

      <div class="row" [hidden]="page===PAGE_CREATE || page===PAGE_EDIT">
        <div class="col-12">
          <div class="offset-3 col-6">
            <input type="text" class="form-control search-bar" placeholder="Start typing to search" id="search-bar">
          </div>
          <!-- <h4>Details</h4> -->
          <form class="form-horizontal form-label-left input_mask">

            <div class="col-md-6 col-sm-6 col-xs-12">
              <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                <label for="inputSuccess2">Last Name</label>
                <input type="text" class="form-control" [(ngModel)]="visit.patient_last_name" name="last-name" disabled>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                <label for="inputSuccess2">First Name</label>
                <input type="text" class="form-control" [(ngModel)]="visit.patient_first_name" name="first-name" disabled>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                <label for="inputSuccess2">Clinic Name</label>
                <input type="text" class="form-control" [(ngModel)]="visit.clinic_name" name="clinic-name" disabled>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                <label for="inputSuccess2">Referring Doctor</label>
                <input type="text" class="form-control" [(ngModel)]="visit.referring_doctor_name" name="referring-doctor" disabled>
              </div>
              <!-- <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                <label for="inputSuccess2">Reading Doctor</label>
                <input type="text" class="form-control" [(ngModel)]="visit.reading_doctor_name" name="reading-doctor" disabled>
              </div> -->
              <div class="col-md-12 col-sm-12 col-xs-12 form-group">
                <label for="inputSuccess2">Comments</label>
                <input type="text" class="form-control" [(ngModel)]="visit.comments" name="comments" disabled>
              </div>
            </div>

            <div class="col-md-6 col-sm-6 col-xs-12 form-group">
              <div class="col-md-12 col-sm-12 col-xs-12 form-group">
                <label for="inputSuccess2">Tech</label>
                <input type="text" class="form-control" [(ngModel)]="visit.tech" name="tech" disabled>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                <label for="inputSuccess2">Visited On</label>
                <input type="text" class="form-control" [(ngModel)]="visit.visited_at" name="visited-at" disabled>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                <label for="inputSuccess2">Hookup On</label>
                <input type="text" class="form-control" [(ngModel)]="visit.hookup_at" name="hookup-at" disabled>
              </div>
              <!-- <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                <label for="inputSuccess2">Scanned On</label>
                <input type="text" class="form-control" [(ngModel)]="visit.scanned_at" name="scanned-on" disabled>
              </div> -->
              <div class="col-md-4 col-sm-4 col-xs-12 form-group">
                <label for="inputSuccess2">Duration (Days)</label>
                <input type="text" class="form-control" [(ngModel)]="visit.hookup_duration" name="duration" disabled>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 form-group">
                <label for="inputSuccess2">Delivery</label>
                <input type="text" class="form-control" [(ngModel)]="visit.delivery_id_type" name="delivery" disabled>
              </div>
            </div>
          </form>
          <br>

          <!-- <h4>Browse</h4> -->
          <div class="col-12">
            <table datatable [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Health Card</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Tech</th>
                  <th>Referring Doctor</th>
                  <th>Owner</th>
                  <th>Id</th>
                </tr>
              </thead>
            </table>
          </div>

          <br>

        </div>
      </div>

    </div>
  </div>
</div>

<div>
  <div class="row sub-footer">
    <div class="col-12" [hidden]="page===PAGE_CREATE || page===PAGE_EDIT">
      <button class="btn btn-primary btn-review" [disabled]="!visit.id" (click)="switchPage(PAGE_EDIT)"><i class="fa fa-pencil"></i> &nbsp; Edit</button>
    </div>
    <div class="col-12" [hidden]="page===PAGE_SEARCH">
      <button class="btn btn-primary btn-review" (click)="onSubmit()" [disabled]="!createFormGroup.valid"><i class="fa fa-check"></i> &nbsp; Submit</button>
      <button class="btn btn-primary btn-review" (click)="switchPage(PAGE_SEARCH)"><i class="fa fa-times"></i> &nbsp; Cancel</button>
    </div>
  </div>
</div>

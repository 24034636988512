import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';

import { Role } from "../../models/role.model";
import { Owner } from "../../models/owner.model";
import { Employee } from "../../models/employee.model";
import { Physician } from "../../models/physician.model";
import { EmployeeService } from "../../providers/employee.service";
import { AuthService } from "../../providers/auth.service";
import { PhysicianService } from "../../providers/physician.service";

import { DataTablesResponse } from "../../models/database-response.model";

import { formatLocaleTime } from "../../utils/format";

@Component({
  selector: 'app-home',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;

  public dtOptions: any = {};
  public currentDoctor: Physician;

  private employees: Employee[] = [];
  public employee: Employee | null = new Employee();

  public readonly PAGE_SEARCH: string = "search";
  public readonly PAGE_CREATE: string = "create";
  public readonly PAGE_EDIT: string = "edit";
  public page: string = this.PAGE_SEARCH;

  public error: string = "";

  public roles: Role[] = [];
  public owners: Owner[] = [];
  public createFormGroup: FormGroup;

  public readonly NONE_OPTION: string = "None of the above";
  public prevOwner: string = "";

  public ngOnInit() {}

  constructor(
    private employeeService: EmployeeService,
    private authService: AuthService,
    private physicianService: PhysicianService,
  ) {

    // roll callback
    const rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
      $("td", row).unbind("click");
      $("td", row).bind("click", () => this.rowClickHandler(data));
      return row;
    };

    // datatable options
    this.dtOptions = {
        autoWidth: false,
        responsive: true,
        lengthChange: false,
        select: true,
        pageLength: 25,
        dom: "Blfrtip",
        buttons: [],
        rowCallback: rowCallback,
        serverSide: true,
        processing: true,
        ajax: this.ajaxCall(),
        order: [[ 0, "desc" ]],
        columns: [
          { data: "employee:updated_at" },
          { data: "owner:company_class" },
          { data: "owner:name" },
          {
            data: "employee:roles",
            orderable: false,
          },
          { data: "employee:email" },
          { data: "employee:lastname" },
          { data: "employee:firstname" },
          { data: "employee:city" },
          { data: "employee:id", visible: false },
        ],
        language: {
          infoFiltered: ""
        }
    };

    this.createFormGroup = new FormGroup({
      LastName: new FormControl("", [ Validators.required, ]),
      FirstName: new FormControl("", [ Validators.required, ]),
      JobTitle: new FormControl("", [ Validators.required, ]),
      Email: new FormControl("", [ Validators.required, Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/), ]),
      Status: new FormControl("", [ Validators.required, ]),
      RoleId: new FormControl("", [ Validators.required, ]),
      City: new FormControl("", [ Validators.required, ]),
      OwnerId: new FormControl("", [ Validators.required, ]),
      OwnerName: new FormControl("", [ ]),
      OwnerDomain: new FormControl("", [ Validators.required, Validators.pattern(/^(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/), ]),
      OwnerAddress: new FormControl("", [ Validators.required, ]),
      OwnerTelephone: new FormControl("", [ Validators.required, Validators.pattern(/^[1]?(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]\d{4}$/), ]),
      OwnerAbpmPrice: new FormControl("", [ Validators.required, Validators.pattern(/^\d+[.]?\d{0,2}$/), ]),
      OwnerFax: new FormControl("", [ Validators.required, Validators.pattern(/^[1]?(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]\d{4}$/), ]),
      Password: new FormControl("", [ ]),
      ReferringDoctor: new FormControl("", [ ]),
    });

    this.createFormGroup.valueChanges.subscribe((val: any) => {

      const selectedNewCompany: boolean = val.OwnerId === this.NONE_OPTION && val.OwnerId !== this.prevOwner;
      if (selectedNewCompany) {
        // this needs to be set to prevent a inifinite loop
        this.prevOwner = val.OwnerId;

        this.createFormGroup.get("OwnerDomain")?.setValue("");
        this.createFormGroup.get("OwnerAddress")?.setValue("");
        this.createFormGroup.get("OwnerTelephone")?.setValue("");
        this.createFormGroup.get("OwnerAbpmPrice")?.setValue("");
        this.createFormGroup.get("OwnerFax")?.setValue("");

        this.createFormGroup.controls["OwnerId"].clearValidators();
        this.createFormGroup.controls["OwnerId"].updateValueAndValidity();
        this.createFormGroup.controls["OwnerName"].setValidators([Validators.required]);
        this.createFormGroup.controls["OwnerName"].updateValueAndValidity();
      }

      const selectedDifferentCompany: boolean = val.OwnerId !== this.prevOwner;
      if (selectedDifferentCompany) {
        // this needs to be set to prevent a inifinite loop
        this.prevOwner = val.OwnerId;

        for (const owner of this.owners) {
          if (owner.id == val.OwnerId) {
            this.createFormGroup.get("OwnerName")?.setValue(owner.name);
            this.createFormGroup.get("OwnerDomain")?.setValue(owner.domain);
            this.createFormGroup.get("OwnerAddress")?.setValue(owner.address);
            this.createFormGroup.get("OwnerTelephone")?.setValue(owner.telephone);
            this.createFormGroup.get("OwnerAbpmPrice")?.setValue(owner.abpm_price);
            this.createFormGroup.get("OwnerFax")?.setValue(owner.fax);
          }
        }
      }

    });

    this.refreshLookupTables();
  }

  private refreshLookupTables() {
    this.employeeService.getRoles().then((roles: any[]) => {
      this.roles = roles;
    });
    this.employeeService.getCompanies().then((companies: any[]) => {
      this.owners = companies;
    });
  }

  private ajaxCall(): any {
    const that: StaffComponent = this;

    return (dataTablesParams: any, callback: any): void => {
      dataTablesParams.columns.push({
        data: 'employee:status',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: true,
          method: "neq",
          value: "Disabled",
        },
      });
      that.employeeService.ajaxCall(dataTablesParams)
        .then((response: any) => {

          that.selectFirstRow();

          // callback to the datatable to rerender with the updated information
          const result: DataTablesResponse = response.Payload.data.result;
          callback({
            recordsTotal: result.recordsTotal,
            recordsFiltered: result.recordsFiltered,
            data: result.data.map(formatLocaleTime),
          });

        })
        .catch((error: any) => {
          console.log("Error datatable handler for staff: " + error.message);
        });
    }
  }

  public rowClickHandler(info: any) {
    this.employeeService.getEmployeeById(info["employee:id"])
      .then((data) => {
        this.employee = new Employee();
        this.employee.id = data.id;
        this.employee.lastname = data.lastname;
        this.employee.firstname = data.firstname;
        this.employee.job = data.job;
        this.employee.email = data.email;
        this.employee.owner_id = data.owner.id;
        this.employee.owner_name = data.owner.name;
        this.employee.owner_domain = data.owner.domain;
        this.employee.owner_address = data.owner.address;
        this.employee.owner_telephone = data.owner.telephone;
        this.employee.owner_abpm_price = data.owner.abpm_price;
        this.employee.owner_fax = data.owner.fax;
        this.employee.city = data.city;
        this.employee.status = data.status;
        this.employee.role_name = data.roles.name.join(", ");
        this.employee.role_id = data.roles.id;
      });
  }

  public ngAfterViewInit(): void {
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      $("#search-bar").on("keyup change", function () {
        if (dtInstance.search() !== (<HTMLInputElement>this)["value"]) {
          dtInstance.search((<HTMLInputElement>this)["value"]).draw();
        }
      });
    });
    $("#search-bar").focus();
  }

  private selectFirstRow(): void {
    this.datatableElement?.dtInstance.then((dtInstance: any) => {
      dtInstance.rows().deselect();
      if (dtInstance.rows().count() > 0) {
        dtInstance.row(0).select();
        this.rowClickHandler(dtInstance.row(0).data());
      }
    });
  }

  private reloadDatatable(): void {
    this.datatableElement?.dtInstance.then(dtInstance => dtInstance.ajax.reload());
  }

  public switchPage(page: string) {
    this.page = page;
    this.error = "";
    this.createFormGroup.reset();

    if (this.page === this.PAGE_EDIT) {
      this.createFormGroup.patchValue({
        LastName: this.employee.lastname,
        FirstName: this.employee.firstname,
        JobTitle: this.employee.job,
        Email: this.employee.email,
        Status: this.employee.status,
        RoleId: this.employee.role_id,
        City: this.employee.city,
        OwnerId: this.employee.owner_id,
        OwnerDomain: this.employee.owner_domain,
        OwnerAddress: this.employee.owner_address,
        OwnerTelephone: this.employee.owner_telephone,
        OwnerAbpmPrice: parseInt(this.employee.owner_abpm_price),
        OwnerFax: this.employee.owner_fax,
        Password: "",
      });
      this.createFormGroup.controls["Password"].clearValidators();
      this.createFormGroup.controls["Password"].updateValueAndValidity();

    } else if (this.page === this.PAGE_CREATE) {
      this.employee = new Employee();
      this.createFormGroup.controls["Password"].setValidators([Validators.required, Validators.minLength(4), Validators.maxLength(20)]);
      this.createFormGroup.controls["Password"].updateValueAndValidity();
      this.createFormGroup.patchValue({ Password: "" });

    } else if (this.page === this.PAGE_SEARCH) {
      this.selectFirstRow();
    }
  }

  public pageName(): string {
    if (this.page === this.PAGE_EDIT && this.employee.firstname.length + this.employee.lastname.length < 25) {
      return this.employee.firstname + " " + this.employee.lastname;
    }
    return this.page.charAt(0).toUpperCase() + this.page.substr(1).toLowerCase();
  }

  public createEditTitle(): string {
    return (this.page === this.PAGE_EDIT) ? "&nbsp&nbspEdit" : "Create";
  }

  public resetPassword(employee: Employee): Promise<any> {
    const newPassword = this.createFormGroup.get("Password")?.value;
    return this.employeeService.setPassword(employee, newPassword).then(() => {
      this.switchPage(this.PAGE_SEARCH);
    });
  }

  public onSubmit(): Promise<any> {

    let data = {
      firstname: this.createFormGroup.get("FirstName")?.value,
      lastname: this.createFormGroup.get("LastName")?.value,
      email: this.createFormGroup.get("Email")?.value,
      job: this.createFormGroup.get("JobTitle")?.value,
      city: this.createFormGroup.get("City")?.value,
      status: this.createFormGroup.get("Status")?.value,
      roles: this.createFormGroup.get("RoleId")?.value,
      password: this.createFormGroup.get("Password")?.value,
      owner: {
        name: this.createFormGroup.get("OwnerName")?.value,
        domain: this.createFormGroup.get("OwnerDomain")?.value,
        address: this.createFormGroup.get("OwnerAddress")?.value,
        telephone: this.createFormGroup.get("OwnerTelephone")?.value,
        fax: this.createFormGroup.get("OwnerFax")?.value,
        abpm_price: this.createFormGroup.get("OwnerAbpmPrice")?.value,
      },
    };

    if (this.currentDoctor){
      data['doctor'] = {
        id: this.currentDoctor?.id,
        last_name: this.currentDoctor?.last_name,
        first_initials: this.currentDoctor?.first_initials,
        name: this.currentDoctor?.name,
        roles: this.createFormGroup.get("RoleId")?.value.indexOf('7') > -1 ? "Reading" : null,
      }
    }

    return this.employeeService.upsertEmployee(data).then((response) => {
        if (response.Status === "Failed") {
          this.error = "Unable to modify employee";
          return;
        }
        this.refreshLookupTables();
        this.reloadDatatable();
        this.switchPage(this.PAGE_SEARCH);
        this.error = "";
      })
      .catch((error) => {
        this.error = error.error.message;
      });
  }

  public deleteEmployee(employee: Employee): Promise<any> {
    return this.employeeService.deleteEmployee(employee.id)
      .then(() => this.reloadDatatable());
  }

  public creatingCompany(): boolean {
    return this.createFormGroup.get("OwnerId")?.value === this.NONE_OPTION;
  }

  searchReferringDoctor = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(1000),
    distinctUntilChanged(),
    switchMap(term => (!this.authService.isCardioStudyCompanyClass() || term.length < 2) ? Promise.resolve([])
      : this.physicianService.searchDoctor(term, true, false))
  )

  searchReferringDoctorFormatter = (result: any): string => {
    return [
      result.last_name,
      result.first_initials,
      result.clinic ? result.clinic.city : null,
      result.billing_code,
    ].filter(x => !!x).join(" | ")
  };

  searchReferringDoctorInputFormatter = (result: any): string => {
    if (!result.name) {
      return result;
    }

    this.createFormGroup.patchValue({
      ReferringDoctor: result.name,
      LastName: result.last_name,
      FirstName: result.first_name,
      City: result.clinic ? result.clinic.city : null,
      JobTitle: "Doctor",
      Status: "Active",
    });
    // this.errorStrings.city = result.clinic ? null : "None found";
    this.currentDoctor = result;
    console.log(result)
    return result.name;
  };

}

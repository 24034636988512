import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { ContextMenuComponent } from "ngx-contextmenu";

import { Holter } from "../../models/holter.model";

import { HolterService } from "../../providers/holter.service";
import { AuthService } from "../../providers/auth.service";
import { PhysicianService } from "../../providers/physician.service";

import { PAT_OPTIONS } from "../../config/constants";
import { PDF_OPTIONS } from "../../config/constants";

@Component({
  selector: 'app-research-search-documents',
  templateUrl: './research-search-documents.component.html',
  styleUrls: ['./research-search-documents.component.scss']
})
export class ResearchSearchDocumentsComponent implements OnInit, AfterViewInit {

  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;
  public dtOptions: any = {};

  @ViewChild(ContextMenuComponent)
  public suggestedMenu: ContextMenuComponent;

  public pdfSrc: any = null;
  public formGroup: FormGroup;

  public multiSelected: boolean = false;
  public currentHolter: Holter;
  public sourceFilter: string = "pat";
  public anchorFilter: string | null = "";
  public anchorSelectFilter: string | null = "";
  public searchFilter: string | null = "";
  public searchKeyFilter: string | null = "";
  public searchValueFilter: string | null = "";

  public loadingPdf: boolean = false;
  public pdfSizeLeft: any = 50;
  public pdfSizeRight: any = 50;
  public showPdfPreview: string = "pat";
  public patDataJson: any = null;

  public anchorList: string[] = PAT_OPTIONS;

  public ngAfterViewInit(): void {

    const that = this;
    document.addEventListener("click",  function(e){
      if (!e.target) {
        return;
      }
      const className = (e.target as Element).className;
      if(className == "as-split-gutter-icon"){
        window.dispatchEvent(new Event('resize'));
        that.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.draw();
        });
      }
    });

    // set up the callbacks
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {

      // on search term callback
      $("#search-bar").on("keyup change", function () {
        if (dtInstance.search() !== this["value"]) {
          dtInstance.search(this["value"]).draw();
        }
      });
    });

    // focus on the search bar right away after load
    $("#search-bar").focus();
  }

  public ngOnInit() {}

  constructor(
    private physicianService: PhysicianService,
    private holterService: HolterService,
    private authService: AuthService,
  ) {

    // roll callback
    const rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
      $("td", row).bind("click", () => this.rowClickHandler(data));
      return row;
    };

    const drawCallback: any = (settings) => {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
          const row = dtInstance.row(rowIdx);

        });
      });
    };

    this.holterService.clearFilters();
    this.holterService.updateResearchFilter(true);

    // datatable options
    this.dtOptions = {
        autoWidth: false,
        responsive: true,
        lengthChange: false,
        select: true,
        pageLength: 50,
        dom: "Blfrtip",
        buttons: [],
        rowCallback: rowCallback,
        serverSide: true,
        processing: true,
        scrollY: window.innerHeight - 500,
        ajax: this.holterService.getAjaxFuncion(),
        drawCallback: drawCallback,
        order: [[ 2, "desc" ]],
        columnDefs: [{
          targets: 0,
          className: 'expand-row-column'
        },{
          targets: 1,
          className: 'download-file-column'
        }],
        columns: [
          {
            data: "holter:id",
            orderable: false,
            render: function ( data, type, row, meta ) {
              const state = row["holter:state"];
              if (row["holter:closed_by"]) {
                return "SUSPENDED";
              } else {
                return state;
              }
            },
          },
          {
            data: "patient:last_name",
            orderable: true,
            render: function ( data, type, row, meta ) {
              const last = row["patient:last_name"];
              const first = row["patient:first_name"];
              const card = row["patient:health_card"];
              if (!card) {
                return "No Health Card <Br> No Name";
              } else if (!last && !first) {
                return card.substring(0,10) + "<Br>" + "No Name";
              } else if (!last) {
                return card.substring(0,10)  + "<Br>" + first;
              } else if (!first) {
                return card.substring(0,10)  + "<Br>" + last;
              } else {
                return card.substring(0,10)  + "<Br>" + last + ", " + first;
              }
            },
          },
          { data: "holter:scan_date" },
          { data: "holter:signed_off_at" },
          { data: "referring_doctor:name" },
          { data: "holter:id", visible: false },
          { data: "patient:first_name", visible: false },
          { data: "holter:state", visible: false },
          { data: "reading_doctor:name", visible:false },
          { data: "holter:closed_by", visible:false },
          { data: "patient:health_card", visible: false },
          { data: "holter:pat_data", visible: false },
          { data: "holter:pdf_data", visible: false },
        ],
        language: {
          infoFiltered: ""
        }
    };

    this.formGroup = new FormGroup({

    });
  }

  private updateMultiSelected(that: any) {
    that.datatableElement.dtInstance.then((dtInstance: any) => {
      const selectedData = dtInstance.rows(".selected").data();
      that.multiSelected = selectedData.length > 1;
    });
  }

  public rowClickHandler(info: any, force: boolean = false) {
    setTimeout(() => this.updateMultiSelected(this), 200);
    this.loadingPdf = true;
    this.holterService.getHolter(info["holter:id"]).then(holter => {
      this.currentHolter = holter;
      this.datatableElement.dtInstance.then((dtInstance: any) => {
        const selectedData = dtInstance.rows(".selected").data();
        if (selectedData.length == 1) {
          if (this.sourceFilter == "pat"){
            this.showPat();
          }
          if (this.sourceFilter == "pdf"){
            this.showTracings();
          }
          if (this.sourceFilter == "report"){
            this.showReport();
          }
        }
      });
    });
  }

  public showPat() {
    this.showPdfPreview = "pat";
    this.patDataJson = JSON.parse(this.currentHolter.pat_data);
  }

  // use this method if you want to keep the order of the object properties
  public orderByKey(a, b) {
    return a.key;
  }

  public showTracings() {
    return this.holterService.getTracings(this.currentHolter.id, "annotated").then(url => {
      this.pdfSrc = url;
      this.showPdfPreview = "tracings";
      setTimeout(() => {
        this.loadingPdf = false;
      }, 200);
    });
  }

  public showRequisition() {
    this.holterService.getRequisition(this.currentHolter.id).then(url => {
      this.pdfSrc = url;
      this.showPdfPreview = "requisition";
      setTimeout(() => {
        this.loadingPdf = false;
      }, 200);
    });
  }

  public showReport() {
    this.showPdfPreview = "report";
    return this.holterService.getMonitorReport(this.currentHolter.id)
      .then((base64Data: string) => {
        this.pdfSrc = HolterService.base64ToArrayBuffer(base64Data);
        setTimeout(() => {
            this.loadingPdf = false;
        }, 200);
      })
  }

  public sourceSelectionChanged() {
    if (this.sourceFilter == "pat"){
      this.anchorList = PAT_OPTIONS;
    }
    if (this.sourceFilter == "pdf"){
      this.anchorList = PDF_OPTIONS;
    }
    if (this.sourceFilter == "report"){
      this.anchorList = null;
    }
    this.anchorFilter = ""
    this.anchorSelectFilter = ""
  }

  private reloadTable() {
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.page(0).draw(false);
    });
  }

  public search() {
    if (((!this.anchorFilter && !this.anchorSelectFilter) || !this.searchFilter) && this.sourceFilter != 'report' || !this.sourceFilter){
      return
    }
    if (this.sourceFilter == "pdf") {
      this.holterService.clearFilters();
      this.holterService.updateResearchSearchFilter(this.anchorFilter ? this.anchorFilter : this.anchorSelectFilter, this.searchFilter, this.sourceFilter);
      this.holterService.setCustomFilter("research")
    }
    if (this.sourceFilter == "pat") {

      this.holterService.clearFilters();
      this.holterService.updateResearchSearchFilter(this.anchorFilter ? this.anchorFilter : this.anchorSelectFilter, this.searchFilter, this.sourceFilter);
      this.holterService.setCustomFilter("research")
    }
    if (this.sourceFilter == "report") {
      this.holterService.clearFilters();
      this.holterService.updateResearchSearchFilter(this.anchorFilter ? this.anchorFilter : this.anchorSelectFilter, this.searchFilter, this.sourceFilter);
      this.holterService.setCustomFilter("research")
    }
    this.reloadTable();
  }

  // SEARCHING IN WIP
  public searchKey(searchKeyFilter) {
  }

  public searchValue() {
  }

}
import { Injectable } from '@angular/core';

import { Employee } from "../models/employee.model";
import { EmployeeService } from "../providers/employee.service";
import { AuthService } from "../providers/auth.service";

@Injectable()
export class FeatureToggleService {

  constructor(
    private employeeService: EmployeeService,
    private authService: AuthService,
  ) {}

  // deprecated
  public showLdsReport(): Promise<boolean> {
    return this.authService.getCurrentEmployee().then(employee => {
      if (!employee) {
        return false;
      }
      return ["pang@cardiostudy.ca", "aeon@cardiostudy.ca"].indexOf(employee.email) >= 0;
    });
  }

  public showHolterSubtab(tab: string): Promise<boolean> {
    return this.authService.getCurrentEmployee().then(employee => {
      if (!employee) {
        return false;
      }
      let permittedPages = [];
      for (const pages of employee.roles.permissions.portal) {
        permittedPages = permittedPages.concat(pages);
      }
      return permittedPages.indexOf(tab) > -1 || permittedPages.indexOf("*") > -1;
    });
  }

}
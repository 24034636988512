<div class="md-width">

  <div class="row sub-header">
    <div class="col-12">
      <div class="text-center">
        <br>
          <ul class="list-inline">
            <li class="list-inline-item table-filter"
                (click)="switchPage(PAGE_SEARCH)"
                [class.table-filter-selected]="page===PAGE_SEARCH">
                <div class="col-xs-3"><i class="fa fa-search"></i></div>
                <div class="col-xs-9 text-center"><p>Search</p></div>
            </li>
            <li class="list-inline-item table-filter"
                (click)="switchPage(PAGE_CREATE)"
                [class.table-filter-selected]="page===PAGE_CREATE || page===PAGE_EDIT">
                <div class="col-xs-3"><i class="fa fa-plus"></i></div>
                <div class="col-xs-9 text-center"><p [innerHTML]="createEditTitle()"></p></div>
            </li>
          </ul>
      </div>
    </div>
  </div>
</div>

<div class="row sub-main">

  <div class="page-title">
    <div class="title_left">
      <h3>Admin - {{pageName()}}</h3>
    </div>
  </div>
  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="x_content">

      <div class="row" [hidden]="page===PAGE_SEARCH">
        <div class="col-12">

          <br>
          <form class="form-horizontal form-label-left input_mask" [formGroup]="createFormGroup">

            <h4>Employee</h4>
            <div class="row" [hidden]="page===PAGE_EDIT">
              <div class="col">
                <div class="row">
                  <div class="col form-group">
                    <label>If adding Doctor, please search below. If not found, please create the doctor first in the directory tab.</label>
                    <input type="text" class="form-control" placeholder="Start typing to search for doctor" id="doctor-search-bar" formControlName="ReferringDoctor" [ngbTypeahead]="searchReferringDoctor" [resultFormatter]="searchReferringDoctorFormatter" [inputFormatter]="searchReferringDoctorInputFormatter">
                  </div>
                  <div class="col form-group">

                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="row">
                  <div class="col form-group required">
                    <label for="inputSuccess2">Last Name</label>
                    <input type="text" class="form-control" formControlName="LastName" placeholder="" />
                  </div>
                  <div class="col form-group required">
                    <label for="inputSuccess2">First Name</label>
                    <input type="text" class="form-control" formControlName="FirstName" placeholder="">
                  </div>
                </div>
                <div class="row">
                  <div class="col form-group required">
                    <label for="inputSuccess2">Job Title</label>
                    <input type="text" class="form-control" formControlName="JobTitle" placeholder="" />
                  </div>
                  <div class="col form-group required">
                    <label for="inputSuccess2">Email</label>
                    <input type="text" class="form-control" formControlName="Email" placeholder="" [attr.disabled]="page===PAGE_EDIT ? '' : null">
                  </div>
                </div>
                <div class="row">
                  <div class="col form-answer error-message" [hidden]="error === ''">{{error}}</div>
                </div>
              </div>
              <div class="col-3">
                <div class="row">
                  <div class="col form-group required">
                    <label for="inputSuccess2">Status</label>
                    <input type="text" class="form-control" formControlName="Status" placeholder="" />
                  </div>
                </div>
                <div class="row">
                  <div class="col form-group required">
                    <label for="inputSuccess2">City</label>
                    <input type="text" class="form-control" formControlName="City" placeholder="" />
                  </div>
                </div>
              </div>
              <div class="col-3">
                <div class="row">
                  <div class="col form-group required">
                    <label for="inputSuccess2">Roles</label>
                    <select class="form-control" formControlName="RoleId" data-live-search="true" multiple size="10">
                      <option *ngFor="let role of roles" [value]="role.id">{{role.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <!-- TODO: need to add section here to assign user to a doctor id -->
            <br>

            <h4>Company</h4>
            <div class="row">
              <div class="col form-group required" [hidden]="creatingCompany()">
                <label for="inputSuccess2">Name</label>
                <select class="form-control" formControlName="OwnerId" data-live-search="true">
                  <option *ngFor="let owner of owners" [value]="owner.id">{{owner.name}}</option>
                  <option>{{NONE_OPTION}}</option>
                </select>
              </div>
              <div class="col form-group required" [hidden]="!creatingCompany()">
                <label for="inputSuccess2">Name</label>
                <input type="text" class="form-control" formControlName="OwnerName" placeholder="Cardio Study" />
              </div>
              <div class="col form-group required">
                <label for="inputSuccess2">Domain</label>
                <input type="text" class="form-control" formControlName="OwnerDomain" placeholder="cardiostudy.ca">
              </div>
            </div>
            <div class="row">
              <div class="col-6 form-group required">
                <label for="inputSuccess2">Full Address</label>
                <input type="text" class="form-control" formControlName="OwnerAddress" placeholder="187 St. Clair Avenue West, Toronto, Ontario M4V 1P7" />
              </div>
              <div class="col form-group required">
                <label for="inputSuccess2">Telephone</label>
                <input type="text" class="form-control" formControlName="OwnerTelephone" placeholder="1(800)123-1234">
              </div>
              <div class="col form-group required">
                <label for="inputSuccess2">Fax</label>
                <input type="text" class="form-control" formControlName="OwnerFax" placeholder="1(800)123-1234">
              </div>
            </div>
            <div class="row">
              <div class="col-3 form-group required">
                <label for="inputSuccess2">Default ABPM Price (CAD)</label>
                <input type="text" class="form-control" formControlName="OwnerAbpmPrice" placeholder="75" />
              </div>
            </div>
            <div class="row">
              <div class="col form-answer bottom-info-message">Please note that any changes to this section will apply to everyone within the same company.**</div>
            </div>
            <br>

            <h4>Password</h4>

            <div class="row" [hidden]="page===PAGE_CREATE">
              <div class="col-3 form-group">
                <div class="row">
                  <div class="col">
                    <label for="inputSuccess2">New Password</label>
                    <input type="password" class="form-control" formControlName="Password">
                  </div>
                </div>
                <div class="row">
                  <div class="col text-center">
                    <button class="btn btn-primary btn-review" (click)="resetPassword(employee)" [disabled]="createFormGroup.get('Password').value === '' || page===PAGE_CREATE">Reset</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" [hidden]="page!==PAGE_CREATE">
              <div class="col-3 form-group required">
                <div class="row">
                  <div class="col">
                    <label for="inputSuccess2">Password</label>
                    <input type="password" class="form-control" formControlName="Password">
                  </div>
                </div>
              </div>
            </div>

            <br>

          </form>
        </div>
      </div>

      <div class="row" [hidden]="page===PAGE_CREATE || page===PAGE_EDIT">
        <div class="col-12">
          <div class="offset-3 col-6">
            <input type="text" class="form-control search-bar" placeholder="Start typing to search" id="search-bar">
          </div>
          <form class="form-horizontal form-label-left input_mask row">

            <div class="col form-group">
              <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                <label for="inputSuccess2">Last Name</label>
                <input type="text" class="form-control" [(ngModel)]="employee.lastname" name="lastname" disabled>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                <label for="inputSuccess2">First Name</label>
                <input type="text" class="form-control" [(ngModel)]="employee.firstname" name="firstname" disabled>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                <label for="inputSuccess2">Job Title</label>
                <input type="text" class="form-control" [(ngModel)]="employee.job" name="job" disabled>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                <label for="inputSuccess2">Email</label>
                <input type="text" class="form-control" [(ngModel)]="employee.email" name="email" disabled>
              </div>
            </div>

            <div class="col form-group">
              <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                <label for="inputSuccess2">Company</label>
                <input type="text" class="form-control" [(ngModel)]="employee.owner_name" name="owner_name" disabled>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                <label for="inputSuccess2">City</label>
                <input type="text" class="form-control" [(ngModel)]="employee.city" name="city" disabled>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                <label for="inputSuccess2">Status</label>
                <input type="text" class="form-control" [(ngModel)]="employee.status" name="status" disabled>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                <label for="inputSuccess2">Role</label>
                <input type="text" class="form-control" [(ngModel)]="employee.role_name" name="role" disabled>
              </div>
            </div>

          </form>
          <br>

          <!-- <h4>Browse</h4> -->
          <div class="col-12">
            <table datatable [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover">
              <thead>
                <tr>
                  <th>Modified On</th>
                  <th>Class</th>
                  <th>Company</th>
                  <th>Role</th>
                  <th>Email</th>
                  <th>Last Name</th>
                  <th>First Name</th>
                  <th>City</th>
                  <th>Id</th>
                </tr>
              </thead>
            </table>
          </div>

          <br>

        </div>
      </div>

    </div>
  </div>
</div>

<div>
  <div class="row sub-footer">
    <div class="col-12" [hidden]="page===PAGE_CREATE || page===PAGE_EDIT">
      <button class="btn btn-primary btn-review" [disabled]="!employee.id" (click)="switchPage(PAGE_EDIT)"><i class="fa fa-pencil"></i> &nbsp; Edit</button>
      <button class="btn btn-primary btn-review" [disabled]="!employee.id" (click)="deleteEmployee(employee)"><i class="fa fa-ban"></i> &nbsp; Delete</button>
    </div>
    <div class="col-12" [hidden]="page===PAGE_SEARCH">
      <button class="btn btn-primary btn-review" (click)="onSubmit()" [disabled]="!createFormGroup.valid"><i class="fa fa-check"></i> &nbsp; Submit</button>
      <button class="btn btn-primary btn-review" (click)="switchPage(PAGE_SEARCH)"><i class="fa fa-times"></i> &nbsp; Cancel</button>
    </div>
  </div>
</div>
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    public auth: AuthService,
    public router: Router
  ) {}

  private isMatchingRoute(routeA, routeB): boolean {
    if (routeA.length != routeB.length) {
      return false;
    }

    for (let idx = 0; idx < routeA.length; idx++) {
      if (routeA[idx] != "*" && routeB[idx] != "*" && routeA[idx] != routeB[idx]) {
        return false;
      }
    }
    return true;
  }

  private containsMatchingRoute(permittedRoutes, currentRoute): boolean {
    const currentParts = currentRoute.split("/");
    for (const permittedRoute of permittedRoutes) {
      if (this.isMatchingRoute(currentParts, permittedRoute.split("/"))) {
        return true;
      }
    }
    return false;
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.auth.isAuthenticated(route.queryParams, route.url)
      .then((authenticated: boolean) => {
        if (!authenticated) {
          this.router.navigate(['/login']);
        }

        return this.auth.getCurrentEmployee().then(employee => {
          const permittedPages = employee.roles.permissions.portal;
          const authorized = permittedPages.indexOf("*") > -1 || this.containsMatchingRoute(permittedPages, state.url);
          if (authorized) {
            return true;
          }

          if (permittedPages.length > 0) {

            let routeFound = false;

            // load one within the same starting path
            const pathParts = state.url.split("/");
            if (pathParts.length >= 2) {
              const startingPath = "/" + pathParts[1];
              for (const page of permittedPages) {
                if (page.startsWith(startingPath)) {
                  this.router.navigateByUrl(page);
                  routeFound = true;
                }
              }
            }

            // fallback, just load any page
            if (!routeFound) {
              for (const page of permittedPages) {
                if (page.indexOf("*") < 0) {
                  this.router.navigateByUrl(page);
                }
              }
            }
          } else {
            this.router.navigate(['/login']);
          }
        })

      });
  }
}
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';

import { Physician } from "../../models/physician.model";
import { AuthService } from "../../providers/auth.service";
import { PhysicianService } from "../../providers/physician.service";
import { ClinicService } from "../../providers/clinic.service";
import { DataTablesResponse } from "../../models/database-response.model";
import * as moment from 'moment';

@Component({
  selector: 'app-fax-directory',
  templateUrl: './fax-directory.component.html',
  styleUrls: ['./fax-directory.component.scss']
})
export class FaxDirectoryComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;

  public dtOptions: any = {};
  public formGroup: FormGroup;

  public currentDoctor: Physician | null;

  public formChanged: boolean = false;
  public hiddenDoctors: boolean = false;
  public hiddenClinics: boolean = false;

  public ngOnInit() {}

  constructor(
    private authService: AuthService,
    private physicianService: PhysicianService,
    private clinicService: ClinicService,
  ) {

    this.physicianService.clearFilters();
    this.physicianService.updateSrcFilter("directory_xlsx");
    this.physicianService.updateHiddenDoctorFilter(false);
    this.physicianService.updateHiddenClinicFilter(false);

    // roll callback
    const rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
      $("td", row).unbind("click");
      $("td", row).bind("click", () => this.rowClickHandler(data));
      return row;
    };

    // datatable options
    this.dtOptions = {
        autoWidth: false,
        responsive: true,
        lengthChange: false,
        select: true,
        pageLength: 50,
        dom: "Blfrtip",
        buttons: [],
        rowCallback: rowCallback,
        serverSide: true,
        processing: true,
        scrollY: window.innerHeight - 650,
        order: [[ 0, "asc" ]],
        ajax: this.physicianService.getAjaxFuncion(),
        columns: [
          { data: 'doctor:last_name' },
          { data: 'doctor:first_initials' },
          { data: 'doctor:billing_number' },
          { data: "clinic_assignment:billing_code" },
          { data: 'doctor:title' },
          { data: 'doctor:roles' },
          { data: 'clinic_assignment:fax_number' },
          {
            data: 'clinic_assignment:clinic',
            orderable: false,
            render: ( data, type, row, meta ) => {
              return data?.name || null;
            },
          },
          {
            data: 'clinic_assignment:clinic',
            orderable: false,
            render: ( data, type, row, meta ) => {
              return data?.city || null;
            },
          },
          {
            data: 'clinic_assignment:clinic',
            orderable: false,
            render: ( data, type, row, meta ) => {
              return data?.province || null;
            },
          },
          { data: 'doctor:id', visible: false },
          { data: 'doctor:is_hidden_at', visible: false },
          { data: 'clinic_assignment:is_hidden_at', visible: false },
        ]
    };

    this.formGroup = new FormGroup({
      LastName: new FormControl("", [ Validators.required, ]),
      FirstName: new FormControl("", [ Validators.required, ]),
      BillingNunber: new FormControl("", []),
      BillingCode: new FormControl("", []),
      FaxNumber: new FormControl("", []),
      Title: new FormControl("", []),
      Roles: new FormControl("", []),
      ClinicName: new FormControl("", [ Validators.required, ]),
      Address: new FormControl("", []),
      City: new FormControl("", []),
      Province: new FormControl("", []),
      PostalCode: new FormControl("", []),
    });

    this.formGroup.valueChanges.subscribe(x => {
      this.formChanged = true;
    });

    // $(".lastNameAutoComplete").autoComplete({
    //   resolverSettings: {
    //     url: 'testdata/test-list.json'
    //   }
    // });
  }

  private resetFilter() {
    this.physicianService.clearFilters();
    this.physicianService.updateSrcFilter("directory_xlsx");
    this.physicianService.updateHiddenDoctorFilter(this.hiddenDoctors ? true : false);
    this.physicianService.updateHiddenClinicFilter(this.hiddenClinics ? true : false);
  }

  public showHiddenDoctorEntries() {
    this.hiddenDoctors = !this.hiddenDoctors;
    this.hiddenClinics = false;
    this.resetFilter();
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw(false);
    });
  }

  public showHiddenClinicEntries() {
    this.hiddenClinics = !this.hiddenClinics;
    this.hiddenDoctors = false;
    this.resetFilter();
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw(false);
    });
  }

  public rowClickHandler(info: any, force: boolean = false) {
    this.physicianService.getDoctor(info["doctor:id"], info["clinic_assignment:clinic"]?.id).then(doctor => this.populateDoctor(doctor));
  }

  private populateDoctor(doctor: Physician) {
    this.formGroup.patchValue({
      LastName: doctor.last_name,
      FirstName: doctor.first_initials,
      BillingNunber: doctor.billing_number,
      BillingCode: doctor.billing_code,
      FaxNumber: doctor.fax_number,
      Title: doctor.title,
      Roles: doctor.roles,
      ClinicName: doctor.clinic?.name,
      Address: doctor.clinic?.street,
      City: doctor.clinic?.city,
      Province: doctor.clinic?.province,
      PostalCode: doctor.clinic?.postal_code,
    });
    this.currentDoctor = doctor;
    this.formChanged = false;
  }

  public ngAfterViewInit(): void {
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      $("#search-bar").on("keyup change", function () {
        setTimeout(() => {
          const search_string = this["value"].replace(',', '')
          if (dtInstance.search() !== search_string) {
            dtInstance.search(search_string).draw();
          }
        }, 1000);
      });
    });
    $("#search-bar").focus();
  }

  public isAddingDoctor(): boolean {
    return this.currentDoctor && !this.currentDoctor.id;
  }

  public upsertInfo() {
    this.upsertDoctor().then(() => {
      this.formChanged = false;
      this.datatableElement?.dtInstance.then((dtInstance: any) => {
        dtInstance.draw();
      });
    });
  }

  public upsertDoctor(): Promise<any> {
    const data = {
      last_name: this.formGroup.get("LastName")?.value.last_name || this.formGroup.get("LastName")?.value,
      first_initials: this.formGroup.get("FirstName")?.value,
      name: (this.formGroup.get("LastName")?.value.last_name || this.formGroup.get("LastName")?.value) + ", " + this.formGroup.get("FirstName")?.value,
      billing_number: this.formGroup.get("BillingNunber")?.value,
      billing_code: this.formGroup.get("BillingCode")?.value,
      fax: this.formGroup.get("FaxNumber")?.value,
      title: this.formGroup.get("Title")?.value,
      roles: this.formGroup.get("Roles")?.value,
      import_src: "directory_xlsx",
      clinics: [{
        name: this.formGroup.get("ClinicName")?.value?.name || this.formGroup.get("ClinicName")?.value,
        street: this.formGroup.get("Address")?.value,
        city: this.formGroup.get("City")?.value,
        province: this.formGroup.get("Province")?.value,
        postal_code: this.formGroup.get("PostalCode")?.value,
      }],
    };
    if (this.isAddingDoctor()) {
      return this.physicianService.insertDoctor(data);
    } else {
      return this.physicianService.updateDoctor(this.currentDoctor.id, data);
    }
  }

  public disableDoctor() {

  }

  public enableDoctor() {

  }

  public hideDoctor(hide : boolean) {
    if (hide) {
      this.physicianService.hideDoctor(this.currentDoctor.id).then(result => {
        this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.draw(false);
        });
      });
    }
    else {
      this.physicianService.unhideDoctor(this.currentDoctor.id).then(result => {
        this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.draw(false);
        });
      });
    }
  }

  public hideClinic(hide : boolean) {
    const data = {
      clinics: [{
        name: this.formGroup.get("ClinicName")?.value?.name || this.formGroup.get("ClinicName")?.value,
        street: this.formGroup.get("Address")?.value,
        city: this.formGroup.get("City")?.value,
        province: this.formGroup.get("Province")?.value,
        postal_code: this.formGroup.get("PostalCode")?.value,
      }],
    };
    if (hide) {
      this.physicianService.hideDoctorClinic(this.currentDoctor.id, data).then(result => {
        this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.draw(false);
        });
      });
    }
    else {
      this.physicianService.unhideDoctorClinic(this.currentDoctor.id, data).then(result => {
        this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.draw(false);
        });
      });
    }
  }

  public newDoctor() {
    this.datatableElement?.dtInstance.then((dtInstance: any) => {
      dtInstance.rows().deselect();
    });
    this.formGroup.reset();
    this.currentDoctor = new Physician();
  }

  searchClinic = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => (!this.authService.isCardioStudyCompanyClass() || term.length < 2) ? Promise.resolve([])
        : this.clinicService.searchClinics(term))
    )

  searchClinicFormatter = (result: any): string => result.name;

  searchClinicInputFormatter = (result: any): string => {
    if (!result.name) {
      return result;
    }

    this.formGroup.patchValue({
      ClinicName: result.name,
      Address: result.street,
      City: result.city,
      Province: result.province,
      PostalCode: result.postal_code,
    });
    return result.name;
  };

  searchDoctor = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => (!this.authService.isCardioStudyCompanyClass() || term.length < 2 || !this.isAddingDoctor()) ? Promise.resolve([])
        : this.physicianService.searchDoctor(term, true, false))
    )

  searchDoctorFormatter = (result: any): string => {
    return [
      result.last_name,
      result.first_initials,
      result.clinic ? result.clinic.city : null,
      result.billing_code,
    ].filter(x => !!x).join(" | ")
  };

  searchDoctorInputFormatter = (result: any): string => {
    if (!result.last_name) {
      return result;
    }

    this.formGroup.patchValue({
      LastName: result.last_name,
      FirstName: result.first_initials,
      BillingNunber: result.billing_number,
      BillingCode: result.billing_code,
      FaxNumber: result.fax_number,
      Title: result.title,
      Roles: result.roles,
    });
    return result.last_name;
  };

}

<div>
  <div class="row sub-main">

    <div class="page-title">
      <div class="title_left">
        <h3>{{title}}</h3>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="x_panel">
      <div class="x_content">

        <br><br>

        <!-- These divs require abpm to be loaded first -->
        <div *ngIf="abpm$ | async as abpm">

          <div class="row" [hidden]="hidePatientEdit(abpm)">
            <div class="col-12 md-width">
              <h4>Patient Information</h4>
              <form class="form-horizontal form-label-left" [formGroup]="createFormGroup">
                <div class="form-group form-display row">
                  <div class="col form-group required">
                    <label for="inputSuccess2">Health Card (or Blue Cross, IFH, etc.)</label>
                    <input type="text" class="form-control" placeholder="1234123123" formControlName="HealthCard">
                  </div>
                  <div class="col form-group required">
                    <label for="inputSuccess2">Referring Doctor (full name)</label>
                    <input type="text" class="form-control" placeholder="" formControlName="ReferringDoctorName">
                  </div>
                  <div class="col form-group required">
                    <label for="inputSuccess2">Reading Doctor (full name)</label>
                    <input type="text" class="form-control" placeholder="" formControlName="ReadingDoctorName" list="readinglist">
                      <datalist id="readinglist">
                        <option *ngFor="let dr of readingDoctorOptions" [value]="dr.value">{{dr.text}}</option>
                      </datalist>
                  </div>
                  <div class="col form-group required">
                    <label for="inputSuccess2">Read By</label>
                    <select class="form-control" formControlName="ReadBy" data-live-search="true">
                      <option>Cardio Study</option>
                      <option>In-House</option>
                    </select>
                  </div>
                </div>
                <div class="form-group form-display row">
                  <div class="col form-group required">
                    <label for="inputSuccess2">First Name</label>
                    <input type="text" class="form-control" placeholder="" formControlName="FirstName">
                  </div>
                  <div class="col form-group required">
                    <label for="inputSuccess2">Last Name</label>
                    <input type="text" class="form-control" placeholder="" formControlName="LastName">
                  </div>
                  <div class="col form-group required">
                    <label for="inputSuccess2">Birthdate</label>
                    <input type="text" class="form-control" [class.has-error]="hasError('Birthdate')" placeholder="yyyy/mm/dd" formControlName="Birthdate" dateInput>
                    <div *ngIf="hasError('Birthdate')" class="error-message">Birthdate needs to be in YYYY/MM/DD format.</div>
                  </div>
                  <div class="col form-group required">
                    <label for="inputSuccess2">Gender</label>
                    <select class="form-control" formControlName="Gender" data-live-search="true">
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </select>
                  </div>
                </div>
                <div class="form-group form-display row">
                  <div class="col-6 form-group required">
                    <label for="inputSuccess2">Clinic Address</label>
                    <input id="typeahead-clinic" type="text" class="form-control" formControlName="Location" [ngbTypeahead]="searchClinic" placeholder="" />
                  </div>
                  <div class="col form-group required">
                    <label for="inputSuccess2" (click)="toggleHeightUnit()" class="toggle-unit no-select">Height ({{heightUnit}})</label>
                    <input type="text" class="form-control" placeholder="0" formControlName="Height" numberInput>
                  </div>
                  <div class="col form-group required">
                    <label for="inputSuccess2" (click)="toggleWeightUnit()" class="toggle-unit no-select">Weight ({{weightUnit}})</label>
                    <input type="text" class="form-control" placeholder="0" formControlName="Weight" numberInput>
                  </div>
                </div>
                <div class="form-group form-display row">
                  <div class="col form-group">
                    <label for="inputSuccess2">Indication for Test</label>
                    <input type="text" class="form-control" placeholder="" formControlName="Indication">
                  </div>
                </div>
                <div class="form-group form-display row">
                  <div class="col form-group">
                    <label for="inputSuccess2">Medications</label>
                    <textarea class="form-control" rows="3" formControlName="Medications"></textarea>
                  </div>
                </div>
                <div class="text-right">
                  <button class="btn btn-primary btn-review" type="button" [disabled]="!createFormGroup.valid" (click)="onSubmit(abpm)"><i class="fa fa-check"></i> Assign</button>
                </div>
              </form>
            </div>
          </div>

          <div class="row" [hidden]="!hidePatientEdit(abpm)">
            <div class="col-12 md-width">
              <h4>Patient Information</h4>
              <form class="form-horizontal form-label-left">
                <div class="form-group form-display row">
                  <label class="control-label col-2" for="first-name">Name </label>
                  <div class="col form-answer">{{report ? report.patient.firstName + " " + report.patient.lastName : " - "}}</div>
                  <label class="control-label col-2" for="last-name">Health Card </label>
                  <div class="col form-answer">{{report ? report.patient.healthCard : " - "}}</div>
                </div>
                <div class="form-group form-display row">
                  <label class="control-label col-2" for="first-name">Referring Doctor </label>
                  <div class="col form-answer">{{report ? report.patient.referringDoctor : " - "}}</div>
                  <label class="control-label col-2" for="last-name">Read By </label>
                  <div class="col form-answer">{{report ? report.patient.readBy : " - "}}</div>
                </div>
                <div class="form-group form-display row">
                  <label class="control-label col-2" for="first-name">Reading Doctor </label>
                  <div class="col form-answer">{{report ? report.patient.readingDoctor : " - "}}</div>
                  <label class="control-label col-2" for="last-name">Birthdate </label>
                  <div class="col form-answer">{{report ? report.patient.birthdate : " - "}}</div>
                </div>
                <div class="form-group form-display row">
                  <label class="control-label col-2" for="first-name">Location </label>
                  <div class="col form-answer">{{report ? report.patient.location : " - "}}</div>
                  <label class="control-label col-2" for="last-name">Gender </label>
                  <div class="col form-answer">{{report ? report.patient.gender : " - "}}</div>
                </div>
                <div class="form-group form-display row">
                  <label class="control-label col-2" for="last-name">Height (cm) </label>
                  <div class="col form-answer">{{report ? report.patient.height : " - "}}</div>
                  <label class="control-label col-2" for="last-name">BMI </label>
                  <div class="col form-answer">{{report ? report.patient.bmi : " - "}}</div>
                </div>
                <div class="form-group form-display row">
                  <label class="control-label col-2" for="first-name">Weight (kg) </label>
                  <div class="col form-answer">{{report ? report.patient.weight : " - "}}</div>
                  <label class="control-label col-2" for="last-name">BSA (m<sup>2</sup>) </label>
                  <div class="col form-answer">{{report ? report.patient.bsa : " - "}}</div>
                </div>
                <div class="form-group form-display row">
                  <label class="control-label col-2" for="first-name">Indication for Test </label>
                  <div class="col form-answer">{{report ? report.patient.indication : " - "}}</div>
                  <label class="control-label col-2" for="last-name">Medications </label>
                  <div class="col form-answer">{{report ? report.patient.medications : " - "}}</div>
                </div>
                <br><br>
              </form>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-12 md-width">
            <h4>Test Information</h4>
            <form class="form-horizontal form-label-left">
              <div class="form-group form-display row">
                <label class="control-label col-2" for="first-name">Device Id </label>
                <div class="col form-answer">{{report ? report.test.serialNumber : " - "}}</div>
                <label class="control-label col-2" for="last-name">Overall Successful </label>
                <div class="col form-answer">{{report ? report.test.successfulReading : " - "}}</div>
              </div>
              <div class="form-group form-display row">
                <label class="control-label col-2" for="first-name">Start </label>
                <div class="col form-answer">{{report ? report.test.start : " - "}}</div>
                <!-- <label class="control-label col-2" for="last-name">Overall Average </label>
                <div class="col form-answer">{{report ? report.test.overallAverage : " - "}}</div> -->
                <label class="control-label col-2" for="last-name">Day Time Successful </label>
                <div class="col form-answer">{{report ? report.test.dayTimeSuccessful : " - "}}</div>
              </div>
              <div class="form-group form-display row">
                <label class="control-label col-2" for="first-name">End </label>
                <div class="col form-answer">{{report ? report.test.end : " - "}}</div>
                <label class="control-label col-2" for="last-name">Night Time Successful </label>
                <div class="col form-answer">{{report ? report.test.nightTimeSuccessful : " - "}}</div>
              </div>
              <div class="form-group form-display row">
                <label class="control-label col-2" for="first-name">Duration (hours) </label>
                <div class="col form-answer">{{report ? report.test.duration : " - "}}</div>
              </div>
            </form>
          </div>
        </div>

        <br><br>

        <div class="row" [hidden]="false">
          <div class="col-12 md-width">
            <h4>Tabular Summary</h4>

            <table class="table">
              <thead>
                <tr>
                  <th width="40%">{{report ? ("Overall Summary - Successful: " + report.test.successfulReading) : "Overall Summary"}}</th>
                  <th width="20%">Average</th>
                  <th width="20%">Minimum</th>
                  <th width="20%">Maximum</th>
                </tr>
              </thead>
              <tbody>
                <tr class="shaded">
                  <td>SYSTOLIC (mmHg)</td>
                  <td>{{report ? report.tabular.total.systolic.average : "-"}}</td>
                  <td>{{report ? report.tabular.total.systolic.min : "-"}}</td>
                  <td>{{report ? report.tabular.total.systolic.max : "-"}}</td>
                </tr>
                <tr>
                  <td>DIASTOLIC (mmHg)</td>
                  <td>{{report ? report.tabular.total.diastolic.average : "-"}}</td>
                  <td>{{report ? report.tabular.total.diastolic.min : "-"}}</td>
                  <td>{{report ? report.tabular.total.diastolic.max : "-"}}</td>
                </tr>
                <tr class="shaded">
                  <td>MAP (mmHg)</td>
                  <td>{{report ? report.tabular.total.map.average : "-"}}</td>
                  <td>{{report ? report.tabular.total.map.min : "-"}}</td>
                  <td>{{report ? report.tabular.total.map.max : "-"}}</td>
                </tr>
                <tr>
                  <td>PULSE RATE (BPM)</td>
                  <td>{{report ? report.tabular.total.pulseRate.average : "-"}}</td>
                  <td>{{report ? report.tabular.total.pulseRate.min : "-"}}</td>
                  <td>{{report ? report.tabular.total.pulseRate.max : "-"}}</td>
                </tr>
                <tr class="shaded">
                  <td>PULSE PRESSURE (mmHg)</td>
                  <td>{{report ? report.tabular.total.pulsePressure.average : "-"}}</td>
                  <td>{{report ? report.tabular.total.pulsePressure.min : "-"}}</td>
                  <td>{{report ? report.tabular.total.pulsePressure.max : "-"}}</td>
                </tr>
              </tbody>
            </table>

            <table class="table">
              <thead>
                <tr>
                  <th width="40%">{{report ? ("Daytime Summary - Successful: " + report.test.dayTimeSuccessful) : "Daytime Summary"}}</th>
                  <th width="20%">Average</th>
                  <th width="20%">Minimum</th>
                  <th width="20%">Maximum</th>
                </tr>
              </thead>
              <tbody>
                <tr class="shaded">
                  <td>SYSTOLIC (mmHg)</td>
                  <td>{{report ? report.tabular.day.systolic.average : "-"}}</td>
                  <td>{{report ? report.tabular.day.systolic.min : "-"}}</td>
                  <td>{{report ? report.tabular.day.systolic.max : "-"}}</td>
                </tr>
                <tr>
                  <td>DIASTOLIC (mmHg)</td>
                  <td>{{report ? report.tabular.day.diastolic.average : "-"}}</td>
                  <td>{{report ? report.tabular.day.diastolic.min : "-"}}</td>
                  <td>{{report ? report.tabular.day.diastolic.max : "-"}}</td>
                </tr>
                <tr class="shaded">
                  <td>MAP (mmHg)</td>
                  <td>{{report ? report.tabular.day.map.average : "-"}}</td>
                  <td>{{report ? report.tabular.day.map.min : "-"}}</td>
                  <td>{{report ? report.tabular.day.map.max : "-"}}</td>
                </tr>
                <tr>
                  <td>PULSE RATE (BPM)</td>
                  <td>{{report ? report.tabular.day.pulseRate.average : "-"}}</td>
                  <td>{{report ? report.tabular.day.pulseRate.min : "-"}}</td>
                  <td>{{report ? report.tabular.day.pulseRate.max : "-"}}</td>
                </tr>
                <tr class="shaded">
                  <td>PULSE PRESSURE (mmHg)</td>
                  <td>{{report ? report.tabular.day.pulsePressure.average : "-"}}</td>
                  <td>{{report ? report.tabular.day.pulsePressure.min : "-"}}</td>
                  <td>{{report ? report.tabular.day.pulsePressure.max : "-"}}</td>
                </tr>
              </tbody>
            </table>

            <table class="table">
              <thead>
                <tr>
                  <th width="40%">{{report ? ("Nighttime Summary - Successful: " + report.test.nightTimeSuccessful) : "Nighttime Summary"}}</th>
                  <th width="20%">Average</th>
                  <th width="20%">Minimum</th>
                  <th width="20%">Maximum</th>
                </tr>
              </thead>
              <tbody>
                <tr class="shaded">
                  <td>SYSTOLIC (mmHg)</td>
                  <td>{{report ? report.tabular.evening.systolic.average : "-"}}</td>
                  <td>{{report ? report.tabular.evening.systolic.min : "-"}}</td>
                  <td>{{report ? report.tabular.evening.systolic.max : "-"}}</td>
                </tr>
                <tr>
                  <td>DIASTOLIC (mmHg)</td>
                  <td>{{report ? report.tabular.evening.diastolic.average : "-"}}</td>
                  <td>{{report ? report.tabular.evening.diastolic.min : "-"}}</td>
                  <td>{{report ? report.tabular.evening.diastolic.max : "-"}}</td>
                </tr>
                <tr class="shaded">
                  <td>MAP (mmHg)</td>
                  <td>{{report ? report.tabular.evening.map.average : "-"}}</td>
                  <td>{{report ? report.tabular.evening.map.min : "-"}}</td>
                  <td>{{report ? report.tabular.evening.map.max : "-"}}</td>
                </tr>
                <tr>
                  <td>PULSE RATE (BPM)</td>
                  <td>{{report ? report.tabular.evening.pulseRate.average : "-"}}</td>
                  <td>{{report ? report.tabular.evening.pulseRate.min : "-"}}</td>
                  <td>{{report ? report.tabular.evening.pulseRate.max : "-"}}</td>
                </tr>
                <tr class="shaded">
                  <td>PULSE PRESSURE (mmHg)</td>
                  <td>{{report ? report.tabular.evening.pulsePressure.average : "-"}}</td>
                  <td>{{report ? report.tabular.evening.pulsePressure.min : "-"}}</td>
                  <td>{{report ? report.tabular.evening.pulsePressure.max : "-"}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div>
    <div class="row sub-footer">
      <div class="col-12">
        <!-- <button class="btn btn-primary btn-review" type="button" (click)="createFax()" [hidden]="hideFax()"><i class="fa fa-fax"></i> Fax</button> -->
        <!-- <button class="btn btn-primary btn-review" type="button" (click)="downloadReport()"><i class="fa fa-file-pdf-o"></i> Download</button> -->
        <button class="btn btn-primary btn-review" type="button" (click)="navigateTo('/abpm')"><i class="fa fa-times"></i> Cancel</button>
      </div>
    </div>
  </div>
</div>
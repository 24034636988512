import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';

import { FormControl, FormGroup, Validators } from "@angular/forms";

import * as moment from "moment";

import { Abpm } from "../../models/abpm.model";
import { Visit } from "../../models/visit.model";
import { Physician } from "../../models/physician.model";
import { Clinic } from "../../models/clinic.model";
import { Patient } from "../../models/patient.model";

import { AuthService } from "../../providers/auth.service";
import { AbpmService } from "../../providers/abpm.service";
import { VisitService } from "../../providers/visit.service";
import { PatientService } from "../../providers/patient.service";
import { PhysicianService } from "../../providers/physician.service";
import { ClinicService } from "../../providers/clinic.service";

@Component({
  selector: 'app-home',
  templateUrl: './abpm-statistical.component.html',
  styleUrls: ['./abpm-statistical.component.scss']
})
export class AbpmStatisticalComponent implements OnInit {

  public error: string = "";
  public patient: Patient = new Patient();

  public title: string = "";

  public abpm$: Observable<Abpm>;
  public visit: Visit = new Visit();
  public report: any = null;

  public readonly HEIGHT_METRIC = "cm";
  public readonly HEIGHT_IMPERIAL = "in";
  public readonly WEIGHT_METRIC = "kg";
  public readonly WEIGHT_IMPERIAL = "lb";
  public heightUnit: string = this.HEIGHT_METRIC;
  public weightUnit: string = this.WEIGHT_METRIC;

  public readingDoctorOptions: any[] = [];
  public createFormGroup: FormGroup;

  constructor(
    private authService: AuthService,
    private abpmService: AbpmService,
    private patientService: PatientService,
    private visitService: VisitService,
    private physicianService: PhysicianService,
    private clinicService: ClinicService,
    private route: ActivatedRoute,
    private router: Router
  ) {

    // the following allows reloading of the same route
    this.router.routeReuseStrategy.shouldReuseRoute = function(){
        return false;
    };
    this.router.events.subscribe((evt) => {
        if (evt instanceof NavigationEnd) {
            this.router.navigated = false;
            window.scrollTo(0, 0);
        }
    });

  }

  public ngOnInit() {

    // get the ABPM object from the server
    this.abpm$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.abpmService.getAbpm(parseInt(params.get('id'), 10)))
    );

    // generate the report
    this.abpm$.subscribe((abpm: Abpm) => {
      this.abpmService.verifyMeasurements(abpm.id).then(abpm => {
        return this.visitService.getVisit(abpm.visit_id).then((visit: Visit) => {
          if (!visit) {
            return this.visitService.getMinimumVisit(abpm.visit_id);
          }
          return visit;

        }).then((visit: Visit) => {

          if (visit) {
            if ((visit.patient_first_name.length + visit.patient_last_name.length) < 20) {
              this.title = "ABPM - " + visit.patient_first_name + " " + visit.patient_last_name;
            } else {
              this.title = "ABPM - Statistical";
            }
          } else {
            this.title = "ABPM - Unassigned";
          }

          this.abpmService.getAnalysis(abpm.id).then(response => {
            abpm.analysis = response.analysis;
            this.report = response.report;
            this.visit = visit;
            this.populateForm(null, visit);
          });

        });
      });
    });

    this.createFormGroup = new FormGroup({
      HealthCard: new FormControl("", [ Validators.required, ]),
      ReferringDoctorName: new FormControl("", [ Validators.required, ]),
      ReadingDoctorName: new FormControl("", [ Validators.required, ]),
      FirstName: new FormControl("", [ Validators.required, ]),
      LastName: new FormControl("", [ Validators.required, ]),
      Location: new FormControl("", [ Validators.required, ]),
      Birthdate: new FormControl("", [ Validators.required, Validators.pattern(/^\d{4}\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/) ]),
      Height: new FormControl("", [ Validators.required, Validators.pattern(/^\d*\.?\d*$/) ]),
      Weight: new FormControl("", [ Validators.required, Validators.pattern(/^\d*\.?\d*$/) ]),
      Gender: new FormControl("", [ Validators.required, ]),
      Indication: new FormControl("", [ ]),
      Medications: new FormControl("", [ ]),
      ReadBy: new FormControl("", [ Validators.required, ]),
    });

    this.physicianService.allReading().then(doctors => {
      doctors.forEach(doctor => {
        const text = [
          doctor.last_name,
          doctor.first_initials,
          doctor.clinic ? doctor.clinic.city : null,
          doctor.billing_code,
        ].filter(x => !!x).join(" | ");

        this.readingDoctorOptions.push({
          value: doctor.name,
          text: text,
        });
      });
    });

    this.createFormGroup.valueChanges.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      switchMap((changes: any) => {
        if (changes.HealthCard.replace(/[^\d]/g, '').substr(0, 10).length < 1) {
          return Promise.resolve();
        }
        return this.patientService.getPatientByHealthCard(changes.HealthCard).then((patient: Patient) => {
            if (patient && (!this.patient || patient.id !== this.patient.id)) {
              this.populateForm(patient, null);
            }
            this.patient = patient;
          });
      })
     ).subscribe();
  }

  public hasError(fieldName: string): boolean {
    return fieldName == "Birthdate" && (this.createFormGroup.controls.Birthdate.errors?.required || this.createFormGroup.controls.Birthdate.errors?.pattern);
  }

  public hidePatientEdit(abpm: Abpm): boolean {
    if (!abpm) {
      return true;
    }
    return abpm.status.toLowerCase() === "completed";
  }

  public toggleHeightUnit(): void {
    this.heightUnit = (this.heightUnit == this.HEIGHT_METRIC) ? this.HEIGHT_IMPERIAL : this.HEIGHT_METRIC;
  }

  public toggleWeightUnit(): void {
    this.weightUnit = (this.weightUnit == this.WEIGHT_METRIC) ? this.WEIGHT_IMPERIAL : this.WEIGHT_METRIC;
  }

  private populateForm(patient: Patient, visit: Visit): void {
    if (patient) {
      this.createFormGroup.patchValue({
        FirstName: patient.first_name,
        LastName: patient.last_name,
        Birthdate: patient.birthdate,
        Height: patient.height,
        Weight: patient.weight,
        Gender: patient.gender,
      });
    }

    if (visit) {
      this.createFormGroup.patchValue({
        Medications: visit.medications,
        Indication: visit.indication,
        ReadBy: visit.read_by,
        HealthCard: visit.patient_health_card,
        FirstName: visit.patient_first_name,
        LastName: visit.patient_last_name,
        Birthdate: visit.patient_birthdate,
        Height: visit.patient_height,
        Weight: visit.patient_weight,
        Gender: visit.patient_gender,
        Location: visit.clinic_street,
        ReferringDoctorName: visit.referring_doctor_name,
        ReadingDoctorName: visit.reading_doctor_name,
      })
    }
  }

  public onSubmit(abpm: Abpm): Promise<any> {

    // convert to metric if units aren't in metric
    let height = parseFloat(this.createFormGroup.get("Height")?.value);
    let weight = parseFloat(this.createFormGroup.get("Weight")?.value);
    if (!isNaN(height) && this.heightUnit == this.HEIGHT_IMPERIAL) {
      height = height * 2.54;
    }
    if (!isNaN(weight) && this.weightUnit == this.WEIGHT_IMPERIAL) {
      weight = weight * 0.453592;
    }

    const data = {
      visit: {
        id: abpm.visit_id,
        read_by: this.createFormGroup.get("ReadBy")?.value,
        indication: this.createFormGroup.get("Indication")?.value,
        medications: this.createFormGroup.get("Medications")?.value,
        patient: {
          last_name: this.createFormGroup.get("LastName")?.value,
          first_name: this.createFormGroup.get("FirstName")?.value,
          healthcard: this.createFormGroup.get("HealthCard")?.value,
          gender: this.createFormGroup.get("Gender")?.value,
          birthdate: this.createFormGroup.get("Birthdate")?.value,
          height: Math.round(height * 10) / 10,
          weight: Math.round(weight * 10) / 10,
        },
        clinic: {
          street: this.createFormGroup.get("Location")?.value,
        },
        referring_doctor: {
          name: this.createFormGroup.get("ReferringDoctorName")?.value,
        },
        reading_doctor: {
          name: this.createFormGroup.get("ReadingDoctorName")?.value?.name || this.createFormGroup.get("ReadingDoctorName")?.value,
        }
      },
    };
    return this.abpmService.updateAbpm(abpm.id, data).then(() =>
      this.router.navigateByUrl("/abpm/detail/interpretations/" + this.route.snapshot.params["id"])
    );
  }

  // clinic typeahead
  public searchClinic: any = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        if (!this.authService.isCardioStudyCompanyClass() || term.length < 3) {
          return Promise.resolve([]);
        }
        return this.clinicService.searchClinic(term).then((options: Clinic[]) => {
          if (options === null || options === undefined || options.length === 0) {
            return [];
          }
          return options.map((o: Clinic) => o.street);
        });
      })
    );

  public navigateTo(url: string): void {
    this.router.navigateByUrl(url);
  }
}

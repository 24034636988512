<div class="row sub-main no-footer">

  <div class="page-title">
    <div class="title_left">
      <h3>Holter - Search</h3>
    </div>
  </div>
  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="x_content">

      <div class="row">
        <div class="col-12">

          <div class="row">
            <div class="col-5 col-xl-6">
              <input type="text" class="form-control search-bar" placeholder="Start typing to search" id="search-bar">
            </div>
            <!-- <div class="col-7 col-xl-6 text-center">
              <div class="row">
                <a class="col flag-info" (click)="setEmailFilter()" [ngClass]="emailFilter && 'flag-info-shaded'">
                  <i class="fa fa-user"></i>
                  <div>Uploaded<br>By Me</div>
                </a>
                <a class="col flag-info" (click)="updateFlags('missingDoctor', flags.missingDoctor ? null : true)" [ngClass]="flags.missingDoctor && 'flag-info-shaded'">
                  <i class="fa fa-flag"></i>
                  <div>Missing<br>Doctor</div>
                </a>
                <a class="col flag-info" (click)="updateFlags('infoReqPacemaker', flags.infoReqPacemaker ? null : true)" [ngClass]="flags.infoReqPacemaker && 'flag-info-shaded'">
                  <i class="fa fa-flag"></i>
                  <div>Info, Req,<br>Pace</div>
                </a>
                <a class="col flag-info" (click)="updateFlags('pendingDataSdcard', flags.pendingDataSdcard ? null : true)" [ngClass]="flags.pendingDataSdcard && 'flag-info-shaded'">
                  <i class="fa fa-flag"></i>
                  <div>Pending<br>Data</div>
                </a>
                <a class="col flag-info" (click)="updateFlags('lostDamagedData', flags.lostDamagedData ? null : true)" [ngClass]="flags.lostDamagedData && 'flag-info-shaded'">
                  <i class="fa fa-flag"></i>
                  <div>Damaged<br>Data</div>
                </a>
                <a class="col flag-info" (click)="updateFlags('resolved', flags.resolved ? null : true)" [ngClass]="flags.resolved && 'flag-info-shaded'">
                  <i class="fa fa-flag"></i>
                  <div>Case<br>Resolved</div>
                </a>
                <a class="col flag-info" (click)="updateFlags('notFaxed', flags.notFaxed ? null : true)" [ngClass]="flags.notFaxed && 'flag-info-shaded'">
                  <i class="fa fa-flag"></i>
                  <div>Pending<br>Fax</div>
                </a>
              </div>
            </div> -->
          </div>

          <div>
            <table datatable [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover" id="not-pending-table">
              <thead>
                <tr>
                  <th>Files</th>
                  <th>Scan Date</th>
                  <th>Health Card</th>
                  <th>Last Name</th>
                  <th>First Name</th>
                  <th>Birthdate</th>
                  <th>Clinic</th>
                  <th>Clinic Fax</th>
                  <th>Flags</th>
                  <th>Id</th>
                </tr>
              </thead>
            </table>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>
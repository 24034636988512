import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-reserved-faxing-resubmit',
  templateUrl: './modal-reserved-faxing-resubmit.component.html',
  styleUrls: ['./modal-reserved-faxing-resubmit.component.scss']
})
export class ModalReservedFaxingResubmitComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }
  passBack() {this.activeModal.close(true);}

}

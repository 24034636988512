import { AfterViewInit, Component, OnInit, ViewChild, QueryList, ViewChildren, ChangeDetectorRef, ComponentFactoryResolver, ViewContainerRef, ComponentRef  } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';

import * as moment from "moment";
import * as download from "downloadjs";
import { saveAs } from "file-saver";

import { Medtrace } from "../../models/medtrace.model";

import { AuthService } from "../../providers/auth.service";
import { PhysicianService } from "../../providers/physician.service";
import { ClinicService } from "../../providers/clinic.service";
import { MedtraceService } from "../../providers/medtrace.service";
import { HolterService } from "../../providers/holter.service";

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalMedtraceDisplayComponent } from '../modal-medtrace-display/modal-medtrace-display.component';
import { ChartMedtraceHolterMonitorComponent } from '../chart-medtrace-holter-monitor/chart-medtrace-holter-monitor.component';

@Component({
  selector: 'app-logistics-monitor-traffic',
  templateUrl: './logistics-monitor-traffic.component.html',
  styleUrls: ['./logistics-monitor-traffic.component.scss']
})
export class LogisticsMonitorTrafficComponent implements OnInit, AfterViewInit {

  @ViewChildren(DataTableDirective)
  dtElements: QueryList<any>;

  public dtOptions: any = {};
  public dtRendered = true;
  public formGroup: FormGroup;

  public radio = "serial";
  public tableHeaders = ["USAGE", "ENTRY DATE RANGE", "DOCTOR'S NAME [billing number]", "CLINIC NAME", "ZONE", "STATUS [serial]"];

  public isActive = false;
  public isShared = false;
  public isExpired = false;
  public isNotActive = false;
  public isDemo = false;
  public isRepair = false;

  public activeCount = "";
  public demoCount = "";
  public expiredCount = "";
  public repairCount = "";
  public monitorsCount = "";
  public entriesCount = "";

  public activeStyle = {};
  public demoStyle = {};
  public expiredStyle = {};
  public repairStyle = {};

  public multiSelected: boolean = false;
  public currentMedtrace: Medtrace;
  public branchMedtrace: Medtrace;
  public search: string = null;

  public billingCodeList: string[] = [];
  public billingCodeText: any = "";
  public billingCodeSelection: any = "";

  public clinic: any;
  public clinicList: any[] = [];
  public clinicIDList: string[] = [];
  public clinicText: any = "";
  public clinicSelection: any = "";

  public doctor: any;
  public doctorList: any[] = [];
  public doctorIDList: string[] = [];
  public doctorText: any = "";
  public doctorSelection: any = "";

  public staff: any;
  public staffList: any[] = [];
  public staffIDList: string[] = [];
  public staffText: any = "";
  public staffSelection: any = "";

  @ViewChildren('dynamicBillingCodeYTD', { read: ViewContainerRef }) dynamicBillingCodeYTD: QueryList<ViewContainerRef>;
  @ViewChildren('dynamicClinicYTD', { read: ViewContainerRef }) dynamicClinicYTD: QueryList<ViewContainerRef>;
  @ViewChildren('dynamicDoctorYTD', { read: ViewContainerRef }) dynamicDoctorYTD: QueryList<ViewContainerRef>;
  @ViewChildren('dynamicStaffYTD', { read: ViewContainerRef }) dynamicStaffYTD: QueryList<ViewContainerRef>;
  @ViewChildren('dynamicBillingCodeMTD', { read: ViewContainerRef }) dynamicBillingCodeMTD: QueryList<ViewContainerRef>;
  @ViewChildren('dynamicClinicMTD', { read: ViewContainerRef }) dynamicClinicMTD: QueryList<ViewContainerRef>;
  @ViewChildren('dynamicDoctorMTD', { read: ViewContainerRef }) dynamicDoctorMTD: QueryList<ViewContainerRef>;
  @ViewChildren('dynamicStaffMTD', { read: ViewContainerRef }) dynamicStaffMTD: QueryList<ViewContainerRef>;
  // TODO: NEED TO MAKE MULTIPLE COMPONENT REFS! THIS ONLY TAKES THE LATEST ONE MADE
  public componentRef: ComponentRef<ChartMedtraceHolterMonitorComponent>;

  public readonly DATE: string[] = [
    moment().format("YYYY/MM/DD"),
  ];

  public rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
    $("td", row).bind("click", () => this.rowClickHandler(data));
    $("td", row).bind("contextmenu", (e) => {
      e.preventDefault();
      this.rowClickHandler(data);
      setTimeout(() => {
        this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
          dtElement.dtInstance.then((dtInstance: any) => {
            if (dtInstance.table().node().id == 'medtrace-table') {
              this.branchMedtrace["clinic_name"] = data["clinic:name"]
              this.displayCountInformation('left');
            }
          });
        });
      }, 500);
      return false;
    });
    return row;
  };

  public drawCallback: any = (settings) => {
    this.dtElements?.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
          const row = dtInstance.row(rowIdx);
          if (this.currentMedtrace && row.data()["medtrace:id"] == this.currentMedtrace.id) {
            (row as any).select();
          }
          const status = row.data()["medtrace:status"];
          if (status == "ACTIVE" || status == "SHARED" ){
            $(row.node()).addClass('faxed-entry');
          }
          if (status == "REPAIR"){
            $(row.node()).addClass('failed-entry');
          }
          if (status == "EXPIRED"){
            $(row.node()).addClass('closed-entry');
          }
        });
      });
    });
  };

  public getColumns() {
    let columns = [];
    if (this.radio == "serial") {
      columns = [
        { data: "medtrace:focus_count"},
        { data: "medtrace:scan_date",
          render: ( data, type, row, meta ) => {
            const max_date = row["medtrace:scan_date"] ? row["medtrace:scan_date"] : "";
            const beginning_date = row["medtrace:beginning_date"] ? row["medtrace:beginning_date"] : "";
            let date_string = max_date + "<Br>" + beginning_date;
            return date_string;
          },
        },
        { data: "doctor:name",
          render: ( data, type, row, meta ) => {
            const name = row["doctor:name"] ? row["doctor:name"] : "";
            const billing_number = row["doctor:billing_number"] ? row["doctor:billing_number"] : "";
            const doctor_count = parseInt(row["medtrace:doctor_count"]);
            let doctor_count_string = doctor_count > 1 ? "and " + String(doctor_count-1) + " more" : "";
            let name_string = name + "(" + billing_number + ")"+ "<Br>" + doctor_count_string;
            return name_string;
          },
        },
        { data: "clinic:name",
          render: ( data, type, row, meta ) => {
            const name = row["clinic:name"] ? row["clinic:name"] : "";
            const clinic_count = parseInt(row["medtrace:clinic_count"]);
            let clinic_count_string = clinic_count > 1 ? "and " + String(clinic_count-1) + " more" : "";
            let clinic_string = name + "<Br>" + clinic_count_string;
            return clinic_string;
          },
        },
        { data: "medtrace:zone" },
        { data: "medtrace:serial_number",
          render: ( data, type, row, meta ) => {
            const status = row["medtrace:status"] ? row["medtrace:status"] : "";
            const serial = row["medtrace:serial_number"] ? row["medtrace:serial_number"] : "";
            const serial_count = parseInt(row["medtrace:serial_count"]);
            let serial_count_string = serial_count > 1 ? "and " + String(serial_count-1) + " more" : "";
            let serial_string = this.radio == "serial" ? status + "<Br>" + serial : serial + "<Br>" + serial_count_string;
            return serial_string;
          },
        },
        { data: "medtrace:status", visible: false },
        { data: "medtrace:id", visible: false },
        { data: "medtrace:doctor_id", visible: false },
        { data: "medtrace:clinic_id", visible: false },
        { data: "doctor:billing_number", visible: false },
        { data: "medtrace:beginning_date", visible: false },
        { data: "medtrace:doctor_count", visible: false },
        { data: "medtrace:clinic_count", visible: false },
        { data: "medtrace:serial_count", visible: false },
      ]
    }
    else if (this.radio == "clinic") {
      columns = [
        { data: "medtrace:focus_count"},
        { data: "medtrace:active_count",
          render: ( data, type, row, meta ) => {
            const active_count = row["medtrace:active_count"] ? parseInt(row["medtrace:active_count"]) : 0;
            return active_count;
          },
        },
        { data: "medtrace:scan_date",
          render: ( data, type, row, meta ) => {
            const max_date = row["medtrace:scan_date"] ? row["medtrace:scan_date"] : "";
            const beginning_date = row["medtrace:beginning_date"] ? row["medtrace:beginning_date"] : "";
            let date_string = max_date + "<Br>" + beginning_date;
            return date_string;
          },
        },
        { data: "doctor:name",
          render: ( data, type, row, meta ) => {
            const name = row["doctor:name"] ? row["doctor:name"] : "";
            const billing_number = row["doctor:billing_number"] ? row["doctor:billing_number"] : "";
            const doctor_count = parseInt(row["medtrace:doctor_count"]);
            let doctor_count_string = doctor_count > 1 ? "and " + String(doctor_count-1) + " more" : "";
            let name_string = name + "(" + billing_number + ")"+ "<Br>" + doctor_count_string;
            return name_string;
          },
        },
        { data: "clinic:name",
          render: ( data, type, row, meta ) => {
            const name = row["clinic:name"] ? row["clinic:name"] : "";
            const clinic_count = parseInt(row["medtrace:clinic_count"]);
            let clinic_count_string = clinic_count > 1 ? "and " + String(clinic_count-1) + " more" : "";
            let clinic_string = name + "<Br>" + clinic_count_string;
            return clinic_string;
          },
        },
        { data: "medtrace:zone" },
        { data: "medtrace:serial_number",
          render: ( data, type, row, meta ) => {
            const status = row["medtrace:status"] ? row["medtrace:status"] : "";
            const serial = row["medtrace:serial_number"] ? row["medtrace:serial_number"] : "";
            const serial_count = parseInt(row["medtrace:serial_count"]);
            let serial_count_string = serial_count > 1 ? "and " + String(serial_count-1) + " more" : "";
            let serial_string = this.radio == "serial" ? status + "<Br>" + serial : serial + "<Br>" + serial_count_string;
            return serial_string;
          },
        },
        { data: "medtrace:status", visible: false },
        { data: "medtrace:id", visible: false },
        { data: "medtrace:doctor_id", visible: false },
        { data: "medtrace:clinic_id", visible: false },
        { data: "doctor:billing_number", visible: false },
        { data: "medtrace:beginning_date", visible: false },
        { data: "medtrace:doctor_count", visible: false },
        { data: "medtrace:clinic_count", visible: false },
        { data: "medtrace:serial_count", visible: false },
      ]
    }
    return columns
  }

  public branchBuilder = {
      autoWidth: false,
      responsive: true,
      lengthChange: false,
      select: true,
      pageLength: 20,
      dom: "Blfrtip",
      buttons: [],
      rowCallback: this.rowCallback,
      serverSide: true,
      processing: true,
      scrollY: 0.6*window.innerHeight,
      ajax: this.medtraceService.getAjaxFuncion(),
      drawCallback: this.drawCallback,
      order: this.radio == 'serial' ? [[ 1, "desc" ]] : [[ 2, "desc" ]],
      columnDefs: [{
        targets: 0,
        className: 'expand-row-column'
      },{
        targets: 1,
        className: 'download-file-column'
      }],
      columns: this.getColumns(),
      language: {
        infoFiltered: "",
        info: this.radio == 'serial' ? "Showing _START_ of _END_ of _TOTAL_ monitors" : "Showing _START_ of _END_ of _TOTAL_ clinics" ,
      }
  }

  public ngAfterViewInit(): void {

    const that = this;
    document.addEventListener("click",  function(e){
      if (!e.target) {
        return;
      }
      const className = (e.target as Element).className;
      if(className == "as-split-gutter-icon"){
        window.dispatchEvent(new Event('resize'));
        that.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
          dtElement.dtInstance.then((dtInstance: any) => {
            dtInstance.draw();
          });
        });
      }
    });

    // set up the callbacks
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        if (dtInstance.table().node().id == 'medtrace-table') {
          // on search term callback
          $("#search_bar").on("keyup change", function () {
            setTimeout(() => {
              const search_string = this["value"].replace(',', '')
              if (dtInstance.search() !== search_string) {
                dtInstance.search(search_string).draw();
              }
            }, 1000);
          });
        }
      });
    });

    // focus on the search bar right away after load
    $("#search_bar").focus();

    this.formGroup.patchValue({
      DateFilter: "ALL_TIME",
      UsageFilter: "",
    })

    this.medtraceService.getGraphicalDataSettings().then(settings => {
      let user_settings = settings;
      this.billingCodeList = user_settings["billing_code"];
      this.clinicIDList = user_settings["clinic"]["id_list"] ? user_settings["clinic"]["id_list"] : [];
      this.clinicList = user_settings["clinic"]["obj_list"] ? user_settings["clinic"]["obj_list"] : [];
      this.doctorIDList = user_settings["doctor"]["id_list"] ? user_settings["doctor"]["id_list"] : [];
      this.doctorList = user_settings["doctor"]["obj_list"] ? user_settings["doctor"]["obj_list"] : [];
      this.staffIDList = user_settings["staff"]["id_list"] ? user_settings["staff"]["id_list"] : [];
      this.staffList = user_settings["staff"]["obj_list"] ? user_settings["staff"]["obj_list"] : [];
      this.loadComponent("billing", this.dynamicBillingCodeYTD, this.dynamicBillingCodeMTD, this.billingCodeList);
      this.loadComponent("clinic", this.dynamicClinicYTD, this.dynamicClinicMTD, this.clinicIDList);
      this.loadComponent("doctor", this.dynamicDoctorYTD, this.dynamicDoctorMTD, this.doctorIDList);
      this.loadComponent("staff", this.dynamicStaffYTD, this.dynamicStaffMTD, this.staffIDList);
    });
  }

  public ngOnInit() {}

  constructor(
    private medtraceService: MedtraceService,
    private modalService: NgbModal,
    private physicianService: PhysicianService,
    private clinicService: ClinicService,
    private authService: AuthService,
    private holterService: HolterService,
    private cdr: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {

    this.medtraceService.clearFilters();
    this.medtraceService.setCustomFilter("branch_monitor_traffic_serial");

    this.populateMonitorCounts(null, null, null, null);

    // datatable options
    this.dtOptions = this.branchBuilder;

    this.formGroup = new FormGroup({
      FocusFilter: new FormControl("", []),
      DateFrom: new FormControl("", []),
      DateTo: new FormControl("", []),
      Active: new FormControl("", []),
      Expired: new FormControl("", []),
      Demo: new FormControl("", []),
      Repair: new FormControl("", []),
      Search: new FormControl("", []),
      DateFilter: new FormControl("", []),
      UsageFilter: new FormControl("", []),
    });
  }

  private loadComponent(type, hostYTD: QueryList<ViewContainerRef>, hostMTD: QueryList<ViewContainerRef>, list): void {
    this.medtraceService.getMedtraceGraphicalData(type, list).then(counts => {
      hostYTD.map((vcr: ViewContainerRef, index: number) =>{
        const factory = this.componentFactoryResolver.resolveComponentFactory(ChartMedtraceHolterMonitorComponent);
        vcr.clear();
        this.componentRef = vcr.createComponent(factory);
        this.componentRef.instance.chartData = counts["YTD"][list[index]];
      })
      hostMTD.map((vcr: ViewContainerRef, index: number) =>{
        const factory = this.componentFactoryResolver.resolveComponentFactory(ChartMedtraceHolterMonitorComponent);
        vcr.clear();
        this.componentRef = vcr.createComponent(factory);
        this.componentRef.instance.chartData = counts["MTD"][list[index]];
      })
    });
  }

  private populateMonitorCounts(dateFrom, dateTo, lower, upper) {
    this.medtraceService.getMedtraceMonitorCounts(this.radio, dateFrom, dateTo, null, lower, upper).then(counts => {
      this.activeCount = counts.active;
      this.demoCount = counts.demo;
      this.expiredCount = counts.expired;
      this.repairCount = counts.repair;
      this.monitorsCount = this.radio == 'serial' ? counts.monitors + " monitors" : counts.monitors + " clinics";
      this.entriesCount = counts.entries;
      this.activeStyle = this.getStyle(counts.active_progress);
      this.demoStyle = this.getStyle(counts.demo_progress);
      this.expiredStyle = this.getStyle(counts.expired_progress);
      this.repairStyle = this.getStyle(counts.repair_progress);
    });
  }

  public getStyle(progress) {
    return {'outline': 'solid', 'outline-width': 'thin', 'background':'linear-gradient(to right, orange '+progress+'%, white '+progress+'%'}
  }

  private updateMultiSelected(that: any) {
    that.dtElements?.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        // Checks for multi selection on the serial table only
        if (dtInstance.table().node().id == 'medtrace-table') {
          const selectedData = dtInstance.rows(".selected").data();
          that.multiSelected = selectedData.length > 1;
        }
      });
    });
  }

  public rowClickHandler(info: any, force: boolean = false) {
    setTimeout(() => this.updateMultiSelected(this), 200);
    this.medtraceService.getMedtraceClinicCounts(info["medtrace:id"], this.formGroup.get("DateFrom")?.value, this.formGroup.get("DateTo")?.value).then(medtrace => {
      this.branchMedtrace = medtrace;
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    // this.dtTrigger.unsubscribe();
    if (this.componentRef){
      this.componentRef.destroy();
    }
  }

  private isValidDate(date: any) {
    return !isNaN(Date.parse(date));
  }

  public applyBranchFilter() {
    this.dtRendered = false;
    if (this.radio == "serial"){
      this.medtraceService.setCustomFilter("branch_monitor_traffic_serial");
      this.tableHeaders = ["USAGE", "ENTRY DATE RANGE", "DOCTOR'S NAME [billing number]", "CLINIC NAME", "ZONE", "STATUS [serial]"];
    }
    if (this.radio == "clinic"){
      this.medtraceService.setCustomFilter("branch_monitor_traffic_clinic");
      this.tableHeaders = ["USAGE", "ACTIVE COUNT", "ENTRY DATE RANGE", "DOCTOR'S NAME [billing number]", "CLINIC NAME", "ZONE", "SERIAL"];
    }
    this.branchBuilder.language = {
      infoFiltered: "",
      info: this.radio == 'serial' ? "Showing _START_ of _END_ of _TOTAL_ monitors" : "Showing _START_ of _END_ of _TOTAL_ clinics",
    }
    this.branchBuilder.columns = this.getColumns()
    this.branchBuilder.order = this.radio == 'serial' ? [[ 1, "desc" ]] : [[ 2, "desc" ]],
    // Removing and creating new table
    this.cdr.detectChanges();
    this.dtRendered = true;
    this.cdr.detectChanges();
    let usage = this.getUsageFilterNumbers()
    this.populateMonitorCounts(this.formGroup.get("DateFrom")?.value, this.formGroup.get("DateTo")?.value, usage["lower"], usage["upper"]);
    this.formGroup.patchValue({
      Search: '',
    });

    // set up the search callbacks
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        if (dtInstance.table().node().id == 'medtrace-table') {
          // on search term callback
          $("#search_bar").on("keyup change", function () {
            setTimeout(() => {
              const search_string = this["value"].replace(',', '')
              if (dtInstance.search() !== search_string) {
                dtInstance.search(search_string).draw();
              }
            }, 1000);
          });
        }
      });
    });
  }

  public getCheckboxStatusChanges() {
    let status = [];
    this.isActive ? status.push("ACTIVE") : null;
    this.isShared ? status.push("SHARED") : null;
    this.isExpired ? status.push("EXPIRED") : null;
    this.isNotActive ? status.push("NOT ACTIVE") : null;
    this.isDemo ? status.push("DEMO") : null;
    this.isRepair ? status.push("REPAIR") : null;

    status.length == 0 ? status = ["ACTIVE", "SHARED", "EXPIRED", "NOT ACTIVE", "DEMO", "REPAIR"] : null;
    return status
  }

  public checkboxChange(){
    this.medtraceService.updateStatusFilter(this.getCheckboxStatusChanges());
    this.reloadTable('medtrace');
  }

  public applyFilter() {
    this.medtraceService.clearFilters();
    this.radio == "serial" ? this.medtraceService.setCustomFilter("branch_monitor_traffic_serial") : this.medtraceService.setCustomFilter("branch_monitor_traffic_clinic") ;
    let startDateFilter = null;
    let endDateFilter = null;
    if (this.formGroup.get("DateFrom")?.value) {
      if (this.isValidDate(this.formGroup.get("DateFrom")?.value)) {
        startDateFilter = new Date(this.formGroup.get("DateFrom")?.value)
      }
      this.medtraceService.setDateFromFilter(startDateFilter);
    }
    if (this.formGroup.get("DateTo")?.value) {
      if (this.isValidDate(this.formGroup.get("DateTo")?.value)) {
        endDateFilter = new Date(this.formGroup.get("DateTo")?.value);
      }
      this.medtraceService.setDateToFilter(endDateFilter);
    }
    let usage = this.getUsageFilterNumbers();
    this.populateMonitorCounts(this.formGroup.get("DateFrom")?.value, this.formGroup.get("DateTo")?.value, usage["lower"], usage["upper"]);
    this.medtraceService.updateStatusFilter(this.getCheckboxStatusChanges());
    this.medtraceService.setFocusCountFilter(usage["lower"], usage["upper"]);
    this.reloadTable('medtrace');
  }

  private reloadTable(table) {
    this.dtElements?.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        if (dtInstance.table().node().id == 'medtrace-table' && table == 'medtrace') {
          dtInstance.page(0).draw(false);
        }
      });
    });
  }

  public displayCountInformation(side) {
    const modalRef = this.modalService.open(ModalMedtraceDisplayComponent, { size:'lg'});
    if (side == 'left'){
      modalRef.componentInstance.medtrace = this.branchMedtrace;
      modalRef.componentInstance.type = this.radio == "serial" ? "display_monitor_traffic" : "display_monitor_traffic_clinic";
    }
  }

  public selection(type, item){
    if (type == "billing"){
      this.billingCodeSelection = item;
    }
    if (type == "clinic"){
      this.clinicSelection = item;
    }
    if (type == "doctor"){
      this.doctorSelection = item;
    }
    if (type == "staff"){
      this.staffSelection = item;
    }
  }

  public saveGraphicalSettings() {
    let data = JSON.stringify({
      monitor_traffic: {
        billing_code: this.billingCodeList,
        clinic: {
          id_list: this.clinicIDList,
          obj_list: this.clinicList,
        },
        doctor: {
          id_list: this.doctorIDList,
          obj_list: this.doctorList,
        },
        staff: {
          id_list: this.staffIDList,
          obj_list: this.staffList,
        }
      }
    })
    this.medtraceService.saveGraphicalDataSettings(data);
  }

  public addContent(type: string) {
    if (type == "billing"){
      this.billingCodeList.push(this.billingCodeText);
      this.billingCodeText = "";
      this.loadComponent("billing", this.dynamicBillingCodeYTD, this.dynamicBillingCodeMTD, this.billingCodeList);
    }
    if (type == "clinic"){
      this.clinicList.push(this.clinic);
      this.clinicIDList.push(this.clinic.id);
      this.clinicText = "";
      this.loadComponent("clinic", this.dynamicClinicYTD, this.dynamicClinicMTD, this.clinicIDList);
    }
    if (type == "doctor"){
      this.doctorList.push(this.doctor);
      this.doctorIDList.push(this.doctor.id);
      this.doctorText = "";
      this.loadComponent("doctor", this.dynamicDoctorYTD, this.dynamicDoctorMTD, this.doctorIDList);
    }
    if (type == "staff"){
      this.staffList.push(this.staff);
      this.staffIDList.push(this.staff.id);
      this.staffText = "";
      this.loadComponent("staff", this.dynamicStaffYTD, this.dynamicStaffMTD, this.staffIDList);
    }
    this.saveGraphicalSettings();
  }

  public removeContent(type: string) {
    if (type == "billing"){
      const index: number = this.billingCodeList.indexOf(this.billingCodeSelection);
      if (index !== -1) {
        this.billingCodeList.splice(index, 1);
      }
      this.loadComponent("billing", this.dynamicBillingCodeYTD, this.dynamicBillingCodeMTD, this.billingCodeList);
    }
    if (type == "clinic"){
      const index: number = this.clinicIDList.indexOf(this.clinicSelection.id);
      if (index !== -1) {
        this.clinicList.splice(index, 1);
        this.clinicIDList.splice(index, 1);
      }
      this.loadComponent("clinic", this.dynamicClinicYTD, this.dynamicClinicMTD, this.clinicIDList);
    }
    if (type == "doctor"){
      const index: number = this.doctorIDList.indexOf(this.doctorSelection.id);
      if (index !== -1) {
        this.doctorList.splice(index, 1);
        this.doctorIDList.splice(index, 1);
      }
      this.loadComponent("doctor", this.dynamicDoctorYTD, this.dynamicDoctorMTD, this.doctorIDList);
    }
    if (type == "staff"){
      const index: number = this.staffIDList.indexOf(this.staffSelection.id);
      if (index !== -1) {
        this.staffList.splice(index, 1);
        this.staffIDList.splice(index, 1);
      }
      this.loadComponent("staff", this.dynamicStaffYTD, this.dynamicStaffMTD, this.doctorIDList);
    }
    this.saveGraphicalSettings();
  }

  searchReferringDoctor = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(1000),
    distinctUntilChanged(),
    switchMap(term => (!this.authService.isCardioStudyCompanyClass() || term.length < 2) ? Promise.resolve([])
      : this.physicianService.searchDoctorNoClinic(term, true, false))
  )

  searchReferringDoctorFormatter = (result: any): string => {
    return [
      result.last_name,
      result.first_initials,
      result.billing_number,
    ].filter(x => !!x).join(" | ")
  };

  searchReferringDoctorInputFormatter = (result: any): string => {
    if (!result.name) {
      return result;
    }
    this.doctor = result;
    let doctor_name = result.name + " (" + result.billing_number + ")" + " (" + result.id + ")";
    this.doctor.name = doctor_name;
    return doctor_name;
  };

  searchClinic = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => (!this.authService.isCardioStudyCompanyClass() || term.length < 2) ? Promise.resolve([])
        : this.clinicService.searchClinics(term))
    )

  searchClinicFormatter = (result: any): string => result.name;

  searchClinicInputFormatter = (result: any): string => {
    if (!result.name) {
      return result;
    }
    this.clinic = result;
    let clinic_name = result.name + " (" + result.id + ")";
    this.clinic.name = clinic_name;
    return clinic_name;
  };

  searchEmployee = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(1000),
    distinctUntilChanged(),
    switchMap(term => (!this.authService.isCardioStudyCompanyClass() || term.length < 2) ? Promise.resolve([])
      : this.holterService.searchEmployees(term))
  )

  searchEmployeeFormatter = (result: any): string => {
    if (result.type == "staff"){
      return result.name + " (SCANNER)" + " (" + result.id + ")";
    }
    if (result.type == "employee"){
      return result.name + " (ANALYST/EDITOR)" + " (" + result.id + ")";
    }
  };

  searchEmployeeInputFormatter = (result: any): string => {
    if (!result.name) {
      return result;
    }
    this.staff = result;
    let staff_name = "";
    if (result.type == "staff"){
      staff_name = result.name + " (SCANNER)" + " (" + result.id + ")";
    }
    if (result.type == "employee"){
      staff_name = result.name + " (ANALYST/EDITOR)" + " (" + result.id + ")";
    }
    this.staff.name = staff_name
    return staff_name;
  };

  searchBillingCode = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => (!this.authService.isCardioStudyCompanyClass()) ? Promise.resolve([])
        : this.medtraceService.searchBillingCode(term))
    )

  searchBillingCodeFormatter = (result: any): string => result;

  searchBillingCodeInputFormatter = (result: any): string => {
    return result;
  };

  public changeDates() {
    if (this.formGroup.get("DateFilter")?.value == "DAY") {
      this.formGroup.patchValue({
        DateFrom: moment().subtract(1, 'days').format("YYYY/MM/DD"),
        DateTo: null,
      })
    }
    else if (this.formGroup.get("DateFilter")?.value == "WEEK") {
      this.formGroup.patchValue({
        DateFrom: moment().subtract(7, 'days').format("YYYY/MM/DD"),
        DateTo: null,
      })
    }
    else if (this.formGroup.get("DateFilter")?.value == "MONTH") {
      this.formGroup.patchValue({
        DateFrom: moment().subtract(1, 'months').format("YYYY/MM/DD"),
        DateTo: null,
      })
    }
    else if (this.formGroup.get("DateFilter")?.value == "YEAR") {
      this.formGroup.patchValue({
        DateFrom: moment().subtract(1, 'years').format("YYYY/MM/DD"),
        DateTo: null,
      })
    }
    else if (this.formGroup.get("DateFilter")?.value == "ALL_TIME") {
      this.formGroup.patchValue({
        DateFrom: null,
        DateTo: null,
      })
    }
  }

  public getUsageFilterNumbers() {
    let result = {};
    if (this.formGroup.get("UsageFilter")?.value == "") {
      result = {
        upper: null, lower: null,
      }
    }
    else if (this.formGroup.get("UsageFilter")?.value == "LT5") {
      result = {
        upper: 5, lower: null,
      }
    }
    else if (this.formGroup.get("UsageFilter")?.value == "MT5LT30") {
      result = {
        upper: 30, lower: 5,
      }
    }
    else if (this.formGroup.get("UsageFilter")?.value == "MT30") {
      result = {
        upper: null, lower: 30,
      }
    }
    return result;
  }

  public exportQuery() {
    let status = this.getCheckboxStatusChanges();
    let usage = this.getUsageFilterNumbers();
    let dateTo = null;
    if (!this.formGroup.get("DateTo")?.value) {
      dateTo = moment().format("YYYY/MM/DD")
    }
    else {
      dateTo = this.formGroup.get("DateTo")?.value
    }
    this.medtraceService.exportQuery(this.radio, this.formGroup.get("DateFrom")?.value, dateTo, usage["lower"], usage["upper"], status).subscribe(data => {
      saveAs(data, "medtrace_query.csv");
    });
  }

  public downloadReport() {
    let status = this.getCheckboxStatusChanges();
    let usage = this.getUsageFilterNumbers();
    let dateTo = null;
    if (!this.formGroup.get("DateTo")?.value) {
      dateTo = moment().format("YYYY/MM/DD")
    }
    else {
      dateTo = this.formGroup.get("DateTo")?.value
    }
    this.medtraceService.getMedtraceQueryReport(this.radio, this.formGroup.get("DateFrom")?.value, dateTo, usage["lower"], usage["upper"], status)
      .then((base64Data: string) => {
        download(HolterService.base64ToArrayBuffer(base64Data), "Recall Report", "application/pdf");
      })
  }

}


import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { version } from '../../../package.json';

import { environment } from "../../environments/environment";
import { AuthService } from "../providers/auth.service";
import { DataTablesResponse } from "../models/database-response.model";

import { formatLocaleTime, formatServerTime } from "../utils/format";
import { Observable } from "rxjs";

@Injectable()
export class MedtraceService {

  private readonly JOB_URL: string = environment.api + "/job";
  private readonly CLIENT_NAME: string = "cardio_study";

  private statusFilter: any = null;
  private statusFocusFilter: any = null;
  private serialFilter: number = null;
  private clinicFilter: number = null;
  private doctorFilter: number = null;
  private customFilter: string | null = null;
  private customDisplayFilter: string | null = null;
  private customSerialFilter: string | null = null;
  private dateFromFilter: string | null = null;
  private dateToFilter: string | null = null;
  private recallDateFilter: string | null = null;
  private serialDisplayFilter: number = null;
  private doctorDisplayFilter: number = null;
  private clinicDisplayFilter: number = null;
  private focusCountUpperLimitFilter: number = null;
  private focusCountLowerLimitFilter: number = null;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  public clearFilters() {
    this.customFilter = null;
    this.statusFilter = null;
    this.dateFromFilter = null;
    this.dateToFilter = null;
    this.recallDateFilter = null;
    this.focusCountLowerLimitFilter = null;
    this.focusCountUpperLimitFilter = null;
  }

  public clearDisplayFilters() {
    this.customDisplayFilter = null;
    this.serialDisplayFilter = null;
    this.doctorDisplayFilter = null;
    this.clinicDisplayFilter = null;
    this.statusFilter = null;
  }

  public clearFocusFilters() {
    this.customSerialFilter = null;
    this.statusFocusFilter = null;
    this.serialFilter = null;
    this.clinicFilter = null;
    this.doctorFilter = null;
    this.dateFromFilter = null;
    this.dateToFilter = null;
  }

  public updateStatusFilter(state: string[] | null) {
    this.statusFilter = state;
  }

  public updateStatusFocusFilter(state: string[] | null) {
    this.statusFocusFilter = state;
  }

  public updateSerialFilter(id: number | null) {
    this.serialFilter = id;
  }

  public updateDisplayFilter(filterName: string | null, medtrace: any | null) {
    if (filterName == "serial" || filterName == "home_holter"){
      this.serialDisplayFilter = medtrace.serial_number;
    }
    else if (filterName == "display_monitor_traffic"){
      this.customDisplayFilter = "display_monitor_traffic";
      this.serialDisplayFilter = medtrace.serial_number;
    }
    else if (filterName == "display_monitor_traffic_clinic"){
      this.customDisplayFilter = "display_monitor_traffic_clinic";
      this.clinicDisplayFilter = medtrace.clinic_id;
    }
    else if (filterName == "doctor"){
      this.doctorDisplayFilter = medtrace.doctor_id;
    }
    else if (filterName == "clinic"){
      this.clinicDisplayFilter = medtrace.clinic_id;
    }
    else if (filterName == "Clinic Traffic" || filterName == "Doctor Traffic" ){
      this.customDisplayFilter = "traffic_usage";
      this.doctorDisplayFilter = medtrace.doctor_id;
      this.clinicDisplayFilter = medtrace.clinic_id;
    }
    else if (filterName == "Track Monitor" ){
      this.customDisplayFilter = "monitor_usage";
      this.serialDisplayFilter = medtrace.serial_number;
      this.clinicDisplayFilter = medtrace.clinic_id;
    }

  }

  public setClinicFilter(id: number | null) {
    this.clinicFilter = id;
  }

  public setDoctorFilter(id: number | null) {
    this.doctorFilter = id;
  }

  public setEmptyFocusFilter() {
    this.serialFilter = 1;
  }

  public setDateFromFilter(date: string | null) {
    this.dateFromFilter = date;
  }

  public setDateToFilter(date: string | null) {
    this.dateToFilter = date;
  }

  public setRecallDateFilter(date: string | null) {
    this.recallDateFilter = date;
  }

  public setCustomFilter(filterName: string | null) {
    this.customFilter = this.customFilter == filterName ? null : filterName;
  }

  public setCustomSerialFilter(filterName: string | null) {
    this.customSerialFilter = this.customSerialFilter == filterName ? null : filterName;
  }

  public setFocusCountFilter(lowerLimit: number | null, upperLimit: number | null) {
    this.focusCountLowerLimitFilter = lowerLimit;
    this.focusCountUpperLimitFilter = upperLimit;
  }

  // this is used to override the ajax call in datatables for server-side processing
  public getAjaxFuncion(): any {

    // pass this class to the callback through "that"
    const that: MedtraceService = this;

    // server side rendering
    return (dataTablesParams: any, callback: any): void => {

      // populate the info in multiform part
      dataTablesParams["table"] = "medtrace";

      if (!!this.customFilter) {
        dataTablesParams["custom_medtrace"] = this.customFilter;
        dataTablesParams["date_from"] = this.dateFromFilter;
        dataTablesParams["date_to"] = this.dateToFilter;
        dataTablesParams["recall_date"] = this.recallDateFilter;
        dataTablesParams["count_lower_limit"] = this.focusCountLowerLimitFilter;
        dataTablesParams["count_upper_limit"] = this.focusCountUpperLimitFilter;
      }

      if (this.statusFilter !== null) {
        dataTablesParams.columns.push({
          data: 'medtrace:status',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: 'in',
            value: this.statusFilter,
          },
        });
      }

      const formData: FormData = new FormData();
      formData.append("info", JSON.stringify({
        client: that.CLIENT_NAME,
        job_name: "query_data",
        job_id: null,
        task_status: "create",
        data: dataTablesParams
      }));

      // request the data from url
      that.http.post(that.JOB_URL, formData, this.httpOptions()).toPromise()
        .then((response: any) => {

          // callback to the datatable to rerender with the updated information
          const result: DataTablesResponse = response.Payload.data.result;
          callback({
            recordsTotal: result.recordsTotal,
            recordsFiltered: result.recordsFiltered,
            data: result.data.map(formatLocaleTime),
          });

        })
        .catch((error: any) => {
          console.log("Error datatable handler for medtrace: " + error.message);
        });
    };
  }

  // this is used to override the ajax call in datatables for server-side processing
  public getDisplayAjaxFuncion(): any {

    // pass this class to the callback through "that"
    const that: MedtraceService = this;

    // server side rendering
    return (dataTablesParams: any, callback: any): void => {

      // populate the info in multiform part
      dataTablesParams["table"] = "medtrace";

      if (!!this.customDisplayFilter) {
        dataTablesParams["custom_medtrace"] = this.customDisplayFilter;
        dataTablesParams["medtrace_serial"] = this.serialDisplayFilter;
        dataTablesParams["clinic_id"] = this.clinicDisplayFilter;
        dataTablesParams["doctor_id"] = this.doctorDisplayFilter;
        dataTablesParams["date_from"] = this.dateFromFilter;
        dataTablesParams["date_to"] = this.dateToFilter;
      }

      if (this.statusFilter !== null) {
        dataTablesParams.columns.push({
          data: 'medtrace:status',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: 'in',
            value: this.statusFilter,
          },
        });
      }

      if (this.serialDisplayFilter !== null) {
        dataTablesParams.columns.push({
          data: 'medtrace:serial_number',
          name: '',
          searchable: true,
          orderable: true,
          search: {
              regex: true,
              value: this.serialDisplayFilter,
          },
        });
      }

      if (this.doctorDisplayFilter !== null) {
          dataTablesParams.columns.push({
          data: 'medtrace:doctor_id',
          name: '',
          searchable: true,
          orderable: true,
          search: {
              regex: true,
              value: this.doctorDisplayFilter,
          },
        });
      }

      if (this.clinicDisplayFilter !== null) {
        dataTablesParams.columns.push({
        data: 'medtrace:clinic_id',
        name: '',
        searchable: true,
        orderable: true,
        search: {
            regex: true,
            value: this.clinicDisplayFilter,
        },
      });
    }

      const formData: FormData = new FormData();
      formData.append("info", JSON.stringify({
        client: that.CLIENT_NAME,
        job_name: "query_data",
        job_id: null,
        task_status: "create",
        data: dataTablesParams
      }));

      // request the data from url
      that.http.post(that.JOB_URL, formData, this.httpOptions()).toPromise()
        .then((response: any) => {

          // callback to the datatable to rerender with the updated information
          const result: DataTablesResponse = response.Payload.data.result;
          callback({
            recordsTotal: result.recordsTotal,
            recordsFiltered: result.recordsFiltered,
            data: result.data.map(formatLocaleTime),
          });

        })
        .catch((error: any) => {
          console.log("Error datatable handler for medtrace: " + error.message);
        });
    };
  }

  // this is used to override the ajax call in datatables for server-side processing
  public getAjaxSerialFuncion(): any {

    // pass this class to the callback through "that"
    const that: MedtraceService = this;

    // server side rendering
    return (dataTablesParams: any, callback: any): void => {

      // populate the info in multiform part
      dataTablesParams["table"] = "medtrace";

      if (!!this.customSerialFilter) {
        dataTablesParams["custom_medtrace"] = this.customSerialFilter;
        dataTablesParams["medtrace_serial"] = this.serialFilter;
        dataTablesParams["clinic_id"] = this.clinicFilter;
        dataTablesParams["doctor_id"] = this.doctorFilter;
        dataTablesParams["date_from"] = this.dateFromFilter;
        dataTablesParams["date_to"] = this.dateToFilter;
      }

      if (this.statusFocusFilter !== null) {
        dataTablesParams.columns.push({
          data: 'medtrace:status',
          name: '',
          searchable: true,
          orderable: true,
          search: {
            regex: true,
            method: 'in',
            value: this.statusFocusFilter,
          },
        });
      }

      if (this.serialFilter !== null) {
        dataTablesParams.columns.push({
          data: 'medtrace:serial_number',
          name: '',
          searchable: true,
          orderable: true,
          search: {
              regex: true,
              value: this.serialFilter,
          },
        });
      }

      if (this.doctorFilter !== null) {
        dataTablesParams.columns.push({
          data: 'medtrace:doctor_id',
          name: '',
          searchable: true,
          orderable: true,
          search: {
              regex: true,
              value: this.doctorFilter,
          },
        });
      }

      if (this.clinicFilter !== null) {
        dataTablesParams.columns.push({
          data: 'medtrace:clinic_id',
          name: '',
          searchable: true,
          orderable: true,
          search: {
              regex: true,
              value: this.clinicFilter,
          },
        });
      }

      const formData: FormData = new FormData();
      formData.append("info", JSON.stringify({
        client: that.CLIENT_NAME,
        job_name: "query_data",
        job_id: null,
        task_status: "create",
        data: dataTablesParams
      }));

      // request the data from url
      that.http.post(that.JOB_URL, formData, this.httpOptions()).toPromise()
        .then((response: any) => {

          // callback to the datatable to rerender with the updated information
          const result: DataTablesResponse = response.Payload.data.result;
          callback({
            recordsTotal: result.recordsTotal,
            recordsFiltered: result.recordsFiltered,
            data: result.data.map(formatLocaleTime),
          });

        })
        .catch((error: any) => {
          console.log("Error datatable handler for medtrace: " + error.message);
        });
    };
  }

  public getMedtrace(id: number): Promise<any> {
    const url = environment.api + '/medtrace/' + id;
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getMedtraceClinicCounts(id: number, dateFrom: any, dateTo: any): Promise<any> {
    const queryParams = [];
    if (dateFrom)
      queryParams.push('date_from=' + dateFrom)
    if (dateTo)
      queryParams.push('date_to=' + dateTo)
    let url = environment.api + '/medtrace/clinic-counts/' + id + '?' + queryParams.join("&");
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getLatestMedtraceBySerialNumber(serial: string): Promise<any> {
    const url = environment.api + '/medtrace/serial/' + serial;
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  private httpOptions(): any {
    return { headers: new HttpHeaders({
      "token": this.authService.getToken(),
      "version": version,
    }) };
  }

  // public updateMedtrace(medtraceId: number, data: any): Promise<any> {
  //   const url = environment.api + '/medtrace/' + medtraceId;
  //   return this.http.post(url, data, this.httpOptions()).toPromise()
  //     .then((response: any) => response);
  // }

  // public getMedtraceSerial(serial: any): Promise<any[]> {
  //   const url = environment.api + '/medtrace/serial/' + serial;
  //   return this.http.get(url, this.httpOptions()).toPromise()
  //     .then((response: any) => response);
  // }

  public createHolterMedtrace(data: any): Promise<any> {
    const url = environment.api + '/medtrace/holter/';
    return this.http.post(url, data, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public createMedtrace(data: any): Promise<any> {
    const url = environment.api + '/medtrace/create';
    return this.http.post(url, data, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public createMedtraceAdmin(data: any): Promise<any> {
    const url = environment.api + '/medtrace/admin/create';
    return this.http.post(url, data, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getMedtraceMonitorCounts(filter: string, dateFrom: any, dateTo: any, recallDate: any, lowerLimit: number, upperLimit: number): Promise<any> {
    const queryParams = [];
    if (dateFrom)
      queryParams.push('date_from=' + dateFrom)
    if (dateTo)
      queryParams.push('date_to=' + dateTo)
    if (recallDate)
      queryParams.push('recall_date=' + recallDate)
    if (lowerLimit)
      queryParams.push('lower=' + lowerLimit)
    if (upperLimit)
      queryParams.push('upper=' + upperLimit)
    let url = environment.api + '/medtrace/monitor-counts/' + filter + "?"  + queryParams.join("&");
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public saveGraphicalDataSettings(data: any): Promise<any[]> {
    const url = environment.api + '/medtrace/graphical-settings';
    return this.http.post(url, data, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getGraphicalDataSettings(): Promise<any[]> {
    const url = environment.api + '/medtrace/graphical-settings';
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getMedtraceGraphicalData(graph: any, dataPoints: any[]): Promise<any[]> {
    const dataArgs = JSON.stringify(dataPoints);
    const url = environment.api + '/medtrace/graphical?graph=' + graph + '&data_points=' + dataArgs;
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public searchBillingCode(term: string): Promise<any> {
    const url = environment.api + '/medtrace/billing-code?search=' + encodeURIComponent(term);
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public exportQuery(filter: string, dateFrom: string, dateTo: string, lower: number, upper: number, status: any[]): Observable<any> {
    const queryParams = [];
    const dataArgs = JSON.stringify(status);
    if (filter) {
      queryParams.push('custom=' + filter)
    }
    if (dateFrom) {
      queryParams.push('start=' + dateFrom)
    }
    if (dateTo) {
      queryParams.push('end=' + dateTo)
    }
    if (lower) {
      queryParams.push('lower=' + lower)
    }
    if (upper) {
      queryParams.push('upper=' + upper)
    }
    if (status) {
      queryParams.push('status=' + dataArgs)
    }
    const url = environment.api + '/medtrace/query?' + queryParams.join("&");
    const options = this.httpOptions();
    options.responseType = "blob";
    return this.http.get(url, options);
  }

  public getMedtraceQueryReport(filter: string, dateFrom: string, dateTo: string, lower: number, upper: number, status: any[]) {
    const queryParams = [];
    const dataArgs = JSON.stringify(status);
    if (filter) {
      queryParams.push('custom=' + filter)
    }
    if (dateFrom) {
      queryParams.push('start=' + dateFrom)
    }
    if (dateTo) {
      queryParams.push('end=' + dateTo)
    }
    if (lower) {
      queryParams.push('lower=' + lower)
    }
    if (upper) {
      queryParams.push('upper=' + upper)
    }
    if (status) {
      queryParams.push('status=' + dataArgs)
    }
    const url = environment.api + '/medtrace/recall-report?' + queryParams.join("&");
    const options = this.httpOptions();
    // options.responseType = "blob";
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }
}
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { version } from '../../../package.json';

import { environment } from "../../environments/environment";
import { AuthService } from "../providers/auth.service";
import { DataTablesResponse } from "../models/database-response.model";

@Injectable()
export class AnalyticsService {

  private readonly JOB_URL: string = environment.api + "/job";
  private readonly CLIENT_NAME: string = "cardio_study";

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  public getAnalytics(): Promise<any | null> {

    // populate the info in multiform part
    const formData: FormData = new FormData();
    formData.append('info', JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "analytics",
      job_id: null,
      task_status: "create",
      data: {},
    }));

    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => this.process(response.Payload.data))
      .catch((error: any) => {
        console.log("Failed to get analytics: " + error.message);
        return null;
      });
  }

  // pre-process helper function, error check, cumulative count, date obj conversion
  // must have keys: name, year, month, count
  private preProcess(dataPoints: any[]): any {
    let processed: any = {};
    let count: any = {}

    for (const dataPoint of dataPoints) {

      // check for errors
      if (dataPoint.year < 2014 || dataPoint.name === null || dataPoint.name === undefined) {
        continue;
      }

      // ignore the current month because it's still counting
      const today: any = new Date();
      if (dataPoint.year === today.getFullYear() && dataPoint.month === today.getMonth() + 1) {
        continue;
      }

      // create the entry if it doesn't exist
      if (!(dataPoint.name in processed)) {
        processed[dataPoint.name] = [];
        count[dataPoint.name] = 0;
      }

      count[dataPoint.name] += dataPoint.count;

      processed[dataPoint.name].push({
        time: new Date(dataPoint.year, dataPoint.month),
        value: dataPoint.count,
        name: dataPoint.name,
        cumulative: count[dataPoint.name],
      });
    }

    return {
      data: processed,
      count: count,
    };

  }

  // helper function to sort the count by order of highest counts
  private sortData(unsortedData: any): any {
    let sorted: any[] = []

    // build a sortable array and then sort it
    let sortable: any[] = [];
    for (const name in unsortedData.count) {
      sortable.push([unsortedData.count[name], name]);
    }
    sortable.sort((a, b) => b[0] - a[0]);

    // build index alligned arrays
    for (const item of sortable) {
      const records: any[] = unsortedData.data[item[1]];

      let buffer: any = {
        name: records[0].name,
        time: [],
        value: [],
        cumulative: [],
      };

      for (const record of records) {
        buffer.time.push(record.time);
        buffer.value.push(record.value);
        buffer.cumulative.push(record.cumulative);
      }

      sorted.push(buffer);
    }
    return sorted;
  }

  private process(data: any): any {
    return {
      tech: this.sortData(this.preProcess(data.tech)),
      doctor: this.sortData(this.preProcess(data.doctor)),
      location: this.sortData(this.preProcess(data.location)),
    }
  }

  private httpOptions(): any {
    return { headers: new HttpHeaders({
      "token": this.authService.getToken(),
      "version": version,
    }) };
  }

}
import { AfterViewInit, Component, OnInit, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ElementRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { saveAs } from "file-saver";
import { ContextMenuComponent } from "ngx-contextmenu";

import { formatNotes, formatNotesOptions, formatNotesSingle, formatNotesMultiple } from "../../utils/format";

import { Fax } from "../../models/fax.model";
import { Holter } from "../../models/holter.model";

import { HolterService } from "../../providers/holter.service";
import { FaxService } from "../../providers/fax.service";

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAddressedComponent } from '../modal-addressed/modal-addressed.component';
@Component({
  selector: 'app-logistics-follow-up',
  templateUrl: './logistics-follow-up.component.html',
  styleUrls: ['./logistics-follow-up.component.scss']
})
export class LogisticsFollowUpComponent implements OnInit, AfterViewInit{
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<any>;
  public dtOptions: any = {};
  public dtOptions2: any = {};
  public dtRendered = true;

  @ViewChild(ContextMenuComponent)
  public suggestedMenu: ContextMenuComponent;

  public pdfSrc: any = null;
  public loadingPdf: boolean = false;
  public showPdfPreview: string = null;
  public formGroup: FormGroup;

  public multiSelected: boolean = false;
  public currentHolter: Holter = null;
  public focusHolter: Holter = null;
  public currentFax: Fax;
  public faxNotes: string[] = [];
  public staffNotes: string[] = [];
  public holterStatusFilter: string | null = "Pending";
  public textStaffNotes = ""

  public batchCalls = true;
  public titleSelection: any = "";
  public clinicSelection: any = "";
  public staffNotePlaceHolder: any = "Add staff notes";

  public batch_columns = [
    { data: "holter:scan_date",
      render: ( data, type, row, meta ) => {
        const scan_date = row["holter:scan_date"] || "";
        const beginning_date = row["holter:beginning_date"] || "";
        let date_string = scan_date + "<Br>" + beginning_date;
        return date_string;
      },
    },
    { data: "clinic:name",
      render: ( data, type, row, meta ) => {
        const clinic = row["clinic:name"] || "";
        const clinic_count = parseInt(row["holter:clinic_count"]);
        let clinic_count_string = clinic_count > 1 ? "and " + String(clinic_count-1) + " more" : "";
        let name_string = clinic + "<Br>" + clinic_count_string;
        return name_string;
      },
    },
    {
      data: "patient:last_name",
      render: ( data, type, row, meta ) => {
        const last_name = row["patient:last_name"] ? row["patient:last_name"] : "";
        const first_name = row["patient:first_name"] ? row["patient:first_name"] : "";
        const health_card = row["patient:health_card"] ? row["patient:health_card"] : "";
        const patient_count = parseInt(row["holter:patient_count"]);
        let patient_count_string = patient_count > 1 ? "and " + String(patient_count-1) + " more" : "";
        let name_string = last_name + " " + first_name + " (" + health_card + ")"+ "<Br>" + patient_count_string;
        return name_string;
      },
    },
    { data: "clinic:phone_number" },
    { data: "holter:last_called" },
    { data: "holter:id", visible: false },
    { data: "patient:first_name", visible: false },
    { data: "patient:health_card", visible: false },
    { data: "holter:patient_count", visible: false },
    { data: "holter:beginning_date", visible: false },
    { data: "holter:clinic_count", visible: false },
    { data: "holter:clinic_names", visible: false },
  ]

  public normal_columns = [
    { data: "holter:scan_date"},
    { data: "clinic:name"},
    {
      data: "patient:last_name",
      render: ( data, type, row, meta ) => {
        const last_name = row["patient:last_name"] ? row["patient:last_name"] : "";
        const first_name = row["patient:first_name"] ? row["patient:first_name"] : "";
        const health_card = row["patient:health_card"] ? row["patient:health_card"] : "";
        let name_string = last_name + " " + first_name + " (" + health_card + ")";
        return name_string;
      },
    },
    { data: "clinic:phone_number" },
    { data: "holter:called_at" },
    { data: "holter:id", visible: false },
    { data: "patient:first_name", visible: false },
    { data: "patient:health_card", visible: false },
  ]


  @ViewChild("attemptLogDiv") attemptLogDiv: ElementRef;

  public ngAfterViewInit(): void {

    const that = this;
    document.addEventListener("click",  function(e){
      if (!e.target) {
        return;
      }
      const className = (e.target as Element).className;
      if(className == "as-split-gutter-icon"){
        window.dispatchEvent(new Event('resize'));
        that.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
          dtElement.dtInstance.then((dtInstance: any) => {
            dtInstance.draw();
          });
        });
      }
    });

    // set up the callbacks
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        if (dtInstance.table().node().id == 'left-table') {
          // on search term callback
          $("#search_bar").on("keyup change", function () {
            setTimeout(() => {
              const search_string = this["value"].replace(',', '')
              if (dtInstance.search() !== search_string) {
                dtInstance.search(search_string).draw();
              }
            }, 1000);
          });
        }
      });
    });

    // focus on the search bar right away after load
    $("#search-bar").focus();
  }

  public ngOnInit() {}

  constructor(
    private holterService: HolterService,
    private faxService: FaxService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
  ) {

    // roll callback
    const rowBranchCallback: any = (row: Node, data: any[] | Object, index: number) => {
      $("td", row).bind("click", () => this.rowClickHandler(data));
      return row;
    };

    const rowFocusCallback: any = (row: Node, data: any[] | Object, index: number) => {
      $("td", row).bind("click", () => this.rowFocusClickHandler(data));
      $("td", row).bind("contextmenu", (e) => {
        e.preventDefault();
        this.rowFocusClickHandler(data);
        this.holterService.getHolter(data["holter:id"]).then(holter => {
          this.focusHolter = holter;
          this.openCaseAddressedModal();
        });
        return false;
      });
      return row;
    };

    const drawCallback: any = (settings) => {
      this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
        dtElement.dtInstance.then((dtInstance: any) => {
          if (dtInstance.table().node().id == 'left-table') {
            dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
              const row = dtInstance.row(rowIdx);
            });
          }
        });
      });
    };

    this.statusFilterChanged('');

    // datatable options
    this.dtOptions = {
        autoWidth: false,
        responsive: true,
        lengthChange: false,
        select: true,
        pageLength: 20,
        dom: "Blfrtip",
        buttons: [],
        rowCallback: rowBranchCallback,
        serverSide: true,
        processing: true,
        scrollY: window.innerHeight - 380,
        ajax: this.holterService.getAjaxFuncion(),
        drawCallback: drawCallback,
        order: [[ 0, "asc" ]],
        columnDefs: [{
          targets: 0,
          className: 'expand-row-column'
        },{
          targets: 1,
          className: 'download-file-column'
        }],
        columns: [
          { data: "holter:scan_date",
            render: ( data, type, row, meta ) => {
              const scan_date = row["holter:scan_date"] || "";
              const beginning_date = row["holter:beginning_date"] || "";
              let date_string = scan_date + "<Br>" + beginning_date;
              return date_string;
            },
          },
          { data: "clinic:name",
            render: ( data, type, row, meta ) => {
              const clinic = row["clinic:name"] || "";
              const clinic_count = parseInt(row["holter:clinic_count"]);
              let clinic_count_string = clinic_count > 1 ? "and " + String(clinic_count-1) + " more" : "";
              let name_string = clinic + "<Br>" + clinic_count_string;
              return name_string;
            },
          },
          {
            data: "patient:last_name",
            render: ( data, type, row, meta ) => {
              const last_name = row["patient:last_name"] ? row["patient:last_name"] : "";
              const first_name = row["patient:first_name"] ? row["patient:first_name"] : "";
              const health_card = row["patient:health_card"] ? row["patient:health_card"] : "";
              const patient_count = parseInt(row["holter:patient_count"]);
              let patient_count_string = patient_count > 1 ? "and " + String(patient_count-1) + " more" : "";
              let name_string = last_name + " " + first_name + " (" + health_card + ")"+ "<Br>" + patient_count_string;
              return name_string;
            },
          },
          { data: "clinic:phone_number" },
          { data: "holter:last_called" },
          { data: "holter:id", visible: false },
          { data: "patient:first_name", visible: false },
          { data: "patient:health_card", visible: false },
          { data: "holter:patient_count", visible: false },
          { data: "holter:beginning_date", visible: false },
          { data: "holter:clinic_count", visible: false },
          { data: "holter:clinic_names", visible: false },
        ],
        language: {
          infoFiltered: ""
        }
    };

      // datatable options
      this.dtOptions2 = {
        autoWidth: false,
        responsive: true,
        lengthChange: false,
        select: true,
        pageLength: 20,
        dom: "Blfrtip",
        buttons: [],
        rowCallback: rowFocusCallback,
        serverSide: true,
        processing: true,
        scrollY: window.innerHeight/2 - 300,
        ajax: this.holterService.getFocusAjax(),
        // drawCallback: drawCallback,
        order: [[ 0, "desc" ]],
        columnDefs: [{
          targets: 0,
          className: 'expand-row-column'
        },{
          targets: 1,
          className: 'download-file-column'
        }],
        columns: [
          { data: "holter:hookup_date",},
          {
            data: "patient:last_name",
            render: ( data, type, row, meta ) => {
              const last_name = row["patient:last_name"] ? row["patient:last_name"] : "";
              const first_name = row["patient:first_name"] ? row["patient:first_name"] : "";
              let name_string = last_name + " " + first_name;
              return name_string;
            },
          },
          { data: "patient:health_card" },
          { data: "referring_doctor:name" },
          { data: "holter:id", visible: false },
          { data: "patient:first_name", visible: false },
        ],
        language: {
          infoFiltered: ""
        }
    };

    this.formGroup = new FormGroup({
      StaffNotes: new FormControl("", []),
      TextStaffNotes: new FormControl("", []),
      FaxNotes: new FormControl("", []),
      Fax: new FormControl("", []),
      Addressed: new FormControl("", []),
    });
  }

  private updateMultiSelected(that: any) {
    this.dtElements?.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        if (dtInstance.table().node().id == 'right-table') {
          const selectedData = dtInstance.rows(".selected").data();
          that.multiSelected = selectedData.length > 1;
        }
      });
    });
  }

  public rowClickHandler(info: any, force: boolean = false) {
    this.holterService.getHolter(info["holter:id"]).then(holter => {
      this.currentHolter = holter;
      this.focusHolter = null;
      this.pdfSrc = null;
      this.holterService.clearFocusFilters();
      if (this.batchCalls) {
        this.titleSelection = holter.clinic_phone_number + " - " + info["holter:patient_count"] + " Abnormal Reports"
        this.clinicSelection = "From: " + info["holter:clinic_names"]
        this.holterService.setCustomFocusFilter("focus_abnormal");
        this.holterService.updateClinicPhoneNumberFilter(holter.clinic_phone_number);
        this.reloadTable("right")
      }
      else {
        this.titleSelection = holter.clinic_phone_number;
        this.clinicSelection = holter.clinic_name;
        let id = this.currentHolter.id;
        this.holterService.updateFocusHolterIDFilter(id.toString());
        this.reloadTable("right")
      }
    });
  }

  public rowFocusClickHandler(info: any, force: boolean = false) {
    setTimeout(() => this.updateMultiSelected(this), 200);
    setTimeout(() => {
      if (this.multiSelected){
        this.formGroup.patchValue({
          FaxNotes: "",
          StaffNotes: "",
          TextStaffNotes: "",
          Addressed: false,
          Fax: "",
        });
        this.staffNotePlaceHolder = "Add staff notes to multiple selection"
        this.holterService.getHolter(info["holter:id"]).then(holter => {
          this.focusHolter = holter;
        });
      }
      else{
        this.loadingPdf = true;
        this.staffNotePlaceHolder = "Add staff notes"
        this.holterService.getHolter(info["holter:id"]).then(holter => {
          this.populateFax(holter, true);
        });
      }
    }, 300);
  }

  public faxSelectionChanged() {
    const newFaxId = this.formGroup.get("Fax")?.value;
    return this.faxService.getFax(newFaxId).then(newFax =>
      this.populateFax(this.currentHolter, true, newFax)
    );
  }

  private populateFax(holter: Holter, reloadPreview: boolean, faxOverride: Fax = undefined)
  {
    this.focusHolter = holter;
    this.currentFax = faxOverride !== undefined ? faxOverride : (holter.faxes ? holter.faxes[0] : null);

    this.formGroup.patchValue({
      FaxNotes: this.currentHolter?.fax_notes,
      StaffNotes: formatNotesMultiple([holter.notes, holter.requisition_staff_notes]),
      Fax: this.currentFax?.id || "",
      TextStaffNotes: "",
    });
    this.faxNotes = formatNotesOptions(holter.fax_notes) || [];
    if (holter.requisition_fax_notes) {
      this.faxNotes.push(formatNotesSingle(holter.requisition_fax_notes));
    }
    if (reloadPreview && this.currentFax) {
      this.faxService.getS3Preview(this.currentFax.id).then((url) => {
        this.pdfSrc = url;
      });
    }
  }

  private reloadTable(table) {
    this.dtElements?.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        if (dtInstance.table().node().id == 'left-table' && table == 'left') {
          this.holterStatusFilter == "Addressed" ? dtInstance.order([[ 0, 'desc']]) : dtInstance.order([[ 0, 'asc']]);
          dtInstance.table().draw(false);
        }
        if (dtInstance.table().node().id == 'right-table' && table == 'right') {
          dtInstance.table().draw(false);
        }
      });
    });
  }

  public statusFilterChanged(trigger: string): void {
    this.holterService.clearFilters();
    this.holterService.updateSkipFollowUpFilter(false);
    trigger == '' ? this.batchCalls = true : null;

    if (this.holterStatusFilter == "Pending") {
      this.holterService.setCustomFilter("abnormal_pending");
      this.holterService.updateBatchCallFilter(this.batchCalls);
      // OLD SETTINGS
      // this.holterService.updateStateFilter(["FAXING"]);
      // this.holterService.updateCancelledFaxFilter(false);
      // this.holterService.updateSkipFaxingFilter(false);
      // this.holterService.updateAbnormalFilter(true);
      // this.holterService.updateAddressedFilter(false);
    }

    if (this.holterStatusFilter == "New") {
      this.holterService.setCustomFilter("abnormal_new");
      this.holterService.updateBatchCallFilter(this.batchCalls);
      // OLD SETTINGS
      // this.holterService.updateStateFilter(["FAXING"]);
      // this.holterService.updateCancelledFaxFilter(false);
      // this.holterService.updateSkipFaxingFilter(false);
      // this.holterService.updateAbnormalFilter(true);
      // this.holterService.updateAddressedFilter(false);
    }

    if (this.holterStatusFilter == "Addressed") {
      this.holterService.updateStateFilter(["FAXING"]);
      this.holterService.updateCancelledFaxFilter(false);
      // this.holterService.updateFaxedFilter(false);
      this.holterService.updateSkipFaxingFilter(false);
      this.holterService.updateAddressedFilter(true);
      this.batchCalls = false;
    }
    trigger == "batch" || this.holterStatusFilter == "Addressed" ? this.changeTable() : this.reloadTable("left");
  }

  public changeTable() {
    this.dtRendered = false
    this.batchCalls ? this.dtOptions.columns = this.batch_columns : this.dtOptions.columns = this.normal_columns;
    this.cdr.detectChanges();
    this.dtRendered = true
    this.cdr.detectChanges();

    // set up the search callbacks
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        if (dtInstance.table().node().id == 'left-table') {
          // on search term callback
          $("#search_bar").on("keyup change", function () {
            if (dtInstance.search() !== this["value"]) {
              dtInstance.search(this["value"]).draw();
            }
          });
        }
      });
    });
  }

  public faxType(fileType: string) {
    let types = ""
    if (fileType.includes("final")){
      types = types + "FINAL ";
    }
    if (fileType.includes("tracings")){
      types = types + "TRACINGS ";
    }
    if (fileType.includes("preliminary")){
      types = types + "PRELIMINARY ";
    }
    if (fileType.includes("notes_req")){
      types = types + "REQ FORM ";
    }
    else if(fileType.includes("req")){
      types = types + "REQ ";
    }
    if (fileType.includes("notes_data")){
      types = types + "PATIENT DATA ";
    }
    if (fileType.includes("notes_pacemaker")){
      types = types + "PACEMAKER ";
    }
    return types;
  }

  public openCaseAddressedModal() {
    const modalRef = this.modalService.open(ModalAddressedComponent, {size:'xl'});
    modalRef.componentInstance.holter = this.focusHolter;
    modalRef.result.then((result) => {},
    (reason) => {
      this.holterService.getHolter(this.focusHolter.id).then(holter => {
        this.focusHolter = holter;
        this.formGroup.patchValue({
          StaffNotes: formatNotesMultiple([this.focusHolter.notes, this.focusHolter.requisition_staff_notes]),
          TextStaffNotes: "",
          Addressed: false,
          FaxNotes: this.focusHolter?.fax_notes,
          Fax: this.currentFax?.id || "",
        });
      });
      this.reloadTable('left');
      this.reloadTable('right');
    });
  }

  private logisticsNotesFormat(info: any) {
    let output = "";
    if (info["staff_notes"]) {
      output = "\n[Logistics]\nNote: " + info["staff_notes"];
    }
    else {
      output = "\n[Logistics]\nNote: Called";
    }
    if (this.focusHolter.addressed_at == null && this.formGroup.get("Addressed")?.value == true){
      output = output + " and addressed"
    }
    return output;
  }

  public update() {
    const updatePromises = [];
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        if (dtInstance.table().node().id == 'right-table') {
          dtInstance.rows(".selected").every((idx) => {
            const entry = dtInstance.row(idx).data();
            updatePromises.push(this.holterService.addressedFax(entry["holter:id"], {addressed: this.formGroup.get("Addressed")?.value}));
            let data = {
              phone_number: this.currentHolter.clinic_phone_number,
              staff_notes: null,
            }
            const staffNotesControl = this.formGroup.get('TextStaffNotes');
            if (staffNotesControl.dirty) {
              data.staff_notes = this.formGroup.get("TextStaffNotes")?.value;
            }
            let output = this.logisticsNotesFormat(data);
            updatePromises.push(this.holterService.addNotes(entry["holter:id"], output, null));
          });
        }
      });
    });

    Promise.all(updatePromises).then(() => {
      this.holterService.getHolter(this.focusHolter.id).then(holter => {
        this.focusHolter = holter;
        if (this.multiSelected){
          this.formGroup.patchValue({
            StaffNotes: "",
            FaxNotes: "",
            TextStaffNotes: "",
            Addressed: false,
            Fax: "",
          });
        }
        else {
          this.formGroup.patchValue({
            StaffNotes: formatNotesMultiple([holter.notes, holter.requisition_staff_notes]),
            TextStaffNotes: "",
            Addressed: false,
            FaxNotes: this.focusHolter?.fax_notes,
            Fax: this.currentFax?.id || "",
          });
        }
      });
      this.formGroup.get("TextStaffNotes").markAsPristine();
      this.reloadTable('left');
      this.reloadTable('right');
    });
  }

  public reverseAddressed() {
    let data = {
      addressed_at: null,
      addressed_by: null,
    }
    this.holterService.updateHolter(this.currentHolter.id, data).then(() => {
      this.holterService.addNotes(this.currentHolter.id, "Reversed Addressed", null);
      this.reloadTable("right");
    });
  }

  public queryAbnormalHolter() {
    this.holterService.exportQuery(this.holterStatusFilter, false).subscribe(data => {
      saveAs(data, "query.csv");
    });
  }
}

<div class="modal-body">
    <form>
      <div class="row">
        <div class="col text-center">
          <div class="form-group">
            <div class="input-group">
              <label for="modalReason">Caution: Please confirm that you want the doctor to read the report again.</label>
            </div>
            <button type="submit" class="btn btn-primary btn-review" (click)="passBack()">Resubmit</button>
          </div>
        </div>
      </div>
    </form>
  </div>
<div class="md-width">

  <div class="row sub-header">
    <div class="col-12">
      <div class="text-center">
        <br>
        <ul class="list-inline">
          <!-- <li *ngIf="showHolterTab$ | async as showHolterTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/billing/holter')"
            [class.table-filter-selected]="urlContains('holter')"
            [hidden]="!showHolterTab">
              <div class="col"><p class="text-center">Holter</p></div>
          </li> -->
          <li *ngIf="showHolterLogTab$ | async as showHolterLogTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/billing/holter-log')"
            [class.table-filter-selected]="urlContains('holter-log')"
            [hidden]="!showHolterLogTab">
              <div class="col"><p class="text-center">Holter Log</p></div>
          </li>
          <li *ngIf="showAbpmTab$ | async as showAbpmTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/billing/abpm')"
            [class.table-filter-selected]="urlContains('abpm')"
            [hidden]="!showAbpmTab">
              <div class="col"><p class="text-center">ABPM</p></div>
          </li>
          <li *ngIf="showInvoiceTab$ | async as showInvoiceTab"
            class="list-inline-item table-filter"
            (click)="navigateTo('/billing/invoice')"
            [class.table-filter-selected]="urlContains('invoice')"
            [hidden]="!showInvoiceTab">
              <div class="col"><p class="text-center">Invoice</p></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
import { AfterViewInit, Component, OnInit, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import * as moment from "moment";
import { ContextMenuComponent } from "ngx-contextmenu";

import { SparkDevice } from "../../models/spark-device.model";

import { SparkService } from "../../providers/spark.service";

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalSparkMonitorDisplayComponent } from '../modal-spark-monitor-display/modal-spark-monitor-display.component';

@Component({
  selector: 'app-debug-spark-monitor',
  templateUrl: './debug-spark-monitor.component.html',
  styleUrls: ['./debug-spark-monitor.component.scss']
})
export class DebugSparkMonitorComponent implements OnInit {
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<any>;

  public dtOptions: any = {};
  public dtOptions2: any = {};
  public dtRendered = true;

  @ViewChild(ContextMenuComponent)
  public suggestedMenu: ContextMenuComponent;

  public pdfSrc: any = null;
  public formGroup: FormGroup;
  public branchFormGroup: FormGroup;

  public multiSelected: boolean = false;
  public search: string = null;
  public titleSelection: string = "Doctor | Clinic";

  public branchFilter: string | null = "";
  public focusFilter: string | null = "All";

  public readonly DATE: string[] = [
    moment().format("YYYY/MM/DD"),
  ];

  public focusRowClickHandler(info: any, force: boolean = false) {
    setTimeout(() => this.updateMultiSelected(this), 200);

  }

  public focusRowCallback: any = (row: Node, data: any[] | Object, index: number) => {
    $("td", row).bind("click", () => this.focusRowClickHandler(data));
    return row;
  };

  public focusDrawCallBack: any = (settings) => {
    this.dtElements?.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        if (dtInstance.table().node().id == 'focus-table') {
          dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {

          });
        }
      });
    });
  };

  public ngAfterViewInit(): void {

    const that = this;
    document.addEventListener("click",  function(e){
      if (!e.target) {
        return;
      }
      const className = (e.target as Element).className;
      if(className == "as-split-gutter-icon"){
        window.dispatchEvent(new Event('resize'));
        that.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
          dtElement.dtInstance.then((dtInstance: any) => {
            dtInstance.draw();
          });
        });
      }
    });

    // set up the callbacks
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        if (dtInstance.table().node().id == 'branch-table') {
          // on search term callback
          $("#search_bar").on("keyup change", function () {
            if (dtInstance.search() !== this["value"]) {
              dtInstance.search(this["value"]).draw();
            }
          });
        }
      });
    });

    // focus on the search bar right away after load
    $("#search_bar").focus();

    this.formGroup.patchValue({
      FocusFilter: "All"
    })
    this.branchFormGroup.patchValue({
      BranchFilter: ""
    })
  }

  public ngOnInit() {}

  constructor(
    private sparkService: SparkService,
    private modalService: NgbModal,
  ) {
    // roll callback
    const rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
      $("td", row).bind("click", () => this.rowClickHandler(data));
      return row;
    };

    const focusCallback: any = (row: Node, data: any[] | Object, index: number) => {
      // $("td", row).bind("click", () => this.focusClickHandler(data));
      $("td", row).bind("contextmenu", (e) => {
        e.preventDefault();
        this.displayDatabaseInfo(data);
      });
      return row;
    };

    const drawCallback: any = (settings) => {
      this.dtElements?.forEach((dtElement: DataTableDirective, index: number) => {
        dtElement.dtInstance.then((dtInstance: any) => {
          dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
            const row = dtInstance.row(rowIdx);

          });
        });
      });
    };

    this.sparkService.clearFilters();
    this.sparkService.updateUploadDeviceIDFilter(0);

    // datatable options
    this.dtOptions = {
        autoWidth: false,
        responsive: true,
        lengthChange: false,
        select: true,
        pageLength: 50,
        dom: "Blfrtip",
        buttons: [],
        rowCallback: rowCallback,
        serverSide: true,
        processing: true,
        scrollY: window.innerHeight - 500,
        ajax: this.sparkService.getDeviceAjaxFunction(),
        drawCallback: drawCallback,
        columns: [
          // { data: "spark_device:id",
          //   render: ( data, type, row, meta ) => {
          //     // const upload_count = row["spark_device:upload_count"] ? row["spark_device:upload_count"] : "";
          //     // return upload_count;
          //     return row["spark_device:id"];
          //   },
          // },
          { data: "spark_device:ip_address" },
          { data: "spark_device:mac_address" },
          { data: "spark_device:guid" },
          { data: "spark_device:updated_at" },
          // { data: "spark_device:upload_count", visible: false },
          { data: "spark_device:id", visible: false },
        ],
        language: {
          infoFiltered: ""
        }
    };

    this.dtOptions2 = {
      autoWidth: false,
      responsive: true,
      lengthChange: false,
      select: true,
      pageLength: 50,
      dom: "Blfrtip",
      buttons: [],
      rowCallback: focusCallback,
      serverSide: true,
      processing: true,
      scrollY: window.innerHeight - 500,
      ajax: this.sparkService.getDeviceUploadAjaxFunction(),
      drawCallback: drawCallback,
      columns: [
        { data: "holter:upload_computer" },
        { data: "holter:upload_computer_path" },
        { data: "spark_upload:id", visible: false },
        { data: "spark_device:id", visible: false },
        { data: "holter:id", visible: false },
      ],
      language: {
        infoFiltered: ""
      }
    };
    this.sparkService.setCustomDeviceFilter("all_spark");
    // this.sparkService.setCustomUploadFilter("device_paths");

    // datatable options
    // this.dtOptions2 = {
    //   autoWidth: false,
    //   responsive: true,
    //   lengthChange: false,
    //   select: true,
    //   pageLength: 50,
    //   dom: "Blfrtip",
    //   buttons: [],
    //   rowCallback: rowCallback,
    //   serverSide: true,
    //   processing: true,
    //   scrollY: window.innerHeight - 500,
    //   ajax: this.sparkService.getDeviceUploadAjaxFunction(),
    //   drawCallback: drawCallback,
    //   columns: [
    //     { data: "holter:state" },
    //     { data: "patient:last_name" },
    //     { data: "spark_upload:file" },
    //     { data: "spark_upload:created_at" },
    //     { data: "spark_device:updated_at" },

    //     { data: "spark_upload:id", visible: false },
    //     { data: "spark_device:id", visible: false },
    //     { data: "holter:id", visible: false },
    //   ],
    //   language: {
    //     infoFiltered: ""
    //   }
    // };

    this.formGroup = new FormGroup({
      FocusFilter: new FormControl("", []),
      DateFrom: new FormControl("", []),
      DateTo: new FormControl("", []),
    });

    this.branchFormGroup = new FormGroup({
      Search: new FormControl("", []),
      BranchFilter: new FormControl("", []),
    });
}

  private updateMultiSelected(that: any) {
    that.dtElements?.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        // Checks for multi selection on the serial table only
        if (dtInstance.table().node().id == 'branch-table') {
          const selectedData = dtInstance.rows(".selected").data();
          that.multiSelected = selectedData.length > 1;
        }
      });
    });
  }

  public rowClickHandler(info: any, force: boolean = false) {
    setTimeout(() => this.updateMultiSelected(this), 200);
    this.sparkService.clearFocusFilters();
    this.sparkService.setCustomUploadFilter("device_paths");
    this.sparkService.updateUploadDeviceIDFilter(info["spark_device:id"]);
    this.reloadTable('focus');
  }

  public focusClickHandler(info: any, force: boolean = false) {
    // setTimeout(() => this.updateMultiSelected(this), 200);
    this.sparkService.updateUploadDeviceIDFilter(info["spark_device:id"]);
    this.reloadTable('focus');
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    // this.dtTrigger.unsubscribe();
  }

  public applyBranchFilter() {
    this.sparkService.clearFilters();
    let filter = this.branchFormGroup.get("BranchFilter").value;
    if (filter == ""){
      this.sparkService.setCustomDeviceFilter("all_spark");
    }
    else if (filter == "ACTIVE"){
      this.sparkService.setCustomDeviceFilter("active_spark");
    }
    else if (filter == "NOT_ACTIVE"){
      this.sparkService.setCustomDeviceFilter("not_active_spark");
    }
    this.reloadTable('branch');
  }

  public applyFocusFilter() {
    this.sparkService.clearFocusFilters();
    let filter = this.formGroup.get("FocusFilter").value;
    if (filter == "All"){
      this.sparkService.updateUploadFileFilter(null);
    }
    else if (filter == "Pat"){
      this.sparkService.updateUploadFileFilter("pat.001");
    }
    else if (filter == "PDF"){
      this.sparkService.updateUploadFileFilter("out_01.pdf");
    }
    this.reloadTable('focus');
  }

  private reloadTable(table) {
    this.dtElements?.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        if (dtInstance.table().node().id == 'branch-table' && table == 'branch') {
          dtInstance.page(0).draw(false);
        }
        if (dtInstance.table().node().id == 'focus-table' && table == 'focus') {
          dtInstance.page(0).draw(false);
        }
      });
    });
  }

  private displayDatabaseInfo(info){
    console.log(info)
    const modalRef = this.modalService.open(ModalSparkMonitorDisplayComponent, { size:'lg'});
    modalRef.componentInstance.path = info["holter:upload_computer_path"];
    modalRef.componentInstance.device = info["spark_device:id"];
    // modalRef.componentInstance.type = this.radio == "serial" ? "display_monitor_traffic" : "display_monitor_traffic_clinic";

  }
}
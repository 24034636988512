import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-modal-flag',
  templateUrl: './modal-flag.component.html',
  styleUrls: ['./modal-flag.component.scss']
})
export class ModalFlagComponent implements OnInit {
  @Input() public holter;
  public formGroup: FormGroup;
  public reserveCB: boolean = false;
  public unreserveCB: boolean = false;
  public skipInterpretationCB: boolean = false;
  public changeInterpretationCB: boolean = false;
  public pacemakerInquiryCB: boolean = false;
  public pacemakerResolvedCB: boolean = false;
  constructor(
    public activeModal: NgbActiveModal,
  ) {
    this.formGroup = new FormGroup({
      Reserve: new FormControl("", [ ]),
      Unreserve: new FormControl("", [ ]),
      SkipToReading: new FormControl("", [ ]),
      BackToEditing: new FormControl("", [ ]),
      PacemakerInquiry: new FormControl("", [ ]),
      PacemakerResolved: new FormControl("", [ ]),
      RequisitionResolved: new FormControl("", [ ]),
    });
  }

  ngOnInit(): void {
    this.populateModal(this.holter);
  }

  reserveCheck(event){
    this.reserveCB = event.target.checked;
  }

  unreserveCheck(event){
    this.unreserveCB = event.target.checked;
  }

  skipInterpretationCheck(event){
    this.skipInterpretationCB = event.target.checked;
  }

  changeInterpretationCheck(event){
    this.changeInterpretationCB = event.target.checked;
  }

  pacemakerInquiryCheck(event){
    this.pacemakerInquiryCB = event.target.checked;
  }

  pacemakerResolvedCheck(event){
    this.pacemakerResolvedCB = event.target.checked;
  }


  private populateModal(holter) {
    this.formGroup.reset();
    // this.formGroup.patchValue({
    //   Reserve: holter.multi ? false : holter.reserve,
    //   Unreserve: holter.multi ? false : !holter.reserve,
    //   SkipToReading: holter.multi ? false : !holter.canSkip,
    //   BackToEditing: holter.multi ? false : !holter.canEdit,
    //   PacemakerInquiry: holter.multi ? false : holter.pacemakerInquiry,
    //   PacemakerResolved: holter.multi ? false : !holter.pacemakerInquiry,
    //   RequisitionResolved: holter.multi ? false : holter.req,
    // });
  }

  public passBack() {
    this.activeModal.close({
      reserve: this.formGroup.get("Reserve")?.value,
      unreserve: this.formGroup.get("Unreserve")?.value,
      skipToReading: this.formGroup.get("SkipToReading")?.value,
      backToEditing: this.formGroup.get("BackToEditing")?.value,
      pacemakerInquiry: this.formGroup.get("PacemakerInquiry")?.value,
      pacemakerResolved: this.formGroup.get("PacemakerResolved")?.value,
      reqResolved: this.formGroup.get("RequisitionResolved")?.value,
    });
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule , ReactiveFormsModule} from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DataTablesModule } from 'angular-datatables';
import { AngularSplitModule } from 'angular-split';

import * as PlotlyJS from 'plotly.js/dist/plotly.js';
import { PlotlyModule } from 'angular-plotly.js';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContextMenuModule } from 'ngx-contextmenu';

import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './providers/auth.service';
import { BillingService } from './providers/billing.service';
import { PhysicianService } from './providers/physician.service';
import { EmployeeService } from './providers/employee.service';
import { PatientService } from './providers/patient.service';
import { FaxService } from './providers/fax.service';
import { VisitService } from './providers/visit.service';
import { AbpmService } from './providers/abpm.service';
import { ClinicService } from './providers/clinic.service';
import { HolterService } from './providers/holter.service';
import { MedtraceService } from './providers/medtrace.service';
import { SparkService } from './providers/spark.service';
import { RequisitionService } from './providers/requisition.service';
import { AbpmCalculationsService } from './providers/abpm-calculations.service';
import { AnalyticsService } from './providers/analytics.service';
import { FeatureToggleService } from './providers/feature-toggle.service';
import { AuthGuardService } from './providers/auth-guard.service';

import { WebviewDirective } from './directives/webview.directive';
import { HealthCardCodeInputDirective } from './directives/input.directive';
import { HealthCardInputDirective } from './directives/input.directive';
import { DateInputDirective } from './directives/input.directive';
import { NumberInputDirective } from './directives/input.directive';
import { PhoneInputDirective } from './directives/input.directive';
import { UpperCaseDirective } from './directives/input.directive';

import { PhysicianComponent } from "./components/physician/physician.component";
import { StaffComponent } from "./components/staff/staff.component";
import { BillingComponent } from "./components/billing/billing.component";
import { BillingDetailComponent } from "./components/billing-detail/billing-detail.component";
import { PatientComponent } from "./components/patient/patient.component";
import { FaxComponent } from "./components/fax/fax.component";
import { AbpmComponent } from "./components/abpm/abpm.component";
import { HolterComponent } from "./components/holter/holter.component";
import { HolterEditComponent } from "./components/holter-edit/holter-edit.component";
import { HolterVisitComponent } from "./components/holter-visit/holter-visit.component";
import { HolterPendingComponent } from "./components/holter-pending/holter-pending.component";
import { HolterReservedComponent } from "./components/holter-reserved/holter-reserved.component";
import { HolterReadingComponent } from "./components/holter-reading/holter-reading.component";
import { HolterSearchComponent } from "./components/holter-search/holter-search.component";
import { HolterDetailComponent } from "./components/holter-detail/holter-detail.component";
import { HolterDetailDebugComponent } from "./components/holter-detail-debug/holter-detail-debug.component";
import { HolterDetailMedfluxComponent } from "./components/holter-detail-medflux/holter-detail-medflux.component";
import { HolterDetailReportComponent } from "./components/holter-detail-report/holter-detail-report.component";
import { HelpComponent } from "./components/help/help.component";
import { LoginComponent } from "./components/login/login.component";
import { VisitComponent } from "./components/visit/visit.component";
import { ReportsComponent } from "./components/reports/reports.component";
import { AnalyticsComponent } from "./components/analytics/analytics.component";
import { AbpmStatisticalComponent } from "./components/abpm-statistical/abpm-statistical.component";
import { AbpmDetailComponent} from "./components/abpm-detail/abpm-detail.component";
import { AbpmInterpretationComponent} from "./components/abpm-interpretation/abpm-interpretation.component";
import { AbpmGraphicalComponent} from "./components/abpm-graphical/abpm-graphical.component";
import { AbpmRawComponent} from "./components/abpm-raw/abpm-raw.component";
import { InboxComponent } from "./components/inbox/inbox.component";
import { InboxMainComponent } from "./components/inbox-main/inbox-main.component";
import { BillingHolterComponent } from './components/billing-holter/billing-holter.component';
import { BillingAbpmComponent } from './components/billing-abpm/billing-abpm.component';
import { FaxLegacyComponent } from './components/fax-legacy/fax-legacy.component';
import { FaxRepairComponent } from './components/fax-repair/fax-repair.component';
import { FaxHolterComponent } from './components/fax-holter/fax-holter.component';
import { HolterRequisitionComponent } from './components/holter-requisition/holter-requisition.component';
import { FaxDirectoryComponent } from './components/fax-directory/fax-directory.component';
import { BillingInvoiceComponent } from './components/billing-invoice/billing-invoice.component';
import { ModalRejectionComponent } from './components/modal-rejection/modal-rejection.component';
import { ModalNodataComponent } from './components/modal-nodata/modal-nodata.component';
import { ModalClosedComponent } from './components/modal-closed/modal-closed.component';
import { DebugComponent } from './components/debug/debug.component';
import { DebugHolterComponent } from './components/debug-holter/debug-holter.component';
import { LogisticsComponent } from './components/logistics/logistics.component';
import { LogisticsHomeHolterComponent } from './components/logistics-home-holter/logistics-home-holter.component';
import { LogisticsFollowUpComponent } from './components/logistics-follow-up/logistics-follow-up.component';
import { ModalAddressedComponent } from './components/modal-addressed/modal-addressed.component';
import { DebugAbpmComponent } from './components/debug-abpm/debug-abpm.component';
import { DebugSparkComponent } from './components/debug-spark/debug-spark.component';
import { DebugRequisitionComponent } from './components/debug-requisition/debug-requisition.component';
import { DebugDirectoryComponent } from './components/debug-directory/debug-directory.component';
import { ModalFlagComponent } from './components/modal-flag/modal-flag.component';
import { ModalStaffNotesComponent } from './components/modal-staff-notes/modal-staff-notes.component';
import { ModalReservedFaxingResubmitComponent } from './components/modal-reserved-faxing-resubmit/modal-reserved-faxing-resubmit.component';
import { ModalCreateFaxComponent } from './components/modal-create-fax/modal-create-fax.component';
import { ReqFormatPipe } from './utils/req-formatter.pipe';
import { FaxClinicDoctorDirectoryComponent } from './components/fax-clinic-doctor-directory/fax-clinic-doctor-directory.component';
import { ModalClinicDoctorComponent } from './components/modal-clinic-doctor/modal-clinic-doctor.component';
import { BillingHolterLogComponent } from './components/billing-holter-log/billing-holter-log.component';
import { ResearchSearchDocumentsComponent } from './components/research-search-documents/research-search-documents.component';
import { LogisticsMedtraceComponent } from './components/logistics-medtrace/logistics-medtrace.component';
import { ModalMedtraceComponent } from './components/modal-medtrace/modal-medtrace.component';
import { ModalMedtraceDisplayComponent } from './components/modal-medtrace-display/modal-medtrace-display.component';
import { LogisticsMonitorTrafficComponent } from './components/logistics-monitor-traffic/logistics-monitor-traffic.component';
import { ChartMedtraceHolterMonitorComponent } from './components/chart-medtrace-holter-monitor/chart-medtrace-holter-monitor.component';
import { DebugSparkMonitorComponent } from './components/debug-spark-monitor/debug-spark-monitor.component';
import { ModalSparkMonitorDisplayComponent } from './components/modal-spark-monitor-display/modal-spark-monitor-display.component';

PlotlyModule.plotlyjs = PlotlyJS;

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  url: 'api.cardiostudy.ca/uploads',
  maxFilesize: 50,
  acceptedFiles: '*'
};

@NgModule({
  declarations: [
    AppComponent,
    PhysicianComponent,
    StaffComponent,
    BillingComponent,
    BillingDetailComponent,
    PatientComponent,
    FaxComponent,
    AbpmComponent,
    AbpmDetailComponent,
    AbpmInterpretationComponent,
    AbpmGraphicalComponent,
    AbpmRawComponent,
    AbpmStatisticalComponent,
    HolterComponent,
    HolterEditComponent,
    HolterVisitComponent,
    HolterPendingComponent,
    HolterReservedComponent,
    HolterReadingComponent,
    HolterSearchComponent,
    HolterDetailComponent,
    HolterDetailDebugComponent,
    HolterDetailMedfluxComponent,
    HolterDetailReportComponent,
    HelpComponent,
    LoginComponent,
    VisitComponent,
    ReportsComponent,
    AnalyticsComponent,
    InboxComponent,
    InboxMainComponent,
    BillingHolterComponent,
    BillingAbpmComponent,
    FaxLegacyComponent,
    FaxRepairComponent,
    FaxHolterComponent,
    WebviewDirective,
    HealthCardCodeInputDirective,
    HealthCardInputDirective,
    DateInputDirective,
    NumberInputDirective,
    UpperCaseDirective,
    PhoneInputDirective,
    HolterRequisitionComponent,
    FaxDirectoryComponent,
    BillingInvoiceComponent,
    ModalRejectionComponent,
    ModalNodataComponent,
    ModalClosedComponent,
    DebugComponent,
    DebugHolterComponent,
    LogisticsComponent,
    LogisticsHomeHolterComponent,
    LogisticsFollowUpComponent,
    ModalAddressedComponent,
    DebugAbpmComponent,
    DebugSparkComponent,
    DebugRequisitionComponent,
    DebugDirectoryComponent,
    ModalFlagComponent,
    ModalStaffNotesComponent,
    ModalReservedFaxingResubmitComponent,
    ModalCreateFaxComponent,
    ReqFormatPipe,
    FaxClinicDoctorDirectoryComponent,
    ModalClinicDoctorComponent,
    BillingHolterLogComponent,
    ResearchSearchDocumentsComponent,
    LogisticsMedtraceComponent,
    ModalMedtraceComponent,
    ModalMedtraceDisplayComponent,
    LogisticsMonitorTrafficComponent,
    ChartMedtraceHolterMonitorComponent,
    DebugSparkMonitorComponent,
    ModalSparkMonitorDisplayComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    DataTablesModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    DropzoneModule,
    PlotlyModule,
    PdfViewerModule,
    ReactiveFormsModule,
    NgbModule,
    AngularSplitModule,
    ContextMenuModule.forRoot({
      useBootstrap4: true,
      autoFocus: true,
    }),
    // TypeaheadModule.forRoot(),
  ],
  providers: [
    AuthService,
    AuthGuardService,
    CookieService,
    BillingService,
    PhysicianService,
    EmployeeService,
    PatientService,
    FaxService,
    VisitService,
    AbpmService,
    AbpmCalculationsService,
    AnalyticsService,
    ClinicService,
    HolterService,
    MedtraceService,
    SparkService,
    RequisitionService,
    FeatureToggleService,
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

<div>
  <div class="row sub-main">

    <div class="page-title">
      <div class="title_left">
        <h3>{{title}}</h3>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="x_panel">
      <div class="x_content">

        <br><br>

        <div class="row split-container">

          <as-split direction="horizontal">

            <as-split-area [size]="40">
              <div class="col">

                <h4>Details</h4>

                <table class="table">
                  <thead>
                    <tr><th colspan="4">Blood Pressure and Mean Arterial Pressure (MAP) Averages</th></tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td width="20%">Day BP Average</td>
                      <td width="30%">{{analysis ? analysis.interpretations.averages.dayBloodPressure : "-"}}</td>
                      <td width="20%">Day MAP Average</td>
                      <td width="30%">{{analysis ? analysis.interpretations.averages.dayMap : "-"}}</td></tr>
                    <tr>
                      <td>Night BP Average</td>
                      <td>{{analysis ? analysis.interpretations.averages.eveningBloodPressure : "-"}}</td>
                      <td>Night MAP Average</td>
                      <td>{{analysis ? analysis.interpretations.averages.eveningMap : "-"}}</td></tr>
                    <tr>
                      <td>Total BP Average</td>
                      <td>{{analysis ? analysis.interpretations.averages.totalBloodPressure : "-"}}</td>
                      <td>% Nocturnal change in MAP<br>(normal: >10%)</td>
                      <td>{{analysis ? analysis.interpretations.averages.nocturnalMap : "-"}}</td>
                    </tr>
                  </tbody>
                </table>

                <table class="table">
                  <thead>
                    <tr><th colspan="4">Hypertension Burden - Percent Blood Pressure Above Threshold</th></tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="2">Day (Threshold 135/85 mmHg)</td>
                      <td colspan="2">Night (Threshold 120/70 mmHg)</td>
                    </tr>
                    <tr>
                      <td width="20%">Systolic (>135 mmHg)</td>
                      <td width="30%">{{analysis ? analysis.interpretations.hypertension.day.systolic : "-"}}</td>
                      <td width="20%">Systolic (>120 mmHg)</td>
                      <td width="30%">{{analysis ? analysis.interpretations.hypertension.evening.systolic : "-"}}</td>
                    </tr>
                    <tr>
                      <td>Diastolic (>85 mmHg)</td>
                      <td>{{analysis ? analysis.interpretations.hypertension.day.diastolic : "-"}}</td>
                      <td>Diastolic (>70 mmHg)</td>
                      <td>{{analysis ? analysis.interpretations.hypertension.evening.diastolic : "-"}}</td>
                    </tr>
                  </tbody>
                </table>

                <table class="table">
                  <thead>
                    <tr>
                      <th width="50%">Systolic BP</th>
                      <th width="50%">Diastolic BP</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Maximum: {{analysis ? analysis.interpretations.bloodpressure.systolic.max : "-"}}</td>
                      <td>Maximum: {{analysis ? analysis.interpretations.bloodpressure.diastolic.max : "-"}}</td>
                    </tr>
                    <tr>
                      <td>Minimum: {{analysis ? analysis.interpretations.bloodpressure.systolic.min : "-"}}</td>
                      <td>Minimum: {{analysis ? analysis.interpretations.bloodpressure.diastolic.min : "-"}}</td>
                    </tr>
                  </tbody>
                </table>

                <br><br>

                <h4>Interpretation</h4>

                <!-- These divs require abpm to be loaded first -->
                <div *ngIf="abpm$ | async as abpm">

                  <p class="text-center" [hidden]="abpm.status.toLowerCase() === 'completed'"><b><i>This is a preliminary report</i></b></p>
                  <select class="form-control" [disabled]="disableInterpretations(abpm)" [(ngModel)]="interpretation1">
                    <option></option>
                    <option>Normal BP</option>
                    <option>Daytime Systolic Hypertension</option>
                    <option>Daytime Systolic & Diastolic Hypertension</option>
                    <option>Nocturnal Systolic Hypertension</option>
                    <option>Nocturnal Diastolic Hypertension</option>
                    <option>Nocturnal Systolic & Diastolic Hypertension</option>
                    <option>Persistent Systolic Hypertension</option>
                    <option>Persistent Diastolic Hypertension</option>
                    <option>Persistent Systolic & Diastolic Hypertension</option>
                  </select>
                  <br>
                  <select class="form-control" [disabled]="disableInterpretations(abpm)" [(ngModel)]="interpretation2">
                    <option></option>
                    <option>Normal BP</option>
                    <option>Daytime Systolic Hypertension</option>
                    <option>Daytime Systolic & Diastolic Hypertension</option>
                    <option>Nocturnal Systolic Hypertension</option>
                    <option>Nocturnal Diastolic Hypertension</option>
                    <option>Nocturnal Systolic & Diastolic Hypertension</option>
                    <option>Persistent Systolic Hypertension</option>
                    <option>Persistent Diastolic Hypertension</option>
                    <option>Persistent Systolic & Diastolic Hypertension</option>
                  </select>
                  <br>
                  <select class="form-control" [disabled]="disableInterpretations(abpm)" [(ngModel)]="interpretation3">
                    <option></option>
                    <option>Appropriate Nocturnal Dip</option>
                    <option>Attenuated Nocturnal Dip - Consider OSA</option>
                    <option>Paradoxical rise of Nocturnal BP - Consider OSA</option>
                  </select>
                  <br>
                  <textarea class="form-control" rows="3" id="comment" [disabled]="disableInterpretations(abpm)" [(ngModel)]="interpretationNotes" placeholder="Notes"></textarea>
                  <div class="text-right">
                    <button class="btn btn-primary btn-review" type="button" [disabled]="disableInterpretations(abpm)" (click)="autoFill(abpm)">
                      <i class="fa fa-flash"></i> Autofill
                    </button>
                    <button class="btn btn-primary btn-review" type="button" [disabled]="disableInterpretations(abpm)" (click)="updateInterpretations(abpm, interpretation1, interpretation2, interpretation3, interpretationNotes)">
                      <i class="fa fa-floppy-o"></i> Update
                    </button>
                  </div>

                  <br><br>

                  <div [hidden]="hideSignoff(abpm)">
                    <h4>Reading Doctor Signature</h4>
                    <div class="row text-center">
                      <div class="offset-3 col-6">
                        <input type="text" class="form-control" [(ngModel)]="readingDoctorName" name="reading-doctor-last-name" placeholder="" [attr.disabled]="true">
                      </div>
                      <div class="text-center md-width col-12 error-message" [hidden]="error === ''">{{error}}</div>
                    </div>
                    <div class="row text-center">
                      <div class="col">
                        <button class="btn btn-primary btn-review" type="button" (click)="signoff(abpm)" [disabled]="disableSignature()"><i class="fa fa-pencil"></i> Sign-off</button>
                      </div>
                    </div>
                    <br>
                  </div>

                  <div [hidden]="hideSignature(abpm)">
                    <h4>Signature</h4>
                    <div class="row text-center">
                      <div class="col">
                        <p>This document has been digitally signed off.</p>
                      </div>
                    </div>
                    <div class="row text-center">
                      <div class="col">
                        <p><b>{{signature(abpm)}}</b></p>
                      </div>
                    </div>
                    <br>
                  </div>


                </div>

              </div>
            </as-split-area>

            <as-split-area [size]="60">
              <div class="col info-panel" *ngIf="abpm$ | async as abpm">
                <div class="row">
                  <div class="col">
                    <button class="btn btn-primary btn-review" type="button" (click)="previewReport(abpm, 'general')" [disabled]="currentPreviewType === 'general'">Full Report</button>
                    <button class="btn btn-primary btn-review" type="button" (click)="previewReport(abpm, 'lds')" [disabled]="currentPreviewType === 'lds'">Summary</button>
                  </div>
                </div>
                <br>
                <div class="preview-panel">
                  <pdf-viewer id="pdf-viewer" [autoresize]="true" [src]="pdfSrc" [render-text]="false" [original-size]="false" [fit-to-page]="true" style="display: block;"></pdf-viewer>
                </div>
              </div>
            </as-split-area>

          </as-split>

        </div>

      </div>
    </div>
  </div>

  <div>
    <div class="row sub-footer">
      <div class="col-12" *ngIf="abpm$ | async as abpm">
        <!-- <button class="btn btn-primary btn-review" type="button" (click)="createFax()" [hidden]="hideFax()"><i class="fa fa-fax"></i> Fax</button> -->
        <button class="btn btn-primary btn-review" type="button" [hidden]="!isAdmin" (click)="hide(abpm)">Hide</button>
        <button class="btn btn-primary btn-review" type="button" (click)="downloadReport(abpm)"><i class="fa fa-file-pdf-o"></i> Download</button>
        <button class="btn btn-primary btn-review" type="button" (click)="navigateTo('/abpm')"><i class="fa fa-times"></i> Cancel</button>
      </div>
    </div>
  </div>
</div>
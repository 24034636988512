<div class="md-width">

  <div class="row sub-header">
    <div class="col-12">
      <div class="text-center">
        <br>
        <ul class="list-inline">
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="row sub-main no-footer">

  <div class="page-title">
    <div class="title_left">
      <h3>ABPM - Search</h3>
    </div>
  </div>
  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="x_content">

      <div class="col-12 md-width">
        <h4>Patient Information</h4>
        <form class="form-horizontal form-label-left" [formGroup]="createFormGroup">
          <div class="form-group form-display row">
            <div class="col form-group required">
              <label for="inputSuccess2">Health Card (or Blue Cross, IFH, etc.)</label>
              <input type="text" class="form-control" placeholder="1234123123" formControlName="HealthCard" [attr.disabled]="isComplete() ? '' : null">
            </div>
            <div class="col form-group required">
              <label for="inputSuccess2">Referring Doctor (full name)</label>
              <input type="text" class="form-control" placeholder="" formControlName="ReferringDoctorName" [attr.disabled]="isComplete() ? '' : null">
            </div>
            <div class="col form-group required">
              <label for="inputSuccess2">Reading Doctor (full name)</label>
              <input type="text" class="form-control" placeholder="" formControlName="ReadingDoctorName" list="readinglist" [attr.disabled]="isComplete() ? '' : null">
                <datalist id="readinglist">
                  <option *ngFor="let dr of readingDoctorOptions" [value]="dr.value">{{dr.text}}</option>
                </datalist>
            </div>
            <div class="col form-group required">
              <label for="inputSuccess2">Read By</label>
              <select class="form-control" formControlName="ReadBy" data-live-search="true" [attr.disabled]="isComplete() ? '' : null">
                <option>Cardio Study</option>
                <option>In-House</option>
              </select>
            </div>
          </div>
          <div class="form-group form-display row">
            <div class="col form-group required">
              <label for="inputSuccess2">First Name</label>
              <input type="text" class="form-control" placeholder="" formControlName="FirstName" [attr.disabled]="isComplete() ? '' : null">
            </div>
            <div class="col form-group required">
              <label for="inputSuccess2">Last Name</label>
              <input type="text" class="form-control" placeholder="" formControlName="LastName" [attr.disabled]="isComplete() ? '' : null">
            </div>
            <div class="col form-group required">
              <label for="inputSuccess2">Birthdate</label>
              <input type="text" class="form-control" [class.has-error]="hasError('Birthdate')" placeholder="yyyy/mm/dd" formControlName="Birthdate" dateInput [attr.disabled]="isComplete() ? '' : null">
              <div *ngIf="hasError('Birthdate')" class="error-message">Birthdate needs to be in YYYY/MM/DD format.</div>
            </div>
            <div class="col form-group required">
              <label for="inputSuccess2">Gender</label>
              <select class="form-control" formControlName="Gender" data-live-search="true " [attr.disabled]="isComplete() ? '' : null">
                <option value="M">Male</option>
                <option value="F">Female</option>
              </select>
            </div>
          </div>
          <div class="form-group form-display row">
            <div class="col-6 form-group required">
              <label for="inputSuccess2">Clinic Address</label>
              <input id="typeahead-clinic" type="text" class="form-control" formControlName="Location" [ngbTypeahead]="searchClinic" placeholder="" [attr.disabled]="isComplete() ? '' : null"/>
            </div>
            <div class="col form-group required">
              <label for="inputSuccess2" (click)="toggleHeightUnit()" class="toggle-unit no-select">Height ({{heightUnit}})</label>
              <input type="text" class="form-control" placeholder="0" formControlName="Height" numberInput [attr.disabled]="isComplete() ? '' : null">
            </div>
            <div class="col form-group required">
              <label for="inputSuccess2" (click)="toggleWeightUnit()" class="toggle-unit no-select">Weight ({{weightUnit}})</label>
              <input type="text" class="form-control" placeholder="0" formControlName="Weight" numberInput [attr.disabled]="isComplete() ? '' : null">
            </div>
          </div>
          <div class="form-group form-display row">
            <div class="col form-group">
              <label for="inputSuccess2">Indication for Test</label>
              <input type="text" class="form-control" placeholder="" formControlName="Indication" [attr.disabled]="isComplete() ? '' : null">
            </div>
          </div>
          <div class="form-group form-display row">
            <div class="col form-group">
              <label for="inputSuccess2">Medications</label>
              <textarea class="form-control" rows="3" formControlName="Medications" [attr.disabled]="isComplete() ? '' : null"></textarea>
            </div>
          </div>
          <div class="text-right">
            <button class="btn btn-primary btn-review" type="button" [disabled]="!createFormGroup.valid || isComplete()" (click)="onSubmit()"><i class="fa fa-check"></i> Assign</button>
          </div>
        </form>
      </div>

      <div class="row" [hidden]="false">
        <div class="col-12">
            <div class="row">
                <div class="col-3">
                    <label>Status</label>
                    <select class="form-control" data-live-search="true" [(ngModel)]="abpmStatusFilter" (change)="statusFilterChanged()">
                        <option value="All">All</option>
                        <option value="Review">Review</option>
                        <option value="Completed">Completed</option>
                    </select>
                </div>
                <div class="col-6">
                    <input type="text" class="form-control search-bar" placeholder="Start typing to search" id="search-bar">
                </div>
                <div class="col-1">
                    <button class="btn btn-primary btn-review" type="button" (click)="queryAbpm()">Query</button>
                </div>
                <div class="col-1">
                  <button class="btn btn-primary btn-review" type="button" (click)="queryBillingAbpm()">Billing Query</button>
              </div>
            </div>

            <div>
                <table datatable [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover" id="not-pending-table">
                <thead>
                    <tr>
                      <th width="10px"></th>
                      <th>Status</th>
                      <th>Hookup Date</th>
                      <th>Last Name</th>
                      <th>First Name</th>
                      <th>Birthdate</th>
                      <th>Owner</th>
                      <th>Read By</th>
                      <th>Id</th>
                    </tr>
                </thead>
                </table>
            </div>

        </div>
      </div>

    </div>
  </div>
</div>
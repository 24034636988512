<div class="modal-body">
    <form>
      <div class="row">
        <div class="col text-center">
          <label class="form-check-label" [hidden]="!holter.multi">Note: Multiple holters selected</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <form class="form-horizontal form-label-left ml-3 mr-n3" [formGroup]="formGroup">
            <div class="row">
                <div class="col form-group">
                    <input class="form-check-input" type="checkbox" name="check0" id="check0" (change)="reserveCheck($event)" formControlName="Reserve" [attr.disabled]="holter.fax_repair || (holter.reserve && !holter.multi) || unreserveCB ? '' : null">
                    <label class="form-check-label" for="check0">Reserve</label>
                </div>
                <div class="col form-group">
                  <input class="form-check-input" type="checkbox" name="check1" id="check1" (change)="unreserveCheck($event)" formControlName="Unreserve" [attr.disabled]="holter.fax_repair || (!holter.reserve && !holter.multi) || reserveCB ? '' : null">
                  <label class="form-check-label" for="check1">Unreserve</label>
              </div>
            </div>
            <div class="row">
                <div class="col form-group">
                    <input class="form-check-input" type="checkbox" name="check2" id="check2" (change)="skipInterpretationCheck($event)" formControlName="SkipToReading" [attr.disabled]="(!holter.canSkip && !holter.multi) || changeInterpretationCB ? '' : null">
                    <label class="form-check-label" for="check2">Skip Interpretation</label>
                </div>
                <div class="col form-group">
                  <input class="form-check-input" type="checkbox" name="check3" id="check3" (change)="changeInterpretationCheck($event)" formControlName="BackToEditing" [attr.disabled]="(!holter.canEdit && !holter.multi) || skipInterpretationCB ? '' : null">
                  <label class="form-check-label" for="check3">Change Interpretation</label>
                </div>
            </div>
            <div class="row">
                <div class="col form-group">
                    <input class="form-check-input" type="checkbox" name="check4" id="check4" (change)="pacemakerInquiryCheck($event)" formControlName="PacemakerInquiry" [attr.disabled]="(holter.pacemakerInquiry && !holter.multi) || pacemakerResolvedCB ? '' : null">
                    <label class="form-check-label" for="check4">Pacemaker Inquiry</label>
                </div>
                <div class="col form-group">
                  <input class="form-check-input" type="checkbox" name="check5" id="check5" (change)="pacemakerResolvedCheck($event)" formControlName="PacemakerResolved" [attr.disabled]="(!holter.pacemakerInquiry && !holter.multi) || pacemakerInquiryCB ? '' : null">
                  <label class="form-check-label" for="check5">Pacemaker Resolved</label>
                </div>
            </div>
            <div class="row">
                <div class="col form-group">
                    <input class="form-check-input" type="checkbox" name="check6" id="check6" formControlName="RequisitionResolved" [attr.disabled]="holter.req ? '' : null">
                    <label class="form-check-label" for="check6">Requisition Resolved</label>
                </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row text-center">
        <div class="col">
            <button type="submit" class="btn btn-primary btn-review" (click)="passBack()" [disabled]="!formGroup.valid">Update</button>
        </div>

      </div>
    </form>
  </div>
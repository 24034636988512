import { AbpmAnalysis } from "./abpm-analysis.model";

export class Abpm {
  public id: number | null = null;
  public job_id: number | null = null;
  public measurements: any | null = null;
  public uploaded_at: number | null = null;
  public serial_number: string | null = null;
  public status: string | null = null;
  public visit_id: number | null = null;
  public analysis: AbpmAnalysis | null = null;
  public comments: string = "";
  public interpretation_1: string = "";
  public interpretation_2: string = "";
  public interpretation_3: string = "";
  public interpretation_notes: string = "";
  public bug_timezone: boolean | null = false;
  public updated_at: number | null = null;

  public signoff_doctor_id: number | null = null;
  public signoff_doctor_last_name: string | null = null;
  public signoff_doctor_first_initials: string | null = null;
  public signoff_doctor_name: string | null = null;
}
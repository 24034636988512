<div class="md-width">

  <div class="row sub-header">
    <div class="col-12">
      <div class="text-center">
        <br>
        <ul class="list-inline">
          <!-- <li class="list-inline-item table-filter" id="pending"
              (click)="switchFilter(STATUS_PENDING)"
              [class.table-filter-selected]="status==STATUS_PENDING">
                  <div class="col-xs-3"><i class="fa fa-clock-o"></i></div>
                  <div class="col-xs-9 text-center"><p>Pending</p></div>
          </li> -->
          <!-- <li class="list-inline-item table-filter table-filter-center" id="review"
              (click)="switchFilter(STATUS_REVIEW)"
              [class.table-filter-selected]="status==STATUS_REVIEW">
                  <div class="col-xs-3"><i class="fa fa-list"></i></div>
                  <div class="col-xs-9 text-center"><p>In Review</p></div>
          </li>
          <li class="list-inline-item table-filter" id="completed"
              (click)="switchFilter(STATUS_COMPLETED)"
              [class.table-filter-selected]="status==STATUS_COMPLETED">
                  <div class="col-xs-3"><i class="fa fa-check"></i></div>
                  <div class="col-xs-9 text-center"><p>Completed</p></div>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="row sub-main no-footer">

  <div class="page-title">
    <div class="title_left">
      <h3>ABPM - Search</h3>
    </div>
  </div>
  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="x_content">

      <div class="row" [hidden]="false">
        <div class="col-12">
          <div class="row">
            <div class="col-md-auto ml-4">
              <div class="custom-control custom-switch">
                <input class="custom-control-input" type="checkbox" id="switch1" [hidden]="isAdmin == false" [(ngModel)]="hiddenAbpms" (click)="showHiddenAbpmEntries()">
                <label class="custom-control-label"[hidden]="isAdmin == false" for="switch1">Hidden Abpms</label>
              </div>
            </div>
            <div class="col-2">
              <label>Type</label>
              <select class="form-control" data-live-search="true" [(ngModel)]="abpmFilter" (change)="resetFilter()">
                <option value="">All</option>
                <option value="SERIAL">Hardcoded Serial</option>
              </select>
            </div>
            <div class="col-2">
              <label>Status</label>
              <select class="form-control" data-live-search="true" [(ngModel)]="abpmStatusFilter" (change)="resetFilter()">
                  <option value="All">All</option>
                  <option value="Review">Review</option>
                  <option value="Completed">Completed</option>
              </select>
            </div>
            <div class="col-4">
              <input type="text" class="form-control search-bar" placeholder="Start typing to search" id="search-bar">
            </div>
            <div class="col-4"></div>

          </div>


          <!-- <div [hidden]="status!=STATUS_PENDING">
            <table datatable [dtOptions]="dtOptionsPending" class="table table-striped row-bordered datatable-buttons hover" id="pending-table">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Uploaded On</th>
                  <th>Serial Number</th>
                  <th>Owner</th>
                  <th>Id</th>
                </tr>
              </thead>
            </table>
          </div> -->

          <div>
            <table datatable [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover" id="not-pending-table">
              <thead>
                <tr>
                  <th width="10px"></th>
                  <th>Status</th>
                  <th>Hookup Date</th>
                  <th>Last Name</th>
                  <th>First Name</th>
                  <th>Birthdate</th>
                  <th>Owner</th>
                  <th>Read By</th>
                  <th>Reading Doctor</th>
                </tr>
              </thead>
            </table>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>
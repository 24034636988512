import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { version } from '../../../package.json';

import { environment } from "../../environments/environment";
import { JOB_STATUS, JOBS } from "../config/constants";
import { AuthService } from "../providers/auth.service";
import { Visit } from "../models/visit.model";
import { DataTablesResponse } from "../models/database-response.model";

import { formatLocaleTime, formatServerTime } from "../utils/format";

@Injectable()
export class VisitService {

  private readonly JOB_URL: string = environment.api + "/job";
  private readonly CLIENT_NAME: string = "cardio_study";

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  // this is used to override the ajax call in datatables for server-side processing
  public getBillingAjaxFuncion(): any {

    // pass this class to the callback through "that"
    const that: VisitService = this;

    // server side rendering
    return (dataTablesParams: any, callback: any): void => {

      // populate the info in multiform part
      dataTablesParams["table"] = "visit";
      const formData: FormData = new FormData();
      formData.append('info', JSON.stringify({
        client: that.CLIENT_NAME,
        job_name: "query_data",
        job_id: null,
        task_status: JOB_STATUS.CREATE,
        data: dataTablesParams
      }));

      // request the data from url
      that.http.post(that.JOB_URL, formData, this.httpOptions()).toPromise()
        .then((response: any) => {

          // callback to the datatable to rerender with the updated information
          const result: DataTablesResponse = response.Payload.data.result;

          // need to filter out the visits without any billing
          const filteredRecords: any[] = result.data.map(formatLocaleTime)
          .filter(entry => "billing:status" in entry && !!entry["billing:status"]);

          callback({
            recordsTotal: filteredRecords.length,
            recordsFiltered: filteredRecords.length,
            data: filteredRecords,
          });

        })
        .catch((error: any) => {
          console.log("Error datatable handler for visit: " + error.message);
        });
    };
  }

  // this is used to override the ajax call in datatables for server-side processing
  public ajaxCall(dataTablesParams: any): Promise<any> {

    // populate the info in multiform part
    dataTablesParams["table"] = "visit";
    const formData: FormData = new FormData();
    formData.append('info', JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "query_data",
      job_id: null,
      task_status: "create",
      data: dataTablesParams
    }));

    // request the data from url
    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise();
  }

  public getVisit(id: number): Promise<Visit | null> {

    if (id === null || id === undefined) {
      return Promise.resolve(null);
    }

    // populate the info in multiform part
    const query: any = {
      draw: 1,
      columns: [
        { data: "visit:id", search: { value: id.toString(), regex: false} },
        { data: "visit:visited_at" },
        { data: "visit:hookup_at" },
        { data: "visit:scanned_at" },
        { data: "visit:comments" },
        { data: "visit:medications" },
        { data: "visit:indication" },
        { data: "visit:read_by" },
        { data: "visit:hookup_duration" },
        { data: "visit:delivery_id_type" },
        { data: "visit:monitor_serial_number" },
        { data: "visit:tech" },
        { data: "visit:dx_code" },
        { data: "visit:updated_at" },
        { data: "patient:id" },
        { data: "patient:health_card" },
        { data: "patient:health_card_code" },
        { data: "patient:first_name" },
        { data: "patient:last_name" },
        { data: "patient:gender" },
        { data: "patient:birthdate" },
        { data: "patient:height" },
        { data: "patient:weight" },
        { data: "clinic:id" },
        { data: "clinic:name" },
        { data: "clinic:street" },
        { data: "clinic:city" },
        { data: "clinic:province" },
        { data: "clinic:postal_code" },
        { data: "clinic:fax" },
        { data: "referring_doctor:id" },
        { data: "referring_doctor:last_name" },
        { data: "referring_doctor:first_initials" },
        { data: "referring_doctor:name" },
        { data: "reading_doctor:id" },
        { data: "reading_doctor:last_name" },
        { data: "reading_doctor:first_initials" },
        { data: "reading_doctor:name" },
        { data: "owner:id" },
        { data: "owner:name" },
        { data: "owner:address" },
        { data: "owner:telephone" },
        { data: "owner:abpm_price" },
        { data: "owner:fax" },
        { data: "billing:id" },
        { data: "billing:items" },
        { data: "billing:status" },
        { data: "billing:balance" },
        { data: "billing:balance_pre_tax" },
        { data: "billing:paid_on" },
        { data: "billing:invoice_link" },
        { data: "billing:credit_card" },
        { data: "billing:invoice_date" },
        { data: "billing:invoice_due_date" },
        { data: "billing:notes" },
      ],
      order: [],
      table: "visit",
      start: 0,
      length: 1,
      search: {},
    };

    const formData: FormData = new FormData();
    formData.append('info', JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "query_data",
      job_id: null,
      task_status: "create",
      data: query,
    }));

    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => {
        const result: DataTablesResponse = response.Payload.data.result;
        if (result.data.length <= 0) {
          return null;
        }

        result.data = result.data.map(formatLocaleTime);
        const raw: any = result.data[0];
        let visit: Visit = new Visit();
        visit.id = raw["visit:id"];
        visit.visited_at = raw["visit:visited_at"];
        visit.hookup_at = raw["visit:hookup_at"];
        visit.scanned_at = raw["visit:scanned_at"];
        visit.comments = raw["visit:comments"];
        visit.medications = raw["visit:medications"];
        visit.indication = raw["visit:indication"];
        visit.read_by = raw["visit:read_by"];
        visit.hookup_duration = raw["visit:hookup_duration"];
        visit.delivery_id_type = raw["visit:delivery_id_type"];
        visit.monitor_serial_number = raw["visit:monitor_serial_number"];
        visit.tech = raw["visit:tech"];
        visit.dx_code = raw["visit:dx_code"];
        visit.updated_at = raw["visit:updated_at"];
        visit.patient_id = raw["patient:id"];
        visit.patient_health_card = raw["patient:health_card"];
        visit.patient_health_card_code = raw["patient:health_card_code"];
        visit.patient_first_name = raw["patient:first_name"];
        visit.patient_last_name = raw["patient:last_name"];
        visit.patient_gender = raw["patient:gender"];
        visit.patient_birthdate = raw["patient:birthdate"];
        visit.patient_height = raw["patient:height"] ? parseInt(raw["patient:height"], 10) : null;
        visit.patient_weight = raw["patient:weight"] ? parseInt(raw["patient:weight"], 10) : null;
        visit.clinic_id = raw["clinic:id"];
        visit.clinic_name = raw["clinic:name"];
        visit.clinic_street = raw["clinic:street"];
        visit.clinic_city = raw["clinic:city"];
        visit.clinic_province = raw["clinic:province"];
        visit.clinic_postal_code = raw["clinic:postal_code"];
        visit.clinic_fax = raw["clinic:fax"];
        visit.referring_doctor_id = raw["referring_doctor:id"];
        visit.referring_doctor_last_name = raw["referring_doctor:last_name"];
        visit.referring_doctor_first_initials = raw["referring_doctor:first_initials"];
        visit.referring_doctor_name = raw["referring_doctor:name"];
        visit.reading_doctor_id = raw["reading_doctor:id"];
        visit.reading_doctor_last_name = raw["reading_doctor:last_name"];
        visit.reading_doctor_first_initials = raw["reading_doctor:first_initials"];
        visit.reading_doctor_name = raw["reading_doctor:name"];
        visit.owner_id = raw["owner:id"];
        visit.owner_name = raw["owner:name"];
        visit.owner_address = raw["owner:address"];
        visit.owner_telephone = raw["owner:telephone"];
        visit.owner_abpm_price = raw["owner:abpm_price"];
        visit.owner_fax = raw["owner:fax"];
        visit.billing_id = raw["billing:id"];
        visit.billing_items = raw["billing:items"];
        visit.billing_status = raw["billing:status"];
        visit.billing_balance = raw["billing:balance"];
        visit.billing_balance_pre_tax = raw["billing:balance_pre_tax"];
        visit.billing_paid_on = raw["billing:paid_on"];
        visit.billing_invoice_link = raw["billing:invoice_link"];
        visit.billing_credit_card = raw["billing:credit_card"];
        visit.billing_invoice_date = raw["billing:invoice_date"];
        visit.billing_invoice_due_date = raw["billing:invoice_due_date"];
        visit.billing_notes = raw["billing:notes"];
        return visit;
      })
      .catch((error: any) => {
        console.log("Failed to get visit: " + error.message);
        return null;
      });
  }

  public getMinimumVisit(id: number): Promise<Visit | null> {

    if (id === null || id === undefined) {
      return Promise.resolve(null);
    }

    // populate the info in multiform part
    const query: any = {
      draw: 1,
      columns: [
        { data: "visit:id", search: { value: id.toString(), regex: false} },
        { data: "visit:visited_at" },
        { data: "visit:hookup_at" },
        { data: "visit:scanned_at" },
        { data: "visit:updated_at" },
        { data: "patient:id" },
        { data: "patient:health_card" },
        { data: "patient:first_name" },
        { data: "patient:last_name" },
      ],
      order: [],
      table: "visit",
      start: 0,
      length: 1,
      search: {},
    };

    const formData: FormData = new FormData();
    formData.append('info', JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "query_data",
      job_id: null,
      task_status: "create",
      data: query,
    }));

    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => {
        const result: DataTablesResponse = response.Payload.data.result;
        if (result.data.length <= 0) {
          return null;
        }

        result.data = result.data.map(formatLocaleTime);
        const raw: any = result.data[0];
        let visit: Visit = new Visit();
        visit.id = raw["visit:id"];
        visit.visited_at = raw["visit:visited_at"];
        visit.hookup_at = raw["visit:hookup_at"];
        visit.scanned_at = raw["visit:scanned_at"];
        visit.updated_at = raw["visit:updated_at"];
        visit.patient_id = raw["patient:id"];
        visit.patient_health_card = raw["patient:health_card"];
        visit.patient_first_name = raw["patient:first_name"];
        visit.patient_last_name = raw["patient:last_name"];
        return visit;
      })
      .catch((error: any) => {
        console.log("Failed to get visit: " + error.message);
        return null;
      });
  }


  public createVisit(visit: Visit, update: boolean = false): Promise<Visit | any> {

    // populate the info in multiform part
    const query: any = {
      entries: [{
        id: visit.id,
        visited_at: formatServerTime(visit.visited_at),
        hookup_at: formatServerTime(visit.hookup_at),
        scanned_at: formatServerTime(visit.scanned_at),
        comments: visit.comments,
        medications: visit.medications,
        indication: visit.indication,
        read_by: visit.read_by,
        hookup_duration: visit.hookup_duration,
        delivery_id_type: visit.delivery_id_type,
        monitor_serial_number: visit.monitor_serial_number,
        billing_id: visit.billing_id,
        tech: visit.tech,
        patient_id: visit.patient_id,
        clinic_id: visit.clinic_id,
        referring_doctor_id: visit.referring_doctor_id,
        reading_doctor_id: visit.reading_doctor_id,
      }],
      table: "visit",
    };

    const formData: FormData = new FormData();
    formData.append('info', JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: update ? "update_data" : "post_data",
      job_id: null,
      task_status: "create",
      data: query,
    }));

    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => response.Payload.data.result)
      .catch((error: any) => {
        console.log("Failed to create visit: " + error.message);
        return null;
      });
  }

  public hasVisit(patientId: number): Promise<any | null> {

    const formData: FormData = new FormData();
    formData.append('info', JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "query_data",
      job_id: null,
      task_status: "create",
      data: {
        draw: 1,
        order: [],
        start: 0,
        length: 15,
        search: {},
        table: "visit",
        columns: [
          { data: "patient:id", search: { value: patientId.toString(), regex: true } },
          { data: "visit:id" },
        ],
      },
    }));

    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => {
        const result: DataTablesResponse = response.Payload.data.result;
        if (result.data.length <= 0) {
          return null;
        }
        const raw: any = result.data[0];
        let visit: Visit = new Visit();
        visit.id = raw["visit:id"];
        visit.patient_id = raw["patient:id"];
        return visit;
      })
      .catch((error: any) => {
        console.log("Failed to get visit: " + error.message);
        return null;
      });
  }

  private httpOptions(): any {
    return { headers: new HttpHeaders({
      "token": this.authService.getToken(),
      "version": version,
    }) };
  }

}
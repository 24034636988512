export class Fax {
  public id: number | null = null;
  public job_id: number | null = null;
  public file_name: string | null = null;
  public original_path: string | null = null;
  public pdf_path: string | null = null;
  public doctor: string | null = null;
  public fax_number: string | null = null;
  public status: string | null = null;
  public received_at: number | null = null;
  public cancelled_at: number | null = null;
  public attempt_at: number | null = null;
  public attempts: number | null = null;
  public attempt_log: any = null;
  public success_at: number | null = null;
  public to_fax: string | null = null;
  public fax_notes: string | null = null;
  public queue_id: string | null = null;
  public holter_id: string | null = null;
  public doctor_id: string | null = null;
  public holter_additional_fax_numbers: string | null = null;
  public clinic_additional_fax_numbers: string | null = null;
}
<div>
  <div class="row sub-main">

    <div class="page-title">
      <div class="title_left">
        <h3>Holter - Edit</h3>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="x_panel">
      <div class="x_content">

        <div class="row split-container">
          <as-split direction="horizontal" (dragEnd)="onDragEnd($event)">

            <as-split-area [size]="pdfSizeLeft">

        <br>

        <form class="form-horizontal form-label-left" [formGroup]="formGroup">
          <div class="row">

            <div class="col-5 px-0 ml-3 mr-n3">
              <div class="form-group form-display row">
                <div class="col form-group">
                  <label>Last Name <i [hidden]="!errorStrings.last_name" class="conflict-message-inline">{{errorStrings.last_name}}</i></label>
                  <input type="text" class="form-control" placeholder="" formControlName="LastName" [attr.disabled]="disableControl() ? '' : null">
                </div>
                <div class="col form-group">
                  <label>First Name <i [hidden]="!errorStrings.first_name" class="conflict-message-inline">{{errorStrings.first_name}}</i></label>
                  <input type="text" class="form-control" placeholder="" formControlName="FirstName" [attr.disabled]="disableControl() ? '' : null">
                </div>
                <div class="col form-group">
                  <label>Health Card</label>
                  <input type="text" class="form-control" placeholder="" formControlName="HealthCard" [attr.disabled]="disableControl() || !isHolterEditor ? '' : null">
                </div>

              </div>
              <div class="form-group form-display row">
                <div class="col form-group">
                  <label>Hookup Date</label>
                  <input type="text" class="form-control" placeholder="YYYY/MM/DD" formControlName="HookupDate" [attr.disabled]="disableControl() ? '' : null">
                </div>
                <div class="col form-group">
                  <label>Scan Date</label>
                  <input type="text" class="form-control" placeholder="YYYY/MM/DD" formControlName="ScanDate" [attr.disabled]="true">
                </div>
                <div class="col form-group">
                  <label>Tech</label>
                  <input type="text" class="form-control" placeholder="" formControlName="Tech" [attr.disabled]="true">
                </div>

              </div>
              <div class="form-group form-display row">
                <div class="col form-group required">
                  <label>Reading Doctor</label>
                  <input type="text" class="form-control" placeholder="" [class.approved-field]="currentHolter && !errorStrings.readingDr" [class.disapproved-field]="currentHolter && errorStrings.readingDr" formControlName="ReadingDoctor" list="readinglist" [attr.disabled]="multiSelected || disableControl() ? '' : null">
                    <datalist id="readinglist">
                      <option *ngFor="let dr of readingDoctorOptions" [value]="dr.value">{{dr.text}}</option>
                    </datalist>
                </div>
                <div class="col form-group">
                  <label>Referring Doctor</label>
                  <input type="text" class="form-control" placeholder="" [class.approved-field]="currentHolter && !errorStrings.referringDr" [class.disapproved-field]="currentHolter && errorStrings.referringDr" formControlName="ReferringDoctor" [attr.disabled]="multiSelected || disableControl() ? '' : null" [ngbTypeahead]="searchReferringDoctor" [resultFormatter]="searchReferringDoctorFormatter" [inputFormatter]="searchReferringDoctorInputFormatter">
                </div>
              </div>
              <div class="form-group form-display row">
                <div class="col form-group">
                  <label>City</label>
                  <input type="text" class="form-control" placeholder="" [class.has-conflict]="!!errorStrings.city" formControlName="City" [attr.disabled]="multiSelected || disableControl() ? '' : null">
                </div>
                <div class="col form-group">
                  <label>Clinic Name</label>
                  <input type="text" class="form-control" placeholder="" [class.approved-field]="currentHolter && !errorStrings.clinic" [class.disapproved-field]="currentHolter && errorStrings.clinic" formControlName="Clinic" [attr.disabled]="multiSelected || disableControl() ? '' : null">
                </div>
              </div>

              <div class="col form-group">
                <form [formGroup]="checkBoxFormGroup">
                  <div class="form-group form-display row ml-4 mr-n4">
                    <div class="col form-group">
                      <input class="form-check-input" type="checkbox" name="check0" id="check0" formControlName="Reserved" [attr.disabled]="multiSelected || disableControl() || currentHolter.state == 'READING' || currentHolter.state == 'FAXING' ? '' : null">
                      <label class="form-check-label" for="check0">Reserved</label>
                    </div>
                    <div class="col form-group">
                      <input class="form-check-input" type="checkbox" name="check1" id="check1" formControlName="SkipToReading" [attr.disabled]="multiSelected || disableControl() || currentHolter.state != 'EDITING'? '' : null">
                      <label class="form-check-label" for="check1">Skip Interpretation</label>
                    </div>
                    <div class="col form-group">
                      <input class="form-check-input" type="checkbox" name="check2" id="check2" formControlName="FlagNoData" [attr.disabled]="multiSelected || disableControl() ? '' : null">
                      <label class="form-check-label" for="check2">No Monitor Data</label>
                    </div>
                  </div>
                  <div class="form-group form-display row ml-4 mr-n4">
                    <div class="col form-group">
                      <input class="form-check-input" type="checkbox" name="check3" id="check3" formControlName="FlagPacemakerInquiry" [attr.disabled]="multiSelected || disableControl() || currentHolter.state == 'READING' || currentHolter.state == 'FAXING' ? '' : null">
                      <label class="form-check-label" for="check3">Pacemaker Inq.</label>
                    </div>
                    <div class="col form-group">
                      <input class="form-check-input" type="checkbox" name="check4" id="check4" formControlName="FlagNoReq" [attr.disabled]="multiSelected || disableControl() || currentHolter.requisition_id ? '' : null">
                      <label class="form-check-label" for="check4">No Requisition</label>
                      <label class="req-resolved-indicator">{{(!!this.currentHolter && this.currentHolter.req_resolved_by) ? '&#40;' + 'Req Resolved' + '&#41;': '&nbsp;'}}</label>
                    </div>
                    <div class="col form-group">
                      <input class="form-check-input" type="checkbox" onclick="return false;" name="check5" id="check5" formControlName="FlagNoTracings" [attr.disabled]="true">
                      <label class="form-check-label" for="check5">No Report Tracings</label>
                    </div>

                  </div>
                </form>
              </div>
            </div>

            <div class="col-5 px-0 ml-3 mr-n3">
              <div class="form-group form-display row">
                <div class="col-2 form-group">
                  <label>VC</label>
                  <input type="text" class="form-control" placeholder="" formControlName="HealthCardVersion" [attr.disabled]="disableControl() ? '' : null">
                </div>
                <div class="col-2 form-group">
                  <label>Dur. <i [hidden]="!errorStrings.duration" class="conflict-message-inline">{{errorStrings.duration}}</i></label>
                  <input type="text" class="form-control" placeholder="" [class.has-conflict]="!!errorStrings.duration" formControlName="Duration" [attr.disabled]="multiSelected || disableControl() ? '' : null">
                </div>
                <div class="col-4 form-group">
                  <label>Birthdate</label>
                  <input type="text" class="form-control" placeholder="YYYY/MM/DD" formControlName="Birthdate" [attr.disabled]="disableControl() ? '' : null">
                </div>
                <div class="col-4 form-group">
                  <label>Gender <i [hidden]="!errorStrings.gender" class="conflict-message-inline">{{errorStrings.gender}}</i></label>
                  <select class="form-control" data-live-search="true" [class.has-conflict]="!!errorStrings.gender" formControlName="Gender" [attr.disabled]="multiSelected || disableControl() ? '' : null">
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                  </select>
                </div>
              </div>
              <div class="form-group form-display row">
                <div class="col form-group">
                  <label>DX Code</label>
                  <select class="form-control" data-live-search="true" (mouseleave)="verboseDxcode = false" (mouseenter)="verboseDxcode = true" formControlName="DxCode" [attr.disabled]="multiSelected || disableControl() ? '' : null">
                    <option *ngFor="let codeInfo of DXCODE_OPTIONS" [value]="codeInfo.code">{{verboseDxcode ? codeInfo.text : codeInfo.code}}</option>
                  </select>
                </div>
                <div class="col form-group">
                  <label>Delivery</label>
                  <input type="text" class="form-control" placeholder="" formControlName="Delivery" [contextMenu]="deliveryMenu" [attr.disabled]="multiSelected || disableControl() ? '' : null">
                </div>
                <div class="col form-group">
                  <label>Device SN</label>
                  <input type="text" class="form-control" [ngClass]="{'medtrace-field' : this.currentHolter?.referring_doctor_id != this.currentMedtrace?.doctor_id}" placeholder="" formControlName="Device" [readonly]="true" (contextmenu)="showMedtraceDisplay($event)">
                </div>
              </div>
              <div class="form-group form-display row">
                <div class="col form-group">
                  <label>Attached Req.</label>
                  <input type="text" class="form-control" placeholder="{{attachedReqPlaceHolder}}" formControlName="Requisition" (selectItem)="selectedReq($event)" [ngbTypeahead]="searchRequisition" [resultFormatter]="searchRequisitionFormatter" [inputFormatter]="searchRequisitionInputFormatter" [attr.disabled]="disableControl() ? '' : null">
                </div>
              </div>
              <div class="form-group form-display row">
                <div class="col form-group">
                  <label>Requisitions (For Updating)</label>
                  <div class="req-container">
                    <ul>
                      <li class="overflow-auto" *ngFor="let req of reqAuto" (click)="toggleSelection(req, 1)" [class.item-selected]="req.id === reqID" (click)="selectedSearchReq(req)">{{req | reqFormat}}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="form-group form-display row">
                <div class="col form-group">
                  <p class="billing-code-indicator">{{billingCode ? 'Billing Code: ' + billingCode : '&nbsp;'}}</p>
                </div>
              </div>
            </div>
            <div class="col-2 px-0">
              <div class="form-group form-display my-n3 row">
                <div class="col text-center">
                  <i data-toggle="tooltip" data-placement="left" title="See Requisition" class="fa download-req fa-file-o filter-icons" [class.filter-icons-off]="showPdfPreview != 'requisition'" (click)="toggleFileTypeFilter('req')"></i>
                  <p>Requisition</p>
                </div>
              </div>
              <div class="form-group form-display my-n3 row">
                <div class="col text-center">
                  <i data-toggle="tooltip" data-placement="left" title="See Tracings" class="fa download-tracings fa-file-pdf-o filter-icons" [class.filter-icons-off]="showPdfPreview != 'tracings'" (click)="toggleFileTypeFilter('pdf')"></i>
                  <p>Tracings</p>
                </div>
              </div>
              <div class="form-group form-display my-n3 row">
                <div class="col text-center">
                  <i data-toggle="tooltip" data-placement="left" title="See Faxes" class="fa download-faxes fa-fax filter-icons" [class.filter-icons-off]="showPdfPreview != 'fax'" (click)="toggleFileTypeFilter('fax')"></i>
                  <p>Faxes</p>
                </div>
              </div>
              <div class="text-center">
                <button class="btn btn-primary btn-narrow" (click)="updateHolter()" type="button" [disabled]="multiSelected || !currentHolter || disableControl() || !formGroup.valid || (!formChanged && !checkBoxFormChanged && !reqChanged) || !checkBoxFormGroup.valid">Update</button>

              </div>
            </div>
          </div>
        </form>
        <hr>
        <div class="row">
          <div class="col-12">
            <div class="row ml-2 mr-n2">
              <div class="col-sm-1">
                  <div class="custom-control custom-switch">
                    <input class="custom-control-input" type="checkbox" id="switch1" [(ngModel)]="showEntries" (click)="showUserEntries()" [hidden]="isSuperUser == false">
                    <label class="custom-control-label" [hidden]="isSuperUser == false" for="switch1">Work List</label>
                  </div>
              </div>
              <div class="col-1">
                <label>B.C.</label>
                <select class="form-control" data-live-search="true" [(ngModel)]="codeFilter" (change)="applySmartFilter()">
                  <option>All</option>
                  <option>ON</option>
                  <option>BC</option>
                  <option>WB</option>
                  <option>NBC</option>
                </select>
              </div>
              <div class="col-1">
                <label>Status</label>
                <select class="form-control" data-live-search="true" [(ngModel)]="holterStatusFilter" (change)="applySmartFilter()">
                  <option value="">All</option>
                  <option value="PENDING">Pending</option>
                  <option value="EDITING">Editing</option>
                  <option value="RESERVED">Reserved</option>
                  <option value="READING">Reading</option>
                  <option value="FAXING">Faxing</option>
                  <option value="SUSPENDED">Suspended</option>
                </select>
              </div>
              <div class="col-4">
                <label>Search</label>
                <input type="text" class="form-control search-bar" placeholder="Start typing to search" id="search-bar">
              </div>
              <div class="col-sm-1 pointer" (click)="applySmartFilterIcon('Entries to Repair')">
                <div class="row">
                  <div class="col thin text-right">
                    <i class="fa smart-filter-icons fa-wrench" [class.filter-icons-off]="currentSmartFilter() != 'Entries to Repair'"></i>
                  </div>
                  <div class="col thin text-left icon-filter-numbers">
                    ({{smartFilterCount('Entries to Repair')}})
                  </div>
                </div>
              </div>
              <div class="col-sm-1 pointer" (click)="applySmartFilterIcon('Entries to Interpret')">
                <div class="row">
                  <div class="col thin text-right">
                    <i class="fa smart-filter-icons fa-pencil" [class.filter-icons-off]="currentSmartFilter() != 'Entries to Interpret'"></i>
                  </div>
                  <div class="col thin text-left icon-filter-numbers">
                    ({{smartFilterCount('Entries to Interpret')}})
                  </div>
                </div>
              </div>
              <div class="col-sm-1 pointer" (click)="applySmartFilterIcon('Editor Reject')">
                <div class="row">
                  <div class="col thin text-right">
                    <i class="fa smart-filter-icons fa-eraser" [class.filter-icons-off]="currentSmartFilter() != 'Editor Reject'"></i>
                  </div>
                  <div class="col thin text-left icon-filter-numbers">
                    ({{smartFilterCount('Editor Reject')}})
                  </div>
                </div>
              </div>
              <div class="col-sm-1 pointer" (click)="applySmartFilterIcon('Doctor Reject')">
                <div class="row">
                  <div class="col thin text-right">
                    <i class="fa smart-filter-icons fa-user-md" [class.filter-icons-off]="currentSmartFilter() != 'Doctor Reject'"></i>
                  </div>
                  <div class="col thin text-left icon-filter-numbers">
                    ({{smartFilterCount('Doctor Reject')}})
                  </div>
                </div>
              </div>
              <div class="col-1">
              </div>
            </div>

            <div>
              <table datatable [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover" id="editing-table">
                <thead>
                  <tr>
                    <th width="10px"></th>
                    <th>Status</th>
                    <th>Files</th>
                    <th>Sync</th>
                    <th>Scan Date</th>
                    <th>Name</th>
                    <th>Reading Dr</th>
                    <th>Tech</th>
                    <th>Slot</th>
                    <th>Reserved</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="downloadProgress">
          <div class="col text-center progress-text">
            {{downloadProgress}}
          </div>
        </div>
      </as-split-area>

      <as-split-area [size]="pdfSizeRight">
        <form class="form-horizontal form-label-left" [formGroup]="formGroup">
          <div class="form-group form-display row ml-3">
            <div class="col form-group text-center">
              <button class="btn btn-primary btn-review" type="button" (click)="addStaffNotes()" [disabled]="!currentHolter || currentHolter.closed_by || multiSelected">Staff Notes</button>
            </div>
            <div class="col form-group text-center">
              <button class="btn btn-primary btn-review" type="button" (click)="openCreateFaxModal()" [disabled]="!currentHolter || currentHolter.closed_by || multiSelected">Fax Options</button>
            </div>
          </div>
          <div class="form-group form-display row ml-3">

            <div class="col form-group">
              <label>Staff Notes</label>
              <div class="row">
                <div class="col">
                  <textarea rows="3" class="form-control" placeholder="" formControlName="StaffNotes" readonly></textarea>
                </div>
              </div>
            </div>
            <div class="col form-group">
              <label>Requisition Notes</label>
              <div class="row">
                <div class="col">
                  <textarea rows="3" class="form-control" placeholder="" formControlName="ReqNotes" readonly></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group form-display row ml-3" *ngIf="currentHolter?.faxes.length > 0 && showPdfPreview == 'fax'">
            <div class="col form-group">
              <select class="form-control" data-live-search="true" formControlName="Fax" (change)="faxSelectionChanged()">
                <option *ngFor="let fax of currentHolter.faxes" [value]="fax.id" [ngClass]="(fax && currentFax && (fax.id == currentFax.id)) && 'current-entry'">{{fax?.status}} {{faxType(fax?.type)}} - {{fax?.fax_number}} {{fax?.doctor}}</option>
              </select>
            </div>
          </div>
          <div class="preview-panel">
            <div *ngIf="currentHolter && pdfSrc && !loadingPdf">
              <pdf-viewer [src]="pdfSrc" [autoresize]="true" [render-text]="false" [show-all]="showPdfPreview != 'tracings'" [original-size]="false" [fit-to-page]="true" style="display: block;"></pdf-viewer>
            </div>
          </div>
        </form>
      </as-split-area>
    </as-split>
    </div>
      </div>
    </div>
  </div>
  <div>
    <div class="row sub-footer">
      <div class="col-12">
        <button class="btn btn-primary btn-review" type="button" (click)="openCaseClosedModal()" [disabled]="!currentHolter || !!currentHolter.closed_by || multiSelected || this.currentHolter.state == 'READING' || this.currentHolter.state == 'FAXING'">Case Closed</button>
        <button class="btn btn-primary btn-review" type="button" (click)="syncHolter()" [disabled]="!currentHolter || currentHolter.closed_by || this.currentHolter.state == 'FAXING'">Sync</button>
        <button class="btn btn-primary btn-review" type="button" (click)="openRejectionModal()" [disabled]="!currentHolter || disableControl() || multiSelected">{{(currentHolter && !!currentHolter.rejected_by) ? 'Resubmit' : 'Reject'}}</button>
        <button class="btn btn-primary btn-review" type="button" (click)="openFlagModal()" [disabled]="!currentHolter || currentHolter.closed_by">Flag As</button>
      </div>
    </div>
  </div>


  <!-- this is for REQ contextMenu -->
  <context-menu #deliveryMenu>
    <ng-template *ngFor="let info of DELIVERY_OPTIONS" contextMenuItem (execute)="deliveryContextMenuClick($event, info)">{{info}}</ng-template>
  </context-menu>

</div>
<div class="row sub-main no-footer">

    <div class="page-title">
      <div class="title_left">
        <h3>Logistics - Home Holter</h3>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="x_panel">
      <div class="x_content">

        <div class="row">
          <dropzone id="dropzone-box" [config]="dropzoneConfig" [message]="'Click or drag new files here'"
          (error)="onUploadError($event)" (success)="onUploadSuccess($event)"></dropzone>
        </div>

        <br>

        <div class="row split-container">
          <as-split direction="horizontal">

            <as-split-area [size]="50">
              <div class="col">

                <div class="row">
                  <div class="col-4">
                    <select class="form-control" data-live-search="true" [(ngModel)]="typeFilter" (change)="typeFilterChanged()">
                      <option>All</option>
                      <option>Home Holter</option>
                      <option>Clinical</option>
                      <option>None</option>
                    </select>
                  </div>
                  <div class="col-8">
                    <input type="text" class="form-control search-bar" placeholder="Start typing to search" id="search-bar">
                  </div>
                </div>
                <br>

                <table datatable [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Last Updated</th>
                      <th>Email Received</th>
                      <th>Email Subject</th>
                      <th>Type</th>
                      <th>File Name</th>
                      <th>Id</th>
                    </tr>
                  </thead>
                </table>
                <div class="row">
                  <div class="col-4">
                    <button class="btn btn-primary btn-review" type="button" (click)="updateDocumentType(typeFilter !== 'Archived' ? 'Archived' : 'New')" [disabled]="!currentReq">
                      {{typeFilter === "Archived" ? "Restore" : "Archive"}}
                    </button>
                  </div>
                  <div class="col-8 text-right">
                    <button class="btn btn-primary btn-review" type="button" (click)="updateDocumentType('Invoice')" [disabled]="!currentReq || !currentReq.program">Mark as Invoice</button>
                    <button class="btn btn-primary btn-review" type="button" (click)="updateDocumentType('Requisition')" [disabled]="!currentReq || !currentReq.program">Mark as Requisition</button>
                  </div>
                </div>
              </div>
            </as-split-area>

            <as-split-area [size]="50">
              <div class="col info-panel" *ngIf="currentReq">

                <form class="form-horizontal form-label-left" [formGroup]="reqFormGroup">
                  <div class="row">
                    <div class="col form-group">
                      <label>Last Name</label>
                      <input type="text" class="form-control" placeholder="" formControlName="LastName" [ngbTypeahead]="searchHolter" [resultFormatter]="searchHolterFormatter" [inputFormatter]="searchHolterInputLastFormatter" [focusFirst]="false">
                    </div>
                    <div class="col form-group">
                      <label>First Name</label>
                      <input type="text" class="form-control" placeholder="" formControlName="FirstName" [ngbTypeahead]="searchHolter" [resultFormatter]="searchHolterFormatter" [inputFormatter]="searchHolterInputFirstFormatter" [focusFirst]="false">
                    </div>
                    <div class="col form-group">
                      <label>Hookup Date</label>
                      <input type="text" class="form-control" placeholder="YYYY/MM/DD" formControlName="HookupDate" [ngbTypeahead]="searchHolter" [resultFormatter]="searchHolterFormatter" [inputFormatter]="searchHolterInputHookupDateFormatter" [focusFirst]="false">
                    </div>
                    <div class="col form-group">
                      <div class="col form-group">
                        <button class="btn btn-primary btn-review" (click)="renameReq()" type="button" [disabled]="multiSelected || !currentReq || !hasProgramSelected()">Update</button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col form-group required">
                      <label>Program</label>
                      <select class="form-control" data-live-search="true" formControlName="Program">
                        <option>Clinical</option>
                        <option>Home Holter</option>
                      </select>
                    </div>
                    <div class="col form-group">
                      <label>Fax Notes</label>
                      <input type="text" class="form-control" placeholder="" formControlName="FaxNotes">
                    </div>
                    <div class="col form-group">
                      <label>Staff Notes</label>
                      <input type="text" class="form-control" placeholder="" formControlName="StaffNotes">
                    </div>
                    <div class="col form-group">
                      <div class="col form-group">
                      </div>
                    </div>
                  </div>
                  <br>
                </form>

                <div class="preview-panel">
                  <pdf-viewer [src]="pdfSrc" (after-load-complete)="pdfLoaded($event)" [(page)]="pdfPage" [autoresize]="true" [render-text]="false" [original-size]="false" [fit-to-page]="true" style="display: block;"></pdf-viewer>
                </div>

                <br>
                <form class="form-horizontal form-label-left" [formGroup]="formGroup">
                  <div class="row">
                    <div class="col text-right">
                      {{pdfPage}} of {{pdfPageCount}} pages
                    </div>
                  </div>
                  <div class="row">
                    <div class="col form-group">
                      <label>Page Selection</label>
                      <input type="text" class="form-control" placeholder="2, 4" formControlName="Pages">
                    </div>
                    <div class="col text-right form-group">
                      <button class="btn btn-primary btn-narrow" (click)="rotatePdf()" type="button" [disabled]="!currentReq || multiSelected">Rotate CCW</button>
                      <button class="btn btn-primary btn-narrow" (click)="splitPdf()" type="button" [disabled]="!currentReq || multiSelected || !formGroup.valid">Split</button>
                      <button class="btn btn-primary btn-narrow" (click)="mergePdf()" type="button" [disabled]="!currentReq || !multiSelected">Merge</button>
                    </div>
                  </div>
                </form>

              </div>
            </as-split-area>

          </as-split>
        </div>
      </div>
    </div>
  </div>

import { AfterViewInit, Component, OnInit, ViewChild, QueryList, ViewChildren, ChangeDetectorRef, ComponentFactoryResolver, ViewContainerRef, ComponentRef  } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {Observable, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';

import * as moment from "moment";
import * as download from "downloadjs";
import { saveAs } from "file-saver";

import { Medtrace } from "../../models/medtrace.model";

import { AuthService } from "../../providers/auth.service";
import { PhysicianService } from "../../providers/physician.service";
import { ClinicService } from "../../providers/clinic.service";
import { MedtraceService } from "../../providers/medtrace.service";
import { HolterService } from "../../providers/holter.service";

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalMedtraceDisplayComponent } from '../modal-medtrace-display/modal-medtrace-display.component';
import { Clinic } from 'src/app/models/clinic.model';
import { SerialPatternValidator, ExistingSerialValidator } from './matching.validator';
import { Physician } from 'src/app/models/physician.model';

@Component({
  selector: 'app-logistics-medtrace',
  templateUrl: './logistics-medtrace.component.html',
  styleUrls: ['./logistics-medtrace.component.scss']
})
export class LogisticsMedtraceComponent implements OnInit, AfterViewInit {
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<any>;

  public dtOptions: any = {};
  public dtRendered = true;
  public formGroup: FormGroup;
  public editMedtraceFormGroup: FormGroup;
  public medtraceButtonText: string = "Create"
  private clinic: Clinic;
  private doctor: Physician;

  public radio = "serial";
  public tableHeaders = ["USAGE", "ENTRY DATE RANGE", "DOCTOR'S NAME [billing number]", "CLINIC NAME", "ZONE", "STATUS [serial]"];

  public isActive = false;
  public isShared = false;
  public isExpired = false;
  public isNotActive = false;
  public isDemo = false;
  public isRepair = false;
  public isPending = false;

  public activeCount = "";
  public demoCount = "";
  public expiredCount = "";
  public repairCount = "";
  public pendingCount = "";
  public monitorsCount = "";
  public entriesCount = "";

  public activeStyle = {};
  public demoStyle = {};
  public expiredStyle = {};
  public repairStyle = {};
  public pendingStyle = {};

  public multiSelected: boolean = false;
  public currentMedtrace: Medtrace;
  public branchMedtrace: Medtrace;
  public search: string = null;

  get SerialNumber() {
    return this.editMedtraceFormGroup.get("SerialNumber");
  }

  public readonly DATE: string[] = [
    moment().format("YYYY/MM/DD"),
  ];

  public rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
    $("td", row).bind("click", () => this.rowClickHandler(data));
    $("td", row).bind("contextmenu", (e) => {
      e.preventDefault();
      this.rowClickHandler(data);
      setTimeout(() => {
        this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
          dtElement.dtInstance.then((dtInstance: any) => {
            if (dtInstance.table().node().id == 'medtrace-table') {
              this.branchMedtrace["clinic_name"] = data["clinic:name"]
              this.displayCountInformation('left');
            }
          });
        });
      }, 500);
      return false;
    });
    return row;
  };

  public drawCallback: any = (settings) => {
    this.dtElements?.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
          const row = dtInstance.row(rowIdx);
          if (this.currentMedtrace && row.data()["medtrace:id"] == this.currentMedtrace.id) {
            (row as any).select();
          }
          const status = row.data()["medtrace:status"];
          if (status == "ACTIVE" || status == "SHARED" ){
            $(row.node()).addClass('faxed-entry');
          }
          if (status == "REPAIR"){
            $(row.node()).addClass('failed-entry');
          }
          if (status == "EXPIRED"){
            $(row.node()).addClass('closed-entry');
          }
        });
      });
    });
  };

  public getColumns() {
    let columns = [];
    if (this.radio == "serial") {
      columns = [
        { data: "medtrace:focus_count",
          render: ( data, type, row, meta ) => {
            const focus_count = row["medtrace:focus_count"] ? row["medtrace:focus_count"] : "";
            let focus_string = focus_count
            if (this.formGroup.get("DateFilter")?.value) {
              focus_string = "0/" + focus_count;
            }
            return focus_string;
          },
        },
        { data: "medtrace:scan_date",
          render: ( data, type, row, meta ) => {
            const max_date = row["medtrace:scan_date"] ? row["medtrace:scan_date"] : "";
            const beginning_date = row["medtrace:beginning_date"] ? row["medtrace:beginning_date"] : "";
            let date_string = max_date + "<Br>" + beginning_date;
            return date_string;
          },
        },
        { data: "doctor:name",
          render: ( data, type, row, meta ) => {
            const name = row["doctor:name"] ? row["doctor:name"] : "";
            const billing_number = row["doctor:billing_number"] ? row["doctor:billing_number"] : "";
            const doctor_count = parseInt(row["medtrace:doctor_count"]);
            let doctor_count_string = doctor_count > 1 ? "and " + String(doctor_count-1) + " more" : "";
            let name_string = name + "(" + billing_number + ")"+ "<Br>" + doctor_count_string;
            return name_string;
          },
        },
        { data: "clinic:name",
          render: ( data, type, row, meta ) => {
            const name = row["clinic:name"] ? row["clinic:name"] : "";
            const clinic_count = parseInt(row["medtrace:clinic_count"]);
            let clinic_count_string = clinic_count > 1 ? "and " + String(clinic_count-1) + " more" : "";
            let clinic_string = name + "<Br>" + clinic_count_string;
            return clinic_string;
          },
        },
        { data: "medtrace:zone" },
        { data: "medtrace:serial_number",
          render: ( data, type, row, meta ) => {
            const status = row["medtrace:status"] ? row["medtrace:status"] : "";
            const serial = row["medtrace:serial_number"] ? row["medtrace:serial_number"] : "";
            const serial_count = parseInt(row["medtrace:serial_count"]);
            let serial_count_string = serial_count > 1 ? "and " + String(serial_count-1) + " more" : "";
            let serial_string = this.radio == "serial" ? status + "<Br>" + serial : serial + "<Br>" + serial_count_string;
            return serial_string;
          },
        },
        { data: "medtrace:status", visible: false },
        { data: "medtrace:id", visible: false },
        { data: "medtrace:doctor_id", visible: false },
        { data: "medtrace:clinic_id", visible: false },
        { data: "doctor:billing_number", visible: false },
        { data: "medtrace:beginning_date", visible: false },
        { data: "medtrace:doctor_count", visible: false },
        { data: "medtrace:clinic_count", visible: false },
        { data: "medtrace:serial_count", visible: false },
      ]
    }
    else if (this.radio == "clinic") {
      columns = [
        { data: "medtrace:focus_count",
          render: ( data, type, row, meta ) => {
            const focus_count = row["medtrace:focus_count"] ? row["medtrace:focus_count"] : "";
            let focus_string = focus_count
            if (this.formGroup.get("DateFilter")?.value) {
              focus_string = "0/" + focus_count;
            }
            return focus_string;
          },
        },
        { data: "medtrace:active_count",
          render: ( data, type, row, meta ) => {
            const active_count = row["medtrace:active_count"] ? parseInt(row["medtrace:active_count"]) : 0;
            return active_count;
          },
        },
        { data: "medtrace:scan_date",
          render: ( data, type, row, meta ) => {
            const max_date = row["medtrace:scan_date"] ? row["medtrace:scan_date"] : "";
            const beginning_date = row["medtrace:beginning_date"] ? row["medtrace:beginning_date"] : "";
            let date_string = max_date + "<Br>" + beginning_date;
            return date_string;
          },
        },
        { data: "doctor:name",
          render: ( data, type, row, meta ) => {
            const name = row["doctor:name"] ? row["doctor:name"] : "";
            const billing_number = row["doctor:billing_number"] ? row["doctor:billing_number"] : "";
            const doctor_count = parseInt(row["medtrace:doctor_count"]);
            let doctor_count_string = doctor_count > 1 ? "and " + String(doctor_count-1) + " more" : "";
            let name_string = name + "(" + billing_number + ")"+ "<Br>" + doctor_count_string;
            return name_string;
          },
        },
        { data: "clinic:name",
          render: ( data, type, row, meta ) => {
            const name = row["clinic:name"] ? row["clinic:name"] : "";
            const clinic_count = parseInt(row["medtrace:clinic_count"]);
            let clinic_count_string = clinic_count > 1 ? "and " + String(clinic_count-1) + " more" : "";
            let clinic_string = name + "<Br>" + clinic_count_string;
            return clinic_string;
          },
        },
        { data: "medtrace:zone" },
        { data: "medtrace:serial_number",
          render: ( data, type, row, meta ) => {
            const status = row["medtrace:status"] ? row["medtrace:status"] : "";
            const serial = row["medtrace:serial_number"] ? row["medtrace:serial_number"] : "";
            const serial_count = parseInt(row["medtrace:serial_count"]);
            let serial_count_string = serial_count > 1 ? "and " + String(serial_count-1) + " more" : "";
            let serial_string = this.radio == "serial" ? status + "<Br>" + serial : serial + "<Br>" + serial_count_string;
            return serial_string;
          },
        },
        { data: "medtrace:status", visible: false },
        { data: "medtrace:id", visible: false },
        { data: "medtrace:doctor_id", visible: false },
        { data: "medtrace:clinic_id", visible: false },
        { data: "doctor:billing_number", visible: false },
        { data: "medtrace:beginning_date", visible: false },
        { data: "medtrace:doctor_count", visible: false },
        { data: "medtrace:clinic_count", visible: false },
        { data: "medtrace:serial_count", visible: false },
      ]
    }
    return columns
  }

  public branchBuilder = {
      autoWidth: false,
      responsive: true,
      lengthChange: false,
      select: true,
      pageLength: 20,
      dom: "Blfrtip",
      buttons: [],
      rowCallback: this.rowCallback,
      serverSide: true,
      processing: true,
      scrollY: 0.6*window.innerHeight,
      ajax: this.medtraceService.getAjaxFuncion(),
      drawCallback: this.drawCallback,
      order: this.radio == 'serial' ? [[ 1, "desc" ]] : [[ 2, "desc" ]],
      columnDefs: [{
        targets: 0,
        className: 'expand-row-column'
      },{
        targets: 1,
        className: 'download-file-column'
      }],
      columns: this.getColumns(),
      language: {
        infoFiltered: "",
        info: this.radio == 'serial' ? "Showing _START_ of _END_ of _TOTAL_ monitors" : "Showing _START_ of _END_ of _TOTAL_ clinics" ,
      }
  }

  public ngAfterViewInit(): void {

    const that = this;
    document.addEventListener("click",  function(e){
      if (!e.target) {
        return;
      }
      const className = (e.target as Element).className;
      if(className == "as-split-gutter-icon"){
        window.dispatchEvent(new Event('resize'));
        that.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
          dtElement.dtInstance.then((dtInstance: any) => {
            dtInstance.draw();
          });
        });
      }
    });

    // set up the callbacks
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        if (dtInstance.table().node().id == 'medtrace-table') {
          // on search term callback
          $("#search_bar").on("keyup change", function () {
            setTimeout(() => {
              const search_string = this["value"].replace(',', '')
              if (dtInstance.search() !== search_string) {
                dtInstance.search(search_string).draw();
              }
            }, 1000);
          });
        }
      });
    });

    // focus on the search bar right away after load
    $("#search_bar").focus();
  }

  public ngOnInit() {}

  constructor(
    private medtraceService: MedtraceService,
    private modalService: NgbModal,
    private physicianService: PhysicianService,
    private clinicService: ClinicService,
    private authService: AuthService,
    private holterService: HolterService,
    private cdr: ChangeDetectorRef,
    private existingSerialValidator: ExistingSerialValidator,
  ) {

    this.medtraceService.clearFilters();
    this.medtraceService.setCustomFilter("branch_monitor_traffic_serial");

    this.populateMonitorCounts(null, null, null, null, null);

    // datatable options
    this.dtOptions = this.branchBuilder;

    this.formGroup = new FormGroup({
      FocusFilter: new FormControl("", []),
      DateFrom: new FormControl("", []),
      DateTo: new FormControl("", []),
      Active: new FormControl("", []),
      Expired: new FormControl("", []),
      Demo: new FormControl("", []),
      Repair: new FormControl("", []),
      Pending: new FormControl("", []),
      Search: new FormControl("", []),
      DateFilter: new FormControl("", []),
      UsageFilter: new FormControl("", []),
    });

    this.editMedtraceFormGroup = new FormGroup({
      SerialNumber: new FormControl("", {
        validators: [Validators.required, SerialPatternValidator],
        asyncValidators: [
          this.existingSerialValidator.validate.bind(
            this.existingSerialValidator
          ),
        ],
        updateOn: 'blur',
      }),
      ClinicName: new FormControl("", []),
      DoctorName: new FormControl("", []),
    });
  }

  private populateMonitorCounts(dateFrom, dateTo, recallDate, lower, upper) {
    this.medtraceService.getMedtraceMonitorCounts(this.radio, dateFrom, dateTo, recallDate, lower, upper).then(counts => {
      this.activeCount = counts.active;
      this.demoCount = counts.demo;
      this.expiredCount = counts.expired;
      this.repairCount = counts.repair;
      this.pendingCount = counts.pending;
      this.monitorsCount = this.radio == 'serial' ? counts.monitors + " monitors" : counts.monitors + " clinics";
      this.entriesCount = counts.entries;
      this.activeStyle = this.getStyle(counts.active_progress);
      this.demoStyle = this.getStyle(counts.demo_progress);
      this.expiredStyle = this.getStyle(counts.expired_progress);
      this.repairStyle = this.getStyle(counts.repair_progress);
      this.pendingStyle = this.getStyle(counts.pending_progress);
    });
  }

  public getStyle(progress) {
    return {'outline': 'solid', 'outline-width': 'thin', 'background':'linear-gradient(to right, orange '+progress+'%, white '+progress+'%'}
  }

  private updateMultiSelected(that: any) {
    that.dtElements?.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        // Checks for multi selection on the serial table only
        if (dtInstance.table().node().id == 'medtrace-table') {
          const selectedData = dtInstance.rows(".selected").data();
          that.multiSelected = selectedData.length > 1;
        }
      });
    });
  }

  public rowClickHandler(info: any, force: boolean = false) {
    setTimeout(() => this.updateMultiSelected(this), 200);
    this.medtraceService.getMedtraceClinicCounts(info["medtrace:id"], this.formGroup.get("DateFrom")?.value, this.formGroup.get("DateTo")?.value).then(medtrace => {
      this.branchMedtrace = medtrace;
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    // this.dtTrigger.unsubscribe();
  }

  private isValidDate(date: any) {
    return !isNaN(Date.parse(date));
  }

  public applyBranchFilter() {
    this.dtRendered = false;
    if (this.radio == "serial"){
      this.medtraceService.setCustomFilter("branch_monitor_traffic_serial");
      this.tableHeaders = ["USAGE", "ENTRY DATE RANGE", "DOCTOR'S NAME [billing number]", "CLINIC NAME", "ZONE", "STATUS [serial]"];
    }
    if (this.radio == "clinic"){
      this.medtraceService.setCustomFilter("branch_monitor_traffic_clinic");
      this.tableHeaders = ["USAGE", "ACTIVE COUNT", "ENTRY DATE RANGE", "DOCTOR'S NAME [billing number]", "CLINIC NAME", "ZONE", "SERIAL"];
    }
    this.branchBuilder.language = {
      infoFiltered: "",
      info: this.radio == 'serial' ? "Showing _START_ of _END_ of _TOTAL_ monitors" : "Showing _START_ of _END_ of _TOTAL_ clinics",
    }
    this.branchBuilder.columns = this.getColumns()
    this.branchBuilder.order = this.radio == 'serial' ? [[ 1, "desc" ]] : [[ 2, "desc" ]],
    // Removing and creating new table
    this.cdr.detectChanges();
    this.dtRendered = true;
    this.cdr.detectChanges();
    let usage = this.getUsageFilterNumbers()
    this.populateMonitorCounts(this.formGroup.get("DateFrom")?.value, this.formGroup.get("DateTo")?.value, this.formGroup.get("DateFilter")?.value, usage["lower"], usage["upper"]);
    this.formGroup.patchValue({
      Search: '',
    });

    // set up the search callbacks
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        if (dtInstance.table().node().id == 'medtrace-table') {
          // on search term callback
          $("#search_bar").on("keyup change", function () {
            setTimeout(() => {
              const search_string = this["value"].replace(',', '')
              if (dtInstance.search() !== search_string) {
                dtInstance.search(search_string).draw();
              }
            }, 1000);
          });
        }
      });
    });
  }

  public getCheckboxStatusChanges() {
    let status = [];
    this.isActive ? status.push("ACTIVE") : null;
    this.isShared ? status.push("SHARED") : null;
    this.isExpired ? status.push("EXPIRED") : null;
    this.isNotActive ? status.push("NOT ACTIVE") : null;
    this.isDemo ? status.push("DEMO") : null;
    this.isRepair ? status.push("REPAIR") : null;
    this.isPending ? status.push("PENDING") : null;

    status.length == 0 ? status = ["ACTIVE", "SHARED", "EXPIRED", "NOT ACTIVE", "DEMO", "REPAIR", "PENDING"] : null;
    return status
  }

  public checkboxChange(){
    this.medtraceService.updateStatusFilter(this.getCheckboxStatusChanges());
    this.reloadTable('medtrace');
  }

  public applyFilter() {
    this.medtraceService.clearFilters();
    this.radio == "serial" ? this.medtraceService.setCustomFilter("branch_monitor_traffic_serial") : this.medtraceService.setCustomFilter("branch_monitor_traffic_clinic") ;
    let startDateFilter = null;
    let endDateFilter = null;
    let recallDateFilter = null;
    if (this.formGroup.get("DateFrom")?.value) {
      if (this.isValidDate(this.formGroup.get("DateFrom")?.value)) {
        startDateFilter = new Date(this.formGroup.get("DateFrom")?.value)
      }
      this.medtraceService.setDateFromFilter(startDateFilter);
    }
    if (this.formGroup.get("DateTo")?.value) {
      if (this.isValidDate(this.formGroup.get("DateTo")?.value)) {
        endDateFilter = new Date(this.formGroup.get("DateTo")?.value);
      }
      this.medtraceService.setDateToFilter(endDateFilter);
    }
    if (this.formGroup.get("DateFilter")?.value) {
      if (this.isValidDate(this.formGroup.get("DateFilter")?.value)) {
        recallDateFilter = new Date(this.formGroup.get("DateFilter")?.value);
      }
      this.medtraceService.setRecallDateFilter(recallDateFilter);
    }
    let usage = this.getUsageFilterNumbers();
    this.populateMonitorCounts(this.formGroup.get("DateFrom")?.value, this.formGroup.get("DateTo")?.value, this.formGroup.get("DateFilter")?.value, usage["lower"], usage["upper"]);
    this.medtraceService.updateStatusFilter(this.getCheckboxStatusChanges());
    this.medtraceService.setFocusCountFilter(usage["lower"], usage["upper"]);
    this.reloadTable('medtrace');
  }

  private reloadTable(table) {
    this.dtElements?.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        if (dtInstance.table().node().id == 'medtrace-table' && table == 'medtrace') {
          dtInstance.page(0).draw(false);
        }
      });
    });
  }

  public displayCountInformation(side) {
    const modalRef = this.modalService.open(ModalMedtraceDisplayComponent, { size:'lg'});
    if (side == 'left'){
      modalRef.componentInstance.medtrace = this.branchMedtrace;
      modalRef.componentInstance.type = this.radio == "serial" ? "display_monitor_traffic" : "display_monitor_traffic_clinic";
    }
  }

  public getUsageFilterNumbers() {
    let result = {};
    if (this.formGroup.get("UsageFilter")?.value == "") {
      result = {
        upper: null, lower: null,
      }
    }
    else if (this.formGroup.get("UsageFilter")?.value == "LT5") {
      result = {
        upper: 5, lower: null,
      }
    }
    else if (this.formGroup.get("UsageFilter")?.value == "MT5LT30") {
      result = {
        upper: 30, lower: 5,
      }
    }
    else if (this.formGroup.get("UsageFilter")?.value == "MT30") {
      result = {
        upper: null, lower: 30,
      }
    }
    return result;
  }

  public exportQuery() {
    let status = this.getCheckboxStatusChanges();
    let usage = this.getUsageFilterNumbers();
    let dateTo = null;
    if (!this.formGroup.get("DateTo")?.value) {
      dateTo = moment().format("YYYY/MM/DD")
    }
    else {
      dateTo = this.formGroup.get("DateTo")?.value
    }
    this.medtraceService.exportQuery(this.radio, this.formGroup.get("DateFrom")?.value, dateTo, usage["lower"], usage["upper"], status).subscribe(data => {
      saveAs(data, "medtrace_query.csv");
    });
  }

  public downloadReport() {
    let status = this.getCheckboxStatusChanges();
    let usage = this.getUsageFilterNumbers();
    let dateTo = null;
    if (!this.formGroup.get("DateTo")?.value) {
      dateTo = moment().format("YYYY/MM/DD")
    }
    else {
      dateTo = this.formGroup.get("DateTo")?.value
    }
    this.medtraceService.getMedtraceQueryReport(this.radio, this.formGroup.get("DateFrom")?.value, dateTo, usage["lower"], usage["upper"], status)
      .then((base64Data: string) => {
        download(HolterService.base64ToArrayBuffer(base64Data), "Recall Report", "application/pdf");
      })
  }

  public newMedtrace() {
    this.medtraceService.createMedtraceAdmin({
      serial_number: this.editMedtraceFormGroup.get("SerialNumber")?.value,
      doctor_id: this.doctor.id,
      clinic_id: this.clinic.id,
    }).then(() => {
      let usage = this.getUsageFilterNumbers();
      this.populateMonitorCounts(this.formGroup.get("DateFrom")?.value, this.formGroup.get("DateTo")?.value, this.formGroup.get("DateFilter")?.value, usage["lower"], usage["upper"]);
      this.reloadTable('medtrace');
    })
  }

  searchClinic = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => (!this.authService.isCardioStudyCompanyClass() || term.length < 2) ? Promise.resolve([])
        : this.clinicService.searchClinicsWithDoctors(term))
    )

  searchClinicFormatter = (result: any): string => {
    return [
      result.name,
      result.doctor ? result.doctor.last_name : null,
      result.doctor ? result.doctor.first_initials : null,
      result.billing_code,
    ].filter(x => !!x).join(" | ")
  };

  searchClinicInputFormatter = (result: any): string => {
    if (!result.name) {
      return result;
    }

    this.editMedtraceFormGroup.patchValue({
      ClinicName: result.name,
      DoctorName: result.doctor.name,
    });
    this.doctor = result.doctor;
    this.clinic = result;
    return result.name;
  };

}
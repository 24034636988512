import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { HostListener } from '@angular/core';
import { Router } from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { environment } from "../../../environments/environment";
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import * as moment from "moment";
import { formatNotesSingle } from "../../utils/format";

import { RequisitionService } from "../../providers/requisition.service";
import { HolterService } from "../../providers/holter.service";
import { AuthService } from "../../providers/auth.service";
import { PhysicianService } from "../../providers/physician.service";

import { formatLocaleDateSingle } from "../../utils/format";

@Component({
  selector: 'app-logistics-home-holter',
  templateUrl: './logistics-home-holter.component.html',
  styleUrls: ['./logistics-home-holter.component.scss']
})
export class LogisticsHomeHolterComponent implements AfterViewInit {

  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;
  public dtOptions: any = {};

  private readonly UPLOAD_URL: string = environment.api + '/requisition';
  public dropzoneConfig: DropzoneConfigInterface | null = null;

  public multiSelected: boolean = false;
  public currentReq: any = null;

  public pendingReqs: string[] = [];
  public pdfSrc: any = null;
  public pdfPage: any = 1;
  public pdfPageCount: any = null;

  public formGroup: FormGroup;
  public reqFormGroup: FormGroup;

  public typeFilter: any = "All";

  constructor(
    private authService: AuthService,
    private holterService: HolterService,
    private requisitionService: RequisitionService,
    private physicianService: PhysicianService,
    private router: Router,
  ) {

    // configure dropzone
    this.dropzoneConfig = {
      url: this.UPLOAD_URL,
      maxFilesize: 50,
      paramName: "file",
      acceptedFiles: ".pdf",
      headers: { "token": this.authService.getToken() },
      params: {
        info: JSON.stringify({}),
      }
    };

    // roll callback
    const rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
      $("td", row).bind("click", () => this.rowClickHandler(data));
      return row;
    };

    this.requisitionService.updateDocumentTypeFilter(this.typeFilter);
    this.requisitionService.updateStatusFilter(null);

    // datatable options
    this.dtOptions = {
      autoWidth: false,
      responsive: true,
      lengthChange: false,
      select: true,
      pageLength: 50,
      dom: "Blfrtip",
      buttons: [],
      rowCallback: rowCallback,
      serverSide: true,
      processing: true,
      scrollY: window.innerHeight - 480,
      ajax: this.requisitionService.getAjaxFuncion(),
      order: [[ 1, "desc" ]],
      columnDefs: [{
        targets: 0,
        className: 'expand-row-column'
      },{
        targets: 1,
        className: 'download-file-column'
      }],
      columns: [
        {
          data: "requisition:last_name" ,
          orderable: true,
          render: function ( data, type, row, meta ) {
            const last = row["requisition:last_name" ];
            const first = row["requisition:first_name"];
            if (!last && !first) {
              return "";
            } else if (!last) {
              return first;
            } else if (!first) {
              return last;
            } else {
              return last + ", " + first;
            }
          },
        },
        { data: "requisition:updated_at" },
        { data: "requisition:received_at" },
        { data: "requisition:received_subject" },
        { data: "requisition:document_type" },
        { data: "requisition:file_name", visible: false },
        { data: "requisition:id", visible: false },
        { data: "requisition:first_name", visible: false },
        { data: "requisition:program", visible: false },
      ],
      language: {
        infoFiltered: ""
      }
    };

    this.reqFormGroup = new FormGroup({
      LastName: new FormControl("", [ Validators.required, ]),
      FirstName: new FormControl("", [ Validators.required, ]),
      HookupDate: new FormControl("", [ Validators.pattern(/^\d{4}\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/) ]),
      Program: new FormControl("", [ Validators.required, ]),
      FaxNotes: new FormControl("", []),
      StaffNotes: new FormControl("", []),
    });
    this.formGroup = new FormGroup({
      Pages: new FormControl("", [ Validators.required, Validators.pattern(/[\s,]*(\d+)[\s,]*/)]),
    });
    this.reloadRequisitions();
  }

  private updateMultiSelected(that: any) {
    that.datatableElement.dtInstance.then((dtInstance: any) => {
      const selectedData = dtInstance.rows(".selected").data();
      that.multiSelected = selectedData.length > 1;
    });
  }

  public rowClickHandler(info: any, force: boolean = false) {
    setTimeout(() => this.updateMultiSelected(this), 200);
    this.requisitionService.getRequisition(info["requisition:id"]).then(
      requisition => this.populateRequisition(requisition, true)
    );
  }

  public typeFilterChanged(): void {
    this.requisitionService.updateProgramTypeFilter(this.typeFilter);
    this.reloadTable();
  }

  public ngAfterViewInit(): void {
    const that = this;
    document.addEventListener("click",  function(e){
      if (!e.target) {
        return;
      }
      const className = (e.target as Element).className;
      if(className == "as-split-gutter-icon"){
        window.dispatchEvent(new Event('resize'));
        that.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.draw();
        });
      }
    });

    this.configureReqHeights();

    // set up the callbacks
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {

      // on search term callback
      $("#search-bar").on("keyup change", function () {
        if (dtInstance.search() !== (<HTMLInputElement>this)["value"]) {
          dtInstance.search((<HTMLInputElement>this)["value"]).draw();
        }
      });
    });

    // focus on the search bar right away after load
    $("#search-bar").focus();

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.configureReqHeights();
  }

  public onUploadError(event: any) {
    console.log("Dropzone Error: " + event);
    this.configureReqHeights();
    this.reloadRequisitions();
  }

  public onUploadSuccess(event) {
    this.configureReqHeights();
    this.reloadRequisitions();
  }

  private configureReqHeights() {
    const dropzoneHeight = $("#dropzone-box").height();
    $(".req-table").height(window.innerHeight - dropzoneHeight - 280);
  }

  private reloadRequisitions(): Promise<void> {
    return this.holterService.getRequisitions().then((reqs) => {
      this.pendingReqs = reqs.pending.map(x => x.file_name);
    })
  }

  private populateRequisition(requisition, reloadPreview: boolean) {
    this.currentReq = requisition;
    this.formGroup.reset();
    this.reqFormGroup.reset();
    this.requisitionService.getRequisitionPresignedUrl(requisition.id).then(url => {
      this.pdfSrc = url;
    });
    this.reqFormGroup.patchValue({
      LastName: requisition.last_name,
      FirstName: requisition.first_name,
      HookupDate: formatLocaleDateSingle(requisition.date),
      Program: requisition.program,
      FaxNotes: formatNotesSingle(requisition.fax_notes),
      StaffNotes: formatNotesSingle(requisition.staff_notes),
    });
  }

  private reloadTable() {
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  public updateDocumentType(documentType: string) {
    this.datatableElement?.dtInstance.then((dtInstance: any) => {
      const updatePromises = [];
      dtInstance.rows(".selected").every((idx) => {
        const entry = dtInstance.row(idx).data();
        updatePromises.push(this.requisitionService.markDocumentType(entry["requisition:id"], documentType));
      });
      Promise.all(updatePromises).then(() => dtInstance.draw());
    });
  }

  public splitPdf() {
    this.requisitionService.splitDocument(this.currentReq.id, this.formGroup.get("Pages")?.value || "")
      .then(() => this.reloadTable());
  }

  public mergePdf() {
    this.datatableElement?.dtInstance.then((dtInstance: any) => {
      const reqIds = [];
      dtInstance.rows(".selected").every((idx) => {
        const entry = dtInstance.row(idx).data();
        reqIds.push(entry["requisition:id"]);
      });
      this.requisitionService.mergeDocument(reqIds).then(() => this.reloadTable());
    });
  }

  public rotatePdf() {
    this.requisitionService.rotateDocument(this.currentReq.id, this.formGroup.get("Pages")?.value || "")
      .then((url) => {
        this.pdfSrc = url;
      });
  }

  public renameReq() {
    this.requisitionService.renameReq(this.currentReq.id, {
      first_name: this.getFormFirstName(),
      last_name: this.getFormLastName(),
      hookup_date: this.getFormHookupDate(),
      program: this.reqFormGroup.get("Program")?.value,
      staff_notes: this.reqFormGroup.get("StaffNotes")?.value,
      fax_notes: this.reqFormGroup.get("FaxNotes")?.value,
    })
    .then(() => {
      this.reloadTable();
      this.requisitionService.getRequisition(this.currentReq.id).then(
        requisition => this.populateRequisition(requisition, true)
      );
    });
  }

  public hasProgramSelected() {
    return !!this.reqFormGroup.get("Program")?.value;
  }

  public pdfLoaded(pdf: any) {
    this.pdfPage = 1;
    this.pdfPageCount = pdf.numPages;
  }

  public getFormFirstName() {
    let value = this.reqFormGroup.get("FirstName")?.value;
    if (typeof value === 'object' && value !== null) {
      value = value.patient.first_name;
    }
    return value;
  }

  public getFormLastName() {
    let value = this.reqFormGroup.get("LastName")?.value;
    if (typeof value === 'object' && value !== null) {
      value = value.patient.last_name;
    }
    return value;
  }

  public getFormHookupDate() {
    let value = this.reqFormGroup.get("HookupDate")?.value;
    if (typeof value === 'object' && value !== null) {
      value = value.hookup_date;
    }
    return value;
  }

  searchHolter = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => (term.length < 2) ? Promise.resolve([])
        : this.holterService.searchHolterForInbox(
          this.getFormFirstName(),
          this.getFormLastName(),
          this.getFormHookupDate(),
          {
            hookup_date: null,
            patient: {
              first_name: "No Match Found",
              last_name: null,
            }
          }
        ))
    )

  searchHolterFormatter = (result: any): string => {
    return [
      result.patient.first_name,
      result.patient.last_name,
      result.hookup_date ? moment(result.hookup_date).format("YYYY/MM/DD") : '',
    ].filter(x => !!x).join(" | ")
  };

  searchHolterInputFirstFormatter = (result: any): string => {
    if (typeof result === 'object' && result !== null) {
      this.reqFormGroup.patchValue({FirstName: result.patient.first_name});
      this.reqFormGroup.patchValue({LastName: result.patient.last_name});
      this.reqFormGroup.patchValue({HookupDate: (result.hookup_date ? moment(result.hookup_date).format("YYYY/MM/DD") : '')});
      return result.patient.first_name;
    }
    return result;
  };

  searchHolterInputLastFormatter = (result: any): string => {
    if (typeof result === 'object' && result !== null) {
      this.reqFormGroup.patchValue({FirstName: result.patient.first_name});
      this.reqFormGroup.patchValue({LastName: result.patient.last_name});
      this.reqFormGroup.patchValue({HookupDate: (result.hookup_date ? moment(result.hookup_date).format("YYYY/MM/DD") : '')});
      return result.patient.last_name;
    }
    return result;
  };

  searchHolterInputHookupDateFormatter = (result: any): string => {
    if (typeof result === 'object' && result !== null) {
      this.reqFormGroup.patchValue({FirstName: result.patient.first_name});
      this.reqFormGroup.patchValue({LastName: result.patient.last_name});
      this.reqFormGroup.patchValue({HookupDate: (result.hookup_date ? moment(result.hookup_date).format("YYYY/MM/DD") : '')});
      return result.hookup_date;
    }
    return result;
  };

}

import { AfterViewInit, Component, OnInit, ViewChildren, ViewChild, QueryList, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Subject, Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';

import { saveAs } from "file-saver";

import { DataTableDirective } from 'angular-datatables';

import { AuthService } from "../../providers/auth.service";
import { AbpmService } from "../../providers/abpm.service";
import { VisitService } from "../../providers/visit.service";
import { PhysicianService } from "../../providers/physician.service";
import { PatientService } from "../../providers/patient.service";

import { Abpm } from "../../models/abpm.model";
import { Visit } from "../../models/visit.model";
import { Physician } from "../../models/physician.model";
import { Clinic } from "../../models/clinic.model";
import { Patient } from "../../models/patient.model";
import { ClinicService } from "../../providers/clinic.service";

@Component({
  selector: 'app-debug-abpm',
  templateUrl: './debug-abpm.component.html',
  styleUrls: ['./debug-abpm.component.scss']
})
export class DebugAbpmComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(DataTableDirective)
  // dtElements: QueryList<DataTableDirective>;
  private datatableElement: DataTableDirective;

  public dtTrigger: any = new Subject();
  public dtOptions: any = {};
  public dtOptionsPending: any = {};
  public dtOptionsRaw: any = {};

  public dtInstancePending: any = null;
  public dtInstanceNotPending: any = null;

  public readonly HEIGHT_METRIC = "cm";
  public readonly HEIGHT_IMPERIAL = "in";
  public readonly WEIGHT_METRIC = "kg";
  public readonly WEIGHT_IMPERIAL = "lb";
  public heightUnit: string = this.HEIGHT_METRIC;
  public weightUnit: string = this.WEIGHT_METRIC;

  public abpmStatusFilter: string | null = "All";

  public readingDoctorOptions: any[] = [];
  public createFormGroup: FormGroup;

  public patient: Patient = new Patient();
  public abpm$: Observable<Abpm>;
  public currentAbpm: Abpm;

  public ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  public ngAfterViewInit(): void {

    // this handles the filter tabs on the top of the table
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) =>  {
      if (dtInstance === undefined) {
        return;
      }
      $("#search-bar").on("keyup change", function () {
        if (dtInstance.search() !== (<HTMLInputElement>this)["value"]) {
          dtInstance.search((<HTMLInputElement>this)["value"]).draw();
        }
      });
      $("#search-bar").focus();
    });
  }

  public ngOnInit() {}

  constructor(
    private abpmService: AbpmService,
    private visitService: VisitService,
    private authService: AuthService,
    private patientService: PatientService,
    private physicianService: PhysicianService,
    private clinicService: ClinicService,
    private router: Router
  ) {

    // roll callback
    const rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
      // $("td", row).unbind("click");
      $(".fa-plus", row).bind("click", (event) => this.expandRowsHandler(event, data, true));
      $(".fa-minus", row).bind("click", (event) => this.expandRowsHandler(event, data, false));
      $("td", row).bind("click", () => this.rowClickHandler(data));
      return row;
    };

    // if reading doctor, filter so that they can see just what's assigned to them. no need to strict permission check on server, because it's okay for them to see
    const isReadingDoctor = this.authService.getToken(true).roles.some(role => role.name === "Reading Doctor");
    const tokenInfo = this.authService.getToken(true);
    this.abpmService.updateReadingDoctorFilter((tokenInfo.doctor_id && isReadingDoctor) ? tokenInfo.doctor_id : null);

    this.abpmService.clearFilters();
    // datatable options
    this.dtOptions = {
        autoWidth: false,
        responsive: true,
        lengthChange: false,
        select: true,
        pageLength: 25,
        dom: "Blfrtip",
        buttons: [],
        rowCallback: rowCallback,
        serverSide: true,
        processing: true,
        scrollY: window.innerHeight - 930,
        ajax: this.abpmService.getAjaxFuncion(),
        order: [[ 2, "desc" ]],
        columns: [
          {
            data: 'abpm:id',
            orderable: false,
            render: ( data, type, row, meta ) => {
              const duplicateCount = row["abpm:duplicate_count"];
              let expandHtml = ``;
              if (duplicateCount > 1)
                expandHtml = ` &nbsp;<a><i class="fa fa-plus expand-rows-icon"></i></a>`;
              if (duplicateCount < -1)
                expandHtml = ` &nbsp;<a><i class="fa fa-minus expand-rows-icon"></i></a>`;

              return expandHtml;
            },
          },
          { data: "abpm:status" },
          { data: "visit:hookup_at" },
          { data: "patient:last_name" },
          { data: "patient:first_name" },
          { data: "patient:birthdate" },
          { data: "owner:name" },
          { data: "visit:read_by" },
          { data: "abpm:serial_number", visible: false },
          { data: "abpm:visit_id", visible: false },
        ],
        language: {
          infoFiltered: ""
        }
    };

    this.createFormGroup = new FormGroup({
      HealthCard: new FormControl("", [ Validators.required, ]),
      ReferringDoctorName: new FormControl("", [ Validators.required, ]),
      ReadingDoctorName: new FormControl("", [ Validators.required, ]),
      FirstName: new FormControl("", [ Validators.required, ]),
      LastName: new FormControl("", [ Validators.required, ]),
      Location: new FormControl("", [ Validators.required, ]),
      Birthdate: new FormControl("", [ Validators.required, Validators.pattern(/^\d{4}\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/) ]),
      Height: new FormControl("", [ Validators.required, Validators.pattern(/^\d*\.?\d*$/) ]),
      Weight: new FormControl("", [ Validators.required, Validators.pattern(/^\d*\.?\d*$/) ]),
      Gender: new FormControl("", [ Validators.required, ]),
      Indication: new FormControl("", [ ]),
      Medications: new FormControl("", [ ]),
      ReadBy: new FormControl("", [ Validators.required, ]),
    });

    this.physicianService.allReading().then(doctors => {
      doctors.forEach(doctor => {
        const text = [
          doctor.last_name,
          doctor.first_initials,
          doctor.clinic ? doctor.clinic.city : null,
          doctor.billing_code,
        ].filter(x => !!x).join(" | ");

        this.readingDoctorOptions.push({
          value: doctor.name,
          text: text,
        });
      });
    });

    this.createFormGroup.valueChanges.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      switchMap((changes: any) => {
        if (changes.HealthCard.replace(/[^\d]/g, '').substr(0, 10).length < 1) {
          return Promise.resolve();
        }
        return this.patientService.getPatientByHealthCard(changes.HealthCard).then((patient: Patient) => {
            if (patient && (!this.patient || patient.id !== this.patient.id)) {
              this.populateForm(patient, null);
            }
            this.patient = patient;
          });
      })
     ).subscribe();
  }

  private populateForm(patient: Patient, visit: Visit): void {
    if (patient) {
      this.createFormGroup.patchValue({
        FirstName: patient.first_name,
        LastName: patient.last_name,
        Birthdate: patient.birthdate,
        Height: patient.height,
        Weight: patient.weight,
        Gender: patient.gender,
      });
    }

    if (visit) {
      this.createFormGroup.patchValue({
        Medications: visit.medications,
        Indication: visit.indication,
        ReadBy: visit.read_by,
        HealthCard: visit.patient_health_card,
        FirstName: visit.patient_first_name,
        LastName: visit.patient_last_name,
        Birthdate: visit.patient_birthdate,
        Height: visit.patient_height,
        Weight: visit.patient_weight,
        Gender: visit.patient_gender,
        Location: visit.clinic_street,
        ReferringDoctorName: visit.referring_doctor_name,
        ReadingDoctorName: visit.reading_doctor_name,
      })
    }
  }

  public toggleHeightUnit(): void {
    this.heightUnit = (this.heightUnit == this.HEIGHT_METRIC) ? this.HEIGHT_IMPERIAL : this.HEIGHT_METRIC;
  }

  public toggleWeightUnit(): void {
    this.weightUnit = (this.weightUnit == this.WEIGHT_METRIC) ? this.WEIGHT_IMPERIAL : this.WEIGHT_METRIC;
  }

  public expandRowsHandler(event: any, info: any, expand: boolean) {
    const clickedId = info["abpm:id"];
    if (expand)
    {
      this.abpmService.setCustomFilter("show_abpm_duplicates");
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
          const row = dtInstance.rows().data()[rowIdx];
          if (row["abpm:id"] != clickedId) {
            return;
          }
          this.abpmService.updateShowDuplicates(row["abpm:visit_id"], row["abpm:serial_number"]);
          dtInstance.page(0).draw();
        });
      });
    }
    else
    {
      this.statusFilterChanged();
    }
  }


  public statusFilterChanged(): void {
    this.abpmService.clearFilters();
    this.abpmService.updateStatusFilter(this.abpmStatusFilter);
    this.reloadTable();
  }

  private reloadTable() {
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw(false);
    });
  }

  private updateMultiSelected(that: any) {
    that.datatableElement.dtInstance.then((dtInstance: any) => {
      const selectedData = dtInstance.rows(".selected").data();
      that.multiSelected = selectedData.length > 1;
    });
  }

  public rowClickHandler(info: any) {
    // this.router.navigateByUrl('/abpm/detail/interpretations/' + info["abpm:id"]);
    setTimeout(() => this.updateMultiSelected(this), 200);

    this.abpmService.getAbpm(info["abpm:id"]).then(abpm => {
      this.currentAbpm = abpm;
      this.visitService.getVisit(abpm.visit_id).then((visit: Visit) => {
        if (!visit) {
          return this.visitService.getMinimumVisit(abpm.visit_id);
        }
        return visit;
      }).then((visit: Visit) => {

        this.populateForm(null, visit);
      });
    });

  }

  public queryAbpm() {
    this.abpmService.exportQuery(this.abpmStatusFilter, true).subscribe(data => {
      saveAs(data, "query.csv");
    });
  }

  public queryBillingAbpm() {
    this.abpmService.exportQuery("Billing ABPM", true).subscribe(data => {
      saveAs(data, "billingABPM.csv");
    });
  }

  // clinic typeahead
  public searchClinic: any = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        if (!this.authService.isCardioStudyCompanyClass() || term.length < 3) {
          return Promise.resolve([]);
        }
        return this.clinicService.searchClinic(term).then((options: Clinic[]) => {
          if (options === null || options === undefined || options.length === 0) {
            return [];
          }
          return options.map((o: Clinic) => o.street);
        });
      })
    );

  public hasError(fieldName: string): boolean {
    return fieldName == "Birthdate" && (this.createFormGroup.controls.Birthdate.errors?.required || this.createFormGroup.controls.Birthdate.errors?.pattern);
  }

  // ADD IN PROMISE LATER
  // public onSubmit() {

  public onSubmit(): Promise<any> {
    const abpm = this.currentAbpm;
    // convert to metric if units aren't in metric
    let height = parseFloat(this.createFormGroup.get("Height")?.value);
    let weight = parseFloat(this.createFormGroup.get("Weight")?.value);
    if (!isNaN(height) && this.heightUnit == this.HEIGHT_IMPERIAL) {
      height = height * 2.54;
    }
    if (!isNaN(weight) && this.weightUnit == this.WEIGHT_IMPERIAL) {
      weight = weight * 0.453592;
    }

    const data = {
      visit: {
        id: abpm.visit_id,
        read_by: this.createFormGroup.get("ReadBy")?.value,
        indication: this.createFormGroup.get("Indication")?.value,
        medications: this.createFormGroup.get("Medications")?.value,
        patient: {
          last_name: this.createFormGroup.get("LastName")?.value,
          first_name: this.createFormGroup.get("FirstName")?.value,
          healthcard: this.createFormGroup.get("HealthCard")?.value,
          gender: this.createFormGroup.get("Gender")?.value,
          birthdate: this.createFormGroup.get("Birthdate")?.value,
          height: Math.round(height * 10) / 10,
          weight: Math.round(weight * 10) / 10,
        },
        clinic: {
          street: this.createFormGroup.get("Location")?.value,
        },
        referring_doctor: {
          name: this.createFormGroup.get("ReferringDoctorName")?.value,
        },
        reading_doctor: {
          name: this.createFormGroup.get("ReadingDoctorName")?.value?.name || this.createFormGroup.get("ReadingDoctorName")?.value,
        }
      },
    };
    return this.abpmService.updateAbpm(abpm.id, data).then(() =>
      // this.router.navigateByUrl("/abpm/detail/interpretations/" + this.route.snapshot.params["id"])
      this.reloadTable()
    );
  }

  public isComplete (){
    if (!this.currentAbpm) {
      return true;
    }
    return this.currentAbpm.status == "Completed" ? true : false;
  }

}

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import * as download from "downloadjs";

import { Abpm } from "../../models/abpm.model";
import { AbpmService } from "../../providers/abpm.service";
import { AbpmCalculationsService } from '../../providers/abpm-calculations.service';
import { Visit } from "../../models/visit.model";
import { VisitService } from "../../providers/visit.service";
import { FeatureToggleService } from "../../providers/feature-toggle.service";

@Component({
  selector: 'app-home',
  templateUrl: './abpm-detail.component.html',
  styleUrls: ['./abpm-detail.component.scss']
})
export class AbpmDetailComponent implements OnInit {

  public abpm: Abpm = null;

  constructor(
    private abpmService: AbpmService,
    private calculate: AbpmCalculationsService,
    private visitService: VisitService,
    private featureToggleService: FeatureToggleService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.abpmService.getAbpm(this.abpmId()).then((abpm: Abpm) => this.abpm = abpm);
  }

  public ngOnInit() {}

  public abpmId(): number {
    const tokens = this.router.url.split("/");
    return parseInt(tokens[tokens.length - 1], 10);
  }

  // deprecated
  public downloadReport(downloadFile: boolean = true): Promise<any> {
    $("body, button").css("cursor", "wait");
    return this.abpmService.getReportPresignedUrl(this.abpm.id).then((url) => {
      download(url);
      $("body, button").css("cursor", "auto");
    });
    // return downloadFile ? download("data:application/pdf;base64," + pdfBase64, reportName, "application/pdf") : { base64: pdfBase64, name: faxName };
  }

  private showWaiting(show: boolean) {
    if (show) {
      $("body, button").css("cursor", "wait");
    } else {
      $("body, button").css("cursor", "auto");
    }
  }

  public hideFax(): boolean {
    if (!this.abpm) {
      return true;
    }
    return this.abpm.status.toLowerCase() !== "completed";
  }

  public createFax(): Promise<any> {
    if (!this.abpm) {
      return Promise.resolve();
    }
    return this.downloadReport(false)
      .then((report: any) => {
        this.showWaiting(true);
        return this.abpmService.createFax(report);
      })
      .then(() => {
        this.showWaiting(false);
        this.router.navigateByUrl("/fax");
      });
  }

  public navigateTo(url: string): void {
    this.router.navigateByUrl(url);
  }

  public urlContains(str: string): boolean {
    return this.router.url.indexOf(str) > -1;
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';

import { AuthService } from "../../providers/auth.service";
import { PhysicianService } from "../../providers/physician.service";

import { formatLocaleDateSingle, formatNotesMultiple  } from "../../utils/format";

import * as moment from "moment";

@Component({
  selector: 'app-modal-staff-notes',
  templateUrl: './modal-staff-notes.component.html',
  styleUrls: ['./modal-staff-notes.component.scss']
})
export class ModalStaffNotesComponent implements OnInit {
  @Input() public holter;
  @Input() public doctor;
  @Input() public ereq;
  @Input() public disable;
  public formGroup: FormGroup;
  public createReq: boolean = false;
  public createReqNotes: string = '';
  public clinic: string = '';
  public city: string = '';
  public testDuration: string = '';
  public otherIndication: boolean = false;
  public labelText: string = 'Create E-Requisition';
  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthService,
    private physicianService: PhysicianService,
    ) {
    this.formGroup = new FormGroup({
      StaffNotes: new FormControl("", [ ]),
      StaffLog: new FormControl("", [ ]),
      CreateReq: new FormControl("", [ ]),
      LastName: new FormControl("", [ ]),
      FirstName: new FormControl("", [ ]),
      HealthCard: new FormControl("", [ ]),
      HealthCardVersion: new FormControl("", [ ]),
      Birthdate: new FormControl("", [ ]),
      Tech: new FormControl("", [ ]),
      ReferringDoctor: new FormControl("", [ ]),
      Adult: new FormControl("", [ ]),
      TestDate: new FormControl("", [ ]),
      Duration: new FormControl("", [ ]),
      OtherHoursText: new FormControl("", [ ]),
      Abnormal: new FormControl("", [ ]),
      Afib: new FormControl("", [ ]),
      Palpitations: new FormControl("", [ ]),
      PostStroke: new FormControl("", [ ]),
      Syncope: new FormControl("", [ ]),
      AtrialArrhythmia: new FormControl("", [ ]),
      Presyncope: new FormControl("", [ ]),
      Medication: new FormControl("", [ ]),
      ChestPain: new FormControl("", [ ]),
      Ventricular: new FormControl("", [ ]),
      Fatigue: new FormControl("", [ ]),
      Pacemaker: new FormControl("", [ ]),
      VVI: new FormControl("", [ ]),
      DDD: new FormControl("", [ ]),
      AtrialFibrillation: new FormControl("", [ ]),
      ROArrhythmia: new FormControl("", [ ]),
      Other: new FormControl("", [ ]),
      OtherIndicationsText: new FormControl("", [ ]),
    });
  }

  ngOnInit(): void {
    if (this.disable) {
      this.formGroup.patchValue({
        StaffLog: formatNotesMultiple([this.holter.notes, this.holter.requisition_staff_notes]),
      });
    }
    else {
      this.ereq ? this.labelText = 'Modify E-Requisition' : null;
      this.populateModal(this.holter);
      this.clinic = this.holter.clinic_name;
      this.city = this.holter.clinic_city;
    }
  }

  onRightClick($event, form_field, value) {
    $event.preventDefault();
    this.formGroup.get(form_field).markAsPristine();
    this.formGroup.patchValue({
      [form_field]: value,
    });
  }

  private populateModal(holter) {
    this.formGroup.reset();
    let duration_strings = ['1', '2', '3', '14'];
    if (holter.duration){
      duration_strings.indexOf(holter.duration.toString()) > -1 ? this.testDuration = holter.duration.toString() : this.testDuration = '0';
      this.formGroup.patchValue({
        Duration: !(duration_strings.indexOf(holter.duration.toString()) > -1) ? '0' : null,
        OtherHoursText: this.testDuration == '0' ? holter.duration.toString() : null,
      });
    }

    if (this.ereq){
      let ereq_data = JSON.parse(this.ereq.erequisition_data);
      // console.log(JSON.parse(this.ereq.erequisition_data));
      this.otherIndication = ereq_data.other;
      this.formGroup.patchValue({
        Abnormal: ereq_data.abnormal ? ereq_data.abnormal : null,
        Afib: ereq_data.afib ? ereq_data.afib : null,
        Palpitations: ereq_data.palpitations ? ereq_data.palpitations : null,
        PostStroke: ereq_data.postStroke ? ereq_data.postStroke : null,
        Syncope: ereq_data.syncope ? ereq_data.syncope : null,
        AtrialArrhythmia: ereq_data.atrialArrhythmia ? ereq_data.atrialArrhythmia : null,
        Presyncope: ereq_data.presyncope ? ereq_data.presyncope : null,
        Medication: ereq_data.medication ? ereq_data.medication : null,
        ChestPain: ereq_data.chestPain ? ereq_data.chestPain : null,
        Ventricular: ereq_data.ventricular ? ereq_data.ventricular : null,
        Fatigue: ereq_data.fatigue ? ereq_data.fatigue : null,
        Pacemaker: ereq_data.pacemaker ? ereq_data.pacemaker : null,
        VVI: ereq_data.VVI ? ereq_data.VVI : null,
        DDD: ereq_data.DDD ? ereq_data.DDD : null,
        AtrialFibrillation: ereq_data.atrialFibrillation ? ereq_data.atrialFibrillation : null,
        ROArrhythmia: ereq_data.roArrhythmia ? ereq_data.roArrhythmia : null,
        OtherIndicationsText: ereq_data.otherIndicationsText ? ereq_data.otherIndicationsText : null,
      });
    }
    this.formGroup.patchValue({
      LastName: holter.patient_last_name,
      FirstName: holter.patient_first_name,
      HealthCard: holter.patient_health_card.substring(0,10),
      HealthCardVersion: holter.patient_health_card_code,
      Birthdate: holter.patient_birthdate,
      Tech: holter.staff_first_name ? (holter.staff_first_name + " " + holter.staff_last_name) : null,
      StaffLog: formatNotesMultiple([holter.notes, holter.requisition_staff_notes]),
      ReferringDoctor: holter.referring_doctor_name,
      TestDate: holter.hookup_date,
      Adult: this.isPediatricPatient(holter.patient_birthdate, holter.hookup_date),
    });
  }

  passBack() {
    this.activeModal.close({
      createReq: this.formGroup.get("CreateReq")?.value,
      lastName: this.formGroup.get("LastName")?.value,
      firstName: this.formGroup.get("FirstName")?.value,
      healthCard: this.formGroup.get("HealthCard")?.value,
      healthCardVersion: this.formGroup.get("HealthCardVersion")?.value,
      birthdate: this.formGroup.get("Birthdate")?.value,
      referringDoctor: this.formGroup.get("ReferringDoctor")?.value,
      adult: this.formGroup.get("Adult")?.value == 0 ? true : false,
      testDate: this.formGroup.get("TestDate")?.value,
      staffNotes: this.formGroup.get("StaffNotes")?.value,
      abnormal: this.formGroup.get("Abnormal")?.value,
      afib: this.formGroup.get("Afib")?.value,
      palpitations: this.formGroup.get("Palpitations")?.value,
      postStroke: this.formGroup.get("PostStroke")?.value,
      syncope: this.formGroup.get("Syncope")?.value,
      atrialArrhythmia: this.formGroup.get("AtrialArrhythmia")?.value,
      presyncope: this.formGroup.get("Presyncope")?.value,
      medication: this.formGroup.get("Medication")?.value,
      chestPain: this.formGroup.get("ChestPain")?.value,
      ventricular: this.formGroup.get("Ventricular")?.value,
      fatigue: this.formGroup.get("Fatigue")?.value,
      pacemaker: this.formGroup.get("Pacemaker")?.value,
      VVI: this.formGroup.get("VVI")?.value,
      DDD: this.formGroup.get("DDD")?.value,
      atrialFibrillation: this.formGroup.get("AtrialFibrillation")?.value,
      roArrhythmia: this.formGroup.get("ROArrhythmia")?.value,
      other: this.formGroup.get("Other")?.value,
      otherIndicationsText: this.formGroup.get("OtherIndicationsText")?.value,
      clinic: this.clinic,
      city: this.city,
      duration: this.testDuration == '0' ? this.formGroup.get("OtherHoursText")?.value : this.testDuration,
    });
  }

  searchReferringDoctor = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => (!this.authService.isCardioStudyCompanyClass() || term.length < 2) ? Promise.resolve([])
        : this.physicianService.searchDoctor(term, true, false))
    )

  searchReferringDoctorFormatter = (result: any): string => {
    return [
      result.last_name,
      result.first_initials,
      result.clinic ? result.clinic.city : null,
      result.billing_code,
    ].filter(x => !!x).join(" | ")
  };

  searchReferringDoctorInputFormatter = (result: any): string => {
    if (!result.name) {
      return result;
    }

    this.formGroup.patchValue({
      ReferringDoctor: result.name,
    });
    this.clinic = result.clinic ? result.clinic.city : null;
    this.city = result.clinic ? result.clinic.name : null;
    return result.name;
  };

  private isPediatricPatient(birthdate, hookup_date) {
    if (!birthdate) {
      return false;
    }
    if (!hookup_date) {
      return true;
    }
    var birthday = moment(birthdate);
    var currentDate = hookup_date ? moment(hookup_date) : moment();
    if (currentDate.diff(birthday, 'years') < 18) {
      return '1'
    }
    else {
      return '0'
    }
  }

}

import { Component, Input, OnInit, ViewChild, ChangeDetectorRef  } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DataTableDirective } from 'angular-datatables';

import { SparkService } from "../../providers/spark.service";
import { formatLocaleDateSingle } from "../../utils/format";

@Component({
  selector: 'app-modal-spark-monitor-display',
  templateUrl: './modal-spark-monitor-display.component.html',
  styleUrls: ['./modal-spark-monitor-display.component.scss']
})
export class ModalSparkMonitorDisplayComponent implements OnInit {
  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;

  public dtOptions: any = {};
  public tableHeaders = ["DEVICE ID", "SLOT", "NAME", "PATH", "PATIENT"];
  @Input() public path;
  @Input() public device;
  public emptySlotsCount: number = 0;

  public formGroup: FormGroup;


  public rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
    // $("td", row).bind("click", () => this.rowClickHandler(data));
    // $("td", row).bind("contextmenu", (e) => {
    //   e.preventDefault();
    //   this.rowClickHandler(data);
    //   setTimeout(() => {
    //     this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
    //       dtElement.dtInstance.then((dtInstance: any) => {
    //         if (dtInstance.table().node().id == 'medtrace-table') {
    //           this.modify('left');
    //         }
    //       });
    //     });
    //   }, 500);
    //   return false;
    // });
    return row;
  };

  public drawCallback: any = (settings) => {
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      // dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
      //   const row = dtInstance.row(rowIdx);
      //   const serial = row.data()["medtrace:serial_number"];
      //   const scan_date = row.data()["medtrace:scan_date"];
      //   const doctor_id = row.data()["medtrace:doctor_id"];
      //   const clinic_id = row.data()["medtrace:clinic_id"];
      //   if (serial == this.medtrace.serial_number && scan_date == formatLocaleDateSingle(this.medtrace.scan_date) && doctor_id == this.medtrace.doctor_id && clinic_id == this.medtrace.clinic_id){
      //     $(row.node()).addClass('highlighting-reserved-entry');
      //   }
      // });
    });
  };

  public displayBuilder = {
    autoWidth: false,
    responsive: true,
    lengthChange: false,
    select: true,
    pageLength: 50,
    dom: "Blfrtip",
    buttons: [],
    rowCallback: this.rowCallback,
    serverSide: true,
    processing: true,
    scrollY: window.innerHeight - 500,
    ajax: this.sparkService.getDisplayAjaxFuncion(),
    drawCallback: this.drawCallback,
    order: [[ 1, "desc" ]],
    columns: [
      { data: "spark_device:id"},
      { data: "holter:upload_computer_slot"},
      { data: "holter:upload_computer" },
      { data: "holter:upload_computer_path" },
      { data: 'patient:last_name',
      render: ( data, type, row, meta ) => {
        const last_name = row["patient:last_name"] ? row["patient:last_name"] : "";
        const first_name = row["patient:first_name"] ? row["patient:first_name"] : "";
        const health_card = row["patient:health_card"] ? row["patient:health_card"] : "";
        let name = health_card + "<Br>" + last_name + ' ' + first_name;
        return name;
      },
      },
      { data: "patient:health_card", visible: false },
      { data: "patient:first_name", visible: false },
      { data: "spark_upload:id", visible: false },
    ],
    language: {
      infoFiltered: ""
    }

  };

  constructor(
    public activeModal: NgbActiveModal,
    private sparkService: SparkService,
  ) {

  }

  ngOnInit(): void {
    this.sparkService.clearDisplayFilters();
    this.sparkService.setCustomDisplayFilter("device_slots");
    this.sparkService.updateDisplayDevicePathFilter(this.device, this.path);
    this.dtOptions = this.displayBuilder;
    this.sparkService.getDatabaseInfo(this.device, this.path).then(count => {
      this.emptySlotsCount = count["empty_slot_count"];
    });
  }

  ngOnDestroy() {

  }

  public passBack(action) {
    this.activeModal.close();
  }

}
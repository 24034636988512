<div>
  <div class="row sub-main">

    <div class="page-title" *ngIf="holter$ | async as holter">
      <div class="title_left">
        <h3>{{title}}</h3>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="x_panel">
      <div class="x_content">

        <div class="row">
          <div class="col-12 md-width">

            <div class="row" *ngIf="holter$ | async as holter">

              <div class="col-12 md-width">

                <h4>Holter Monitor Report</h4>
                <form class="form-horizontal form-label-left">
                  <div class="form-group form-display row">
                    <label class="control-label col-2" for="first-name">Patient's Name </label>
                    <div class="col form-answer">{{(holter.patient_last_name && holter.patient_last_name) ? (holter.patient_last_name + ', ' + holter.patient_first_name) : '-'}}</div>
                    <label class="control-label col-2" for="last-name">Date of Test </label>
                    <div class="col form-answer">{{holter.scan_date || '-'}}</div>
                  </div>
                  <div class="form-group form-display row">
                    <label class="control-label col-2" for="first-name">Date of Birth </label>
                    <div class="col form-answer">{{holter.patient_birthdate || '-'}}</div>
                    <label class="control-label col-2" for="last-name">Referring Physician </label>
                    <div class="col form-answer">{{holter.referring_doctor_name || '-'}}</div>
                  </div>
                  <div class="form-group form-display row">
                    <label class="control-label col-2" for="first-name">HIN </label>
                    <div class="col form-answer">{{buildHIN(holter) || '-'}}</div>
                    <label class="control-label col-2" for="last-name"></label>
                    <div class="col form-answer"></div>
                  </div>
                  <br>
                  <div class="form-group form-display row">
                    <label class="control-label col-2" for="first-name">Indications </label>
                    <div class="col form-answer">{{getMedfluxData(holter, 'indications') || '-'}}</div>
                  </div>
                  <div class="form-group form-display row">
                    <label class="control-label col-2" for="first-name">12 Lead ECG </label>
                    <div class="col form-answer">{{getMedfluxData(holter, 'leadecg') || '-'}}</div>
                  </div>
                </form>
                <br>

                <h4>General Comments</h4>
                <textarea #generalComment1Div class="form-control" rows="1" [(ngModel)]="generalComment1"></textarea><br>
                <textarea #generalComment2Div class="form-control" rows="1" [(ngModel)]="generalComment2"></textarea><br>
                <textarea #generalComment3Div class="form-control" rows="1" [(ngModel)]="generalComment3"></textarea><br>
                <textarea #generalComment4Div class="form-control" rows="1" [(ngModel)]="generalComment4"></textarea><br>
                <textarea #generalComment5Div class="form-control" rows="1" [(ngModel)]="generalComment5"></textarea><div class="alert-message">{{alertGeneralComment5}}</div><br>
                <br>

                <h4>Diary Comments</h4>
                <p><i>Patient diary was reviewed by analyst</i></p>
                <textarea #specificComment1Div class="form-control" rows="1" [(ngModel)]="specificComment1"></textarea><br>
                <textarea #specificComment2Div class="form-control" rows="1" [(ngModel)]="specificComment2"></textarea><br>
                <textarea #specificComment3Div class="form-control" rows="1" [(ngModel)]="specificComment3"></textarea><br>
                <textarea #specificComment4Div class="form-control" rows="1" [(ngModel)]="specificComment4"></textarea><br>
                <textarea #specificComment5Div class="form-control" rows="1" [(ngModel)]="specificComment5"></textarea><br>
                <br>

                <h4>Summary</h4>
                <textarea #summary1Div class="form-control" rows="1" [(ngModel)]="summary1"></textarea><br>
                <textarea #summary2Div class="form-control" rows="1" [(ngModel)]="summary2"></textarea><br>
                <textarea #summary3Div class="form-control" rows="1" [(ngModel)]="summary3"></textarea><br>
                <textarea #summary4Div class="form-control" rows="1" [(ngModel)]="summary4"></textarea><br>
                <textarea #summary5Div class="form-control" rows="1" [(ngModel)]="summary5"></textarea><br>
                <br>

                <form class="form-horizontal form-label-left">
                  <div class="form-group form-display row">
                    <label class="control-label col-1" for="first-name">Analyst: </label>
                    <div class="col form-answer">{{getMedfluxData(holter, 'analyst') || '-'}}</div>
                  </div>
                  <div class="form-group form-display row">
                    <label class="control-label col-1" for="first-name">Editor: </label>
                    <div class="col form-answer">{{getMedfluxData(holter, 'editor') || '-'}}</div>
                  </div>
                  <div class="form-group form-display row">
                    <div class="col text-right">{{holter.reading_doctor_name || ''}}</div>
                  </div>
                </form>



              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>

  <div>
    <div class="row sub-footer">
      <div class="col-12">
        <button class="btn btn-primary btn-review" type="button" (click)="clearForm()"><i class="fa fa-file-o"></i> Clear</button>
        <button class="btn btn-primary btn-review" type="button" (click)="undoForm()"><i class="fa fa-undo"></i> Undo</button>
        <button class="btn btn-primary btn-review" type="button" (click)="autofillForm()"><i class="fa fa-flash"></i> Autofill</button>
        <button class="btn btn-primary btn-review" type="button" (click)="autofillForm(true)"><i class="fa fa-flash"></i> Editor Autofill</button>
        <button class="btn btn-primary btn-review" type="button" (click)="submitForm()"><i class="fa fa-check"></i> Submit</button>
      </div>
    </div>
  </div>

</div>
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { Router } from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { Subscription, timer, Subject } from 'rxjs';
import { switchMap, debounceTime, distinctUntilChanged, map, takeUntil, take} from 'rxjs/operators';

import * as moment from "moment";
import * as download from "downloadjs";
import * as JSZip from "jszip";
import * as JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalRejectionComponent } from '../modal-rejection/modal-rejection.component';

import { Holter } from "../../models/holter.model";

import { HolterService } from "../../providers/holter.service";
import { AuthService } from "../../providers/auth.service";

@Component({
  selector: 'app-home',
  templateUrl: './holter-reading.component.html',
  styleUrls: ['./holter-reading.component.scss'],
  host: {'(document:keypress)': 'handleKeyboardEvent($event)',
  }
})
export class HolterReadingComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;
  private subscription: Subscription;
  private destroy$ = new Subject();

  public dtOptions: any = {};

  public multiSelected: boolean = false;
  public editingReport: boolean = false;
  public showPdfPreview: string = null;

  public pdfSrc: any = null;
  public pdfSrcLeft: any = null;
  public loadingPdf: boolean = false;
  public pageNumber: any = 1;
  public totalPages: any;

  public formGroupReport: FormGroup;
  public formGroupChangedList: any = {};

  public currentHolter: Holter;
  public currentDatatablePage: number = 0;
  public currentHolterList: any[] = [];
  public wasSaved: boolean = false;
  public isPrepared: boolean = false;

  public searchDatabases: any[] = [];

  public wasAutofilled: boolean = false;
  public pdfPatConflicts: any = {};
  public autofillConflicts: any = {};

  public prioritySort: boolean = true;
  public statusFilter: any = "All";
  public rejectResult = {description: ''}

  public emailFilter: string | null = null;
  public batchMode: boolean = false;
  public isSkipToReading: boolean = false;
  public holterIdx: string = "";
  public holterCount: string = "";
  public tableCount: any = "";
  public readingDoctorID: number = 0;

  public pdfSizeLeft: any = 50;
  public pdfSizeRight: any = 50;

  private readonly DEBOUNCE_SEC: number = 1;

  @ViewChild("generalComment1Div") generalComment1Div: ElementRef;
  @ViewChild("generalComment2Div") generalComment2Div: ElementRef;
  @ViewChild("generalComment3Div") generalComment3Div: ElementRef;
  @ViewChild("generalComment4Div") generalComment4Div: ElementRef;
  @ViewChild("generalComment5Div") generalComment5Div: ElementRef;
  @ViewChild("specificComment1Div") specificComment1Div: ElementRef;
  @ViewChild("specificComment2Div") specificComment2Div: ElementRef;
  @ViewChild("specificComment3Div") specificComment3Div: ElementRef;
  @ViewChild("specificComment4Div") specificComment4Div: ElementRef;
  @ViewChild("specificComment5Div") specificComment5Div: ElementRef;
  @ViewChild("summary1Div") summary1Div: ElementRef;
  @ViewChild("summary2Div") summary2Div: ElementRef;
  @ViewChild("summary3Div") summary3Div: ElementRef;
  @ViewChild("summary4Div") summary4Div: ElementRef;
  @ViewChild("summary5Div") summary5Div: ElementRef;
  @ViewChild("additionalComment1Div") additionalComment1Div: ElementRef;
  @ViewChild("additionalComment2Div") additionalComment2Div: ElementRef;
  @ViewChild("additionalComment3Div") additionalComment3Div: ElementRef;
  @ViewChild("additionalComment4Div") additionalComment4Div: ElementRef;
  @ViewChild("additionalComment5Div") additionalComment5Div: ElementRef;

  public setEmailFilter() {
    if (this.emailFilter) {
      this.emailFilter = null;

    } else {
      const token = this.authService.getToken(true);
      this.emailFilter = token.email;
    }

    this.holterService.updateEmailFilter(this.emailFilter);
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw(false);
    });
  }

  private reloadTable() {
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.page(0).draw(false);
      setTimeout(() => {
        this.tableCount = dtInstance.page.info().recordsDisplay;
        this.refreshHolterCountDisplay();
      }, 500);
    });
  }

  public statusFilterChanged(): void {
    if (this.statusFilter === "All") {
      this.holterService.updateRejectedFilter(false);
      this.holterService.updateStateFilter(["READING"]);
      this.holterService.updateAbnormalFilter(null);

    } else if (this.statusFilter === "Rejected") {
      this.holterService.updateRejectedFilter(true);
      this.holterService.updateStateFilter(["READING", "FAXING"]);
      this.holterService.updateAbnormalFilter(null);

    } else if (this.statusFilter === "Sent") {
      this.holterService.updateRejectedFilter(false);
      this.holterService.updateStateFilter(["FAXING"]);
      this.holterService.updateAbnormalFilter(null);

    } else if (this.statusFilter === "To Read") {
      this.holterService.updateRejectedFilter(false);
      this.holterService.updateStateFilter(["READING"]);
      this.holterService.updateAbnormalFilter(false);

    } else if (this.statusFilter === "Priority") {
      this.holterService.updateRejectedFilter(false);
      this.holterService.updateStateFilter(["READING"]);
      this.holterService.updateAbnormalFilter(true);
    }
    // Removing storage of filter memory
    // localStorage.setItem(document.location.href+'Status', String(this.statusFilter));
    this.reloadTable();
  }

  public applyPrioritySort(): void {
    const newValue = !this.prioritySort;
    this.holterService.applyPrioritySort(newValue ? true : null);
    this.reloadTable();
  }

  // important variable that changes if the status column position changes
  private readonly STATUS_COLUMN_INDEX: number = 0;

  public ngAfterViewInit(): void {

    const that = this;
    document.addEventListener("click",  function(e){
      if (!e.target) {
        return;
      }
      const className = (e.target as Element).className;
      if(className == "as-split-gutter-icon"){
        window.dispatchEvent(new Event('resize'));
        that.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.draw(false);
        });
      }
    });

    setTimeout(() => {
      // set up the callbacks
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        // on search term callback
        $("#search-bar").on("keyup change", function () {
          setTimeout(() => {
            const search_string = this["value"].replace(',', '')
            if (dtInstance.search() !== search_string) {
              dtInstance.search(search_string).draw();
            }
          }, 1000);
        });
        // on page change callback
        $('#reading-table').on('page.dt', function(){
          var info = dtInstance.page.info();
          that.currentDatatablePage = info.page;
          if (that.statusFilter == "All"){
            localStorage.setItem('currentPage', String(that.currentDatatablePage));
          }
          // that.sendToFaxing();
          that.refreshHolterCountDisplay();
          // NOTE: REMOVED FOR NOW
          // if (!that.currentHolter) {
          //   that.displayHolterCount();
          // }
        });
        // focus on the search bar right away after load
        $("#search-bar").focus();

        //Find previous holter and select if it exist
        let found_holter = JSON.parse(localStorage.getItem('currentHolter'));
        let found_page = JSON.parse(localStorage.getItem('currentPage'));
        if (found_page == null) {
          found_page = 0;
        }
        if (found_holter) {
          // Switch to page first then run loop through datatable
          dtInstance.page(found_page).draw(false);
          setTimeout(() => {
            dtInstance.rows().every( function ( rowIdx, tableLoop, rowLoop ) {
              const row = dtInstance.row(rowIdx);
              if (row.data()["holter:id"] == found_holter) {
                (row as any).select();
                that.holterService.getHolter(row.data()["holter:id"]).then(holter => {
                  that.currentHolter = holter;
                });
              }
            });
            this.tableCount = dtInstance.page.info().recordsDisplay;
          }, 500);
        }
        else {
          this.tableCount = dtInstance.page.info().recordsDisplay;
        }
        // finding the current list of holter entries
        this.refreshHolterCountDisplay();
        // when loading page, send prepared holters
        // this.sendToFaxing();
      });
    }, 1500);
  }

  public ngOnInit() {}

  constructor(
    private holterService: HolterService,
    private authService: AuthService,
    private router: Router,
    private modalService: NgbModal,
  ) {

    this.holterService.clearFilters();

    const token = this.authService.getToken(true);
    this.isSkipToReading = token.skip_to_reading;

    // if reading doctor, filter so that they can see just what's assigned to them. no need to strict permission check on server, because it's okay for them to see
    const isReadingDoctor = token.roles.some(role => role.name === "Reading Doctor");
    const tokenInfo = this.authService.getToken(true);
    this.holterService.updateReadingDoctorFilter((tokenInfo.doctor_id && isReadingDoctor) ? this.readingDoctorID = tokenInfo.doctor_id : this.readingDoctorID = null);

    // notify the holter service of updated flags
    this.holterService.applyPrioritySort(this.prioritySort);

    let readingbatchMode = JSON.parse(localStorage.getItem('readingBatchMode'));
    readingbatchMode ? this.batchMode = (/true/i).test(readingbatchMode) : null;

    //Removing status filter change per port 287
    // var found_status_filter = localStorage.getItem(document.location.href+'Status');
    // if (found_status_filter){
    //   this.statusFilter = found_status_filter;
    // }
    this.statusFilterChanged();

    // roll callback
    const rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
      // $("td", row).unbind("click");
      $(".table-download-icon", row).bind("click", (event) => this.downloadFileHandler(event, data));
      $("td", row).not(".download-file-column").bind("click", () => this.rowClickHandler(data));
      return row;
    };

    const drawCallback: any = (settings) => {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
          const row = dtInstance.row(rowIdx);
          if (this.currentHolter && row.data()["holter:id"] == this.currentHolter.id) {
            (row as any).select();
          }
        });
      });
    };

    // setting size of pdfviewer
    this.getPdfViewerSpacing();

    // datatable options
    this.dtOptions = {
        autoWidth: false,
        responsive: true,
        lengthChange: false,
        select: true,
        pageLength: 50,
        dom: "Blfrtip",
        buttons: [],
        rowCallback: rowCallback,
        serverSide: true,
        processing: true,
        scrollY: window.innerHeight - 450,
        ajax: this.holterService.getAjaxFuncion(),
        drawCallback: drawCallback,
        order: [[ 0, "desc" ]],
        columnDefs: [{
          targets: 0,
          className: 'download-file-column'
        }],
        columns: [
          { data: "holter:scan_date" },
          {
            data: "patient:last_name",
            orderable: true,
            render: function ( data, type, row, meta ) {
              const last = row["patient:last_name"];
              const first = row["patient:first_name"];
              if (!last && !first) {
                return "";
              } else if (!last) {
                return first;
              } else if (!first) {
                return last;
              } else {
                return last + ", " + first;
              }
            },
          },
          { data: "holter:signed_off_at" },
          {
            data: "holter:uploaded_files",
            orderable: false,
            render: function ( data, type, row, meta ) {
              const uploadedfiles = JSON.parse(data);
              const hasPdf = uploadedfiles.includes('out_01.pdf');
              const hasPat = uploadedfiles.includes('pat.001');
              const hasBackup = !!row["holter:backed_up_at"];
              const expandedFlags = HolterService.expandFlags(row["holter:flags"]);
              const hasFinal = expandedFlags["holter:flag_verified"] && row["holter:state"] === "FAXING";
              const hasReq = !!row["holter:requisition_id"];
              const hasPreliminary = expandedFlags["holter:flag_verified"] && row["holter:state"] !== "FAXING";

              let icons = '';
              // icons += `<a data-toggle="tooltip" data-placement="left" title="Download Data"><i class="fa download-backup fa-database ` + (hasBackup ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Tracings"><i class="fa download-tracings fa-file-pdf-o ` + (hasPdf ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Requisition"><i class="fa download-req fa-file-o ` + (hasReq ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Preliminary"><i class="fa download-preliminary fa-file-text-o ` + (hasPreliminary ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Final Report"><i class="fa download-final fa-file-pdf-o ` + (hasFinal ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Batch"><i class="fa download-batch fa-file-text-o"></i></a>`;
              return icons;
            },
          },
          {
            data: "holter:state",
            orderable: false,
            render: function(data, type, row, meta) {
              if (!!row["holter:rejected_by"]) {
                return "Rejected";
              }
              if (row["holter:state"] === "FAXING") {
                return "Sent";
              }
              if (!!row["holter:report_prepared_at"]) {
                return "To be sent at " + moment((row["holter:report_prepared_at"])).add(1,'hours').format("HH:mm");
              }
              if (!!row["holter:flag_abnormal"]) {
                return "Priority To Read";
              }
              return "To Read";
            },
          },
          {
            data: "holter:flags",
            orderable: false,
            visible: false,
          },
          {
            data: "holter:requisition_id",
            orderable: false,
            visible: false,
          },
          { data: "holter:rejected_by", visible: false },
          { data: "holter:flag_abnormal", visible: false },
          { data: 'holter:state', visible: false },
          { data: 'holter:id', visible: false },
          { data: 'holter:backed_up_at', visible: false },
          { data: "holter:report_prepared_at", visible: false },
          { data: "patient:first_name", visible: false },
        ],
        language: {
          infoFiltered: ""
        }
    };

    this.formGroupReport = new FormGroup({
      LeadECG: new FormControl("", []),
      GeneralComment1: new FormControl("", []),
      GeneralComment2: new FormControl("", []),
      GeneralComment3: new FormControl("", []),
      GeneralComment4: new FormControl("", []),
      GeneralComment5: new FormControl("", []),
      SpecificComment1: new FormControl("", []),
      SpecificComment2: new FormControl("", []),
      SpecificComment3: new FormControl("", []),
      SpecificComment4: new FormControl("", []),
      SpecificComment5: new FormControl("", []),
      Summary1: new FormControl("", []),
      Summary2: new FormControl("", []),
      Summary3: new FormControl("", []),
      Summary4: new FormControl("", []),
      Summary5: new FormControl("", []),
      AdditionalComment1: new FormControl("", []),
      // AdditionalComment2: new FormControl("", []),
      // AdditionalComment3: new FormControl("", []),
      // AdditionalComment4: new FormControl("", []),
      // AdditionalComment5: new FormControl("", []),
    });
  }

  public ngOnDestroy(): void {
    // this.sendToFaxing();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private updateMultiSelected(that: any) {
    that.datatableElement.dtInstance.then((dtInstance: any) => {
      const selectedData = dtInstance.rows(".selected").data();
      that.multiSelected = selectedData.length > 1;
    });
  }

  private findNextHolterId() {
    const index = this.currentHolterList.map(element => element.id).indexOf(this.currentHolter.id);
    let filtered_list = this.currentHolterList.slice(index+1).filter((holter) => { return holter.prepared_at == null; })
    return (this.currentHolterList.length <= 0 || filtered_list.length <= 0) ? null : filtered_list[0];
  }

  private refreshHolterCountDisplay(){
    // finding the current list of holter entries
    setTimeout(() => {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        this.currentHolterList = []
        dtInstance.rows().every(rowIdx => {
          const row = dtInstance.rows().data()[rowIdx];
          this.currentHolterList.push({
            id: row["holter:id"],
            prepared_at: row["holter:report_prepared_at"],
          });
        });
      });
      setTimeout(() => {
        this.displayHolterIndex();
      }, 500);
    }, 800);
  }

  private displayHolterIndex(){
    // This works here because currentHolter is null on page reload
    // NOTE: REMOVED FOR NOW
    // this.displayHolterCount();
    // Compares the seen table list with the currentHolter ID
    this.holterService.getReadingTableCount(this.readingDoctorID)
        .then(data => {
          const index = this.currentHolterList.map(element => element.id).indexOf(this.currentHolter.id);
          if (index > -1 && this.currentHolter && (this.statusFilter === "All" || this.statusFilter === "To Read")) {
            this.holterIdx = this.currentHolter.patient_last_name + ", " + this.currentHolter.patient_first_name + " ("+ String(index+1+(this.currentDatatablePage*50)) +"/" + data + ")";
          }
          else if (this.statusFilter != "All" && this.statusFilter != "To Read"){
            this.holterIdx = "";
          }
        });
  }

  // NOTE: NOT IN USE BUT CAN COME IN HANDY LATER
  private displayHolterCount(){
    // This displays text for the holters completed and to be read based on the table information and API call
    if (this.datatableElement && (this.statusFilter === "All" || this.statusFilter === "To Read")){
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        let record_displayed = dtInstance.page.info().recordsDisplay;
        this.holterService.getReadingCount(this.readingDoctorID)
        .then(data => {
            this.holterCount = "Completed: " + data + " To Read:" + (record_displayed - data);
          });
      });
    }
    else {
      this.holterCount = "Completed: To be determined";
    }
  }

  public rowClickHandler(info: any) {
    setTimeout(() => this.updateMultiSelected(this), 200);
    this.loadingPdf = true;
    this.holterService.getHolter(info["holter:id"]).then(holter => {
      if (this.statusFilter == "All"){
        localStorage.setItem('currentHolter', String(holter.id));
      }
      this.currentHolter = holter;
      this.currentHolter.report_prepared_at ? this.isPrepared = true : this.isPrepared = false;
      this.loadPdf();
      this.editingReport = false;
      this.refreshHolterCountDisplay();

      // load the report preview if only 1 was selected
      setTimeout(() => {
        this.datatableElement.dtInstance.then((dtInstance: any) => {
          const selectedData = dtInstance.rows(".selected").data();
          if (selectedData.length == 1) {
            if (this.batchMode) {
              this.showReadingBatch();
            } else {
              this.showTracings();
            }
          }
        });
      }, 500);
    }).catch((error: any) => {
      this.loadingPdf = false;
    });
  }

  public forceLoadNext(holter_id: any) {
    this.loadingPdf = true;
    this.holterService.getHolter(holter_id).then(holter => {
      if (this.statusFilter == "All"){
        localStorage.setItem('currentHolter', String(holter_id.id));
      }
      this.currentHolter = holter;
      this.currentHolter.report_prepared_at ? this.isPrepared = true : this.isPrepared = false;
      this.loadPdf();
      this.editingReport = false;

      // load the report preview if only 1 was selected
      setTimeout(() => {
        if (this.batchMode) {
          this.showReadingBatch();
        } else {
          this.showTracings();
        }
      }, 500);
    }).catch((error: any) => {
      this.loadingPdf = false;
    });
  }

  public pdfPatConflictValue(controlName: string): string | null {
    if (!this.wasAutofilled) {
      return null;
    }
    const backendName = this.holterService.AUTOFILL_TO_FORM_MAPPING[controlName];
    if (backendName && backendName in this.pdfPatConflicts) {
      return this.pdfPatConflicts[backendName];
    }
    return null;
  }

  public autofillConflictValue(controlName: string): string | null {
    if (!this.wasAutofilled) {
      return null;
    }
    if (controlName in this.autofillConflicts) {
      return this.autofillConflicts[controlName];
    }
    return null;
  }

  public hasConflict(controlName: string): boolean {
    return this.pdfPatConflictValue(controlName) !== null || this.autofillConflictValue(controlName) !== null;
  }

  private convertStringToArray(toConvert: string): string[] {
    if (!toConvert || toConvert === "") {
      return [];
    }
    try {
      return JSON.parse(toConvert);
    } catch(e) {
      return [toConvert]
    }
  }

  private populateReport(leadEcg, general: string, specific: string, summary: string, additional: string): void {
    const generalComments = this.convertStringToArray(general);
    const specificComments = this.convertStringToArray(specific);
    const summaries = this.convertStringToArray(summary);
    const additionalComments = this.convertStringToArray(additional);

    this.formGroupReport.patchValue({
      LeadECG: leadEcg,
      GeneralComment1: generalComments.length >= 1 ? generalComments[0] : null,
      GeneralComment2: generalComments.length >= 2 ? generalComments[1] : null,
      GeneralComment3: generalComments.length >= 3 ? generalComments[2] : null,
      GeneralComment4: generalComments.length >= 4 ? generalComments[3] : null,
      GeneralComment5: generalComments.length >= 5 ? generalComments[4] : null,
      SpecificComment1: specificComments.length >= 1 ? specificComments[0] : null,
      SpecificComment2: specificComments.length >= 2 ? specificComments[1] : null,
      SpecificComment3: specificComments.length >= 3 ? specificComments[2] : null,
      SpecificComment4: specificComments.length >= 4 ? specificComments[3] : null,
      SpecificComment5: specificComments.length >= 5 ? specificComments[4] : null,
      Summary1: summaries.length >= 1 ? summaries[0] : null,
      Summary2: summaries.length >= 2 ? summaries[1] : null,
      Summary3: summaries.length >= 3 ? summaries[2] : null,
      Summary4: summaries.length >= 4 ? summaries[3] : null,
      Summary5: summaries.length >= 5 ? summaries[4] : null,
      AdditionalComment1: additionalComments.length >= 1 ? additionalComments[0] : null,
      // AdditionalComment2: additionalComments.length >= 2 ? additionalComments[1] : null,
      // AdditionalComment3: additionalComments.length >= 3 ? additionalComments[2] : null,
      // AdditionalComment4: additionalComments.length >= 4 ? additionalComments[3] : null,
      // AdditionalComment5: additionalComments.length >= 5 ? additionalComments[4] : null,
    });
    setTimeout(() => this.autoResizeTextAreas(), 500);
  }

  private setToScrollHeight(elementRef: ElementRef) {
    if (elementRef) {
      const e = elementRef.nativeElement;
      const styleHeight = parseInt(e.style.height.substring(0, e.style.height.length - 2));
      e.style.height = (e.scrollHeight > styleHeight || isNaN(styleHeight)) ? ((e.scrollHeight + 3) + 'px') : e.style.height;
    }
  }

  private autoResizeTextAreas() {
    if (!this.setToScrollHeight) return;
    this.setToScrollHeight(this.generalComment1Div);
    this.setToScrollHeight(this.generalComment2Div);
    this.setToScrollHeight(this.generalComment3Div);
    this.setToScrollHeight(this.generalComment4Div);
    this.setToScrollHeight(this.generalComment5Div);
    this.setToScrollHeight(this.specificComment1Div);
    this.setToScrollHeight(this.specificComment2Div);
    this.setToScrollHeight(this.specificComment3Div);
    this.setToScrollHeight(this.specificComment4Div);
    this.setToScrollHeight(this.specificComment5Div);
    this.setToScrollHeight(this.summary1Div);
    this.setToScrollHeight(this.summary2Div);
    this.setToScrollHeight(this.summary3Div);
    this.setToScrollHeight(this.summary4Div);
    this.setToScrollHeight(this.summary5Div);
    this.setToScrollHeight(this.additionalComment1Div);
    this.setToScrollHeight(this.additionalComment2Div);
    this.setToScrollHeight(this.additionalComment3Div);
    this.setToScrollHeight(this.additionalComment4Div);
    this.setToScrollHeight(this.additionalComment5Div);
  }

  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.currentHolter){
      if (event.ctrlKey == true && event.key == "Enter"){
        this.isPrepared ? this.send() : this.save(true);
      }
    }
  }

  // Moved logic to backend with scheduler per port290
  public sendToFaxing(){
    setTimeout(() => {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.rows().every(rowIdx => {
          const row = dtInstance.row(rowIdx);
          const time = row.data()["holter:report_prepared_at"]
          if (time) {
            const newTime = moment(time).add(1, 'hours');
            if (moment(new Date()).isAfter(newTime) ){
              const updateData = {
                state: "FAXING",
              };
              return this.holterService.updateHolter(row.data()["holter:id"], updateData)
            }
          }
        });
      });
    }, 1000);
  }

  public send(){
    const updateData = {
      state: "FAXING",
    };

    return this.holterService.updateHolter(this.currentHolter.id, updateData)
      .then(() => {
        this.wasSaved = true;
        this.isPrepared = false;
        this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.draw(false);
        });
        const nextHolterId = this.findNextHolterId();
        const index = this.currentHolterList.map(element => element.id).indexOf(this.currentHolter.id);
        this.currentHolterList.splice(index,1)
        this.tableCount = this.tableCount - 1;
        if (nextHolterId) {
          this.forceLoadNext(nextHolterId.id);
          setTimeout(() => {
            this.refreshHolterCountDisplay();
          }, 500);
        } else {
          this.currentHolter = null;
          this.pdfSrc = null;
          this.showPdfPreview = null;
        }
      });
  }

  public save(signoff: boolean) {

    // todo - remove rejected entries

    const medfluxData = JSON.parse(this.currentHolter.medflux_data);
    if (medfluxData) {
      medfluxData.leadecg = this.formGroupReport.get("LeadECG").value
    }

    let updateData = {
      medflux_data: medfluxData,
    };
    if (this.statusFilter == "Sent"){
      updateData["doctor_resubmit"] = true;
      updateData["form_changed_lines"] = JSON.stringify(this.formGroupChangedList);
      this.holterService.addNotes(this.currentHolter.id, "Reading doctor resubmitted final report", null);
    }

    if (this.editingReport) {
      updateData["general_comments"] = JSON.stringify([
        this.formGroupReport.get("GeneralComment1").value,
        this.formGroupReport.get("GeneralComment2").value,
        this.formGroupReport.get("GeneralComment3").value,
        this.formGroupReport.get("GeneralComment4").value,
        this.formGroupReport.get("GeneralComment5").value,
      ]);
      updateData["specific_comments"] = JSON.stringify([
        this.formGroupReport.get("SpecificComment1").value,
        this.formGroupReport.get("SpecificComment2").value,
        this.formGroupReport.get("SpecificComment3").value,
        this.formGroupReport.get("SpecificComment4").value,
        this.formGroupReport.get("SpecificComment5").value,
      ]);
      updateData["summary"] = JSON.stringify([
        this.formGroupReport.get("Summary1").value,
        this.formGroupReport.get("Summary2").value,
        this.formGroupReport.get("Summary3").value,
        this.formGroupReport.get("Summary4").value,
        this.formGroupReport.get("Summary5").value,
      ]);
      updateData["additional_comments"] = JSON.stringify([
        this.formGroupReport.get("AdditionalComment1").value,
        // this.formGroupReport.get("AdditionalComment2").value,
        // this.formGroupReport.get("AdditionalComment3").value,
        // this.formGroupReport.get("AdditionalComment4").value,
        // this.formGroupReport.get("AdditionalComment5").value,
      ]);
    }

    if (signoff) {
      this.holterService.prepareHolter(this.currentHolter.id);
      this.holterService.addNotes(this.currentHolter.id, "Reading doctor prepared final report", null);
    }

    return this.holterService.updateHolter(this.currentHolter.id, updateData)
      .then(() => {
        this.wasSaved = true;
        this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.draw(false);
        });
        const nextHolterId = this.findNextHolterId();
        // regular save, not a signoff
        if (!signoff) {
          this.toggleReport();
          return;
        }
        if (nextHolterId) {
          this.forceLoadNext(nextHolterId.id);
          setTimeout(() => {
            this.refreshHolterCountDisplay();
          }, 500);
        } else {
          this.currentHolter = null;
          this.pdfSrc = null;
          this.showPdfPreview = null;
        }
      });
  }

  public buildHIN(holter: Holter): string | null {
    if (!holter || !holter.patient_health_card)
      return null;

    if (holter.patient_health_card_code) {
      return holter.patient_health_card + "-" + holter.patient_health_card_code;
    } else {
      return holter.patient_health_card;
    }
  }

  public getMedfluxData(holter: Holter, field: string): string | null {
    if (!holter || !holter.medflux_data)
      return null;

    const parsed = JSON.parse(holter.medflux_data);
    return parsed[field];
  }

  private downloadPDF(url: any, holter: Holter, fileType: string, extension: string) {
    this.holterService.downloadFile(url)
      .subscribe(blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = holter.patient_last_name + "_" + holter.patient_first_name + "_" + holter.hookup_date + "_" + fileType + "." + extension;
        a.click();
        URL.revokeObjectURL(objectUrl);
      });
  }

  public downloadFileHandler(event: any, info: any) {
    setTimeout(() => {
      $("body, .table-download-icon, tr").css("cursor", "wait");
      return this.holterService.getHolter(parseInt(info["holter:id"]))
      .then((holter: Holter | null) => {

        // no holter found with matching id
        if (!holter) {
          return;
        }

        // determine which download was clicked
        const classNames = event.target.className;
        const fileNamePrefix = holter.patient_last_name + "_" + holter.patient_first_name + "_" + holter.hookup_date + "_";
        if (classNames.includes('download-tracings')) {
          return this.holterService.getTracings(holter.id, "flattened").then(url => {
            this.downloadPDF(url, holter, 'tracing', 'pdf');
          });
        } else if (classNames.includes('download-backup')) {
          return this.holterService.getZipPresignedUrl(holter.id).then((url) => {
            // disabled donwloading flash.dat PORT-146
            // this.downloadPDF(url, holter, '', '');
          });
        } else if (classNames.includes('download-preliminary')) {
          return this.holterService.getMonitorReport(holter.id)
          .then((base64Data: string) => {
            download(HolterService.base64ToArrayBuffer(base64Data), fileNamePrefix + "prelim", "application/pdf");
          })
        } else if (classNames.includes('download-final')) {
          return this.holterService.getMonitorReport(holter.id)
          .then((base64Data: string) => {
            download(HolterService.base64ToArrayBuffer(base64Data), fileNamePrefix + "final", "application/pdf");
          })
        } else if (classNames.includes('download-req')) {
          return this.holterService.getRequisition(holter.id).then((url) => {
            this.downloadPDF(url, holter, 'req', 'pdf');
          });
        } else if (classNames.includes('download-batch')) {
          this.holterService.getReadingBatchReport(holter.id, "flattened").then(url => {
            this.downloadPDF(url, holter, "batch", "pdf")
          });
        }
        return;
      })
      .then(() => {
        $("body, .table-download-icon, tr").css("cursor", "auto");
      })
      .catch(() => {
        $("body, .table-download-icon, tr").css("cursor", "auto");
      });
    }, 500);
  }

  public loadPdf(): Promise<any> {
    if (this.isSkipToReading) {
      return;
    }
    return this.holterService.getMonitorReport(this.currentHolter.id)
      .then((base64Data: string) => {
        this.pdfSrc = HolterService.base64ToArrayBuffer(base64Data);
        this.pageNumber = 0;
        setTimeout(() => {
            this.loadingPdf = false;
            this.editingReport = false;
        }, 200);
      })
  }

  public flipPage(flipFoward: boolean){
    if (!this.totalPages) {
      return;
    }
    if (flipFoward && (this.pageNumber < this.totalPages)) {
      this.pageNumber = this.pageNumber + 1;
    } else if (!flipFoward && (this.pageNumber > 1)) {
      this.pageNumber = this.pageNumber - 1;
    }
  }

  public afterLoadComplete(pdf): void {
    this.totalPages = pdf.numPages;
  }

  public toggleReport() {
    this.destroyFormObservables();
    if (this.editingReport) {
      this.loadPdf();
    } else {
      this.wasSaved = false;
      this.editingReport = true;
      this.formGroupReport.reset();
      this.holterService.getHolter(this.currentHolter.id).then(holter => {
        const leadEcg = this.getMedfluxData(holter, 'leadecg');
        setTimeout(() => {
          this.populateReport(leadEcg, holter.general_comments, holter.specific_comments, holter.summary, holter.additional_comments);
          if (this.statusFilter == "Sent"){
            this.setupFormObservables();
            if (holter.report_revision_lines){
              this.formGroupChangedList = JSON.parse(holter.report_revision_lines);
            }
            else {
              this.formGroupChangedList = {
              "general":[],
              "diary":[],
              "summary":[],
              "additional":[],
              };
            }
          }
        }, 400);
      });
    }
  }

  private destroyFormObservables(){
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setupFormObservables(){
    this.destroy$ = new Subject();
    this.formGroupReport.get("GeneralComment1")?.valueChanges.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroy$))
    .subscribe(event => this.updateChangedLine(event, this, '0', null, null, null));
    this.formGroupReport.get("GeneralComment2")?.valueChanges.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroy$))
    .subscribe(event => this.updateChangedLine(event, this, '1', null, null, null));
    this.formGroupReport.get("GeneralComment3")?.valueChanges.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroy$))
    .subscribe(event => this.updateChangedLine(event, this, '2', null, null, null));
    this.formGroupReport.get("GeneralComment4")?.valueChanges.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroy$))
    .subscribe(event => this.updateChangedLine(event, this, '3', null, null, null));
    this.formGroupReport.get("GeneralComment5")?.valueChanges.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroy$))
    .subscribe(event => this.updateChangedLine(event, this, '4', null, null, null));

    this.formGroupReport.get("SpecificComment1")?.valueChanges.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroy$))
    .subscribe(event => this.updateChangedLine(event, this, null, '0', null, null));
    this.formGroupReport.get("SpecificComment2")?.valueChanges.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroy$))
    .subscribe(event => this.updateChangedLine(event, this, null, '1', null, null));
    this.formGroupReport.get("SpecificComment3")?.valueChanges.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroy$))
    .subscribe(event => this.updateChangedLine(event, this, null, '2', null, null));
    this.formGroupReport.get("SpecificComment4")?.valueChanges.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroy$))
    .subscribe(event => this.updateChangedLine(event, this, null, '3', null, null));
    this.formGroupReport.get("SpecificComment5")?.valueChanges.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroy$))
    .subscribe(event => this.updateChangedLine(event, this, null, '4', null, null));

    this.formGroupReport.get("Summary1")?.valueChanges.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroy$))
    .subscribe(event => this.updateChangedLine(event, this, null, null, '0', null));
    this.formGroupReport.get("Summary2")?.valueChanges.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroy$))
    .subscribe(event => this.updateChangedLine(event, this, null, null, '1', null));
    this.formGroupReport.get("Summary3")?.valueChanges.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroy$))
    .subscribe(event => this.updateChangedLine(event, this, null, null, '2', null));
    this.formGroupReport.get("Summary4")?.valueChanges.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroy$))
    .subscribe(event => this.updateChangedLine(event, this, null, null, '3', null));
    this.formGroupReport.get("Summary5")?.valueChanges.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroy$))
    .subscribe(event => this.updateChangedLine(event, this, null, null, '4', null));

    this.formGroupReport.get("AdditionalComment1")?.valueChanges.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroy$))
    .subscribe(event => this.updateChangedLine(event, this, null, null, null, '0'));
  }

  private updateChangedLine(event, that, general, diary, summary, additional) {
    if (general){
      if (!that.formGroupChangedList["general"].includes(general)){
        that.formGroupChangedList["general"].push(general);
      }
    }
    if (diary){
      if (!that.formGroupChangedList["diary"].includes(diary)){
        that.formGroupChangedList["diary"].push(diary);
      }
    }
    if (summary){
      if (!that.formGroupChangedList["summary"].includes(summary)){
        that.formGroupChangedList["summary"].push(summary);
      }
    }
    if (additional){
      if (!that.formGroupChangedList["additional"].includes(additional)){
        that.formGroupChangedList["additional"].push(additional);
      }
    }
  }

  public showTracings() {
    this.holterService.getTracings(this.currentHolter.id, "annotated").then(url => {
      this.pdfSrcLeft = url;
      this.showPdfPreview = "tracings";
    });
  }

  public showRequisition() {
    this.holterService.getRequisition(this.currentHolter.id).then(url => {
      this.pdfSrcLeft = url;
      this.showPdfPreview = "requisition";
    });
  }

  public closeTracings() {
    this.showPdfPreview = null;
    // this.sendToFaxing();
    let that = this;

    setTimeout(() => {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        that.refreshHolterCountDisplay();
        dtInstance.page(this.currentDatatablePage).draw(false);
        $("#search-bar").on("keyup change", function () {
          const search_string = this["value"].replace(',', '')
          setTimeout(() => {
            const search_string = this["value"].replace(',', '')
            if (dtInstance.search() !== search_string) {
              dtInstance.search(search_string).draw();
            }
          }, 1000);
        });
        $('#reading-table').on('page.dt', function(){
          // that.sendToFaxing();
          var info = dtInstance.page.info();
          that.currentDatatablePage = info.page;
          if (that.statusFilter == "All"){
            localStorage.setItem('currentPage', String(that.currentDatatablePage));
          }
          that.refreshHolterCountDisplay();
          // NOTE: REMOVED FOR NOW
          // if (!that.currentHolter) {
          //   that.displayHolterCount();
          // }
        });
      });
    }, 1000);
  }

  public openRejectionModal() {
    this.rejectResult.description = "";
    const modalRef = this.modalService.open(ModalRejectionComponent, {size:'md'});
    modalRef.componentInstance.rejectResult = this.rejectResult;
    modalRef.result.then(result => {
      if (result.description) {
        return this.holterService.addNotes(this.currentHolter.id, result.description, null).then(() => this.rejectHolter());
      } else {
        return this.rejectHolter();
      }
    });
  }

  public rejectHolter() {
    this.holterService.rejectHolter(this.currentHolter.id).then(() => {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.draw(false);
      });
      const nextHolterId = this.findNextHolterId();
      const index = this.currentHolterList.map(element => element.id).indexOf(this.currentHolter.id);
      this.currentHolterList.splice(index,1)
      this.tableCount = this.tableCount - 1;
      if (nextHolterId) {
        this.forceLoadNext(nextHolterId.id);
        setTimeout(() => {
          this.refreshHolterCountDisplay();
        }, 500);
      } else {
        this.currentHolter = null;
        this.pdfSrc = null;
        this.showPdfPreview = null;
      }
    });
  }

  private getPdfViewerSpacing(){
    const pdfsize = JSON.parse(localStorage.getItem('readingSpace'));
    pdfsize ? this.pdfSizeLeft = pdfsize : this.pdfSizeLeft = 50;
    this.pdfSizeRight = 100 - this.pdfSizeLeft;
  }

  public onDragEnd(e: { gutterNum: any; sizes: Array<any> }) {
    localStorage.setItem('readingSpace', e.sizes[0]);
  }

  public showReadingBatch() {
    return this.holterService.getReadingBatchReport(this.currentHolter.id, "original")
      .then(url => {
        this.pdfSrcLeft = url;
        this.showPdfPreview = "batch";
      });
  }

  public saveBatchMode() {
    localStorage.setItem('readingBatchMode', this.batchMode.toString());
  }

}
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-rejection',
  templateUrl: './modal-rejection.component.html',
  styleUrls: ['./modal-rejection.component.scss']
})
export class ModalRejectionComponent implements OnInit {
  @Input() public rejectResult;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
      }
  passBack() {this.activeModal.close(this.rejectResult);}

}

<div class="login">
  <div class="text-center">
    <div class="login_title">
      <img src="assets/logo.png" class="img-login-logo"> Cardio Study
    </div>
  </div>
  <br/>
  <div>
    <form class="form-vertical form-label-left input_mask" [formGroup]="formGroup">
      <div class="offset-md-4 col-md-4 offset-sm-4 col-sm-4 col-xs-12 form-group required">
        <label>Email</label>
        <input type="text" class="form-control" formControlName="Email">
      </div>
      <div class="offset-md-4 col-md-4 offset-sm-4 col-sm-4 col-xs-12 form-group required">
        <label>Password</label>
        <input type="password" class="form-control" formControlName="Password">
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 text-center error-message" [hidden]="errorMessage === ''">{{errorMessage}}</div>
      <div class="col-md-12 col-sm-12 col-xs-12 form-group text-center">
        <button class="btn btn-primary btn-review" (click)="login()" [disabled]="!formGroup.valid">Login</button>
      </div>
    </form>
  </div>
</div>

<div>
    <div class="row sub-main">

      <div class="page-title">
        <div class="title_left">
          <h3>Debug - Spark Device</h3>
        </div>
      </div>
      <div class="clearfix"></div>

      <div class="x_panel">
        <div class="x_content">

          <div class="row split-container">

            <as-split direction="horizontal">
              <as-split-area [size]="50">
                <div class="col">
                  <form class="form-horizontal form-label-left" [formGroup]="branchFormGroup">
                    <div class="row">
                      <div class="col form-group">
                        <label>Search</label>
                        <input type="text" formControlName="Search" [(ngModel)]="search" class="form-control search-bar" placeholder="Start typing to search" id="search_bar">
                      </div>
                      <div class="col form-group">
                        <label>Types of lists</label>
                        <select class="form-control" data-live-search="true" formControlName="BranchFilter" (change)="applyBranchFilter()">
                          <option value="">All</option>
                          <option value="ACTIVE">Active</option>
                          <option value="NOT_ACTIVE">Not Active</option>
                        </select>
                      </div>
                    </div>
                  </form>
                  <table datatable [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover" id="branch-table">
                    <thead>
                      <tr>
                        <th>IP</th>
                        <th>Mac</th>
                        <th>GUID</th>
                        <th>Last Updated</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </as-split-area>
              <as-split-area [size]="50">
                <div class="col info-panel" >
                  <form class="form-horizontal form-label-left" [formGroup]="formGroup">
                    <div class="row">
                      <div class="col">
                        <!-- <div class="form-group form-display row">
                          <div class="col-3 form-group">
                            <label>Types of lists</label>
                            <select class="form-control" data-live-search="true" formControlName="FocusFilter" (change)="applyFocusFilter()">
                              <option>All</option>
                              <option>Pat</option>
                              <option>PDF</option>
                            </select>
                          </div>
                          <div class="col-3 form-group">
                            <label>Date From</label>
                            <input type="text" class="form-control" placeholder="YYYY/MM/DD" formControlName="DateFrom">
                          </div>
                          <div class="col-3 form-group">
                            <label>Date To</label>
                            <input type="text" class="form-control" placeholder="YYYY/MM/DD" formControlName="DateTo">
                          </div>
                          <div class="col-3 text-center">
                            <button class="btn btn-primary btn-review" type="button" (click)="applyFocusFilter()">Apply</button>
                          </div>
                        </div> -->
                      </div>
                    </div>

                  </form>
                  <table datatable [dtOptions]="dtOptions2" class="table table-striped row-bordered datatable-buttons hover" id="focus-table">
                    <thead>
                      <tr>
                        <th>Upload Computer</th>
                        <th>Upload Computer Path</th>
                      </tr>
                    </thead>
                  </table>
                  <!-- <table datatable [dtOptions]="dtOptions2" class="table table-striped row-bordered datatable-buttons hover" id="focus-table">
                    <thead>
                      <tr>
                        <th>State</th>
                        <th>Patient Name</th>
                        <th>File</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                      </tr>
                    </thead>
                  </table> -->
                </div>
              </as-split-area>
            </as-split>
          </div>
        </div>
      </div>
    </div>

    <!--
    <div>
      <div class="row sub-footer">
        <div class="col-12">

        </div>
      </div>
    </div>
    -->
      <!-- this is for contextMenu -->
  </div>
export class Patient {
  public updated_at: number | null = null;
  public last_name: string | null = null;
  public first_name: string | null = null;
  public health_card: string | null = null;
  public health_card_code: string | null = null;
  public gender: string | null = null;
  public birthdate: number | null = null;
  public height: number | null = null;
  public weight: number | null = null;
  public id: number | null = null;
}
<div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>Fax</h3>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="x_panel">
          <div class="x_content">
            <div class="offset-3 col-6">
              <input type="text" class="form-control search-bar" placeholder="Start typing to search" id="search-bar">
            </div>
            <dropzone [config]="dropzoneConfig" [message]="'Click or drag images here to upload'"
              (error)="onUploadError($event)" (success)="onUploadSuccess($event)"></dropzone>
            <br/>
            <table *ngIf="showTable" datatable [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover">
              <thead>
                <tr>
                  <th>File Name</th>
                  <th>Fax Number</th>
                  <th>Status</th>
                  <th>Received</th>
                  <th>Scheduled For</th>
                  <th>Success On</th>
                  <th>Attempts</th>
                  <th>Owner</th>
                  <th>Id</th>
                </tr>
              </thead>
            </table>
            <div class="row text-center">
              <div class="col">
                <button class="btn btn-primary btn-review" type="button" (click)="bulkVerify()">Bulk Verify</button>
                <button class="btn btn-primary btn-review" type="button" (click)="bulkForceFax()">Bulk Force Fax</button>
                <div class="col form-answer success-message" [hidden]="bulkMessage === ''">{{bulkMessage}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" [hidden]="fax.id == null">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="x_panel">
          <div class="x_content">
            <br />

            <form class="form-horizontal form-label-left input_mask row">


              <div class="col form-group has-feedback preview-panel">
                <div [hidden]="loadingPdf">
                  <pdf-viewer [src]="pdfSrc" [render-text]="false" [original-size]="true" [fit-to-page]="true" [(page)]="previewPage" style="display: block;"></pdf-viewer>
                </div>
                <div class="row text-center">
                  <div class="col" [hidden]="!loadingPdf">
                    <br>
                    <button class="btn btn-primary btn-review" type="button" (click)="loadPdf()" ><i class="fa fa-file-pdf-o"></i>  Preview File</button>
                  </div>
                </div>
              </div>

              <div class="col form-group has-feedback">
                <form class="form-horizontal form-label-left">
                  <div class="form-group form-display row">
                    <label class="control-label no-padding col-3">Status </label>
                    <div class="col form-answer">{{fax.status}}</div>
                  </div>
                  <div class="form-group form-display row" [hidden]="!fax.success_at">
                    <label class="control-label no-padding col-3">Faxed On </label>
                    <div class="col form-answer">{{fax.success_at}}</div>
                  </div>
                  <div class="form-group form-display row" [hidden]="!fax.attempt_at">
                    <label class="control-label no-padding col-3">Attempt On </label>
                    <div class="col form-answer">{{fax.attempt_at}}</div>
                  </div>
                  <div class="form-group form-display row" [hidden]="!fax.cancelled_at">
                    <label class="control-label no-padding col-3">Cancelled On </label>
                    <div class="col form-answer">{{fax.cancelled_at}}</div>
                  </div>
                  <div class="form-group form-display row">
                    <label class="control-label no-padding col-3">File </label>
                    <div class="col form-answer">{{fax.file_name}}</div>
                  </div>
                  <div class="form-group form-display row">
                    <label class="control-label no-padding col-3">Received </label>
                    <div class="col form-answer">{{fax.received_at}}</div>
                  </div>
                  <div class="form-group form-display row">
                    <label class="control-label no-padding col-3">Fax Number </label>
                    <div class="col form-answer" [hidden]="fax.status == 'Awaiting Verification'">{{fax.fax_number}}</div>
                    <div class="col form-answer" [hidden]="fax.status != 'Awaiting Verification'">
                      <input type="text" class="form-control" [(ngModel)]="fax.fax_number" name="fax_number" phoneInput>
                    </div>
                  </div>
                  <div class="form-group form-display row">
                    <label class="control-label no-padding col-3">Doctor </label>
                    <div class="col form-answer" [hidden]="fax.status == 'Awaiting Verification'">{{fax.doctor}}</div>
                    <div class="col form-answer" [hidden]="fax.status != 'Awaiting Verification'">
                      <input type="text" class="form-control" [(ngModel)]="fax.doctor" name="doctor">
                      <div class="col form-answer error-message" [hidden]="errorMessage === ''">{{errorMessage}}</div>
                    </div>
                  </div>
                  <br>
                  <div class="form-group form-display row" [hidden]="fax.status == 'Awaiting Verification' || !fax.attempt_log">
                    <label class="control-label no-padding col-3">Attempts </label>
                    <div class="col form-answer">
                      <div *ngFor="let attempt of attempts()">
                        <p>{{attempt.formatTime}} - {{attempt.status}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="form-group form-display row">

                  </div>
                  <div class="form-group form-display row">
                    <div class="text-left">
                      <button class="btn btn-primary btn-review" type="button" [hidden]="fax.status != 'Awaiting Verification'" (click)="verified()"><i class="fa fa-check"></i>  Submit</button>
                      <button class="btn btn-primary btn-review" type="button" [hidden]="fax.status != 'Scheduled'" (click)="fax_immediately()"><i class="fa fa-upload"></i>  Fax Immediately</button>
                      <button class="btn btn-primary btn-review" type="button" [hidden]="fax.status != 'Failed'" (click)="retry_fax()"><i class="fa fa-repeat"></i>  Force Retry</button>
                      <button class="btn btn-primary btn-review" type="button" [hidden]="fax.status == 'Cancelled' || fax.status == 'Faxed'" (click)="cancel_fax()"><i class="fa fa-times"></i>  Cancel</button>
                    </div>
                  </div>
                </form>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>


  </div>

<div>
  <div class="row sub-main">

    <div class="page-title" *ngIf="abpm$ | async as abpm">
      <div class="title_left">
        <h3>{{title}}</h3>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="x_panel">
      <div class="x_content">

        <br><br>

        <div class="row" [hidden]="false">
          <div class="col-12 md-width">

            <!-- <h4>Time Graph</h4> -->
            <plotly-plot id="graph" class="graphical-summary" [data]="graph.data" [layout]="graph.layout" [useResizeHandler]="true"></plotly-plot>

          </div>
        </div>

      </div>
    </div>
  </div>

  <div>
    <div class="row sub-footer">
      <div class="col-12">
        <button class="btn btn-primary btn-review" type="button" (click)="navigateTo('/abpm')"><i class="fa fa-times"></i> Cancel</button>
      </div>
    </div>
  </div>
</div>
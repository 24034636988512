import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';

import * as moment from "moment";
import * as download from "downloadjs";
import * as JSZip from "jszip";
import * as JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalRejectionComponent } from '../modal-rejection/modal-rejection.component';
import { ModalStaffNotesComponent } from '../modal-staff-notes/modal-staff-notes.component';
import { ModalReservedFaxingResubmitComponent } from '../modal-reserved-faxing-resubmit/modal-reserved-faxing-resubmit.component';
import { ContextMenuComponent } from "ngx-contextmenu";

import { formatServerTime, formatLocalTime, formatNotesMultiple  } from "../../utils/format";

import { Holter } from "../../models/holter.model";
import { DXCODE_OPTIONS } from "../../config/constants";
import { REQ_OPTIONS } from "../../config/constants";

import { EmployeeService } from "../../providers/employee.service";
import { HolterService } from "../../providers/holter.service";
import { AuthService } from "../../providers/auth.service";
import { ClinicService } from "../../providers/clinic.service";
import { PhysicianService } from "../../providers/physician.service";
import { PatientService } from "../../providers/patient.service";
import { MedtraceService } from "../../providers/medtrace.service";
import { Medtrace } from 'src/app/models/medtrace.model';

@Component({
  selector: 'app-home',
  templateUrl: './holter-reserved.component.html',
  styleUrls: ['./holter-reserved.component.scss']
})
export class HolterReservedComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;

  @ViewChild(ContextMenuComponent)
  public suggestedMenu: ContextMenuComponent;

  @ViewChild('dxcodeMenu')
  public otherMenu: ContextMenuComponent;

  public dtOptions: any = {};

  public multiSelected: boolean = false;
  public showReport: boolean = false;
  public showPdfPreview: string = null;

  public pdfSrc: any = null;

  public formGroupMedflux: FormGroup;
  public formGroupReport: FormGroup;

  public currentHolter: Holter;

  public searchDatabases: any[] = [];

  public wasAutofilled: boolean = false;
  public pdfPatConflicts: any = {};
  public autofillConflicts: any = {};

  public statusFilter: string = "All";
  public rejectedSort: boolean = true;
  public reorderPage: number = null;
  public reservedFilter: boolean = false;
  public startDateFilter: string | null = null;
  public endDateFilter: string | null = null;

  public readingDoctorOptions: any[] = [];
  public downloadProgress = null;

  public rejectResult = {description: ''}

  public currentPreviewHolter: any = null;
  public allHolters: any[] = [];

  public errorStrings: any = {};

  public flags: any = {
    flag_verified: null,
    flag_pacemaker_inquiry: null,
    flag_no_data: null,
    flag_no_req: null,
    flag_no_backup: null,
    flag_no_tracings: null,
    flag_invalid_referring: null,
    flag_invalid_healthcard: null,
    flag_invalid_dob: null,
    flag_need_repair: null,
  };

  public emailFilter: string | null = null;

  private readonly DEBOUNCE_SEC: number = 1;

  public readonly PRECANNED_PHRASES: string[] = [
    "Patient triggered events with corresponding symptoms noted: 0 event(s).",
    "Patient triggered events with no corresponding symptoms:    0 event(s).",
    "Symptoms noted with no patient triggered event:             0 event(s).",
    "The patient's triggered events corresponded to ",
    "Note: Only xx:xx hours of data available for analysis during a xx day recording period.",
    "Note: Events noted on the diary were routine activities.",
    "Note: Other events noted on the diary were out of recording range, cannot be documented.",
    "Note: Events were noted on the diary without specific recording time, cannot be documented.",
    "Note: Poor data quality and significant artifacts.",
    "Note: Diary was not returned.",
  ];

  public DXCODE_OPTIONS = DXCODE_OPTIONS;
  public REQ_OPTIONS = REQ_OPTIONS;

  @ViewChild("generalComment1Div") generalComment1Div: ElementRef;
  @ViewChild("generalComment2Div") generalComment2Div: ElementRef;
  @ViewChild("generalComment3Div") generalComment3Div: ElementRef;
  @ViewChild("generalComment4Div") generalComment4Div: ElementRef;
  @ViewChild("generalComment5Div") generalComment5Div: ElementRef;
  @ViewChild("specificComment1Div") specificComment1Div: ElementRef;
  @ViewChild("specificComment2Div") specificComment2Div: ElementRef;
  @ViewChild("specificComment3Div") specificComment3Div: ElementRef;
  @ViewChild("specificComment4Div") specificComment4Div: ElementRef;
  @ViewChild("specificComment5Div") specificComment5Div: ElementRef;
  @ViewChild("summary1Div") summary1Div: ElementRef;
  @ViewChild("summary2Div") summary2Div: ElementRef;
  @ViewChild("summary3Div") summary3Div: ElementRef;
  @ViewChild("summary4Div") summary4Div: ElementRef;
  @ViewChild("summary5Div") summary5Div: ElementRef;
  @ViewChild("additionalComment1Div") additionalComment1Div: ElementRef;
  // @ViewChild("additionalComment2Div") additionalComment2Div: ElementRef;
  // @ViewChild("additionalComment3Div") additionalComment3Div: ElementRef;
  // @ViewChild("additionalComment4Div") additionalComment4Div: ElementRef;
  // @ViewChild("additionalComment5Div") additionalComment5Div: ElementRef;

  @ViewChild("indications") indications: ElementRef;

  public updateFlags(flagName, value) {
    this.flags[flagName] = value;
    this.holterService.updateFlags(this.flags);
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  public setEmailFilter() {
    if (this.emailFilter) {
      this.emailFilter = null;

    } else {
      const token = this.authService.getToken(true);
      this.emailFilter = token.email;
    }

    this.holterService.updateEmailFilter(this.emailFilter);
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  // important variable that changes if the status column position changes
  private readonly STATUS_COLUMN_INDEX: number = 0;

  public ngAfterViewInit(): void {

    const that = this;
    document.addEventListener("click",  function(e){
      if (!e.target) {
        return;
      }
      const className = (e.target as Element).className;
      if(className == "as-split-gutter-icon"){
        window.dispatchEvent(new Event('resize'));
        that.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.draw();
        });
      }
    });

    // set up the callbacks
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      // on search term callback
      $("#search-bar").on("keyup change", function () {
        setTimeout(() => {
          const search_string = this["value"].replace(',', '')
          if (dtInstance.search() !== search_string) {
            dtInstance.search(search_string).draw();
          }
        }, 1000);
      });
    });

    // focus on the search bar right away after load
    $("#search-bar").focus();
  }

  public ngOnInit() {}

  constructor(
    private physicianService: PhysicianService,
    private clinicService: ClinicService,
    private holterService: HolterService,
    private authService: AuthService,
    private patientService: PatientService,
    private medtraceService: MedtraceService,
    private modalService: NgbModal,
  ) {

    // notify the holter service of updated flags
    this.holterService.clearFilters();
    this.holterService.applyRejectedSort(true);
    this.holterService.updateFlags(this.flags);
    this.statusFilterChanged();

    // roll callback
    const rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
      // $("td", row).unbind("click");
      $(".table-download-icon", row).bind("click", (event) => this.downloadFileHandler(event, data));
      $("td", row).not(".download-file-column").bind("click", () => this.rowClickHandler(data));
      return row;
    };

    const drawCallback: any = (settings) => {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
          const row = dtInstance.row(rowIdx);

          if (this.currentHolter && row.data()["holter:id"] == this.currentHolter.id) {
            (row as any).select();
          }

          const isRejected = !!row.data()['holter:rejected_by'];
          if (isRejected) {
            $(row.node()).addClass('rejected-entry');
          }

          const isSubmitted = row.data()['holter:state'] === "READING";
          if (isSubmitted) {
            $(row.node()).addClass('submitted-entry');
          }
        });
      });
    };

    // datatable options
    this.dtOptions = {
        autoWidth: false,
        responsive: true,
        lengthChange: false,
        select: true,
        pageLength: 50,
        dom: "Blfrtip",
        buttons: [],
        rowCallback: rowCallback,
        serverSide: true,
        processing: true,
        scrollY: window.innerHeight - 560,
        ajax: this.holterService.getAjaxFuncion(),
        drawCallback: drawCallback,
        order: [[ 0, "desc" ]],
        columnDefs: [{
          targets: 0,
          className: 'download-file-column'
        }],
        columns: [
          { data: "holter:scan_date" },
          { data: "patient:last_name" },
          { data: "patient:first_name" },
          {
            data: "holter:uploaded_files",
            orderable: false,
            render: function ( data, type, row, meta ) {
              const uploadedfiles = JSON.parse(data);
              const hasPdf = uploadedfiles.includes('out_01.pdf');
              const hasPat = uploadedfiles.includes('pat.001');
              const hasBackup = !!row["holter:backed_up_at"];
              const expandedFlags = HolterService.expandFlags(row["holter:flags"]);
              const hasFinal = expandedFlags["holter:flag_verified"] && row["holter:state"] === "FAXING";
              const hasReq = !!row["holter:requisition_id"];
              const hasPreliminary = expandedFlags["holter:flag_verified"] && row["holter:state"] !== "FAXING";

              let icons = '';
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Data"><i class="fa download-backup fa-database ` + (hasBackup ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Tracings"><i class="fa download-tracings fa-file-pdf-o ` + (hasPdf ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Requisition"><i class="fa download-req fa-file-o ` + (hasReq ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Preliminary"><i class="fa download-preliminary fa-file-text-o ` + (hasPreliminary ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Final Report"><i class="fa download-final fa-file-pdf-o ` + (hasFinal ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              return icons;
            },
          },
          {
            data: "holter:state",
            orderable: false,
            render: function(data, type, row, meta) {
              if (!!row["holter:rejected_by"] && row["holter:state"] === "RESERVED") {
                return "Editor Rejected";
              }
              if (!row["holter:rejected_by"] && (row["holter:state"] === "READING" || row["holter:state"] === "FAXING")) {
                return "Sent";
              }
              if (!!row["holter:rejected_by"] && row["holter:state"] === "READING") {
                return "Doctor Rejected";
              }
              return "To Process";
            },
          },
          {
            data: "holter:requisition_id",
            orderable: false,
            visible: false,
          },
          {
            data: "holter:flags",
            orderable: false,
            visible: false,
          },
          { data: 'holter:rejected_by', visible: false },
          { data: 'holter:state', visible: false },
          { data: 'holter:id', visible: false },
          { data: 'holter:backed_up_at', visible: false },
        ],
        language: {
          infoFiltered: ""
        }
    };

    this.formGroupMedflux = new FormGroup({
      ReferringDoctorName: new FormControl("", [ Validators.required, ]),
      FirstName: new FormControl("", [ Validators.required, ]),
      LastName: new FormControl("", [ Validators.required, ]),
      HealthCard: new FormControl("", [ Validators.required, Validators.pattern(/^\d+$/) ]),
      HealthCardVersion: new FormControl("", [ ]),
      Birthdate: new FormControl("", [ Validators.required, Validators.pattern(/^\d{4}\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/) ]),
      Gender: new FormControl("", [ Validators.required, ]),
      StaffNotes: new FormControl("", []),
      Clinic: new FormControl("", [ Validators.required, ]),
      Duration: new FormControl("", []),
      ReadingDoctorName: new FormControl("", []),
      TestDate: new FormControl("", [ Validators.required, Validators.pattern(/^\d{4}\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/) ]),
      Editor: new FormControl("", []),
      Analyst: new FormControl("", []),
      Indications: new FormControl("", []),
      LeadECG: new FormControl("", []),
      ReportClinic: new FormControl("", []),
      TimeAnalyzed: new FormControl("", []),
      MinHR: new FormControl("", []),
      MaxHR: new FormControl("", []),
      MeanHR: new FormControl("", []),
      TotalBeats: new FormControl("", []),
      Rhythm1: new FormControl("", []),
      Rhythm2: new FormControl("", []),
      Rhythm3: new FormControl("", []),
      Arrhythmia1: new FormControl("", []),
      Arrhythmia2: new FormControl("", []),
      Arrhythmia3: new FormControl("", []),
      Arrhythmia4: new FormControl("", []),
      EventNumber1: new FormControl("", []),
      EventNumber2: new FormControl("", []),
      EventNumber3: new FormControl("", []),
      EventNumber4: new FormControl("", []),
      PauseLongestRR: new FormControl("", []),
      PauseTime: new FormControl("", []),
      PauseCount: new FormControl("", []),
      APBTotal: new FormControl("", []),
      APBPair: new FormControl("", []),
      APBSVT: new FormControl("", []),
      VPBTotal: new FormControl("", []),
      VPBPair: new FormControl("", []),
      VPBVT: new FormControl("", []),
    });

    this.formGroupReport = new FormGroup({
      GeneralComment1: new FormControl("", []),
      GeneralComment2: new FormControl("", []),
      GeneralComment3: new FormControl("", []),
      GeneralComment4: new FormControl("", []),
      GeneralComment5: new FormControl("", []),
      SpecificComment1: new FormControl("", []),
      SpecificComment2: new FormControl("", []),
      SpecificComment3: new FormControl("", []),
      SpecificComment4: new FormControl("", []),
      SpecificComment5: new FormControl("", []),
      Summary1: new FormControl("", []),
      Summary2: new FormControl("", []),
      Summary3: new FormControl("", []),
      Summary4: new FormControl("", []),
      Summary5: new FormControl("", []),
      AdditionalComment1: new FormControl("", []),
      // AdditionalComment2: new FormControl("", []),
      // AdditionalComment3: new FormControl("", []),
      // AdditionalComment4: new FormControl("", []),
      // AdditionalComment5: new FormControl("", []),
    });

    this.physicianService.allReading().then(doctors => {
      doctors.forEach(doctor => {
        const text = [
          doctor.last_name,
          doctor.first_initials,
        ].filter(x => !!x).join(" | ");

        this.readingDoctorOptions.push({
          value: doctor.name,
          text: text,
        });
      });
    });
  }

  private updateMultiSelected(that: any) {
    that.datatableElement.dtInstance.then((dtInstance: any) => {
      const selectedData = dtInstance.rows(".selected").data();
      that.multiSelected = selectedData.length > 1;
    });
  }

  public rowClickHandler(info: any, force: boolean = false) {
    setTimeout(() => this.updateMultiSelected(this), 200);
    this.holterService.getHolter(info["holter:id"]).then(holter => {
      this.currentHolter = holter;
      this.populateHolter(holter);
      this.showReport = false;
    });
  }

  public statusFilterChanged(): void {
    if (this.statusFilter === "All") {
      this.holterService.updateStateFilter(["EDITING", "RESERVED", "READING", "FAXING"]);
      this.holterService.updateRejectedFilter(null);

    } else if (this.statusFilter === "Editor Rejected") {
      this.holterService.updateStateFilter(["RESERVED"]);
      this.holterService.updateRejectedFilter(true);

    } else if (this.statusFilter === "Sent") {
      this.holterService.updateStateFilter(["READING", "FAXING"]);
      this.holterService.updateRejectedFilter(false);

    } else if (this.statusFilter === "To Process") {
      this.holterService.updateStateFilter(["RESERVED"]);
      this.holterService.updateRejectedFilter(false);

    } else if (this.statusFilter === "Doctor Rejected") {
      this.holterService.updateStateFilter(["READING"]);
      this.holterService.updateRejectedFilter(true);
    }
    this.reloadTable();
  }

  public applyRejectedSort(): void {
    const newValue = !this.rejectedSort;
    this.holterService.applyRejectedSort(newValue ? true : null);
    this.reloadTable();
  }

  public applyReservedFilter(): void {
    const newValue = !this.reservedFilter;
    if (newValue) {
      this.holterService.updateReservedByFilter(this.authService.getToken(true).email);
      this.reloadTable();
    } else {
      this.holterService.updateReservedByFilter(null);
      this.statusFilterChanged();
    }
  }

  private isValidDate(date: any) {
    return !isNaN(Date.parse(date));
  }

  public filterChanged() {
    let startDateFilter = null;
    if (this.isValidDate(this.startDateFilter)) {
      startDateFilter = {
        regex: true,
        method: "gte",
        value: new Date(this.startDateFilter),
      };
    }
    this.holterService.updateStartScanDateFilter(startDateFilter);

    let endDateFilter = null;
    if (this.isValidDate(this.endDateFilter)) {
      endDateFilter = {
        regex: true,
        method: "lte",
        value: new Date(this.endDateFilter),
      };
    }
    this.holterService.updateEndScanDateFilter(endDateFilter);

    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  private reloadTable() {
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw(false);
    });
  }

  private populateHolter(holter: any) {
    this.errorStrings = {};
    this.wasAutofilled = false;
    this.pdfPatConflicts = {};
    this.autofillConflicts = {};
    this.formGroupMedflux.reset();

    const medfluxDataJson = JSON.parse(holter.medflux_data);
    this.formGroupMedflux.patchValue({
      ReferringDoctorName: holter.referring_doctor_name,
      FirstName: holter.patient_first_name,
      LastName: holter.patient_last_name,
      HealthCard: holter.patient_health_card.substring(0,10),
      HealthCardVersion: holter.patient_health_card_code,
      Birthdate: holter.patient_birthdate,
      Gender: holter.patient_gender,
      StaffNotes: formatNotesMultiple([holter.notes, holter.requisition_staff_notes]),
      Clinic: holter.clinic_name,
      Duration: holter.duration,
      ReadingDoctorName: holter.reading_doctor_name,
      TestDate: holter.hookup_date,
      Editor: (holter.editor_firstname && holter.editor_lastname) ? (holter.editor_firstname + " " + holter.editor_lastname) : null,
      Analyst: (holter.analyst_firstname && holter.analyst_lastname) ? (holter.analyst_firstname + " " + holter.analyst_lastname) : null,
      Indications: medfluxDataJson ? medfluxDataJson.indications : null,
      LeadECG: medfluxDataJson ? medfluxDataJson.leadecg : null,
      ReportClinic: holter.report_clinic,
      TimeAnalyzed: medfluxDataJson ? medfluxDataJson.timeanalyzed : null,
      MinHR: medfluxDataJson ? medfluxDataJson.minhr : null,
      MaxHR: medfluxDataJson ? medfluxDataJson.maxhr : null,
      MeanHR: medfluxDataJson ? medfluxDataJson.meanhr : null,
      TotalBeats: medfluxDataJson ? medfluxDataJson.totalbeats : null,
      Rhythm1: medfluxDataJson ? medfluxDataJson.rhythm1 : null,
      Rhythm2: medfluxDataJson ? medfluxDataJson.rhythm2 : null,
      Rhythm3: medfluxDataJson ? medfluxDataJson.rhythm3 : null,
      Arrhythmia1: medfluxDataJson ? medfluxDataJson.arrhythmia1 : null,
      Arrhythmia2: medfluxDataJson ? medfluxDataJson.arrhythmia2 : null,
      Arrhythmia3: medfluxDataJson ? medfluxDataJson.arrhythmia3 : null,
      Arrhythmia4: medfluxDataJson ? medfluxDataJson.arrhythmia4 : null,
      EventNumber1: medfluxDataJson ? medfluxDataJson.eventnumber1 : null,
      EventNumber2: medfluxDataJson ? medfluxDataJson.eventnumber2 : null,
      EventNumber3: medfluxDataJson ? medfluxDataJson.eventnumber3 : null,
      EventNumber4: medfluxDataJson ? medfluxDataJson.eventnumber4 : null,
      PauseLongestRR: medfluxDataJson ? medfluxDataJson.pauselongestrr : null,
      PauseTime: medfluxDataJson ? medfluxDataJson.pausetime : null,
      PauseCount: medfluxDataJson ? medfluxDataJson.pausecount : null,
      APBTotal: medfluxDataJson ? medfluxDataJson.apbtotal : null,
      APBPair: medfluxDataJson ? medfluxDataJson.apbpair : null,
      APBSVT: medfluxDataJson ? medfluxDataJson.apbsvt : null,
      VPBTotal: medfluxDataJson ? medfluxDataJson.vpbtotal : null,
      VPBPair: medfluxDataJson ? medfluxDataJson.vpbpair : null,
      VPBVT: medfluxDataJson ? medfluxDataJson.vpbvt : null,
    });

    this.verifyHealthcardObservable(null, this);
  }

  public autofilled(controlName: string): boolean {

    const exceptionNames = [
      "LastName",
      "FirstName",
      "HealthCard",
      "HealthCardVersion",
      "Birthdate",
      "ReferringDoctorName",
      "Gender",
      "Clinic",
      "TestDate",
    ];

    const value = this.formGroupMedflux.get(controlName).value;
    return (exceptionNames.indexOf(controlName) > -1) || !this.wasAutofilled || !!value || value == 0;
  }

  public pdfPatConflictValue(controlName: string): string | null {
    if (!this.wasAutofilled) {
      return null;
    }
    const backendName = this.holterService.AUTOFILL_TO_FORM_MAPPING[controlName];
    if (backendName && backendName in this.pdfPatConflicts) {
      return this.pdfPatConflicts[backendName];
    }
    return null;
  }

  public autofillConflictValue(controlName: string): string | null {
    if (!this.wasAutofilled) {
      return null;
    }
    if (controlName in this.autofillConflicts) {
      return this.autofillConflicts[controlName];
    }
    return null;
  }

  public hasConflict(controlName: string): boolean {
    return this.pdfPatConflictValue(controlName) !== null || this.autofillConflictValue(controlName) !== null;
  }

  public unreserve() {
    this.datatableElement?.dtInstance.then((dtInstance: any) => {
      const updatePromises = [];
      dtInstance.rows(".selected").every((idx) => {
        const entry = dtInstance.row(idx).data();
        updatePromises.push(this.holterService.unreserve(entry["holter:id"]));
      });
      Promise.all(updatePromises).then(() => dtInstance.draw());
    });
  }

  private convertStringToArray(toConvert: string): string[] {
    if (!toConvert || toConvert === "") {
      return [];
    }
    try {
      return JSON.parse(toConvert);
    } catch(e) {
      return [toConvert]
    }
  }

  private populateReport(general: string, specific: string, summary: string, additional: string): void {
    const generalComments = this.convertStringToArray(general);
    const specificComments = this.convertStringToArray(specific);
    const summaries = this.convertStringToArray(summary);
    const additionalComments = this.convertStringToArray(additional);

    this.formGroupReport.patchValue({
      GeneralComment1: generalComments.length >= 1 ? generalComments[0] : null,
      GeneralComment2: generalComments.length >= 2 ? generalComments[1] : null,
      GeneralComment3: generalComments.length >= 3 ? generalComments[2] : null,
      GeneralComment4: generalComments.length >= 4 ? generalComments[3] : null,
      GeneralComment5: generalComments.length >= 5 ? generalComments[4] : null,
      SpecificComment1: specificComments.length >= 1 ? specificComments[0] : null,
      SpecificComment2: specificComments.length >= 2 ? specificComments[1] : null,
      SpecificComment3: specificComments.length >= 3 ? specificComments[2] : null,
      SpecificComment4: specificComments.length >= 4 ? specificComments[3] : null,
      SpecificComment5: specificComments.length >= 5 ? specificComments[4] : null,
      Summary1: summaries.length >= 1 ? summaries[0] : null,
      Summary2: summaries.length >= 2 ? summaries[1] : null,
      Summary3: summaries.length >= 3 ? summaries[2] : null,
      Summary4: summaries.length >= 4 ? summaries[3] : null,
      Summary5: summaries.length >= 5 ? summaries[4] : null,
      AdditionalComment1: additionalComments.length >= 1 ? additionalComments[0] : null,
      // AdditionalComment2: additionalComments.length >= 2 ? additionalComments[1] : null,
      // AdditionalComment3: additionalComments.length >= 3 ? additionalComments[2] : null,
      // AdditionalComment4: additionalComments.length >= 4 ? additionalComments[3] : null,
      // AdditionalComment5: additionalComments.length >= 5 ? additionalComments[4] : null,
    });
    setTimeout(() => this.autoResizeTextAreas(), 500);
  }

  public toggleReport() {
    this.wasAutofilled = false;
    this.autofillConflicts = {};
    this.pdfPatConflicts = {};

    this.holterService.getHolter(this.currentHolter.id).then(holter => {
      this.currentHolter = holter;
      if (this.showReport) {
        this.formGroupMedflux.reset();
        this.populateHolter(holter);
        this.showReport = false;
      } else {
        this.formGroupReport.reset();
        this.showReport = true;

        // autofill if not filled in yet
        if (!holter.general_comments || !holter.specific_comments || !holter.summary) {
          this.autofillForm();
        } else {
          this.populateReport(holter.general_comments, holter.specific_comments, holter.summary, holter.additional_comments);
        }
      }
    });
  }

  public showTracings(refreshHolters: boolean = false) {
    if (refreshHolters) {
      this.currentPreviewHolter = this.currentHolter.id;
      this.patientService.getHolters(this.currentHolter.patient_id).then(holters => {
        this.allHolters = holters;
      });
    }
    return this.holterService.getTracings(this.currentPreviewHolter, "annotated").then(url => {
      this.pdfSrc = url;
      this.showPdfPreview = "tracings";
    });
  }

  public showRequisition() {
    return this.holterService.getRequisition(this.currentPreviewHolter).then(url => {
      this.pdfSrc = url;
      this.showPdfPreview = "requisition";
    });
  }

  public showReportPreview() {
    if (this.currentPreviewHolter){
      return this.holterService.getMonitorReport(this.currentPreviewHolter)
        .then((base64Data: string) => {
          this.pdfSrc = HolterService.base64ToArrayBuffer(base64Data);
          this.showPdfPreview = "report";
        });
    }
  }

  public showReadingBatch() {
    return this.holterService.getReadingBatchReport(this.currentPreviewHolter, "original")
      .then(url => {
        this.pdfSrc = url;
        this.showPdfPreview = "batch";
      });
  }

  public closeTracings() {
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
    this.showPdfPreview = null;
  }

  private setToScrollHeight(elementRef: ElementRef) {
    if (elementRef) {
      const e = elementRef.nativeElement;
      const styleHeight = parseInt(e.style.height.substring(0, e.style.height.length - 2));
      e.style.height = (e.scrollHeight > styleHeight || isNaN(styleHeight)) ? ((e.scrollHeight + 3) + 'px') : e.style.height;
    }
  }

  private autoResizeTextAreas() {
    if (!this.setToScrollHeight) return;
    this.setToScrollHeight(this.generalComment1Div);
    this.setToScrollHeight(this.generalComment2Div);
    this.setToScrollHeight(this.generalComment3Div);
    this.setToScrollHeight(this.generalComment4Div);
    this.setToScrollHeight(this.generalComment5Div);
    this.setToScrollHeight(this.specificComment1Div);
    this.setToScrollHeight(this.specificComment2Div);
    this.setToScrollHeight(this.specificComment3Div);
    this.setToScrollHeight(this.specificComment4Div);
    this.setToScrollHeight(this.specificComment5Div);
    this.setToScrollHeight(this.summary1Div);
    this.setToScrollHeight(this.summary2Div);
    this.setToScrollHeight(this.summary3Div);
    this.setToScrollHeight(this.summary4Div);
    this.setToScrollHeight(this.summary5Div);
    this.setToScrollHeight(this.additionalComment1Div);
    // this.setToScrollHeight(this.additionalComment2Div);
    // this.setToScrollHeight(this.additionalComment3Div);
    // this.setToScrollHeight(this.additionalComment4Div);
    // this.setToScrollHeight(this.additionalComment5Div);
  }

  public contextMenuClick(event, phrase) {
    this.formGroupReport.get(event.item).patchValue(phrase);
  }

  public dxcodeContextMenuClick(event, phrase) {
    this.formGroupMedflux.patchValue({
      Indications: phrase.text.substring(4),
    });
  }

  public reqContextMenuClick(event, phrase) {
    this.formGroupMedflux.patchValue({
      Indications: this.formGroupMedflux.get("Indications")?.value ? this.formGroupMedflux.get("Indications")?.value + ", " + phrase.text : phrase.text,
    });
  }

  public savePatientAndReport() {
    $("body, button").css("cursor", "wait");

    let readingDr = this.formGroupMedflux.get("ReadingDoctorName")?.value;
    if (this.readingDoctorOptions.filter(option => option.value == readingDr).length <= 0) {
      readingDr = null;
    }
    return this.holterService.updateHolter(this.currentHolter.id, {
      patient: {
        first_name: this.formGroupMedflux.get("FirstName")?.value,
        last_name: this.formGroupMedflux.get("LastName")?.value,
        healthcard: this.formGroupMedflux.get("HealthCard")?.value,
        healthcard_version: this.formGroupMedflux.get("HealthCardVersion")?.value,
        birthdate: this.formGroupMedflux.get("Birthdate")?.value,
        gender: this.formGroupMedflux.get("Gender")?.value,
      },
      referring_doctor: {
        name: this.formGroupMedflux.get("ReferringDoctorName")?.value?.name || this.formGroupMedflux.get("ReferringDoctorName")?.value,
      },
      clinic: {
        name: this.formGroupMedflux.get("Clinic")?.value?.name || this.formGroupMedflux.get("Clinic")?.value,
      },
      reading_doctor: {
        name: readingDr,
      },
      duration: this.formGroupMedflux.get("Duration")?.value,
      report_clinic: this.formGroupMedflux.get("ReportClinic")?.value,
    }).then(() => {
      this.holterService.updateMedfluxVars(this.currentHolter.id, {
        indications: this.formGroupMedflux.get("Indications")?.value,
        leadecg: this.formGroupMedflux.get("LeadECG")?.value,
      })
    }).then(() => {
      this.holterService.markEditor(this.currentHolter.id);
    }).then(() => {
      const annotatePromise = this.holterService.annotate(this.currentHolter.id);
      const reloadPromise = this.holterService.getHolter(this.currentHolter.id).then(holter => {
        this.currentHolter = holter;
        this.populateHolter(holter);
        this.reloadTable();
      });
      this.autofillConflicts = {};
      this.pdfPatConflicts = {};

      Promise.all([annotatePromise, reloadPromise]).then(() => {
        $("body, button").css("cursor", "auto");
      });
    });
  }

  public save() {
    $("body, button").css("cursor", "wait");

    if (this.showReport) {
      this.holterService.updateHolter(this.currentHolter.id, {
        "general_comments": JSON.stringify([
          this.formGroupReport.get("GeneralComment1").value,
          this.formGroupReport.get("GeneralComment2").value,
          this.formGroupReport.get("GeneralComment3").value,
          this.formGroupReport.get("GeneralComment4").value,
          this.formGroupReport.get("GeneralComment5").value,
        ]),
        "specific_comments": JSON.stringify([
          this.formGroupReport.get("SpecificComment1").value,
          this.formGroupReport.get("SpecificComment2").value,
          this.formGroupReport.get("SpecificComment3").value,
          this.formGroupReport.get("SpecificComment4").value,
          this.formGroupReport.get("SpecificComment5").value,
        ]),
        "summary": JSON.stringify([
          this.formGroupReport.get("Summary1").value,
          this.formGroupReport.get("Summary2").value,
          this.formGroupReport.get("Summary3").value,
          this.formGroupReport.get("Summary4").value,
          this.formGroupReport.get("Summary5").value,
        ]),
        "additional_comments": JSON.stringify([
          this.formGroupReport.get("AdditionalComment1").value,
          // this.formGroupReport.get("AdditionalComment2").value,
          // this.formGroupReport.get("AdditionalComment3").value,
          // this.formGroupReport.get("AdditionalComment4").value,
          // this.formGroupReport.get("AdditionalComment5").value,
        ]),
      }).then(() => {
        this.holterService.getHolter(this.currentHolter.id).then(holter => {
          this.currentHolter = holter;
          this.populateHolter(holter);
          this.reloadTable();
          $("body, button").css("cursor", "auto");
        });
      });

    } else {
      this.updateHolter().then(() => {
        const annotatePromise = this.holterService.annotate(this.currentHolter.id);
        const reloadPromise = this.holterService.getHolter(this.currentHolter.id).then(holter => {
          this.currentHolter = holter;
          this.populateHolter(holter);
          this.reloadTable();
        });
        this.autofillConflicts = {};
        this.pdfPatConflicts = {};

        Promise.all([annotatePromise, reloadPromise]).then(() => {
          $("body, button").css("cursor", "auto");
        });
      });

    }

  }

  public submitForm() {
    const token = this.authService.getToken(true);
    let data = {
      state: "READING",
      general_comments: JSON.stringify([
        this.formGroupReport.get("GeneralComment1").value,
        this.formGroupReport.get("GeneralComment2").value,
        this.formGroupReport.get("GeneralComment3").value,
        this.formGroupReport.get("GeneralComment4").value,
        this.formGroupReport.get("GeneralComment5").value,
      ]),
      specific_comments: JSON.stringify([
        this.formGroupReport.get("SpecificComment1").value,
        this.formGroupReport.get("SpecificComment2").value,
        this.formGroupReport.get("SpecificComment3").value,
        this.formGroupReport.get("SpecificComment4").value,
        this.formGroupReport.get("SpecificComment5").value,
      ]),
      summary: JSON.stringify([
        this.formGroupReport.get("Summary1").value,
        this.formGroupReport.get("Summary2").value,
        this.formGroupReport.get("Summary3").value,
        this.formGroupReport.get("Summary4").value,
        this.formGroupReport.get("Summary5").value,
      ]),
      additional_comments: JSON.stringify([
        this.formGroupReport.get("AdditionalComment1").value,
        // this.formGroupReport.get("AdditionalComment2").value,
        // this.formGroupReport.get("AdditionalComment3").value,
        // this.formGroupReport.get("AdditionalComment4").value,
        // this.formGroupReport.get("AdditionalComment5").value,
      ]),
    }
    this.holterService.addNotes(this.currentHolter.id, "Submitted", null);
    if (this.currentHolter.patient_health_card.length > 10){
      data['patient'] = {
        first_name: this.formGroupMedflux.get("FirstName")?.value,
        last_name: this.formGroupMedflux.get("LastName")?.value,
        healthcard: this.formGroupMedflux.get("HealthCard")?.value,
        healthcard_version: this.formGroupMedflux.get("HealthCardVersion")?.value,
        birthdate: this.formGroupMedflux.get("Birthdate")?.value,
        gender: this.formGroupMedflux.get("Gender")?.value,
        merge: true,
      }
    }
    if (this.currentHolter.state == "FAXING"){
      const modalRef = this.modalService.open(ModalReservedFaxingResubmitComponent, {size:'md'});
      modalRef.result.then(result => {
        if (result) {
          if (this.currentHolter.patient_health_card.length > 10) {
            const dupe_name = this.errorStrings.last_name + " " + this.errorStrings.first_name
            this.holterService.addNotes(this.currentHolter.id, "Patient was merged with " + dupe_name + " due to duplicate health card", null)
          }
          this.holterService.updateHolter(this.currentHolter.id, data).then(() => {
            this.holterService.getHolter(this.currentHolter.id)
              .then((holter: Holter | null) => {
                this.currentHolter = holter;
                this.populateHolter(holter);
                this.showReportPreview();
                this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
                  dtInstance.draw(false);
                });
              });
          });
        }
      });
    }
    else {
      if (this.currentHolter.patient_health_card.length > 10) {
        const dupe_name = this.errorStrings.last_name + " " + this.errorStrings.first_name
        this.holterService.addNotes(this.currentHolter.id, "Patient was merged with " + dupe_name + " due to duplicate health card", null)
      }
      this.holterService.updateHolter(this.currentHolter.id, data).then(() => {
        this.holterService.getHolter(this.currentHolter.id)
          .then((holter: Holter | null) => {
            this.currentHolter = holter;
            this.populateHolter(holter);
            this.showReportPreview();
            this.medtraceService.createHolterMedtrace(
              {
                holter_id: holter.id,
                serial_number: holter.device_serial_number,
                status: "ACTIVE",
              });
            this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.draw(false);
            });
          });
      });
    }
  }

  private populateFormWithAutoFill(medfluxVars: any, analyst: string, editor: string): void {

    const hasValue = x => x !== undefined && x !== null && x !== "";

    this.autofillConflicts = {};
    const patchValueDict: any = {};
    Object.keys(this.formGroupMedflux.controls).forEach(key => {
      const autofillKey = this.holterService.AUTOFILL_TO_FORM_MAPPING[key];

      var currentValue = this.formGroupMedflux.get(key).value;
      currentValue = hasValue(currentValue) ? currentValue : null;

      if (autofillKey === undefined) {
        patchValueDict[key] = currentValue;
        return;
      }

      var newValue = medfluxVars.merged[autofillKey];
      newValue = hasValue(newValue) ? newValue : null;

      if (currentValue != newValue) {
        if (currentValue && newValue) {
          this.autofillConflicts[key] = "Found: " + newValue;
          patchValueDict[key] = currentValue;
        } else if (hasValue(newValue)) {
          patchValueDict[key] = newValue;
        } else if (currentValue) {
          this.autofillConflicts[key] = "Found: empty";
          patchValueDict[key] = currentValue;
        }
      } else {
        patchValueDict[key] = newValue;
      }
    });

    patchValueDict["Analyst"] = analyst;
    patchValueDict["Editor"] = editor;

    this.formGroupMedflux.reset();
    this.formGroupMedflux.patchValue(patchValueDict);
    Object.keys(this.formGroupMedflux.controls).forEach(key => {
      this.formGroupMedflux.get(key).markAsDirty();
      this.formGroupMedflux.get(key).markAsTouched();
    });
    this.pdfPatConflicts = medfluxVars.conflicts;
  }

  public autofillForm(): void {
    if (this.showReport) {
      this.holterService.getCommentPredictions(this.currentHolter.id, true).then(predictions => {
        this.populateReport(predictions.general_comments, predictions.specific_comments, predictions.summary, null);
        // if (predictions.alerts && predictions.alerts.general_comment_5) {
        //   this.alertGeneralComment5 = predictions.alerts.general_comment_5;
        // }
        this.wasAutofilled = true;
      });
    } else {
      const analyst = this.currentHolter.analyst_firstname + " " + this.currentHolter.analyst_lastname;
      const token = this.authService.getToken(true);
      const editor = token["first_name"] + " " + token["last_name"];
      this.holterService.getMedfluxVars(this.currentHolter.id).then(medfluxVars => {
        this.populateFormWithAutoFill(medfluxVars, analyst, editor);
        this.wasAutofilled = true;
      });
    }
  }

  private updateHolter(): Promise<void> {
    let readingDr = this.formGroupMedflux.get("ReadingDoctorName")?.value;
    if (this.readingDoctorOptions.filter(option => option.value == readingDr).length <= 0) {
      readingDr = null;
    }
    return this.holterService.updateHolter(this.currentHolter.id, {
      patient: {
        first_name: this.formGroupMedflux.get("FirstName")?.value,
        last_name: this.formGroupMedflux.get("LastName")?.value,
        healthcard: this.formGroupMedflux.get("HealthCard")?.value,
        healthcard_version: this.formGroupMedflux.get("HealthCardVersion")?.value,
        birthdate: this.formGroupMedflux.get("Birthdate")?.value,
        gender: this.formGroupMedflux.get("Gender")?.value,
      },
      hookup_date: this.formGroupMedflux.get("TestDate")?.value,
      flag_verified: true, // when a save happens it means the editor verified the information
      general_comments: null, // when a save happens clear the summary to force an autofill on medflux page
      specific_comments: null, // when a save happens clear the summary to force an autofill on medflux page
      summary: null, // when a save happens clear the summary to force an autofill on medflux page
      referring_doctor: {
        name: this.formGroupMedflux.get("ReferringDoctorName")?.value?.name || this.formGroupMedflux.get("ReferringDoctorName")?.value,
      },
      clinic: {
        name: this.formGroupMedflux.get("Clinic")?.value?.name || this.formGroupMedflux.get("Clinic")?.value,
      },
      reading_doctor: {
        name: readingDr,
      },
      duration: this.formGroupMedflux.get("Duration")?.value,
      report_clinic: this.formGroupMedflux.get("ReportClinic")?.value,
      medflux_data: {
        indications: this.formGroupMedflux.get("Indications")?.value,
        leadecg: this.formGroupMedflux.get("LeadECG")?.value,
        timeanalyzed: this.formGroupMedflux.get("TimeAnalyzed")?.value,
        minhr: this.formGroupMedflux.get("MinHR")?.value,
        maxhr: this.formGroupMedflux.get("MaxHR")?.value,
        meanhr: this.formGroupMedflux.get("MeanHR")?.value,
        totalbeats: this.formGroupMedflux.get("TotalBeats")?.value,
        rhythm1: this.formGroupMedflux.get("Rhythm1").value,
        rhythm2: this.formGroupMedflux.get("Rhythm2").value,
        rhythm3: this.formGroupMedflux.get("Rhythm3").value,
        arrhythmia1: this.formGroupMedflux.get("Arrhythmia1").value,
        arrhythmia2: this.formGroupMedflux.get("Arrhythmia2").value,
        arrhythmia3: this.formGroupMedflux.get("Arrhythmia3").value,
        arrhythmia4: this.formGroupMedflux.get("Arrhythmia4").value,
        eventnumber1: this.formGroupMedflux.get("EventNumber1").value,
        eventnumber2: this.formGroupMedflux.get("EventNumber2").value,
        eventnumber3: this.formGroupMedflux.get("EventNumber3").value,
        eventnumber4: this.formGroupMedflux.get("EventNumber4").value,
        pauselongestrr: this.formGroupMedflux.get("PauseLongestRR")?.value,
        pausetime: this.formGroupMedflux.get("PauseTime")?.value,
        pausecount: this.formGroupMedflux.get("PauseCount")?.value,
        apbtotal: this.formGroupMedflux.get("APBTotal")?.value,
        apbpair: this.formGroupMedflux.get("APBPair")?.value,
        apbsvt: this.formGroupMedflux.get("APBSVT")?.value,
        vpbtotal: this.formGroupMedflux.get("VPBTotal")?.value,
        vpbpair: this.formGroupMedflux.get("VPBPair")?.value,
        vpbvt: this.formGroupMedflux.get("VPBVT")?.value,
      }
    }).then(() => {
      this.holterService.markEditor(this.currentHolter.id);
    });
  }

  public buildHIN(holter: Holter): string | null {
    if (!holter || !holter.patient_health_card)
      return null;

    if (holter.patient_health_card_code) {
      return holter.patient_health_card + "-" + holter.patient_health_card_code;
    } else {
      return holter.patient_health_card;
    }
  }

  public getMedfluxData(holter: Holter, field: string): string | null {
    if (!holter || !holter.medflux_data)
      return null;

    const parsed = JSON.parse(holter.medflux_data);
    return parsed[field];
  }

  public downloadFileHandler(event: any, info: any) {
    setTimeout(() => {
      $("body, .table-download-icon, tr").css("cursor", "wait");
      return this.holterService.getHolter(parseInt(info["holter:id"]))
      .then((holter: Holter | null) => {

        // no holter found with matching id
        if (!holter) {
          return;
        }

        // determine which download was clicked
        const classNames = event.target.className;
        if (classNames.includes('download-tracings')) {
          return this.holterService.getTracings(holter.id, "flattened").then(url => {
            download(url);
          });
        } else if (classNames.includes('download-backup')) {
          return this.holterService.backupUrls(holter.id).then(urls => {
            const zip_name = `${holter.patient_last_name}, ${holter.patient_first_name}_backup_${holter.hookup_date}_${holter.referring_doctor_name}.zip`;
            var count = 0;
            var zip = new JSZip();
            urls.forEach(url => {
              return JSZipUtils.getBinaryContent(url.url, (err, data) => {
                zip.file(url.filename, data, {binary:true});
                count++;
                this.downloadProgress = "Downloaded " + count + "/" + urls.length + " files";
                if (count == urls.length) {
                  this.downloadProgress = "Zipping files. This may take a couple of minutes. Do not navigate away from page.";
                  zip.generateAsync({type:'blob'}).then(content => {
                    saveAs(content, zip_name);
                    this.downloadProgress = null;
                  });
                }
              });
            });
          });
        } else if (classNames.includes('download-preliminary')) {
          return this.holterService.getMonitorReport(holter.id)
          .then((base64Data: string) => {
            download(HolterService.base64ToArrayBuffer(base64Data), "preliminary_report.pdf", "application/pdf");
          })
        } else if (classNames.includes('download-final')) {
          return this.holterService.getMonitorReport(holter.id)
          .then((base64Data: string) => {
            download(HolterService.base64ToArrayBuffer(base64Data), "final_report.pdf", "application/pdf");
          })
        } else if (classNames.includes('download-req')) {
          return this.holterService.getRequisition(holter.id).then((url) => {
            download(url);
          });
        }
        return;
      })
      .then(() => {
        $("body, .table-download-icon, tr").css("cursor", "auto");
      })
      .catch(() => {
        $("body, .table-download-icon, tr").css("cursor", "auto");
      });
    }, 500);
  }

  searchClinic = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => (!this.authService.isCardioStudyCompanyClass() || term.length < 2) ? Promise.resolve([])
        : this.clinicService.searchClinics(term))
    )

  searchClinicFormatter = (result: any): string => result.name;

  searchClinicInputFormatter = (result: any): string => {
    if (!result.name) {
      return result;
    }

    this.formGroupMedflux.patchValue({
      Clinic: result.name,
    });
    return result.name;
  };

  searchReferringDoctor = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => (!this.authService.isCardioStudyCompanyClass() || term.length < 2) ? Promise.resolve([])
        : this.physicianService.searchDoctor(term, true, false))
    )

  searchReferringDoctorFormatter = (result: any): string => {
    return [
      result.last_name,
      result.first_initials,
      result.clinic ? result.clinic.city : null,
      result.billing_code,
    ].filter(x => !!x).join(" | ")
  };

  searchReferringDoctorInputFormatter = (result: any): string => {
    if (!result.name) {
      return result;
    }

    this.formGroupMedflux.patchValue({
      ReferringDoctorName: result.name,
      Clinic: result.clinic ? result.clinic.name : null,
    });
    return result.name;
  };

  public openRejectionModal() {
    this.rejectResult.description = "";
    const modalRef = this.modalService.open(ModalRejectionComponent, {size:'md'});
    modalRef.componentInstance.rejectResult = this.rejectResult;
    modalRef.result.then(result => {
      if (result.description) {
        return this.holterService.addNotes(this.currentHolter.id, result.description, null).then(() => this.rejectHolter());
      } else {
        return this.rejectHolter();
      }
    });
  }

  public rejectHolter() {
    this.holterService.rejectHolter(this.currentHolter.id).then(() => {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.draw();
      });
      this.holterService.getHolter(this.currentHolter.id).then(holter => {
        this.currentHolter = holter;
        this.populateHolter(holter);
        this.showReport = false;
      });
    });
  }

  public addStaffNotes() {
    const modalRef = this.modalService.open(ModalStaffNotesComponent, {size:'lg'});
    modalRef.componentInstance.holter = this.currentHolter;
    modalRef.componentInstance.disable = true;
    modalRef.result.then(result => {
      this.holterService.addNotes(this.currentHolter.id, result.staffNotes, null).then(() => {
        this.holterService.getHolter(this.currentHolter.id).then(holter => this.populateHolter(holter));
      });
    });
  }

  public saveTooltip() {
    if (!this.wasAutofilled && this.currentHolter && !this.currentHolter.flag_verified) {
      return "Need to autofill";
    }
    if (!this.formGroupMedflux.valid && !this.showReport) {
      return "Missing required info";
    }
    return "";
  }

  public submitTooltip() {
    if (!this.showReport) {
      return "Need to review report";
    }
    if (this.errorStrings.last_name) {
      return "Patient will merge with " + this.errorStrings.last_name + ", " + this.errorStrings.first_name;
    }
    return "";
  }

  public alsoReservedText() {
    if (!this.currentHolter) {
      return "";
    }
    const token = this.authService.getToken(true);
    const reservations = [];
    for(const employee of this.currentHolter.reservations) {
      if (employee.email != token.email) {
        reservations.push(employee.first);
      }
    }
    if (reservations.length > 0) {
      return "Also reserved by " + reservations.join(", ");
    }
    return "";
  }

  public reorderReadingBatch() {
    $("body, button").css("cursor", "wait");
    this.holterService.reorderReadingBatchReport(this.currentHolter.id, {shift_till: this.reorderPage})
      .then(() => this.showReadingBatch())
      .then(() => $("body, button").css("cursor", "auto"));
  }

  public rebatch() {
    $("body, button").css("cursor", "wait");
    this.holterService.rebatchReport(this.currentHolter.id)
      .then(() => this.showReadingBatch())
      .then(() => $("body, button").css("cursor", "auto"));
  }

  public formatTime(time) {
    if (!time) {
      return "No Hookup Date";
    }
    return formatLocalTime(time);
  }

  private verifyHealthcardObservable(event, that) {
    if (!that.currentHolter) {
      return;
    }
    const healthcard = that.currentHolter.patient_health_card.substring(0,10);
    this.patientService.getPatientByHealthCard(healthcard).then(found_patient => {
      if ((found_patient.last_name != that.currentHolter.patient_last_name) || (found_patient.first_name != that.currentHolter.patient_first_name)){
        this.errorStrings.last_name = found_patient.last_name;
        this.errorStrings.first_name = found_patient.first_name;
      }
    });
  }
}
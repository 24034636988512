import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { version } from '../../../package.json';

import { environment } from "../../environments/environment";
import { AuthService } from "../providers/auth.service";
import { Employee } from "../models/employee.model";
import { Role } from "../models/role.model";
import { Owner } from "../models/owner.model";
import { DataTablesResponse } from "../models/database-response.model";

@Injectable()
export class EmployeeService {

  private readonly JOB_URL: string = environment.api + "/job";
  private readonly CLIENT_NAME: string = "cardio_study";

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  // this is used to override the ajax call in datatables for server-side processing
  public ajaxCall(dataTablesParams: any): Promise<any> {

    // populate the info in multiform part
    dataTablesParams["table"] = "employee";
    const formData: FormData = new FormData();
    formData.append('info', JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "query_data",
      job_id: null,
      task_status: "create",
      data: dataTablesParams
    }));

    // request the data from url
    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise();
  }

  public setPassword(employee: Employee, password: string): Promise<any> {
    const formData: FormData = new FormData();
    formData.append('info', JSON.stringify({
      client: this.CLIENT_NAME,
      job_name: "reset_password",
      job_id: null,
      task_status: "create",
      data: {
        employee_id: employee.id,
        password: password
      },
    }));
    return this.http.post(this.JOB_URL, formData, this.httpOptions()).toPromise()
      .then((response: any) => {
        return response.Payload;
      })
      .catch((error: any) => {
        console.log("Failed to reset the password: " + error.message);
        return null;
      });
  }

  public upsertEmployee(data: any): Promise<any> {
    const url = environment.api + '/employee';
    return this.http.post(url, data, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getEmployeeById(id: any): Promise<any> {
    const url = environment.api + '/employee/' + id;
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getEmployeeByEmail(email: string): Promise<any> {
    const url = environment.api + '/employee?email=' + encodeURIComponent(email);
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public deleteEmployee(id: any): Promise<any> {
    const url = environment.api + '/employee/' + id;
    return this.http.delete(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getCompanies(): Promise<any> {
    const url = environment.api + '/company';
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getRoles(): Promise<any> {
    const url = environment.api + '/employee/role';
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  public getInfo(email: string): Promise<any> {
    const url = environment.api + '/employee/info?email=' + encodeURIComponent(email);
    return this.http.get(url, this.httpOptions()).toPromise()
      .then((response: any) => response);
  }

  private httpOptions(): any {
    return { headers: new HttpHeaders({
      "token": this.authService.getToken(),
      "version": version,
    }) };
  }

}
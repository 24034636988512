import { Component, Input, OnInit, ViewChild, ChangeDetectorRef  } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DataTableDirective } from 'angular-datatables';

import { MedtraceService } from "../../providers/medtrace.service";
import { ClinicService } from "../../providers/clinic.service";
import { formatLocaleDateSingle } from "../../utils/format";

@Component({
  selector: 'app-modal-medtrace-display',
  templateUrl: './modal-medtrace-display.component.html',
  styleUrls: ['./modal-medtrace-display.component.scss']
})
export class ModalMedtraceDisplayComponent implements OnInit {
  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;

  public dtOptions: any = {};
  public tableHeaders = ["STATUS [serial]", "ENTRY DATE", "DOCTOR'S NAME [billing number]", "CLINIC NAME", "ZONE"];
  public displayText = "";
  public monitorText = "";
  @Input() public medtrace;
  @Input() public type;

  public formGroup: FormGroup;


  public rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
    // $("td", row).bind("click", () => this.rowClickHandler(data));
    // $("td", row).bind("contextmenu", (e) => {
    //   e.preventDefault();
    //   this.rowClickHandler(data);
    //   setTimeout(() => {
    //     this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
    //       dtElement.dtInstance.then((dtInstance: any) => {
    //         if (dtInstance.table().node().id == 'medtrace-table') {
    //           this.modify('left');
    //         }
    //       });
    //     });
    //   }, 500);
    //   return false;
    // });
    return row;
  };

  public drawCallback: any = (settings) => {
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
        const row = dtInstance.row(rowIdx);
        const serial = row.data()["medtrace:serial_number"];
        const scan_date = row.data()["medtrace:scan_date"];
        const doctor_id = row.data()["medtrace:doctor_id"];
        const clinic_id = row.data()["medtrace:clinic_id"];
        if (serial == this.medtrace.serial_number && scan_date == formatLocaleDateSingle(this.medtrace.scan_date) && doctor_id == this.medtrace.doctor_id && clinic_id == this.medtrace.clinic_id){
          $(row.node()).addClass('highlighting-reserved-entry');
        }
      });
    });
  };

  public displayBuilder = {
    autoWidth: false,
    responsive: true,
    lengthChange: false,
    select: true,
    pageLength: 50,
    dom: "Blfrtip",
    buttons: [],
    rowCallback: this.rowCallback,
    serverSide: true,
    processing: true,
    scrollY: window.innerHeight - 500,
    ajax: this.medtraceService.getDisplayAjaxFuncion(),
    drawCallback: this.drawCallback,
    order: [[ 1, "desc" ]],
    columns: [
      { data: "medtrace:status",
        render: ( data, type, row, meta ) => {
          const status = row["medtrace:status"] ? row["medtrace:status"] : "";
          const serial = row["medtrace:serial_number"] ? row["medtrace:serial_number"] : "";
          let serial_string = status + "<Br>" + serial;
          return serial_string;
        },
      },
      { data: "medtrace:scan_date" },
      { data: "doctor:name",
        render: ( data, type, row, meta ) => {
          const name = row["doctor:name"] ? row["doctor:name"] : "";
          const billing_number = row["doctor:billing_number"] ? row["doctor:billing_number"] : "";
          let name_string = name + "<Br>" + billing_number;
          return name_string;
        },
      },
      { data: "clinic:name" },
      { data: "medtrace:zone" },
      { data: "medtrace:serial_number", visible: false },
      { data: "medtrace:id", visible: false },
      { data: "medtrace:doctor_id", visible: false },
      { data: "medtrace:clinic_id", visible: false },
      { data: "doctor:billing_number", visible: false },
    ],
    language: {
      infoFiltered: ""
    }
  };

  public displayMonitorTrafficBuilder = {
    autoWidth: false,
    responsive: true,
    lengthChange: false,
    select: true,
    pageLength: 20,
    dom: "Blfrtip",
    buttons: [],
    rowCallback: this.rowCallback,
    serverSide: true,
    processing: true,
    scrollY: window.innerHeight - 500,
    ajax: this.medtraceService.getDisplayAjaxFuncion(),
    drawCallback: this.drawCallback,
    order: [[ 1, "desc" ]],
    columns: [
      { data: "medtrace:focus_count", orderable: false },
      { data: "medtrace:scan_date",
        render: ( data, type, row, meta ) => {
          const max_date = row["medtrace:scan_date"] ? row["medtrace:scan_date"] : "";
          const beginning_date = row["medtrace:beginning_date"] ? row["medtrace:beginning_date"] : "";
          let date_string = max_date + "<Br>" + beginning_date;
          return date_string;
        },
      },
      { data: "doctor:name",
        render: ( data, type, row, meta ) => {
          const name = row["doctor:name"] ? row["doctor:name"] : "";
          const billing_number = row["doctor:billing_number"] ? row["doctor:billing_number"] : "";
          const doctor_count = parseInt(row["medtrace:doctor_count"]);
          let doctor_count_string = doctor_count > 1 ? "and " + String(doctor_count-1) + " more" : "";
          let name_string = name + "(" + billing_number + ")"+ "<Br>" + doctor_count_string;
          return name_string;
        },
      },
      { data: "clinic:name",
        render: ( data, type, row, meta ) => {
          const name = row["clinic:name"] ? row["clinic:name"] : "";
          const clinic_count = parseInt(row["medtrace:clinic_count"]);
          let clinic_count_string = clinic_count > 1 ? "and " + String(clinic_count-1) + " more" : "";
          let clinic_string = name + "<Br>" + clinic_count_string;
          return clinic_string;
        },
      },
      { data: "medtrace:zone" },
      { data: "medtrace:status",
        render: ( data, type, row, meta ) => {
          const status = row["medtrace:status"] ? row["medtrace:status"] : "";
          const serial = row["medtrace:serial_number"] ? row["medtrace:serial_number"] : "";
          let serial_string = status + "<Br>" + serial;
          return serial_string;
        },
      },
      { data: "medtrace:serial_number", visible: false },
      { data: "medtrace:id", visible: false },
      { data: "medtrace:doctor_id", visible: false },
      { data: "medtrace:clinic_id", visible: false },
      { data: "doctor:billing_number", visible: false },
      { data: "medtrace:beginning_date", visible: false },
      { data: "medtrace:doctor_count", visible: false },
      { data: "medtrace:clinic_count", visible: false },
      { data: "medtrace:serial_count", visible: false },
    ],
    language: {
      infoFiltered: ""
    }
  };

  constructor(
    public activeModal: NgbActiveModal,
    private medtraceService: MedtraceService,
    private clinicService: ClinicService,
  ) {

    this.medtraceService.clearDisplayFilters();
    this.formGroup = new FormGroup({
      Active: new FormControl("", []),
      Expired: new FormControl("", []),
      Demo: new FormControl("", []),
      Repair: new FormControl("", []),
      Zone: new FormControl("", []),
    });
  }

  ngOnInit(): void {
    if (this.type == "display_monitor_traffic" || this.type == "display_monitor_traffic_clinic") {
      this.type == "display_monitor_traffic" ? this.displayText = "Serial Number: " + this.medtrace.serial_number : this.displayText = "Clinic: " + this.medtrace.clinic_name;
      this.monitorText = this.medtrace.monitor_active_count + " Active : " + this.medtrace.monitor_total_count + " Total";
      this.medtraceService.updateDisplayFilter(this.type, this.medtrace);
      this.tableHeaders = ["USAGE", "ENTRY DATE RANGE", "DOCTOR'S NAME [billing number]", "CLINIC NAME", "ZONE", "STATUS [serial]"];
      this.dtOptions = this.displayMonitorTrafficBuilder;
    }
    else {
      this.medtraceService.updateDisplayFilter(this.type, this.medtrace);
      this.tableHeaders = ["STATUS [serial]", "ENTRY DATE", "DOCTOR'S NAME [billing number]", "CLINIC NAME", "ZONE"];
      this.dtOptions = this.displayBuilder;
    }
    this.clinicService.getClinic(this.medtrace.clinic_id).then(clinic => {
      this.formGroup.patchValue({
        Zone: clinic.zone
      });
    });
  }

  ngOnDestroy() {

  }

  public passBack(action) {
    this.activeModal.close();
  }

  public getCheckboxStatusChanges() {
    let status = [];
    this.formGroup.get("Active").value ? status.push("ACTIVE") : null;
    this.formGroup.get("Expired").value ? status.push("EXPIRED") : null;
    this.formGroup.get("Demo").value ? status.push("DEMO") : null;
    this.formGroup.get("Repair").value ? status.push("REPAIR") : null;
    status.length == 0 ? status = ["ACTIVE", "SHARED", "EXPIRED", "NOT ACTIVE", "DEMO", "REPAIR"] : null;
    return status
  }

  public checkboxChange(){
    this.medtraceService.updateStatusFilter(this.getCheckboxStatusChanges());
    this.reloadTable();
  }

  public update(){
    let data = {
      zone: this.formGroup.get("Zone").value
    }
    this.clinicService.updateClinic(this.medtrace.clinic_id, data).then(clinic => {
      this.reloadTable();
    });
  }

  private reloadTable() {
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.page(0).draw();
    });
  }

}
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';

import * as moment from "moment";
import * as download from "downloadjs";
import * as JSZip from "jszip";
import * as JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";

import { DXCODE_OPTIONS } from "../../config/constants";
import { Holter } from "../../models/holter.model";
import { Physician } from "../../models/physician.model";
import { Medtrace } from "../../models/medtrace.model";

import { HolterService } from "../../providers/holter.service";
import { AuthService } from "../../providers/auth.service";
import { ClinicService } from "../../providers/clinic.service";
import { PhysicianService } from "../../providers/physician.service";
import { RequisitionService } from 'src/app/providers/requisition.service';
import { MedtraceService } from 'src/app/providers/medtrace.service';

import { formatNotes, formatNotesMultiple } from "../../utils/format";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalRejectionComponent } from '../modal-rejection/modal-rejection.component';
import { ModalMedtraceDisplayComponent } from '../modal-medtrace-display/modal-medtrace-display.component';


@Component({
  selector: 'app-home',
  templateUrl: './holter-visit.component.html',
  styleUrls: ['./holter-visit.component.scss']
})
export class HolterVisitComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;

  public dtOptions: any = {};

  public multiSelected: boolean = false;

  public codeFilter: string | null = "All";
  public holterStatusFilter: string | null = "";

  public formGroup: FormGroup;
  public currentHolter: Holter;
  public currentDoctor: Physician;
  public currentMedtrace: Medtrace;
  private loaded: boolean = false;

  public errorStrings: any = {};

  public formChanged: boolean = false;
  public billingCode: any = null;

  public holterErrorIdx: number = null;
  public holterErrors: any[] = [];

  public filterDatabaseIdx: number = null;
  public searchDatabases: any[] = [];
  public reqOptions: string[] = [];

  public readingDoctorOptions: any[] = [];
  public downloadProgress = null;

  public rejectResult = {description: ''}

  public fileTypeFilters: any = {
    backup: null,
    pdf: null,
    req: null,
    preliminary: null,
    final: null,
  };

  public emailFilter: string | null = null;

  private readonly DEBOUNCE_SEC: number = 1;

  public verboseDxcode: boolean = false;
  public DXCODE_OPTIONS = DXCODE_OPTIONS;

  public setEmailFilter() {
    if (this.emailFilter) {
      this.emailFilter = null;

    } else {
      const token = this.authService.getToken(true);
      this.emailFilter = token.email;
    }

    this.holterService.updateEmailFilter(this.emailFilter);
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw(false);
    });
  }

  // important variable that changes if the status column position changes
  private readonly STATUS_COLUMN_INDEX: number = 0;

  public ngAfterViewInit(): void {

    // set up the callbacks
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {

      // on search term callback
      $("#search-bar").on("keyup change", function () {
        if (dtInstance.search() !== (<HTMLInputElement>this)["value"]) {
          dtInstance.search((<HTMLInputElement>this)["value"]).draw(false);
        }
      });
    });

    // focus on the search bar right away after load
    $("#search-bar").focus();
  }

  private isPediatricPatient(birthdate, hookup_date) {
    if (!birthdate) {
      return false;
    }
    if (!hookup_date) {
      return true;
    }
    var birthday = moment(birthdate);
    var currentDate = hookup_date ? moment(hookup_date) : moment();
    return currentDate.diff(birthday, 'years') < 18;
  }

  private isHolterTechnicianOnly(roles: any[]) {
    let counts = 0;
    for (const role of roles) {
      if (role.name === "Holter Technician") {
        counts += 1;
      }
    }
    return counts == 1;
  }

  public ngOnInit() {}

  constructor(
    private physicianService: PhysicianService,
    private clinicService: ClinicService,
    private holterService: HolterService,
    private authService: AuthService,
    private requisitionService: RequisitionService,
    private medtraceService: MedtraceService,
    private router: Router,
    private modalService: NgbModal,
  ) {

    // sets the default filters as well
    this.filterChanged();

    // roll callback
    const rowCallback: any = (row: Node, data: any[] | Object, index: number) => {
      // $("td", row).unbind("click");
      $(".table-download-icon", row).bind("click", (event) => this.downloadFileHandler(event, data));
      $(".fa-plus", row).bind("click", (event) => this.expandRowsHandler(event, data, true));
      $(".fa-minus", row).bind("click", (event) => this.expandRowsHandler(event, data, false));
      $("td", row).not(".download-file-column").not(".expand-row-column").bind("click", () => this.rowClickHandler(data));
      return row;
    };

    const drawCallback: any = (settings) => {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
          const row = dtInstance.row(rowIdx);

          if (this.currentHolter && row.data()["holter:id"] == this.currentHolter.id) {
            (row as any).select();
          }

          const isClosed = !!row.data()['holter:closed_by'];
          if (isClosed) {
            $(row.node()).addClass('closed-entry');
          }
          else {
            // const expandedFlags = HolterService.expandFlags(row.data()["holter:flags"]);
            // const needRepair = expandedFlags["holter:flag_need_repair"];
            // if (needRepair) {
            //   $(row.node()).addClass('need-repair-entry');
            // }

            const billing_code = row.data()["clinic_assignment:billing_code"];
            const state = row.data()["holter:state"];
            const reading_doctor = row.data()["reading_doctor:name"];
            const expandedFlags = HolterService.expandFlags(row.data()["holter:flags"]);
            const no_req = expandedFlags["holter:flag_no_req"];
            const no_data = expandedFlags["holter:flag_no_data"];
            if (((!billing_code || ((state == "READING" || state == "FAXING") && !reading_doctor) || no_req) && state != "PENDING") || no_data)   {
              $(row.node()).addClass('need-repair-entry');
            }
            else {
              const faxes = row.data()["holter:faxes"];
              let final_faxed = false;
              let faxStatus = "";
              let faxReceived;
              if (faxes && faxes.length > 0) {
                for (let fax of faxes){
                  if (fax["type"]){
                    if (fax["type"].includes("final")){
                      final_faxed = true;
                      let received = new Date(fax["received_at"])
                      if ((!faxReceived) || (faxReceived < received)){
                        faxStatus = fax["status"]
                        faxReceived = new Date(received)
                      }
                    }
                  }
                }
              }
              if (faxStatus.toUpperCase() === "FAXED" && final_faxed) {
                $(row.node()).addClass('faxed-entry');
              }
            }
          }
        });
      });
    };

    // datatable options
    this.dtOptions = {
        autoWidth: false,
        responsive: true,
        lengthChange: false,
        select: true,
        pageLength: 50,
        dom: "Blfrtip",
        buttons: [],
        rowCallback: rowCallback,
        serverSide: true,
        processing: true,
        scrollY: window.innerHeight - 850,
        ajax: this.holterService.getAjaxFuncion(),
        drawCallback: drawCallback,
        order: [[ 4, "desc" ]],
        columnDefs: [{
          targets: 0,
          className: 'expand-row-column'
        },{
          targets: 1,
          className: 'download-file-column'
        }],
        columns: [
          {
            data: 'holter:id',
            orderable: false,
            render: ( data, type, row, meta ) => {
              const duplicateCount = row["holter:duplicate_count"]
              let expandHtml = ``;
              if (duplicateCount > 1)
                expandHtml = ` &nbsp;<a><i class="fa fa-plus expand-rows-icon"></i></a>`;
              if (duplicateCount < -1)
                expandHtml = ` &nbsp;<a><i class="fa fa-minus expand-rows-icon"></i></a>`;
              return expandHtml;
            },
          },
          {
            data: "holter:state",
            render: function ( data, type, row, meta ) {
              return row["holter:closed_by"] ? "SUSPENDED" : data;
            }
          },
          {
            data: "holter:uploaded_files",
            orderable: false,
            render: function ( data, type, row, meta ) {
              const uploadedfiles = JSON.parse(data);
              const hasPdf = uploadedfiles.includes('out_01.pdf');
              const hasPat = uploadedfiles.includes('pat.001');
              const hasBackup = !!row["holter:backed_up_at"];
              const expandedFlags = HolterService.expandFlags(row["holter:flags"]);
              const hasFinal = expandedFlags["holter:flag_verified"] && row["holter:state"] === "FAXING";
              const hasReq = !!row["holter:requisition_id"];
              const hasPreliminary = expandedFlags["holter:flag_verified"] && row["holter:state"] !== "FAXING";

              let icons = '';
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Tracings"><i class="fa download-tracings fa-file-pdf-o ` + (hasPdf ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Requisition"><i class="fa download-req fa-file-o ` + (hasReq ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Preliminary"><i class="fa download-preliminary fa-file-text-o ` + (hasPreliminary ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Final Report"><i class="fa download-final fa-file-pdf-o ` + (hasFinal ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              icons += `<a data-toggle="tooltip" data-placement="left" title="Download Data"><i class="fa download-backup fa-database ` + (hasBackup ? `table-download-icon` : `table-download-icon-disabled`) + `"></i></a>`;
              return icons;
            },
          },
          { data: "holter:hookup_date" },
          { data: "holter:scan_date" },
          {
            data: "patient:last_name",
            orderable: true,
            render: function ( data, type, row, meta ) {
              const last = row["patient:last_name"];
              const first = row["patient:first_name"];
              if (!last && !first) {
                return "";
              } else if (!last) {
                return first;
              } else if (!first) {
                return last;
              } else {
                return last + ", " + first;
              }
            },
          },
          {
            data: "patient:health_card",
            orderable: true,
            render: function ( data, type, row, meta ) {
              const card = row["patient:health_card"];
              const code = row["patient:health_card_code"];
              if ((!card && !code) || !card) {
                return "";
              } else if (!code) {
                return card.substring(0,10);
              } else {
                return card.substring(0,10) + "-" + code;
              }
            },
          },
          { data: "holter:duration" },
          { data: "reading_doctor:name" },
          { data: "referring_doctor:name" },
          { data: "clinic:city" },
          { data: "clinic_assignment:billing_code" },
          { data: "holter:upload_computer_slot" },
          {
            data: "holter:reservations",
            orderable: false,
            render: function ( data, type, row, meta ) {
              const names = [];
              for(const employee of data) {
                names.push(employee.first)
              }
              return names.join(", ");
            }
          },
          {
            data: "holter:flags",
            orderable: false,
            visible: false,
          },
          {
            data: "holter:requisition_id",
            orderable: false,
            visible: false,
          },
          { data: "patient:gender", visible: false },
          { data: "patient:birthdate", visible: false },
          { data: "holter:updated_at", visible: false },
          { data: 'patient:health_card_code', visible: false },
          { data: 'patient:first_name', visible: false },
          { data: 'holter:id', visible: false },
          { data: 'holter:state', visible: false },
          { data: 'holter:device_id', visible: false },
          { data: 'holter:verification_number', visible: false },
          { data: 'holter:backed_up_at', visible: false },
          { data: 'holter:closed_by', visible: false },
          { data: 'holter:faxes', visible: false },
          { data: 'referring_doctor:id', visible: false },
          { data: 'clinic:id', visible: false },
        ],
        language: {
          infoFiltered: ""
        }
    };

    this.formGroup = new FormGroup({
      FirstName: new FormControl("", []),
      LastName: new FormControl("", []),
      Gender: new FormControl("", []),
      HealthCard: new FormControl("", []),
      HealthCardVersion: new FormControl("", []),
      Birthdate: new FormControl("", []),
      DxCode: new FormControl("", []),
      HookupDate: new FormControl("", []),
      Duration: new FormControl("", []),
      ScanDate: new FormControl("", []),
      Tech: new FormControl("", []),
      ReferringDoctor: new FormControl("", []),
      City: new FormControl("", []),
      Device: new FormControl("", []),
      Clinic: new FormControl("", []),
      FaxNumber: new FormControl("", []),
      ReadingDoctor: new FormControl("", []),
      Delivery: new FormControl("", []),
      StaffNotes: new FormControl("", []),
      FaxNotes: new FormControl("", []),
      AdditionalStaffNotes: new FormControl("", []),
      AdditionalFaxNotes: new FormControl("", []),
      Requisition: new FormControl("", []),
      FlagVerified: new FormControl("", []),
      FlagPacemakerInquiry: new FormControl("", []),
      FlagNoData: new FormControl("", []),
      FlagNoReq: new FormControl("", []),
      FlagNoBackup: new FormControl("", []),
      FlagNoTracings: new FormControl("", []),
      FlagInvalidReferring: new FormControl("", []),
      FlagInvalidHealthcard: new FormControl("", []),
      FlagInvalidDob: new FormControl("", []),
    });

    this.formGroup.valueChanges.subscribe(x => {
      this.formChanged = true;
    });

    this.holterService.getDatabaseOptions().then(options => {
      this.searchDatabases = options;
    });

    this.holterService.getHolterErrors("visit").then(errors => {
      const options = [];
      for (const [key, value] of Object.entries(errors)) {
        options.push({
          name: key,
          count: value,
        });
      }
      this.holterErrors = options;
      options.forEach((option, index) => {
        if (option.name == "Visit No Suspended Duplicates") {
          this.holterErrorIdx = index;
          this.applySmartFilter();
        }
      });
    });

    this.physicianService.allReading().then(doctors => {
      doctors.forEach(doctor => {
        const text = [
          doctor.last_name,
          doctor.first_initials,
        ].filter(x => !!x).join(" | ");

        this.readingDoctorOptions.push({
          value: doctor.name,
          text: text,
        });
      });
    });

    this.formGroup.get("Gender")?.valueChanges.subscribe(event => this.verifyTracingsObservable(event, this));
    this.formGroup.get("Duration")?.valueChanges.subscribe(event => this.verifyTracingsObservable(event, this));
    this.formGroup.get("ReadingDoctor")?.valueChanges.subscribe(event => this.verifyTracingsObservable(event, this));
    this.formGroup.get("ReferringDoctor")?.valueChanges.subscribe(event => this.verifyReferringDoctorObservable(event, this));
    this.formGroup.get("Clinic")?.valueChanges.subscribe(event => this.verifyClinicObservable(event, this));

    this.formGroup.get("ReferringDoctor")?.valueChanges.subscribe(event => this.getBillingCodeObservable(event, this));
    this.formGroup.get("Clinic")?.valueChanges.subscribe(event => this.getBillingCodeObservable(event, this));
  }

  private getBillingCodeObservable(event, that) {
    if (!that.currentHolter || !that.loaded) {
      return;
    }
    const doctor = that.formGroup.get("ReferringDoctor")?.value?.name || that.formGroup.get("ReferringDoctor")?.value;
    const clinic = that.formGroup.get("Clinic")?.value;
    if (doctor && clinic) {
      // SEARCHING BILLING CODE DOESNT WORK WHEN CLINICS/DOCTORS HAVE SAME NAMES IN DIRECTORY
      // this.physicianService.searchBillingCode(doctor, clinic).then(billingCode => {
      //   this.billingCode = billingCode || null;
      // });
      this.billingCode = this.currentDoctor?.billing_code || null;
    } else {
      this.billingCode = null;
    }
  }

  public verifyTracings() {
    $("body, button").css("cursor", "wait");
    this.verifyTracingsObservable(null, this).then(() => {
      $("body, button").css("cursor", "auto");
    });
  }

  private verifyReadingDoctorObservable(event, that) {
    if (!that.currentHolter || !that.loaded) {
      return;
    }
    const name = that.formGroup.get("ReadingDoctor")?.value;
    this.physicianService.verifyReading(name).then(verified => {
      this.errorStrings.readingDr = verified ? "" : "Reading doctor not found in directory";
    });
  }

  private verifyReferringDoctorObservable(event, that) {
    if (!that.currentHolter || !that.loaded) {
      return;
    }
    const name = that.formGroup.get("ReferringDoctor")?.value?.name || that.formGroup.get("ReferringDoctor")?.value;
    this.physicianService.verify(name).then(verified => {
      this.errorStrings.referringDr = verified ? "" : "Doctor not found in directory";
    });
  }

  private verifyClinicObservable(event, that) {
    if (!that.currentHolter || !that.loaded) {
      return;
    }
    const name = that.formGroup.get("Clinic")?.value;
    this.clinicService.verify(name).then(verified => {
      this.errorStrings.clinic = verified ? "" : "Clinic not found in directory";
    });
  }

  private verifyTracingsObservable(event, that) {
    if (!that.currentHolter || !that.loaded || !that.currentHolter.uploaded_files.includes("out_01.pdf")) {
      return;
    }

    return that.holterService.getMedfluxVars(that.currentHolter.id)
    .then(medfluxVars => {
        this.errorStrings.gender = "";
        this.errorStrings.duration = "";
        this.errorStrings.readingDr = "";

        const tracingGender = medfluxVars.pdf["GENDER"] || null;
        if (tracingGender !== that.formGroup.get("Gender")?.value) {
          this.errorStrings.gender = "Conflict: " + (tracingGender || "empty");
        }

        const tracingDuration = medfluxVars.pdf["DAYS RECORDED"] || null;
        if (tracingDuration != that.formGroup.get("Duration")?.value) {
          this.errorStrings.duration = "Conflict: " + (tracingDuration || "empty");
        }

        const tracingReadingDr = medfluxVars.pdf["READING DR"] || null;
        if (tracingReadingDr != that.formGroup.get("ReadingDoctor")?.value) {
          this.errorStrings.readingDr = "Conflict: " + (tracingReadingDr || "empty");
        }

        if (that.currentHolter
          && that.formGroup.get("ReadingDoctor")?.value === that.currentHolter.reading_doctor_name
          && that.currentHolter.reading_doctor_roles !== "Reading") {
          this.errorStrings.readingDr = "Not a registered reading doctor";
        }
      });
  }

  private updateMultiSelected(that: any) {
    that.datatableElement.dtInstance.then((dtInstance: any) => {
      const selectedData = dtInstance.rows(".selected").data();
      that.multiSelected = selectedData.length > 1;
    });
  }

  public filterChanged(trigger: any = null) {

    this.holterService.clearFilters();

    const codeFilter = this.codeFilter.toLowerCase() === "all" ? null : this.codeFilter;
    this.holterService.updateBillingCodeFilter(codeFilter);

    if (this.holterStatusFilter) {
      this.holterService.updateStateFilter([this.holterStatusFilter]);
    } else {
      this.holterService.updateStateFilter(null);
    }

    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  public rowClickHandler(info: any, force: boolean = false) {
    setTimeout(() => this.updateMultiSelected(this), 200);
    this.physicianService.getDoctor(info["referring_doctor:id"], info["clinic:id"]).then(doctor => {
      this.currentDoctor = doctor;
      this.holterService.getHolter(info["holter:id"]).then(holter => {
        this.populateHolter(holter);
        // Get latest Medtrace Data for selected holter serial number
        this.medtraceService.getLatestMedtraceBySerialNumber(holter.device_serial_number).then(medtrace => this.currentMedtrace = medtrace);
      });
    });
  }

  private populateHolter(holter: Holter)
  {
    this.errorStrings = {};
    this.loaded = false;
    this.currentHolter = holter;
    this.formGroup.reset();
    const requisitionStr = this.searchRequisitionFormatter({
      last_name: holter.requisition_last_name,
      first_name: holter.requisition_first_name,
      date: holter.requisition_date,
      status: holter.requisition_status,
    });
    this.formGroup.patchValue({
      FirstName: holter.patient_first_name,
      LastName: holter.patient_last_name,
      Gender: holter.patient_gender,
      HealthCard: holter.patient_health_card.substring(0,10),
      HealthCardVersion: holter.patient_health_card_code,
      Birthdate: holter.patient_birthdate,
      DxCode: holter.dx_code_code,
      HookupDate: holter.hookup_date,
      Duration: holter.duration,
      ScanDate: holter.scan_date,
      Tech: holter.staff_first_name + " " + holter.staff_last_name,
      ReferringDoctor: holter.referring_doctor_name,
      City: holter.clinic_city,
      Device: holter.device_serial_number,
      Clinic: holter.clinic_name,
      FaxNumber: holter.clinic_fax,
      ReadingDoctor: holter.reading_doctor_name,
      Delivery: holter.delivery,
      StaffNotes: formatNotesMultiple([holter.notes, holter.requisition_staff_notes]),
      FaxNotes: formatNotesMultiple([holter.fax_notes, holter.requisition_fax_notes]),
      Requisition: requisitionStr,
      FlagVerified: holter.flag_verified,
      FlagPacemakerInquiry: holter.flag_pacemaker_inquiry,
      FlagNoData: holter.flag_no_data,
      FlagNoReq: holter.flag_no_req,
      FlagNoBackup: holter.flag_no_backup,
      FlagNoTracings: holter.flag_no_tracings,
      FlagInvalidReferring: holter.flag_invalid_referring,
      FlagInvalidHealthcard: holter.flag_invalid_healthcard,
      FlagInvalidDob: holter.flag_invalid_dob,
    })
    this.formChanged = false;
    this.loaded = true;
    this.verifyReadingDoctorObservable(null, this);
    this.verifyReferringDoctorObservable(null, this);
    this.verifyClinicObservable(null, this);
    this.getBillingCodeObservable(null, this);
  }

  public updateHolter(): void {
    if (this.currentHolter == null) {
      return;
    }
    $("body, button").css("cursor", "wait");
    let requisition = this.formGroup.get("Requisition")?.value;
    if (!requisition) {
      requisition = null
    } else if (typeof requisition !== "object") {
      requisition = {
        id: this.currentHolter.requisition_id,
      }
    }
    let readingDr = this.formGroup.get("ReadingDoctor")?.value;
    if (this.readingDoctorOptions.filter(option => option.value == readingDr).length <= 0) {
      readingDr = null;
    }
    this.holterService.updateHolter(this.currentHolter.id, {
      patient: {
        healthcard: this.formGroup.get("HealthCard")?.value,
        healthcard_version: this.formGroup.get("HealthCardVersion")?.value,
        gender: this.formGroup.get("Gender")?.value,
      },
      dxcode: {
        code: this.formGroup.get("DxCode")?.value,
      },
      duration: this.formGroup.get("Duration")?.value,
      referring_doctor: {
        name: this.formGroup.get("ReferringDoctor")?.value?.name || this.formGroup.get("ReferringDoctor")?.value,
      },
      clinic: {
        name: this.formGroup.get("Clinic")?.value,
        city: this.formGroup.get("City")?.value,
        clinic_id: this.currentDoctor ? this.currentDoctor.clinic?.id : null,
      },
      reading_doctor: {
        name: readingDr,
      },
      delivery: this.formGroup.get("Delivery")?.value,
      notes: this.formGroup.get("StaffNotes")?.value,
      fax_notes: this.formGroup.get("FaxNotes")?.value,
      // flag_verified: this.formGroup.get("FlagVerified")?.value,
      // flag_pacemaker_inquiry: this.formGroup.get("FlagPacemakerInquiry")?.value,
      // flag_no_data: this.formGroup.get("FlagNoData")?.value,
      // flag_no_req: this.formGroup.get("FlagNoReq")?.value,
      // flag_no_backup: this.formGroup.get("FlagNoBackup")?.value,
      // flag_no_tracings: this.formGroup.get("FlagNoTracings")?.value,
      // flag_invalid_referring: this.formGroup.get("FlagInvalidReferring")?.value,
      // flag_invalid_healthcard: this.formGroup.get("FlagInvalidHealthcard")?.value,
      // flag_invalid_dob: this.formGroup.get("FlagInvalidDob")?.value,
      requisition: requisition,
    }).then(() => {
      $("body, button").css("cursor", "auto");
      this.formChanged = false;
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.draw(false);
      });
    });
  }

  public formatDatabaseDisplay(database: any) {
    if (database.computer) {
      return database.computer + ' ' + database.path;
    }
    return database.path;
  }

  public applySmartFilter() {
    const toFilter = this.holterErrorIdx == null ? null : this.holterErrors[this.holterErrorIdx];
    this.holterService.clearFilters();
    this.holterStatusFilter = "";
    this.codeFilter = "All";
    if (toFilter == null) {
      // no-op
    } else if (toFilter.name == "Duplicate Data") {
      this.holterService.applyGroupBy("holter_duplicates");
    } else if (toFilter.name == "No Suspended Duplicates") {
      this.holterService.setCustomFilter("no_suspended_dupes");
    } else if (toFilter.name == "Visit No Suspended Duplicates") {
      this.holterService.setCustomFilter("visit_no_suspended_dupes");
    } else if (toFilter.name == "No Requisition") {
      this.holterService.updateFlag("flag_no_req", true);
    } else if (toFilter.name == "No Data") {
      this.holterService.updateFlag("flag_no_data", true);
    } else if (toFilter.name == "Pacemaker Inquiry") {
      this.holterService.updateFlag("flag_pacemaker_inquiry", true);
    } else if (toFilter.name == "Invalid Referring Doctor") {
      this.holterService.updateFlag("flag_invalid_referring", true);
    } else if (toFilter.name == "Invalid Healthcard") {
      this.holterService.updateFlag("flag_invalid_healthcard", true);
    } else if (toFilter.name == "Invalid Date of Birth") {
      this.holterService.updateFlag("flag_invalid_dob", true);
    } else if (toFilter.name == "Doctor Reject") {
      this.holterService.updateRejectedFilter(true);
      this.holterService.updateStateFilter(["READING"]);
    } else if (toFilter.name == "Editor Reject") {
      this.holterService.updateRejectedFilter(true);
      this.holterService.updateStateFilter(["EDITING", "RESERVED"]);
    } else if (toFilter.name == "Reserved") {
      this.holterService.updateReservedFilter(true);
    } else if (toFilter.name == "Not Reserved") {
      this.holterService.updateReservedFilter(false);
    } else if (toFilter.name == "Unlinked Reading Doctor") {
      this.holterService.updateInvalidReadingDoctorFilter(true);
    }
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw(false);
    });
  }

  private hasReserved(employees, toCheckEmail) {
    for(const employee of employees) {
      if (employee.email == toCheckEmail) {
        return true;
      }
    }
    return false;
  }

  public reserveHolters() {
    const token = this.authService.getToken(true);

    this.datatableElement?.dtInstance.then((dtInstance: any) => {
      const updatePromises = [];
      dtInstance.rows(".selected").every((idx) => {
        const entry = dtInstance.row(idx).data();
        if (this.hasReserved(entry["holter:reservations"], token.email)) {
          return;
        }
        updatePromises.push(this.holterService.reserve(entry["holter:id"]));
      });
      Promise.all(updatePromises).then(() => dtInstance.draw(false));
    });
  }

  public unreserveHolters() {
    const token = this.authService.getToken(true);

    this.datatableElement?.dtInstance.then((dtInstance: any) => {
      const updatePromises = [];
      dtInstance.rows(".selected").every((idx) => {
        const entry = dtInstance.row(idx).data();
        if (!this.hasReserved(entry["holter:reservations"], token.email)) {
          return;
        }
        updatePromises.push(this.holterService.unreserve(entry["holter:id"]));
      });
      Promise.all(updatePromises).then(() => dtInstance.draw(false));
    });
  }

  public expandRowsHandler(event: any, info: any, expand: boolean) {
    const clickedId = info["holter:id"];

    if (expand)
    {
      this.holterService.setCustomFilter("show_duplicates");
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
          const row = dtInstance.rows().data()[rowIdx];
          if (row["holter:id"] != clickedId) {
            return;
          }
          this.holterService.updateShowDuplicates(row["holter:verification_number"], row["holter:device_id"]);
          dtInstance.page(0).draw();
        });
      });
    }
    else
    {
      this.applySmartFilter();
    }
  }

  public downloadFileHandler(event: any, info: any) {
    setTimeout(() => {
      $("body, .table-download-icon, tr").css("cursor", "wait");
      return this.holterService.getHolter(parseInt(info["holter:id"]))
      .then((holter: Holter | null) => {

        // no holter found with matching id
        if (!holter) {
          return;
        }

        // determine which download was clicked
        const classNames = event.target.className;
        const fileNamePrefix = holter.patient_last_name + "_" + holter.patient_first_name + "_" + holter.hookup_date + "_";
        if (classNames.includes('download-tracings')) {
          return this.holterService.getTracings(this.currentHolter.id, "flattened").then(url => {
            this.downloadPDF(url, holter, 'tracing', 'pdf');
          });
        } else if (classNames.includes('download-backup')) {
          return this.holterService.backupUrls(holter.id).then(urls => {
            const zip_name = `${holter.patient_last_name}, ${holter.patient_first_name}_backup_${holter.hookup_date}_${holter.referring_doctor_name}.zip`;
            var count = 0;
            var zip = new JSZip();
            urls.forEach(url => {
              return JSZipUtils.getBinaryContent(url.url, (err, data) => {
                zip.file(url.filename, data, {binary:true});
                count++;
                this.downloadProgress = "Downloaded " + count + "/" + urls.length + " files";
                if (count == urls.length) {
                  this.downloadProgress = "Zipping files. This may take a couple of minutes. Do not navigate away from page.";
                  zip.generateAsync({type:'blob'}).then(content => {
                    saveAs(content, zip_name);
                    this.downloadProgress = null;
                  });
                }
              });
            });
          });
        } else if (classNames.includes('download-preliminary')) {
          return this.holterService.getMonitorReport(holter.id)
          .then((base64Data: string) => {
            download(HolterService.base64ToArrayBuffer(base64Data), fileNamePrefix + "prelim", "application/pdf");
          })
        } else if (classNames.includes('download-final')) {
          return this.holterService.getMonitorReport(holter.id)
          .then((base64Data: string) => {
            download(HolterService.base64ToArrayBuffer(base64Data), fileNamePrefix + "final", "application/pdf");
          })
        } else if (classNames.includes('download-req')) {
          return this.holterService.getRequisition(holter.id).then((url) => {
            this.downloadPDF(url, holter, 'req', 'pdf');
          });
        }
        return;
      })
      .then(() => {
        $("body, .table-download-icon, tr").css("cursor", "auto");
      })
      .catch(() => {
        $("body, .table-download-icon, tr").css("cursor", "auto");
      });
    }, 500);
  }

  searchRequisition = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => (term.length < 2) ? Promise.resolve([])
        : this.requisitionService.searchRequisition(
          term, false, {
            first_name: "",
            last_name: "No requisition found",
        }))
    );

  searchRequisitionFormatter = (result: any): string => {
    if (typeof result !== 'object') {
      return result;
    }
    return ((result.last_name || result.first_name) ? (result.last_name + ", " + result.first_name) : "") +
      (result.date ? (" - " + moment(result.date).format("YYYY-MM-DD")) : "") + (result.status ? (" " + result.status) : "");
  };

  searchRequisitionInputFormatter = (result: any): string => {
    return this.searchRequisitionFormatter(result);
  };

  searchReferringDoctor = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => (!this.authService.isCardioStudyCompanyClass() || term.length < 2) ? Promise.resolve([])
        : this.physicianService.searchDoctor(term, true, false))
    )

  searchReferringDoctorFormatter = (result: any): string => {
    return [
      result.last_name,
      result.first_initials,
      result.clinic ? result.clinic.city : null,
      result.billing_code,
    ].filter(x => !!x).join(" | ")
  };

  searchReferringDoctorInputFormatter = (result: any): string => {
    if (!result.name) {
      return result;
    }

    this.formGroup.patchValue({
      ReferringDoctor: result.name,
      City: result.clinic ? result.clinic.city : null,
      Clinic: result.clinic ? result.clinic.name : null,
    });
    this.errorStrings.city = result.clinic ? null : "None found";
    this.currentDoctor = result;
    return result.name;
  };

  public toggleFileTypeFilter(fileType) {
    const newValue = this.fileTypeFilters[fileType] === null ? true : null;
    this.fileTypeFilters[fileType] = newValue;

    if (fileType == "backup") this.holterService.updateBackupFilter(newValue);
    if (fileType == "pdf") this.holterService.updatePdfFilter(newValue);
    if (fileType == "req") this.holterService.updateRequisitionFilter(newValue);
    if (fileType == "preliminary") this.holterService.updatePreliminaryFilter(newValue);
    if (fileType == "final") this.holterService.updateFinalReportFilter(newValue);

    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw(false);
    });
  }

  public flagPacemakeInquiry() {
    this.holterService.updateHolter(this.currentHolter.id, {
      flag_pacemaker_inquiry: !this.currentHolter.flag_pacemaker_inquiry,
    }).then(() => {
      this.holterService.getHolter(this.currentHolter.id).then(holter => this.populateHolter(holter));
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.draw(false);
      });
    });
  }

  public openRejectionModal() {
    if (!!this.currentHolter.rejected_by) {
      return this.unrejectHolter();
    } else {
      this.rejectResult.description = "";
      const modalRef = this.modalService.open(ModalRejectionComponent, {size:'md'});
      modalRef.componentInstance.rejectResult = this.rejectResult;
      modalRef.result.then(result => {
        if (result.description) {
          return this.holterService.addNotes(this.currentHolter.id, result.description, null).then(() => this.rejectHolter());
        } else {
          return this.rejectHolter();
        }
      });
    }
  }

  public unrejectHolter() {
    this.holterService.unrejectHolter(this.currentHolter.id).then(() => {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.draw(false);
      });
      this.holterService.getHolter(this.currentHolter.id).then(holter => this.populateHolter(holter));
    });
  }

  public rejectHolter() {
    this.holterService.rejectHolter(this.currentHolter.id).then(() => {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.draw(false);
      });
      this.holterService.getHolter(this.currentHolter.id).then(holter => this.populateHolter(holter));
    });
  }

  public addStaffNotes() {
    const notes = this.formGroup.get("AdditionalStaffNotes")?.value;
    this.holterService.addNotes(this.currentHolter.id, notes, null).then(() => {
      this.holterService.getHolter(this.currentHolter.id).then(holter => this.populateHolter(holter));
      this.formGroup.get("AdditionalStaffNotes").reset();
    });
  }

  public addFaxNotes() {
    const notes = this.formGroup.get("AdditionalFaxNotes")?.value;
    this.holterService.addNotes(this.currentHolter.id, null, notes).then(() => {
      this.holterService.getHolter(this.currentHolter.id).then(holter => this.populateHolter(holter));
      this.formGroup.get("AdditionalFaxNotes").reset();
    });
  }

  public skipToReading() {
    this.holterService.skipToReading(this.currentHolter.id).then(() => {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.draw(false);
      });
      this.holterService.getHolter(this.currentHolter.id).then(holter => this.populateHolter(holter));
    });
  }

  private downloadPDF(url: any, holter: Holter, fileType: string, extension: string) {
    this.holterService.downloadFile(url)
      .subscribe(blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = holter.patient_last_name + "_" + holter.patient_first_name + "_" + holter.hookup_date + "_" + fileType + "." + extension;
        a.click();
        URL.revokeObjectURL(objectUrl);
      });
  }

  public syncHolter() {
    this.datatableElement?.dtInstance.then((dtInstance: any) => {
      const updatePromises = [];
      dtInstance.rows(".selected").every((idx) => {
        const entry = dtInstance.row(idx).data();
        updatePromises.push(this.holterService.syncRequest(entry["holter:id"]));
      });
      Promise.all(updatePromises).then(() => dtInstance.draw(false));
    });
  }

  public showMedtraceDisplay($event){
    $event.preventDefault();
    const modalRef = this.modalService.open(ModalMedtraceDisplayComponent, { size:'lg'});
    modalRef.componentInstance.medtrace = this.currentMedtrace;
    modalRef.componentInstance.type = "display_monitor_traffic";
    // return false;
  }

}
<div class="md-width">

  <div class="row sub-header">
    <div class="col-12">
      <div class="text-center">
        <br>
        <ul class="list-inline">
          <li class="list-inline-item table-filter"
            (click)="navigateTo('/abpm/detail/interpretations/' + abpmId())"
            [class.table-filter-selected]="urlContains('interpretations')">
              <div class="col-xs-3"><i class="fa fa-pie-chart"></i></div>
              <div class="col-xs-9 text-center"><p>Interpretation</p></div>
          </li>
          <li class="list-inline-item table-filter"
            (click)="navigateTo('/abpm/detail/statistical/' + abpmId())"
            [class.table-filter-selected]="urlContains('statistical')">
              <div class="col-xs-3"><i class="fa fa-list-ol"></i></div>
              <div class="col-xs-9 text-center"><p>Statistical</p></div>
          </li>
          <li class="list-inline-item table-filter"
            (click)="navigateTo('/abpm/detail/graphical/' + abpmId())"
            [class.table-filter-selected]="urlContains('graphical')">
              <div class="col-xs-3"><i class="fa fa-area-chart"></i></div>
              <div class="col-xs-9 text-center"><p>Graphical</p></div>
          </li>
          <li class="list-inline-item table-filter"
            (click)="navigateTo('/abpm/detail/raw/' + abpmId())"
            [class.table-filter-selected]="urlContains('raw')">
              <div class="col-xs-3"><i class="fa fa-database"></i></div>
              <div class="col-xs-9 text-center"><p>Raw Data</p></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
<div>
  <div class="row sub-main">

      <div class="page-title" *ngIf="holter$ | async as holter">
        <div class="title_left">
          <h3>{{title}}</h3>
        </div>
      </div>
      <div class="clearfix"></div>

      <div class="x_panel">
        <div class="x_content">

          <div class="row">
            <div class="col-12">
              <form class="form-horizontal form-label-left" [formGroup]="formGroup">
                <div class="row">

                  <div class="col-4">
                    <h4>Patient Information</h4>
                    <div class="form-group form-display row">
                      <div class="col form-group required">
                        <label>Last Name</label>
                        <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('LastName')" [class.has-conflict]="conflictValue('LastName')" formControlName="LastName">
                        <div class="conflict-message" [hidden]="!conflictValue('LastName')">{{conflictValue('LastName')}}</div>
                      </div>
                      <div class="col form-group required">
                        <label>First Name</label>
                        <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('FirstName')" [class.has-conflict]="conflictValue('FirstName')" formControlName="FirstName">
                        <div class="conflict-message" [hidden]="!conflictValue('FirstName')">{{conflictValue('FirstName')}}</div>
                      </div>
                    </div>
                    <div class="form-group form-display row">
                      <div class="col-4 form-group required">
                        <label>Health Card</label>
                        <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('HealthCard')" [class.has-conflict]="conflictValue('HealthCard')" formControlName="HealthCard">
                        <div class="conflict-message" [hidden]="!conflictValue('HealthCard')">{{conflictValue('HealthCard')}}</div>
                      </div>
                      <div class="col-2 form-group required">
                        <label>Version</label>
                        <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('HealthCardVersion')" [class.has-conflict]="conflictValue('HealthCardVersion')" formControlName="HealthCardVersion">
                        <div class="conflict-message" [hidden]="!conflictValue('HealthCardVersion')">{{conflictValue('HealthCardVersion')}}</div>
                      </div>
                      <div class="col-6 form-group required">
                        <label>Date of Birth (YYYY/MM/DD)</label>
                        <input type="text" class="form-control" placeholder="YYYY/MM/DD" [class.not-autofilled]="!autofilled('Birthdate')" [class.has-conflict]="conflictValue('Birthdate')" formControlName="Birthdate">
                        <div class="conflict-message" [hidden]="!conflictValue('Birthdate')">{{conflictValue('Birthdate')}}</div>
                      </div>
                    </div>
                    <div class="form-group form-display row">
                      <div class="col form-group required">
                        <label>Referring Physician</label>
                        <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('ReferringDoctorName')" [class.has-conflict]="conflictValue('ReferringDoctorName')" formControlName="ReferringDoctorName">
                        <div class="conflict-message" [hidden]="!conflictValue('ReferringDoctorName')">{{conflictValue('ReferringDoctorName')}}</div>
                      </div>
                      <div class="col form-group required">
                        <label>Gender</label>
                        <select class="form-control" data-live-search="true" [class.not-autofilled]="!autofilled('Gender')" [class.has-conflict]="conflictValue('Gender')" formControlName="Gender">
                          <div class="conflict-message" [hidden]="!conflictValue('Gender')">{{conflictValue('Gender')}}</div>
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                        </select>
                      </div>
                    </div>
                    <h4>Report Information</h4>
                    <div class="form-group form-display row">
                      <div class="col-9 form-group required">
                        <label>Clinic</label>
                        <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('Clinic')" [class.has-conflict]="conflictValue('Clinic')" formControlName="Clinic">
                        <div class="conflict-message" [hidden]="!conflictValue('Clinic')">{{conflictValue('Clinic')}}</div>
                      </div>
                      <div class="col-3 form-group">
                        <label>Duration</label>
                        <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('Duration')" [class.has-conflict]="conflictValue('Duration')" formControlName="Duration">
                        <div class="conflict-message" [hidden]="!conflictValue('Duration')">{{conflictValue('Duration')}}</div>
                      </div>
                    </div>
                    <div class="form-group form-display row">
                      <div class="col-6 form-group">
                        <label>Reading Physician</label>
                        <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('ReadingDoctorName')" [class.has-conflict]="conflictValue('ReadingDoctorName')" formControlName="ReadingDoctorName">
                        <div class="conflict-message" [hidden]="!conflictValue('ReadingDoctorName')">{{conflictValue('ReadingDoctorName')}}</div>
                      </div>
                      <div class="col-3 form-group">
                        <label>Editor</label>
                        <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('Editor')" [class.has-conflict]="conflictValue('Editor')" formControlName="Editor">
                        <div class="conflict-message" [hidden]="!conflictValue('Editor')">{{conflictValue('Editor')}}</div>
                      </div>
                      <div class="col-3 form-group">
                        <label>Analyst</label>
                        <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('Analyst')" [class.has-conflict]="conflictValue('Analyst')" formControlName="Analyst">
                        <div class="conflict-message" [hidden]="!conflictValue('Analyst')">{{conflictValue('Analyst')}}</div>
                      </div>
                    </div>
                    <div class="form-group form-display row">
                      <div class="col form-group">
                        <label>Indications</label>
                        <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('Indications')" [class.has-conflict]="conflictValue('Indications')" formControlName="Indications">
                        <div class="conflict-message" [hidden]="!conflictValue('Indications')">{{conflictValue('Indications')}}</div>
                      </div>
                    </div>
                    <div class="form-group form-display row">
                      <div class="col form-group">
                        <label>12 Lead ECG</label>
                        <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('LeadECG')" [class.has-conflict]="conflictValue('LeadECG')" formControlName="LeadECG">
                        <div class="conflict-message" [hidden]="!conflictValue('LeadECG')">{{conflictValue('LeadECG')}}</div>
                      </div>
                    </div>
                    <div class="form-group form-display row">
                      <div class="col form-group">
                        <label>Comments</label>
                        <textarea class="form-control" rows="4" placeholder="" [class.not-autofilled]="!autofilled('Comments')" [class.has-conflict]="conflictValue('Comments')" formControlName="Comments"></textarea>
                        <div class="conflict-message" [hidden]="!conflictValue('Comments')">{{conflictValue('Comments')}}</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-8">
                    <h4>Test Details</h4>
                    <div class="form-group form-display row">
                      <div class="col-4 form-group required">
                        <label>Date of Test (YYYY/MM/DD)</label>
                        <input type="text" class="form-control" placeholder="YYYY/MM/DD" [class.not-autofilled]="!autofilled('TestDate')" [class.has-conflict]="conflictValue('TestDate')" formControlName="TestDate">
                        <div class="conflict-message" [hidden]="!conflictValue('TestDate')">{{conflictValue('TestDate')}}</div>
                      </div>
                    </div>
                    <div class=row>
                      <div class=col>
                        <div class="form-group form-display row">
                          <div class="col form-group">
                            Rhythm<hr>
                          </div>
                        </div>
                        <div class="form-group form-display row">
                          <div class="col form-group">
                            <label>Time Analyzed</label>
                            <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('TimeAnalyzed')" [class.has-conflict]="conflictValue('TimeAnalyzed')" formControlName="TimeAnalyzed">
                            <div class="conflict-message" [hidden]="!conflictValue('TimeAnalyzed')">{{conflictValue('TimeAnalyzed')}}</div>
                          </div>
                          <div class="col form-group">
                            <label>Min HR</label>
                            <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('MinHR')" [class.has-conflict]="conflictValue('MinHR')" formControlName="MinHR">
                            <div class="conflict-message" [hidden]="!conflictValue('MinHR')">{{conflictValue('MinHR')}}</div>
                          </div>
                          <div class="col form-group">
                            <label>Max HR</label>
                            <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('MaxHR')" [class.has-conflict]="conflictValue('MaxHR')" formControlName="MaxHR">
                            <div class="conflict-message" [hidden]="!conflictValue('MaxHR')">{{conflictValue('MaxHR')}}</div>
                          </div>
                          <div class="col form-group">
                            <label>Mean HR</label>
                            <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('MeanHR')" [class.has-conflict]="conflictValue('MeanHR')" formControlName="MeanHR">
                            <div class="conflict-message" [hidden]="!conflictValue('MeanHR')">{{conflictValue('MeanHR')}}</div>
                          </div>
                          <div class="col form-group">
                            <label>Total Beats</label>
                            <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('TotalBeats')" [class.has-conflict]="conflictValue('TotalBeats')" formControlName="TotalBeats">
                            <div class="conflict-message" [hidden]="!conflictValue('TotalBeats')">{{conflictValue('TotalBeats')}}</div>
                          </div>
                        </div>
                        <div class="form-group form-display row">
                          <div class="col form-group">
                            Arrhythmia<hr>
                          </div>
                        </div>
                        <div class="form-group form-display row">
                          <div class="col form-group">
                            <label>Rhythm 1</label>
                            <select class="form-control" [class.not-autofilled]="!autofilled('Rhythm1')" [class.has-conflict]="conflictValue('Rhythm1')" formControlName="Rhythm1">
                              <option>Normal sinus rhythm</option>
                              <option>Atrial fibrillation</option>
                              <option>Atrial flutter</option>
                              <option>Sinus rhythm</option>
                              <option>V-Pacing</option>
                              <option>Paced Rhythm</option>
                              <option>3rd degree AV block</option>
                             </select>
                            <div class="conflict-message" [hidden]="!conflictValue('Rhythm1')">{{conflictValue('Rhythm1')}}</div>
                          </div>
                        </div>
                        <div class="form-group form-display row">
                          <div class="col form-group">
                            <label>Rhythm 2</label>
                            <select class="form-control" [class.not-autofilled]="!autofilled('Rhythm2')" [class.has-conflict]="conflictValue('Rhythm2')" formControlName="Rhythm2">
                              <option>Atrial fibrillation</option>
                              <option>Atrial flutter</option>
                              <option>Paroxysmal atrial fibrillation</option>
                              <option>V-Pacing</option>
                              <option>Paced Rhythm</option>
                              <option>Wenckebach Mobitz 1 (proximal 2nd degree AV block type 1)</option>
                              <option>2nd degree AV block type 2</option>
                              <option>3rd degree AV block</option>
                             </select>
                            <div class="conflict-message" [hidden]="!conflictValue('Rhythm2')">{{conflictValue('Rhythm2')}}</div>
                          </div>
                        </div>
                        <div class="form-group form-display row">
                          <div class="col form-group">
                            <label>Rhythm 3</label>
                            <select class="form-control" [class.not-autofilled]="!autofilled('Rhythm3')" [class.has-conflict]="conflictValue('Rhythm3')" formControlName="Rhythm3">
                              <option>Atrial fibrillation</option>
                              <option>Atrial flutter</option>
                              <option>Paroxysmal atrial fibrillation</option>
                              <option>V-Pacing</option>
                              <option>Paced Rhythm</option>
                              <option>Wenckebach Mobitz 1 (proximal 2nd degree AV block type 1)</option>
                              <option>3rd degree AV block</option>
                            </select>
                            <div class="conflict-message" [hidden]="!conflictValue('Rhythm3')">{{conflictValue('Rhythm3')}}</div>
                          </div>
                        </div>
                      </div>
                      <div class=col>
                        <div class="form-group form-display row">
                          <div class="col form-group">
                            Pauses<hr>
                          </div>
                        </div>
                        <div class="form-group form-display row">
                          <div class="col form-group">
                            <label>Longest R-R</label>
                            <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('PauseLongestRR')" [class.has-conflict]="conflictValue('PauseLongestRR')" formControlName="PauseLongestRR">
                            <div class="conflict-message" [hidden]="!conflictValue('PauseLongestRR')">{{conflictValue('PauseLongestRR')}}</div>
                          </div>
                          <div class="col form-group">
                            <label>Time</label>
                            <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('PauseTime')" [class.has-conflict]="conflictValue('PauseTime')" formControlName="PauseTime">
                            <div class="conflict-message" [hidden]="!conflictValue('PauseTime')">{{conflictValue('PauseTime')}}</div>
                          </div>
                          <div class="col form-group">
                            <label>Pauses > 2.5</label>
                            <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('PauseCount')" [class.has-conflict]="conflictValue('PauseCount')" formControlName="PauseCount">
                            <div class="conflict-message" [hidden]="!conflictValue('PauseCount')">{{conflictValue('PauseCount')}}</div>
                          </div>
                        </div>
                        <div class="form-group form-display row">
                          <div class="col form-group">
                            APB<hr>
                          </div>
                        </div>
                        <div class="form-group form-display row">
                          <div class="col form-group">
                            <label>Total</label>
                            <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('APBTotal')" [class.has-conflict]="conflictValue('APBTotal')" formControlName="APBTotal">
                            <div class="conflict-message" [hidden]="!conflictValue('APBTotal')">{{conflictValue('APBTotal')}}</div>
                          </div>
                          <div class="col form-group">
                            <label>Pair</label>
                            <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('APBPair')" [class.has-conflict]="conflictValue('APBPair')" formControlName="APBPair">
                            <div class="conflict-message" [hidden]="!conflictValue('APBPair')">{{conflictValue('APBPair')}}</div>
                          </div>
                          <div class="col form-group">
                            <label>SVT</label>
                            <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('APBSVT')" [class.has-conflict]="conflictValue('APBSVT')" formControlName="APBSVT">
                            <div class="conflict-message" [hidden]="!conflictValue('APBSVT')">{{conflictValue('APBSVT')}}</div>
                          </div>
                        </div>
                        <div class="form-group form-display row">
                          <div class="col form-group">
                            VPB<hr>
                          </div>
                        </div>
                        <div class="form-group form-display row">
                          <div class="col form-group">
                            <label>Total</label>
                            <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('VPBTotal')" [class.has-conflict]="conflictValue('VPBTotal')" formControlName="VPBTotal">
                            <div class="conflict-message" [hidden]="!conflictValue('VPBTotal')">{{conflictValue('VPBTotal')}}</div>
                          </div>
                          <div class="col form-group">
                            <label>Pair</label>
                            <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('VPBPair')" [class.has-conflict]="conflictValue('VPBPair')" formControlName="VPBPair">
                            <div class="conflict-message" [hidden]="!conflictValue('VPBPair')">{{conflictValue('VPBPair')}}</div>
                          </div>
                          <div class="col form-group">
                            <label>VT</label>
                            <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('VPBVT')" [class.has-conflict]="conflictValue('VPBVT')" formControlName="VPBVT">
                            <div class="conflict-message" [hidden]="!conflictValue('VPBVT')">{{conflictValue('VPBVT')}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group form-display row">
                      <div class="col form-group">
                        Diary Events<hr>
                      </div>
                    </div>
                    <div class="form-group form-display row">
                      <div class="col-1 form-group">
                        <label>No. Events</label>
                        <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('EventNumber1')" [class.has-conflict]="conflictValue('EventNumber1')" formControlName="EventNumber1">
                        <div class="conflict-message" [hidden]="!conflictValue('EventNumber1')">{{conflictValue('EventNumber1')}}</div>
                      </div>
                      <div class="col-5 form-group">
                        <label>Corresponded Arrhythmia</label>
                        <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('Arrhythmia1')" [class.has-conflict]="conflictValue('Arrhythmia1')" formControlName="Arrhythmia1">
                        <div class="conflict-message" [hidden]="!conflictValue('Arrhythmia1')">{{conflictValue('Arrhythmia1')}}</div>
                      </div>
                      <div class="col-1 form-group">
                        <label>No. Events</label>
                        <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('EventNumber2')" [class.has-conflict]="conflictValue('EventNumber2')" formControlName="EventNumber2">
                        <div class="conflict-message" [hidden]="!conflictValue('EventNumber2')">{{conflictValue('EventNumber2')}}</div>
                      </div>
                      <div class="col-5 form-group">
                        <label>Corresponded Arrhythmia</label>
                        <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('Arrhythmia2')" [class.has-conflict]="conflictValue('Arrhythmia2')" formControlName="Arrhythmia2">
                        <div class="conflict-message" [hidden]="!conflictValue('Arrhythmia2')">{{conflictValue('Arrhythmia2')}}</div>
                      </div>
                    </div>
                    <div class="form-group form-display row">
                      <div class="col-1 form-group">
                        <label>No. Events</label>
                        <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('EventNumber3')" [class.has-conflict]="conflictValue('EventNumber3')" formControlName="EventNumber3">
                        <div class="conflict-message" [hidden]="!conflictValue('EventNumber3')">{{conflictValue('EventNumber3')}}</div>
                      </div>
                      <div class="col-5 form-group">
                        <label>Corresponded Arrhythmia</label>
                        <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('Arrhythmia3')" [class.has-conflict]="conflictValue('Arrhythmia3')" formControlName="Arrhythmia3">
                        <div class="conflict-message" [hidden]="!conflictValue('Arrhythmia3')">{{conflictValue('Arrhythmia3')}}</div>
                      </div>
                      <div class="col-1 form-group">
                        <label>No. Events</label>
                        <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('EventNumber4')" [class.has-conflict]="conflictValue('EventNumber4')" formControlName="EventNumber4">
                        <div class="conflict-message" [hidden]="!conflictValue('EventNumber4')">{{conflictValue('EventNumber4')}}</div>
                      </div>
                      <div class="col-5 form-group">
                        <label>Corresponded Arrhythmia</label>
                        <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('Arrhythmia4')" [class.has-conflict]="conflictValue('Arrhythmia4')" formControlName="Arrhythmia4">
                        <div class="conflict-message" [hidden]="!conflictValue('Arrhythmia4')">{{conflictValue('Arrhythmia4')}}</div>
                      </div>
                    </div>
                    <div class="form-group form-display row">
                      <div class="col form-group">
                        <label>Symptoms</label>
                        <input type="text" class="form-control" placeholder="" [class.not-autofilled]="!autofilled('Symptoms')" [class.has-conflict]="conflictValue('Symptoms')" formControlName="Symptoms">
                        <div class="conflict-message" [hidden]="!conflictValue('Symptoms')">{{conflictValue('Symptoms')}}</div>
                      </div>
                    </div>
                  </div>

                </div>
              </form>
            </div>
          </div>

        </div>
      </div>
  </div>

  <div>
    <div class="row sub-footer">
      <div class="col-12">
        <button class="btn btn-primary btn-review" type="button" (click)="clearForm()"><i class="fa fa-file-o"></i> Clear</button>
        <button class="btn btn-primary btn-review" type="button" (click)="undoForm()"><i class="fa fa-undo"></i> Undo</button>
        <button class="btn btn-primary btn-review" type="button" (click)="autofillForm()"><i class="fa fa-flash"></i> Autofill</button>
        <button class="btn btn-primary btn-review" type="button" (click)="submitForm()" [disabled]="!formGroup.valid"><i class="fa fa-check"></i> Submit</button>
      </div>
    </div>
  </div>
</div>
<div class="row sub-main no-footer">

    <div class="page-title">
      <div class="title_left">
        <h3>Billing - Invoice</h3>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="x_panel">
      <div class="x_content">

        <div class="row split-container">
          <as-split direction="horizontal">

            <as-split-area [size]="50">
              <div class="col">

                <div class="row">
                  <div class="col-4">
                    <select class="form-control" data-live-search="true" [(ngModel)]="statusFilter" (change)="statusFilterChanged()">
                      <option>All</option>
                      <option>New</option>
                      <option>Billed</option>
                      <option>Archived</option>
                    </select>
                  </div>
                  <div class="col-8">
                      <input type="text" class="form-control search-bar" placeholder="Start typing to search" id="search-bar">
                  </div>
                </div>

                <table datatable [dtOptions]="dtOptions" class="table table-striped row-bordered datatable-buttons hover">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Last Updated</th>
                      <th>Upload Date</th>
                      <th>Comments</th>
                      <th>Status</th>
                      <th>Id</th>
                    </tr>
                  </thead>
                </table>
                <div class="row">
                  <div class="col text-right">
                    <button class="btn btn-primary btn-narrow" type="button" (click)="updateStatus('Archived')" [disabled]="!currentReq">Archive</button>
                    <button class="btn btn-primary btn-narrow" type="button" (click)="updateStatus('Billed')" [disabled]="!currentReq">Mark as Billed</button>
                    <button class="btn btn-primary btn-narrow" type="button" (click)="updateStatus('New')" [disabled]="!currentReq">Unbill</button>
                  </div>
                </div>
              </div>
            </as-split-area>

            <as-split-area [size]="50">
              <div class="col info-panel" *ngIf="currentReq">

                <form class="form-horizontal form-label-left" [formGroup]="formGroup">
                  <div class="row">
                    <div class="col form-group">
                      <label>Last Name</label>
                      <input type="text" class="form-control" placeholder="" formControlName="LastName" [ngbTypeahead]="searchHolter" [resultFormatter]="searchHolterFormatter" [inputFormatter]="searchHolterInputLastFormatter">
                    </div>
                    <div class="col form-group">
                      <label>First Name</label>
                      <input type="text" class="form-control" placeholder="" formControlName="FirstName" [ngbTypeahead]="searchHolter" [resultFormatter]="searchHolterFormatter" [inputFormatter]="searchHolterInputFirstFormatter">
                    </div>
                    <div class="col form-group">
                      <label>Hookup Date</label>
                      <input type="text" class="form-control" placeholder="YYYY/MM/DD" formControlName="HookupDate" [ngbTypeahead]="searchHolter" [resultFormatter]="searchHolterFormatter" [inputFormatter]="searchHolterInputHookupDateFormatter">
                    </div>
                    <div class="col form-group">
                      <button class="btn btn-primary btn-review" (click)="renameReq()" type="button" [disabled]="multiSelected || !currentReq || !validToUpdate()">Update</button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col form-group">
                      <label>Comments</label>
                      <textarea class="form-control" rows="1" formControlName="Comments"></textarea>
                    </div>
                  </div>
                </form>

                <div class="preview-panel">
                  <pdf-viewer [src]="pdfSrc" [autoresize]="true" [render-text]="false" [original-size]="false" [fit-to-page]="true" style="display: block;"></pdf-viewer>
                </div>
              </div>
            </as-split-area>

          </as-split>
        </div>
      </div>
    </div>
  </div>
import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Abpm } from "../../models/abpm.model";
import { AbpmService } from "../../providers/abpm.service";
import { Visit } from "../../models/visit.model";
import { VisitService } from "../../providers/visit.service";

@Component({
  selector: 'app-home',
  templateUrl: './abpm-raw.component.html',
  styleUrls: ['./abpm-raw.component.scss']
})
export class AbpmRawComponent implements OnInit, OnDestroy {

  public dtTrigger: any = new Subject();

  public title: string = "";

  public abpm$: Observable<Abpm>;
  public visit: Visit = null;
  public report: any = null;

  public dtOptionsRaw: any = {};

  constructor(
    private abpmService: AbpmService,
    private visitService: VisitService,
    private route: ActivatedRoute,
    private router: Router
  ) {

    this.dtOptionsRaw = {
        autoWidth: false,
        responsive: true,
        lengthChange: false,
        pageLength: 25,
        dom: "Blfrtip",
        buttons: [],
        destroy: true,
        order: [[ 2, "asc" ]],
    };
  }

  public ngOnInit() {

    // get the ABPM object from the server
    this.abpm$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.abpmService.getAbpm(parseInt(params.get('id'), 10)))
    );

    // generate the report
    this.abpm$.subscribe((abpm: Abpm) => {
      return this.visitService.getVisit(abpm.visit_id).then((visit: Visit) => {

        if (visit) {
          if ((visit.patient_first_name.length + visit.patient_last_name.length) < 20) {
            this.title = "ABPM - " + visit.patient_first_name + " " + visit.patient_last_name;
          } else {
            this.title = "ABPM - Raw Data";
          }
        } else {
          this.title = "ABPM - Unassigned";
        }

        this.abpmService.getAnalysis(abpm.id).then(response => {
          abpm.analysis = response.analysis;
          this.report = response.report;
          this.visit = visit;

          // this refreshes the table with the new entries
          this.dtTrigger.next();
        });

      })
    });
  }

  public ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  public getRawTable(): any[] {
    if (!this.report || !this.report.raw) {
      return [];
    }
    return this.report.raw;
  }

  public navigateTo(url: string): void {
    this.router.navigateByUrl(url);
  }
}

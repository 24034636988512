<div class="md-width">

  <div class="row sub-header">
    <div class="col-12">
      <div class="text-center">
        <br>
          <ul class="list-inline">
            <li class="list-inline-item table-filter" id="page-tech"
                (click)="switchPage(PAGE_TECH)" 
                [class.table-filter-selected]="page===PAGE_TECH">
                <div class="col-xs-3"><i class="fa fa-laptop"></i></div>
                <div class="col-xs-9 text-center"><p>Technician</p></div>
            </li>
            <li class="list-inline-item table-filter table-filter-center" id="page-reading"
                (click)="switchPage(PAGE_READING)" 
                [class.table-filter-selected]="page===PAGE_READING">
                <div class="col-xs-3"><i class="fa fa-user-md"></i></div>
                <div class="col-xs-9 text-center"><p>Doctor</p></div>
            </li>
            <li class="list-inline-item table-filter" id="page-location"
                (click)="switchPage(PAGE_LOCATION)" 
                [class.table-filter-selected]="page===PAGE_LOCATION">
                <div class="col-xs-3"><i class="fa fa-calendar-check-o"></i></div>
                <div class="col-xs-9 text-center"><p>Location</p></div>
            </li>
          </ul>
      </div>
    </div>
  </div>
</div>

<div class="row sub-main no-footer">

  <div class="page-title">
    <div class="title_left">
      <h3>Analytics</h3>
    </div>
  </div>
  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="x_content">

      <div class="row" [hidden]="false">
        <div class="col-12">

          <div [hidden]="page!==PAGE_TECH">
              <br>
              <!-- <h4>Technician Metrics</h4> -->
              <plotly-plot id="tech-graph" class="stat-graph" [data]="techGraph.data" [layout]="techGraph.layout" [useResizeHandler]="true"></plotly-plot>
              <plotly-plot id="tech-cumulative-graph" class="stat-graph" [data]="techCumulativeGraph.data" [layout]="techCumulativeGraph.layout" [useResizeHandler]="true"></plotly-plot>
          </div>

          <div [hidden]="page!==PAGE_READING">
              <br>
              <!-- <h4>Reading Dr. Metrics</h4> -->
              <plotly-plot id="doctor-graph" class="stat-graph" [data]="doctorGraph.data" [layout]="doctorGraph.layout" [useResizeHandler]="true"></plotly-plot>
              <plotly-plot id="doctor-cumulative-graph" class="stat-graph" [data]="doctorCumulativeGraph.data" [layout]="doctorCumulativeGraph.layout" [useResizeHandler]="true"></plotly-plot>
          </div>

          <div [hidden]="page!==PAGE_LOCATION">
              <br>
              <!-- <h4>Location Metrics</h4> -->
              <plotly-plot id="location-graph" class="stat-graph" [data]="locationGraph.data" [layout]="locationGraph.layout" [useResizeHandler]="true"></plotly-plot>
              <plotly-plot id="location-cumulative-graph" class="stat-graph" [data]="locationCumulativeGraph.data" [layout]="locationCumulativeGraph.layout" [useResizeHandler]="true"></plotly-plot>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>
